import React from "react";
import { createTheme, ThemeProviderMUI } from "../DeltaUI/Components";
import { COLOR_QUATERNARY, COLOR_PRIMARY, COLOR_SECONDARY } from "../Utils/Constants";

const themeCustom = createTheme({
  palette: {
    primary: {
      main: COLOR_PRIMARY,
    },
    secondary: {
      main: COLOR_SECONDARY,
      contrastText: "white",
    },

  },
   typography: {
    allVariants: {
      color: COLOR_QUATERNARY,
  },
}
});

export interface ThemeAppProviderProps {}

export const ThemeAppProvider: React.FC<ThemeAppProviderProps> = ({
  children,
}) => {
  return <ThemeProviderMUI theme={themeCustom}>{children}</ThemeProviderMUI>;
};
