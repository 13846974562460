import React from "react";

// Firebase App (the core Firebase SDK) is always required and must be listed first
import firebase from "firebase/app";
// If you are using v7 or any earlier version of the JS SDK, you should import firebase using namespace import
// import * as firebase from "firebase/app"

// If you enabled Analytics in your project, add the Firebase SDK for Analytics
import "firebase/analytics";

// Add the Firebase products that you want to use
import "firebase/auth";
import "firebase/firestore";
import { isDesktop } from "./Constants";

interface IFirebase {
  firebaseApp: firebase.app.App;
}

const context: IFirebase = {
  firebaseApp: null as any,
};

export const FirebaseContext = React.createContext(context);

export interface FirebaseProviderProps {
  firebaseConfig: {
    apiKey: string; // "AIzaSyDcc7jzkD5RePpym4GDJzlRBdMnat651D4",
    authDomain: string; // "upool-e94ef.firebaseapp.com",
    projectId: string; // "upool-e94ef",
    storageBucket: string; // "upool-e94ef.appspot.com",
    messagingSenderId: string; // "876105434748",
    appId: string; // "1:876105434748:web:c9d0d2b77d89745baaebf9",
  };
}

export const FirebaseProvider: React.FC<FirebaseProviderProps> = (props) => {
  if (window.location.origin.indexOf("localhost") > -1 && !isDesktop) {
    return (
      <FirebaseContext.Provider
        value={{ firebaseApp: firebase.initializeApp(props.firebaseConfig) }}
      >
        {props.children}
      </FirebaseContext.Provider>
    );
  }
  return <>{props.children}</>;
};
