import React from "react";
import {
  BoxMUI,
  CardMUI,
  PaperMUI,
  Separator,
  TypographyMUI,
} from "../../DeltaUI/Components";
import { IVehicle } from "../../Services/VehicleService";
import CardVehicle from "../User/CardVehicle";

export interface DetailTripResumeProps {
  dataTrip: JSX.Element;
  vehicle?: IVehicle;
  preferences: JSX.Element;
  aditional?: string;
  freeSeatMiddle?: boolean;
}

const DetailTripResume: React.FC<DetailTripResumeProps> = (props) => {
  const { dataTrip, vehicle, preferences, aditional, freeSeatMiddle } = props;
  return (
    <>
      <TypographyMUI className="TypographyExtrabold11pt">Datos</TypographyMUI>
      <Separator px={8} />
      <CardMUI
        elevation={7}
        style={{
          position: "relative",
          marginBottom: "16px",
          padding: "8px 0",
        }}
        className="ShadowCard8"
      >
        {dataTrip}
      </CardMUI>
      <Separator px={16} />
      <TypographyMUI className="TypographyExtrabold11pt">
        Vehículo
      </TypographyMUI>
      <Separator px={8} />
      {vehicle ? (
        <CardVehicle
          style={{ margin: 0 }}
          vehicle={vehicle}
          freeSeatMiddle={freeSeatMiddle}
        />
      ) : null}
      <Separator px={32} />
      {preferences}
      {aditional ? (
        <>
          <Separator px={32} />
          <TypographyMUI className="TypographyExtrabold11pt">
            Información adicional
          </TypographyMUI>
          <Separator px={8} />
          <PaperMUI elevation={7} className="ShadowCard8">
            <BoxMUI style={{ padding: "16px" }}>
              <TypographyMUI>{aditional}</TypographyMUI>
            </BoxMUI>
          </PaperMUI>
        </>
      ) : null}
    </>
  );
};

export default DetailTripResume;
