import React from "react";
import {
  BoxMUI,
  ButtonMUI,
  DialogActionsMUI,
  DialogContentMUI,
  DialogMUI,
  Separator,
} from "../../DeltaUI/Components";
import { ThemeAppProvider } from "../../Providers/ThemeAppProvider";
import { useCustomLog } from "../../Utils";

interface DialogCustomProps {
  context?: string;
  open: boolean;
  onClose?: () => void;
  title?: JSX.Element | string;
  body?: JSX.Element | string;
  footer?: JSX.Element;
}

export const DialogCustom: React.FC<DialogCustomProps> = (props) => {
  const { context, open, onClose, title, body, footer } = props;
  const Log = useCustomLog();

  const message = React.useMemo(() => {
    let m = "";
    if (typeof title === "string") {
      m = title;
    }
    if (typeof body === "string") {
      m = m ? `${m}. ${body}` : body;
    }
    return m;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (context && open) {
      Log.info({
        context,
        message,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <DialogMUI
      sx={{
        "& .MuiDialog-paper": {
          margin: "24px",
          width: "100%",
        },
      }}
      open={open}
      onClose={onClose}
    >
      <ThemeAppProvider>
        {title ? <DialogCustomHeader>{title}</DialogCustomHeader> : null}
        {body ? <DialogCustomBody>{body}</DialogCustomBody> : null}
        {footer ? <div style={{ padding: "8px 0" }}>{footer}</div> : null}
      </ThemeAppProvider>
    </DialogMUI>
  );
};

export const DialogCustomHeader: React.FC = ({ children }) => {
  if (typeof children === "string") {
    return (
      <DialogContentMUI
        className="TypographyBold13pt"
        style={{ padding: "24px", paddingBottom: "8px" }}
      >
        {children}
      </DialogContentMUI>
    );
  }
  return (
    <DialogContentMUI style={{ padding: "24px", paddingBottom: "8px" }}>
      {children}
    </DialogContentMUI>
  );
};

export const DialogCustomBody: React.FC = ({ children }) => {
  if (typeof children === "string") {
    return (
      <DialogContentMUI
        className="TypographyRegular11pt"
        style={{ padding: "8px 24px" }}
      >
        {children}
      </DialogContentMUI>
    );
  }
  return (
    <DialogContentMUI style={{ padding: "8px 24px" }}>
      {children}
    </DialogContentMUI>
  );
};

export const DialogCustomFooter: React.FC = ({ children }) => {
  return (
    <DialogActionsMUI style={{ padding: "24px" }}>{children}</DialogActionsMUI>
  );
};

interface DialogCustomFooterActionsProps {
  onOk?: () => void;
  onCancel?: () => void;
  textOnOk?: string;
  textOnCancel?: string;
}

export const DialogCustomFooterActions: React.FC<DialogCustomFooterActionsProps> = (
  props
) => {
  const { onOk, onCancel, textOnOk, textOnCancel } = props;
  return (
    <BoxMUI className="FlexRow JustifyEnd" style={{ padding: "16px 24px" }}>
      {onCancel ? (
        <>
          <ButtonMUI
            style={{ width: "50%" }}
            variant="outlined"
            onClick={onCancel}
          >
            {textOnCancel || "Cancelar"}
          </ButtonMUI>
          <Separator px={16} orientation="vertical" />
        </>
      ) : null}
      <ButtonMUI
        style={{ width: "50%" }}
        variant="contained"
        color="primary"
        onClick={onOk}
      >
        {textOnOk || "Aceptar"}
      </ButtonMUI>
    </BoxMUI>
  );
};
