import React from "react";
import {
  ToolbarMUI,
  BodyPage,
  Separator,
  MenuOptionsButton,
  ButtonGroup,
  FooterPage,
  ActionSheetOptions,
  BoxMUI,
  TypographyMUI,
  CardMUI,
  ListItemMUI,
  ListItemTextMUI,
} from "../../../DeltaUI/Components";
import { DeleteIcon, GoogleIcon } from "../../../DeltaUI/Components/CustomIcon";
import { UpoolContext } from "../../../Providers/UpoolContext";
import { CustomPage, GoogleLogin } from "../../../Components";
import { UserService } from "../../../Services/UserService";
import { TitlePage } from "../../../Components/TitlePage";
import { DialogCustomHook } from "../../../Components";
import { GoBack } from "../../../Components/GoBack";
import {
  AVAILABLE_SCREEN_SPACE_CSS,
  COLOR_PRIMARY,
} from "../../../Utils/Constants";

const GoogleSettingPage: React.FC = () => {
  const { deleteGoogle } = UserService();
  const { user, setLoading } = React.useContext(UpoolContext);
  const { message, messageError } = DialogCustomHook();
  const [viewOptions, setViewOptions] = React.useState<boolean>(false);

  const handleDelete = () => {
    setViewOptions(() => false);
    message({
      context: "GoogleSettingPage.handleDelete.1",
      title: "¿Querés eliminar tu cuenta de Google?",
      message:
        "Ya no tendrás el inicio de sesion de Google vinculado a tu cuenta.",
      onOk: () => {
        setLoading(() => true);
        deleteGoogle()
          .then(() => {
            message({
              context: "GoogleSettingPage.handleDelete.2",
              title: "¡Listo!",
              message: "La cuenta de Google se eliminó correctamente.",
            });
          })
          .catch(({ message }) => {
            messageError({
              context: "GoogleSettingPage.handleDelete.3",
              message: message,
            });
          })
          .finally(() => setLoading(() => false));
      },
      textOnOk: "Sí",
      textOnCancel: "No",
    });
  };

  return (
    <CustomPage
      headerPage={
        <ToolbarMUI>
          <GoBack />
          <TitlePage text="Cuenta de Google" />
          {user?.googleId ? (
            <ButtonGroup slot="end">
              <MenuOptionsButton
                menuId="GoogleSettingPageMenuOptionsID"
                onClick={() => setViewOptions(() => true)}
              />
            </ButtonGroup>
          ) : null}
        </ToolbarMUI>
      }
    >
      <BodyPage m={3}>
        <BoxMUI
          style={{
            height: `calc(${AVAILABLE_SCREEN_SPACE_CSS} - 56px - 48px)`,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <BoxMUI
            style={{
              width: "100%",
            }}
          >
            {user?.googleId ? (
              <CardMUI
                style={{
                  width: "100%",
                  padding: "8px",
                }}
                elevation={7}
                className="ShadowCard8"
              >
                <ListItemMUI>
                  <GoogleIcon
                    style={{ marginRight: "16px", fontSize: "3rem" }}
                  />
                  <ListItemTextMUI primary="Tu cuenta se encuentra vinculada." />
                </ListItemMUI>
              </CardMUI>
            ) : (
              <TypographyMUI className="TypographyRegular11pt">
                No tenes ninguna cuenta vinculada.
              </TypographyMUI>
            )}
          </BoxMUI>
          <BoxMUI
            style={{
              width: "100%",
            }}
          >
            {user && !user.googleId ? (
              <GoogleLogin login={false} text="Agregar cuenta" />
            ) : null}
            <Separator px={32} />
          </BoxMUI>
        </BoxMUI>
      </BodyPage>
      <FooterPage>
        {user?.googleId ? (
          <ActionSheetOptions
            open={viewOptions}
            onClose={() => setViewOptions(() => false)}
            listOptions={[
              {
                icon: <DeleteIcon style={{ color: COLOR_PRIMARY }} />,
                label: "Eliminar cuenta",
                onClick: handleDelete,
              },
            ]}
          />
        ) : null}
      </FooterPage>
    </CustomPage>
  );
};

export default GoogleSettingPage;
