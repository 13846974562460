import { gql } from "@apollo/client";

export const SETTINGS = gql`
  query settings($where: SettingWhereInput) {
    settings(where: $where) {
      id
      name
      configCategory
      configKey
      configValue
    }
  }
`;
