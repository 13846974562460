import React from "react";
import {
  Camera,
  CameraResultType,
  CameraSource,
  ImageOptions,
} from "@capacitor/camera";
import {
  SwipeableActionSheet,
  SwipeableActionSheetItem,
} from "../../DeltaUI/Components";
import {
  CameraAltOutlinedIcon,
  PhotoLibraryOutlinedIcon,
} from "../../DeltaUI/Components/CustomIcon";
import { Tools, useCustomLog } from "../../Utils";
import { COLOR_PRIMARY } from "../../Utils/Constants";

const IMAGE_OPTIONS: ImageOptions = {
  quality: 100,
  allowEditing: false,
  resultType: CameraResultType.DataUrl,
};

interface OptionsCaptureImageProps {
  style?: React.CSSProperties;
  className?: string;
  open: boolean;
  onClose?: () => void;
  onChange?: (imageUrl: string) => void;
}

export const OptionsCaptureImage: React.FC<OptionsCaptureImageProps> = (
  props
) => {
  const { style, className, open, onClose, onChange } = props;
  const { base64ToObjectURL } = Tools();
  const Log = useCustomLog();

  const handleOnClose = () => {
    if (onClose) {
      onClose();
    }
  };

  const takePictureCamera = async () => {
    handleOnClose();
    Camera.getPhoto({
      ...IMAGE_OPTIONS,
      source: CameraSource.Camera,
    })
      .then((image) => {
        if (onChange && image.dataUrl) {
          base64ToObjectURL(image.dataUrl)
            .then(onChange)
            .catch(() => onChange(image.dataUrl || ""));
        }
      })
      .catch(({ message }) => {
        Log.warn({
          context: "OptionsCaptureImage.takePictureCamera.1",
          message,
        });
      });
  };

  const takePicturePhotos = async () => {
    handleOnClose();
    Camera.getPhoto({
      ...IMAGE_OPTIONS,
      source: CameraSource.Photos,
    })
      .then((image) => {
        if (onChange && image.dataUrl) {
          base64ToObjectURL(image.dataUrl)
            .then(onChange)
            .catch(() => onChange(image.dataUrl || ""));
        }
      })
      .catch(({ message }) => {
        Log.warn({
          context: "OptionsCaptureImage.takePicturePhotos.1",
          message,
        });
      });
  };

  const checkPermissions = async () => {
    const status = await Camera.checkPermissions();
    if (status.camera === "denied") {
      const statusRequest = await Camera.requestPermissions({
        permissions: ["camera"],
      });
      if (statusRequest.camera === "denied") {
        Log.warn({
          context: "OptionsCaptureImage.checkPermissions.1",
          message: "Permiso denegado por el usuario.",
        });
      }
    }
  };

  React.useEffect(() => {
    checkPermissions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={style} className={className}>
      <SwipeableActionSheet open={open} onClose={onClose} type="options">
        <SwipeableActionSheetItem
          onClick={takePictureCamera}
          text="Tomar foto"
          icon={<CameraAltOutlinedIcon style={{ color: COLOR_PRIMARY }} />}
        />
        <SwipeableActionSheetItem
          onClick={takePicturePhotos}
          text="Abrir galería"
          icon={<PhotoLibraryOutlinedIcon style={{ color: COLOR_PRIMARY }} />}
        />
      </SwipeableActionSheet>
    </div>
  );
};
