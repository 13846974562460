import React from "react";
import {
  ButtonMUI,
  BoxMUI,
  Separator,
  TypographyMUI,
} from "../../DeltaUI/Components";
import { UserService } from "../../Services/UserService";
import { DialogCustomHook } from "../DialogCustom";
import {
  DeleteAccountContext,
  DELETE_ACCOUNT_STEPS,
} from "./DeleteAccountContext";

interface InitProps {}

export const Init: React.FC<InitProps> = () => {
  const { setStep, requiresVerification } = React.useContext(
    DeleteAccountContext
  );
  const { messageWarn } = DialogCustomHook();
  const { verifyAccountDeletion } = UserService();

  const next = () => {
    verifyAccountDeletion()
      .then(() => {
        if (requiresVerification) {
          setStep(DELETE_ACCOUNT_STEPS.RECOVERY_OPTIONS);
        } else {
          setStep(DELETE_ACCOUNT_STEPS.END);
        }
      })
      .catch(({ message }) => {
        messageWarn({
          context: "Init.next.1",
          message,
        });
      });
  };

  return (
    <>
      <BoxMUI
        style={{
          width: "100%",
        }}
      >
        <TypographyMUI
          className="TypographyExtrabold26pt"
          sx={{ lineHeight: "48px" }}
        >
          Eliminar cuenta
        </TypographyMUI>
        <Separator px={24} />
        <TypographyMUI className="TypographyRegular11pt">
          ¿Querés eliminar tu cuenta?
        </TypographyMUI>
        <Separator px={24} />
        <TypographyMUI className="TypographyRegular11pt">
          Se eliminarán todos los datos y ya no podrás recuperarlos.
        </TypographyMUI>
        <Separator px={8} />
        {requiresVerification ? (
          <TypographyMUI className="TypographyRegular11pt">
            Por seguridad deberás validar tu identidad.
          </TypographyMUI>
        ) : null}
      </BoxMUI>
      <BoxMUI
        style={{
          width: "100%",
        }}
      >
        <Separator px={16} />
        <ButtonMUI
          style={{
            width: "100%",
            marginTop: "16px",
          }}
          variant="contained"
          color="primary"
          onClick={next}
        >
          Continuar
        </ButtonMUI>
        <Separator px={32} />
      </BoxMUI>
    </>
  );
};
