import React from "react";
import {
  FormControlMUI,
  InputLabelMUI,
  MenuItemMUI,
  SelectMUI,
} from "../../DeltaUI/Components";
import { UserService, IPersonGenders } from "../../Services/UserService";
import { useComponentMount } from "../../Utils";

interface SelectPersonGenderProps {
  style?: React.CSSProperties;
  className?: string;
  value?: number;
  onChange?: (value: number) => void;
  onLoading?: (value: boolean) => void;
  onError?: (err: any) => void;
}

export const SelectPersonGender: React.FC<SelectPersonGenderProps> = (
  props
) => {
  const isMountedComponent = useComponentMount("SelectPersonGender");
  const { style, className, value, onChange, onLoading, onError } = props;

  const { getPersonGenders } = UserService();

  const [dataPersonGenders, setDataPersonGenders] = React.useState<
    IPersonGenders[]
  >([]);

  const handleOnLoading = (l: boolean) => {
    if (onLoading) {
      onLoading(l);
    }
  };

  const getData = () => {
    handleOnLoading(true);
    getPersonGenders()
      .then((res) => {
        if (isMountedComponent.current && res) {
          setDataPersonGenders(() => res);
        }
      })
      .catch(onError)
      .finally(() => handleOnLoading(false));
  };

  React.useEffect(() => {
    isMountedComponent.current = true;
    getData();
    return () => {
      isMountedComponent.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMountedComponent]);

  return (
    <FormControlMUI variant="standard" style={style} className={className}>
      <InputLabelMUI>Sexo</InputLabelMUI>
      <SelectMUI
        variant="standard"
        value={value || ""}
        onChange={(ev) => {
          if (onChange) {
            onChange(Number(ev.target.value));
          }
        }}
      >
        {dataPersonGenders.map((g) => (
          <MenuItemMUI key={g.id} value={g.id}>
            {g.description}
          </MenuItemMUI>
        ))}
      </SelectMUI>
    </FormControlMUI>
  );
};
