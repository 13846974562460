import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import CardTripPublicPage from "./Pages/CardTripPublicPage";
import HomePublicPage from "./Pages/HomePublicPage";
import SearchPublicPage from "./Pages/SearchPublicPage";
import { PublicContextProvider } from "./PublicContext";

export interface PublicLayoutProps {}

const PublicLayoutComponent: React.FC<PublicLayoutProps> = () => {
  return (
    <Switch>
      <Route exact path="/" render={() => <Redirect to="/public/home" />} />
      <Route
        exact
        path="/login"
        render={() => <Redirect to="/public/home" />}
      />
      <Route exact path="/public/home" component={HomePublicPage} />
      <Route exact path="/public/trip" component={CardTripPublicPage} />
      <Route exact path="/public/search" component={SearchPublicPage} />
    </Switch>
  );
};

const PublicLayout: React.FC = () => {
  return (
    <PublicContextProvider>
      <PublicLayoutComponent />
    </PublicContextProvider>
  );
};

export default PublicLayout;
