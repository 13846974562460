import React from "react";
import {
  ToolbarMUI,
  BodyPage,
  Separator,
  TypographyMUI,
  RatingMUI,
  ListItemCarousel,
  RenderListAsync,
  CardMUI,
} from "../../DeltaUI/Components";
import { UserContext } from "../../Components/User/UserContext";
import { IRate } from "../../Services/TripService";
import { UserService } from "../../Services/UserService";
import { Tools, useComponentMount } from "../../Utils";
import LoadData from "../../Components/LoadData";
import CardScoreUser from "../../Components/Card/CardScoreUser";
import CardUserStatistic from "../../Components/Card/CardUserStatistic";
import { ROLE_TYPE } from "../../Utils/Constants";
import { TitlePage } from "../../Components/TitlePage";
import { CustomPage, DialogCustomHook } from "../../Components";
import { GoBack } from "../../Components/GoBack";
import { UpoolContext } from "../../Providers/UpoolContext";

const ScoresUserPage: React.FC = () => {
  const isMountedComponent = useComponentMount("ScoresUserPage");
  const { rates } = UserService();
  const { messageError } = DialogCustomHook();
  const { isActiveApp } = React.useContext(UpoolContext);
  const { viewUser, scoresUserPageMode } = React.useContext(UserContext);
  const [ratesList, setRatesList] = React.useState<IRate[]>([]);
  const [loadingScoresUser, setLoadingScoresUser] = React.useState<boolean>(
    false
  );
  const { getPerson } = Tools();

  const person = React.useMemo(
    () => getPerson(viewUser, 0),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [viewUser]
  );

  const rate = React.useMemo(
    () =>
      scoresUserPageMode === ROLE_TYPE.DRIVER
        ? viewUser.rateAsDriver
        : viewUser.rateAsPassenger,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [viewUser]
  );

  const getData = () => {
    setLoadingScoresUser(() => true);
    rates(viewUser.id, scoresUserPageMode)
      .then((list) => {
        if (isMountedComponent.current) {
          setRatesList(() => list);
        }
      })
      .catch(({ message }) => {
        messageError({
          context: "ScoresUserPage.getData.1",
          message: message,
        });
      })
      .finally(() => setLoadingScoresUser(() => false));
  };

  React.useEffect(() => {
    if (isActiveApp) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActiveApp]);

  React.useEffect(() => {
    isMountedComponent.current = true;
    return () => {
      isMountedComponent.current = false;
    };
  }, [isMountedComponent]);

  return (
    <CustomPage
      headerPage={
        <ToolbarMUI>
          <GoBack />
          <TitlePage
            text={person ? `${person.firstName} ${person.lastName}` : ""}
          />
        </ToolbarMUI>
      }
    >
      <BodyPage m={3}>
        <Separator px={16} />
        <TypographyMUI className="TypographyExtrabold11pt">
          Calificación como{" "}
          {scoresUserPageMode === ROLE_TYPE.DRIVER ? "conductor" : "pasajero"}
        </TypographyMUI>
        <Separator px={8} />
        <CardMUI
          className="FlexRow ShadowCard8"
          elevation={7}
          style={{ padding: "8px 16px" }}
        >
          <RatingMUI
            style={{ alignItems: "center" }}
            readOnly
            value={rate}
            precision={0.1}
            sx={{
              "& .MuiRating-icon": {
                fontSize: "24pt !important",
              },
            }}
          />
          <TypographyMUI
            className="TypographyBold13pt"
            style={{ marginLeft: "16px" }}
          >
            {Number.parseFloat(`${rate}`).toFixed(1)}
          </TypographyMUI>
        </CardMUI>
        <Separator px={32} />
        <TypographyMUI className="TypographyExtrabold11pt">
          Estadísticas de viajes
        </TypographyMUI>
        <Separator px={8} />
        <CardUserStatistic
          user={viewUser}
          isDrive={scoresUserPageMode === ROLE_TYPE.DRIVER}
        />
        <Separator px={32} />
        <TypographyMUI className="TypographyExtrabold11pt">
          Referencias
        </TypographyMUI>
        <LoadData
          description="Aun no tiene calificaciones"
          empty={!ratesList.length}
          loading={loadingScoresUser}
          style={{ width: "100%" }}
        >
          <div style={{ margin: "auto -16px" }}>
            <ListItemCarousel separation={16}>
              <RenderListAsync
                list={ratesList.map((r) => (
                  <CardScoreUser
                    key={r.id}
                    rate={r}
                    isDrive={scoresUserPageMode === ROLE_TYPE.DRIVER}
                  />
                ))}
              />
            </ListItemCarousel>
          </div>
        </LoadData>
        <Separator px={16} />
      </BodyPage>
    </CustomPage>
  );
};

export default ScoresUserPage;
