import React from "react";
import { MapRoute } from "../../Components/Trip/MapRoute";
import { GoogleMaps } from "../../DeltaUI/Components/GoogleMapsApiReact";
import { useCustomLog } from "../../Utils";
import { AVAILABLE_SCREEN_SPACE_CSS } from "../../Utils/Constants";
import LoadData from "../LoadData";
import { MyTripsContext } from "../MyTrips/MyTripsContext";

interface MapTabProps {
  loading: boolean;
}

const MapTab: React.FC<MapTabProps> = ({ loading }) => {
  const { selectTripStretch } = React.useContext(MyTripsContext);
  const Log = useCustomLog();

  return (
    <LoadData
      description="No hay datos"
      empty={!selectTripStretch}
      loading={loading}
      height="40vh"
    >
      <GoogleMaps
        onError={({ message }) => {
          Log.error({
            context: "MapTab.GoogleMaps.1",
            message,
          });
        }}
        style={{
          width: "100vw",
          height: `calc(${AVAILABLE_SCREEN_SPACE_CSS} - 104px)`,
          position: "fixed",
          bottom: "var(--ios-action-footer-height)",
        }}
      >
        {selectTripStretch ? (
          <MapRoute
            originPlace={selectTripStretch.placeFrom}
            destinationPlace={selectTripStretch.placeTo}
          />
        ) : null}
      </GoogleMaps>
    </LoadData>
  );
};

export default MapTab;
