import React from "react";
import { ListItemMUI, ListItemIconMUI, ListItemTextMUI } from "..";

export interface MenuItemProps {
  onClick?: () => void;
  icon?: JSX.Element;
  text?: JSX.Element | string;
}

const MenuItem: React.FC<MenuItemProps> = (props) => {
  const { onClick, icon, text } = props;
  return (
    <ListItemMUI button onClick={onClick}>
      <ListItemIconMUI>{icon}</ListItemIconMUI>
      <ListItemTextMUI primary={text} />
    </ListItemMUI>
  );
};

export default MenuItem;
