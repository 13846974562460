import { gql } from "@apollo/client";
import { FragmentService } from "../FragmentService";

export const VEHICLE_BRANDS = gql`
  query vehicleBrands(
    $where: VehicleBrandWhereInput
    $orderBy: [VehicleBrandOrderByInput!]
    $first: Int
    $last: Int
    $before: VehicleBrandWhereUniqueInput
    $after: VehicleBrandWhereUniqueInput
  ) {
    vehicleBrands(
      where: $where
      orderBy: $orderBy
      first: $first
      last: $last
      before: $before
      after: $after
    ) {
      ...FVehicleBrand
    }
  }
  ${FragmentService.VehicleBrand}
`;

export const VEHICLE_MODELS = gql`
  query vehicleModels(
    $where: VehicleModelWhereInput
    $orderBy: [VehicleModelOrderByInput!]
    $first: Int
    $last: Int
    $before: VehicleModelWhereUniqueInput
    $after: VehicleModelWhereUniqueInput
  ) {
    vehicleModels(
      where: $where
      orderBy: $orderBy
      first: $first
      last: $last
      before: $before
      after: $after
    ) {
      ...FVehicleModel
    }
  }
  ${FragmentService.VehicleModel}
`;

export const VEHICLE_FUEL_TYPES = gql`
  query vehicleFuelTypes(
    $where: VehicleFuelTypeWhereInput
    $orderBy: [VehicleFuelTypeOrderByInput!]
    $first: Int
    $last: Int
    $before: VehicleFuelTypeWhereUniqueInput
    $after: VehicleFuelTypeWhereUniqueInput
  ) {
    vehicleFuelTypes(
      where: $where
      orderBy: $orderBy
      first: $first
      last: $last
      before: $before
      after: $after
    ) {
      ...FVehicleFuelType
    }
  }
  ${FragmentService.VehicleFuelType}
`;

export const COLOURS = gql`
  query colours(
    $where: ColourWhereInput
    $orderBy: [ColourOrderByInput!]
    $first: Int
    $last: Int
    $before: ColourWhereUniqueInput
    $after: ColourWhereUniqueInput
  ) {
    colours(
      where: $where
      orderBy: $orderBy
      first: $first
      last: $last
      before: $before
      after: $after
    ) {
      ...FVehicleColour
    }
  }
  ${FragmentService.Colour}
`;
