import React from "react";
import { AvatarMUI, BadgeMUI } from "../../DeltaUI/Components";
import { CameraAltOutlinedIcon } from "../../DeltaUI/Components/CustomIcon";
import { COLOR_TERTIARY } from "../../Utils/Constants";
import { OptionsCaptureImage } from "./OptionsCaptureImage";
import { EditPhotoWithCropper } from "./EditPhotoWithCropper";
import { UpoolContext } from "../../Providers/UpoolContext";

export interface UserUploadAvatarProps {
  src?: string;
  size?: 4 | 5 | 6 | 10 | 13 | 15;
  onChange?: (src: string) => void;
  style?: React.CSSProperties;
}

const UserUploadAvatar: React.FC<UserUploadAvatarProps> = (props) => {
  const { size, onChange, style, src } = props;
  const [viewUploadOptions, setViewUploadOptions] =
    React.useState<boolean>(false);
  const [editImageUrl, setEditImageUrl] = React.useState<string>("");
  const { setViewFullScreenImage } = React.useContext(UpoolContext);

  const onSave = (src: string) => {
    setEditImageUrl(() => "");
    if (onChange) {
      onChange(src);
    }
  };

  return (
    <>
      <BadgeMUI
        overlap="circular"
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        badgeContent={
          <AvatarMUI
            sx={{
              width: size ? `${size * 3.5}px` : undefined,
              height: size ? `${size * 3.5}px` : undefined,
              background: (theme) => theme.palette.secondary.main,
            }}
            onClick={() => setViewUploadOptions(() => true)}
          >
            <CameraAltOutlinedIcon
              style={{
                fontSize: size ? `${size / 7}rem` : undefined,
              }}
            />
          </AvatarMUI>
        }
      >
        <AvatarMUI
          style={{
            width: size ? `${size * 8}px` : undefined,
            height: size ? `${size * 8}px` : undefined,
            background: `${COLOR_TERTIARY}50`,
            ...style,
          }}
          src={src}
          onClick={() => setViewFullScreenImage(() => src)}
        >
          <CameraAltOutlinedIcon
            style={{
              color: COLOR_TERTIARY,
              fontSize: size ? `${size / 4}rem` : undefined,
            }}
          />
        </AvatarMUI>
        <OptionsCaptureImage
          open={viewUploadOptions}
          onClose={() => setViewUploadOptions(() => false)}
          onChange={(url) => setEditImageUrl(() => url)}
        />
      </BadgeMUI>

      {editImageUrl.length ? (
        <EditPhotoWithCropper
          imageUrl={editImageUrl}
          onSave={onSave}
          onCancel={() => setEditImageUrl(() => "")}
        />
      ) : null}
    </>
  );
};

export default UserUploadAvatar;
