import React from "react";
import { DirectionsRenderer, DirectionsService } from "@react-google-maps/api";

export interface SetRouteProps {
  onError?: (err: { message: string }) => void;
  origin:
    | google.maps.LatLng
    | {
        lat: number;
        lng: number;
      };
  destination:
    | google.maps.LatLng
    | {
        lat: number;
        lng: number;
      };
}

export const SetRoute: React.FC<SetRouteProps> = (props) => {
  const isMountedComponent = React.useRef<boolean>(false);
  const { origin, destination, onError } = props;
  const [key, setkey] = React.useState<string>();
  const [directions, setDirections] = React.useState<{
    result: google.maps.DirectionsResult | null;
    status: google.maps.DirectionsStatus;
  }>();

  const lat: number | undefined = React.useMemo(() => {
    if (typeof origin?.lat === "number") {
      return origin?.lat;
    }
    return origin?.lat();
  }, [origin]);

  const lng: number | undefined = React.useMemo(() => {
    if (typeof destination?.lat === "number") {
      return destination?.lat;
    }
    return destination?.lat();
  }, [destination]);

  const directionsCallback = (
    result: google.maps.DirectionsResult | null,
    status: google.maps.DirectionsStatus
  ) => {
    if (result) {
      if (status === "OK" && isMountedComponent.current) {
        setDirections({
          result,
          status,
        });
      } else {
        if (onError) {
          onError({
            message: `DirectionsService status: ${status}`,
          });
        }
      }
    }
  };

  React.useEffect(() => {
    setkey(() => `${new Date().getTime()}${lat}${lng}`);
    setDirections(() => undefined);
  }, [lat, lng]);

  React.useEffect(() => {
    isMountedComponent.current = true;
    return () => {
      isMountedComponent.current = false;
    };
  }, []);

  return (
    <>
      {key && !directions ? (
        <DirectionsService
          options={{
            destination,
            origin,
            travelMode: google.maps.TravelMode.DRIVING,
          }}
          callback={directionsCallback}
        />
      ) : null}
      {directions?.status === "OK" ? (
        <DirectionsRenderer
          options={{
            directions: directions.result,
            polylineOptions: {
              strokeColor: "#3F5AA6",
            },
            suppressMarkers: true,
          }}
        />
      ) : null}
    </>
  );
};
