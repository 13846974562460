import { gql } from "@apollo/client";
import { TAKE } from "../../Utils/Constants";

export const FragmentService = () => {};

FragmentService.Person = gql`
  fragment FPerson on Person {
    firstName
    lastName
    pictureUrl
    genderId
    birthdate
  }
`;

FragmentService.User = gql`
  fragment FUser on User {
    id
    email
    emailVerified
    phone
    phoneVerified
    lastConnection
    createdAt
    googleId
    facebookId
    appleId
    rateAsDriver
    rateAsPassenger
    rateAverage
    roleType
    tripsFinishedAsDriver
    reservationsFinishedAsDriver
    reservationsCanceledAsDriver
    reservationsFinishedAsPassenger
    reservationsCanceledAsPassenger
    person {
      ...FPerson
    }
  }
  ${FragmentService.Person}
`;

FragmentService.AuthPayload = gql`
  fragment FAuthPayload on AuthPayload {
    userId
    accessToken
    refreshToken
    expiration
  }
`;

FragmentService.ReservationChat = gql`
  fragment FReservationChat on ReservationChat {
    id
    description
    createdAt
    createdById
    sendedAt
    readedAt
    receivedAt
    externalId
    createdByUser {
      ...FUser
    }
  }
  ${FragmentService.User}
`;

FragmentService.Place = gql`
  fragment FPlace on Place {
    geoLocCoords
    id
    placeId
    description
    name
    sublocality
    locality
    admAreaLevel3
    admAreaLevel2
    admAreaLevel1
    country
    postalCode
  }
`;

FragmentService.Trip = gql`
  fragment FTrip on Trip {
    autoAcceptance
    description
    id
    ownerId
    routeId
    statusId
    vehicleId
    typeId
  }
`;

FragmentService.Rate = gql`
  fragment FRate on Rate {
    id
    reservationId
    userFromId
    userToId
    score
    description
    userFrom {
      ...FUser
    }
  }
  ${FragmentService.User}
`;

FragmentService.VehicleBrand = gql`
  fragment FVehicleBrand on VehicleBrand {
    id
    name
  }
`;

FragmentService.VehicleModel = gql`
  fragment FVehicleModel on VehicleModel {
    id
    name
    brandId
    brand {
      ...FVehicleBrand
    }
  }
  ${FragmentService.VehicleBrand}
`;

FragmentService.Colour = gql`
  fragment FVehicleColour on Colour {
    id
    name
    code
  }
`;

FragmentService.VehicleFuelType = gql`
  fragment FVehicleFuelType on VehicleFuelType {
    id
    name
    description
    referenceCost
  }
`;

FragmentService.Vehicle = gql`
  fragment FVehicle on Vehicle {
    id
    userId
    licensePlate
    description
    modelId
    deletedAt
    colour {
      ...FVehicleColour
    }
    model {
      ...FVehicleModel
    }
  }
  ${FragmentService.Colour}
  ${FragmentService.VehicleModel}
`;

FragmentService.Preference = gql`
  fragment FPreference on Preference {
    id
    name
    icon
    description
  }
`;

FragmentService.PreferenceTrip = gql`
  fragment FPreferenceTrip on PreferenceTrip {
    id
    preferenceId
    tripId
    enabled
    preference {
      ...FPreference
    }
  }
  ${FragmentService.Preference}
`;

FragmentService.PreferenceUser = gql`
  fragment FPreferenceUser on PreferenceUser {
    id
    preferenceId
    userId
    enabled
  }
`;

FragmentService.Reservation = gql`
  fragment FReservation on Reservation {
    id
    userId
    tripStretchId
    placeIdFrom
    placeIdTo
    statusId
    amount
    quantity
    confirmedByPassenger
    confirmedByDriver
    totalChats
    unreadChats
    deletedAt
    canceledMessage
    user {
      ...FUser
    }
    rates {
      ...FRate
    }
    tripStretch {
      trip {
        userOwner {
          ...FUser
        }
        statusId
      }
    }
    chats(last: ${TAKE.MESSAGES}) {
      ...FReservationChat
    }
  }
  ${FragmentService.Rate}
  ${FragmentService.User}
  ${FragmentService.ReservationChat}
`;

FragmentService.TripStretch = gql`
  fragment FTripStretch on TripStretch {
    amount
    availablePlaces
    usedPlaces
    totalPlaces
    dateFrom
    dateTo
    id
    meters
    placeIdFrom
    placeIdTo
    placeFrom {
      ...FPlace
    }
    placeTo {
      ...FPlace
    }
    trip {
      ...FTrip
      userOwner {
        ...FUser
      }
      vehicle {
        ...FVehicle
      }
      preferences {
        ...FPreferenceTrip
      }
    }
    reservations {
      ...FReservation
    }
  }
  ${FragmentService.Place}
  ${FragmentService.Trip}
  ${FragmentService.User}
  ${FragmentService.Vehicle}
  ${FragmentService.PreferenceTrip}
  ${FragmentService.Reservation}
`;

FragmentService.Notification = gql`
  fragment FNotification on Notification {
    id
    title
    message
    type
    payload
    sendedAt
    attendedAt
    readedAt
  }
`;

FragmentService.UserLogin = gql`
  fragment FUserLogin on User {
    ...FUser
    vehicles {
      ...FVehicle
    }
    preferences {
      ...FPreferenceUser
      preference {
        ...FPreference
      }
    }
  }
  ${FragmentService.User}
  ${FragmentService.Vehicle}
  ${FragmentService.PreferenceUser}
  ${FragmentService.Preference}
`;
