import React from "react";
import "./TimePicker.css";
import { BoxMUI } from "../..";
import Picker from "pickerjs";

interface TimePickerProps {
  style?: React.CSSProperties;
  className?: string;
  value?: Date;
  onChange?: (value: string | Date) => void;
  width?: number;
  color?: string;
}

export const TimePicker: React.FC<TimePickerProps> = (props) => {
  const { style, className, value, onChange, width, color } = props;
  const _width = width || 300;
  const _color = color || "red";
  const date = React.useRef<Date>(value || new Date());
  const element = React.useRef<HTMLDivElement | null>();
  const pickTime = React.useRef<Picker>();

  const handleOnChange = () => {
    if (pickTime.current && onChange) {
      onChange(pickTime.current.getDate());
    }
  };

  React.useEffect(() => {
    return () => {
      if (element.current) {
        element.current.removeEventListener("touchend", handleOnChange, false);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BoxMUI
      className={className}
      style={{
        ...style,
        position: "relative",
        padding: "16px",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <BoxMUI
        sx={{
          "& .TimePicker": {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            "& .picker-header, .picker-footer": {
              display: "none",
            },
            "& .picker-body .picker-grid.picker-multiple": {
              display: "flex",
              margin: 0,
              "& ul": {
                margin: 0,
                padding: 0,
                listStyleType: "none",
                "& li": {
                  width: `calc(${_width / 2}px - 16px)`,
                  padding: "4px 32px",
                  color: "inherit",
                },
                "& .picker-item.picker-picked": {
                  fontWeight: "bold",
                  fontSize: "large",
                  color: _color,
                },
              },
            },
          },
        }}
      >
        <div
          ref={(el) => {
            if (el && !element.current) {
              element.current = el;
              pickTime.current = new Picker(element.current, {
                format: "HH:mm",
                inline: true,
                controls: false,
                headers: false,
                increment: {
                  minute: 10,
                },
              });
              element.current.addEventListener(
                "touchend",
                handleOnChange,
                false
              );
            }
          }}
          className="TimePicker"
        >
          {date.current.getHours()}:{date.current.getMinutes()}
        </div>
      </BoxMUI>
      <div
        style={{
          position: "absolute",
          width: `calc(${_width}px - 32px)`,
          height: "2px",
          top: "43%",
          background: _color,
        }}
      />
      <div
        style={{
          position: "absolute",
          top: "44%",
          fontWeight: "bold",
          fontSize: "large",
          textAlign: "center",
          color: _color,
        }}
      >
        :
      </div>
      <div
        style={{
          position: "absolute",
          width: `calc(${_width}px - 32px)`,
          height: "2px",
          bottom: "43%",
          background: _color,
        }}
      />
    </BoxMUI>
  );
};
