import { Tools as ToolsDelta } from "../DeltaUI/Utils";
import { IPerson, IUser } from "../Services/UserService";
import { PERSON_GENDER, PREFERENCES } from "./Constants";
import { ITripStretch } from "../Services/TripService";

export const Tools = () => {
  const {
    notAvailable,
    filter,
    parseObj,
    getParamsURL,
    validateEmail,
    validatePhone,
    applyMaskPhone,
    removeMaskPhone,
    URLFileToBlob,
    URLFileToFile,
    blobToFile,
    validatePassword,
    validatePasswordWithSpecialCharacters,
    srcToHTMLImageElement,
    base64ToObjectURL,
    blobToBase64,
  } = ToolsDelta();

  const getPerson = (
    user: IUser | undefined,
    index: number
  ): IPerson | undefined => {
    if (user && Array.isArray(user.person) && user.person.length > index) {
      return user.person[index];
    }
    return undefined;
  };

  const copyObject = (list: any, deleteParameters?: string[]) => {
    if (typeof list !== "object") {
      throw new Error(
        "copyObject => Error: el parametro list debe ser un objeto"
      );
    }
    const result: any = {};
    if (deleteParameters && deleteParameters.length) {
      Object.keys(list).forEach((key) => {
        if (!deleteParameters.find((del) => del === key)) {
          if (typeof list[key] === "object") {
            result[key] = copyObject(list[key]);
          } else {
            result[key] = list[key];
          }
        }
      });
    }
    if (!deleteParameters || !deleteParameters.length) {
      Object.keys(list).forEach((key) => {
        if (typeof list[key] === "object") {
          result[key] = copyObject(list[key]);
        } else {
          result[key] = list[key];
        }
      });
    }
    return result;
  };

  const metersToKM = (value?: number): number | undefined => {
    if (!value) {
      return undefined;
    }
    return Math.round(value / 1000);
  };

  const isGenderF = (person?: IPerson) => {
    if (person && person.genderId === PERSON_GENDER.F) {
      return true;
    }
    return false;
  };

  const tripStretchIsOnlyWomen = (tripStretch?: ITripStretch) => {
    return tripStretch
      ? !!tripStretch.trip.preferences.find(
          (pUser) => pUser.preferenceId === PREFERENCES.ONLY_WOMEN
        )
      : true;
  };

  const isUpToIPhone8 = (model: string) => {
    return !![
      "iPhone3,1",
      "iPhone3,2",
      "iPhone3,3",
      "iPhone4,1",
      "iPhone5,1",
      "iPhone5,2",
      "iPhone5,3",
      "iPhone5,4",
      "iPhone6,1",
      "iPhone6,2",
      "iPhone7,2",
      "iPhone7,1",
      "iPhone8,1",
      "iPhone8,2",
      "iPhone9,1",
      "iPhone9,3",
      "iPhone9,2",
      "iPhone9,4",
      "iPhone10,1",
      "iPhone10,4",
      "iPhone10,2",
      "iPhone10,5",
    ].find((m) => m === model);
  };

  return {
    notAvailable,
    filter,
    parseObj,
    getParamsURL,
    validateEmail,
    validatePhone,
    applyMaskPhone,
    removeMaskPhone,
    URLFileToBlob,
    URLFileToFile,
    blobToFile,
    validatePassword,
    validatePasswordWithSpecialCharacters,
    getPerson,
    copyObject,
    metersToKM,
    isGenderF,
    tripStretchIsOnlyWomen,
    srcToHTMLImageElement,
    base64ToObjectURL,
    isUpToIPhone8,
    blobToBase64,
  };
};
