import { gql } from "@apollo/client";
import { FragmentService } from "../FragmentService";

export const CREATE_ONE_VEHICLE = gql`
  mutation createOneVehicle($data: VehicleCreateInput!) {
    createOneVehicle(data: $data) {
      ...FVehicle
    }
  }
  ${FragmentService.Vehicle}
`;

export const DELETE_ONE_VEHICLE = gql`
  mutation deleteOneVehicle($data: IdInput!) {
    deleteOneVehicle(data: $data) {
      id
    }
  }
`;
