import React from "react";
import {
  IPreferenceUser,
  PreferenceService,
} from "../Services/PreferenceService";
import { useComponentMount, useCustomLog } from "../Utils";
import { UpoolContext } from "./UpoolContext";

interface IPreferenceUserContext {
  preferencesUser: IPreferenceUser[];
}

const context: IPreferenceUserContext = {
  preferencesUser: [],
};

export const PreferenceUserContext = React.createContext(context);

export interface PreferenceUserContextProviderProps {}

export const PreferenceUserContextProvider: React.FC<PreferenceUserContextProviderProps> = (
  props
) => {
  const isMountedComponent = useComponentMount("PreferenceUserContextProvider");
  const { user, person } = React.useContext(UpoolContext);
  const { getPreferences } = PreferenceService();
  const Log = useCustomLog();

  const [preferencesUser, setPreferencesUser] = React.useState<
    IPreferenceUser[]
  >([]);

  const genderId = React.useMemo(() => {
    return person?.genderId;
  }, [person]);

  const loadPreferencesUserIcons = async (index: number) => {
    const pUser = preferencesUser[index];
    if (pUser) {
      const r = await fetch(pUser.preference.icon);
      const text = await r.text();
      if (isMountedComponent.current) {
        setPreferencesUser((l) => {
          if (l[index]) {
            l[index].preference.icon = text;
          }
          return l;
        });
        loadPreferencesUserIcons(index + 1);
      }
    }
  };

  const loadPreferencesUser = async () => {
    setPreferencesUser(() => []);
    getPreferences(true)
      .then((res) => {
        if (isMountedComponent.current && Array.isArray(res) && user) {
          setPreferencesUser(() =>
            res.map((element) => ({
              id: undefined,
              preferenceId: element.id,
              userId: user.id,
              enabled: false,
              preference: element,
            }))
          );
        }
      })
      .catch(({ message }) => {
        Log.error({
          context: "PreferenceUserContextProvider.loadPreferencesUser.1",
          message,
        });
      });
  };

  React.useEffect(() => {
    if (user && preferencesUser.length) {
      loadPreferencesUserIcons(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preferencesUser]);

  React.useEffect(() => {
    if (user) {
      loadPreferencesUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [genderId]);

  React.useEffect(() => {
    isMountedComponent.current = true;
    return () => {
      isMountedComponent.current = false;
    };
  }, [isMountedComponent]);

  return (
    <PreferenceUserContext.Provider
      value={{
        preferencesUser,
      }}
    >
      {props.children}
    </PreferenceUserContext.Provider>
  );
};
