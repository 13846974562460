import React from "react";
import {
  BoxMUI,
  InputMoney,
  LinkMUI,
  ListItemMUI,
  ListItemTextMUI,
  Separator,
  TypographyMUI,
} from "../../DeltaUI/Components";
import {
  DriveEtaIcon,
  MonetizationOnIcon,
  TaxiAlertOutlinedIcon,
} from "../../DeltaUI/Components/CustomIcon";
import {
  COLOR_TERTIARY,
  TRIP_TYPE,
  VIEW_MAX_PREFERENCES,
  COLOR_QUINARY,
} from "../../Utils/Constants";
import { SquareButton } from "../Button/SquareButton";
import TextArea from "../Form/TextArea";
import ActionSheetPreferencesPage from "../User/ActionSheetPreferencesPage";
import PreferencesList from "../User/PreferencesList";
import { CreateTripContext } from "./CreateTripContext";
import { CreateTripNextButton } from "./CreateTripNextButton";
import { PreferenceUserContext } from "../../Providers/PreferenceUserContextProvider";

export interface CreateTripPreferenceProps {
  onNext: () => void;
}

const CreateTripPreference: React.FC<CreateTripPreferenceProps> = (props) => {
  const { onNext } = props;
  const {
    description,
    setDescription,
    amount,
    setAmount,
    isMessageAmountSuggested,
    setIsMessageAmountSuggested,
    amountError,
    setAmountError,
    amountMin,
    amountMax,
    tripTypeId,
    setTripTypeId,
  } = React.useContext(CreateTripContext);
  const { preferencesUser } = React.useContext(PreferenceUserContext);

  const [viewAllPreferences, setViewAllPreferences] =
    React.useState<boolean>(false);
  const isRemis = React.useMemo(() => {
    return tripTypeId === TRIP_TYPE.REMIS;
  }, [tripTypeId]);

  const isAmountMax = React.useMemo(() => {
    if (isRemis) {
      return amountMax ? amountMax * 10 : undefined;
    }
    return amountMax;
  }, [amountMax, isRemis]);

  const isAmountMin = React.useMemo(() => {
    return isRemis ? 10 : amountMin;
  }, [amountMin, isRemis]);

  const alertMin = React.useMemo(() => {
    if (amount && isAmountMin && amount < isAmountMin) {
      return !isRemis
        ? `El precio debe ser mayor a $${isAmountMin}`
        : "El precio es demasiado bajo";
    }
    return null;
  }, [amount, isAmountMin, isRemis]);

  const alertMax = React.useMemo(() => {
    if (amount && isAmountMax && amount > isAmountMax) {
      return !isRemis
        ? `El precio debe ser menor a $${isAmountMax}`
        : "El precio es demasiado alto";
    }
    return null;
  }, [amount, isAmountMax, isRemis]);

  return (
    <>
      <BoxMUI
        sx={{
          padding: "24px",
        }}
      >
        <TypographyMUI className="TypographyExtrabold11pt">
          Tipo de conductor
        </TypographyMUI>
        <Separator px={16} />
        <BoxMUI className="FlexRow">
          <SquareButton
            active={!isRemis}
            label="Particular"
            icon={<DriveEtaIcon />}
            onClick={() => setTripTypeId(() => TRIP_TYPE.PARTICULAR)}
          />
          <Separator px={16} orientation="vertical" />
          <SquareButton
            active={isRemis}
            label="Remís"
            icon={<TaxiAlertOutlinedIcon />}
            onClick={() => setTripTypeId(() => TRIP_TYPE.REMIS)}
          />
        </BoxMUI>

        <Separator px={32} />

        <TypographyMUI className="TypographyExtrabold11pt">
          Precio por persona
        </TypographyMUI>
        <Separator px={16} />
        <BoxMUI className="ContentInputCustom">
          <InputMoney
            className="InputMoney"
            value={`${amount || ""}`}
            onChange={(ev) => {
              setIsMessageAmountSuggested(() => false);
              setAmount(() => ev);
            }}
            iconLeft={<MonetizationOnIcon style={{ color: COLOR_TERTIARY }} />}
            decimalSeparator="."
            max={isAmountMax}
            min={isAmountMin}
            onError={(s) => setAmountError(() => s)}
          />
        </BoxMUI>

        <BoxMUI style={{ paddingTop: "8px" }}>
          {isMessageAmountSuggested ? (
            <TypographyMUI color={COLOR_QUINARY}>
              Te sugerimos un precio de ${amount}
            </TypographyMUI>
          ) : (
            <TypographyMUI color={COLOR_QUINARY}>
              {alertMin || alertMax || ""}
            </TypographyMUI>
          )}
        </BoxMUI>

        <Separator px={24} />

        <ListItemMUI
          button
          style={{
            padding: 0,
          }}
        >
          <ListItemTextMUI
            style={{ width: "100%" }}
            primary={
              <TypographyMUI className="TypographyExtrabold11pt">
                Se permite
              </TypographyMUI>
            }
          />
          <Separator px={16} />
          {preferencesUser.length > VIEW_MAX_PREFERENCES ? (
            <LinkMUI
              component="button"
              onClick={() => setViewAllPreferences(() => true)}
            >
              Ver más
            </LinkMUI>
          ) : null}
        </ListItemMUI>
        <PreferencesList
          style={{ marginRight: "-8px" }}
          color="primary"
          max={VIEW_MAX_PREFERENCES}
        />
        <ActionSheetPreferencesPage
          open={viewAllPreferences}
          onClose={() => setViewAllPreferences(() => false)}
        />

        <Separator px={32} />

        <TypographyMUI className="TypographyExtrabold11pt">
          Información adicional
        </TypographyMUI>
        <Separator px={16} />
        <BoxMUI
          className="ContentInputCustom"
          style={{ padding: "0 4px", color: COLOR_QUINARY }}
        >
          <TextArea
            placeholder="Agregá lo que quieras..."
            value={description}
            onChange={(ev) => setDescription(() => ev)}
          />
        </BoxMUI>
      </BoxMUI>
      <CreateTripNextButton disabled={amountError} onClick={onNext} />
    </>
  );
};

export default CreateTripPreference;
