import React from "react";
import { EnterCodePhone } from "./EnterCodePhone";
import { EnterCodeEmail } from "./EnterCodeEmail";
import { RegisterContext } from "./RegisterContext";

interface EnterCodeProps {}

export const EnterCode: React.FC<EnterCodeProps> = () => {
  const { userToRegister } = React.useContext(RegisterContext);

  return (
    <>
      {userToRegister?.valueType === "phone" ? <EnterCodePhone /> : null}
      {userToRegister?.valueType === "email" ? <EnterCodeEmail /> : null}
    </>
  );
};
