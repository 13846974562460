import React from "react";
import { Geolocation, Position } from "@capacitor/geolocation";

/**
 * Obtiene las coordenadas de la posición actual del usuario
 */
export const CurrentPosition = (options?: PositionOptions | undefined) => {
  const isMountedComponent = React.useRef<boolean>(false);
  const [currentPosition, setCurrentPosition] = React.useState<Position>();
  const [errorPosition, setErrorPosition] = React.useState<Error>();
  const [loadingPosition, setLoadingPosition] = React.useState<boolean>(false);

  const handleOk = (r: Position) => {
    if (isMountedComponent.current) {
      setLoadingPosition(() => false);
      setCurrentPosition(() => r);
    }
  };

  const handleError = (e: Error) => {
    if (isMountedComponent.current) {
      setLoadingPosition(() => false);
      setErrorPosition(() => e);
    }
  };

  const updatePosition = async () => {
    setLoadingPosition(() => true);
    Geolocation.getCurrentPosition(options).then(handleOk).catch(handleError);
  };

  React.useEffect(() => {
    updatePosition();
    isMountedComponent.current = true;
    return () => {
      isMountedComponent.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  return {
    currentPosition,
    errorPosition,
    loadingPosition,
    updatePosition,
  };
};
