import React from "react";
import { IconButtonMUI, TypographyMUI } from "../DeltaUI/Components";
import { AddIcon, RemoveIcon } from "../DeltaUI/Components/CustomIcon";

const COUNT_DEFAULT = {
  min: 1,
  max: 4,
};
export interface AvailablePlacesProps {
  style?: React.CSSProperties;
  className?: string;
  value: number;
  onChange: (value: number) => void;
  min?: number;
  max?: number;
}

const AvailablePlaces: React.FC<AvailablePlacesProps> = (props) => {
  const { value, onChange, style, className, min, max } = props;

  return (
    <div
      className={className}
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        padding: "8px 16px",
        border: "1px solid #d3d3d3",
        borderRadius: "10px",
        ...style,
      }}
    >
      <IconButtonMUI
        color="secondary"
        onClick={() => {
          if (min) {
            onChange(value > min ? value - 1 : value);
          } else {
            onChange(value > COUNT_DEFAULT.min ? value - 1 : value);
          }
        }}
      >
        <RemoveIcon style={{ fontSize: "35px" }} />
      </IconButtonMUI>
      <TypographyMUI className="TypographyBold30pt">{value}</TypographyMUI>
      <IconButtonMUI
        color="secondary"
        onClick={() => {
          if (max) {
            onChange(value < max ? value + 1 : value);
          } else {
            onChange(value < COUNT_DEFAULT.max ? value + 1 : value);
          }
        }}
      >
        <AddIcon style={{ fontSize: "35px" }} />
      </IconButtonMUI>
    </div>
  );
};

export default AvailablePlaces;
