import React from "react";
import { DialogCustomHook } from "..";
import { UpoolContext } from "../../Providers/UpoolContext";
import { SetterState } from "../../Services/DefaultTypes";
import { Tools, useComponentMount, useCustomLog } from "../../Utils";

export enum REGISTER_STEPS {
  FORM,
  ENTRE_CODE,
  PASSWORD,
  SUPLEMENTARY_DATA,
}

interface IRegisterUser {
  firstName?: string;
  lastName?: string;
  emailOrPhone?: string;
  valueType?: "email" | "phone";
  verified?: boolean;
  password?: string;
  birthdate?: Date;
  genderId?: number;
  pictureUrl?: string;
  pictureFile?: File;
  googleId?: string;
  facebookId?: string;
  appleId?: string;
  verificationToken?: string;
}

interface IRegister {
  step: number;
  setStep: SetterState<number>;
  userToRegister: IRegisterUser | undefined;
  setUserToRegister: (values: IRegisterUser | undefined) => void;
  goBackRegister: () => void;
}

const context: IRegister = {
  step: REGISTER_STEPS.FORM,
  setStep: () => {},
  userToRegister: undefined,
  setUserToRegister: () => {},
  goBackRegister: () => {},
};

export const RegisterContext = React.createContext(context);

export interface RegisterProviderProps {}

export const RegisterProvider: React.FC<RegisterProviderProps> = (props) => {
  const isMountedComponent = useComponentMount("RegisterProvider");
  const {
    historyGoBack,
    createAccountWithUserSocialNetworks,
    setCreateAccountWithUserSocialNetworks,
  } = React.useContext(UpoolContext);
  const { message } = DialogCustomHook();
  const { URLFileToFile } = Tools();
  const Log = useCustomLog();
  const [step, setStep] = React.useState<number>(REGISTER_STEPS.FORM);
  const [userToRegister, updateUserToRegister] = React.useState<
    IRegisterUser | undefined
  >();

  const setUserToRegister = (values: IRegisterUser | undefined) => {
    updateUserToRegister((v) => (v ? { ...v, ...values } : values));
  };

  const goBackRegister = () => {
    message({
      context: "RegisterProvider.goBackRegister.1",
      title: "¿Querés cancelar el registro?",
      message: "Se perderán todos los datos ingresados.",
      onOk: historyGoBack,
      textOnOk: "Sí",
      textOnCancel: "No",
    });
  };

  const pictureUrl = React.useMemo(() => {
    return userToRegister?.pictureUrl;
  }, [userToRegister]);

  const pictureFile = React.useMemo(() => {
    return userToRegister?.pictureFile;
  }, [userToRegister]);

  React.useEffect(() => {
    if (pictureUrl && !pictureFile) {
      URLFileToFile(
        pictureUrl,
        `${userToRegister?.firstName} ${userToRegister?.lastName}`
      )
        .then((file) => {
          setUserToRegister({
            pictureFile: file,
          });
        })
        .catch(({ message }) => {
          Log.error({
            context: "RegisterProvider.useEffect.1",
            message,
          });
        });
    }
    // TODO: chequear estas dependencias
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pictureUrl]);

  React.useEffect(() => {
    if (createAccountWithUserSocialNetworks) {
      setUserToRegister({
        firstName: createAccountWithUserSocialNetworks.firstName,
        lastName: createAccountWithUserSocialNetworks.lastName,
        emailOrPhone: "",
        valueType: "email",
        verified: true,
        password: undefined,
        birthdate: undefined,
        genderId: undefined,
        pictureUrl: createAccountWithUserSocialNetworks.pictureUrl,
        pictureFile: undefined,
        googleId: createAccountWithUserSocialNetworks.googleId,
        facebookId: createAccountWithUserSocialNetworks.facebookId,
        appleId: createAccountWithUserSocialNetworks.appleId,
      });
    }
    return () => {
      setCreateAccountWithUserSocialNetworks(() => undefined);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    isMountedComponent.current = true;
    return () => {
      isMountedComponent.current = false;
    };
  }, [isMountedComponent]);

  return (
    <RegisterContext.Provider
      value={{
        step,
        setStep,
        userToRegister,
        setUserToRegister,
        goBackRegister,
      }}
    >
      {props.children}
    </RegisterContext.Provider>
  );
};
