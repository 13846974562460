import React from "react";
import {
  SignInWithApple,
  AppleSignInResponse,
  AppleSignInErrorResponse,
  ASAuthorizationAppleIDRequest,
} from "@awesome-cordova-plugins/sign-in-with-apple";
import { UpoolContext } from "../../Providers/UpoolContext";
import { AppleLoginButton } from "../../DeltaUI/Components";
import { UserService } from "../../Services/UserService";
import { DialogCustomHook } from "../DialogCustom";

import { ERROR_CODE } from "../../Utils/Constants";
import { isIos } from "../../DeltaUI/Utils/Constants";

export interface AppleLoginProps {
  login: boolean;
  style?: React.CSSProperties;
  text?: string;
  fab?: boolean;
}

const AppleLogin: React.FC<AppleLoginProps> = (props) => {
  const { style, text, login, fab } = props;
  const { messageError, message } = DialogCustomHook();
  const { loginApple, signupApple, associateAppleAccount } = UserService();
  const { setLoading } = React.useContext(UpoolContext);

  const handleLoginApple = () => {
    if (isIos) {
      setLoading(() => true);
      SignInWithApple.signin({
        requestedScopes: [
          ASAuthorizationAppleIDRequest.ASAuthorizationScopeFullName,
          ASAuthorizationAppleIDRequest.ASAuthorizationScopeEmail,
        ],
      })
        .then((res: AppleSignInResponse) => {
          if (login) {
            loginApple(res).catch((err1) => {
              setLoading(() => false);
              if (
                err1.code === ERROR_CODE.USER_NOT_EXIST ||
                err1.code === ERROR_CODE.USER_EXIST
              ) {
                message({
                  title: "¡Registrarme!",
                  context: "AppleLogin.handleLoginApple.1",
                  message: err1?.message,
                  textOnOk: "Continuar",
                  onOk: () => {
                    setLoading(() => true);
                    signupApple(res)
                      .catch((err2) => {
                        messageError({
                          context: "AppleLogin.handleLoginApple.2",
                          message: err2.message,
                        });
                      })
                      .finally(() => setLoading(() => false));
                  },
                  onCancel: () => {},
                });
              } else {
                messageError({
                  context: "AppleLogin.handleLoginApple.3",
                  message: err1.message,
                });
              }
            });
          } else {
            associateAppleAccount(res)
              .then(() => {
                message({
                  context: "AppleLogin.handleLoginApple.4",
                  title: "¡Listo!",
                  message: "La cuenta de Apple se asocio correctamente.",
                });
              })
              .catch((err3) => {
                messageError({
                  context: "AppleLogin.handleLoginApple.5",
                  message: err3.message,
                });
              })
              .finally(() => {
                setLoading(() => false);
              });
          }
        })
        .catch((err4: AppleSignInErrorResponse) => {
          setLoading(() => false);
          messageError({
            context: "AppleLogin.handleLoginApple.6",
            message: "No se realizó el login con Apple.",
            messageError: JSON.stringify(err4),
          });
        });
    }
  };

  return (
    <AppleLoginButton
      style={style}
      onClick={handleLoginApple}
      text={text}
      fab={fab}
    />
  );
};

export default AppleLogin;
