import { gql } from "@apollo/client";
import { FragmentService } from "../FragmentService";

export const NOTIFICATION_CREATED = gql`
  subscription notificationCreated {
    notificationCreated {
      ...FNotification
    }
  }
  ${FragmentService.Notification}
`;
