import React from "react";
import { BoxMUI, PaperMUI } from "..";

export interface IStep {
  id: number;
  icon: JSX.Element;
  completed: boolean;
}

interface StepperProps {
  style?: React.CSSProperties;
  className?: string;
  list: IStep[];
  size?: "small" | "medium" | "large";
  color?: "primary" | "secondary";
  value: number;
  onChange?: (stepId: number) => void;
}

export const Stepper: React.FC<StepperProps> = (props) => {
  const { style, className, list, color, onChange, value } = props;

  return (
    <BoxMUI
      style={style}
      sx={{
        width: "100%",
        height: "56px",
        padding: "4px 24px",
        borderRadius: 0,
        background: "white",
      }}
      className={className}
    >
      <BoxMUI
        style={style}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        className={className}
      >
        {list.length
          ? list.map((el, i) => (
              <StepContent
                key={el.id}
                onClick={() => {
                  if (onChange) {
                    onChange(el.id);
                  }
                }}
                active={el.id === value}
                complete={el.completed}
                inProgress={i === 0 || (i > 0 && list[i - 1].completed)}
                end={list.length - 1 === i}
                color={color}
              >
                {el.icon}
              </StepContent>
            ))
          : null}
      </BoxMUI>
    </BoxMUI>
  );
};

interface StepContentProps {
  end?: boolean;
  color?: "primary" | "secondary";
  active?: boolean;
  inProgress?: boolean;
  complete?: boolean;
  onClick?: () => void;
}

export const StepContent: React.FC<StepContentProps> = (props) => {
  const { children, end, color, active, inProgress, complete, onClick } = props;
  return (
    <>
      <PaperMUI
        elevation={active ? 6 : 0}
        style={{
          marginTop: active ? "8px" : 0,
        }}
      >
        <BoxMUI
          onClick={onClick}
          sx={{
            minWidth: "30px",
            height: "30px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "inherit",
            background: (theme) => theme.palette[color || "primary"].main,
            color: (theme) => theme.palette[color || "primary"].contrastText,
            opacity: complete || inProgress ? 1 : 0.4,
          }}
        >
          {children}
        </BoxMUI>
      </PaperMUI>
      {!end ? (
        <BoxMUI
          sx={{
            width: "100%",
            height: "2px",
            background: (theme) => theme.palette[color || "primary"].main,
            opacity: complete ? 1 : 0.4,
          }}
        />
      ) : null}
    </>
  );
};
