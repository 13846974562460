import React from "react";
import { Icons } from "../../Assets";
import { AvatarMUI, BadgeMUI, PaperMUI } from "../../DeltaUI/Components";
import { IUser } from "../../Services/UserService";
import { MomentJS, Tools } from "../../Utils";

export interface UserAvatarProps {
  user?: IUser;
  size?: 4 | 5 | 6 | 10 | 13 | 15;
  sizeDot?: 8 | 12 | 16;
  onClick?: () => void;
  style?: React.CSSProperties;
  elevation?: number;
}

const UserAvatar: React.FC<UserAvatarProps> = (props) => {
  const { user, size, onClick, style, sizeDot, elevation } = props;
  const { getPerson } = Tools();
  const { addToDate } = MomentJS();

  const person = React.useMemo(
    () => getPerson(user, 0),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user]
  );

  /**
   * La conexion se actualiza cada 2 minutos, le sumamos 1 minuto para que no alcanse a desconectarse enseguida
   */
  const active = React.useMemo(
    () =>
      user?.lastConnection
        ? addToDate(3, "m", user?.lastConnection).toDate() >= new Date()
        : false,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user]
  );

  return (
    <BadgeMUI
      overlap="circular"
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      variant="dot"
      sx={{
        "& .MuiBadge-badge": {
          boxShadow: (theme) => `0 0 0 2px ${theme.palette.background.paper}`,
          backgroundColor: `${!active ? "#bdbdbd" : "#44b700"}`,
          color: `${!active ? "#bdbdbd" : "#44b700"}`,
          minWidth: `${sizeDot || 8}px`,
          height: `${sizeDot || 8}px`,
          borderRadius: "50%",
          "&::after": {
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            borderRadius: "50%",
            animation: "ripple 1.2s infinite ease-in-out",
            border: "1px solid currentColor",
            content: '""',
          },
        },
      }}
    >
      <PaperMUI
        elevation={elevation || 0}
        style={{
          padding: "1px",
          borderRadius: "50%",
          background: "#bdbdbd",
        }}
      >
        <AvatarMUI
          style={{
            width: size ? `${size * 8}px` : undefined,
            height: size ? `${size * 8}px` : undefined,
            background: `url(${Icons.personIcon})`,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            ...style,
          }}
          onClick={onClick}
          src={person?.pictureUrl}
        />
      </PaperMUI>
    </BadgeMUI>
  );
};

export default UserAvatar;
