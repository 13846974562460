import React from "react";
import { BoxMUI, TypographyMUI } from "../../DeltaUI/Components";
import { IPlace } from "../../Services/PlaceService";
import { useCustomLog } from "../../Utils";
import { COLOR_QUINARY } from "../../Utils/Constants";
export interface ListItemTextDescriptionPlaceProps {
  place?: IPlace | google.maps.places.PlaceResult;
  distanceMts: string;
}

const ListItemTextDescriptionPlace: React.FC<ListItemTextDescriptionPlaceProps> = (
  props
) => {
  const { place, distanceMts } = props;
  const Log = useCustomLog();

  const description = React.useMemo(() => {
    try {
      const p = place as {
        formatted_address?: string;
        description?: string;
      };
      if (p?.formatted_address) {
        const l1 = p.formatted_address.split(",").map((el) => el.trim());
        const l2 = l1.filter((item, index) => l1.indexOf(item) === index);
        const l3: string[] = [l2[l2.length - 2], l2[l2.length - 1]];
        const l4: string[] = place?.name ? [place?.name, ...l3] : l3;
        return l4.filter((item, index) => l4.indexOf(item) === index);
      }
      if (p?.description) {
        const l1 = p.description.split(",").map((el) => el.trim());
        const l2 = l1.filter((item, index) => l1.indexOf(item) === index);
        const l3: string[] = [l2[l2.length - 2], l2[l2.length - 1]];
        const l4: string[] = place?.name ? [place?.name, ...l3] : l3;
        return l4.filter((item, index) => l4.indexOf(item) === index);
      }
      return [];
    } catch (error) {
      Log.error({
        context: "",
        message: "",
      });
      return [];
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [place]);

  const description1 = React.useMemo(() => {
    if (description.length) {
      return `${description[0]}`;
    }
    return "";
  }, [description]);

  const description2 = React.useMemo(() => {
    if (description.length > 2) {
      return `${description[1]}, ${description[2]}${distanceMts}`;
    } else if (description.length > 1) {
      return `${description[1]}${distanceMts}`;
    }
    return "";
  }, [description, distanceMts]);

  if (description.length) {
    return (
      <BoxMUI>
        <TypographyMUI
          className="TypographyBold11pt"
          style={{
            width: "67vw",
            marginTop: "-4px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {description1}
        </TypographyMUI>
        <TypographyMUI
          color={COLOR_QUINARY}
          className="TypographyRegular10pt"
          style={{
            width: "67vw",
            lineHeight: 1.1,
          }}
        >
          {description2}
        </TypographyMUI>
      </BoxMUI>
    );
  }
  return null;
};

/**
 *  admAreaLevel1: "Entre Ríos"
    admAreaLevel2: "Colón"
    admAreaLevel3: null
    country: "Argentina"
    description: "12 de Abril 1277, E3280 Colón, Entre Ríos, Argentina"
    geoLocCoords: "-58.15549629999999;-32.2330903"
    id: 6
    locality: "Colón"
    name: "Colon. EntreRios"
    placeId: "ChIJpUGG164zrpUR0jQHANw5EH8"
    postalCode: "E3280"
    sublocality: null
 */
export default ListItemTextDescriptionPlace;
