import React from "react";
import { ButtonMUI } from "../../DeltaUI/Components";

interface OnboardingButtonSkipProps {
  style?: React.CSSProperties;
  className?: string;
  onClick?: () => void;
}

export const OnboardingButtonSkip: React.FC<OnboardingButtonSkipProps> = (
  props
) => {
  const { style, className, onClick } = props;

  return (
    <ButtonMUI
      style={{
        position: "absolute",
        right: 0,
        top: "var(--mobile-status-bar-height)",
        zIndex: 2,
        padding: "24px",
        ...style,
      }}
      variant="text"
      onClick={onClick}
      className={className}
    >
      Saltear
    </ButtonMUI>
  );
};
