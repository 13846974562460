import React from "react";
import { COMPILATION_TYPE, config } from "../../config";
import { UpoolContext } from "../../Providers/UpoolContext";
import { ShareService } from "../../Services/ShareService";
import { useComponentMount, useCustomLog } from "../../Utils";
import { APPLICATION_PATHS } from "../../Utils/Constants";
import { SearchTripContext } from "./SearchTripContext";

interface RecreateSearchAdvancedProps {
  s: string;
}

export const RecreateSearchAdvanced: React.FC<RecreateSearchAdvancedProps> = (
  props
) => {
  const isMountedComponent = useComponentMount("RecreateSearchAdvanced");
  const { s } = props;

  const { share } = ShareService();
  const Log = useCustomLog();
  const { setLoading, historyPush } = React.useContext(UpoolContext);
  const {
    setOriginPlace,
    setDestinationPlace,
    setOrderByNumber,
    setAvailablePlaces,
    setDateFrom,
    setDateTo,
    setRadiusFrom,
    setRadiusTo,
    setTypeId,
    setFreeSeatMiddle,
    setPreferencesIncluded,
    search,
    setInitialDate,
    loadingSearchTripResults,
    setLoadingSearchTripResults,
  } = React.useContext(SearchTripContext);
  const [eventSearch, setEventSearch] = React.useState<number>(0);

  const shareId = React.useMemo(() => {
    const v = Number(s);
    if (v) {
      return v;
    }
    return undefined;
  }, [s]);

  const init = () => {
    if (shareId) {
      setLoading(() => true);
      share(shareId)
        .then((res) => {
          if (isMountedComponent.current && res) {
            const s: {
              originPlace: {
                formatted_address: string | undefined;
                geometry: {
                  location: google.maps.LatLng | undefined;
                };
                name: string | undefined;
                place_id: string | undefined;
              };
              destinationPlace: {
                formatted_address: string | undefined;
                geometry: {
                  location: google.maps.LatLng | undefined;
                };
                name: string | undefined;
                place_id: string | undefined;
              };
              orderByNumber: number | undefined;
              availablePlaces: number;
              dateFromNumber: number | undefined;
              dateToNumber: number | undefined;
              radiusFrom: number | undefined;
              radiusTo: number | undefined;
              preferencesIncludedId: number[];
              typeId: number | undefined;
              freeSeatMiddle: boolean;
              initialDateNumber: number | undefined;
            } = JSON.parse(res.description);

            const op: any = s.originPlace;
            const dp: any = s.destinationPlace;

            setOriginPlace(() => ({
              ...op,
              geometry: {
                location: {
                  lat: () => op.geometry.location.lat,
                  lng: () => op.geometry.location.lng,
                },
              },
            }));
            setDestinationPlace(() => ({
              ...dp,
              geometry: {
                location: {
                  lat: () => dp.geometry.location.lat,
                  lng: () => dp.geometry.location.lng,
                },
              },
            }));

            if (s.initialDateNumber) {
              const d = new Date(s.initialDateNumber);
              setInitialDate(() => d);
            }
            setAvailablePlaces(() => s.availablePlaces);

            if (COMPILATION_TYPE.MOBILE === config.compilationMode) {
              historyPush(`${APPLICATION_PATHS.SEARCH_TRIP}/result`);
            }

            // advanced filters
            setOrderByNumber(() => s.orderByNumber);
            if (s.dateFromNumber) {
              const d = new Date(s.dateFromNumber);
              setDateFrom(() => d);
            }
            if (s.dateToNumber) {
              const d = new Date(s.dateToNumber);
              setDateTo(() => d);
            }
            setRadiusFrom(() => s.radiusFrom);
            setRadiusTo(() => s.radiusTo);
            setTypeId(() => s.typeId);
            setFreeSeatMiddle(() => s.freeSeatMiddle);

            setPreferencesIncluded((pList) => {
              pList.forEach((element) => {
                if (s.preferencesIncludedId.find((id) => id === element.id)) {
                  element.active = true;
                }
              });
              return [...pList];
            });

            // buscar
            setEventSearch((c) => c + 1);
          } else {
            setLoading(() => false);
            setLoadingSearchTripResults(() => false);
          }
        })
        .catch(({ message }) => {
          setLoading(() => false);
          setLoadingSearchTripResults(() => false);
          Log.error({
            context: "RecreateSearchAdvanced.init.1",
            message,
          });
        });
    }
  };

  React.useEffect(() => {
    if (!loadingSearchTripResults && isMountedComponent.current) {
      setLoading(() => false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingSearchTripResults]);

  React.useEffect(() => {
    if (eventSearch && isMountedComponent.current) {
      search(true, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventSearch]);

  React.useEffect(() => {
    isMountedComponent.current = true;
    init();
    return () => {
      isMountedComponent.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMountedComponent]);

  return <></>;
};
