import React from "react";
import {
  ToolbarMUI,
  BodyPage,
  ActionSheet,
  ListMUI,
  ButtonGroup,
  Separator,
  FooterPage,
  ButtonMUI,
  BoxMUI,
} from "../../../DeltaUI/Components";
import { SearchTripContext } from "../SearchTripContext";
import AdvancedFiltersOrderBy from "./AdvancedFiltersOrderBy";
import AdvancedFiltersCalendar from "./AdvancedFiltersCalendar";
import AdvancedFiltersRadius from "./AdvancedFiltersRadius";
import AdvancedFiltersPreferences from "./AdvancedFiltersPreferences";
import { TitlePage } from "../../TitlePage";
import AdvancedFiltersTripType from "./AdvancedFiltersTripType";
import { GoBack } from "../../GoBack";
import { CustomPage } from "../../CustomPage";

export interface AdvancedFiltersProps {
  onBack?: () => void;
  onSearch?: () => void;
  open: boolean;
}

const AdvancedFilters: React.FC<AdvancedFiltersProps> = (props) => {
  const { onBack, onSearch, open } = props;
  const { cleanAdvancedFilters } = React.useContext(SearchTripContext);

  // viewAll
  const [
    viewAllPreferencesIncluded,
    setViewAllPreferencesIncluded,
  ] = React.useState<boolean>(false);
  const [
    viewAllPreferencesExcluded,
    setViewAllPreferencesExcluded,
  ] = React.useState<boolean>(false);

  const handleOnSearch = () => {
    if (onSearch) {
      onSearch();
    }
    setViewAllPreferencesIncluded(() => false);
    setViewAllPreferencesExcluded(() => false);
  };

  return (
    <ActionSheet
      open={open}
      onClose={onBack}
      style={{
        height: "calc(100vh + 76px)",
      }}
    >
      <CustomPage
        headerPage={
          <>
            <Separator px={8} />
            <ToolbarMUI>
              <GoBack onClick={handleOnSearch} />
              <TitlePage text="Filtros" />
              <ButtonGroup slot="end">
                {!(viewAllPreferencesIncluded || viewAllPreferencesExcluded) ? (
                  <ButtonMUI
                    color="primary"
                    onClick={cleanAdvancedFilters}
                    className="TypographyBold11pt"
                  >
                    Borrar
                  </ButtonMUI>
                ) : null}
              </ButtonGroup>
            </ToolbarMUI>
          </>
        }
        footerPage={
          <FooterPage>
            <BoxMUI style={{ padding: "16px 24px" }}>
              <ButtonMUI
                className="FullWidth"
                variant="contained"
                color="primary"
                onClick={handleOnSearch}
              >
                Mostrar resultados
              </ButtonMUI>
            </BoxMUI>
          </FooterPage>
        }
      >
        <BodyPage m={1}>
          <ListMUI component="nav">
            <Separator px={8} />
            <AdvancedFiltersOrderBy />
            <Separator px={16} />
            <AdvancedFiltersTripType />
            <Separator px={16} />
            <AdvancedFiltersPreferences />
            <Separator px={16} />
            <AdvancedFiltersCalendar />
            <Separator px={16} />
            <AdvancedFiltersRadius />
            <Separator px={16} />
          </ListMUI>
        </BodyPage>
      </CustomPage>
    </ActionSheet>
  );
};

export default AdvancedFilters;
