import React from "react";
import { UpoolContext } from "../../Providers/UpoolContext";
import {
  ButtonMUI,
  BoxMUI,
  Separator,
  FormInputEmailOrPhone,
  TypographyMUI,
} from "../../DeltaUI/Components";
import { UserService } from "../../Services/UserService";
import { DialogCustomHook } from "../DialogCustom";
import { RecoverPasswordContext } from "./RecoverPasswordContext";

enum VALUE_TYPE {
  EMAIL = "email",
  PHONE = "phone",
}

interface SearchAccountProps {}

export const SearchAccount: React.FC<SearchAccountProps> = () => {
  const { userExists } = UserService();
  const { messageError, messageWarn } = DialogCustomHook();
  const { setLoading } = React.useContext(UpoolContext);
  const { setUserToRecover } = React.useContext(RecoverPasswordContext);
  const [value, setValue] = React.useState<string>("");
  const [valueType, setValueType] = React.useState<
    "email" | "phone" | undefined
  >(VALUE_TYPE.EMAIL);
  const [errorEmailOrPhone, setErrorEmailOrPhone] = React.useState<boolean>(
    false
  );

  const handleSearchUser = () => {
    const where: any = {};
    if (valueType === VALUE_TYPE.PHONE) {
      where.phone = value;
    } else {
      where.email = value;
    }
    setLoading(() => true);
    userExists(where)
      .then((u) => {
        if (u) {
          setUserToRecover(() => u);
        } else {
          if (valueType === VALUE_TYPE.EMAIL) {
            messageWarn({
              context: "RegisterForm.handleSearchUser.1",
              message: "No se encontró ninguna cuenta asociada a este email.",
            });
          } else {
            messageWarn({
              context: "RegisterForm.handleSearchUser.2",
              message:
                "No se encontró ninguna cuenta asociada a este número de teléfono.",
            });
          }
        }
      })
      .catch(({ message }) => {
        messageError({
          context: "RegisterForm.handleSearchUser.3",
          message: message,
        });
      })
      .finally(() => setLoading(() => false));
  };

  return (
    <>
      <BoxMUI
        style={{
          width: "100%",
        }}
      >
        <TypographyMUI
          className="TypographyExtrabold26pt"
          sx={{ lineHeight: "48px" }}
        >
          Recuperar contraseña
        </TypographyMUI>
        <Separator px={24} />
        <TypographyMUI className="TypographyRegular11pt">
          Ingresa el email o teléfono de la cuenta que desea recuperar.
        </TypographyMUI>
        <Separator px={8} />
        <FormInputEmailOrPhone
          value={value}
          onChange={(text, type) => {
            setValue(() => text);
            setValueType(() => type);
          }}
          onError={(v) => setErrorEmailOrPhone(() => v)}
        />
      </BoxMUI>
      <BoxMUI
        style={{
          width: "100%",
        }}
      >
        <Separator px={16} />
        <ButtonMUI
          style={{
            width: "100%",
            marginTop: "16px",
          }}
          variant="contained"
          color="primary"
          onClick={handleSearchUser}
          disabled={!value || errorEmailOrPhone}
        >
          Continuar
        </ButtonMUI>
        <Separator px={32} />
      </BoxMUI>
    </>
  );
};
