import React from "react";
import {
  ToolbarMUI,
  MenuButton,
  BodyPage,
  ButtonGroup,
  CardMUI,
  TypographyMUI,
  ListItemMUI,
  ListItemTextMUI,
  BoxMUI,
  Separator,
  ButtonMUI,
} from "../../DeltaUI/Components";
import {
  SettingsIcon,
  StarIcon,
  EventIcon,
  AlternateEmailOutlinedIcon,
  PhoneOutlinedIcon,
  GoogleIcon,
  FacebookOutlinedIcon,
  AppleIcon,
} from "../../DeltaUI/Components/CustomIcon";
import { MomentJS, Tools } from "../../Utils";
import { Link } from "react-router-dom";
import { UserContext } from "../../Components/User/UserContext";
import { UpoolContext } from "../../Providers/UpoolContext";
import UserAvatar from "../../Components/User/UserAvatar";
import {
  COLOR_TERTIARY,
  ROLE_TYPE,
  APPLICATION_PATHS,
  COLOR_QUINARY,
} from "../../Utils/Constants";
import CardConfirmReservation from "../../Components/Card/CardConfirmReservation";
import { GoBack } from "../../Components/GoBack";
import { CustomPage, HeaderPageCustom } from "../../Components";

interface BoxRateProps {
  style?: React.CSSProperties;
  className?: string;
  onClick: () => void;
  rate: string;
  text: string;
}

export const BoxRate: React.FC<BoxRateProps> = (props) => {
  const { style, className, onClick, rate, text } = props;

  return (
    <BoxMUI
      className={className}
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        padding: "16px",
        ...style,
      }}
    >
      <BoxMUI
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <StarIcon
          style={{
            color: "#ffb400",
            marginTop: "4px",
            marginLeft: "-4px",
          }}
        />
        <TypographyMUI className="TypographyBold13pt">{rate}</TypographyMUI>
      </BoxMUI>
      <BoxMUI
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <TypographyMUI className="TypographyRegular11pt" color={COLOR_QUINARY}>
          {text}
        </TypographyMUI>
      </BoxMUI>
      <Separator px={16} />
      <BoxMUI
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <ButtonMUI
          style={{
            width: "60%",
          }}
          variant="outlined"
          onClick={onClick}
        >
          Ver
        </ButtonMUI>
      </BoxMUI>
    </BoxMUI>
  );
};

const UserPage: React.FC = () => {
  const { viewUser, myUser, setScoresUserPageMode } = React.useContext(
    UserContext
  );
  const {
    selectPathTab,
    historyPush,
    setViewFullScreenImage,
  } = React.useContext(UpoolContext);

  const { getDescriptionDate } = MomentJS();
  const { getPerson } = Tools();

  const person = React.useMemo(
    () => getPerson(viewUser, 0),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [viewUser]
  );

  const name = React.useMemo(
    () => (person ? `${person.firstName} ${person.lastName}` : ""),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [person]
  );

  return (
    <CustomPage>
      <BodyPage>
        <BoxMUI
          sx={{
            position: "absolute",
            top: 0,
            height: "calc(56px + 56px + 56px + 56px)",
            width: "100%",
            zIndex: -1,
            background: (theme) =>
              `linear-gradient(to top, ${theme.palette.primary.main}, #93a4f2)`,
          }}
        />
        <HeaderPageCustom className="Transparent">
          <ToolbarMUI>
            {selectPathTab === APPLICATION_PATHS.USER ? (
              <MenuButton style={{ color: "white" }} />
            ) : (
              <GoBack style={{ color: "white" }} />
            )}
            {myUser ? (
              <ButtonGroup slot="end">
                <Link to={`${selectPathTab}/user/setting/default`}>
                  <SettingsIcon
                    sx={{
                      color: (theme) => theme.palette.primary.contrastText,
                    }}
                  />
                </Link>
              </ButtonGroup>
            ) : null}
          </ToolbarMUI>
        </HeaderPageCustom>
        <BoxMUI
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            padding: "24px",
            marginTop: "-24px",
          }}
        >
          <BoxMUI
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <UserAvatar
              size={13}
              sizeDot={12}
              user={viewUser}
              onClick={() => setViewFullScreenImage(() => person?.pictureUrl)}
            />
          </BoxMUI>

          <CardMUI
            style={{
              width: "100%",
              paddingTop: "48px",
              marginTop: "-40px",
            }}
            className="ShadowCard8"
            elevation={7}
          >
            <TypographyMUI
              style={{
                textAlign: "center",
              }}
              className="TypographyBold14pt"
            >
              {name}
            </TypographyMUI>
            <Separator px={24} />
            <Separator px={0} divider />
            <BoxMUI
              style={{
                display: "flex",
              }}
            >
              <BoxRate
                onClick={() => {
                  setScoresUserPageMode(() => ROLE_TYPE.DRIVER);
                  historyPush(`${selectPathTab}/user/scores`);
                }}
                text="Conductor"
                rate={Number.parseFloat(`${viewUser.rateAsDriver}`).toFixed(1)}
              />
              <BoxRate
                style={{
                  borderLeft: "1px solid #e0e0e0",
                }}
                onClick={() => {
                  setScoresUserPageMode(() => ROLE_TYPE.PASSENGER);
                  historyPush(`${selectPathTab}/user/scores`);
                }}
                text="Pasajero"
                rate={Number.parseFloat(`${viewUser.rateAsPassenger}`).toFixed(
                  1
                )}
              />
            </BoxMUI>
          </CardMUI>

          <CardConfirmReservation />

          <Separator px={32} />
          <TypographyMUI className="TypographyExtrabold11pt">
            Miembro desde
          </TypographyMUI>
          <Separator px={16} />
          <CardMUI
            style={{
              width: "100%",
              padding: "8px",
            }}
            elevation={7}
            className="ShadowCard8"
          >
            <ListItemMUI>
              <EventIcon
                style={{ marginRight: "16px", color: COLOR_TERTIARY }}
              />
              <ListItemTextMUI
                primary={viewUser ? getDescriptionDate(viewUser.createdAt) : ""}
              />
            </ListItemMUI>
          </CardMUI>
          <Separator px={32} />
          <TypographyMUI className="TypographyExtrabold11pt">
            Datos verificados
          </TypographyMUI>
          <Separator px={16} />
          <CardMUI
            style={{
              width: "100%",
              padding: "16px 8px",
            }}
            elevation={7}
            className="ShadowCard8"
          >
            <ListItemMUI disabled={!viewUser?.emailVerified}>
              <AlternateEmailOutlinedIcon />
              <ListItemTextMUI
                style={{ marginLeft: "16px" }}
                primary="Correo electrónico"
              />
            </ListItemMUI>
            <ListItemMUI disabled={!viewUser?.phoneVerified}>
              <PhoneOutlinedIcon />
              <ListItemTextMUI
                style={{ marginLeft: "16px" }}
                primary="Número de teléfono"
              />
            </ListItemMUI>
            <ListItemMUI disabled={!viewUser?.googleId}>
              <GoogleIcon />
              <ListItemTextMUI
                style={{ marginLeft: "16px" }}
                primary="Google"
              />
            </ListItemMUI>
            <ListItemMUI disabled={!viewUser?.facebookId}>
              <FacebookOutlinedIcon style={{ color: "#3b5998" }} />
              <ListItemTextMUI
                style={{ marginLeft: "16px" }}
                primary="Facebook"
              />
            </ListItemMUI>
            <ListItemMUI disabled={!viewUser?.appleId}>
              <AppleIcon />
              <ListItemTextMUI style={{ marginLeft: "16px" }} primary="Apple" />
            </ListItemMUI>
          </CardMUI>

          <Separator px={32} />
        </BoxMUI>
      </BodyPage>
    </CustomPage>
  );
};

export default UserPage;
