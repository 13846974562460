import React from "react";
import { TypographyMUI, BoxMUI } from "../DeltaUI/Components";
import { TRIP_TYPE, COLOR_SECONDARY } from "../Utils/Constants";

export interface TagRemisProps {
  typeId?: number;
}

const TagRemis: React.FC<TagRemisProps> = (props) => {
  const { typeId } = props;
  return (
    <>
      {typeId === TRIP_TYPE.REMIS ? (
        <BoxMUI
          sx={{
            position: "absolute",
            top: "16px",
            right: "-4px",
            padding: "8px",
            borderRadius: "5px",
            color: (theme) => theme.palette.secondary.main,
            background: (theme) => `${theme.palette.secondary.main}25`,
          }}
        >
          <TypographyMUI className="TypographyBold8pt" color={COLOR_SECONDARY}>
            Remís
          </TypographyMUI>
        </BoxMUI>
      ) : null}
    </>
  );
};

export default TagRemis;
