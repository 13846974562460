import React from "react";
import { ButtonMUI, Separator, PaperMUI } from "../../DeltaUI/Components";
import {
  RoomIcon,
  OriginIcon,
  CloseIcon,
} from "../../DeltaUI/Components/CustomIcon";
import { InputAutocomplete } from "../../DeltaUI/Components/GoogleMapsApiReact";
import { SearchTripContext } from "./SearchTripContext";
import { UpoolContext } from "../../Providers/UpoolContext";
import { PlaceResult } from "../../Services/DefaultTypes";
import {
  COLOR_TERTIARY,
  COLOR_SECONDARY,
  COLOR_PRIMARY,
  COLOR_QUATERNARY,
  COLOR_QUINARY,
} from "../../Utils/Constants";
import SearchTripHomeCalendar from "./SearchTripHomeCalendar";
import SearchTripHomePlaces from "./SearchTripHomePlaces";
import { useCustomLog } from "../../Utils";

export interface SearchTripHomeCardProps {
  onSetOrigin: (place?: PlaceResult) => void;
  onSetDestination: (place?: PlaceResult) => void;
}

const SearchTripHomeCard: React.FC<SearchTripHomeCardProps> = (props) => {
  const { onSetOrigin, onSetDestination } = props;
  const {
    originPlace,
    destinationPlace,
    availablePlaces,
    search,
    cleanAdvancedFilters,
  } = React.useContext(SearchTripContext);
  const { historyPush, selectPathTab } = React.useContext(UpoolContext);
  const Log = useCustomLog();

  React.useEffect(() => {
    cleanAdvancedFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PaperMUI
      elevation={3}
      style={{
        position: "absolute",
        bottom: 0,
        width: "100%",
        height: "256px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "24px",
        borderRadius: 0,
      }}
      sx={{
        "& .MuiInputBase-input, .MuiInput-input": {
          fontFamily: "'Open Sans Bold' !important",
        },
      }}
    >
      <InputAutocomplete
        className="ContentInputCustom"
        style={{
          height: "42px",
          padding: "4px 8px",
        }}
        id="SearchTripHomeCardInputAutocompleteOrigen"
        iconLeft={<OriginIcon style={{ color: COLOR_TERTIARY }} />}
        textIconLeft={
          !originPlace ? (
            <span
              style={{ margin: "0 12px", color: COLOR_QUATERNARY }}
              className="TypographyBold11pt"
            >
              Desde
            </span>
          ) : (
            <span style={{ margin: "0 12px", color: COLOR_QUINARY }}>
              Desde
            </span>
          )
        }
        onChange={onSetOrigin}
        initialValue={originPlace}
        iconClean={
          <CloseIcon
            style={{
              fontSize: "20px",
              color: COLOR_PRIMARY,
            }}
          />
        }
        maxHeight="140px"
        onError={({ message }) =>
          Log.error({
            context: "SearchTripHomeCard.InputAutocomplete.1",
            message,
          })
        }
      />
      <Separator px={8} />
      <InputAutocomplete
        className="ContentInputCustom"
        style={{
          height: "42px",
          padding: "4px 8px",
        }}
        id="SearchTripHomeCardInputAutocompleteDestination"
        iconLeft={<RoomIcon style={{ color: COLOR_SECONDARY }} />}
        textIconLeft={
          !destinationPlace ? (
            <span
              style={{ margin: "0 12px", color: COLOR_QUATERNARY }}
              className="TypographyBold11pt"
            >
              Hasta
            </span>
          ) : (
            <span style={{ margin: "0 12px", color: COLOR_QUINARY }}>
              Hasta
            </span>
          )
        }
        onChange={onSetDestination}
        initialValue={destinationPlace}
        iconClean={
          <CloseIcon
            style={{
              fontSize: "20px",
              color: COLOR_PRIMARY,
            }}
          />
        }
        maxHeight="100px"
        onError={({ message }) =>
          Log.error({
            context: "SearchTripHomeCard.InputAutocomplete.2",
            message,
          })
        }
      />
      <Separator px={8} />
      <div className="FlexRow">
        <SearchTripHomeCalendar />
        <Separator px={8} orientation="vertical" />
        <SearchTripHomePlaces />
      </div>
      <Separator px={24} />
      <ButtonMUI
        className="FullWidth"
        variant="contained"
        color="primary"
        onClick={() => {
          search(true, false);
          historyPush(`${selectPathTab}/result`);
        }}
        disabled={!originPlace || !destinationPlace || !availablePlaces}
      >
        Buscar viaje
      </ButtonMUI>
    </PaperMUI>
  );
};

export default SearchTripHomeCard;
