import React from "react";
import { IPlace } from "./PlaceService";
import { IUser } from "./UserService";
import { IVehicle } from "./VehicleService";
import { UpoolContext } from "../Providers/UpoolContext";
import { IPagination } from "./DefaultTypes";
import { IPreferenceTrip } from "./PreferenceService";
import { RESERVATION_STATUS, TAKE, TRIP_STATUS } from "../Utils/Constants";
import { IReservation } from "./ReservationService";
import { useCustomApolloAsync } from "./GraphQL/ApolloService";
import { Mutation } from "./GraphQL/Mutation";
import { Query } from "./GraphQL/Query";

const reservationStatusActive = [
  RESERVATION_STATUS.CREATED,
  RESERVATION_STATUS.IN_REVIEW,
  RESERVATION_STATUS.IN_PROGRESS,
];

const reservationStatusFinished = [
  RESERVATION_STATUS.REJECTED_BY_DRIVER,
  RESERVATION_STATUS.REJECTED_BY_SYSTEM,
  RESERVATION_STATUS.CANCELED_BY_PASSENGER,
  RESERVATION_STATUS.CANCELED_BY_DRIVER,
  RESERVATION_STATUS.FINISHED,
];

export const TripService = () => {
  const { user, isDriverOfTheTrip } = React.useContext(UpoolContext);
  const { queryAsync, mutationAsync } = useCustomApolloAsync();

  const prepareTrips = (list: ITripStretchResult[]): ITripStretch[] => {
    return list.map((tripStretch) => {
      const isDriver = isDriverOfTheTrip(tripStretch);
      return {
        ...tripStretch,
        reservations: tripStretch.reservations.filter((r) => !r.deletedAt),
        isDriver,
        myReservation: !isDriver
          ? tripStretch.reservations.find(
              (r) => !r.deletedAt && r.userId === user?.id
            )
          : undefined,
      };
    });
  };

  const prepareAvailableTrips = (
    availableTrip: IMyAvailableTripStretch
  ): IMyAvailableTripStretch => {
    return {
      ...availableTrip,
      result: availableTrip.result.map((item) => ({
        ...item,
        tripStretch: prepareTrips([item.tripStretch])[0],
      })),
    };
  };

  const tripSuggestedAmount = (where: {
    placeIds: string[];
  }): Promise<IMyTripSuggestedAmount> => {
    return queryAsync({
      query: Query.tripSuggestedAmount,
      variables: {
        where,
      },
    });
  };

  const createOneTrip = (
    variables: any
  ): Promise<ICreateOneTrip | undefined> => {
    return mutationAsync({
      mutation: Mutation.createOneTrip,
      variables,
    }).then((t: ICreateOneTripResult) => {
      if (t) {
        return {
          ...t,
          tripStretch: prepareTrips([t.tripStretches[0]])[0],
        };
      } else {
        return undefined;
      }
    });
  };

  const tripStretchesAvailables = (
    variables: any
  ): Promise<IMyAvailableTripStretch> => {
    return queryAsync({
      query: Query.tripStretchesAvailables,
      variables,
    }).then(prepareAvailableTrips);
  };

  const tripStretch = (
    tripStretchId: number
  ): Promise<ITripStretch | undefined> => {
    return queryAsync({
      query: Query.tripStretch,
      variables: {
        where: {
          id: tripStretchId,
        },
      },
    }).then((t: ITripStretchResult) => {
      if (t) {
        return prepareTrips([t])[0];
      } else {
        return undefined;
      }
    });
  };

  const driverTripsStretches = (
    finished?: boolean,
    paginationId?: number
  ): Promise<ITripStretch[]> => {
    const optional: any = {};
    if (paginationId) {
      optional.after = {
        id: paginationId,
      };
    }
    if (user) {
      const statusTripId = finished
        ? [TRIP_STATUS.DONE, TRIP_STATUS.CANCELED]
        : [TRIP_STATUS.AVAILABLE, TRIP_STATUS.IN_PROGRESS];
      const where: any = {
        trip: {
          ownerId: {
            equals: user.id,
          },
          statusId: {
            in: statusTripId,
          },
          deletedAt: {
            equals: null,
          },
        },
      };
      return queryAsync({
        query: Query.tripStretches,
        variables: {
          where,
          orderBy: [
            {
              dateFrom: finished ? "desc" : "asc",
            },
          ],
          first: TAKE.OTHER,
          ...optional,
        },
      }).then(prepareTrips);
    } else {
      return Promise.resolve([]);
    }
  };

  const passengerTripsStretches = (
    finished?: boolean,
    paginationId?: number
  ): Promise<ITripStretch[]> => {
    const optional: any = {};
    if (paginationId) {
      optional.after = {
        id: paginationId,
      };
    }
    const statusId = finished
      ? reservationStatusFinished
      : reservationStatusActive;
    if (user) {
      const where: any = {
        reservations: {
          some: {
            AND: [
              {
                userId: {
                  equals: user.id,
                },
                statusId: {
                  in: statusId,
                },
                deletedAt: {
                  equals: null,
                },
              },
            ],
          },
        },
        deletedAt: {
          equals: null,
        },
      };
      return queryAsync({
        query: Query.tripStretches,
        variables: {
          where,
          orderBy: [
            {
              dateFrom: finished ? "desc" : "asc",
            },
          ],
          first: TAKE.OTHER,
          ...optional,
        },
      }).then(prepareTrips);
    } else {
      return Promise.resolve([]);
    }
  };

  const deleteOneTrip = (tripId: number): Promise<{ id: number }> => {
    return mutationAsync({
      mutation: Mutation.deleteOneTrip,
      variables: {
        where: { id: tripId },
      },
    });
  };

  const createOneRate = (data: {
    score: number;
    description?: string;
    reservationId: number;
  }): Promise<{ id: number }> => {
    return mutationAsync({
      mutation: Mutation.createOneRate,
      variables: {
        data,
      },
    });
  };

  const updateOneTrip = (
    tripStrechId: number,
    data: {
      dateFrom: Date;
      totalPlaces: number;
    }
  ): Promise<{ id: number }> => {
    return mutationAsync({
      mutation: Mutation.updateOneTrip,
      variables: {
        where: { id: tripStrechId },
        data,
      },
    });
  };

  const tripStrechHomePage = (): Promise<ITripStretch | undefined> => {
    return queryAsync({
      query: Query.tripStrechHomePage,
    }).then((t: ITripStretchResult) => {
      if (t) {
        return prepareTrips([t])[0];
      } else {
        return undefined;
      }
    });
  };

  return {
    tripSuggestedAmount,
    createOneTrip,
    tripStretchesAvailables,
    tripStretch,
    driverTripsStretches,
    passengerTripsStretches,
    deleteOneTrip,
    createOneRate,
    updateOneTrip,
    tripStrechHomePage,
  };
};

export interface ICreateOneTripResult {
  id: number;
  tripStretches: ITripStretchResult[];
}

export interface ICreateOneTrip {
  id: number;
  tripStretch: ITripStretch;
}

export interface ITrip {
  autoAcceptance: boolean;
  description: string;
  id: number;
  ownerId: number;
  routeId: number;
  statusId: number;
  userOwner: IUser;
  vehicle: IVehicle;
  vehicleId: number;
  typeId: number;
  status: ITripStatus;
  preferences: IPreferenceTrip[];
}

export interface ITripStatus {
  code: string;
  description: string;
  id: number;
  name: string;
}

export interface ITripStatusHistory {
  id: number;
  statusId: number;
  tripId: number;
  trip: ITrip;
  status: ITripStatus;
}

export interface ITripStretchResult {
  amount: number;
  availablePlaces: number;
  totalPlaces: number;
  dateFrom: Date;
  dateTo: Date;
  destinationNumber: number;
  enabled: boolean;
  id: number;
  meters: number;
  placeFrom: IPlace;
  placeIdFrom: number;
  placeIdTo: number;
  placeTo: IPlace;
  trip: ITrip;
  tripId: number;
  usedPlaces: number;
  reservations: IReservation[];
}
export interface ITripStretch extends ITripStretchResult {
  myReservation: IReservation | undefined;
  isDriver: boolean;
}

export interface IMyAvailableTripStretchWhere {
  radiusFrom: number;
  radiusTo: number;
}

export interface IMyAvailableTripStretchResultInfo {
  distanceMtsFrom: number;
  distanceMtsTo: number;
}
export interface IMyAvailableTripStretchResult {
  tripStretch: ITripStretch;
  info: IMyAvailableTripStretchResultInfo;
}

export interface IMyAvailableTripStretch {
  result: IMyAvailableTripStretchResult[];
  where: IMyAvailableTripStretchWhere;
  pagination: IPagination;
}

export interface IMyTripSuggestedAmount {
  suggested: number;
  min: number;
  max: number;
}

export interface IRate {
  id: number;
  userFromId: number;
  userToId: number;
  score: number;
  description: string;
  userFrom: IUser;
}

export interface ITripMode {
  drive: boolean;
  passenger: boolean;
}

export interface ITripStretchResume {
  trip: {
    userOwner: IUser;
    statusId: number;
  };
}
