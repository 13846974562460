import React from "react";
import { ToolbarMUI, MenuButton, BodyPage } from "../DeltaUI/Components";
import { TitlePage } from "../Components/TitlePage";
import { CustomPage } from "../Components";

const ConfigurationPage: React.FC = () => {
  return (
    <CustomPage
      headerPage={
        <ToolbarMUI>
          <MenuButton />
          <TitlePage text="Configuración" />
        </ToolbarMUI>
      }
    >
      <BodyPage></BodyPage>
    </CustomPage>
  );
};

export default ConfigurationPage;
