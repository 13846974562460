import React from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

/**
 * Librería de iconos SVG.
 *
 * Pasos para crear un nuevo icnono:
 *  - copiar un icono existente
 *  - reemplazar el "path" (en algunos casos el tag "g" junto con los "path")
 *  - reemplazar el "viewBox" (porque es particular de cada SVG)
 *  - agregar style={{padding: '2px' }} si es necesario
 */

export const SteeringWheelIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      {...props}
      style={{ padding: "2px" }}
      viewBox="0 0 500.000000 500.000000"
    >
      <g transform="matrix(0.13295787,0,0,-0.1330104,-80.797725,581.76448)">
        <path d="m 2300,4354 c -14,-2 -59,-9 -100,-15 C 1616,4257 1075,3857 809,3313 529,2738 563,2051 899,1510 c 196,-315 499,-580 829,-725 595,-261 1262,-204 1799,155 705,471 1007,1363 732,2159 -41,119 -146,329 -214,431 -233,346 -571,604 -962,736 -193,64 -323,85 -553,89 -113,2 -216,2 -230,-1 z m 464,-305 c 319,-52 606,-198 837,-429 219,-218 360,-480 360,-669 0,-90 -16,-137 -56,-169 -33,-26 -61,-28 -115,-6 -23,9 -42,15 -44,13 -1,-2 -15,-40 -30,-84 -28,-80 -78,-182 -181,-370 -30,-55 -55,-103 -55,-107 0,-4 19,-12 42,-19 34,-9 54,-8 112,7 106,28 229,25 291,-7 69,-34 88,-65 88,-136 -1,-100 -101,-328 -211,-482 -124,-173 -285,-325 -477,-450 -256,-167 -407,-172 -450,-16 -7,27 -16,117 -20,200 -3,82 -9,153 -14,158 -4,4 -43,2 -87,-5 -99,-15 -458,-15 -544,0 -35,6 -68,8 -72,4 -4,-4 -11,-84 -16,-177 -10,-181 -20,-222 -65,-264 -79,-73 -268,-8 -514,175 -113,84 -298,274 -369,379 -115,169 -205,376 -208,477 -1,70 20,103 88,137 62,31 178,34 286,7 63,-15 82,-17 118,-7 23,7 42,15 42,19 0,4 -30,61 -67,127 -100,181 -149,282 -175,363 -12,39 -27,72 -33,72 -5,-1 -21,-7 -35,-15 -33,-19 -85,-19 -115,1 -60,39 -78,198 -35,324 12,36 46,112 76,170 234,450 723,763 1251,799 140,10 249,4 397,-20 z" />
        <path d="m 2260,3074 c -307,-28 -680,-122 -943,-238 -54,-24 -57,-28 -51,-53 15,-66 69,-187 135,-308 39,-71 86,-160 105,-196 27,-55 42,-72 86,-99 134,-82 312,-244 405,-370 51,-68 117,-191 134,-247 6,-24 19,-45 28,-48 80,-24 376,-32 543,-14 108,12 138,21 138,43 0,29 91,197 148,272 83,110 244,261 362,339 85,57 99,71 121,117 13,29 59,115 101,193 72,129 131,267 139,323 4,20 -5,27 -66,54 -213,92 -493,172 -731,208 -104,16 -199,22 -374,25 -129,1 -255,1 -280,-1 z m 326,-363 c 95,-43 150,-172 113,-268 -36,-98 -107,-147 -210,-148 -89,0 -151,37 -191,114 -60,115 -20,243 97,304 42,22 140,21 191,-2 z" />
        <path d="m 2465,2705 c -5,-2 -22,-6 -37,-9 -37,-8 -98,-63 -114,-103 -8,-18 -14,-56 -13,-85 2,-167 199,-248 322,-134 91,85 68,254 -43,311 -27,13 -98,26 -115,20 z" />
      </g>
    </SvgIcon>
  );
};

export const CarSeatIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      {...props}
      style={{ padding: "2px" }}
      viewBox="0 0 240.235 240.235"
    >
      <path d="M211.744,6.089C208.081,2.163,203.03,0,197.52,0h-15.143c-11.16,0-21.811,8.942-23.74,19.934l-0.955,5.436 c-0.96,5.47,0.332,10.651,3.639,14.589c3.307,3.938,8.186,6.106,13.74,6.106h19.561c2.714,0,5.339-0.542,7.778-1.504l-2.079,17.761 c-2.001-0.841-4.198-1.289-6.507-1.289h-22.318c-9.561,0-18.952,7.609-20.936,16.961l-19.732,93.027l-93.099-6.69 c-5.031-0.36-9.231,1.345-11.835,4.693c-2.439,3.136-3.152,7.343-2.009,11.847l10.824,42.618 c2.345,9.233,12.004,16.746,21.53,16.746h78.049h1.191h39.729c9.653,0,18.336-7.811,19.354-17.411l15.272-143.981 c0.087-0.823,0.097-1.634,0.069-2.437l5.227-44.648c0.738-1.923,1.207-3.967,1.354-6.087l0.346-4.97 C217.214,15.205,215.407,10.016,211.744,6.089z" />
    </SvgIcon>
  );
};

export const GoogleIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      {...props}
      style={{ padding: "2px", ...props.style }}
      viewBox="0 0 24 24"
    >
      <g transform="matrix(1, 0, 0, 1, 27.009001, -39.238998)">
        <path
          fill="#4285F4"
          d="M -3.264 51.509 C -3.264 50.719 -3.334 49.969 -3.454 49.239 L -14.754 49.239 L -14.754 53.749 L -8.284 53.749 C -8.574 55.229 -9.424 56.479 -10.684 57.329 L -10.684 60.329 L -6.824 60.329 C -4.564 58.239 -3.264 55.159 -3.264 51.509 Z"
        />
        <path
          fill="#34A853"
          d="M -14.754 63.239 C -11.514 63.239 -8.804 62.159 -6.824 60.329 L -10.684 57.329 C -11.764 58.049 -13.134 58.489 -14.754 58.489 C -17.884 58.489 -20.534 56.379 -21.484 53.529 L -25.464 53.529 L -25.464 56.619 C -23.494 60.539 -19.444 63.239 -14.754 63.239 Z"
        />
        <path
          fill="#FBBC05"
          d="M -21.484 53.529 C -21.734 52.809 -21.864 52.039 -21.864 51.239 C -21.864 50.439 -21.724 49.669 -21.484 48.949 L -21.484 45.859 L -25.464 45.859 C -26.284 47.479 -26.754 49.299 -26.754 51.239 C -26.754 53.179 -26.284 54.999 -25.464 56.619 L -21.484 53.529 Z"
        />
        <path
          fill="#EA4335"
          d="M -14.754 43.989 C -12.984 43.989 -11.404 44.599 -10.154 45.789 L -6.734 42.369 C -8.804 40.429 -11.514 39.239 -14.754 39.239 C -19.444 39.239 -23.494 41.939 -25.464 45.859 L -21.484 48.949 C -20.534 46.099 -17.884 43.989 -14.754 43.989 Z"
        />
      </g>
    </SvgIcon>
  );
};

export const OriginIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      {...props}
      style={{ ...props.style }}
      height="40px"
      viewBox="0 0 24 24"
      width="40px"
      fill="#F5835E"
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <circle
        style={{
          fill: "#ffffff",
          fillOpacity: 0,
          stroke: "#a0aff6",
          strokeWidth: 5,
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
        cx="12"
        cy="12"
        r="5.5"
      />
    </SvgIcon>
  );
};
