import React from "react";
import { BodyPage, Stepper, Tabs, ToolbarMUI } from "../DeltaUI/Components";
import { CreateTripDate, CustomPage } from "../Components";
import {
  CreateTripContext,
  CreateTripProvider,
  STEPS_EDIT,
} from "../Components/CreateTrip/CreateTripContext";
import CreateTripVehicle from "../Components/CreateTrip/CreateTripVehicle";
import CreateTripResume from "../Components/CreateTrip/CreateTripResume";
import { MyTripsContext } from "../Components/MyTrips/MyTripsContext";
import { TitlePage } from "../Components/TitlePage";
import { GoBack } from "../Components/GoBack";
import { COLOR_QUATERNARY } from "../Utils/Constants";

const EditTripPageContent: React.FC = () => {
  const {
    tabResume,
    setTabResume,
    steps,
    next,
    finish,
    step,
    setStep,
  } = React.useContext(CreateTripContext);

  return (
    <CustomPage
      headerPage={
        <>
          <ToolbarMUI>
            <GoBack />
            <TitlePage text="Editar viaje" />
          </ToolbarMUI>
          <Stepper
            list={steps}
            value={step}
            onChange={(v) => {
              if (v < step || steps.find((s) => s.id === v - 1)?.completed) {
                setStep(() => v);
              }
            }}
            color="secondary"
          />
          {step === STEPS_EDIT.RESUME ? (
            <Tabs
              value={tabResume}
              invertedColor={true}
              color="secondary"
              textColor={COLOR_QUATERNARY}
              defaultValue={1}
              onChange={(ev) => setTabResume(() => ev)}
              list={[
                {
                  key: 1,
                  label: "Resumen",
                  onClick: () => {},
                },
                {
                  key: 2,
                  label: "Mapa",
                  onClick: () => {},
                },
              ]}
            />
          ) : null}
        </>
      }
    >
      <BodyPage>
        {step === STEPS_EDIT.DATE ? (
          <CreateTripDate
            onNext={() => next(STEPS_EDIT.DATE, STEPS_EDIT.VEHICLE)}
          />
        ) : null}
        {step === STEPS_EDIT.VEHICLE ? (
          <CreateTripVehicle
            onNext={() => next(STEPS_EDIT.VEHICLE, STEPS_EDIT.RESUME)}
          />
        ) : null}
        {step === STEPS_EDIT.RESUME ? (
          <CreateTripResume onNext={finish} />
        ) : null}
      </BodyPage>
    </CustomPage>
  );
};

const EditTripPage: React.FC<{}> = () => {
  const { selectTripStretchForEdit } = React.useContext(MyTripsContext);
  return (
    <CreateTripProvider editTrip={selectTripStretchForEdit}>
      <EditTripPageContent />
    </CreateTripProvider>
  );
};

export default EditTripPage;
