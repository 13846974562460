import React from "react";
import {
  BoxMUI,
  ButtonMUI,
  Separator,
  TypographyMUI,
} from "../../DeltaUI/Components";
import { COLOR_QUATERNARY } from "../../Utils/Constants";
import { CustomPage } from "../CustomPage";

interface Onboarding2Props {
  style?: React.CSSProperties;
  className?: string;
  onClick?: () => void;
  image?: string;
}

export const Onboarding2: React.FC<Onboarding2Props> = (props) => {
  const { style, className, onClick, image } = props;

  return (
    <CustomPage
      style={{
        backgroundImage: `url(${image})`,
        ...style,
      }}
      className={`BackgroundImage ${className}`}
    >
      <BoxMUI
        style={{
          width: "100%",
          height: "51vh",
          position: "absolute",
          bottom: 0,
          left: 0,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-evenly",
        }}
      >
        <BoxMUI
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            margin: "0 32px",
            paddingTop: "24px",
          }}
        >
          <TypographyMUI className="TypographyBold20pt" color="white">
            Si sos pasajero
          </TypographyMUI>
          <Separator px={24} />
          <BoxMUI
            style={{
              textAlign: "center",
              width: "300px",
            }}
          >
            <TypographyMUI color="white" className="TypographyRegular11pt">
              Podes buscar y unirte a cualquier viaje que esté disponible.
            </TypographyMUI>
          </BoxMUI>
        </BoxMUI>
        <ButtonMUI
          style={{
            width: "50%",
            background: "white",
            color: COLOR_QUATERNARY,
          }}
          variant="contained"
          onClick={onClick}
        >
          Continuar
        </ButtonMUI>
      </BoxMUI>
    </CustomPage>
  );
};
