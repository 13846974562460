import React from "react";
import {
  InputMUI,
  ListItemMUI,
  ListItemIconMUI,
  IconButtonMUI,
  InputAdornmentMUI,
} from "..";
import { Autocomplete } from "./Autocomplete";
import { GoogleMapsContext } from "./GoogleMapsProvider";

export interface InputAutocompleteProps {
  style?: React.CSSProperties;
  className?: string;
  id: string;
  iconLeft?: JSX.Element;
  textIconLeft?: JSX.Element;
  iconRight?: JSX.Element;
  onChange?: (place?: google.maps.places.PlaceResult) => void;
  placeholder?: string;
  initialValue?: google.maps.places.PlaceResult;
  options?: google.maps.places.AutocompleteOptions | undefined;
  iconClean?: JSX.Element;
  onError?: (e: Error) => void;
  maxHeight?: string;
}

export const InputAutocomplete: React.FC<InputAutocompleteProps> = (props) => {
  const {
    style,
    className,
    id,
    iconLeft,
    textIconLeft,
    iconRight,
    onChange,
    placeholder,
    initialValue,
    options,
    iconClean,
    onError,
    maxHeight,
  } = props;
  const { map } = React.useContext(GoogleMapsContext);
  const [formattedAddress, setFormattedAddress] = React.useState<string>("");

  const itemIcon = React.useMemo(() => {
    return (icon: JSX.Element) => (
      <ListItemIconMUI
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minWidth: "24px",
          padding: "0 4px",
        }}
      >
        {icon}
      </ListItemIconMUI>
    );
  }, []);

  const handleCallback = (place?: google.maps.places.PlaceResult | null) => {
    if (place?.geometry) {
      if (place?.formatted_address) {
        const l1 = place.formatted_address.split(",").map((el) => el.trim());
        const l2 = l1.filter((item, index) => l1.indexOf(item) === index);
        const l3: string[] = [l2[l2.length - 2], l2[l2.length - 1]];
        const l4: string[] = place?.name ? [place?.name, ...l3] : l3;
        let address = "";
        l4.forEach((item, index) => {
          if (l4.indexOf(item) === index && item !== undefined) {
            address = address ? `${address}, ${item}` : item;
          }
        });
        setFormattedAddress(address);
      }
      if (onChange) {
        onChange(place || undefined);
      }
    }
  };

  const onPlaceChanged = (
    p: google.maps.places.QueryAutocompletePrediction
  ) => {
    if (p.place_id && map) {
      const place = new google.maps.places.PlacesService(map);
      place.getDetails(
        {
          placeId: p.place_id,
          fields: ["name", "place_id", "formatted_address", "geometry"],
        },
        (place, status) => {
          if (status === "OK") {
            handleCallback({ ...place });
          } else {
            handleCallback(undefined);
            if (onError) {
              onError(new Error(`No results found for placeId: ${p.place_id}`));
            }
          }
        }
      );
    } else {
      if (onError && !map) {
        onError(new Error("El mapa no está disponible"));
      }
    }
  };

  React.useEffect(() => {
    handleCallback(initialValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ListItemMUI style={{ padding: "0", ...style }} className={className}>
      {iconLeft
        ? itemIcon(
            <>
              {iconLeft} {textIconLeft || ""}
            </>
          )
        : null}
      <Autocomplete
        options={{
          // types: ["address"],
          componentRestrictions: {
            country: ["ar"],
          },
          ...options,
        }}
        id={id}
        type="Place"
        onChange={onPlaceChanged}
        inputValue={formattedAddress}
        itemIcon={iconLeft}
        maxHeight={maxHeight}
      >
        <InputMUI
          id={id}
          sx={{
            width: "100%",
            marginRight: "4px",
            "& .MuiInput-input": {
              cursor: "pointer",
            },
            "& .MuiInputAdornment-root": {
              margin: 0,
            },
          }}
          placeholder={placeholder}
          value={formattedAddress}
          onChange={(ev) => setFormattedAddress(() => ev.target.value)}
          endAdornment={
            formattedAddress ? (
              <InputAdornmentMUI position="end">
                <IconButtonMUI
                  onClick={() => {
                    setFormattedAddress("");
                    if (onChange) {
                      onChange(undefined);
                    }
                  }}
                  style={{ padding: 0 }}
                >
                  {iconClean ? (
                    iconClean
                  ) : (
                    <span style={{ fontSize: "16px" }}>&#x2715;</span>
                  )}
                </IconButtonMUI>
              </InputAdornmentMUI>
            ) : undefined
          }
        />
      </Autocomplete>
      {iconRight ? itemIcon(iconRight) : null}
    </ListItemMUI>
  );
};
