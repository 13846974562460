import React from "react";
import "./Loader1.css";

export interface Loader1Props {}

const Loader1: React.FC<Loader1Props> = (props) => {
  return <div className="Loader1" />;
};

export default Loader1;
