import React from "react";
import {
  CardMUI,
  CardContentMUI,
  TypographyMUI,
  Separator,
  AvatarMUI,
  BoxMUI,
  IconButtonMUI,
  SwipeableActionSheet,
  SwipeableActionSheetItem,
} from "../../DeltaUI/Components";
import {
  CheckBoxOutlinedIcon,
  DeleteIcon,
  DriveEtaIcon,
  MoreIcon,
} from "../../DeltaUI/Components/CustomIcon";
import { UpoolContext } from "../../Providers/UpoolContext";
import { IVehicle, VehicleService } from "../../Services/VehicleService";
import {
  COLOR_PRIMARY,
  COLOR_QUINARY,
  COLOR_SECONDARY,
} from "../../Utils/Constants";
import { DialogCustomHook } from "../DialogCustom";

export interface CardVehicleProps {
  style?: React.CSSProperties;
  className?: string;
  vehicle: IVehicle;
  onClick?: () => void;
  freeSeatMiddle?: boolean;
  isOptions?: boolean;
  onDelete?: (id: number) => void;
}

const CardVehicle: React.FC<CardVehicleProps> = (props) => {
  const {
    style,
    className,
    vehicle,
    onClick,
    freeSeatMiddle,
    isOptions,
    onDelete,
  } = props;
  const [viewFilter, setViewFilter] = React.useState<boolean>(false);
  const { deleteOneVehicle } = VehicleService();
  const { message, messageError } = DialogCustomHook();
  const { setLoading } = React.useContext(UpoolContext);

  const handleClickOption = (callback?: Function) => {
    setViewFilter(() => false);
    if (callback && typeof callback === "function") {
      callback();
    }
  };

  const name = React.useMemo(() => {
    return `${vehicle.model.brand.name} ${
      vehicle.model.brandId !== 1 ? vehicle.model.name : ""
    }`;
  }, [vehicle]);

  const detail = React.useMemo(() => {
    return (
      <BoxMUI className="FlexRow">
        <AvatarMUI
          sx={{
            width: "50px",
            height: "50px",
            background: (theme) => theme.palette.primary.main,
          }}
        >
          <DriveEtaIcon />
        </AvatarMUI>
        <Separator px={16} orientation="vertical" />
        <BoxMUI className="FlexColumn FullWidth">
          <TypographyMUI className="TypographyBold11pt">{name}</TypographyMUI>
          <Separator px={4} />
          <BoxMUI className="FlexRow">
            <BoxMUI
              className="FlexColumn"
              style={{
                borderRight: "1px solid #e0e0e0",
                paddingRight: "16px",
              }}
            >
              <TypographyMUI className="TypographyBold10pt">
                Patente
              </TypographyMUI>
              <TypographyMUI className="TypographyRegular10pt">
                {vehicle.licensePlate}
              </TypographyMUI>
            </BoxMUI>
            <BoxMUI className="FlexColumn" style={{ paddingLeft: "16px" }}>
              <BoxMUI className="FlexRow" style={{ alignItems: "end" }}>
                <TypographyMUI className="TypographyBold10pt">
                  Color
                </TypographyMUI>
                <BoxMUI
                  style={{
                    width: "12px",
                    height: "12px",
                    margin: "4px",
                    borderRadius: "50%",
                    background: vehicle.colour.code,
                    border: `1px solid ${COLOR_QUINARY}`,
                  }}
                />
              </BoxMUI>
              <TypographyMUI className="TypographyRegular10pt">
                {vehicle.colour.name}{" "}
              </TypographyMUI>
            </BoxMUI>
          </BoxMUI>
        </BoxMUI>
      </BoxMUI>
    );
  }, [name, vehicle]);

  const handleDelete = () => {
    message({
      context: "CardVehicle.handleDelete.1",
      title: "¿Querés eliminar este vehículo?",
      message: detail,
      onOk: async () => {
        setLoading(() => true);
        deleteOneVehicle(vehicle.id)
          .then(() => {
            if (onDelete) {
              onDelete(vehicle.id);
            }
          })
          .catch(({ message }) => {
            messageError({
              context: "CardVehicle.handleDelete.2",
              message: message,
            });
          })
          .finally(() => setLoading(() => false));
      },
      textOnOk: "Sí",
      textOnCancel: "No",
    });
  };

  return (
    <CardMUI
      className={`${className} ShadowCard8`}
      style={style}
      sx={{
        position: "relative",
        margin: "16px",
        padding: "24px",
      }}
      key={vehicle.id}
      title={vehicle.licensePlate}
      elevation={6}
    >
      <CardContentMUI style={{ padding: 0 }} onClick={onClick}>
        {detail}
        {freeSeatMiddle ? (
          <>
            <Separator px={8} divider />
            <BoxMUI className="FlexRow">
              <TypographyMUI color={COLOR_SECONDARY}>
                <CheckBoxOutlinedIcon style={{ marginBottom: "-6px" }} />{" "}
                Asiento libre en el medio
              </TypographyMUI>
            </BoxMUI>
          </>
        ) : null}
      </CardContentMUI>
      {isOptions ? (
        <IconButtonMUI
          style={{
            height: "min-content",
            position: "absolute",
            top: 0,
            right: 0,
            padding: "24px",
            zIndex: 1,
          }}
          onClick={() => setViewFilter(() => true)}
        >
          <MoreIcon style={{ color: COLOR_PRIMARY }} />
        </IconButtonMUI>
      ) : null}
      <SwipeableActionSheet
        open={viewFilter}
        onClose={() => handleClickOption()}
        type="options"
      >
        <SwipeableActionSheetItem
          onClick={handleDelete}
          text="Eliminar"
          icon={<DeleteIcon style={{ color: COLOR_PRIMARY }} />}
        />
      </SwipeableActionSheet>
    </CardMUI>
  );
};

export default CardVehicle;
