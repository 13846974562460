import React from "react";
import { SearchTripContext } from "./SearchTripContext";
import SearchTripHomeCard from "./SearchTripHomeCard";
import { MapRoute } from "../Trip/MapRoute";
import SearchTripTitle from "./SearchTripTeatle";
import { GoogleMaps } from "../../DeltaUI/Components/GoogleMapsApiReact";
import { AppContext } from "../../Providers/AppContext";
import { useCustomLog } from "../../Utils";
import { CustomPage } from "../CustomPage";
import { MenuButton, ToolbarMUI } from "../../DeltaUI/Components";
import { GoBack } from "../GoBack";
import { UpoolContext } from "../../Providers/UpoolContext";

export interface SearchTripHomeProps {}

const SearchTripHomeContent: React.FC<SearchTripHomeProps> = () => {
  const { currentPosition } = React.useContext(AppContext);
  const { originPlace, destinationPlace, loadMap } = React.useContext(
    SearchTripContext
  );
  return (
    <>
      {loadMap ? <SearchTripTitle /> : null}
      <MapRoute
        currentPosition={currentPosition}
        originPlace={originPlace}
        destinationPlace={destinationPlace}
      />
    </>
  );
};

export const SearchTripHome: React.FC<SearchTripHomeProps> = (props) => {
  const { user } = React.useContext(UpoolContext);
  const { setOriginPlace, setDestinationPlace, setLoadMap } = React.useContext(
    SearchTripContext
  );
  const Log = useCustomLog();

  const height = React.useMemo(() => {
    if (user) {
      return `calc(100vh - 256px - 48px - var(--ios-action-footer-height))`;
    }
    return `calc(100vh - 256px - var(--ios-action-footer-height))`;
  }, [user]);

  return (
    <CustomPage>
      <GoogleMaps
        onLoad={() => setLoadMap(true)}
        onError={({ message }) => {
          Log.error({
            context: "SearchTripHome.GoogleMaps.1",
            message,
          });
        }}
        style={{
          width: "100vw",
          height,
          position: "absolute",
          top: 0,
        }}
        footerElement={
          <SearchTripHomeCard
            onSetOrigin={(v) => setOriginPlace(() => v)}
            onSetDestination={(v) => setDestinationPlace(() => v)}
          />
        }
      >
        <SearchTripHomeContent {...props} />
      </GoogleMaps>
      <ToolbarMUI>
        {user ? <MenuButton fab={true} /> : <GoBack fab={true} />}
      </ToolbarMUI>
    </CustomPage>
  );
};

export default SearchTripHome;
