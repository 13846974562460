import React from "react";
import { UpoolContext } from "../Providers/UpoolContext";
import { TAKE } from "../Utils/Constants";
import { useCustomApolloAsync } from "./GraphQL/ApolloService";
import { Mutation } from "./GraphQL/Mutation";
import { Query } from "./GraphQL/Query";

export const prepareNotification = (notification: INotification) => {
  const payload = notification.payload ? JSON.parse(notification.payload) : {};
  return { ...notification, payload };
};

export const NotificationService = () => {
  const { queryAsync, mutationAsync } = useCustomApolloAsync();
  const { user } = React.useContext(UpoolContext);

  const getNotifications = (
    paginationId?: number
  ): Promise<INotification[]> => {
    const where: any = {};
    if (user) {
      where.userId = {
        equals: user.id,
      };
    }

    const optional: any = {};

    if (paginationId) {
      optional.after = {
        id: paginationId,
      };
    }
    return queryAsync({
      query: Query.notifications,
      variables: {
        where,
        orderBy: [
          {
            sendedAt: "desc",
          },
        ],
        first: TAKE.OTHER,
        ...optional,
      },
    }).then((list) => list.map(prepareNotification));
  };

  const getNotificationsNotAttended = (
    paginationId?: number
  ): Promise<INotification[]> => {
    const where: any = {
      attendedAt: {
        equals: null,
      },
    };
    if (user) {
      where.userId = {
        equals: user.id,
      };
    }

    const optional: any = {};
    if (paginationId) {
      optional.after = {
        id: paginationId,
      };
    }
    return queryAsync({
      query: Query.notifications,
      variables: {
        where,
        orderBy: [
          {
            sendedAt: "desc",
          },
        ],
        first: TAKE.OTHER,
        ...optional,
      },
    }).then((list) => list.map(prepareNotification));
  };

  /**
   * Marcar todas las notificaciones como atendidas
   */
  const markAsAttendedAllNotifications = (): Promise<boolean> => {
    return mutationAsync({
      mutation: Mutation.markAsAttendedAllNotifications,
    });
  };

  /**
   * Marcar notificaciones como atendidas por ids
   */
  const markAsAttendedManyNotifications = (ids: number[]): Promise<boolean> => {
    return mutationAsync({
      mutation: Mutation.markAsAttendedManyNotifications,
      variables: {
        where: { ids },
      },
    });
  };

  /**
   * Marcar todas las notificaciones como leidas
   */
  const markAsReadedAllNotifications = (): Promise<boolean> => {
    return mutationAsync({
      mutation: Mutation.markAsReadedAllNotifications,
    });
  };

  /**
   * Marcar notificaciones como atendidas por ids
   */
  const markAsReadedManyNotifications = (ids: number[]): Promise<boolean> => {
    return mutationAsync({
      mutation: Mutation.markAsReadedManyNotifications,
      variables: {
        where: { ids },
      },
    });
  };

  return {
    getNotifications,
    getNotificationsNotAttended,
    markAsAttendedAllNotifications,
    markAsAttendedManyNotifications,
    markAsReadedAllNotifications,
    markAsReadedManyNotifications,
  };
};

export interface INotification {
  id: number;
  title: string;
  message: string;
  type: string;
  payload: any;
  sendedAt: Date;
  attendedAt: Date;
  readedAt: Date;
}
