import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { MyTripsProvider } from "../Components/MyTrips/MyTripsContext";
import { APPLICATION_PATHS, DETAIL_TRIP_MODE } from "../Utils/Constants";
import HomePage from "./HomePage";
import ChatListPage from "./ChatListPage";
import ChatPage from "./ChatPage";
import DetailTripPage from "./DetailTripPage";
import EditTripPage from "./EditTripPage";
import RatePage from "./RatePage";
import ViewUserNavTree from "./User/ViewUserNavTree";

const defaultPath = `${APPLICATION_PATHS.HOME}/default`;
const homeDetailPath = `${APPLICATION_PATHS.HOME}/detail-trip`;
const homeChatPath = `${APPLICATION_PATHS.HOME}/chat`;
const homeRatePath = `${APPLICATION_PATHS.HOME}/rate`;
const homeUserPath = `${APPLICATION_PATHS.HOME}/user`;
const homeEditPath = `${APPLICATION_PATHS.HOME}/edit-trip`;
const homeChatListPath = `${APPLICATION_PATHS.HOME}/chat-list`;

const HomeNavTreeSwitch: React.FC = () => {
  return (
    <Switch>
      <Route exact path={APPLICATION_PATHS.HOME}>
        <Redirect to={defaultPath} />
      </Route>
      <Route exact path={defaultPath} component={HomePage} />
      <Route exact path={homeDetailPath} component={DetailTripPage} />
      <Route exact path={homeChatPath} component={ChatPage} />
      <Route path={homeUserPath} component={ViewUserNavTree} />
      <Route exact path={homeRatePath} component={RatePage} />
      <Route exact path={homeEditPath} component={EditTripPage} />
      <Route exact path={homeChatListPath} component={ChatListPage} />
    </Switch>
  );
};

const HomeNavTree: React.FC = () => {
  return (
    <MyTripsProvider detailTripMode={DETAIL_TRIP_MODE.MY_TRIPS}>
      <HomeNavTreeSwitch />
    </MyTripsProvider>
  );
};

export default HomeNavTree;
