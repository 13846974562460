import React from "react";
import { IonRouterOutlet, IonSplitPane } from "@ionic/react";
import { UpoolContext } from "../../Providers/UpoolContext";
import { EventAppContext } from "../../Providers/EventAppProvider";
import { Route, Redirect } from "react-router-dom";
import SecurityLayoutMenu from "./SecurityLayoutMenu";
import TabsPage from "../../Pages/TabsPage";
import ConfigurationPage from "../../Pages/ConfigurationPage";
import HelpPage from "../../Pages/HelpPage";
import TermsAndConditionsPage from "../../Pages/TermsAndConditionsPage";
import ViewUserNavTree from "../../Pages/User/ViewUserNavTree";
import CommentsPage from "../../Pages/CommentsPage";

const menuId = "MenuApp";

export interface SecurityLayoutProps {}

const SecurityLayout: React.FC<SecurityLayoutProps> = () => {
  const { handleEventLogin } = React.useContext(EventAppContext);
  const { user, setLoading } = React.useContext(UpoolContext);

  React.useEffect(() => {
    setLoading(() => false);
    if (user) {
      handleEventLogin();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <IonSplitPane contentId={menuId} when="(min-width: 3000px)">
      <SecurityLayoutMenu menuId={menuId} />
      <IonRouterOutlet id={menuId}>
        <Route exact path="/login" render={() => <Redirect to="/tabs" />} />
        <Route exact path="/register" render={() => <Redirect to="/tabs" />} />
        <Route path="/tabs" component={TabsPage} />
        <Route exact path="/configuration" component={ConfigurationPage} />
        <Route exact path="/help" component={HelpPage} />
        <Route
          exact
          path="/terms-and-conditions"
          component={TermsAndConditionsPage}
        />
        <Route exact path="/comments" component={CommentsPage} />
        <Route path="/profile/user" component={ViewUserNavTree} />
      </IonRouterOutlet>
    </IonSplitPane>
  );
};

export default SecurityLayout;
