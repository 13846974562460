import React from "react";
import { Link, useLocation } from "react-router-dom";
import { BoxMUI, PaperMUI, TypographyMUI, ThemeMUI } from "..";

export interface CustomTabsBarProps {
  elevation?: number;
  color?: "primary" | "secondary";
  invertedColor?: boolean;
}

export const CustomTabsBar: React.FC<CustomTabsBarProps> = (props) => {
  const { children, elevation, color, invertedColor } = props;

  const getColor = (theme: ThemeMUI) => {
    return invertedColor
      ? theme.palette[color || "primary"].main
      : theme.palette[color || "primary"].contrastText;
  };

  const getBGColor = (theme: ThemeMUI) => {
    return invertedColor
      ? theme.palette[color || "primary"].contrastText
      : theme.palette[color || "primary"].main;
  };

  React.useEffect(() => {
    document.documentElement.style.setProperty(
      "--custom-tabs-bar-height",
      "48px"
    );
    return () => {
      document.documentElement.style.setProperty(
        "--custom-tabs-bar-height",
        "0px"
      );
    };
  }, []);

  return (
    <PaperMUI style={{ zIndex: 1 }} elevation={elevation || 0}>
      <BoxMUI
        sx={{
          paddingBottom: "var(--ios-action-footer-height)",
          width: "100vw",
          display: "flex",
          bgcolor: getBGColor,
          "& a, div > span": {
            color: "#828899",
          },
          "& .CustomTabSelected .CustomTabButtonIndicator": {
            background: getColor,
          },
          "& .CustomTabSelected .CustomTabsBarItemChildren > a, .CustomTabSelected .CustomTabsBarItemChildren > span, .CustomTabSelected .CustomTabsBarItemChildren, .CustomTabSelected .CustomTabsBarItemChildren": {
            color: getColor,
          },
          "& .CustomTabSelected .CustomTabsBarItemLabel, .CustomTabSelected .CustomTabsBarItemLabel": {
            color: getColor,
          },
        }}
      >
        {children}
      </BoxMUI>
    </PaperMUI>
  );
};

interface CustomTabsBarItemProps {
  style?: React.CSSProperties;
  className?: string;
  label?: string | JSX.Element;
  onClick?: (to: string) => void;
  to: string;
  disabledTo?: boolean;
}

export const CustomTabsBarItem: React.FC<CustomTabsBarItemProps> = (props) => {
  const { children, style, className, label, onClick, to, disabledTo } = props;
  const { pathname } = useLocation();

  const content = React.useMemo(() => {
    return (
      <BoxMUI
        style={{
          height: label ? "54px" : "46px",
          display: "flex",
          flexDirection: "column",
          padding: "4px",
        }}
      >
        {children ? (
          <BoxMUI
            className="CustomTabsBarItemChildren"
            style={{
              height: "100%",
              display: "flex",
              alignItems: label ? "end" : "center",
              justifyContent: "center",
            }}
          >
            {children}
          </BoxMUI>
        ) : undefined}
        {label ? (
          <TypographyMUI
            className="CustomTabsBarItemLabel"
            variant="caption"
            style={{
              textAlign: "center",
            }}
          >
            {label}
          </TypographyMUI>
        ) : undefined}
      </BoxMUI>
    );
  }, [label, children]);

  return (
    <BoxMUI
      style={{
        height: label ? "56px" : "48px",
        width: `100%`,
        minWidth: "auto",
        display: "flex",
        flexDirection: "column",
        ...style,
      }}
      onClick={() => (onClick ? onClick(to) : undefined)}
      className={
        pathname.indexOf(to) > -1 ? `CustomTabSelected ${className}` : className
      }
    >
      {!disabledTo ? <Link to={to}>{content}</Link> : <span>{content}</span>}
      <BoxMUI
        style={{
          width: `100%`,
          height: "2px",
        }}
        className="CustomTabButtonIndicator"
      />
    </BoxMUI>
  );
};
