import React from "react";
import { Imgs } from "../../Assets";
import { BoxMUI, Separator, TypographyMUI } from "../../DeltaUI/Components";
import {
  MoreIcon,
  OriginIcon,
  RoomIcon,
} from "../../DeltaUI/Components/CustomIcon";
import {
  COLOR_PRIMARY,
  COLOR_QUINARY,
  COLOR_SECONDARY,
  COLOR_TERTIARY,
  TAKE,
  URL_APP_FRONT,
} from "../../Utils/Constants";
import ListItemTextDescriptionPlace from "../Trip/PlaceDescription";
import { ShareComponent } from "./ShareComponent";

interface ShareSearchProps {
  params: string;
  length?: number;
  originPlace?: google.maps.places.PlaceResult | undefined;
  destinationPlace?: google.maps.places.PlaceResult | undefined;
  onFinish: () => void;
}

export const ShareSearch: React.FC<ShareSearchProps> = (props) => {
  const { length, originPlace, destinationPlace, params, onFinish } = props;
  const [share, setShare] = React.useState<boolean>(false);
  return (
    <BoxMUI
      style={{
        width: "100vw",
        height: "100vh",
        overflow: "hidden",
        background: COLOR_TERTIARY,
      }}
    >
      <BoxMUI
        style={{
          width: "calc(100vw + 48px)",
          marginTop: "-24px",
          marginLeft: "-24px",
          background: COLOR_TERTIARY,
        }}
      >
        <BoxMUI
          id="ShareSearch"
          style={{
            width: "102%",
            padding: "64px 32px 64px 32px",
            background: COLOR_TERTIARY,
          }}
        >
          <BoxMUI
            style={{
              width: "calc(100vw - 16px)",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              background: COLOR_PRIMARY,
              borderRadius: "20px",
            }}
          >
            <BoxMUI
              style={{
                width: "100%",
                display: "flex",
                alignItems: "start",
                justifyContent: "space-between",
                padding: "16px 24px",
              }}
            >
              <BoxMUI
                style={{
                  width: "100%",
                  height: "88px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "end",
                }}
              >
                <Separator px={8} />
                <TypographyMUI className="TypographyRegular14pt" color="white">
                  Encontré {length && length > TAKE.OTHER ? "más de" : ""}
                </TypographyMUI>
                {length && length > 1 ? (
                  <>
                    {length > TAKE.OTHER ? (
                      <TypographyMUI
                        className="TypographyBold16pt"
                        color="white"
                      >
                        {TAKE.OTHER} viajes disponibles
                      </TypographyMUI>
                    ) : (
                      <TypographyMUI
                        className="TypographyBold16pt"
                        color="white"
                      >
                        {length} viajes disponibles
                      </TypographyMUI>
                    )}
                  </>
                ) : (
                  <TypographyMUI className="TypographyBold16pt" color="white">
                    {length} viaje disponible
                  </TypographyMUI>
                )}
              </BoxMUI>
              <img
                src={Imgs.logoPNG}
                alt=""
                style={{
                  height: "50px",
                }}
                onLoad={() => setShare(() => true)}
                onAbort={onFinish}
                onError={onFinish}
              />
            </BoxMUI>
            <BoxMUI
              style={{
                width: "100%",
                padding: "0 16px",
              }}
            >
              <BoxMUI
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "5px",
                  background: "white",
                }}
              >
                <Separator px={16} />
                <BoxMUI
                  style={{
                    width: "calc(100vw - 48px)",
                    height: "105px",
                    display: "flex",
                    padding: "8px 24px",
                  }}
                >
                  <BoxMUI
                    style={{
                      width: "24px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      marginLeft: "-4px",
                      paddingBottom: "8px",
                    }}
                  >
                    <OriginIcon style={{ color: COLOR_TERTIARY }} />
                    <BoxMUI
                      style={{
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <MoreIcon style={{ color: COLOR_QUINARY }} />
                    </BoxMUI>
                    <RoomIcon style={{ color: COLOR_SECONDARY }} />
                  </BoxMUI>
                  <Separator px={8} orientation="vertical" />
                  <BoxMUI style={{ display: "flex", flexDirection: "column" }}>
                    <ListItemTextDescriptionPlace
                      place={originPlace}
                      distanceMts={""}
                    />
                    <Separator px={24} />
                    <ListItemTextDescriptionPlace
                      place={destinationPlace}
                      distanceMts={""}
                    />
                  </BoxMUI>
                </BoxMUI>
                <Separator px={16} />
              </BoxMUI>
            </BoxMUI>
            <Separator px={48} />
            <Separator px={24} />
          </BoxMUI>
        </BoxMUI>
      </BoxMUI>
      {share ? (
        <ShareComponent
          titleModal="Compartir busqueda"
          onFinish={onFinish}
          elementId="ShareSearch"
          config={{
            url: `${URL_APP_FRONT}/public/search${params}`,
            title: "Resultados de busqueda",
          }}
        />
      ) : null}
    </BoxMUI>
  );
};
