import { DialogCustomHook } from "../Components";

export const Safely = () => {
  const { messageError } = DialogCustomHook();

  /**
   * Ejecuta funciones de forma segura.
   * @param exec
   * @returns
   */
  const functionSafely = (exec: Function): any => {
    try {
      exec();
    } catch (err: any) {
      messageError({
        context: "Safely.functionSafely.1",
        message: err.message,
      });
    }
  };

  /**
   * Ejecuta eventos de forma segura.
   * Solo permite volver a ejecutar despues de 500 milisegundos desde la ultima llamada.
   * @param exec
   * @returns
   */
  const eventSafely = (exec: Function): any => {
    var first: boolean = false;
    var timeout: any = null;
    const fn = () => {
      try {
        exec();
        timeout = setTimeout(() => {
          first = false;
        }, 500);
      } catch (err: any) {
        messageError({
          context: "Safely.eventSafely.1",
          message: err.message,
        });
      }
    };
    return () => {
      if (!first) {
        first = true;
        return fn();
      }
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        first = false;
      }, 500);
    };
  };

  return {
    functionSafely,
    eventSafely,
  };
};
