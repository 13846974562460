import React from "react";
import {
  ToolbarMUI,
  BodyPage,
  ListMUI,
  ListItemMUI,
  IconButtonMUI,
  ListItemTextMUI,
  DividerMUI,
  Separator,
  TypographyMUI,
} from "../../../DeltaUI/Components";
import {
  AddAPhotoOutlinedIcon,
  AlternateEmailOutlinedIcon,
  AppleIcon,
  FacebookOutlinedIcon,
  GoogleIcon,
  ModeEditOutlineOutlinedIcon,
  NavigateNextIcon,
  PhoneOutlinedIcon,
  VisibilityOffOutlinedIcon,
  DeleteOutlinedIcon,
} from "../../../DeltaUI/Components/CustomIcon";
import { TitlePage } from "../../../Components/TitlePage";
import { UpoolContext } from "../../../Providers/UpoolContext";
import { COLOR_TERTIARY } from "../../../Utils/Constants";
import { GoBack } from "../../../Components/GoBack";
import { CustomPage } from "../../../Components";
import { isIos } from "../../../DeltaUI/Utils/Constants";

interface ItemConfigProps {
  style?: React.CSSProperties;
  className?: string;
  onClick: () => void;
  text: string;
  icon: JSX.Element;
  disabled?: boolean;
}

export const ItemConfig: React.FC<ItemConfigProps> = (props) => {
  const { style, className, onClick, text, icon, disabled } = props;

  return (
    <>
      <ListItemMUI
        button
        onClick={onClick}
        style={style}
        className={className}
        disabled={disabled}
      >
        <ListItemTextMUI
          primary={
            <div className="FlexRow">
              {icon}
              <TypographyMUI variant="body1" style={{ marginLeft: "8px" }}>
                {text}
              </TypographyMUI>
            </div>
          }
        />
        <IconButtonMUI
          style={{
            padding: 0,
          }}
          edge="end"
        >
          <NavigateNextIcon />
        </IconButtonMUI>
      </ListItemMUI>
      <DividerMUI />
    </>
  );
};

interface ListConfigProps {
  subtitle: string;
}

export const ListConfig: React.FC<ListConfigProps> = (props) => {
  const { children, subtitle } = props;

  return (
    <>
      <TypographyMUI className="TypographyExtrabold11pt">
        {subtitle}
      </TypographyMUI>
      <Separator px={8} />
      <ListMUI>
        <DividerMUI />
        {children}
      </ListMUI>
    </>
  );
};

const UserSettingPage: React.FC = () => {
  const { selectPathTab, historyPush } = React.useContext(UpoolContext);

  return (
    <CustomPage
      headerPage={
        <ToolbarMUI>
          <GoBack />
          <TitlePage text="Configuración" />
        </ToolbarMUI>
      }
    >
      <BodyPage m={3}>
        <Separator px={8} />
        <ListConfig subtitle="Editar perfil">
          <ItemConfig
            text="Datos personales"
            icon={
              <ModeEditOutlineOutlinedIcon style={{ color: COLOR_TERTIARY }} />
            }
            onClick={() =>
              historyPush(`${selectPathTab}/user/setting/personal-information`)
            }
          />
          <ItemConfig
            text="Foto de perfil"
            icon={<AddAPhotoOutlinedIcon style={{ color: COLOR_TERTIARY }} />}
            onClick={() => historyPush(`${selectPathTab}/user/setting/photo`)}
          />
        </ListConfig>

        <Separator px={32} />

        <ListConfig subtitle="Datos de acceso">
          <ItemConfig
            text="Email"
            icon={
              <AlternateEmailOutlinedIcon style={{ color: COLOR_TERTIARY }} />
            }
            onClick={() => historyPush(`${selectPathTab}/user/setting/email`)}
          />
          <ItemConfig
            text="Teléfono"
            icon={<PhoneOutlinedIcon style={{ color: COLOR_TERTIARY }} />}
            onClick={() => historyPush(`${selectPathTab}/user/setting/phone`)}
          />
          <ItemConfig
            text="Contraseña"
            icon={
              <VisibilityOffOutlinedIcon style={{ color: COLOR_TERTIARY }} />
            }
            onClick={() =>
              historyPush(`${selectPathTab}/user/setting/password`)
            }
          />
        </ListConfig>

        <Separator px={32} />

        <ListConfig subtitle="Cuentas asociadas">
          <ItemConfig
            text="Google"
            icon={<GoogleIcon />}
            onClick={() => historyPush(`${selectPathTab}/user/setting/google`)}
          />
          <ItemConfig
            text="Facebook"
            icon={<FacebookOutlinedIcon style={{ color: "#3b5998" }} />}
            onClick={() =>
              historyPush(`${selectPathTab}/user/setting/facebook`)
            }
          />
          <ItemConfig
            text="Apple"
            icon={<AppleIcon />}
            onClick={() => historyPush(`${selectPathTab}/user/setting/apple`)}
            disabled={!isIos}
          />
        </ListConfig>

        <Separator px={32} />

        <ListConfig subtitle="Cuenta">
          <ItemConfig
            text="Eliminar cuenta"
            icon={<DeleteOutlinedIcon style={{ color: COLOR_TERTIARY }} />}
            onClick={() =>
              historyPush(`${selectPathTab}/user/setting/delete-account`)
            }
          />
        </ListConfig>
      </BodyPage>
    </CustomPage>
  );
};

export default UserSettingPage;
