import React from "react";
import { AppBarMUI } from "../DeltaUI/Components";
import { COLOR_TERTIARY } from "../Utils/Constants";
import { AppContext } from "../Providers/AppContext";
import { StatusBar, Style } from "@capacitor/status-bar";
import { isDesktop } from "../DeltaUI/Utils/Constants";
import { useComponentMount } from "../Utils";

const COLOR_TRANSPARENT = "#00000000";

interface StatusBarPageProps {}

export const StatusBarPage: React.FC<StatusBarPageProps> = (props) => {
  const isMountedComponent = useComponentMount("StatusBarPage");
  const { mobileStatusBarHeight } = React.useContext(AppContext);
  const rRef = React.useRef<HTMLDivElement>();
  const sbRef = React.useRef<HTMLDivElement>();

  const handleTouchMove = React.useMemo(() => {
    return () => {
      if (rRef.current && sbRef.current) {
        const coords = rRef.current.getBoundingClientRect();
        const sb: HTMLDivElement = sbRef.current;
        if (coords.top * -1 > mobileStatusBarHeight) {
          sb.style.backgroundColor = COLOR_TERTIARY;
        } else {
          sb.style.backgroundColor = COLOR_TRANSPARENT;
        }
      }
    };
  }, [mobileStatusBarHeight]);

  React.useEffect(() => {
    if (!isDesktop) {
      StatusBar.setStyle({ style: Style.Light });
    }
    window.addEventListener("touchmove", handleTouchMove);
    return () => {
      window.removeEventListener("touchmove", handleTouchMove);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleTouchMove]);

  React.useEffect(() => {
    isMountedComponent.current = true;
    return () => {
      isMountedComponent.current = false;
    };
  }, [isMountedComponent]);

  return (
    <AppBarMUI
      position="static"
      style={{
        zIndex: 1,
        background: COLOR_TRANSPARENT,
      }}
      elevation={0}
    >
      <div
        ref={(el) => {
          if (el) {
            rRef.current = el;
          }
        }}
        className="StatusBar"
        style={{
          position: "relative",
          width: "100vw",
          height: `var(--mobile-status-bar-height)`,
        }}
      />
      <div
        ref={(el) => {
          if (el) {
            sbRef.current = el;
          }
        }}
        className="StatusBar"
        style={{
          position: "fixed",
          width: "100vw",
          height: `var(--mobile-status-bar-height)`,
          top: 0,
          zIndex: 1000,
          backgroundColor: COLOR_TRANSPARENT,
        }}
      />
    </AppBarMUI>
  );
};
