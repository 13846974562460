import React from "react";
import { UpoolContext } from "../../Providers/UpoolContext";
import { ButtonMUI, PaperMUI, Separator } from "../../DeltaUI/Components";
import { CreateTripContext, STEPS } from "./CreateTripContext";

export interface CreateTripNextButtonProps {
  style?: React.CSSProperties;
  className?: string;
  disabled: boolean;
  onClick: () => void;
  text?: string;
  onCancel?: () => void;
  textCancel?: string;
  notViewCancel?: boolean;
}

export const CreateTripNextButton: React.FC<CreateTripNextButtonProps> = (
  props
) => {
  const {
    style,
    className,
    disabled,
    onClick,
    text,
    onCancel,
    textCancel,
    notViewCancel,
  } = props;
  const { loading, historyGoBack } = React.useContext(UpoolContext);
  const { clean, editing, cancel, step } = React.useContext(CreateTripContext);

  const handleOnCancel = () => {
    if (onCancel) {
      onCancel();
    } else {
      if (editing) {
        historyGoBack();
      } else {
        cancel(clean);
      }
    }
  };

  return (
    <>
      <PaperMUI
        className={className}
        elevation={0}
        style={{
          position: "fixed",
          bottom:
            step === STEPS.MAP && !editing
              ? "calc(48px + var(--ios-action-footer-height))"
              : "var(--ios-action-footer-height)",
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
          padding: "24px",
          borderRadius: 0,
          ...style,
        }}
      >
        {onCancel || !notViewCancel ? (
          <>
            <ButtonMUI
              style={{ width: "50%", background: "white" }}
              variant="outlined"
              disabled={loading}
              onClick={handleOnCancel}
            >
              {textCancel || "Cancelar"}
            </ButtonMUI>
            <Separator px={16} orientation="vertical" />
          </>
        ) : null}
        <ButtonMUI
          style={{ width: "50%" }}
          variant="contained"
          color="primary"
          disabled={disabled || loading}
          onClick={onClick}
        >
          {text || "Continuar"}
        </ButtonMUI>
      </PaperMUI>
      <div style={{ width: "100%", height: "100px" }} />
    </>
  );
};
