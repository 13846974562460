import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { UpoolContext } from "../../Providers/UpoolContext";
import { DialogCustomHook } from "../../Components";
import { UserProvider } from "../../Components/User/UserContext";
import { IUser, UserService } from "../../Services/UserService";
import ScoresUserPage from "./ScoresUserPage";
import EmailSettingPage from "./Setting/EmailSettingPage";
import FacebookSettingPage from "./Setting/FacebookSettingPage";
import GoogleSettingPage from "./Setting/GoogleSettingPage";
import PasswordSettingPage from "./Setting/PasswordSettingPage";
import PhoneSettingPage from "./Setting/PhoneSettingPage";
import ProfilePhotoSettingPage from "./Setting/ProfilePhotoSettingPage";
import PersonalInformationPage from "./Setting/PersonalInformationPage";
import UserSettingPage from "./Setting/UserSettingPage";
import UserPage from "./UserPage";
import AppleSettingPage from "./Setting/AppleSettingPage";
import DeleteAccountPage from "./Setting/DeleteAccountPage";
import { useComponentMount } from "../../Utils";

interface ViewUserTabSwitchProps {
  myUser: boolean;
}

const ViewUserNavTreeSwitch: React.FC<ViewUserTabSwitchProps> = (props) => {
  const { myUser } = props;
  const { selectPathTab } = React.useContext(UpoolContext);

  const defaultPath = `${selectPathTab}/user/default`;
  const userScoresPath = `${selectPathTab}/user/scores`;
  const userSettingPhone = `${selectPathTab}/user/setting/phone`;
  const userSettingPassword = `${selectPathTab}/user/setting/password`;
  const userSettingEmail = `${selectPathTab}/user/setting/email`;
  const userSettingFacebook = `${selectPathTab}/user/setting/facebook`;
  const userSettingApple = `${selectPathTab}/user/setting/apple`;
  const userSettingGoogle = `${selectPathTab}/user/setting/google`;
  const userSettingProfilePhoto = `${selectPathTab}/user/setting/photo`;
  const userSettingPersonalInformation = `${selectPathTab}/user/setting/personal-information`;
  const userSettingDeleteAccount = `${selectPathTab}/user/setting/delete-account`;
  const userSetting = `${selectPathTab}/user/setting/default`;

  if (myUser) {
    return (
      <Switch>
        <Route exact path={`${selectPathTab}/user`}>
          <Redirect to={defaultPath} />
        </Route>
        <Route exact path={defaultPath} component={UserPage} />
        <Route exact path={userScoresPath} component={ScoresUserPage} />
        <Route exact path={userSettingPhone} component={PhoneSettingPage} />
        <Route
          exact
          path={userSettingPassword}
          component={PasswordSettingPage}
        />
        <Route exact path={userSettingEmail} component={EmailSettingPage} />
        <Route
          exact
          path={userSettingFacebook}
          component={FacebookSettingPage}
        />
        <Route exact path={userSettingApple} component={AppleSettingPage} />
        <Route exact path={userSettingGoogle} component={GoogleSettingPage} />
        <Route
          exact
          path={userSettingProfilePhoto}
          component={ProfilePhotoSettingPage}
        />
        <Route
          exact
          path={userSettingPersonalInformation}
          component={PersonalInformationPage}
        />
        <Route
          exact
          path={userSettingDeleteAccount}
          component={DeleteAccountPage}
        />
        <Route exact path={userSetting} component={UserSettingPage} />
      </Switch>
    );
  }

  return (
    <Switch>
      <Route exact path={`${selectPathTab}/user`}>
        <Redirect to={defaultPath} />
      </Route>
      <Route exact path={defaultPath} component={UserPage} />
      <Route exact path={userScoresPath} component={ScoresUserPage} />
    </Switch>
  );
};

const ViewUserNavTree: React.FC = () => {
  const isMountedComponent = useComponentMount("ViewUserNavTree");
  const { getOneUser } = UserService();
  const { messageError } = DialogCustomHook();
  const {
    setLoading,
    user,
    historyGoBack,
    viewOtherUserId,
    setViewOtherUserId,
  } = React.useContext(UpoolContext);
  const [viewUser, setViewUser] = React.useState<IUser | undefined>();

  const publicUser = React.useMemo(() => {
    return (
      (viewOtherUserId && !user) ||
      (viewOtherUserId && user && user.id !== viewOtherUserId)
    );
  }, [user, viewOtherUserId]);

  const getUser = () => {
    // if: Si el usuario que quiere ver es distinto del usuario logueado, es otro usuario
    if (viewOtherUserId && publicUser) {
      setLoading(() => true);
      getOneUser(viewOtherUserId)
        .then((u) => {
          if (isMountedComponent.current) {
            setViewUser(() => u);
          }
        })
        .catch(({ message }) => {
          historyGoBack();
          messageError({
            context: "ViewUserNavTree.getUser.1",
            message,
          });
        })
        .finally(() => setLoading(() => false));
    }
  };

  React.useEffect(() => {
    isMountedComponent.current = true;
    getUser();
    return () => {
      isMountedComponent.current = false;
      setViewOtherUserId(() => undefined);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMountedComponent]);

  return (
    <>
      {/**
       * Perfil publico
       */}
      {publicUser && viewUser ? (
        <UserProvider initUser={viewUser} myUser={false}>
          <ViewUserNavTreeSwitch myUser={false} />
        </UserProvider>
      ) : null}

      {/**
       * Mi perfil
       */}
      {!publicUser && user ? (
        <UserProvider initUser={user} myUser={true}>
          <ViewUserNavTreeSwitch myUser={true} />
        </UserProvider>
      ) : null}
    </>
  );
};

export default ViewUserNavTree;
