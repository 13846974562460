import React from "react";
import { BoxMUI, DrawerMUI, IconButtonMUI } from "../DeltaUI/Components";
import { CloseIcon } from "../DeltaUI/Components/CustomIcon";

interface FullScreenImageProps {
  style?: React.CSSProperties;
  className?: string;
  onClose?: () => void;
  image: string;
}

export const FullScreenImage: React.FC<FullScreenImageProps> = (props) => {
  const { style, className, onClose, image } = props;

  return (
    <DrawerMUI
      className={className}
      style={style}
      sx={{
        height: "100vh",
        "& .MuiDrawer-paperAnchorBottom": {
          height: "inherit",
        },
        "& .MuiPaper-root.MuiPaper-elevation.MuiDrawer-paper": {
          background: "#00000066",
        },
      }}
      anchor="bottom"
      open={true}
      onClose={() => {}}
    >
      <BoxMUI
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <IconButtonMUI
          style={{
            position: "absolute",
            right: 0,
            top: "var(--mobile-status-bar-height)",
            zIndex: 1,
          }}
          onClick={onClose}
        >
          <CloseIcon style={{ color: "#ffffff" }} />
        </IconButtonMUI>
        <img
          src={image}
          style={{
            width: "100%",
          }}
          alt=""
        />
      </BoxMUI>
    </DrawerMUI>
  );
};
