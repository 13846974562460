import React from "react";
import { useHistory } from "react-router-dom";
import { ArrowBackIosRoundedIcon } from "../CustomIcon";
import { FabMUI } from "..";

export interface ButtonGoBackProps {
  style?: React.CSSProperties;
  className?: string;
  onClick?: () => void;
  fab?: boolean;
}

const GoBackButton: React.FC<ButtonGoBackProps> = (props) => {
  const { style, className, onClick, fab } = props;
  const history = useHistory();
  const handleOnClick = () => history.goBack();
  return (
    <>
      {fab ? (
        <FabMUI
          size="small"
          color="primary"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginLeft: "-8px",
            color: "white",
            ...style,
          }}
          className={className}
          onClick={onClick || handleOnClick}
        >
          <ArrowBackIosRoundedIcon />
        </FabMUI>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "48px",
            width: "48px",
            marginLeft: "-12px",
            color: "#3F5AA6",
            ...style,
          }}
          className={className}
          onClick={onClick || handleOnClick}
        >
          <ArrowBackIosRoundedIcon />
        </div>
      )}
    </>
  );
};

export default GoBackButton;
