import React from "react";
import { useIonToast } from "@ionic/react";

import { AppMinimize } from "@ionic-native/app-minimize";
import { useHistory } from "react-router-dom";
import { SetterState } from "../Services/DefaultTypes";
import { INotification } from "../Services/NotificationService";
import {
  IAuthPayload,
  IPerson,
  IUser,
  IUserSocialNetworks,
} from "../Services/UserService";
import {
  ROLE_TYPE,
  APPLICATION_PATHS,
  TAB_BAR_AVAILABLE_IN_PATHS,
  STORAGE_KEY,
} from "../Utils/Constants";
import { useLocation } from "react-router-dom";
import { ITripStretch, ITripStretchResume } from "../Services/TripService";
import { Tools, useComponentMount } from "../Utils";
import { AppContext } from "./AppContext";
import { EventAppContext } from "./EventAppProvider";
import { Preferences } from "@capacitor/preferences";
import { isDesktop } from "../DeltaUI/Utils/Constants";
import { COMPILATION_TYPE, config } from "../config";
import { ShareTripStrech } from "../Components/Share/ShareTripStrech";

interface IUpoolContext {
  isActiveApp: boolean;
  viewSplashScreen: boolean;
  setViewSplashScreen: SetterState<boolean>;
  viewOnboarding: boolean;
  setViewOnboarding: SetterState<boolean>;
  loading: boolean;
  setLoading: SetterState<boolean>;
  user: IUser | undefined;
  setUser: SetterState<IUser | undefined>;
  person: IPerson | undefined;
  userIsGenderF: boolean;
  notificationOneSignal?: INotification;
  setNotificationOneSignal: SetterState<INotification | undefined>;
  notificationGeneral: INotification[];
  setNotificationGeneral: SetterState<INotification[]>;
  selectPathTab: string;
  setSelectPathTab: SetterState<string>;
  historyGoBack: () => void;
  historyPush: (path: string, optinalParams?: string) => void;
  isDriverOfTheTrip: (tripStretch: ITripStretchResume) => boolean;
  roleTypeDriver: boolean;
  isViewTabs: boolean;
  setIsViewTabs: SetterState<boolean>;
  viewOtherUserId: number | undefined;
  setViewOtherUserId: SetterState<number | undefined>;
  createAccountWithUserSocialNetworks: IUserSocialNetworks | undefined;
  setCreateAccountWithUserSocialNetworks: SetterState<
    IUserSocialNetworks | undefined
  >;
  viewFullScreenImage: string | undefined;
  setViewFullScreenImage: SetterState<string | undefined>;
  notificationsIsNotReadedIds: number[];
  isMyTripsTab: boolean;
  goBackFunctionList: Array<() => void>;
  setGoBackFunctionList: SetterState<Array<() => void>>;
  authPayload: IAuthPayload | undefined;
  setAuthPayload: SetterState<IAuthPayload | undefined>;
  resolveAuthPayload: (res?: IAuthPayload | undefined) => void;
  confirmChangePage: ((onOk: () => void) => void) | undefined;
  setConfirmChangePage: SetterState<((onOk: () => void) => void) | undefined>;
  uploadProfilePictureFile: File | undefined;
  setUploadProfilePictureFile: SetterState<File | undefined>;
  pathname: string;
  shareTripStretch: ITripStretch | undefined;
  setShareTripStretch: SetterState<ITripStretch | undefined>;
}

const context: IUpoolContext = {
  isActiveApp: true,
  viewSplashScreen: true,
  setViewSplashScreen: () => {},
  viewOnboarding: false,
  setViewOnboarding: () => {},
  loading: false,
  setLoading: () => {},
  user: undefined,
  setUser: () => {},
  person: undefined,
  userIsGenderF: false,
  notificationOneSignal: undefined,
  setNotificationOneSignal: () => {},
  notificationGeneral: [],
  setNotificationGeneral: () => {},
  selectPathTab: "",
  setSelectPathTab: () => {},
  historyGoBack: () => {},
  historyPush: () => {},
  isDriverOfTheTrip: () => false,
  roleTypeDriver: false,
  isViewTabs: true,
  setIsViewTabs: () => {},
  viewOtherUserId: undefined,
  setViewOtherUserId: () => {},
  createAccountWithUserSocialNetworks: undefined,
  setCreateAccountWithUserSocialNetworks: () => {},
  viewFullScreenImage: undefined,
  setViewFullScreenImage: () => {},
  notificationsIsNotReadedIds: [],
  isMyTripsTab: false,
  goBackFunctionList: [],
  setGoBackFunctionList: () => {},
  authPayload: undefined,
  setAuthPayload: () => {},
  resolveAuthPayload: () => {},
  confirmChangePage: undefined,
  setConfirmChangePage: () => {},
  uploadProfilePictureFile: undefined,
  setUploadProfilePictureFile: () => {},
  pathname: "",
  shareTripStretch: undefined,
  setShareTripStretch: () => {},
};

export const UpoolContext = React.createContext(context);

export interface UpoolContextProviderProps {}

export const UpoolContextProvider: React.FC<UpoolContextProviderProps> = (
  props
) => {
  const isMountedComponent = useComponentMount("UpoolContextProvider");
  const params = React.useRef<string>(window.location.search);
  const {
    loading,
    setLoading,
    initUrlSearchParams,
    setInitUrlSearchParams,
  } = React.useContext(AppContext);
  const {
    eventAndroidGoBackButton,
    eventAndroidGoAppStateChange,
    handleEventNotificationTrip,
    eventWithoutConnection,
  } = React.useContext(EventAppContext);
  const history = useHistory();
  const { pathname } = useLocation();
  const { getPerson, isGenderF } = Tools();
  const [present] = useIonToast();

  const [goBackFunctionList, setGoBackFunctionList] = React.useState<
    Array<() => void>
  >([]);
  const [isActiveApp, setIsActiveApp] = React.useState<boolean>(true);
  const [viewSplashScreen, setViewSplashScreen] = React.useState<boolean>(true);
  const [viewOnboarding, setViewOnboarding] = React.useState<boolean>(false);
  const [user, setUser] = React.useState<IUser>();
  const [notificationOneSignal, setNotificationOneSignal] = React.useState<
    INotification | undefined
  >();
  const [notificationGeneral, setNotificationGeneral] = React.useState<
    INotification[]
  >([]);
  const [selectPathTab, setSelectPathTab] = React.useState<string>(
    APPLICATION_PATHS.HOME
  );
  const [isViewTabs, setIsViewTabs] = React.useState<boolean>(true);
  const [viewOtherUserId, setViewOtherUserId] = React.useState<
    number | undefined
  >();
  const [
    createAccountWithUserSocialNetworks,
    setCreateAccountWithUserSocialNetworks,
  ] = React.useState<IUserSocialNetworks | undefined>();
  const [viewFullScreenImage, setViewFullScreenImage] = React.useState<
    string | undefined
  >();
  const [authPayload, setAuthPayload] = React.useState<
    IAuthPayload | undefined
  >();
  const [confirmChangePage, setConfirmChangePage] = React.useState<
    ((onOk: () => void) => void) | undefined
  >();
  const [
    uploadProfilePictureFile,
    setUploadProfilePictureFile,
  ] = React.useState<File | undefined>();
  const [shareTripStretch, setShareTripStretch] = React.useState<
    ITripStretch | undefined
  >();

  const historyGoBack = () => {
    if (user) {
      if (pathname === "/tabs/home/default") {
        if (!isDesktop) {
          AppMinimize.minimize();
        }
      } else if (
        [
          `${APPLICATION_PATHS.USER}/user/default`,
          `${APPLICATION_PATHS.CREATE_TRIP}/default`,
          `${APPLICATION_PATHS.SEARCH_TRIP}/default`,
          `${APPLICATION_PATHS.MY_TRIPS}/default`,
          `${APPLICATION_PATHS.NOTIFICATIONS}/default`,
        ].some((p) => p === `${APPLICATION_PATHS.HOME}/default`)
      ) {
        historyPush(APPLICATION_PATHS.HOME);
      } else {
        history.goBack();
      }
    } else if (pathname.indexOf(APPLICATION_PATHS.SEARCH_TRIP_PUBLIC) >= 0) {
      if (pathname === "/public/search") {
        if (!isDesktop) {
          AppMinimize.minimize();
        }
      } else {
        history.goBack();
      }
    } else {
      if (pathname === "/login") {
        if (!isDesktop) {
          AppMinimize.minimize();
        }
      } else {
        historyPush("/login");
      }
    }
  };

  const historyPush = (path: string, optinalParams?: string) => {
    if (confirmChangePage) {
      confirmChangePage(() =>
        history.push(`${path}${optinalParams || params.current || ""}`)
      );
    } else {
      history.push(`${path}${optinalParams || params.current || ""}`);
    }
  };

  const isMyTripsTab = React.useMemo(() => {
    return !pathname.indexOf(APPLICATION_PATHS.MY_TRIPS);
  }, [pathname]);

  const roleTypeDriver = React.useMemo(
    () => !!(user && user.roleType === ROLE_TYPE.DRIVER),
    [user]
  );

  const isDriverOfTheTrip = (tripStretch: ITripStretchResume) =>
    user?.id === tripStretch.trip.userOwner.id;

  const notificationsIsNotReadedIds = React.useMemo(() => {
    return notificationGeneral.filter((n) => !n.readedAt).map((n) => n.id);
  }, [notificationGeneral]);

  const person = React.useMemo(() => {
    return getPerson(user, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const userIsGenderF = React.useMemo(() => {
    return isGenderF(person);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [person]);

  const handleGoBackList = () => {
    if (goBackFunctionList.length) {
      goBackFunctionList[goBackFunctionList.length - 1]();
    } else {
      historyGoBack();
    }
  };

  const resolveAuthPayload = (res?: IAuthPayload): void => {
    if (res) {
      Preferences.set({
        key: STORAGE_KEY.AUTH_PAYLOAD,
        value: JSON.stringify(res),
      });
    } else {
      Preferences.remove({ key: STORAGE_KEY.AUTH_PAYLOAD });
    }
    setAuthPayload(() => res);
  };

  React.useEffect(() => {
    if (isMountedComponent.current) {
      present({
        message: "Comprueba tu conexión y vuelve a intentar.",
        position: "top",
        color: "light",
        duration: 2000,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventWithoutConnection]);

  React.useEffect(() => {
    if (pathname.indexOf(APPLICATION_PATHS.USER) > -1) {
      setSelectPathTab(() => APPLICATION_PATHS.USER);
    } else if (pathname.indexOf(APPLICATION_PATHS.CREATE_TRIP) > -1) {
      setSelectPathTab(() => APPLICATION_PATHS.CREATE_TRIP);
    } else if (pathname.indexOf(APPLICATION_PATHS.HOME) > -1) {
      setSelectPathTab(() => APPLICATION_PATHS.HOME);
    } else if (pathname.indexOf(APPLICATION_PATHS.MY_TRIPS) > -1) {
      setSelectPathTab(() => APPLICATION_PATHS.MY_TRIPS);
    } else if (pathname.indexOf(APPLICATION_PATHS.NOTIFICATIONS) > -1) {
      setSelectPathTab(() => APPLICATION_PATHS.NOTIFICATIONS);
    } else if (pathname.indexOf(APPLICATION_PATHS.SEARCH_TRIP) > -1) {
      setSelectPathTab(() => APPLICATION_PATHS.SEARCH_TRIP);
    } else if (pathname.indexOf(APPLICATION_PATHS.SEARCH_TRIP_PUBLIC) > -1) {
      setSelectPathTab(() => APPLICATION_PATHS.SEARCH_TRIP_PUBLIC);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  React.useEffect(() => {
    if (TAB_BAR_AVAILABLE_IN_PATHS.some((i) => i === pathname)) {
      setIsViewTabs(() => true);
    } else {
      setIsViewTabs(() => false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  React.useEffect(() => {
    if (createAccountWithUserSocialNetworks) {
      historyPush("/register");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createAccountWithUserSocialNetworks]);

  React.useEffect(() => {
    if (COMPILATION_TYPE.MOBILE === config.compilationMode) {
      if (!user) {
        historyPush("/login");
      } else if (user && initUrlSearchParams) {
        historyPush(APPLICATION_PATHS.SEARCH_TRIP);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, initUrlSearchParams]);

  React.useEffect(() => {
    if (user && notificationOneSignal) {
      historyPush(APPLICATION_PATHS.MY_TRIPS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, notificationOneSignal]);

  React.useEffect(() => {
    if (isMountedComponent.current) {
      handleEventNotificationTrip();
      if (viewFullScreenImage) {
        setViewFullScreenImage(() => undefined);
      }
      handleGoBackList();
      setInitUrlSearchParams(() => undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventAndroidGoBackButton]);

  React.useEffect(() => {
    if (eventAndroidGoAppStateChange.data) {
      const { isActive } = eventAndroidGoAppStateChange.data;
      setIsActiveApp(() => isActive);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventAndroidGoAppStateChange]);

  React.useEffect(() => {
    isMountedComponent.current = true;
    return () => {
      isMountedComponent.current = false;
    };
  }, [isMountedComponent]);

  return (
    <UpoolContext.Provider
      value={{
        isActiveApp,
        viewSplashScreen,
        setViewSplashScreen,
        viewOnboarding,
        setViewOnboarding,
        loading,
        setLoading,
        user,
        setUser,
        person,
        userIsGenderF,
        notificationOneSignal,
        setNotificationOneSignal,
        notificationGeneral,
        setNotificationGeneral,
        selectPathTab,
        setSelectPathTab,
        historyGoBack,
        historyPush,
        isDriverOfTheTrip,
        roleTypeDriver,
        isViewTabs,
        setIsViewTabs,
        viewOtherUserId,
        setViewOtherUserId,
        createAccountWithUserSocialNetworks,
        setCreateAccountWithUserSocialNetworks,
        viewFullScreenImage,
        setViewFullScreenImage,
        notificationsIsNotReadedIds,
        isMyTripsTab,
        goBackFunctionList,
        setGoBackFunctionList,
        authPayload,
        setAuthPayload,
        resolveAuthPayload,
        confirmChangePage,
        setConfirmChangePage,
        uploadProfilePictureFile,
        setUploadProfilePictureFile,
        pathname,
        shareTripStretch,
        setShareTripStretch,
      }}
    >
      {shareTripStretch ? (
        <ShareTripStrech
          tripStrech={shareTripStretch}
          onFinish={() => setShareTripStretch(() => undefined)}
        />
      ) : null}
      {props.children}
    </UpoolContext.Provider>
  );
};
