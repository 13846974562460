import React from "react";
import { Separator } from "../../DeltaUI/Components";
import { ITripStretch, TripService } from "../../Services/TripService";
import { CardTrip } from "../Card";
import LoadData, { LoadDataSpin } from "../LoadData";
import { Virtuoso } from "react-virtuoso";
import { UpoolContext } from "../../Providers/UpoolContext";
import { DialogCustomHook } from "../DialogCustom";
import { AVAILABLE_SCREEN_SPACE_CSS } from "../../Utils/Constants";
import { useComponentMount } from "../../Utils";

export interface MyTripsStretchesProps {
  finished?: boolean;
}

const MyTripsStretches: React.FC<MyTripsStretchesProps> = (props) => {
  const isMountedComponent = useComponentMount("MyTripsStretches");
  const { finished } = props;
  const [
    loadingMyTripsStretches,
    setLoadingMyTripsStretches,
  ] = React.useState<boolean>(false);
  const {
    roleTypeDriver,
    isActiveApp,
    notificationOneSignal,
  } = React.useContext(UpoolContext);

  const { passengerTripsStretches, driverTripsStretches } = TripService();
  const { messageError } = DialogCustomHook();

  const [data, setData] = React.useState<ITripStretch[]>([]);

  const getData = (paginationId?: number) => {
    const serviceData = () =>
      roleTypeDriver
        ? driverTripsStretches(finished, paginationId)
        : passengerTripsStretches(finished, paginationId);

    setLoadingMyTripsStretches(() => true);
    serviceData()
      .then((res) => {
        if (isMountedComponent.current && res && res.length) {
          setData((d) => [...d, ...res]);
        }
      })
      .catch(({ message }) => {
        messageError({
          context: "MyTripsStretches.getData.1",
          message,
        });
      })
      .finally(() => setLoadingMyTripsStretches(() => false));
  };

  React.useEffect(() => {
    if (isActiveApp && !notificationOneSignal) {
      setData(() => []);
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActiveApp]);

  React.useEffect(() => {
    isMountedComponent.current = true;
    return () => {
      isMountedComponent.current = false;
    };
  }, [isMountedComponent]);

  return (
    <Virtuoso
      style={{
        height: `calc(${AVAILABLE_SCREEN_SPACE_CSS} - 56px - 48px - 48px)`,
      }}
      data={data}
      endReached={() =>
        getData(data.length ? data[data.length - 1].id : undefined)
      }
      overscan={200}
      itemContent={(_, t: ITripStretch) => {
        return (
          <>
            <Separator px={16} />
            <CardTrip
              key={t.id}
              tripStretchInitial={t}
              style={{
                position: "relative",
                margin: "8px 24px",
              }}
            />
          </>
        );
      }}
      components={{
        Footer: () => {
          return (
            <>
              {data.length && loadingMyTripsStretches ? (
                <LoadDataSpin />
              ) : (
                <LoadData
                  description="No hay resultados"
                  empty={!data.length}
                  loading={loadingMyTripsStretches}
                  height="40vh"
                >
                  <Separator px={16} />
                </LoadData>
              )}
            </>
          );
        },
      }}
    />
  );
};

export default MyTripsStretches;
