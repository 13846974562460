import React from "react";
import {
  BodyPage,
  SwipeableActionSheet,
  ToolbarMUI,
} from "../../DeltaUI/Components";
import PreferencesList from "./PreferencesList";
import { TitlePage } from "../TitlePage";
import { GoBack } from "../GoBack";
import { CustomPage } from "../CustomPage";
export interface ActionSheetPreferencesPageProps {
  open: boolean;
  onClose?: () => void;
  onOpen?: () => void;
}

const ActionSheetPreferencesPage: React.FC<ActionSheetPreferencesPageProps> = (
  props
) => {
  const { open, onClose } = props;

  return (
    <SwipeableActionSheet
      style={{ height: "100vh" }}
      open={open}
      onClose={onClose}
      type="page"
    >
      <CustomPage
        headerPage={
          <ToolbarMUI>
            <GoBack onClick={onClose} />
            <TitlePage text="Todas las preferencias" />
          </ToolbarMUI>
        }
      >
        <BodyPage m={3}>
          <div className="FlexColumn">
            <PreferencesList />
          </div>
        </BodyPage>
      </CustomPage>
    </SwipeableActionSheet>
  );
};

export default ActionSheetPreferencesPage;
