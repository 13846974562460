import { REGISTER_LOG } from "./LogMutation";
import {
  LOGIN,
  SIGNUP,
  LOGIN_FACEBOOK,
  LOGIN_GOOGLE,
  SIGNUP_FACEBOOK,
  SIGNUP_GOOGLE,
  DELETE_FACEBOOK,
  DELETE_GOOGLE,
  UPDATE_ONE_USER,
  SET_LAST_CONNECTION,
  ASSOCIATE_GOOGLE_ACCOUNT,
  ASSOCIATE_FACEBOOK_ACCOUNT,
  SEND_EMAIL_VERIFICATION_CODE,
  VERIFY_EMAIL_CODE,
  RESET_PASSWORD,
  LOGOUT,
  DELETE_PICTURE_URL,
  DELETE_PHONE,
  DELETE_EMAIL,
  REFRESH_TOKEN,
  CREATE_ONE_USER_COMMENT,
  LOGIN_APPLE,
  SIGNUP_APPLE,
  ASSOCIATE_APPLE_ACCOUNT,
  DELETE_APPLE,
  UPSERT_ONE_USER_DEVICE,
  DELETE_ONE_USER,
  VERIFY_ACCOUNT_DELETION,
  SEND_PHONE_VERIFICATION_CODE,
  VERIFY_PHONE_CODE,
} from "./UserMutation";
import {
  CREATE_ONE_RATE,
  CREATE_ONE_TRIP,
  DELETE_ONE_TRIP,
  EDIT_ONE_TRIP,
} from "./TripMutation";
import { CREATE_ONE_VEHICLE, DELETE_ONE_VEHICLE } from "./VehicleMutation";
import {
  CREATE_ONE_RESERVATION_CHAT,
  MARK_AS_READED_MANY_RESERVATION_CHATS,
} from "./ReservationChatMutation";
import {
  CANCEL_RESERVATION,
  CREATE_RESERVATION,
  REVIEW_ONE_RESERVATION,
} from "./ReservationMutation";
import {
  MARK_AS_ATTENDED_ALL_NOTIFICATIONS,
  MARK_AS_ATTENDED_MANY_NOTIFICATIONS,
  MARK_AS_READED_ALL_NOTIFICATIONS,
  MARK_AS_READED_MANY_NOTIFICATIONS,
} from "./NotificationMutation";
import { UPLOAD_PICTURE_URL } from "./FileUpload";
import { CREATE_ONE_SHARE } from "./ShareMutation";

export const Mutation = {
  registerLog: { name: "registerLog", gql: REGISTER_LOG },
  login: { name: "login", gql: LOGIN },
  signup: { name: "signup", gql: SIGNUP },
  loginGoogle: { name: "loginGoogle", gql: LOGIN_GOOGLE },
  loginFacebook: { name: "loginFacebook", gql: LOGIN_FACEBOOK },
  signupGoogle: { name: "signupGoogle", gql: SIGNUP_GOOGLE },
  signupFacebook: { name: "signupFacebook", gql: SIGNUP_FACEBOOK },
  deleteGoogle: { name: "deleteGoogle", gql: DELETE_GOOGLE },
  deleteFacebook: { name: "deleteFacebook", gql: DELETE_FACEBOOK },
  updateOneUser: { name: "updateOneUser", gql: UPDATE_ONE_USER },
  createOneTrip: { name: "createOneTrip", gql: CREATE_ONE_TRIP },
  createOneVehicle: { name: "createOneVehicle", gql: CREATE_ONE_VEHICLE },
  createOneReservationChat: {
    name: "createOneReservationChat",
    gql: CREATE_ONE_RESERVATION_CHAT,
  },
  createOneReservation: {
    name: "createOneReservation",
    gql: CREATE_RESERVATION,
  },
  reviewOneReservation: {
    name: "reviewOneReservation",
    gql: REVIEW_ONE_RESERVATION,
  },
  deleteOneTrip: {
    name: "deleteOneTrip",
    gql: DELETE_ONE_TRIP,
  },
  cancelOneReservation: {
    name: "cancelOneReservation",
    gql: CANCEL_RESERVATION,
  },
  createOneRate: {
    name: "createOneRate",
    gql: CREATE_ONE_RATE,
  },
  setLastConnection: {
    name: "setLastConnection",
    gql: SET_LAST_CONNECTION,
  },
  updateOneTrip: {
    name: "updateOneTrip",
    gql: EDIT_ONE_TRIP,
  },
  markAsReadedManyReservationChats: {
    name: "markAsReadedManyReservationChats",
    gql: MARK_AS_READED_MANY_RESERVATION_CHATS,
  },
  markAsAttendedAllNotifications: {
    name: "markAsAttendedAllNotifications",
    gql: MARK_AS_ATTENDED_ALL_NOTIFICATIONS,
  },
  markAsAttendedManyNotifications: {
    name: "markAsAttendedManyNotifications",
    gql: MARK_AS_ATTENDED_MANY_NOTIFICATIONS,
  },
  associateGoogleAccount: {
    name: "associateGoogleAccount",
    gql: ASSOCIATE_GOOGLE_ACCOUNT,
  },
  associateFacebookAccount: {
    name: "associateFacebookAccount",
    gql: ASSOCIATE_FACEBOOK_ACCOUNT,
  },
  uploadPictureUrl: {
    name: "uploadPictureUrl",
    gql: UPLOAD_PICTURE_URL,
  },
  markAsReadedAllNotifications: {
    name: "markAsReadedAllNotifications",
    gql: MARK_AS_READED_ALL_NOTIFICATIONS,
  },
  markAsReadedManyNotifications: {
    name: "markAsReadedManyNotifications",
    gql: MARK_AS_READED_MANY_NOTIFICATIONS,
  },
  sendEmailVerificationCode: {
    name: "sendEmailVerificationCode",
    gql: SEND_EMAIL_VERIFICATION_CODE,
  },
  sendPhoneVerificationCode: {
    name: "sendPhoneVerificationCode",
    gql: SEND_PHONE_VERIFICATION_CODE,
  },
  verifyEmailCode: {
    name: "verifyEmailCode",
    gql: VERIFY_EMAIL_CODE,
  },
  verifyPhoneCode: {
    name: "verifyPhoneCode",
    gql: VERIFY_PHONE_CODE,
  },
  resetPassword: {
    name: "resetPassword",
    gql: RESET_PASSWORD,
  },
  logout: {
    name: "logout",
    gql: LOGOUT,
  },
  deletePictureUrl: {
    name: "deletePictureUrl",
    gql: DELETE_PICTURE_URL,
  },
  deletePhone: {
    name: "deletePhone",
    gql: DELETE_PHONE,
  },
  deleteEmail: {
    name: "deleteEmail",
    gql: DELETE_EMAIL,
  },
  refreshToken: {
    name: "refreshToken",
    gql: REFRESH_TOKEN,
  },
  deleteOneVehicle: { name: "deleteOneVehicle", gql: DELETE_ONE_VEHICLE },
  createOneUserComment: {
    name: "createOneUserComment",
    gql: CREATE_ONE_USER_COMMENT,
  },
  loginApple: { name: "loginApple", gql: LOGIN_APPLE },
  signupApple: { name: "signupApple", gql: SIGNUP_APPLE },
  associateAppleAccount: {
    name: "associateAppleAccount",
    gql: ASSOCIATE_APPLE_ACCOUNT,
  },
  deleteApple: { name: "deleteApple", gql: DELETE_APPLE },
  upsertOneUserDevice: {
    name: "upsertOneUserDevice",
    gql: UPSERT_ONE_USER_DEVICE,
  },
  deleteOneUser: {
    name: "deleteOneUser",
    gql: DELETE_ONE_USER,
  },
  verifyAccountDeletion: {
    name: "verifyAccountDeletion",
    gql: VERIFY_ACCOUNT_DELETION,
  },
  createOneShare: {
    name: "createOneShare",
    gql: CREATE_ONE_SHARE,
  },
};
