import React from "react";
import { UpoolContext } from "../../Providers/UpoolContext";
import {
  ButtonMUI,
  BoxMUI,
  Separator,
  FormInputEmailOrPhone,
  TypographyMUI,
} from "../../DeltaUI/Components";
import { UserService } from "../../Services/UserService";
import { DialogCustomHook } from "../DialogCustom";
import {
  PhoneSettingContext,
  PHONE_SETTING_STEPS,
} from "./PhoneSettingContext";

interface SearchAccountProps {}

export const SearchAccount: React.FC<SearchAccountProps> = () => {
  const { userExists } = UserService();
  const { messageError, messageWarn } = DialogCustomHook();
  const { setLoading, user } = React.useContext(UpoolContext);
  const { setStep, phone, setPhone } = React.useContext(PhoneSettingContext);
  const [errorEmailOrPhone, setErrorEmailOrPhone] = React.useState<boolean>(
    false
  );

  const handleSearchUser = () => {
    if (user && phone === user.phone) {
      messageWarn({
        context: "PhoneSetting.handleSearchUser.1",
        message:
          "El teléfono que ingresaste es el mismo que estás usando actualmente.",
      });
    } else {
      const where: any = {
        phone,
      };
      setLoading(() => true);
      userExists(where)
        .then((u) => {
          if (!u) {
            setStep(() => PHONE_SETTING_STEPS.ENTRE_CODE);
          } else {
            messageWarn({
              context: "PhoneSetting.handleSearchUser.2",
              message:
                "Ya existe una cuenta asociada a este teléfono, intente utilizar otro.",
            });
          }
        })
        .catch(({ message }) => {
          messageError({
            context: "PhoneSetting.handleSearchUser.3",
            message: message,
          });
        })
        .finally(() => setLoading(() => false));
    }
  };

  return (
    <>
      <BoxMUI
        style={{
          width: "100%",
        }}
      >
        <TypographyMUI
          className="TypographyExtrabold26pt"
          sx={{ lineHeight: "24px" }}
        >
          Asociar teléfono
        </TypographyMUI>
        <Separator px={32} />
        <FormInputEmailOrPhone
          type="phone"
          value={phone}
          onChange={(text) => {
            setPhone(() => text);
          }}
          onError={(v) => setErrorEmailOrPhone(() => v)}
        />
      </BoxMUI>
      <BoxMUI
        style={{
          width: "100%",
        }}
      >
        <Separator px={16} />
        <ButtonMUI
          style={{
            width: "100%",
            marginTop: "16px",
          }}
          variant="contained"
          color="primary"
          onClick={handleSearchUser}
          disabled={!phone || errorEmailOrPhone}
        >
          Continuar
        </ButtonMUI>
        <Separator px={32} />
      </BoxMUI>
    </>
  );
};
