import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import { UpoolContext } from "../../Providers/UpoolContext";
import LoginPage from "../../Pages/LoginPage";
import RegisterPage from "../../Pages/RegisterPage";
import RecoverPasswordPage from "../../Pages/RecoverPasswordPage";

export interface LoginLayoutProps {}

const LoginLayout: React.FC<LoginLayoutProps> = () => {
  const { setUser } = React.useContext(UpoolContext);

  React.useEffect(() => {
    setUser(() => undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Switch>
      <Route exact path="/" render={() => <Redirect to="/login" />} />
      <Route exact path="/login" component={LoginPage} />
      <Route exact path="/register" component={RegisterPage} />
      <Route exact path="/recover-password" component={RecoverPasswordPage} />
    </Switch>
  );
};

export default LoginLayout;
