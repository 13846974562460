import React from "react";
import { IStep } from "../../DeltaUI/Components/Stepper/Stepper";
import {
  ICreateOneTrip,
  ITripStretch,
  TripService,
} from "../../Services/TripService";
import { IVehicle } from "../../Services/VehicleService";
import { MomentJS, useComponentMount } from "../../Utils";
import {
  RoomIcon,
  EventIcon,
  DirectionsCarIcon,
  TuneIcon,
  DoneIcon,
} from "../../DeltaUI/Components/CustomIcon";
import { PlaceResult, SetterState } from "../../Services/DefaultTypes";
import { IPlace } from "../../Services/PlaceService";
import { UpoolContext } from "../../Providers/UpoolContext";
import { TRIP_TYPE } from "../../Utils/Constants";
import { DialogCustomHook } from "../DialogCustom";

export enum STEPS {
  MAP,
  DATE,
  VEHICLE,
  PREFERENCE,
  RESUME,
}

const configSteps: IStep[] = [
  {
    id: STEPS.MAP,
    icon: <RoomIcon />,
    completed: false,
  },
  {
    id: STEPS.DATE,
    icon: <EventIcon />,
    completed: false,
  },
  {
    id: STEPS.VEHICLE,
    icon: <DirectionsCarIcon />,
    completed: false,
  },
  {
    id: STEPS.PREFERENCE,
    icon: <TuneIcon />,
    completed: false,
  },
  {
    id: STEPS.RESUME,
    icon: <DoneIcon />,
    completed: false,
  },
];

export enum STEPS_EDIT {
  DATE,
  VEHICLE,
  RESUME,
}

const configStepsEdit: IStep[] = [
  {
    id: STEPS_EDIT.DATE,
    icon: <EventIcon />,
    completed: true,
  },
  {
    id: STEPS_EDIT.VEHICLE,
    icon: <DirectionsCarIcon />,
    completed: true,
  },
  {
    id: STEPS_EDIT.RESUME,
    icon: <DoneIcon />,
    completed: true,
  },
];

const context: ICreateTrip = {
  clean: () => {},
  next: () => {},
  finish: () => {},
  step: 0,
  setStep: () => {},
  steps: [],
  setSteps: () => {},
  createTripEndMessage: false,
  setCreateTripEndMessage: () => {},

  originPlace: undefined,
  setOriginPlace: () => {},
  destinationPlace: undefined,
  setDestinationPlace: () => {},
  dateFrom: new Date(),
  setDateFrom: () => {},
  vehicle: undefined,
  setVehicle: () => {},
  totalPlaces: 1,
  setTotalPlaces: () => {},
  amount: undefined,
  setAmount: () => {},
  amountMin: undefined,
  setAmountMin: () => {},
  amountMax: undefined,
  setAmountMax: () => {},
  amountError: false,
  setAmountError: () => {},
  isMessageAmountSuggested: false,
  setIsMessageAmountSuggested: () => {},
  description: "",
  setDescription: () => {},
  tabResume: 1,
  setTabResume: () => {},
  tripTypeId: TRIP_TYPE.PARTICULAR,
  setTripTypeId: () => {},
  freeSeatMiddle: false,
  setFreeSeatMiddle: () => {},
  cancel: () => {},

  // Edit
  editTrip: undefined,
  editing: false,
  placeFrom: undefined,
  setPlaceFrom: () => {},
  placeTo: undefined,
  setPlaceTo: () => {},
  // Fin Edit

  resultCreateOneTrip: null as any,
};

export const CreateTripContext = React.createContext(context);

export interface CreateTripProviderProps {
  editTrip?: ITripStretch;
}

export const CreateTripProvider: React.FC<CreateTripProviderProps> = (
  props
) => {
  const isMountedComponent = useComponentMount("CreateTripProvider");
  const { setIsViewTabs } = React.useContext(UpoolContext);
  const { editTrip } = props;
  const calculateDateFrom = () => {
    const roundMinutes = (min: number) => {
      let current = 0;
      if (min === 0) {
        current = 0;
      } else if (min < 10) {
        current = 10;
      } else if (min > 50) {
        current = 60;
      } else {
        const m1 = `${min}`[0];
        current = Number(`${m1}0`) + 10;
      }
      return current;
    };

    const d = new Date();
    // redondeamos los minutos de d
    d.setMinutes(roundMinutes(d.getMinutes()));

    const z: Date = editTrip?.dateFrom
      ? createMoment({
          date: editTrip?.dateFrom,
        }).toDate()
      : d;

    return z;
  };
  const { messageError, message } = DialogCustomHook();
  const { createMoment } = MomentJS();
  const { tripSuggestedAmount } = TripService();
  const [steps, setSteps] = React.useState<IStep[]>(
    !editTrip ? configSteps : configStepsEdit
  );
  const [step, setStep] = React.useState(steps[0].id);
  const [
    createTripEndMessage,
    setCreateTripEndMessage,
  ] = React.useState<boolean>(false);

  const [originPlace, setOriginPlace] = React.useState<PlaceResult>();
  const [destinationPlace, setDestinationPlace] = React.useState<PlaceResult>();
  const [dateFrom, setDateFrom] = React.useState<Date>(calculateDateFrom());
  const [vehicle, setVehicle] = React.useState<IVehicle>();
  const [totalPlaces, setTotalPlaces] = React.useState<number>(1);
  const [amount, setAmount] = React.useState<number>();
  const [amountError, setAmountError] = React.useState<boolean>(false);
  const [
    isMessageAmountSuggested,
    setIsMessageAmountSuggested,
  ] = React.useState<boolean>(true);
  const [amountMax, setAmountMax] = React.useState<number>();
  const [amountMin, setAmountMin] = React.useState<number>();
  const [description, setDescription] = React.useState<string>("");
  const [tabResume, setTabResume] = React.useState<number>(1);
  const [tripTypeId, setTripTypeId] = React.useState<number>(
    TRIP_TYPE.PARTICULAR
  );
  const [freeSeatMiddle, setFreeSeatMiddle] = React.useState<boolean>(false);

  // Edit
  const [placeFrom, setPlaceFrom] = React.useState<IPlace | undefined>();
  const [placeTo, setPlaceTo] = React.useState<IPlace | undefined>();

  const editing = React.useMemo(() => !!editTrip, [editTrip]);
  // Fin Edit

  const resultCreateOneTrip = React.useRef<ICreateOneTrip>();

  const next = (current: number, next: number) => {
    setSteps((l) => {
      l.forEach((element) => {
        if (element.id === current) {
          element.completed = true;
        }
      });
      return [...l];
    });
    setStep(() => next);
  };

  const finish = () => {
    if (!editing) {
      setSteps((l) => {
        l.forEach((element) => {
          element.completed = false;
        });
        return [...l];
      });
      setStep(() => steps[0].id);
    }
  };

  const handleTripSuggestedAmount = () => {
    tripSuggestedAmount({
      placeIds: [
        originPlace?.place_id || placeFrom?.placeId || "",
        destinationPlace?.place_id || placeTo?.placeId || "",
      ],
    })
      .then(({ suggested, max, min }) => {
        if (isMountedComponent.current) {
          setAmount(() => suggested);
          setAmountMax(() => max);
          setAmountMin(() => min);
        }
      })
      .catch(({ message }) => {
        messageError({
          context: "CreateTripProvider.handleTripSuggestedAmount.1",
          message: message,
        });
      });
  };

  const clean = () => {
    setCreateTripEndMessage(() => false);
    finish();
    setOriginPlace(() => undefined);
    setDestinationPlace(() => undefined);
    setPlaceFrom(() => (!editTrip ? undefined : editTrip.placeFrom));
    setPlaceTo(() => (!editTrip ? undefined : editTrip.placeTo));
    setDateFrom(() => calculateDateFrom());
    setVehicle(() => (!editTrip ? undefined : editTrip.trip.vehicle));
    setTotalPlaces(() =>
      !editTrip ? 1 : editTrip.totalPlaces > 0 ? editTrip.totalPlaces : 1
    );
    setAmount(() => (!editTrip ? undefined : editTrip.amount));
    setDescription(() => (!editTrip ? "" : editTrip.trip.description));
    setTripTypeId(() =>
      !editTrip ? TRIP_TYPE.PARTICULAR : editTrip.trip.typeId
    );
    setFreeSeatMiddle(() => false);
  };

  const cancel = React.useMemo(() => {
    return (onOk?: () => void) => {
      if (
        (destinationPlace || placeFrom || originPlace || placeTo) &&
        !createTripEndMessage
      ) {
        message({
          context: "CreateTripProvider.cancel.1",
          title: "¿Querés cancelar tu viaje?",
          message: "Se perderán todos los datos que ingresaste.",
          textOnOk: "Sí",
          textOnCancel: "No",
          onOk,
        });
      } else if (onOk) {
        onOk();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [originPlace, placeTo, destinationPlace, placeFrom, createTripEndMessage]);

  /**
   * Actualizamos el precio
   */
  React.useEffect(() => {
    if (isMountedComponent.current && !editTrip) {
      setAmount(() => undefined);
      if (
        (destinationPlace || placeFrom) &&
        (originPlace || placeTo) &&
        vehicle &&
        !amount &&
        !editing
      ) {
        handleTripSuggestedAmount();
      }
    }
    // TODO: chequear estas dependencias
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [destinationPlace, originPlace, vehicle]);

  React.useEffect(() => {
    if (step === STEPS.MAP && !editTrip) {
      setIsViewTabs(() => true);
    } else {
      setIsViewTabs(() => false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  React.useEffect(() => {
    isMountedComponent.current = true;
    clean();
    return () => {
      isMountedComponent.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMountedComponent]);

  return (
    <CreateTripContext.Provider
      value={{
        clean,
        next,
        finish,
        step,
        setStep,
        steps,
        setSteps,
        createTripEndMessage,
        setCreateTripEndMessage,
        originPlace,
        setOriginPlace,
        destinationPlace,
        setDestinationPlace,
        dateFrom,
        setDateFrom,
        vehicle,
        setVehicle,
        totalPlaces,
        setTotalPlaces,
        amount,
        setAmount,
        amountMax,
        setAmountMax,
        amountMin,
        setAmountMin,
        amountError,
        setAmountError,
        isMessageAmountSuggested,
        setIsMessageAmountSuggested,
        description,
        setDescription,
        tabResume,
        setTabResume,
        tripTypeId,
        setTripTypeId,
        freeSeatMiddle,
        setFreeSeatMiddle,
        cancel,

        // Edit
        editTrip,
        editing,
        placeFrom,
        setPlaceFrom,
        placeTo,
        setPlaceTo,
        // Fin Edit

        resultCreateOneTrip,
      }}
    >
      {props.children}
    </CreateTripContext.Provider>
  );
};

interface ICreateTrip {
  clean: () => void;
  next: (current: number, next: number) => void;
  finish: () => void;
  step: number;
  setStep: SetterState<number>;
  steps: IStep[];
  setSteps: SetterState<IStep[]>;
  createTripEndMessage: boolean;
  setCreateTripEndMessage: SetterState<boolean>;

  originPlace: PlaceResult | undefined;
  setOriginPlace: SetterState<PlaceResult | undefined>;
  destinationPlace: PlaceResult | undefined;
  setDestinationPlace: SetterState<PlaceResult | undefined>;
  dateFrom: Date;
  setDateFrom: SetterState<Date>;
  vehicle?: IVehicle;
  setVehicle: SetterState<IVehicle | undefined>;
  totalPlaces: number;
  setTotalPlaces: SetterState<number>;
  isMessageAmountSuggested: boolean;
  setIsMessageAmountSuggested: SetterState<boolean>;
  amountError: boolean;
  setAmountError: SetterState<boolean>;
  amount?: number;
  setAmount: SetterState<number | undefined>;
  amountMin?: number;
  setAmountMin: SetterState<number | undefined>;
  amountMax?: number;
  setAmountMax: SetterState<number | undefined>;
  description: string;
  setDescription: SetterState<string>;
  tabResume: number;
  setTabResume: SetterState<number>;
  tripTypeId: number;
  setTripTypeId: SetterState<number>;
  freeSeatMiddle: boolean;
  setFreeSeatMiddle: SetterState<boolean>;
  cancel: (onOk?: () => void) => void;

  // Edit
  editTrip?: ITripStretch;
  editing: boolean;
  placeFrom?: IPlace;
  setPlaceFrom: SetterState<IPlace | undefined>;
  placeTo?: IPlace;
  setPlaceTo: SetterState<IPlace | undefined>;
  // Fin Edit

  resultCreateOneTrip: React.MutableRefObject<ICreateOneTrip | undefined>;
}
