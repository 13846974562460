import React from "react";
import ReactCodeInput from "react-code-input";
import { BoxMUI } from "..";

interface CodeVerifyInputProps {
  value?: string;
  style?: React.CSSProperties;
  className?: string;
  onChange?: (value: string) => void;
  color?: "primary" | "secondary";
}

export const CodeVerifyInput: React.FC<CodeVerifyInputProps> = (props) => {
  const { style, className, onChange, color, value } = props;
  const [key, setKey] = React.useState<any>();
  const ref = React.useRef<any>({});

  const valueInput = React.useMemo(() => {
    return value ? value : "";
  }, [value]);

  React.useEffect(() => {
    if (valueInput && ref.current) {
      ref.current.textInput.forEach((element: any, i: number) => {
        element.value = valueInput.slice(i, i + 1);
      });
    } else {
      setKey(() => new Date().getTime());
    }
  }, [valueInput]);

  return (
    <BoxMUI
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        ...style,
      }}
      className={className}
      sx={{
        "& .react-code-input": {
          "& input": {
            // fontFamily: "monospace !important",
            // border: "1px solid lightgrey !important",
            // margin: "4px !important",
            // width: "36px !important",
            // height: "42px !important",
            // boxSizing: "border-box !important",
            // backgroundColor: "white !important",
            padding: "0px !important",
            borderRadius: "6px !important",
            textAlign: "center !important",
            fontSize: "24px !important",
            boxShadow: "none !important",
            color: (theme) =>
              `${theme.palette[color || "primary"].main} !important`,
          },
          "& input:focus-visible": {
            outlineColor: (theme) => theme.palette[color || "primary"].main,
          },
        },
      }}
    >
      <ReactCodeInput
        key={key}
        ref={ref}
        name="CodeVerifyInput"
        inputMode="numeric"
        type="number"
        fields={6}
        onChange={(v) => {
          if (onChange) {
            onChange(v);
          }
        }}
      />
    </BoxMUI>
  );
};
