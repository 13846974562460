import React from "react";
import {
  BoxMUI,
  ButtonMUI,
  Separator,
  TypographyMUI,
} from "../../DeltaUI/Components";
import { COLOR_SECONDARY } from "../../Utils/Constants";
import { CustomPage } from "../CustomPage";

interface Onboarding1Props {
  style?: React.CSSProperties;
  className?: string;
  onClick?: () => void;
  image?: string;
}

export const Onboarding1: React.FC<Onboarding1Props> = (props) => {
  const { style, className, onClick, image } = props;

  return (
    <CustomPage
      style={{
        backgroundImage: `url(${image})`,
        ...style,
      }}
      className={`BackgroundImage ${className}`}
    >
      <BoxMUI
        style={{
          width: "100%",
          height: "51vh",
          position: "absolute",
          bottom: 0,
          left: 0,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-evenly",
          paddingTop: "40px",
        }}
      >
        <BoxMUI
          style={{
            textAlign: "center",
            color: "white",
            width: "320px",
          }}
        >
          <TypographyMUI className="TypographyBold20pt" color={COLOR_SECONDARY}>
            ¡Bienvenido
          </TypographyMUI>
          <TypographyMUI className="TypographyBold20pt" color={COLOR_SECONDARY}>
            a uPool!
          </TypographyMUI>
          <Separator px={8} />
          <TypographyMUI className="TypographyBold20pt">
            ¡Te registraste
          </TypographyMUI>
          <TypographyMUI className="TypographyBold20pt">
            exitosamente!
          </TypographyMUI>
        </BoxMUI>
        <ButtonMUI
          style={{ width: "50%" }}
          variant="contained"
          color="primary"
          onClick={onClick}
        >
          Continuar
        </ButtonMUI>
      </BoxMUI>
    </CustomPage>
  );
};
