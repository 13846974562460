import React from "react";
import { EnterCodePhone } from "./EnterCodePhone";
import { EnterCodeEmail } from "./EnterCodeEmail";
import { DeleteAccountContext } from "./DeleteAccountContext";

interface EnterCodeProps {
  style?: React.CSSProperties;
  className?: string;
}

export const EnterCode: React.FC<EnterCodeProps> = (props) => {
  const { valueType } = React.useContext(DeleteAccountContext);

  return (
    <>
      {valueType === "phone" ? (
        <EnterCodePhone {...props} />
      ) : (
        <EnterCodeEmail {...props} />
      )}
    </>
  );
};
