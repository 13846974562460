import React from "react";
import {
  DefaultContext,
  ErrorPolicy,
  FetchPolicy,
  FetchResult,
  Observable,
  Observer,
  OperationVariables,
  SubscriptionOptions,
} from "@apollo/client";
import { CustomApolloContext } from "../provider";
import { ApolloServiceError, IRequest, parseError } from "../utils";
interface SubscriptionComponentProps {
  config: IRequest;
  options?: CustomSubscriptionOptions;
  onNext?: (value: any) => void;
  onError?: (error: ApolloServiceError) => void;
}

export const SubscriptionComponent: React.FC<SubscriptionComponentProps> = (
  props
) => {
  const { config, options, onNext, onError } = props;
  const [callSubscription] = useCustomApolloSubscription(config);

  React.useEffect(() => {
    callSubscription({
      ...options,
      next: onNext,
      error: onError,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
};

export const useCustomApolloSubscription = (
  config: IRequest
): CustomSubscriptionOutput => {
  const { client } = React.useContext(CustomApolloContext);
  const [error, setError] = React.useState<ApolloServiceError>();
  const subscription = React.useRef<{
    unsubscribe: () => any;
    closed: boolean;
  }>({
    unsubscribe: () => {},
    closed: false,
  });

  const clientSubscribe = React.useMemo(() => {
    return client.watchQuery;
  }, [client]);

  const call = React.useCallback(
    (options: CustomSubscriptionOptions) => {
      const handleError = (err: any | Error) => {
        if (options.error) {
          options.error(parseError(err));
          setError(() => parseError(err));
        }
        subscription.current.unsubscribe();
      };

      const handleNext = (result: any) => {
        try {
          if (result?.data && result?.data[config.name] && options.next) {
            options.next(result?.data[config.name]);
          } else {
            handleError(
              new Error(
                `Ocurrió un error al recibir los datos de la subscripción ${config.name}`
              )
            );
          }
        } catch (error) {
          handleError(error);
        }
      };

      subscription.current = clientSubscribe({
        ...{
          fetchPolicy: "no-cache",
          ...options,
        },
        query: config.gql,
      }).subscribe({
        ...options,
        next: handleNext,
        error: handleError,
      });
    },
    [clientSubscribe, config]
  );

  React.useEffect(() => {
    return () => {
      subscription.current.unsubscribe();
    };
  }, []);

  return [
    call,
    {
      subscription,
      clientSubscribe,
      error,
    },
  ];
};

export interface CustomSubscriptionOptions<TVariables = OperationVariables>
  extends Observer<any> {
  query?: IRequest;
  variables?: TVariables;
  fetchPolicy?: FetchPolicy;
  errorPolicy?: ErrorPolicy;
  context?: DefaultContext;
}
export type CustomSubscriptionCall = (
  options: CustomSubscriptionOptions
) => void;
export type CustomSubscriptionRequest = {
  subscription: React.MutableRefObject<{
    unsubscribe: () => any;
    closed: boolean;
  }>;
  error: ApolloServiceError | undefined;
  clientSubscribe<T = any, TVariables = OperationVariables>(
    options: SubscriptionOptions<TVariables, T>
  ): Observable<FetchResult<T>>;
};
export type CustomSubscriptionOutput = [
  CustomSubscriptionCall,
  CustomSubscriptionRequest
];
