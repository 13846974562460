import React from "react";
import {
  BoxMUI,
  ButtonMUI,
  Separator,
  TypographyMUI,
} from "../../DeltaUI/Components";
import { COLOR_PRIMARY } from "../../Utils/Constants";
import { CustomPage } from "../CustomPage";

interface Onboarding3Props {
  style?: React.CSSProperties;
  className?: string;
  onClick?: () => void;
  image?: string;
}

export const Onboarding3: React.FC<Onboarding3Props> = (props) => {
  const { style, className, onClick, image } = props;

  return (
    <CustomPage
      style={{
        backgroundImage: `url(${image})`,
        ...style,
      }}
      className={`BackgroundImage ${className}`}
    >
      <BoxMUI
        style={{
          width: "100%",
          height: "51vh",
          position: "absolute",
          bottom: 0,
          left: 0,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-evenly",
        }}
      >
        <BoxMUI
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            margin: "0 32px",
            paddingTop: "24px",
          }}
        >
          <TypographyMUI className="TypographyBold20pt">
            Si sos conductor
          </TypographyMUI>
          <Separator px={24} />
          <BoxMUI
            style={{
              textAlign: "center",
              width: "270px",
            }}
          >
            <TypographyMUI
              color={COLOR_PRIMARY}
              className="TypographyRegular11pt"
            >
              Vas a poder crear viajes y determinar la cantidad de lugares
              disponibles en tu auto.
            </TypographyMUI>
          </BoxMUI>
        </BoxMUI>
        <ButtonMUI
          style={{ width: "50%" }}
          variant="contained"
          color="primary"
          onClick={onClick}
        >
          Comenzar tu viaje
        </ButtonMUI>
      </BoxMUI>
    </CustomPage>
  );
};
