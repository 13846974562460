import React from "react";
import {
  ListItemIconMUI,
  ListItemMUI,
  ListItemTextMUI,
  ListMUI,
  ListSubheaderMUI,
  SwipeableDrawerMUI,
  TypographyMUI,
} from "..";

const fn = () => {};
export interface SwipeableActionSheetProps {
  style?: React.CSSProperties;
  open: boolean;
  onClose?: () => void;
  onOpen?: () => void;
  borderRadius?: string;
  type: "page" | "options";
}

const SwipeableActionSheet: React.FC<SwipeableActionSheetProps> = (props) => {
  const { children, open, onClose, onOpen, style, borderRadius, type } = props;

  return (
    <SwipeableDrawerMUI
      style={style}
      sx={{
        "& .MuiDrawer-paperAnchorBottom": {
          height: "inherit",
          paddingBottom: "var(--ios-action-footer-height)",
          borderRadius,
        },
      }}
      anchor="bottom"
      open={open}
      onClose={onClose || fn}
      onOpen={onOpen || fn}
      disableBackdropTransition={true}
    >
      {type === "options" ? (
        <ListMUI
          style={{ paddingBottom: "24px" }}
          component="nav"
          subheader={
            <ListSubheaderMUI
              style={{ padding: "24px" }}
              component="div"
              id="nested-list-subheader"
            >
              <TypographyMUI className="TypographyBold11pt">
                Opciones
              </TypographyMUI>
            </ListSubheaderMUI>
          }
        >
          {children}
        </ListMUI>
      ) : (
        children
      )}
    </SwipeableDrawerMUI>
  );
};

export default SwipeableActionSheet;

interface SwipeableActionSheetItemProps {
  style?: React.CSSProperties;
  className?: string;
  onClick: () => void;
  text: string;
  icon: JSX.Element;
  disabled?: boolean;
}

export const SwipeableActionSheetItem: React.FC<SwipeableActionSheetItemProps> = (
  props
) => {
  const { style, className, onClick, text, icon, disabled } = props;

  return (
    <ListItemMUI
      style={{ padding: "8px 24px", ...style }}
      className={className}
      button
      onClick={onClick}
      disabled={disabled}
    >
      <ListItemIconMUI>{icon}</ListItemIconMUI>
      <ListItemTextMUI primary={text} />
    </ListItemMUI>
  );
};
