import React from "react";
import { UpoolContext } from "../../Providers/UpoolContext";
import {
  ButtonMUI,
  BoxMUI,
  Separator,
  TypographyMUI,
  FormControlLabelMUI,
  RadioMUI,
  RadioGroupMUI,
} from "../../DeltaUI/Components";
import { DialogCustomHook } from "../DialogCustom";
import {
  RecoverPasswordContext,
  RECOVER_PASSWORD_STEPS,
} from "./RecoverPasswordContext";

interface RecoveryOptionsProps {}

export const RecoveryOptions: React.FC<RecoveryOptionsProps> = () => {
  const { historyGoBack } = React.useContext(UpoolContext);
  const { setStep, userToRecover, valueType, setValueType } = React.useContext(
    RecoverPasswordContext
  );
  const { messageWarn } = DialogCustomHook();

  const blockEmail = React.useMemo(() => {
    if (userToRecover) {
      const mapEmail = userToRecover.email
        ? userToRecover.email.split("@")
        : [];
      if (mapEmail.length) {
        const l = mapEmail[0].length;
        const i = Number.parseFloat(`${l / 2}`).toFixed(0);
        return `${mapEmail[0].slice(0, l - Number(i))}****@${mapEmail[1]}`;
      }
    }
    return null;
  }, [userToRecover]);

  React.useEffect(() => {
    if (userToRecover) {
      setValueType(() => (userToRecover?.email ? "email" : "phone"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <BoxMUI
        style={{
          width: "100%",
        }}
      >
        <Separator px={8} />
        <TypographyMUI className="TypographyBold11pt">
          Opciones para verificar tu cuenta
        </TypographyMUI>
        <Separator px={16} />
        <RadioGroupMUI
          defaultValue={userToRecover?.email ? "email" : "phone"}
          onChange={(ev) => setValueType(() => ev.target.value as any)}
        >
          {userToRecover?.email ? (
            <FormControlLabelMUI
              sx={{
                "& .MuiTypography-root": {
                  fontFamily: "'Open Sans Regular' !important",
                  fontSize: "10pt !important",
                },
              }}
              value="email"
              control={<RadioMUI style={{ marginLeft: "8px" }} />}
              label={`Enviar un código de confirmación por correo electrónico a ${blockEmail}`}
              labelPlacement="start"
              style={{ margin: "0" }}
            />
          ) : null}
          {userToRecover?.phone ? (
            <>
              {userToRecover?.email ? <Separator px={16} divider /> : null}
              <FormControlLabelMUI
                value="phone"
                control={<RadioMUI style={{ marginLeft: "8px" }} />}
                sx={{
                  "& .MuiTypography-root": {
                    fontFamily: "'Open Sans Regular' !important",
                    fontSize: "10pt !important",
                  },
                }}
                label={`Enviar un código de confirmación por WhatsApp a mi teléfono terminado en ${userToRecover.phone.slice(
                  userToRecover.phone.length - 4,
                  userToRecover.phone.length
                )}`}
                labelPlacement="start"
                style={{ margin: "0" }}
              />
            </>
          ) : null}
        </RadioGroupMUI>
      </BoxMUI>
      <BoxMUI
        style={{
          width: "100%",
        }}
      >
        <ButtonMUI
          style={{
            width: "100%",
            marginTop: "16px",
          }}
          variant="outlined"
          color="primary"
          onClick={() => {
            messageWarn({
              context: "RecoverPassword.RecoveryOptions.1",
              message: "Contacta a un administrador para que podamos ayudarte.",
            });
            historyGoBack();
          }}
        >
          Ver más opciones
        </ButtonMUI>
        <Separator px={16} />
        <ButtonMUI
          style={{
            width: "100%",
            marginTop: "16px",
          }}
          variant="contained"
          color="primary"
          onClick={() => setStep(() => RECOVER_PASSWORD_STEPS.ENTRE_CODE)}
          disabled={!valueType}
        >
          Continuar
        </ButtonMUI>
        <Separator px={32} />
      </BoxMUI>
    </>
  );
};
