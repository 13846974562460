import React from "react";
import { ToolbarMUI, MenuButton, BodyPage } from "../DeltaUI/Components";
import { TitlePage } from "../Components/TitlePage";
import { TermsAndConditions } from "../Components/User/TermsAndConditions";
import { COLOR_PRIMARY } from "../Utils/Constants";
import { CustomPage } from "../Components";

const TermsAndConditionsPage: React.FC = () => {
  return (
    <CustomPage
      headerPage={
        <ToolbarMUI>
          <MenuButton style={{ color: COLOR_PRIMARY }} />
          <TitlePage text="Términos y condiciones" />
        </ToolbarMUI>
      }
    >
      <BodyPage m={3}>
        <TermsAndConditions />
      </BodyPage>
    </CustomPage>
  );
};

export default TermsAndConditionsPage;
