import { gql } from "@apollo/client";
import { FragmentService } from "../FragmentService";

export const CREATE_ONE_TRIP = gql`
  mutation createOneTrip($data: TripCreateInput!) {
    createOneTrip(data: $data) {
      id
      tripStretches {
        ...FTripStretch
      }
    }
  }
  ${FragmentService.TripStretch}
`;

export const DELETE_ONE_TRIP = gql`
  mutation deleteOneTrip($where: TripWhereUniqueInput!) {
    deleteOneTrip(where: $where) {
      id
    }
  }
`;

export const CREATE_ONE_RATE = gql`
  mutation createOneRate($data: RateCreateInput!) {
    createOneRate(data: $data) {
      id
    }
  }
`;

export const EDIT_ONE_TRIP = gql`
  mutation updateOneTrip(
    $data: TripUpdateInput!
    $where: TripWhereUniqueInput!
  ) {
    updateOneTrip(where: $where, data: $data) {
      id
    }
  }
`;
