import React from "react";
import { BoxMUI, EmptyAntd, Separator } from "../DeltaUI/Components";

export interface EmptyDataProps {
  description: string;
  style?: React.CSSProperties;
  height?: string;
  icon?: JSX.Element;
  button?: JSX.Element;
}

const EmptyData: React.FC<EmptyDataProps> = (props) => {
  const { description, height, style, icon, button } = props;
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: " flex-end",
        height,
        ...style,
      }}
    >
      {icon ? (
        <div className="ant-empty ant-empty-normal">
          <BoxMUI
            className="ant-empty-image"
            sx={{
              "& svg": {
                height: "100%",
                margin: "auto",
                fontSize: "3rem",
              },
            }}
          >
            {icon}
          </BoxMUI>
          <div className="ant-empty-description">{description}</div>
          {button ? (
            <>
              <Separator px={8} />
              {button}
            </>
          ) : null}
        </div>
      ) : (
        <EmptyAntd
          style={{
            margin: "16px 0",
          }}
          description={description}
          image={EmptyAntd.PRESENTED_IMAGE_SIMPLE}
        />
      )}
    </div>
  );
};

export default EmptyData;
