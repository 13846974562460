import { config, ENVIRONMENT } from "../config";

/**
 * se definen las constantes globales
 */
const COLOR_PRIMARY = "#3f5aa6";
const COLOR_SECONDARY = "#f5835e";
const COLOR_TERTIARY = "#9daefc";
const COLOR_QUATERNARY = "#28264B";
const COLOR_QUINARY = "#828899";

/**
 * @description Lista de códigos de error del servidor
 */
enum ERROR_CODE {
  FAILED_TO_FETCH = "Failed to fetch",
  INTERNAL_SERVER_ERROR = "INTERNAL_SERVER_ERROR",
  FORBIDDEN = "FORBIDDEN",
  UNAUTHENTICATED = "UNAUTHENTICATED",
  BAD_USER_INPUT = "BAD_USER_INPUT",
  USER_NOT_EXIST = "USER_NOT_EXIST",
  USER_EXIST = "USER_EXIST",
}

enum TAKE {
  OTHER = 20,
  MESSAGES = 50,
}

enum APPLICATION_PATHS {
  USER = "/profile",
  HOME = "/tabs/home",
  CREATE_TRIP = "/tabs/create-trip",
  SEARCH_TRIP = "/tabs/search",
  MY_TRIPS = "/tabs/my-trips",
  NOTIFICATIONS = "/tabs/notifications",
  SEARCH_TRIP_PUBLIC = "/public/search",
}

enum ROLE_TYPE {
  PASSENGER = "PASSENGER",
  DRIVER = "DRIVER",
}

enum TRIP_STATUS {
  AVAILABLE = 1,
  IN_PROGRESS,
  DONE,
  CANCELED,
}

enum RESERVATION_STATUS {
  CREATED = 1,
  DELETED_BY_PASSENGER,
  IN_REVIEW,
  REJECTED_BY_DRIVER,
  REJECTED_BY_SYSTEM,
  IN_PROGRESS,
  CANCELED_BY_PASSENGER,
  CANCELED_BY_DRIVER,
  FINISHED,
}

enum DETAIL_TRIP_TAB {
  RESUME = 1,
  RESERVATIONS,
  MAP,
}

enum DETAIL_TRIP_MODE {
  SEARCH_TRIP,
  MY_TRIPS,
  WEB,
}

const TAB_BAR_AVAILABLE_IN_PATHS = [
  "/tabs/home",
  "/tabs/home/default",
  "/tabs/create-trip",
  "/tabs/create-trip/default",
  "/tabs/search",
  "/tabs/search/default",
  "/tabs/my-trips",
  "/tabs/my-trips/default",
  "/tabs/notifications",
  "/tabs/notifications/default",
];

enum TRIP_TYPE {
  PARTICULAR = 1,
  REMIS = 2,
}

const VIEW_MAX_PREFERENCES: number = 50;

enum NOTIFICATION_TYPE {
  MESSENGER = "MESSENGER",
  RESERVATION_REQUEST = "RESERVATION_REQUEST",
  RESERVATION_REVIEW_APPROVED = "RESERVATION_REVIEW_APPROVED",
  RESERVATION_REVIEW_REJECTED = "RESERVATION_REVIEW_REJECTED",
  RESERVATION_REJECTED_BY_SYSTEM = "RESERVATION_REJECTED_BY_SYSTEM",
  RESERVATION_CONFIRM = "RESERVATION_CONFIRM",
  RESERVATION_CANCEL = "RESERVATION_CANCEL",
  TRIP_UPDATED = "TRIP_UPDATED",
  TRIP_IN_PROGRESS = "TRIP_IN_PROGRESS",
  TRIP_DONE = "TRIP_DONE",
  RATE_CREATED = "RATE_CREATED",
  TRIP_RATE_REMINDER = "TRIP_RATE_REMINDER",
}

enum PREFERENCES {
  FREE_SEAT_MIDDLE = 1,
  ONLY_WOMEN = 2,
}

enum PERSON_GENDER {
  F = 1,
  M = 2,
  X = 3,
}

enum RADIUS {
  MAX = 100,
  MIN = 1,
}

enum STORAGE_KEY {
  MESSAGES_PENDING_SEND = "MESSAGES_PENDING_SEND",
  AUTH_PAYLOAD = "AUTH_PAYLOAD",
  WELCOME_TO_MY_TRIPS = "WELCOME_TO_MY_TRIPS",
  TIP_TO_SHARE = "TIP_TO_SHARE",
}

const AVAILABLE_SCREEN_SPACE_CSS =
  "100vh - var(--mobile-status-bar-height) - var(--ios-action-footer-height)";

enum URL {
  UPOOL = "https://upool.com.ar",
  UPOOL_AR = "https://upool.ar",
  DELTA_IT = "https://deltait.com.ar",
}

enum URL_APP_FRONT_BY_ENV_NAME {
  LOCAL = "http://localhost:3000",
  TEST = "https://sharetest.upool.ar",
  STAGE = "https://sharestage.upool.ar",
  PROD = "https://share.upool.ar",
}

const getUrlFront = (envName: string) => {
  switch (envName) {
    case ENVIRONMENT.TEST:
      return URL_APP_FRONT_BY_ENV_NAME.TEST;
    case ENVIRONMENT.STAGE:
      return URL_APP_FRONT_BY_ENV_NAME.STAGE;
    case ENVIRONMENT.PROD:
      return URL_APP_FRONT_BY_ENV_NAME.PROD;
    default:
      return URL_APP_FRONT_BY_ENV_NAME.LOCAL;
  }
};

const URL_APP_FRONT = getUrlFront(config.envName);
enum APP_AUTH {
  APP_NAME = "UPOOL_APP",
  APP_KEY = "124aa48c71bc6139e6ccc183b03e394b",
}

export {
  COLOR_PRIMARY,
  COLOR_SECONDARY,
  COLOR_TERTIARY,
  COLOR_QUATERNARY,
  COLOR_QUINARY,
  ERROR_CODE,
  TAKE,
  APPLICATION_PATHS,
  ROLE_TYPE,
  TRIP_STATUS,
  RESERVATION_STATUS,
  DETAIL_TRIP_TAB,
  DETAIL_TRIP_MODE,
  TAB_BAR_AVAILABLE_IN_PATHS,
  TRIP_TYPE,
  VIEW_MAX_PREFERENCES,
  NOTIFICATION_TYPE,
  PREFERENCES,
  PERSON_GENDER,
  RADIUS,
  STORAGE_KEY,
  AVAILABLE_SCREEN_SPACE_CSS,
  URL,
  URL_APP_FRONT_BY_ENV_NAME,
  URL_APP_FRONT,
  APP_AUTH,
};
