import React from "react";
import {
  ListItemMUI,
  ListItemIconMUI,
  ListItemTextMUI,
  TypographyMUI,
} from "../../DeltaUI/Components";
import { PersonIcon } from "../../DeltaUI/Components/CustomIcon";
import { SearchTripContext } from "./SearchTripContext";
import AvailablePlaces from "../AvailablePlaces";
import { COLOR_TERTIARY } from "../../Utils/Constants";
import { DialogCustom, DialogCustomFooterActions } from "../DialogCustom";

export interface SearchTripHomePlacesProps {}

const SearchTripHomePlaces: React.FC<SearchTripHomePlacesProps> = (props) => {
  const { availablePlaces, setAvailablePlaces } =
    React.useContext(SearchTripContext);
  const [viewAvailablePlaces, setViewAvailablePlaces] =
    React.useState<boolean>(false);

  return (
    <>
      <ListItemMUI
        className="ContentInputCustom"
        style={{
          width: "52%",
          height: "42px",
          padding: "8px 0",
        }}
        onClick={() => {
          setViewAvailablePlaces(() => true);
        }}
      >
        <ListItemIconMUI
          style={{
            minWidth: "24px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "0 4px",
          }}
        >
          <PersonIcon style={{ color: COLOR_TERTIARY }} />
        </ListItemIconMUI>
        <ListItemTextMUI
          style={{
            marginLeft: "8px",
          }}
          primary={
            <TypographyMUI className="TypographyBold11pt">
              {availablePlaces} {availablePlaces > 1 ? "pasajeros" : "pasajero"}
            </TypographyMUI>
          }
        />
      </ListItemMUI>
      <DialogCustom
        context="SearchTripHomePlaces.1"
        title={
          <TypographyMUI className="FullWidth TextCenter TypographyBold13pt">
            Pasajeros
          </TypographyMUI>
        }
        open={viewAvailablePlaces}
        onClose={() => setViewAvailablePlaces(() => false)}
        body={
          <AvailablePlaces
            value={availablePlaces}
            onChange={(v) => setAvailablePlaces(() => v)}
          />
        }
        footer={
          <DialogCustomFooterActions
            onOk={() => setViewAvailablePlaces(() => false)}
            onCancel={() => setViewAvailablePlaces(() => false)}
          />
        }
      />
    </>
  );
};

export default SearchTripHomePlaces;
