import React from "react";
import "./Loader3.css";

interface Loader3Props {
  style?: React.CSSProperties;
  className?: string;
}

export const Loader3: React.FC<Loader3Props> = (props) => {
  const { style, className } = props;
  return <div style={style} className={`Loader3 ${className}`} />;
};
