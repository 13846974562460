import React from "react";
import { UpoolContext } from "../../Providers/UpoolContext";
import { EventAppContext } from "../../Providers/EventAppProvider";
import { SetterState } from "../../Services/DefaultTypes";
import { IReservation } from "../../Services/ReservationService";
import { IRate, ITripStretch, TripService } from "../../Services/TripService";
import { Tools, useComponentMount, useCustomLog } from "../../Utils";
import {
  DETAIL_TRIP_TAB,
  RESERVATION_STATUS,
  TRIP_STATUS,
} from "../../Utils/Constants";
import DialogConfirmCancelReservation from "../Dialog/DialogConfirmCancelReservation";
import { MyTripsNotificacionsProvider } from "./MyTripsNotificacionsContext";
import { INotification } from "../../Services/NotificationService";
import DialogRatingView from "../Dialog/DialogRatingView";
import DialogCancelMessageView from "../Dialog/DialogCancelMessageView";
import { ChatProvider } from "../Chat/ChatContext";

export enum MY_TRIPS_TAB {
  CURRENT = 1,
  END,
}
interface IMyTrips {
  detailTripMode: number;
  isDriverOfSelectedTripStretch: boolean;
  reservationForChat: IReservation | undefined;
  setReservationForChat: SetterState<IReservation | undefined>;
  loadingMyTrips: boolean;
  setLoadingMyTrips: SetterState<boolean>;
  reservationForRate: IReservation | undefined;
  setReservationForRate: SetterState<IReservation | undefined>;
  selectTripStretchForEdit: ITripStretch | undefined;
  setSelectTripStretchForEdit: SetterState<ITripStretch | undefined>;
  selectTripStretchChatsCount: number;
  selectTripStretch?: ITripStretch;
  setSelectTripStretch: SetterState<ITripStretch | undefined>;
  validateIsOnlyWomen: boolean;
  updateSelectTripStretch: Function;
  detailTripTab: number;
  setDetailTripTab: SetterState<number>;
  myTripsTab: number;
  setMyTripsTab: SetterState<number>;
  selectTripStretchReservations: IReservation[];
  searchTripEndMessage: boolean;
  setSearchTripEndMessage: SetterState<boolean>;
  cancelReservation: IReservation | undefined;
  setCancelReservation: SetterState<IReservation | undefined>;
  handleOnNotification: (n: INotification) => void;
  ratingView: IRatingView | undefined;
  setRatingView: SetterState<IRatingView | undefined>;
  isRateCalculate: (t?: ITripStretch) => number;
  canceledMessageView: IReservation | undefined;
  setCanceledMessageView: SetterState<IReservation | undefined>;
}

const context: IMyTrips = {
  detailTripMode: 1,
  isDriverOfSelectedTripStretch: false,
  reservationForChat: undefined,
  setReservationForChat: () => {},
  loadingMyTrips: false,
  setLoadingMyTrips: () => {},
  reservationForRate: undefined,
  setReservationForRate: () => {},
  selectTripStretchForEdit: undefined,
  setSelectTripStretchForEdit: () => {},
  selectTripStretchChatsCount: 0,
  selectTripStretch: undefined,
  setSelectTripStretch: () => {},
  validateIsOnlyWomen: true,
  updateSelectTripStretch: () => {},
  detailTripTab: DETAIL_TRIP_TAB.RESUME,
  setDetailTripTab: () => {},
  myTripsTab: MY_TRIPS_TAB.CURRENT,
  setMyTripsTab: () => {},
  selectTripStretchReservations: [],
  searchTripEndMessage: false,
  setSearchTripEndMessage: () => {},
  cancelReservation: undefined,
  setCancelReservation: () => {},
  handleOnNotification: () => {},
  ratingView: undefined,
  setRatingView: () => {},
  isRateCalculate: (() => {}) as any,
  canceledMessageView: undefined,
  setCanceledMessageView: () => {},
};

export const MyTripsContext = React.createContext(context);

export interface MyTripsProviderProps {
  detailTripMode: number;
}

export const MyTripsProvider: React.FC<MyTripsProviderProps> = (props) => {
  const isMountedComponent = useComponentMount("MyTripsProvider");
  const { detailTripMode } = props;

  const { tripStretch } = TripService();
  const { tripStretchIsOnlyWomen } = Tools();
  const Log = useCustomLog();
  const {
    notificationGeneral,
    roleTypeDriver,
    user,
    userIsGenderF,
    notificationOneSignal,
    isMyTripsTab,
    historyPush,
    selectPathTab,
    setLoading,
    pathname,
  } = React.useContext(UpoolContext);
  const { eventUpdateTrip, handleEventNotificationTrip } = React.useContext(
    EventAppContext
  );

  const [reservationForChat, setReservationForChat] = React.useState<
    IReservation | undefined
  >();
  const [reservationForRate, setReservationForRate] = React.useState<
    IReservation | undefined
  >();
  const [loadingMyTrips, setLoadingMyTrips] = React.useState<boolean>(false);
  const [
    selectTripStretchForEdit,
    setSelectTripStretchForEdit,
  ] = React.useState<ITripStretch | undefined>();
  const [selectTripStretch, setSelectTripStretch] = React.useState<
    ITripStretch | undefined
  >();
  const [detailTripTab, setDetailTripTab] = React.useState<number>(
    DETAIL_TRIP_TAB.RESUME
  );
  const [myTripsTab, setMyTripsTab] = React.useState<number>(
    MY_TRIPS_TAB.CURRENT
  );
  const [
    searchTripEndMessage,
    setSearchTripEndMessage,
  ] = React.useState<boolean>(false);
  const [cancelReservation, setCancelReservation] = React.useState<
    IReservation | undefined
  >();
  const [ratingView, setRatingView] = React.useState<IRatingView | undefined>();
  const [canceledMessageView, setCanceledMessageView] = React.useState<
    IReservation | undefined
  >();

  const resetContext = React.useCallback(() => {
    setReservationForChat(() => undefined);
    setReservationForRate(() => undefined);
    setLoadingMyTrips(() => false);
    setSelectTripStretchForEdit(() => undefined);
    setSelectTripStretch(() => undefined);
    setDetailTripTab(() => DETAIL_TRIP_TAB.RESUME);
    setMyTripsTab(() => MY_TRIPS_TAB.CURRENT);
    setSearchTripEndMessage(() => false);
    setCancelReservation(() => undefined);
    setRatingView(() => undefined);
    setCanceledMessageView(() => undefined);
  }, []);

  const selectTripStretchReservations = React.useMemo(() => {
    return selectTripStretch ? selectTripStretch.reservations : [];
  }, [selectTripStretch]);

  const validateIsOnlyWomen = React.useMemo(() => {
    return (
      (userIsGenderF && tripStretchIsOnlyWomen(selectTripStretch)) ||
      !tripStretchIsOnlyWomen(selectTripStretch)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectTripStretch, userIsGenderF]);

  const isDriverOfSelectedTripStretch = React.useMemo(() => {
    return !!(
      user &&
      selectTripStretch &&
      selectTripStretch.trip.userOwner.id === user.id
    );
  }, [selectTripStretch, user]);

  const selectTripStretchChatsCount = React.useMemo(() => {
    return selectTripStretchReservations.reduce(
      (count, r) => (r.unreadChats ? count + r.unreadChats : count),
      0
    );
  }, [selectTripStretchReservations]);

  const isRateCalculate = React.useMemo(() => {
    return (tripStretch?: ITripStretch) => {
      let count = 0;
      if (
        tripStretch &&
        [TRIP_STATUS.CANCELED, TRIP_STATUS.DONE].some(
          (statusId) => tripStretch.trip.statusId === statusId
        )
      ) {
        if (tripStretch?.myReservation) {
          if (
            [
              RESERVATION_STATUS.CANCELED_BY_DRIVER,
              RESERVATION_STATUS.CANCELED_BY_PASSENGER,
              RESERVATION_STATUS.FINISHED,
            ].some(
              (statusId) => tripStretch.myReservation?.statusId === statusId
            ) &&
            !tripStretch.myReservation.rates.some(
              (r) => r.userFromId === user?.id
            )
          ) {
            count = count + 1;
          }
        } else {
          count =
            count +
            tripStretch.reservations.filter(
              (reservation) =>
                [
                  RESERVATION_STATUS.CANCELED_BY_DRIVER,
                  RESERVATION_STATUS.CANCELED_BY_PASSENGER,
                  RESERVATION_STATUS.FINISHED,
                ].some((statusId) => reservation.statusId === statusId) &&
                !reservation.rates.some((r) => r.userFromId === user?.id)
            ).length;
        }
      }
      return count;
    };
  }, [user]);

  const updateSelectTripStretch = async () => {
    if (selectTripStretch) {
      tripStretch(selectTripStretch.id)
        .then((ts) => {
          if (isMountedComponent.current && ts) {
            setSelectTripStretch((current) => {
              if (current) {
                return ts;
              }
              return current;
            });
          }
        })
        .catch(({ message }) => {
          Log.error({
            context: "MyTripsProvider.updateSelectTripStretch.1",
            message,
          });
        });
    }
  };

  const handleOnNotification = (n: INotification) => {
    resetContext();
    setLoading(() => true);
    handleEventNotificationTrip(n);
  };

  React.useEffect(() => {
    if (
      isMountedComponent.current &&
      reservationForChat &&
      (pathname === `${selectPathTab}/detail-trip` ||
        pathname === `${selectPathTab}/chat-list`)
    ) {
      setLoading(() => false);
      historyPush(`${selectPathTab}/chat`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reservationForChat, pathname]);

  React.useEffect(() => {
    if (isMountedComponent.current) {
      updateSelectTripStretch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventUpdateTrip, notificationGeneral]);

  React.useEffect(() => {
    setMyTripsTab(() => MY_TRIPS_TAB.CURRENT);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roleTypeDriver]);

  React.useEffect(() => {
    if (!selectTripStretch) {
      setDetailTripTab(() => DETAIL_TRIP_TAB.RESUME);
    } else if (
      isMountedComponent.current &&
      selectTripStretch &&
      (pathname === `${selectPathTab}/default` ||
        pathname === `${selectPathTab}/result`)
    ) {
      setLoading(() => false);
      historyPush(`${selectPathTab}/detail-trip`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectTripStretch]);

  React.useEffect(() => {
    if (isMyTripsTab && notificationOneSignal) {
      handleOnNotification(notificationOneSignal);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationOneSignal]);

  React.useEffect(() => {
    isMountedComponent.current = true;
    return () => {
      isMountedComponent.current = false;
    };
  }, [isMountedComponent]);

  return (
    <MyTripsContext.Provider
      value={{
        detailTripMode,
        isDriverOfSelectedTripStretch,
        reservationForChat,
        setReservationForChat,
        loadingMyTrips,
        setLoadingMyTrips,
        reservationForRate,
        setReservationForRate,
        selectTripStretchForEdit,
        setSelectTripStretchForEdit,
        selectTripStretchChatsCount,
        selectTripStretch,
        setSelectTripStretch,
        validateIsOnlyWomen,
        updateSelectTripStretch,
        detailTripTab,
        setDetailTripTab,
        myTripsTab,
        setMyTripsTab,
        selectTripStretchReservations,
        searchTripEndMessage,
        setSearchTripEndMessage,
        cancelReservation,
        setCancelReservation,
        handleOnNotification,
        ratingView,
        setRatingView,
        isRateCalculate,
        canceledMessageView,
        setCanceledMessageView,
      }}
    >
      {cancelReservation ? (
        <DialogConfirmCancelReservation reservation={cancelReservation} />
      ) : null}
      {ratingView ? <DialogRatingView /> : null}
      {canceledMessageView ? <DialogCancelMessageView /> : null}
      <MyTripsNotificacionsProvider>
        <ChatProvider>{props.children}</ChatProvider>
      </MyTripsNotificacionsProvider>
    </MyTripsContext.Provider>
  );
};

export interface IRatingView {
  rate: IRate;
  userFromIsDriver: boolean;
}
