import React from "react";
import {
  ToolbarMUI,
  BodyPage,
  ButtonMUI,
  Separator,
  TypographyMUI,
  RatingMUI,
  BoxMUI,
} from "../DeltaUI/Components";
import TextArea from "../Components/Form/TextArea";
import { MyTripsContext } from "../Components/MyTrips/MyTripsContext";
import { TripService } from "../Services/TripService";
import { UpoolContext } from "../Providers/UpoolContext";
import { EventAppContext } from "../Providers/EventAppProvider";
import ItemAvatarUser from "../Components/User/ItemAvatarUser";
import { CustomPage, DialogCustomHook } from "../Components";
import { GoBack } from "../Components/GoBack";

const RatePage: React.FC = () => {
  const { handleEventUpdateTrip } = React.useContext(EventAppContext);
  const {
    reservationForRate,
    isDriverOfSelectedTripStretch,
  } = React.useContext(MyTripsContext);
  const { setLoading, historyGoBack } = React.useContext(UpoolContext);
  const [description, setDescription] = React.useState<string>("");
  const [score, setScore] = React.useState<number>(0);

  const { createOneRate } = TripService();
  const { messageError, message } = DialogCustomHook();

  const goBack = () => {
    historyGoBack();
  };

  const handleCreateOneRate = () => {
    if (reservationForRate) {
      setLoading(() => true);
      createOneRate({
        score,
        reservationId: reservationForRate.id,
        description,
      })
        .then(() => {
          handleEventUpdateTrip();
          message({
            context: "RatePage.handleCreateOneRate.1",
            title: "¡Usuario calificado!",
            message: (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <TypographyMUI className="TypographyRegular11pt">
                  Tu calificación fue registrada.
                </TypographyMUI>
                <TypographyMUI className="TypographyRegular11pt">
                  ¡Muchas gracias!
                </TypographyMUI>
              </div>
            ),
          });
          goBack();
        })
        .catch(({ message }) => {
          messageError({
            context: "RatePage.handleCreateOneRate.2",
            message: message,
          });
        })
        .finally(() => setLoading(() => false));
    }
  };

  return (
    <CustomPage
      headerPage={
        <ToolbarMUI>
          <GoBack onClick={goBack} />
          <ItemAvatarUser user={reservationForRate?.user} />
        </ToolbarMUI>
      }
    >
      <BodyPage m={3}>
        <Separator px={8} />
        <TypographyMUI className="TypographyExtrabold11pt">
          ¿Qué puntaje le das al{" "}
          {!isDriverOfSelectedTripStretch ? "conductor" : "pasajero"}?
        </TypographyMUI>
        <div className="FlexColumn FlexCenter">
          <Separator px={16} />
          <RatingMUI
            sx={{
              "& .MuiRating-icon": {
                fontSize: "30pt !important",
              },
            }}
            name="simple-controlled"
            value={score}
            onChange={(_, newValue) => {
              if (newValue) {
                setScore(() => newValue);
              } else {
                setScore(() => 0);
              }
            }}
          />
        </div>
        <Separator px={32} />
        <TypographyMUI className="TypographyExtrabold11pt">
          Deja tu comentario
        </TypographyMUI>
        <Separator px={8} />
        <BoxMUI className="ContentInputCustom" style={{ padding: "0 4px" }}>
          <TextArea
            placeholder="Agregá lo que quieras..."
            value={description}
            onChange={(ev) => setDescription(() => ev)}
          />
        </BoxMUI>
      </BodyPage>
      <BoxMUI style={{ padding: "16px 24px" }}>
        <ButtonMUI
          className="FullWidth"
          variant="contained"
          color="primary"
          onClick={handleCreateOneRate}
          disabled={!reservationForRate || !score || !description}
        >
          Calificar
        </ButtonMUI>
      </BoxMUI>
    </CustomPage>
  );
};

export default RatePage;
