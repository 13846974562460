import React from "react";
import {
  ListItemMUI,
  ListItemTextMUI,
  Separator,
  TypographyMUI,
} from "../../../DeltaUI/Components";
import { MomentJS } from "../../../Utils";
import { SearchTripContext } from "../SearchTripContext";
import AdvancedFiltersInputCalendar from "./AdvancedFiltersInputCalendar";

export interface AdvancedFiltersCalendarProps {}

const AdvancedFiltersCalendar: React.FC<AdvancedFiltersCalendarProps> = (
  props
) => {
  const {
    dateFrom,
    setDateFrom,
    dateTo,
    setDateTo,
    minDate,
    maxDate,
  } = React.useContext(SearchTripContext);
  const { addToDate } = MomentJS();

  const minDateFrom: Date = React.useMemo(() => minDate, [minDate]);
  const minDateTo: Date = React.useMemo(() => {
    return dateFrom ? dateFrom : addToDate(1, "d", minDate).toDate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [minDate, dateFrom]);

  return (
    <>
      <ListItemMUI button>
        <ListItemTextMUI
          primary={
            <TypographyMUI className="TypographyExtrabold11pt">
              Rango de fechas
            </TypographyMUI>
          }
        />
      </ListItemMUI>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          padding: "0 16px",
        }}
      >
        <AdvancedFiltersInputCalendar
          date={dateFrom}
          onChange={(ev) => setDateFrom(() => ev)}
          minDate={minDateFrom}
          maxDate={maxDate}
        />
        <Separator px={16} orientation="vertical" />
        <AdvancedFiltersInputCalendar
          date={dateTo}
          onChange={(ev) => setDateTo(() => ev)}
          minDate={minDateTo}
          maxDate={maxDate}
        />
      </div>
    </>
  );
};

export default AdvancedFiltersCalendar;
