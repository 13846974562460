import React from "react";
import { ListItemMUI, ItemCarousel } from "..";

export interface ListItemCarouselProps {
  separation: 0 | 8 | 16 | 32 | 64 | 128;
}

const ListItemCarousel: React.FC<ListItemCarouselProps> = (props) => {
  const { children, separation } = props;
  return (
    <ListItemMUI
      style={{
        padding: "0",
      }}
      button
    >
      <ItemCarousel separation={separation}>{children}</ItemCarousel>
    </ListItemMUI>
  );
};

export default ListItemCarousel;
