import { Preferences } from "@capacitor/preferences";
import { STORAGE_KEY, TAKE } from "../Utils/Constants";
import { useCustomApolloAsync } from "./GraphQL/ApolloService";
import { Mutation } from "./GraphQL/Mutation";
import { Query } from "./GraphQL/Query";
import { IUser } from "./UserService";

export const ReservationChatService = () => {
  const { queryAsync, mutationAsync } = useCustomApolloAsync();

  /**
   * Crea mensajes en una reserva
   * @param data
   */
  const createOneReservationChat = (
    data: ICreateOneReservationChat
  ): Promise<IReservationChat> => {
    return mutationAsync({
      mutation: Mutation.createOneReservationChat,
      variables: {
        data,
      },
    });
  };

  /**
   * Devuelve la lista de chat de una reservación
   * @param data
   */
  const reservationChats = (
    reservationId: number,
    paginationId?: number
  ): Promise<IReservationChat[]> => {
    const optional: any = {};
    if (paginationId) {
      optional.after = {
        id: paginationId,
      };
    }
    return queryAsync({
      query: Query.reservationChats,
      variables: {
        where: {
          reservationId: {
            equals: reservationId,
          },
          deletedAt: {
            equals: null,
          },
        },
        orderBy: {
          createdAt: "desc",
        },
        first: TAKE.MESSAGES,
        ...optional,
      },
    });
  };

  /**
   * Marcar los mensajes como leidos
   * @param data
   */
  const markAsReadedManyReservationChats = (
    reservationId: number
  ): Promise<any> => {
    return mutationAsync({
      mutation: Mutation.markAsReadedManyReservationChats,
      variables: {
        where: { reservationId },
      },
    });
  };

  const saveChats = async (
    reservationId: number,
    chats: IReservationChat[]
  ) => {
    const save: any = {};
    save[`${reservationId}`] = chats;
    Preferences.set({
      key: STORAGE_KEY.MESSAGES_PENDING_SEND,
      value: JSON.stringify(save),
    });
  };

  const cleanChats = async (reservationId: number) => {
    const save: any = {};
    save[`${reservationId}`] = [];
    Preferences.set({
      key: STORAGE_KEY.MESSAGES_PENDING_SEND,
      value: JSON.stringify(save),
    });
  };

  const markMessageAsSent = async (
    reservationId: number,
    externalId: string
  ): Promise<IReservationChat[]> => {
    const value = await Preferences.get({
      key: STORAGE_KEY.MESSAGES_PENDING_SEND,
    }).then((s) => (s.value ? JSON.parse(s.value) : undefined));
    if (value && value[`${reservationId}`]) {
      value[`${reservationId}`] = (value[
        `${reservationId}`
      ] as IReservationChat[]).filter((c) => c.externalId !== externalId);
      return Preferences.set({
        key: STORAGE_KEY.MESSAGES_PENDING_SEND,
        value: JSON.stringify(value),
      }).then(() => value[`${reservationId}`] as IReservationChat[]);
    } else {
      return Promise.resolve([]);
    }
  };

  const getListOfMessagesToSend = async (
    reservationId: number
  ): Promise<IReservationChat[]> => {
    const value = await Preferences.get({
      key: STORAGE_KEY.MESSAGES_PENDING_SEND,
    }).then((s) => (s.value ? JSON.parse(s.value) : undefined));
    if (value && value[`${reservationId}`]) {
      return value[`${reservationId}`] as IReservationChat[];
    }
    return [];
  };

  return {
    createOneReservationChat,
    reservationChats,
    markAsReadedManyReservationChats,
    saveChats,
    markMessageAsSent,
    getListOfMessagesToSend,
    cleanChats,
  };
};

export interface ICreateOneReservationChat {
  description: string;
  reservationId: number;
  externalId: string;
  sendedAt: Date;
}

export interface IReservationChat {
  id: number;
  reservationId: number;
  description: string;
  createdAt: Date;
  createdById: number;
  createdByUser: IUser;
  sendedAt?: Date;
  readedAt?: Date;
  receivedAt?: Date;
  externalId: string;
  myMessage?: boolean;
  consecutive?: boolean;
  separator?: Date;
}

export interface ICreateReservationToChatResult {
  chats: IReservationChat[];
  id: number;
  status: {
    code: string;
    name: string;
  };
}
