import React from "react";
import { DialogCustomHook } from "..";
import {
  ButtonMUI,
  BoxMUI,
  Separator,
  TypographyMUI,
  CodeVerifyInput,
} from "../../DeltaUI/Components";
import { UpoolContext } from "../../Providers/UpoolContext";
import { UserService } from "../../Services/UserService";
import { useComponentMount } from "../../Utils";
import { RegisterContext, REGISTER_STEPS } from "./RegisterContext";

interface EnterCodePhoneProps {}

export const EnterCodePhone: React.FC<EnterCodePhoneProps> = () => {
  const isMountedComponent = useComponentMount("EnterCodePhone");

  const { sendPhoneVerificationCode, verifyPhoneCode } = UserService();
  const { messageError, messageWarn } = DialogCustomHook();
  const { setLoading } = React.useContext(UpoolContext);
  const { setStep, setUserToRegister, userToRegister } = React.useContext(
    RegisterContext
  );

  const [code, setCode] = React.useState<string>("");

  const handleConfirm = () => {
    if (
      code &&
      userToRegister &&
      userToRegister.emailOrPhone &&
      userToRegister.valueType === "phone"
    ) {
      setLoading(() => true);
      verifyPhoneCode(userToRegister.emailOrPhone, code)
        .then((res) => {
          if (!res) {
            setCode(() => "");
            messageWarn({
              context: "Register.EnterCodePhone.1",
              message: "El código no es correcto.",
            });
          } else {
            setUserToRegister({
              verified: true,
              verificationToken: res,
            });
            setStep(() => REGISTER_STEPS.PASSWORD);
          }
        })
        .catch(({ message }) => {
          setCode(() => "");
          messageError({
            context: "Register.EnterCodePhone.2",
            message,
          });
        })
        .finally(() => setLoading(() => false));
    } else {
      messageError({
        context: "Register.EnterCodePhone.3",
        message: "Faltan datos de registro.",
      });
    }
  };

  const handleInitVerify = () => {
    if (
      userToRegister &&
      userToRegister.emailOrPhone &&
      userToRegister.valueType === "phone"
    ) {
      setLoading(() => true);
      sendPhoneVerificationCode(userToRegister.emailOrPhone)
        .then((res) => {
          if (!res) {
            messageError({
              context: "Register.EnterCodePhone.3",
              message: "No se puede enviar el código.",
            });
          }
        })
        .catch(({ message }) => {
          messageError({
            context: "Register.EnterCodePhone.4",
            message,
          });
        })
        .finally(() => setLoading(() => false));
    } else {
      messageError({
        context: "Register.EnterCodePhone.5",
        message: "Faltan datos de registro.",
      });
    }
  };

  const handleReVerify = () => {
    setCode(() => "");
    handleInitVerify();
  };

  React.useEffect(() => {
    handleInitVerify();
    isMountedComponent.current = true;
    return () => {
      isMountedComponent.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMountedComponent]);

  return (
    <>
      <BoxMUI
        style={{
          width: "100%",
        }}
      >
        <Separator px={8} />
        <TypographyMUI className="TypographyExtrabold11pt">
          Revisá tu teléfono
        </TypographyMUI>
        <Separator px={16} />
        <TypographyMUI className="TypographyRegular11pt">
          Ingresa a continuación el código que te enviamos por WhatsApp para
          validar tu número de teléfono.
        </TypographyMUI>
        <Separator px={24} />
        <TypographyMUI variant="overline">Introducí tu código</TypographyMUI>
        <Separator px={8} />
        <CodeVerifyInput
          value={code}
          color="secondary"
          onChange={(v) => setCode(() => v)}
        />
      </BoxMUI>
      <BoxMUI
        style={{
          width: "100%",
        }}
      >
        <ButtonMUI
          style={{
            width: "100%",
            marginTop: "16px",
          }}
          variant="outlined"
          color="primary"
          onClick={handleReVerify}
        >
          Reenviar código
        </ButtonMUI>
        <Separator px={16} />
        <ButtonMUI
          style={{
            width: "100%",
            marginTop: "16px",
          }}
          variant="contained"
          color="primary"
          onClick={handleConfirm}
          disabled={code.length < 6}
        >
          Continuar
        </ButtonMUI>
        <Separator px={32} />
      </BoxMUI>
    </>
  );
};
