import React from "react";
import { SwipeableActionSheet, SwipeableActionSheetItem } from "..";

const fn = () => {};

interface IOption {
  icon: JSX.Element;
  label: string;
  onClick: () => void;
}

export interface ActionSheetOptionsProps {
  open: boolean;
  onClose: () => void;
  onOpen?: () => void;
  listOptions: IOption[];
}

const ActionSheetOptions: React.FC<ActionSheetOptionsProps> = (props) => {
  const { open, onClose, onOpen, listOptions } = props;
  return (
    <SwipeableActionSheet
      open={open}
      onClose={onClose}
      onOpen={onOpen || fn}
      type="options"
    >
      {listOptions.length
        ? listOptions.map((op, index) => (
            <SwipeableActionSheetItem
              key={index}
              onClick={op.onClick}
              text={op.label}
              icon={op.icon}
            />
          ))
        : null}
    </SwipeableActionSheet>
  );
};

export default ActionSheetOptions;
