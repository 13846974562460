import React from "react";
import {
  ListItemMUI,
  ListItemTextMUI,
  TypographyMUI,
} from "../../DeltaUI/Components";
import { IUser } from "../../Services/UserService";
import { Tools } from "../../Utils";
import UserAvatar from "./UserAvatar";

const styles: any = {
  item: {
    padding: "0",
  },
  text: {
    margin: "0",
    marginLeft: "8px",
  },
};

export interface ItemAvatarUserProps {
  user?: IUser;
}

const ItemAvatarUser: React.FC<ItemAvatarUserProps> = (props) => {
  const { user } = props;

  const { getPerson } = Tools();

  const person = React.useMemo(
    () => getPerson(user, 0),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user]
  );

  const avatar = React.useMemo(
    () => <UserAvatar size={4} user={user} />,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user]
  );

  return (
    <div className="FlexRow">
      <ListItemMUI style={styles.item}>
        <div>{avatar}</div>
        <ListItemTextMUI
          style={styles.text}
          primary={
            person ? (
              <TypographyMUI className="TypographyExtrabold13pt">
                {person?.firstName} {person?.lastName}
              </TypographyMUI>
            ) : (
              ""
            )
          }
        />
      </ListItemMUI>
    </div>
  );
};

export default ItemAvatarUser;
