import React from "react";
import { menuController } from "@ionic/core";
import { MenuRoundedIcon } from "../CustomIcon";
import { BoxMUI, FabMUI, IconButtonMUI } from "..";

export interface ButtonGoBackProps {
  fab?: boolean;
  style?: React.CSSProperties;
}

const MenuButton: React.FC<ButtonGoBackProps> = (props) => {
  const { fab, style } = props;
  return (
    <>
      {fab ? (
        <BoxMUI sx={{ "& > :not(style)": { m: 1 } }}>
          <FabMUI
            color="primary"
            size="small"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginLeft: "-8px",
              ...style,
            }}
            onClick={() => {
              menuController.open();
            }}
          >
            <MenuRoundedIcon />
          </FabMUI>
        </BoxMUI>
      ) : (
        <IconButtonMUI
          edge="start"
          style={style}
          onClick={() => {
            menuController.open();
          }}
        >
          <MenuRoundedIcon />
        </IconButtonMUI>
      )}
    </>
  );
};

export default MenuButton;
