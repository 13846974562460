import React from "react";
import { useComponentMount } from "../../Utils";
import CardVehiclePlaces from "../User/CardVehiclePlaces";
import CreateOneVehicle from "../User/CreateOneVehicle";
import MyVehicles from "../User/MyVehicles";
import { CreateTripContext } from "./CreateTripContext";
import { CreateTripNextButton } from "./CreateTripNextButton";
export interface CreateTripVehicleProps {
  onNext: () => void;
}

const CreateTripVehicle: React.FC<CreateTripVehicleProps> = (props) => {
  const isMountedComponent = useComponentMount("CreateTripVehicle");
  const { onNext } = props;
  const {
    vehicle,
    setVehicle,
    totalPlaces,
    setTotalPlaces,
    editing,
    editTrip,
    freeSeatMiddle,
    setFreeSeatMiddle,
  } = React.useContext(CreateTripContext);
  const [create, setCreate] = React.useState(false);

  React.useEffect(() => {
    if (!vehicle) {
      setTotalPlaces(() => 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicle]);

  React.useEffect(() => {
    isMountedComponent.current = true;
    return () => {
      isMountedComponent.current = false;
    };
  }, [isMountedComponent]);

  return (
    <>
      {!vehicle ? (
        <MyVehicles onChange={(v) => setVehicle(() => v)} />
      ) : (
        <CardVehiclePlaces
          vehicle={vehicle}
          places={totalPlaces}
          min={editTrip ? editTrip.usedPlaces : undefined}
          onPlaces={(v) => setTotalPlaces(() => v)}
          freeSeatMiddle={freeSeatMiddle}
          onFreeSeatMiddle={(v) => setFreeSeatMiddle(() => v)}
          edit={editing}
        />
      )}
      {!editing ? (
        <CreateTripNextButton
          textCancel={vehicle ? "Cambiar auto" : "Agregar auto"}
          onCancel={
            vehicle
              ? () => setVehicle(() => undefined)
              : () => setCreate(() => true)
          }
          disabled={!vehicle}
          onClick={onNext}
        />
      ) : (
        <CreateTripNextButton disabled={!vehicle} onClick={onNext} />
      )}
      {create ? (
        <CreateOneVehicle open={true} onClose={() => setCreate(() => false)} />
      ) : null}
    </>
  );
};

export default CreateTripVehicle;
