import React from "react";
import {
  BoxMUI,
  ButtonMUI,
  CardMUI,
  Separator,
  TypographyMUI,
} from "../../DeltaUI/Components";
import { UpoolContext } from "../../Providers/UpoolContext";
import { RESERVATION_STATUS } from "../../Utils/Constants";
import { DialogCustom } from "../DialogCustom";
import { MyTripsContext } from "../MyTrips/MyTripsContext";
import { ItemUser } from "../User/ItemUser";

export interface DialogCancelMessageViewProps {}

const DialogCancelMessageView: React.FC<DialogCancelMessageViewProps> = () => {
  const { user } = React.useContext(UpoolContext);
  const { canceledMessageView, setCanceledMessageView } = React.useContext(
    MyTripsContext
  );

  const canceledByPassenger = React.useMemo(() => {
    return (
      canceledMessageView?.statusId === RESERVATION_STATUS.CANCELED_BY_PASSENGER
    );
  }, [canceledMessageView]);

  const isMyReservation = React.useMemo(() => {
    return canceledMessageView?.userId === user?.id;
  }, [canceledMessageView, user]);

  const onClose = () => {
    setCanceledMessageView(() => undefined);
  };

  return (
    <DialogCustom
      context="DialogCancelMessageView.1"
      title={
        canceledMessageView ? (
          <>
            <Separator px={8} />
            {canceledByPassenger ? (
              <TypographyMUI
                className="TypographyExtrabold11pt"
                style={{ textAlign: "center" }}
              >
                {isMyReservation ? "Te diste de baja" : "Se dió de baja"}
              </TypographyMUI>
            ) : (
              <TypographyMUI
                className="TypographyExtrabold11pt"
                style={{ textAlign: "center" }}
              >
                {isMyReservation ? "Te dió de baja" : "Diste de baja a"}
              </TypographyMUI>
            )}
            <Separator px={24} />
            {!isMyReservation ? (
              <>
                <ItemUser
                  user={canceledMessageView.user}
                  isDrive={false}
                  sizeAvatar={5}
                  disabled={true}
                />
                <Separator px={16} />
              </>
            ) : null}
            <Separator px={0} divider />
          </>
        ) : undefined
      }
      open={true}
      body={
        <>
          <TypographyMUI className="TypographyExtrabold11pt">
            Comentario
          </TypographyMUI>
          <Separator px={8} />
          <CardMUI
            className="FlexRow"
            elevation={0}
            style={{ padding: "8px 16px" }}
          >
            <TypographyMUI>
              {canceledMessageView?.canceledMessage}
            </TypographyMUI>
          </CardMUI>
        </>
      }
      footer={
        <BoxMUI className="FlexRow" style={{ padding: "16px 24px" }}>
          <ButtonMUI
            style={{ width: "100%" }}
            variant="contained"
            color="primary"
            onClick={onClose}
          >
            Aceptar
          </ButtonMUI>
        </BoxMUI>
      }
    />
  );
};

export default DialogCancelMessageView;
