import React from "react";
import { UpoolContext } from "../../Providers/UpoolContext";
import { FacebookLoginButton } from "../../DeltaUI/Components";
import { Authentication } from "../../Utils";
import { UserService } from "../../Services/UserService";
import { DialogCustomHook } from "../DialogCustom";
import { ERROR_CODE } from "../../Utils/Constants";
import { isDesktop } from "../../DeltaUI/Utils/Constants";

export interface FacebookLoginProps {
  login: boolean;
  style?: React.CSSProperties;
  text?: string;
  fab?: boolean;
}

const FacebookLogin: React.FC<FacebookLoginProps> = (props) => {
  const { style, text, login, fab } = props;
  const { messageError, message } = DialogCustomHook();
  const { authFacebook, logout } = Authentication();
  const {
    loginFacebook,
    signupFacebook,
    associateFacebookAccount,
  } = UserService();
  const { setLoading } = React.useContext(UpoolContext);

  const handleLoginFacebook = () => {
    if (!isDesktop) {
      setLoading(() => true);
      authFacebook()
        .then((res) => {
          if (login) {
            loginFacebook(
              res.authResponse.userID,
              res.authResponse.accessToken
            ).catch((err1) => {
              setLoading(() => false);
              if (
                err1.code === ERROR_CODE.USER_NOT_EXIST ||
                err1.code === ERROR_CODE.USER_EXIST
              ) {
                message({
                  title: "¡Registrarme!",
                  context: "FacebookLogin.handleLoginFacebook.1",
                  message: err1?.message,
                  textOnOk: "Continuar",
                  onOk: () => {
                    setLoading(() => true);
                    signupFacebook(
                      res.authResponse.userID,
                      res.authResponse.accessToken
                    )
                      .catch((err2) => {
                        logout();
                        messageError({
                          context: "FacebookLogin.handleLoginFacebook.2",
                          message: err2.message,
                        });
                      })
                      .finally(() => setLoading(() => false));
                  },
                  onCancel: logout,
                });
              } else {
                logout();
                messageError({
                  context: "FacebookLogin.handleLoginFacebook.3",
                  message: err1.message,
                });
              }
            });
          } else {
            associateFacebookAccount(
              res.authResponse.userID,
              res.authResponse.accessToken
            )
              .then(() => {
                message({
                  context: "FacebookLogin.handleLoginFacebook.4",
                  title: "¡Listo!",
                  message: "La cuenta de Facebook se asocio correctamente.",
                });
              })
              .catch((err3) => {
                messageError({
                  context: "FacebookLogin.handleLoginFacebook.5",
                  message: err3.message,
                });
              })
              .finally(() => {
                logout();
                setLoading(() => false);
              });
          }
        })
        .catch((err4) => {
          setLoading(() => false);
          messageError({
            context: "FacebookLogin.handleLoginFacebook.6",
            message: "No se realizó el login con Facebook.",
            messageError: err4.message,
          });
        });
    }
  };

  return (
    <FacebookLoginButton
      style={style}
      onClick={handleLoginFacebook}
      text={text}
      fab={fab}
    />
  );
};

export default FacebookLogin;
