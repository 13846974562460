import React from "react";
import { SetterState } from "../Services/DefaultTypes";
import { Preferences } from "@capacitor/preferences";
import { App, AppInfo, URLOpenListenerEvent } from "@capacitor/app";
import { BatteryInfo, Device, DeviceInfo } from "@capacitor/device";
import { CurrentPosition } from "../DeltaUI/Components/GoogleMapsApiReact";
import { DeviceInfo as DeviceInfoDeltaIT } from "device-info";
import { Tools, useComponentMount } from "../Utils";
import { Keyboard } from "@capacitor/keyboard";
import { Position } from "@capacitor/geolocation";
import { isDesktop, isIos } from "../DeltaUI/Utils/Constants";
import { STORAGE_KEY } from "../Utils/Constants";
import { IS_WEB } from "../config";

interface LogInfo {
  app?: AppInfo;
  device?: DeviceInfo;
  battery?: BatteryInfo;
  model?: string;
}

interface IAppContext {
  loading: boolean;
  setLoading: SetterState<boolean>;
  isWelcomeToMyTrips: boolean;
  setWelcomeToMyTrips: (value: boolean) => void;
  currentPosition: Position | undefined;
  updatePosition: () => void;
  getLogInfo: () => Promise<LogInfo | {}>;
  upToIPhone8: boolean;
  showingKeyboard: boolean;
  mobileStatusBarHeight: number;
  initUrlSearchParams: URLSearchParams | undefined;
  setInitUrlSearchParams: SetterState<URLSearchParams | undefined>;
  isTipToShare: boolean;
  setTipToShare: (value: boolean) => Promise<void>;
}

const context: IAppContext = {
  loading: false,
  setLoading: () => {},
  isWelcomeToMyTrips: true,
  setWelcomeToMyTrips: () => {},
  currentPosition: undefined,
  updatePosition: () => {},
  getLogInfo: () => Promise.resolve({}),
  upToIPhone8: false,
  showingKeyboard: false,
  mobileStatusBarHeight: 0,
  initUrlSearchParams: undefined,
  setInitUrlSearchParams: () => {},
  isTipToShare: true,
  setTipToShare: () => Promise.resolve(),
};

export const AppContext = React.createContext(context);

export interface AppContextProviderProps {}

export const AppContextProvider: React.FC<AppContextProviderProps> = (
  props
) => {
  const isMountedComponent = useComponentMount("AppContextProvider");
  const { currentPosition, updatePosition } = !IS_WEB
    ? CurrentPosition()
    : {
        currentPosition: undefined,
        updatePosition: () => {},
      };
  const { isUpToIPhone8 } = Tools();

  const [logInfo, setLogInfo] = React.useState<LogInfo | {}>({});
  const [loading, setLoading] = React.useState<boolean>(false);
  const [isWelcomeToMyTrips, setIsWelcomeToMyTrips] = React.useState<boolean>(
    true
  );
  const [upToIPhone8, setUpToIPhone8] = React.useState<boolean>(false);
  const [showingKeyboard, setShowingKeyboard] = React.useState<boolean>(false);
  const [
    initUrlSearchParams,
    setInitUrlSearchParams,
  ] = React.useState<URLSearchParams>();
  const [isTipToShare, setIsTipToShare] = React.useState<boolean>(true);

  const setTipToShare = async (value: boolean) => {
    setIsTipToShare(() => value);
    await Preferences.set({
      key: STORAGE_KEY.TIP_TO_SHARE,
      value: `${value ? 1 : 0}`,
    });
  };

  const getTipToShare = async () => {
    const { value } = await Preferences.get({
      key: STORAGE_KEY.TIP_TO_SHARE,
    });
    if (value) {
      setIsTipToShare(() => !!Number(value));
    } else {
      setIsTipToShare(() => true);
    }
  };

  const setWelcomeToMyTrips = async (value: boolean) => {
    setIsWelcomeToMyTrips(() => value);
    await Preferences.set({
      key: STORAGE_KEY.WELCOME_TO_MY_TRIPS,
      value: `${value ? 1 : 0}`,
    });
  };

  const getWelcomeToMyTrips = async () => {
    const { value } = await Preferences.get({
      key: STORAGE_KEY.WELCOME_TO_MY_TRIPS,
    });
    if (value) {
      setIsWelcomeToMyTrips(() => !!Number(value));
    } else {
      setIsWelcomeToMyTrips(() => true);
    }
  };

  const getLogInfo = React.useMemo(() => {
    return () => {
      return Device.getBatteryInfo()
        .then((batteryInfo) => {
          return {
            ...logInfo,
            battery: batteryInfo,
          };
        })
        .catch(() => {
          return logInfo;
        });
    };
  }, [logInfo]);

  const greaterThanIPhone8 = React.useMemo(() => {
    return isIos ? !upToIPhone8 : false;
  }, [upToIPhone8]);

  const mobileStatusBarHeight = React.useMemo(() => {
    if (greaterThanIPhone8) {
      return 40;
    } else if (isIos) {
      return 20;
    } else {
      return 30;
    }
  }, [greaterThanIPhone8]);

  React.useEffect(() => {
    document.documentElement.style.setProperty(
      "--mobile-status-bar-height",
      `${mobileStatusBarHeight}px`
    );
  }, [mobileStatusBarHeight]);

  React.useEffect(() => {
    if (greaterThanIPhone8) {
      document.documentElement.style.setProperty(
        "--ios-action-footer-height",
        "32px"
      );
    } else {
      document.documentElement.style.setProperty(
        "--ios-action-footer-height",
        "0px"
      );
    }
    if (!isDesktop) {
      Keyboard.removeAllListeners().then(() => {
        Keyboard.addListener("keyboardWillShow", (info) => {
          setShowingKeyboard(() => true);
          // console.info("keyboard will show with height:", info.keyboardHeight);
          if (greaterThanIPhone8) {
            document.documentElement.style.setProperty(
              "--ios-action-footer-height",
              "0px"
            );
          }
        });
        Keyboard.addListener("keyboardWillHide", () => {
          setShowingKeyboard(() => false);
          // console.info("keyboard will hide");
          if (greaterThanIPhone8) {
            document.documentElement.style.setProperty(
              "--ios-action-footer-height",
              "32px"
            );
          }
        });
      });
    }
  }, [greaterThanIPhone8]);

  React.useEffect(() => {
    getTipToShare();
    getWelcomeToMyTrips();
    Device.getInfo()
      .then((deviceInfo) => {
        if (isIos) {
          DeviceInfoDeltaIT.getModel().then((v) => {
            setUpToIPhone8(() => isUpToIPhone8(v.model));
            setLogInfo((c) => ({
              ...c,
              device: deviceInfo,
              model: v.model,
            }));
          });
        } else {
          setLogInfo((c) => ({
            ...c,
            device: deviceInfo,
            model: undefined,
          }));
        }

        if (!isDesktop) {
          App.getInfo()
            .then((appInfo) => {
              setLogInfo((c) => ({
                ...c,
                app: appInfo,
                model: undefined,
              }));
            })
            .catch((err) => console.error(err));
        }
      })
      .catch((err) => console.error(err));

    if (window.location.search) {
      setInitUrlSearchParams(() => new URLSearchParams(window.location.search));
    }
    App.addListener("appUrlOpen", (event: URLOpenListenerEvent) => {
      const { url } = event;
      const urlParts = url.split("?");
      if (urlParts.length === 2) {
        // TODO: esto podria mejorarse usando la nueva arquitectura
        setInitUrlSearchParams(() => undefined);
        setTimeout(() => {
          setInitUrlSearchParams(() => new URLSearchParams(`?${urlParts[1]}`));
        }, 10);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    isMountedComponent.current = true;
    return () => {
      isMountedComponent.current = false;
    };
  }, [isMountedComponent]);

  return (
    <AppContext.Provider
      value={{
        loading,
        setLoading,
        isWelcomeToMyTrips,
        setWelcomeToMyTrips,
        currentPosition,
        updatePosition,
        getLogInfo,
        upToIPhone8,
        showingKeyboard,
        mobileStatusBarHeight,
        initUrlSearchParams,
        setInitUrlSearchParams,
        isTipToShare,
        setTipToShare,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};
