import React from "react";
import { UpoolContext } from "../../Providers/UpoolContext";
import { ButtonMUI, PaperMUI } from "../../DeltaUI/Components";
import { ITripStretch } from "../../Services/TripService";
import { RESERVATION_STATUS, TRIP_STATUS } from "../../Utils/Constants";
import DialogConfirmCountPassenger from "../Dialog/DialogConfirmCountPassenger";
import { MyTripsUtils } from "../MyTrips/MyTripsUtils";

export interface DetailTripReservationButtonProps {
  tripStretch?: ITripStretch;
  onOk?: () => void;
}

export const DetailTripReservationButton: React.FC<DetailTripReservationButtonProps> = (
  props
) => {
  const { tripStretch, onOk } = props;
  const { loading } = React.useContext(UpoolContext);

  const myReservation = React.useMemo(() => {
    return tripStretch?.myReservation;
  }, [tripStretch]);

  const {
    open,
    setOpen,
    quantity,
    setQuantity,
    handleCreateOneReservation,
    tripMode,
    createNewReservation,
  } = MyTripsUtils({
    tripStretch,
    onChange: onOk,
  });

  const availablePlaces = React.useMemo(() => {
    return tripStretch ? tripStretch.totalPlaces - tripStretch.usedPlaces : 0;
  }, [tripStretch]);

  const totalAmount = React.useMemo(() => {
    return tripStretch ? tripStretch.amount : 0;
  }, [tripStretch]);

  return (
    <>
      {tripMode.passenger &&
      tripStretch?.trip.statusId === TRIP_STATUS.AVAILABLE &&
      (!myReservation ||
        (myReservation &&
          myReservation.statusId === RESERVATION_STATUS.CREATED) ||
        createNewReservation) &&
      availablePlaces ? (
        <>
          <PaperMUI
            elevation={0}
            style={{
              position: "fixed",
              bottom: "var(--ios-action-footer-height)",
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              padding: "24px",
              borderRadius: 0,
              background: "transparent",
            }}
          >
            <ButtonMUI
              className="FullWidth"
              variant="contained"
              color="primary"
              onClick={() => setOpen(() => true)}
            >
              Unirme
            </ButtonMUI>
          </PaperMUI>
          <div style={{ width: "100%", height: "100px" }} />
        </>
      ) : null}
      <DialogConfirmCountPassenger
        open={open}
        onCancel={() => setOpen(() => false)}
        onOk={() => handleCreateOneReservation(quantity)}
        value={quantity}
        onChange={(ev) => setQuantity(() => ev)}
        disabledOk={!quantity || loading}
        availablePlaces={availablePlaces}
        totalAmount={totalAmount}
      />
    </>
  );
};
