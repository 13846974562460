import React from "react";
import { EventAppContext } from "../Providers/EventAppProvider";

// un bucle infinito por actualización de estado ronda entre 31 y 1.53 (1.53 es lo mas rapido)
const tPromedioLimit = 25;
// tiempo maximo que podrian estar los componentes actualizandose en cola
const estimatedTime = 3000;

export const useComponentMount = (componentName: string) => {
  const isMountedComponent = React.useRef<boolean>(false);
  const changeCount = React.useRef<number>(0);
  const timeRef = React.useRef<Date>(new Date());
  const { handleEventInfiniteLoop } = React.useContext(EventAppContext);

  if (changeCount.current) {
    // si a la hora actual le restamos el tiempo en el que el componente se monta obtenemos la cantidad de tiempo (t) que el componente se encuentra montado
    const t = new Date().getTime() - timeRef.current.getTime();

    // el tiempo (t) dividido la cantidad de cambios es igual al promedio de tiempo (tPromedio) que ocurre entre actualizaciones
    const tPromedio = t / changeCount.current;

    // un tiempo estimado de 2 segundos y medio para verificar si la cantidad de cambios no supera el limite promedio entonces el componente se refresco normalmente
    if (t > estimatedTime && tPromedio < tPromedioLimit) {
      console.error(
        `Bucle infinito => ${componentName} => t: ${t} - tPromedio: ${tPromedio}`
      );
      handleEventInfiniteLoop(
        `BI: ${componentName} - t: ${t} - tPromedio: ${tPromedio}`
      );
    } else if (t > estimatedTime) {
      // si pasa el tiempo estimado de comprobación reiniciamos el tiempo y el contador de cambios para mantener el promedio
      changeCount.current = 0;
      timeRef.current = new Date();
    }
  }

  // conteo de cambios
  changeCount.current = changeCount.current + 1;
  return isMountedComponent;
};
