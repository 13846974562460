import React from "react";
import {
  ToolbarMUI,
  MenuButton,
  BodyPage,
  Separator,
  TypographyMUI,
  BoxMUI,
  ButtonMUI,
} from "../DeltaUI/Components";
import { TitlePage } from "../Components/TitlePage";
import { COLOR_PRIMARY, COLOR_QUINARY } from "../Utils/Constants";
import TextArea from "../Components/Form/TextArea";
import { UserService } from "../Services/UserService";
import { CustomPage, DialogCustomHook } from "../Components";
import { UpoolContext } from "../Providers/UpoolContext";
import { goStore } from "../App";

const CommentsPage: React.FC = () => {
  const [description, setDescription] = React.useState<string>("");
  const { createOneUserComment } = UserService();
  const { messageError, message } = DialogCustomHook();
  const { setLoading } = React.useContext(UpoolContext);

  const handleCreateOneUserComment = () => {
    setLoading(() => true);
    createOneUserComment(description)
      .then(() => {
        setDescription(() => "");
        message({
          context: "CommentsPage.handleCreateOneUserComment.1",
          title: "¡Muchas gracias!",
          message: "Se ha enviado su opinión.",
        });
      })
      .catch(({ message }) => {
        messageError({
          context: "CommentsPage.handleCreateOneUserComment.2",
          message: message,
        });
      })
      .finally(() => setLoading(() => false));
  };

  return (
    <CustomPage
      headerPage={
        <ToolbarMUI>
          <MenuButton style={{ color: COLOR_PRIMARY }} />
          <TitlePage text="Tu opinión" />
        </ToolbarMUI>
      }
    >
      <BodyPage m={3}>
        <TypographyMUI className="TypographyRegular11pt">
          Dejanos un comentario si te gustó la aplicación o si tuviste algún
          inconveniente. Cualquier sugerencia o problema nos ayudará a mejorar.
        </TypographyMUI>
        <Separator px={16} />
        <TypographyMUI className="TypographyExtrabold13pt">
          Danos tu opinión
        </TypographyMUI>
        <Separator px={16} />
        <BoxMUI
          className="ContentInputCustom"
          style={{ padding: "0 4px", color: COLOR_QUINARY }}
        >
          <TextArea
            placeholder="Agregá lo que quieras..."
            value={description}
            onChange={(ev) => setDescription(() => ev)}
          />
        </BoxMUI>
        <Separator px={24} />
        <ButtonMUI
          className="FullWidth"
          variant="contained"
          color="primary"
          onClick={handleCreateOneUserComment}
          disabled={!description}
        >
          Enviar
        </ButtonMUI>
        <Separator px={48} />
        <TypographyMUI className="TypographyRegular11pt">
          También podes calificarnos en la tienda.
        </TypographyMUI>
        <Separator px={16} />
        <ButtonMUI
          className="FullWidth"
          variant="contained"
          color="primary"
          onClick={goStore}
        >
          Ir a la tienda
        </ButtonMUI>
      </BodyPage>
    </CustomPage>
  );
};

export default CommentsPage;
