import React from "react";
import {
  ListItemMUI,
  ListItemTextMUI,
  TypographyMUI,
  MenuItemMUI,
  SelectMUI,
} from "../../../DeltaUI/Components";
import { COLOR_QUATERNARY } from "../../../Utils/Constants";
import { ORDER_BY, SearchTripContext } from "../SearchTripContext";

export interface AdvancedFiltersOrderByProps {}

const AdvancedFiltersOrderBy: React.FC<AdvancedFiltersOrderByProps> = (
  props
) => {
  const { orderByNumber, setOrderByNumber } =
    React.useContext(SearchTripContext);

  const value = React.useMemo(() => {
    return typeof orderByNumber === "number" ? orderByNumber : "none";
  }, [orderByNumber]);

  return (
    <>
      <ListItemMUI style={{ paddingBottom: 0 }}>
        <ListItemTextMUI
          primary={
            <TypographyMUI className="TypographyExtrabold11pt">
              Ordenar por
            </TypographyMUI>
          }
          style={{ marginBottom: 0 }}
        />
      </ListItemMUI>
      <ListItemMUI button>
        <SelectMUI
          variant="standard"
          style={{ margin: "0", width: "100%", color: COLOR_QUATERNARY }}
          value={value}
          onChange={(ev) => {
            if (ev.target.value !== "none") {
              setOrderByNumber(() => Number(ev.target.value));
            } else {
              setOrderByNumber(() => undefined);
            }
          }}
        >
          <MenuItemMUI value="none">
            <TypographyMUI className="TypographySemibold11pt">
              Sin ordenar
            </TypographyMUI>
          </MenuItemMUI>
          <MenuItemMUI value={ORDER_BY.dateFromDesc}>
            <TypographyMUI className="TypographySemibold11pt">
              Más recientes
            </TypographyMUI>
          </MenuItemMUI>
          <MenuItemMUI value={ORDER_BY.dateFromAsc}>
            <TypographyMUI className="TypographySemibold11pt">
              Más antiguo
            </TypographyMUI>
          </MenuItemMUI>
          <MenuItemMUI value={ORDER_BY.amountAsc}>
            <TypographyMUI className="TypographySemibold11pt">
              Menor precio
            </TypographyMUI>
          </MenuItemMUI>
          <MenuItemMUI value={ORDER_BY.amountDesc}>
            <TypographyMUI className="TypographySemibold11pt">
              Mayor precio
            </TypographyMUI>
          </MenuItemMUI>
        </SelectMUI>
      </ListItemMUI>
    </>
  );
};

export default AdvancedFiltersOrderBy;
