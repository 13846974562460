import { gql } from "@apollo/client";
import { FragmentService } from "../FragmentService";

export const CREATE_ONE_RESERVATION_CHAT = gql`
  mutation createOneReservationChat($data: ReservationChatCreateInput!) {
    createOneReservationChat(data: $data) {
      ...FReservationChat
    }
  }
  ${FragmentService.ReservationChat}
`;

export const MARK_AS_READED_MANY_RESERVATION_CHATS = gql`
  mutation markAsReadedManyReservationChats(
    $where: ReservationFKWhereUniqueInput!
  ) {
    markAsReadedManyReservationChats(where: $where)
  }
`;
