import React from "react";
import {
  BoxMUI,
  ButtonMUI,
  CardMUI,
  ListItemMUI,
  ListItemTextMUI,
  Separator,
  TypographyMUI,
} from "../../DeltaUI/Components";
import { AlternateEmailOutlinedIcon } from "../../DeltaUI/Components/CustomIcon";
import { UpoolContext } from "../../Providers/UpoolContext";
import { COLOR_TERTIARY } from "../../Utils/Constants";
import {
  EmailSettingContext,
  EMAIL_SETTING_STEPS,
} from "./EmailSettingContext";

interface InitPageProps {
  style?: React.CSSProperties;
  className?: string;
}

export const InitPage: React.FC<InitPageProps> = (props) => {
  const { user } = React.useContext(UpoolContext);
  const { setStep } = React.useContext(EmailSettingContext);

  return (
    <>
      <BoxMUI
        style={{
          width: "100%",
        }}
      >
        {user && user.email ? (
          <>
            <TypographyMUI className="TypographyBold11pt">
              Email actual
            </TypographyMUI>
            <Separator px={16} />
            <CardMUI
              style={{
                width: "100%",
                padding: "8px",
              }}
              elevation={7}
              className="ShadowCard8"
            >
              <ListItemMUI>
                <AlternateEmailOutlinedIcon
                  style={{ marginRight: "16px", color: COLOR_TERTIARY }}
                />
                <ListItemTextMUI primary={user.email} />
              </ListItemMUI>
            </CardMUI>
          </>
        ) : (
          <TypographyMUI className="TypographyRegular11pt">
            No tenes ningún email asociado a tu cuenta.
          </TypographyMUI>
        )}
      </BoxMUI>
      <BoxMUI
        style={{
          width: "100%",
        }}
      >
        <ButtonMUI
          style={{
            width: "100%",
            marginTop: "16px",
          }}
          color="primary"
          variant="contained"
          onClick={() => setStep(() => EMAIL_SETTING_STEPS.SEARCH_ACCOUNT)}
        >
          {user && !user.email ? <span>Agregar</span> : <span>Cambiar</span>}
        </ButtonMUI>
        <Separator px={32} />
      </BoxMUI>
    </>
  );
};
