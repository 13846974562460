import React from "react";
import { DialogCustomHook } from "..";
import { UpoolContext } from "../../Providers/UpoolContext";
import { EventAppContext } from "../../Providers/EventAppProvider";
import {
  INotification,
  NotificationService,
} from "../../Services/NotificationService";
import {
  DETAIL_TRIP_TAB,
  NOTIFICATION_TYPE,
  TRIP_STATUS,
} from "../../Utils/Constants";
import { MyTripsContext } from "./MyTripsContext";
import { useComponentMount, useCustomLog } from "../../Utils";
import { TripService } from "../../Services/TripService";

interface IMyTripsNotificacions {
  notificationSelected: INotification | undefined;
}

const context: IMyTripsNotificacions = {
  notificationSelected: undefined,
};

export const MyTripsNotificacionsContext = React.createContext(context);

export interface MyTripsNotificacionsProviderProps {}

export const MyTripsNotificacionsProvider: React.FC<MyTripsNotificacionsProviderProps> = (
  props
) => {
  const isMountedComponent = useComponentMount("MyTripsNotificacionsProvider");
  const { messageWarn } = DialogCustomHook();
  const Log = useCustomLog();
  const { markAsAttendedManyNotifications } = NotificationService();
  const { tripStretch } = TripService();
  const {
    setNotificationGeneral,
    notificationGeneral,
    user,
    setNotificationOneSignal,
  } = React.useContext(UpoolContext);
  const {
    eventNotificationMarkAsAttended,
    handleEventNotificationMarkAsAttended,
    eventNotificationTrip,
  } = React.useContext(EventAppContext);
  const {
    selectTripStretchReservations,
    setReservationForChat,
    selectTripStretch,
    setRatingView,
    setSelectTripStretch,
    setDetailTripTab,
  } = React.useContext(MyTripsContext);
  const [
    notificationSelectedMarkAsAttended,
    setnotificationSelectedMarkAsAttended,
  ] = React.useState<INotification>();

  /**
   * Calcula la notificación seleccionada
   */
  const notificationSelected: INotification | undefined = React.useMemo(() => {
    if (eventNotificationTrip.data) {
      return eventNotificationTrip.data;
    }
    return undefined;
  }, [eventNotificationTrip]);

  const prepareSelectTripStretchForNotification = async (id: number) => {
    tripStretch(id)
      .then((ts) => setSelectTripStretch(() => ts))
      .catch(({ message }) => {
        Log.error({
          context: "DetailTripPage.prepareSelectTripStretchForNotification.1",
          message,
        });
      });
  };

  /**
   * Avisa que está todo preparado para resolver la notificación
   */
  const readyToGo = React.useMemo(() => {
    if (
      selectTripStretch &&
      notificationSelected &&
      notificationSelected.payload.tripStretchId === selectTripStretch.id
    ) {
      return true;
    }
    return false;
  }, [notificationSelected, selectTripStretch]);

  /**
   * Calcula todas las notificaciones no atendidas de un viaje
   */
  const allNotificationsFromSelectTripStretch = React.useMemo(() => {
    if (selectTripStretch && readyToGo) {
      return notificationGeneral.filter(
        (n) => n.payload?.tripStretchId === selectTripStretch.id
      );
    }
    return [];
  }, [notificationGeneral, selectTripStretch, readyToGo]);

  /**
   * Calcula todas las notificaciones no atendidas para la pantalla de detalle de viaje
   */
  const notificationsForDetailTripPage = React.useMemo(() => {
    if (selectTripStretch && readyToGo) {
      if (selectTripStretch.trip.statusId === TRIP_STATUS.AVAILABLE) {
        return allNotificationsFromSelectTripStretch.filter(
          (n) =>
            n.type !== NOTIFICATION_TYPE.MESSENGER &&
            n.type !== NOTIFICATION_TYPE.RESERVATION_REQUEST
        );
      }
      return allNotificationsFromSelectTripStretch.filter(
        (n) => n.type !== NOTIFICATION_TYPE.MESSENGER
      );
    }
    return [];
  }, [allNotificationsFromSelectTripStretch, selectTripStretch, readyToGo]);

  React.useEffect(() => {
    if (
      selectTripStretch &&
      notificationSelected &&
      notificationSelected.payload.tripStretchId !== selectTripStretch.id
    ) {
      setSelectTripStretch(() => undefined);
    } else if (notificationSelected && !selectTripStretch) {
      prepareSelectTripStretchForNotification(
        notificationSelected.payload.tripStretchId
      );
    } else if (readyToGo) {
      setNotificationOneSignal(() => undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationSelected, selectTripStretch]);

  /**
   * Si se selecciono una notificación la atiende segun el tipo de notificación
   * Solo atiende las notificaciones que no son de detalle de viaje, ya que las otras se atienden arriba
   */
  React.useEffect(() => {
    if (notificationSelected && readyToGo) {
      if (
        [
          NOTIFICATION_TYPE.MESSENGER,
          NOTIFICATION_TYPE.RESERVATION_REQUEST,
          NOTIFICATION_TYPE.TRIP_RATE_REMINDER,
          NOTIFICATION_TYPE.RATE_CREATED,
          NOTIFICATION_TYPE.RESERVATION_CANCEL,
        ].some((type) => notificationSelected.type === type)
      ) {
        setDetailTripTab(() => DETAIL_TRIP_TAB.RESERVATIONS);
      }
      if (notificationSelected.type === NOTIFICATION_TYPE.MESSENGER) {
        const reservation = selectTripStretchReservations.find(
          (r) => r.id === notificationSelected.payload.reservationId
        );
        if (reservation) {
          setReservationForChat(() => reservation);
        } else {
          messageWarn({
            context: "MyTripsNotificacionsProvider.useEffect.1",
            message: "Este chat ya no está disponible.",
          });
        }
      } else if (
        notificationSelected.type === NOTIFICATION_TYPE.RATE_CREATED &&
        selectTripStretch
      ) {
        const reservation = selectTripStretchReservations.find(
          (r) => r.id === notificationSelected.payload.reservationId
        );
        if (reservation) {
          if (selectTripStretch.isDriver) {
            const rate = reservation.rates.find(
              (re) => re.userToId === user?.id
            );
            if (rate) {
              setRatingView(() => ({
                rate,
                userFromIsDriver: false,
              }));
            }
          } else {
            const rate = reservation.rates.find(
              (re) => re.userToId === user?.id
            );
            if (rate) {
              setRatingView(() => ({
                rate,
                userFromIsDriver: true,
              }));
            }
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationSelected, readyToGo]);

  /**
   * Marca todas las notificaciones de un viaje como atendidas a excepción de las filtradas por tipo
   */
  React.useEffect(() => {
    if (readyToGo) {
      if (notificationsForDetailTripPage.length) {
        handleEventNotificationMarkAsAttended(notificationsForDetailTripPage);
      } else if (!notificationSelectedMarkAsAttended) {
        setnotificationSelectedMarkAsAttended(() => notificationSelected);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationsForDetailTripPage, readyToGo]);

  /**
   * Marca la notificación seleccionada como atendida
   */
  React.useEffect(() => {
    if (
      notificationSelectedMarkAsAttended &&
      readyToGo &&
      notificationSelected
    ) {
      handleEventNotificationMarkAsAttended([notificationSelected]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationSelectedMarkAsAttended, readyToGo]);

  /**
   * Marca una notificación como atendida
   */
  React.useEffect(() => {
    if (eventNotificationMarkAsAttended.data.length) {
      const notifications: INotification[] =
        eventNotificationMarkAsAttended.data;
      const notificationsIsNotAttendedAt = notifications.filter(
        (n) => !n?.attendedAt
      );
      if (notificationsIsNotAttendedAt.length) {
        markAsAttendedManyNotifications(
          notificationsIsNotAttendedAt.map((n) => n.id)
        )
          .then(() => {
            setNotificationGeneral((nList) =>
              nList.filter(
                (notif) =>
                  !notificationsIsNotAttendedAt.find((n) => n.id === notif.id)
              )
            );
          })
          .catch(({ message }) => {
            Log.error({
              context: "MyTripsNotificacionsProvider.useEffect.2",
              message,
            });
          })
          .finally(() => handleEventNotificationMarkAsAttended([]));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventNotificationMarkAsAttended]);

  React.useEffect(() => {
    isMountedComponent.current = true;
    return () => {
      isMountedComponent.current = false;
    };
  }, [isMountedComponent]);

  return (
    <MyTripsNotificacionsContext.Provider
      value={{
        notificationSelected,
      }}
    >
      {props.children}
    </MyTripsNotificacionsContext.Provider>
  );
};
