import React from "react";
import { UpoolContext } from "../../Providers/UpoolContext";
import {
  BoxMUI,
  ButtonMUI,
  Separator,
  TypographyMUI,
} from "../../DeltaUI/Components";
import { EventAppContext } from "../../Providers/EventAppProvider";
import {
  IReservation,
  ReservationService,
} from "../../Services/ReservationService";
import { Tools } from "../../Utils";
import { DialogCustom, DialogCustomHook } from "../DialogCustom";
import TextArea from "../Form/TextArea";
import { MyTripsContext } from "../MyTrips/MyTripsContext";

export interface DialogConfirmCancelReservationProps {
  reservation: IReservation;
}

const DialogConfirmCancelReservation: React.FC<
  DialogConfirmCancelReservationProps
> = (props) => {
  const { reservation } = props;
  const { cancelOneReservation } = ReservationService();
  const { messageError } = DialogCustomHook();
  const { getPerson } = Tools();
  const { handleEventUpdateTrip } = React.useContext(EventAppContext);
  const { setLoading, isDriverOfTheTrip, historyGoBack } =
    React.useContext(UpoolContext);
  const { setCancelReservation } = React.useContext(MyTripsContext);
  const [message, setMessage] = React.useState<string>("");

  const isDriver = React.useMemo(() => {
    return isDriverOfTheTrip(reservation.tripStretch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reservation]);

  const onClose = () => {
    setCancelReservation(() => undefined);
  };

  const handleCancelOneReservation = () => {
    setLoading(() => true);
    cancelOneReservation(reservation.id, message)
      .then(() => {
        onClose();
        handleEventUpdateTrip();
        historyGoBack();
      })
      .catch(({ message }) => {
        messageError({
          context:
            "DialogConfirmCancelReservation.handleCancelOneReservation.1",
          message: message,
        });
      })
      .finally(() => setLoading(() => false));
  };

  const person = React.useMemo(
    () => getPerson(reservation.user, 0),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [reservation]
  );

  const name = React.useMemo(
    () => (person ? `${person.firstName} ${person.lastName}` : ""),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [person]
  );

  return (
    <DialogCustom
      context="DialogConfirmCancelReservation.1"
      title={isDriver ? "Dar de baja" : "Darme de baja"}
      open={true}
      body={
        <>
          {isDriver ? (
            <TypographyMUI>
              ¿Querés dar de baja a <b>{name}</b>?
            </TypographyMUI>
          ) : (
            <TypographyMUI>
              ¿Querés dar de baja la reserva de este viaje?
            </TypographyMUI>
          )}
          <Separator px={16} />
          <BoxMUI className="ContentInputCustom" style={{ padding: "0 4px" }}>
            <TextArea
              placeholder="Agregá un motivo"
              value={message}
              onChange={(ev) => setMessage(() => ev)}
            />
          </BoxMUI>
        </>
      }
      footer={
        <BoxMUI className="FlexRow" style={{ padding: "16px 24px" }}>
          <ButtonMUI
            style={{ width: "50%" }}
            variant="outlined"
            onClick={onClose}
          >
            Cancelar
          </ButtonMUI>
          <Separator px={16} orientation="vertical" />
          <ButtonMUI
            style={{ width: "50%" }}
            variant="contained"
            color="primary"
            onClick={handleCancelOneReservation}
            disabled={!message}
          >
            Aceptar
          </ButtonMUI>
        </BoxMUI>
      }
    />
  );
};

export default DialogConfirmCancelReservation;
