import React from "react";
import { FormHelperTextMUI } from "..";
export interface FormHelperTextProps {
  active: boolean;
  message?: string | JSX.Element;
}

const FormHelperText: React.FC<FormHelperTextProps> = (props) => {
  const { active, message, children } = props;

  if (!message) {
    return (
      <FormHelperTextMUI
        style={{
          height: "19px",
          marginBottom: "-19px",
        }}
      >
        {active ? <>{children}</> : null}
      </FormHelperTextMUI>
    );
  }
  return (
    <FormHelperTextMUI
      style={{
        height: "19px",
        marginBottom: "-19px",
      }}
    >
      {active ? <>{message}</> : null}
    </FormHelperTextMUI>
  );
};

export default FormHelperText;
