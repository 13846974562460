import React from "react";
import { UpoolContext } from "../../Providers/UpoolContext";
import {
  BoxMUI,
  RatingMUI,
  Separator,
  TypographyMUI,
} from "../../DeltaUI/Components";
import { IUser } from "../../Services/UserService";
import { Tools } from "../../Utils";
import UserAvatar from "./UserAvatar";

interface ItemUserProps {
  style?: React.CSSProperties;
  className?: string;
  user: IUser;
  isDrive: boolean;
  sizeAvatar?: 4 | 5 | 6 | 10 | 15;
  disabled?: boolean;
}

export const ItemUser: React.FC<ItemUserProps> = (props) => {
  const { style, className, user, isDrive, sizeAvatar, disabled } = props;
  const { getPerson } = Tools();
  const { historyPush, selectPathTab, setViewOtherUserId } =
    React.useContext(UpoolContext);

  const person = React.useMemo(
    () => getPerson(user, 0),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user]
  );

  const name = React.useMemo(
    () => (person ? `${person.firstName} ${person.lastName}` : ""),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [person]
  );

  const avatar = React.useMemo(
    () => <UserAvatar size={sizeAvatar || 4} user={user} />,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user]
  );

  const viewUser = () => {
    if (!disabled) {
      setViewOtherUserId(() => user.id);
      historyPush(`${selectPathTab}/user`);
    }
  };

  return (
    <BoxMUI style={style} className={`FlexRow ${className}`}>
      <BoxMUI onClick={viewUser}>{avatar}</BoxMUI>
      <Separator px={12} orientation="vertical" />
      <BoxMUI className="FlexColumn JustifyCenter">
        <TypographyMUI
          style={{ lineHeight: 1.1 }}
          className="TypographyBold11pt"
        >
          {name}
        </TypographyMUI>
        <RatingMUI
          style={{
            fontSize: "1rem",
          }}
          readOnly
          value={isDrive ? user.rateAsDriver : user.rateAsPassenger}
        />
      </BoxMUI>
    </BoxMUI>
  );
};
