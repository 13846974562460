import React from "react";
import OneSignal from "onesignal-cordova-plugin";
import { UpoolContext } from "./UpoolContext";

import { EventAppContext } from "./EventAppProvider";
import {
  INotification,
  prepareNotification,
} from "../Services/NotificationService";
import { config, ENVIRONMENT, IS_WEB } from "../config";
import { useComponentMount, useCustomLog } from "../Utils";
import {
  ActionPerformed,
  LocalNotifications,
} from "@capacitor/local-notifications";
import { isDesktop, isIos } from "../DeltaUI/Utils/Constants";

interface IOneSignal {}

const context: IOneSignal = {};

export const OneSignalContext = React.createContext(context);

export interface OneSignalProviderProps {}

const OneSignalContextProvider: React.FC<OneSignalProviderProps> = (props) => {
  const isMountedComponent = useComponentMount("OneSignalContextProvider");
  const { eventLogin } = React.useContext(EventAppContext);
  const { setNotificationOneSignal, user } = React.useContext(UpoolContext);
  const Log = useCustomLog();

  const handleNotificationOpened = (
    additionalData: INotification | undefined
  ) => {
    if (additionalData) {
      setNotificationOneSignal(() => prepareNotification(additionalData));
    }
  };

  React.useEffect(() => {
    try {
      LocalNotifications.removeAllListeners();
      if (
        config.env.credentials.appIdOneSignal &&
        config.env.credentials.appIdFirebase
      ) {
        // Uncomment to set OneSignal device logging to VERBOSE
        if (config.envName !== ENVIRONMENT.PROD) {
          OneSignal.setLogLevel(6, 0);
        }
        if (user) {
          OneSignal.setExternalUserId(`${user.id}`);
        }

        // NOTE: Update the setAppId value below with your OneSignal AppId.
        OneSignal.setAppId(config.env.credentials.appIdOneSignal);
        OneSignal.setNotificationOpenedHandler((jsonData) => {
          const notifOneSignal = jsonData as IOneSignalNotificationOpenedCallback;
          handleNotificationOpened(notifOneSignal.notification.additionalData);
        });
        OneSignal.setNotificationWillShowInForegroundHandler(
          async (notificationReceivedEvent) => {
            const notification = notificationReceivedEvent.getNotification();
            // Get custom additional data you sent with the notification
            if (isIos) {
              const additionalData:
                | INotification
                | undefined = notification.additionalData as any;
              const id =
                Number(notification.collapseId) ||
                additionalData?.id ||
                new Date().getTime();
              LocalNotifications.schedule({
                notifications: [
                  {
                    id,
                    title: `${notification.title}`,
                    body: `${notification.body}`,
                    largeIcon: notification.largeIcon,
                    extra: additionalData,
                  },
                ],
              });
              notificationReceivedEvent.complete(null);
            } else {
              notificationReceivedEvent.complete(notification);
            }
          }
        );
        // iOS - Prompts the user for notification permissions.
        //    * Since this shows a generic native prompt, we recommend instead using an In-App Message to prompt for notification permission (See step 6) to better communicate to your users what notifications they will get.
        OneSignal.promptForPushNotificationsWithUserResponse((accepted) => {
          if (!accepted) {
            Log.warn({
              context: "OneSignalContextProvider.useEffect.1",
              message:
                "El usuario rechazo el permiso para recibir notificaciones push.",
            });
          }
        });
        LocalNotifications.addListener(
          "localNotificationActionPerformed",
          (notificationAction: ActionPerformed) => {
            handleNotificationOpened(notificationAction.notification.extra);
          }
        ).catch(({ message }) => {
          Log.error({
            context: "OneSignalContextProvider.useEffect.2",
            message,
          });
        });
      } else {
        Log.error({
          context: "OneSignalContextProvider.useEffect.3",
          message: `OneSignal no está configurado para el ambiente ${config.appName}`,
        });
      }
    } catch (error: any) {
      Log.error({
        context: "OneSignalContextProvider.useEffect.4",
        message: error.message,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventLogin]);

  React.useEffect(() => {
    isMountedComponent.current = true;
    return () => {
      isMountedComponent.current = false;
    };
  }, [isMountedComponent]);

  return (
    <OneSignalContext.Provider value={{}}>
      {props.children}
    </OneSignalContext.Provider>
  );
};

export const OneSignalProvider: React.FC<OneSignalProviderProps> = (props) => {
  if (IS_WEB) {
    return <>{props.children}</>;
  } else if (!isDesktop) {
    return (
      <OneSignalContextProvider {...props}>
        {props.children}
      </OneSignalContextProvider>
    );
  }
  return <>{props.children}</>;
};

/**
 *
 * {
 *   notification: {
 *     notificationId: "8f0f6b1b-1cbf-450c-829c-73a18b0e2fa2";
 *     body: "De Kun Aguero - Viaje de Concepción del Uruguay a Pronunciamiento.";
 *     title: "Nuevo mensaje";
 *     additionalData: {
 *       code: "MESSENGER_33";
 *       attendedAt: null;
 *       payload: '{"reservationChatId":1171,"reservationId":33,"tripStretchId":30,"tripId":30,"pictureUrl":"https://minio.upool.ar/person-picture-url/6dbbf7ba-1d0b-4122-b594-c792b4ce6184.jpg"}';
 *       sendedAt: "2022-05-17T18:11:50.927Z";
 *       readedAt: null;
 *       id: 222;
 *       message: "De Kun Aguero - Viaje de Concepción del Uruguay a Pronunciamiento.";
 *       receivedAt: null;
 *       title: "Nuevo mensaje";
 *       type: "MESSENGER";
 *     };
 *     rawPayload: {
 *       "google.delivered_priority": "normal";
 *       "google.sent_time": 1652811111301;
 *       "google.ttl": 259200;
 *       "google.original_priority": "normal";
 *       custom: '{"a":{"code":"MESSENGER_33","attendedAt":null,"payload":"{\\"reservationChatId\\":1171,\\"reservationId\\":33,\\"tripStretchId\\":30,\\"tripId\\":30,\\"pictureUrl\\":\\"https:\\/\\/minio.upool.ar\\/person-picture-url\\/6dbbf7ba-1d0b-4122-b594-c792b4ce6184.jpg\\"}","sendedAt":"2022-05-17T18:11:50.927Z","readedAt":null,"id":222,"message":"De Kun Aguero - Viaje de Concepción del Uruguay a Pronunciamiento.","receivedAt":null,"title":"Nuevo mensaje","type":"MESSENGER"},"i":"8f0f6b1b-1cbf-450c-829c-73a18b0e2fa2"}';
 *       grp: "MESSENGER_33";
 *       vis: "0";
 *       chnl: '{"dscr":"","grp_nm":"Viajes","grp_id":"OS_d3222ed9-e7f9-4c6b-a482-e9cfe732505f","id":"OS_cb01ccc2-c759-4a39-9bac-a08b75e6b3fa","nm":"Notificaciones de mensajes"}';
 *       from: "284632302232";
 *       alert: "De Kun Aguero - Viaje de Concepción del Uruguay a Pronunciamiento.";
 *       licon: "https://minio.upool.ar/person-picture-url/6dbbf7ba-1d0b-4122-b594-c792b4ce6184.jpg";
 *       title: "Nuevo mensaje";
 *       grp_msg: "Nuevo mensaje (+$[notif_count])";
 *       "google.message_id": "0:1652811111336888%e91048c4f9fd7ecd";
 *       "google.c.sender.id": "284632302232";
 *       androidNotificationId: 980130213;
 *     };
 *     groupKey: "MESSENGER_33";
 *     priority: 0;
 *     largeIcon: "https://minio.upool.ar/person-picture-url/6dbbf7ba-1d0b-4122-b594-c792b4ce6184.jpg";
 *     groupMessage: "Nuevo mensaje (+$[notif_count])";
 *     fromProjectNumber: "284632302232";
 *     androidNotificationId: 980130213;
 *   };
 *   action: {
 *     type: 0;
 *   };
 * }
 */
export interface IOneSignalNotificationOpenedCallback {
  action: {
    type: number; // 0;
  };
  notification: IOneSignalNotificationReceivedCallback;
}

export interface IOneSignalNotificationReceivedCallback {
  notificationId: string;
  body: string;
  title: string;
  additionalData: INotification;
  groupKey: string;
  priority: number;
  largeIcon: string;
  groupMessage: string;
  fromProjectNumber: string;
  androidNotificationId: number;
}
