import React from "react";
import { ToolbarMUI, BodyPage } from "../DeltaUI/Components";
import { Chat } from "../Components/Chat/Chat";
import { MyTripsContext } from "../Components/MyTrips/MyTripsContext";
import { UpoolContext } from "../Providers/UpoolContext";
import ItemAvatarUser from "../Components/User/ItemAvatarUser";
import { EventAppContext } from "../Providers/EventAppProvider";
import { NOTIFICATION_TYPE } from "../Utils/Constants";
import { GoBack } from "../Components/GoBack";
import { CustomPage } from "../Components";

const CreateTripPage: React.FC = () => {
  const { historyGoBack, notificationGeneral, isActiveApp } = React.useContext(
    UpoolContext
  );
  const {
    reservationForChat,
    setReservationForChat,
    isDriverOfSelectedTripStretch,
  } = React.useContext(MyTripsContext);
  const {
    handleEventNotificationMarkAsAttended,
    handleEventUpdateTrip,
  } = React.useContext(EventAppContext);

  const goBack = () => {
    if (reservationForChat) {
      const markAsAttended = notificationGeneral.filter(
        (n) =>
          n.type === NOTIFICATION_TYPE.MESSENGER &&
          n.payload.reservationId === reservationForChat.id
      );
      if (markAsAttended.length) {
        handleEventNotificationMarkAsAttended(markAsAttended);
      }
    }
    setReservationForChat(() => undefined);
    historyGoBack();
    handleEventUpdateTrip();
  };

  return (
    <CustomPage
      headerPage={
        <ToolbarMUI>
          <GoBack onClick={goBack} />
          {!isDriverOfSelectedTripStretch ? (
            <ItemAvatarUser
              user={reservationForChat?.tripStretch.trip.userOwner}
            />
          ) : (
            <ItemAvatarUser user={reservationForChat?.user} />
          )}
        </ToolbarMUI>
      }
    >
      <BodyPage>
        {reservationForChat && isActiveApp ? (
          <Chat reservation={reservationForChat} />
        ) : null}
      </BodyPage>
    </CustomPage>
  );
};

export default CreateTripPage;
