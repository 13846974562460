import React from "react";
import { ButtonMUI, FabMUI } from "..";
import { AppleIcon } from "../CustomIcon";
export interface AppleLoginButtonProps {
  onClick: () => void;
  style?: React.CSSProperties;
  text?: string;
  fab?: boolean;
}

const AppleLoginButton: React.FC<AppleLoginButtonProps> = (props) => {
  const { onClick, style, text, fab } = props;
  if (fab) {
    return (
      <FabMUI style={{ background: "white", ...style }} onClick={onClick}>
        <AppleIcon />
      </FabMUI>
    );
  }
  return (
    <ButtonMUI
      variant="contained"
      size="small"
      style={style}
      sx={{
        width: "100%",
        margin: "8px 0",
        backgroundColor: "#fff",
        textTransform: "uppercase",
        fontWeight: "bold",
        color: "#757575",
      }}
      startIcon={<AppleIcon />}
      onClick={onClick}
    >
      {text || "Apple"}
    </ButtonMUI>
  );
};

export default AppleLoginButton;
