import moment from "moment-with-locales-es6";

moment.locale("es");

const ORIGIN_FORMAT = "YYYY-MM-DDTHH:mm:ss.sssZ";

const DEFAULT_FORMAT_DATE_TIME = "DD/MM/YYYY HH:mm";
const DEFAULT_FORMAT_TIME_DATE = "HH:mm DD/MM/YYYY";
const DEFAULT_FORMAT_DATE = "DD/MM/YYYY";
const DEFAULT_FORMAT_TIME = "HH:mm";

const FORMAT_DESCRIPTION_1 = "D [de] MMMM [de] YYYY";
const FORMAT_DESCRIPTION_3 = "D [de] MMMM";

/**
 * Devuelve moment.Moment
 * Opciones:
 *  - date: Puede ser de tipo "number" o "string"
 *  - utc: Devuelve en la zona actual si el valor es false
 *  - originFormat: Debe pasar el valor del formato de la fecha si quiere mejores resultados
 * @param {string | Date} date
 * @param {boolean} utc
 * @param {string} originFormat
 * @returns {moment.Moment}
 */
const createMoment = (config?: {
  date?: string | number | Date;
  utc?: boolean;
  originFormat?: string;
}): moment.Moment => {
  if (config?.date) {
    let myDate = config.date;

    // si myDate es un number o string
    if (typeof config.date === "number" || typeof config.date === "string") {
      const nan: number | "NaN" = Number(config.date);
      myDate = !(nan.toString() === "NaN") ? nan : config.date;

      if (config?.utc) {
        if (config?.originFormat) {
          return moment.utc(myDate, config?.originFormat);
        }
        return moment.utc(myDate);
      }
      if (config?.originFormat) {
        return moment(myDate, config?.originFormat);
      }
      return moment(myDate);
    }

    // si myDate es una clase Date Javascript
    const m = moment(); // Initial moment object

    // Create the new date
    const _newDate = moment(myDate);

    // Inject it into the initial moment object
    m.set(_newDate.toObject());

    if (config?.utc) {
      return m.utc();
    }
    return m;
  }
  if (config?.utc) {
    return moment.utc();
  }
  return moment();
};

/**
 * Devuelve moment.Moment. Puede especificar un formato
 * @param {moment.Moment} mom
 * @returns {string}
 */
const momentFormat = (mom: moment.Moment, format?: string) => {
  return mom?.format(format || undefined);
};

/**
 * Devuelve Date Javascript de un moment.Moment
 * @param {moment.Moment} mom
 * @returns {Date}
 */
const momentToDate = (mom: moment.Moment) => {
  return mom.toDate();
};

/**
 * Devuelve la fecha actual en formato moment o formato especifico
 * @param {string | Date} date
 * @returns {string}
 * @deprecated @TODO: está repetido ??
 */
const currentDate = (format?: string) => {
  return format ? createMoment().format(format) : createMoment().format();
};

/**
 * Devuelve la fecha especificada en el formato por default o especifico
 * @param {string | Date} date
 * @returns {string}
 * @deprecated @TODO: está repetido ??
 */
const formatDate = (date?: string | Date, format?: string) => {
  return createMoment({ date }).format(format || DEFAULT_FORMAT_TIME);
};

/**
 * Devuelve la fecha actual en el formato unix
 * @param {string | Date} date
 * @returns {string}
 */
const currentDateUnix = (date?: string | Date) => {
  return createMoment({ date, utc: true }).unix() * 1000;
};

/**
 * Devuelvel a fecha en el formato UTC (Coordinated Universal Time)
 * Debe especificar el formato original
 *
 * Ej: 2020-07-21T09:29:34-03:00 -> 2020-07-21T12:29:34Z
 * @param {string | Date} date
 * @returns {string}
 */
const getDateUTC = (date: string | Date, originFormat?: string) => {
  return createMoment({ originFormat, date, utc: true }).format();
};

/**
 * Devuelve la fecha en el formato indicado
 * @deprecated @TODO: está repetido ??
 */
const getFormat = (date?: string | Date, format?: string) => {
  return momentFormat(
    createMoment({
      date,
      originFormat: ORIGIN_FORMAT,
    }),
    format || DEFAULT_FORMAT_TIME
  );
};

/**
 * Devuelve la hora en un formato por default
 */
const getTime = (date?: string | Date) => {
  return momentFormat(
    createMoment({
      date,
      originFormat: ORIGIN_FORMAT,
    }),
    DEFAULT_FORMAT_TIME
  );
};

/**
 * Devuelve la hora y fecha en un formato por default
 */
const getTimeDate = (date?: string | Date) => {
  return momentFormat(
    createMoment({
      date,
      originFormat: ORIGIN_FORMAT,
    }),
    DEFAULT_FORMAT_TIME_DATE
  );
};

/**
 * Devuelve la fecha y hora en un formato por default
 */
const getDateTime = (date?: string | Date) => {
  return momentFormat(
    createMoment({
      date,
      originFormat: ORIGIN_FORMAT,
    }),
    DEFAULT_FORMAT_DATE_TIME
  );
};

/**
 * Devuelve la fecha en un formato por default
 */
const getDate = (date?: string | Date) => {
  return momentFormat(
    createMoment({
      date,
      originFormat: ORIGIN_FORMAT,
    }),
    DEFAULT_FORMAT_DATE
  );
};

/**
 * Devuelve fecha en modo descriptivo 1
 */
const getDescriptionDate = (date?: string | Date) => {
  return momentFormat(
    createMoment({
      date,
      originFormat: ORIGIN_FORMAT,
    }),
    FORMAT_DESCRIPTION_1
  );
};

/**
 * Devuelve fecha en modo descriptivo 2 (19 Oct)
 */
const getDescriptionDate2 = (date?: string | Date, de?: boolean) => {
  const m = createMoment({
    date,
    originFormat: ORIGIN_FORMAT,
  });
  const mmm: string = momentFormat(m, "MMM").replace(".", "");
  return `${momentFormat(m, "D")}${de ? " de " : " "}${mmm
    .slice(0, 1)
    .toUpperCase()}${mmm.slice(1, 3)}`;
};

/**
 * Devuelve fecha en modo descriptivo 1
 */
const getDescriptionDate3 = (date?: string | Date) => {
  return momentFormat(
    createMoment({
      date,
      originFormat: ORIGIN_FORMAT,
    }),
    FORMAT_DESCRIPTION_3
  );
};

/**
 * Agregar tiempo al tiempo actual
 * add: cantidad de tiempo a agregar
 * type: el tipo de tiempo a agregar
 * Lista de tipos
 *  años (y): años
 *  cuartos (Q): cuarto
 *  meses (M): mes
 *  semanas (w): semanas
 *  días (d): día
 *  horas (h): hora
 *  minutos (m): minutos
 *  segundos (s): segundos
 *  milisegundos (ms): milisegundos
 * @param add
 * @param type
 * @returns
 */
const addToDate = (
  add: number,
  type: "y" | "Q" | "M" | "w" | "d" | "h" | "m" | "s" | "ms",
  date?: string | Date
): moment.Moment => {
  return date
    ? createMoment({
        date,
      }).add(add, type)
    : createMoment().add(add, type);
};

/**
 * @description Usando la libreria MomentJS
 *
 * https://momentjs.com/
 */
export const MomentJS = () => {
  return {
    createMoment,
    momentFormat,
    momentToDate,
    currentDate,
    formatDate,
    currentDateUnix,
    getDateUTC,
    getFormat,
    getTime,
    getTimeDate,
    getDateTime,
    getDate,
    getDescriptionDate,
    addToDate,
    getDescriptionDate2,
    getDescriptionDate3,
  };
};
