import React from "react";
import { UpoolContext } from "../../Providers/UpoolContext";
import { useComponentMount } from "../../Utils";
import { CreateTripContext } from "./CreateTripContext";

export interface CreateTripConfirmGoBackProps {}

export const CreateTripConfirmGoBack: React.FC<CreateTripConfirmGoBackProps> = () => {
  const isMountedComponent = useComponentMount("CreateTripConfirmGoBack");
  const { cancel } = React.useContext(CreateTripContext);
  const {
    setConfirmChangePage,
    setGoBackFunctionList,
    historyGoBack,
  } = React.useContext(UpoolContext);

  React.useEffect(() => {
    setConfirmChangePage(() => cancel);
    if (isMountedComponent.current) {
      setGoBackFunctionList((l) => {
        l.pop();
        return [...l, () => cancel(historyGoBack)];
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cancel]);

  React.useEffect(() => {
    isMountedComponent.current = true;
    setGoBackFunctionList((l) => [...l, () => cancel(historyGoBack)]);
    return () => {
      isMountedComponent.current = false;
      setConfirmChangePage(() => undefined);
      setGoBackFunctionList((l) => {
        l.pop();
        return l;
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMountedComponent]);

  return <></>;
};
