import React from "react";
import { CardMUI } from "../../DeltaUI/Components";
import { ITripStretch } from "../../Services/TripService";
import { ItemUser } from "../User/ItemUser";

export interface CardReservationDriverProps {
  tripStretch: ITripStretch;
}

const CardReservationDriver: React.FC<CardReservationDriverProps> = (props) => {
  const { tripStretch } = props;

  const userOwner = React.useMemo(
    () => tripStretch.trip.userOwner,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tripStretch]
  );

  return (
    <CardMUI
      elevation={7}
      style={{
        marginBottom: "16px",
        padding: "16px 24px",
      }}
      className="ShadowCard8"
    >
      <ItemUser sizeAvatar={5} user={userOwner} isDrive={true} />
    </CardMUI>
  );
};

export default CardReservationDriver;
