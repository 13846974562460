import React from "react";
import {
  ToolbarMUI,
  BodyPage,
  FormControlMUI,
  InputLabelMUI,
  InputMUI,
  BoxMUI,
  ButtonMUI,
  Separator,
} from "../../../DeltaUI/Components";
import { UpoolContext } from "../../../Providers/UpoolContext";
import { UserService } from "../../../Services/UserService";
import { TitlePage } from "../../../Components/TitlePage";
import { CustomPage, DialogCustomHook } from "../../../Components";
import { GoBack } from "../../../Components/GoBack";
import { MomentJS, Tools, useComponentMount } from "../../../Utils";
import { SelectBirthdate } from "../../../Components/User/SelectBirthdate";
import { SelectPersonGender } from "../../../Components/User/SelectPersonGender";

const PersonalInformationPage: React.FC = () => {
  const isMountedComponent = useComponentMount("PersonalInformationPage");
  const { updateOneUser } = UserService();
  const { getPerson } = Tools();
  const { createMoment, getDate } = MomentJS();
  const { user, setLoading } = React.useContext(UpoolContext);
  const { message, messageError } = DialogCustomHook();
  const [firstName, setFirstName] = React.useState<string>("");
  const [lastName, setLastName] = React.useState<string>("");
  const [birthdate, setBirthdate] = React.useState<Date>(new Date());
  const [genderId, setGenderId] = React.useState<number>(1);

  const person = React.useMemo(
    () => getPerson(user, 0),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user]
  );

  const personBirthdate = React.useMemo(() => {
    return createMoment({
      date: getDate(person?.birthdate),
      originFormat: "DD/MM/YYYY",
    }).toDate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [person]);

  const isUpdate = React.useMemo(() => {
    if (person) {
      return (
        person.firstName !== firstName ||
        person.lastName !== lastName ||
        personBirthdate > birthdate ||
        personBirthdate < birthdate ||
        person.genderId !== genderId
      );
    }
    return false;
  }, [birthdate, firstName, genderId, lastName, person, personBirthdate]);

  const handleUpdateUser = () => {
    if (user) {
      setLoading(() => true);
      updateOneUser({
        id: user.id,
        firstName,
        lastName,
        birthdate,
        genderId,
      })
        .then(() => {
          message({
            context: "PhoneSetting.handleUpdateUser.1",
            title: "¡Listo!",
            message: "Sus datos se actualizaron correctamente.",
          });
        })
        .catch(({ message }) => {
          messageError({
            context: "PhoneSetting.handleUpdateUser.2",
            message: message,
          });
        })
        .finally(() => setLoading(() => false));
    }
  };

  React.useEffect(() => {
    if (person) {
      setFirstName(() => person.firstName);
      setLastName(() => person.lastName);
      setBirthdate(() => personBirthdate);
      setGenderId(() => person.genderId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [person]);

  React.useEffect(() => {
    isMountedComponent.current = true;
    return () => {
      isMountedComponent.current = false;
    };
  }, [isMountedComponent]);

  return (
    <CustomPage
      headerPage={
        <ToolbarMUI>
          <GoBack />
          <TitlePage text="Datos personales" />
        </ToolbarMUI>
      }
    >
      <BodyPage>
        <BoxMUI style={{ margin: "24px" }}>
          <form noValidate autoComplete="off">
            <FormControlMUI
              id="firstName"
              variant="standard"
              style={{
                width: "100%",
                margin: "8px 0",
              }}
            >
              <InputLabelMUI
                htmlFor="standar-adornment-firstName"
                className="TypographySemibold11pt"
              >
                Nombre
              </InputLabelMUI>
              <InputMUI
                id="standar-adornment-firstName"
                type="text"
                value={firstName}
                onChange={(ev) => setFirstName(() => ev.target.value)}
              />
            </FormControlMUI>
            <Separator px={8} />
            <FormControlMUI
              id="lastName"
              variant="standard"
              style={{
                width: "100%",
                margin: "8px 0",
              }}
            >
              <InputLabelMUI
                htmlFor="standar-adornment-lastName"
                className="TypographySemibold11pt"
              >
                Apellido
              </InputLabelMUI>
              <InputMUI
                id="standar-adornment-lastName"
                type="text"
                value={lastName}
                onChange={(ev) => setLastName(() => ev.target.value)}
              />
            </FormControlMUI>
            <Separator px={8} />
            <SelectBirthdate
              value={birthdate}
              onChange={(v) => setBirthdate(() => v)}
              style={{
                width: "100%",
                margin: "8px 0",
              }}
            />
            <Separator px={8} />
            <SelectPersonGender
              value={genderId}
              onChange={(v) => setGenderId(() => v)}
              onLoading={(v) => setLoading(() => v)}
              onError={messageError}
              style={{
                width: "100%",
                margin: "8px 0",
              }}
            />
          </form>
        </BoxMUI>
        <BoxMUI
          style={{
            position: "fixed",
            bottom: "var(--ios-action-footer-height)",
            width: "100%",
            display: "flex",
            padding: "24px",
          }}
        >
          <ButtonMUI
            style={{ width: "100%" }}
            variant="contained"
            color="primary"
            onClick={handleUpdateUser}
            disabled={!isUpdate}
          >
            Actualizar
          </ButtonMUI>
        </BoxMUI>
        <div style={{ width: "100%", height: "100px" }} />
      </BodyPage>
    </CustomPage>
  );
};

export default PersonalInformationPage;
