import React from "react";
import { UpoolContext } from "../../Providers/UpoolContext";
import {
  ButtonMUI,
  BoxMUI,
  Separator,
  TypographyMUI,
  FormControlLabelMUI,
  RadioMUI,
  RadioGroupMUI,
} from "../../DeltaUI/Components";
import { DialogCustomHook } from "../DialogCustom";
import {
  DeleteAccountContext,
  DELETE_ACCOUNT_STEPS,
} from "./DeleteAccountContext";

interface VerificationOptionsProps {}

export const VerificationOptions: React.FC<VerificationOptionsProps> = () => {
  const { historyGoBack, user } = React.useContext(UpoolContext);
  const { setStep, valueType, setValueType } = React.useContext(
    DeleteAccountContext
  );
  const { messageWarn } = DialogCustomHook();

  const blockEmail = React.useMemo(() => {
    if (user) {
      const mapEmail = user.email ? user.email.split("@") : [];
      if (mapEmail.length) {
        const l = mapEmail[0].length;
        const i = Number.parseFloat(`${l / 2}`).toFixed(0);
        return `${mapEmail[0].slice(0, l - Number(i))}****@${mapEmail[1]}`;
      }
    }
    return null;
  }, [user]);

  const next = () => {
    setStep(() => DELETE_ACCOUNT_STEPS.ENTRE_CODE);
  };

  React.useEffect(() => {
    if (user) {
      setValueType(() => (user?.email ? "email" : "phone"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <BoxMUI
        style={{
          width: "100%",
        }}
      >
        <Separator px={8} />
        <TypographyMUI className="TypographyBold11pt">
          Opciones de verificación
        </TypographyMUI>
        <Separator px={16} />
        <RadioGroupMUI
          defaultValue={user?.email ? "email" : "phone"}
          onChange={(ev) => setValueType(() => ev.target.value as any)}
        >
          {user?.email ? (
            <FormControlLabelMUI
              sx={{
                "& .MuiTypography-root": {
                  fontFamily: "'Open Sans Regular' !important",
                  fontSize: "10pt !important",
                },
              }}
              value="email"
              control={<RadioMUI style={{ marginLeft: "8px" }} />}
              label={`Enviar un código de confirmación por correo electrónico a ${blockEmail}`}
              labelPlacement="start"
              style={{ margin: "0" }}
            />
          ) : null}
          {user?.phone ? (
            <>
              {user?.email ? <Separator px={16} divider /> : null}
              <FormControlLabelMUI
                value="phone"
                control={<RadioMUI style={{ marginLeft: "8px" }} />}
                sx={{
                  "& .MuiTypography-root": {
                    fontFamily: "'Open Sans Regular' !important",
                    fontSize: "10pt !important",
                  },
                }}
                label={`Enviar un código de confirmación por WhatsApp a mi teléfono terminado en ${user.phone.slice(
                  user.phone.length - 4,
                  user.phone.length
                )}`}
                labelPlacement="start"
                style={{ margin: "0" }}
              />
            </>
          ) : null}
        </RadioGroupMUI>
      </BoxMUI>
      <BoxMUI
        style={{
          width: "100%",
        }}
      >
        <ButtonMUI
          style={{
            width: "100%",
            marginTop: "16px",
          }}
          variant="outlined"
          color="primary"
          onClick={() => {
            messageWarn({
              context: "DeleteAccount.VerificationOptions.2",
              message: "Contacta a un administrador para que podamos ayudarte.",
            });
            historyGoBack();
          }}
        >
          Ver más opciones
        </ButtonMUI>
        <Separator px={16} />
        <ButtonMUI
          style={{
            width: "100%",
            marginTop: "16px",
          }}
          variant="contained"
          color="primary"
          onClick={next}
          disabled={!valueType}
        >
          Continuar
        </ButtonMUI>
        <Separator px={32} />
      </BoxMUI>
    </>
  );
};
