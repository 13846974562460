import React from "react";
import { IonMenu } from "@ionic/react";
import { UpoolContext } from "../../Providers/UpoolContext";
import {
  ListMUI,
  MenuItem,
  ListItemMUI,
  ListItemTextMUI,
  BoxMUI,
  TypographyMUI,
  Separator,
} from "../../DeltaUI/Components";
import {
  HomeOutlinedIcon,
  ExitToAppIcon,
  DescriptionOutlinedIcon,
  PersonOutlineOutlinedIcon,
  AssistantOutlinedIcon,
} from "../../DeltaUI/Components/CustomIcon";
import { menuController } from "@ionic/core";
import {
  COLOR_TERTIARY,
  APPLICATION_PATHS,
  COLOR_QUINARY,
} from "../../Utils/Constants";
import UserAvatar from "../User/UserAvatar";
import { EventAppContext } from "../../Providers/EventAppProvider";
import { UserService } from "../../Services/UserService";
import HeaderPageCustom from "../HeaderPage";
import { CustomPage } from "../CustomPage";

export interface SecurityLayoutMenuProps {
  menuId: string;
}

const SecurityLayoutMenu: React.FC<SecurityLayoutMenuProps> = (props) => {
  const { menuId } = props;
  const { logout } = UserService();
  const { handleEventLogin } = React.useContext(EventAppContext);
  const {
    user,
    setLoading,
    historyPush,
    person,
    setConfirmChangePage,
  } = React.useContext(UpoolContext);

  const handlePush = (page: string) => {
    menuController.close();
    historyPush(page);
  };

  const handleLogout = () => {
    setConfirmChangePage(() => undefined);
    logout();
  };

  React.useEffect(() => {
    setLoading(() => false);
    if (user) {
      handleEventLogin();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goMyProfile = () => {
    handlePush(`${APPLICATION_PATHS.USER}/user`);
  };

  return (
    <IonMenu
      contentId={menuId}
      type="overlay"
      swipeGesture={true}
      maxEdgeStart={16}
    >
      <CustomPage fullScreen={true}>
        <HeaderPageCustom
          className="Transparent"
          style={{ padding: "16px 8px 0" }}
        >
          {user ? (
            <>
              <ListItemMUI
                style={{
                  alignItems: "flex-end",
                  paddingBottom: "0",
                }}
              >
                <BoxMUI>
                  <UserAvatar size={6} user={user} onClick={goMyProfile} />
                </BoxMUI>
              </ListItemMUI>
              <ListItemMUI
                style={{
                  paddingTop: "0",
                  paddingBottom: "0",
                }}
              >
                <ListItemTextMUI
                  primary={
                    <TypographyMUI className="TypographyBold13pt">
                      {person ? `${person.firstName} ${person.lastName}` : ""}
                    </TypographyMUI>
                  }
                  secondary={
                    <TypographyMUI
                      color={COLOR_QUINARY}
                      className="TypographyRegular11pt"
                    >
                      {user.email || user.phone}
                    </TypographyMUI>
                  }
                />
              </ListItemMUI>
            </>
          ) : (
            <ListItemMUI
              style={{
                paddingTop: "0",
                paddingBottom: "0",
              }}
            >
              <ListItemTextMUI primary={`Usuario no disponible!`} />
            </ListItemMUI>
          )}
        </HeaderPageCustom>
        <ListMUI sx={{ padding: "0 8px 24px" }}>
          <ListItemMUI>
            <Separator px={8} divider />
          </ListItemMUI>
          <MenuItem
            icon={<HomeOutlinedIcon style={{ color: COLOR_TERTIARY }} />}
            text={<div className="TypographyBold11pt">Home</div>}
            onClick={() => handlePush("/tabs")}
          />
          <MenuItem
            icon={
              <PersonOutlineOutlinedIcon style={{ color: COLOR_TERTIARY }} />
            }
            text={<div className="TypographyBold11pt">Perfil</div>}
            onClick={goMyProfile}
          />
          <MenuItem
            icon={<DescriptionOutlinedIcon style={{ color: COLOR_TERTIARY }} />}
            text={
              <div className="TypographyBold11pt">Términos y condiciones</div>
            }
            onClick={() => handlePush("/terms-and-conditions")}
          />
          <MenuItem
            icon={<AssistantOutlinedIcon style={{ color: COLOR_TERTIARY }} />}
            text={<div className="TypographyBold11pt">Tu opinión</div>}
            onClick={() => handlePush("/comments")}
          />
          <ListItemMUI>
            <Separator px={8} divider />
          </ListItemMUI>
          <MenuItem
            icon={<ExitToAppIcon style={{ color: COLOR_TERTIARY }} />}
            text={<div className="TypographyBold11pt">Cerrar sesión</div>}
            onClick={handleLogout}
          />
        </ListMUI>
      </CustomPage>
    </IonMenu>
  );
};

export default SecurityLayoutMenu;
