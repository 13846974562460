import React from "react";
import { GoogleMapsContext, IGoogleMapsPosition } from "./GoogleMapsProvider";

const DEFAULT_POSITION = {
  lat: -35.999528,
  lng: -65.116949,
};

interface SetCenterProps {
  position?: IGoogleMapsPosition;
}

export const SetCenter: React.FC<SetCenterProps> = (props) => {
  const { position } = props;
  const { map } = React.useContext(GoogleMapsContext);

  React.useEffect(() => {
    if (map) {
      map.setCenter(position || DEFAULT_POSITION);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [position, map]);

  return <></>;
};
