import React from "react";
import { BoxMUI, Separator, TypographyMUI } from "../../DeltaUI/Components";
import { MomentJS } from "../../Utils";
import { COLOR_QUINARY } from "../../Utils/Constants";

export interface ChatSeparatorDateProps {
  date: Date;
}

export const ChatSeparatorDate: React.FC<ChatSeparatorDateProps> = (props) => {
  const { date } = props;
  const { getDescriptionDate3, createMoment, getDate, addToDate } = MomentJS();
  const today = React.useRef<Date>(
    createMoment({
      date: getDate(new Date()),
      originFormat: "DD/MM/YYYY",
    }).toDate()
  );
  const yesterday = React.useRef<Date>(
    createMoment({
      date: getDate(addToDate(-1, "d", new Date()).toDate()),
      originFormat: "DD/MM/YYYY",
    }).toDate()
  );
  const createdAt = React.useRef<Date>(
    createMoment({
      date: getDate(date),
      originFormat: "DD/MM/YYYY",
    }).toDate()
  );

  const message = React.useMemo(() => {
    if (
      createdAt.current < today.current &&
      !(createdAt.current < yesterday.current)
    ) {
      return "Ayer";
    } else if (
      !(createdAt.current < today.current) &&
      createdAt.current > yesterday.current
    ) {
      return "Hoy";
    }
    return getDescriptionDate3(createdAt.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {date ? (
        <>
          <Separator px={16} />
          <BoxMUI style={{ width: "100%", textAlign: "center" }}>
            <TypographyMUI
              className="TypographyRegular10pt"
              color={COLOR_QUINARY}
            >
              {message}
            </TypographyMUI>
          </BoxMUI>
          <Separator px={16} />
        </>
      ) : null}
    </>
  );
};
