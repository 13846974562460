import React from "react";
import {
  BoxMUI,
  ButtonMUI,
  Separator,
  TypographyMUI,
} from "../../DeltaUI/Components";
import AvailablePlaces from "../AvailablePlaces";
import { DialogCustom } from "../DialogCustom";
export interface DialogConfirmCountPassengerProps {
  open: boolean;
  onCancel: () => void;
  onOk: () => void;
  value: number;
  onChange?: (ev: number) => void;
  disabledOk?: boolean;
  availablePlaces: number;
  totalAmount: number;
}

const DialogConfirmCountPassenger: React.FC<
  DialogConfirmCountPassengerProps
> = (props) => {
  const {
    open,
    onCancel,
    onOk,
    value,
    onChange,
    disabledOk,
    availablePlaces,
    totalAmount,
  } = props;

  React.useEffect(() => {
    if (open && onChange) {
      onChange(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <div>
      <DialogCustom
        context="DialogConfirmCountPassenger.1"
        title={
          <TypographyMUI className="FullWidth TextCenter TypographyBold13pt">
            Lugares a reservar
          </TypographyMUI>
        }
        open={open}
        onClose={onCancel}
        body={
          <>
            <AvailablePlaces
              value={value}
              onChange={(ev) => {
                if (onChange) {
                  onChange(ev);
                }
              }}
              max={availablePlaces}
            />
            <Separator px={24} />
            <TypographyMUI>
              El precio es de{" "}
              <b style={{ fontWeight: "bold" }}>${totalAmount * value}</b>
            </TypographyMUI>
            <Separator px={24} />
            <TypographyMUI>
              {availablePlaces > 1
                ? `Quedan ${availablePlaces} lugares disponibles`
                : `Queda ${availablePlaces} lugar disponible`}
            </TypographyMUI>
          </>
        }
        footer={
          <BoxMUI className="FlexRow" style={{ padding: "16px 24px" }}>
            <ButtonMUI
              style={{ width: "50%" }}
              variant="outlined"
              onClick={onCancel}
            >
              Cancelar
            </ButtonMUI>
            <Separator px={16} orientation="vertical" />
            <ButtonMUI
              style={{ width: "50%" }}
              variant="contained"
              color="primary"
              onClick={onOk}
              disabled={disabledOk}
            >
              Solicitar
            </ButtonMUI>
          </BoxMUI>
        }
      />
    </div>
  );
};

export default DialogConfirmCountPassenger;
