import React from "react";
import {
  BodyPage,
  Tabs,
  ToolbarMUI,
  MenuButton,
  Stepper,
} from "../DeltaUI/Components";
import { CreateTripDate, CreateTripMap, CustomPage } from "../Components";
import {
  CreateTripContext,
  CreateTripProvider,
  STEPS,
} from "../Components/CreateTrip/CreateTripContext";
import CreateTripVehicle from "../Components/CreateTrip/CreateTripVehicle";
import CreateTripPreference from "../Components/CreateTrip/CreateTripPreference";
import CreateTripResume from "../Components/CreateTrip/CreateTripResume";
import { CreateTripEnd } from "../Components/CreateTrip/CreateTripEnd";
import { TitlePage } from "../Components/TitlePage";
import { Imgs } from "../Assets";
import { GoBack } from "../Components/GoBack";
import { UpoolContext } from "../Providers/UpoolContext";
import {
  APPLICATION_PATHS,
  COLOR_PRIMARY,
  COLOR_QUATERNARY,
} from "../Utils/Constants";
import { CreateTripConfirmGoBack } from "../Components/CreateTrip/CreateTripConfirmGoBack";

const CreateTripPageContent: React.FC = () => {
  const {
    tabResume,
    setTabResume,
    steps,
    next,
    finish,
    step,
    setStep,
    createTripEndMessage,
  } = React.useContext(CreateTripContext);
  const { historyPush } = React.useContext(UpoolContext);

  return (
    <CustomPage
      headerPage={
        <>
          <ToolbarMUI>
            {step === STEPS.MAP ? (
              <MenuButton style={{ color: COLOR_PRIMARY }} />
            ) : (
              <GoBack
                onClick={() => {
                  setStep(() => STEPS.MAP);
                }}
              />
            )}
            <TitlePage text="Crear viaje" />
          </ToolbarMUI>
          <Stepper
            list={steps}
            value={step}
            onChange={(stepId) => {
              if (
                stepId < step ||
                steps.find((s) => s.id === stepId - 1)?.completed
              ) {
                setStep(() => stepId);
              }
            }}
            color="secondary"
          />
          {step === STEPS.RESUME ? (
            <Tabs
              value={tabResume}
              invertedColor={true}
              color="secondary"
              textColor={COLOR_QUATERNARY}
              defaultValue={1}
              onChange={(ev) => setTabResume(() => ev)}
              list={[
                {
                  key: 1,
                  label: "Resumen",
                  onClick: () => {},
                },
                {
                  key: 2,
                  label: "Mapa",
                  onClick: () => {},
                },
              ]}
            />
          ) : null}
        </>
      }
    >
      <CreateTripConfirmGoBack />

      <BodyPage>
        {step === STEPS.MAP ? (
          <CreateTripMap onNext={() => next(STEPS.MAP, STEPS.DATE)} />
        ) : null}
        {step === STEPS.DATE ? (
          <CreateTripDate onNext={() => next(STEPS.DATE, STEPS.VEHICLE)} />
        ) : null}
        {step === STEPS.VEHICLE ? (
          <CreateTripVehicle
            onNext={() => next(STEPS.VEHICLE, STEPS.PREFERENCE)}
          />
        ) : null}
        {step === STEPS.PREFERENCE ? (
          <CreateTripPreference
            onNext={() => next(STEPS.PREFERENCE, STEPS.RESUME)}
          />
        ) : null}
        {step === STEPS.RESUME ? <CreateTripResume onNext={finish} /> : null}
      </BodyPage>

      <CreateTripEnd
        open={createTripEndMessage}
        onOk={() => historyPush(APPLICATION_PATHS.HOME)}
        image={Imgs.tripCreated}
      />
    </CustomPage>
  );
};

const CreateTripPage: React.FC<{}> = () => {
  return (
    <CreateTripProvider>
      <CreateTripPageContent />
    </CreateTripProvider>
  );
};

export default CreateTripPage;
