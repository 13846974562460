import React from "react";
import { ButtonMUI } from "..";
import EmptyData from "../../../Components/EmptyData";
import { GPS, GPS_STATUS, StatusGPS } from "../../Utils/GPS";
import { LocationOffIcon } from "../CustomIcon";
import { GoogleMapsProvider } from "./GoogleMapsProvider";

export interface GoogleMapsProps {
  style?: React.CSSProperties;
  onLoad?: Function;
  onError?: (err: Error) => void;
  headerElement?: JSX.Element;
  footerElement?: JSX.Element;
}

export const GoogleMaps: React.FC<GoogleMapsProps> = (props) => {
  const {
    style,
    onLoad,
    children,
    onError,
    headerElement,
    footerElement,
  } = props;
  const { isAvailable, request } = GPS();

  const [statusGPS, setStatusGPS] = React.useState<StatusGPS | undefined>();

  const requestButton = (
    <ButtonMUI
      onClick={() => {
        request()
          .then((s) => setStatusGPS(() => s))
          .catch(onError);
      }}
    >
      Reintentar
    </ButtonMUI>
  );

  React.useEffect(() => {
    isAvailable()
      .then((s) => setStatusGPS(() => s))
      .catch(onError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {statusGPS === GPS_STATUS.GRANTED ? (
        <GoogleMapsProvider
          onLoad={onLoad}
          style={style}
          headerElement={headerElement}
          footerElement={footerElement}
        >
          {children}
        </GoogleMapsProvider>
      ) : null}
      {statusGPS === GPS_STATUS.DISABLED ? (
        <>
          <EmptyData
            icon={<LocationOffIcon />}
            description="GPS apagado"
            height="45vh"
            button={requestButton}
          />
        </>
      ) : null}
      {statusGPS === GPS_STATUS.DENIED ? (
        <>
          <EmptyData
            icon={<LocationOffIcon />}
            description="No tiene permisos de ubicación"
            height="45vh"
            button={requestButton}
          />
        </>
      ) : null}
      {statusGPS === GPS_STATUS.ERROR ? (
        <>
          <EmptyData
            icon={<LocationOffIcon />}
            description="No se puede cargar el mapa"
            height="45vh"
            button={requestButton}
          />
        </>
      ) : null}
    </>
  );
};
