import areaCode from "./areaCode.json";

export const Tools = () => {
  const notAvailable = () => {
    alert("Se encuentra en desarrollo!!");
  };

  const filter = (list: any[], search: string) => {
    if (search) {
      const result: any[] = list.filter((item) => {
        let itemLower = JSON.stringify(item);
        itemLower = itemLower.toLowerCase();
        const searchSplit: any[] = search.toLowerCase().split(" ");
        let validate = 0;
        searchSplit.forEach((search) => {
          if (itemLower.indexOf(search) !== -1) {
            validate += 1;
          }
        });
        return validate === searchSplit.length;
      });
      return result;
    } else {
      return list;
    }
  };

  const parseObj = (obj: any) => {
    const result: any = {};
    Object.keys(obj).forEach((key: any) => {
      const nan: any = Number(obj[key]);
      if (!(nan.toString() === "NaN")) {
        result[key] = Number(obj[key]);
      } else if (obj[key] === "false") {
        result[key] = false;
      } else if (obj[key] === "true") {
        result[key] = true;
      } else {
        result[key] = obj[key];
      }
    });
    return result;
  };

  const getParamsURL = () => {
    const params: any = {};
    const search = window.location.search.replaceAll("?", "");
    const split1 = search.split("&");

    for (const param of split1) {
      const split2 = param.split("=");
      params[split2[0]] = split2[1];
    }
    return parseObj(params);
  };

  const validateEmail = (email: string) => {
    if (!email) {
      return false;
    }
    const e = email.replaceAll(" ", "");
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return e ? re.test(e) : false;
  };

  const validatePhone = (phone: string) => {
    if (!phone) {
      return false;
    }
    const e = phone.replaceAll(" ", "");
    const re = /^[0-9]{10}$/;
    return e ? re.test(e) : false;
  };

  const validatePassword = (password: string) => {
    if (!password) {
      return false;
    }
    const e = password;

    /**
     *  
        /^
          (?=.*\d)          // should contain at least one digit
          (?=.*[a-z])       // should contain at least one lower case
          (?=.*[A-Z])       // should contain at least one upper case
          [a-zA-Z0-9]{8,}   // should contain at least 8 from the mentioned characters
        $/
     */
    const re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,16}$/;
    return e ? re.test(e) : false;
  };

  const validatePasswordWithSpecialCharacters = (password: string) => {
    if (!password) {
      return false;
    }
    const e = password;

    // algunos caracteres especiales @ -(escapado) _ $ # % ¡ !
    const re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@\-_$#%¡!])[0-9a-zA-Z@\-_$#%¡!]{8,16}$/;

    // todos los caracteres especiales
    // const re =
    //   /^(?=(?:.*\d){1})(?=(?:.*[A-Z]){1})(?=(?:.*[a-z]){1})(?=(?:.*\W){1})\S{8,16}$/;
    return e ? re.test(e) : false;
  };

  const applyMaskPhone = (phone: string, disabledMask?: boolean) => {
    const e = removeMaskPhone(phone.replaceAll(" ", ""));

    if (!disabledMask) {
      if (e.length > 4) {
        const validate1 = e.slice(0, 4);
        if (areaCode.x4.some((i) => i === validate1)) {
          const res = `${validate1}-${e.slice(4, e.length)}`;
          return res.slice(0, 11);
        }
      }

      if (e.length > 3) {
        const validate2 = e.slice(0, 3);
        if (areaCode.x3.some((i) => i === validate2)) {
          const res = `${validate2}-${e.slice(3, e.length)}`;
          return res.slice(0, 11);
        }
      }

      if (e.length > 2) {
        const validate3 = e.slice(0, 2);
        if (areaCode.x2.some((i) => i === validate3)) {
          const res = `${validate3}-${e.slice(2, e.length)}`;
          return res.slice(0, 11);
        }
      }
    }

    return e.slice(0, 11);
  };

  const removeMaskPhone = (phone: string) => {
    const e = phone.replaceAll("-", "");
    return e.slice(0, 10);
  };

  const blobToBase64 = async (
    blob: Blob
  ): Promise<string | ArrayBuffer | null> => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  };

  const URLFileToBlob = async (fileURL: string): Promise<Blob | undefined> => {
    const res = await fetch(fileURL);
    if (res.ok) {
      const blob = await res.blob();
      return blob;
    }
    return undefined;
  };

  const URLFileToFile = async (
    fileURL: string,
    filename: string
  ): Promise<File | undefined> => {
    const blob = await URLFileToBlob(fileURL);
    if (blob) {
      return new File([blob], filename, {
        type: blob.type,
      });
    }
    return undefined;
  };

  const blobToFile = (blob: Blob, filename: string): File => {
    return new File([blob], filename, {
      type: blob.type,
    });
  };

  const srcToHTMLImageElement = (src: string): Promise<HTMLImageElement> => {
    return new Promise((resolve, reject) => {
      const img = document.createElement("img");
      img.setAttribute("crossorigin", "anonymous");
      img.onload = () => resolve(img);
      img.onerror = reject;
      img.src = src;
    });
  };

  const base64ToObjectURL = (
    src: string,
    quality?: number
  ): Promise<string> => {
    return new Promise((resolve, reject) => {
      srcToHTMLImageElement(src)
        .then((img) => {
          try {
            const canvas = document.createElement("canvas");
            canvas.height = img.height;
            canvas.width = img.width;
            const ctx = canvas.getContext("2d");
            if (ctx) {
              ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
            }
            canvas.toBlob(
              (blob: Blob | null) => {
                if (blob) {
                  return resolve(URL.createObjectURL(blob));
                } else {
                  return reject(
                    new Error("Tools.base64ToObjectURL.1 -> blob is null")
                  );
                }
              },
              "image/jpeg",
              quality
            );
          } catch ({ message }) {
            return reject(new Error(`Tools.base64ToObjectURL.2 -> ${message}`));
          }
        })
        .catch(({ message }) => {
          return reject(new Error(`Tools.base64ToObjectURL.3 -> ${message}`));
        });
    });
  };

  return {
    notAvailable,
    filter,
    parseObj,
    getParamsURL,
    validateEmail,
    validatePhone,
    applyMaskPhone,
    removeMaskPhone,
    URLFileToBlob,
    URLFileToFile,
    blobToFile,
    validatePassword,
    validatePasswordWithSpecialCharacters,
    srcToHTMLImageElement,
    base64ToObjectURL,
    blobToBase64,
  };
};
