import React from "react";
import {
  ButtonMUI,
  CalendarPicker,
  FormControlMUI,
  InputLabelMUI,
  InputMUI,
  Separator,
} from "../../DeltaUI/Components";
import { MomentJS } from "../../Utils";
import { COLOR_SECONDARY } from "../../Utils/Constants";
import { DialogCustom } from "../DialogCustom";

interface SelectBirthdateProps {
  style?: React.CSSProperties;
  className?: string;
  value?: Date;
  onChange?: (value: Date) => void;
}

export const SelectBirthdate: React.FC<SelectBirthdateProps> = (props) => {
  const { style, className, value, onChange } = props;
  const { getDescriptionDate, addToDate } = MomentJS();
  const [viewCalendar, setViewCalendar] = React.useState<boolean>(false);
  const [birthdate, setBirthdate] = React.useState<Date>();

  return (
    <>
      <FormControlMUI variant="standard" style={style} className={className}>
        <InputLabelMUI>Fecha de nacimiento</InputLabelMUI>
        <InputMUI
          type="text"
          value={value ? getDescriptionDate(value) : ""}
          onClick={() => setViewCalendar(() => true)}
        />
      </FormControlMUI>
      {viewCalendar ? (
        <DialogCustom
          context="SelectBirthdate.1"
          open={true}
          onClose={() => {
            if (viewCalendar) {
              setBirthdate(() => value);
              setViewCalendar(() => false);
            }
          }}
          body={
            <div style={{ padding: "16px 0" }}>
              <CalendarPicker
                startDate={value || addToDate(-17, "y").toDate()}
                maxDate={addToDate(-17, "y").toDate()}
                onChange={(ev) => setBirthdate(() => ev)}
                header={false}
                color={COLOR_SECONDARY}
                showMonthAndYearPickers={true}
              />
              <Separator px={16} />
              <ButtonMUI
                className="FullWidth"
                variant="contained"
                color="primary"
                onClick={() => {
                  setViewCalendar(() => false);
                  if (onChange && birthdate) {
                    onChange(birthdate);
                  }
                }}
              >
                Aceptar
              </ButtonMUI>
            </div>
          }
        />
      ) : null}
    </>
  );
};
