import { CREDENTIALS, ICredentials } from "./credentials";
import app from "./app.config.json";

export type Compilation = "MOBILE" | "WEB";
export enum COMPILATION_TYPE {
  MOBILE = "MOBILE",
  WEB = "WEB",
}
export type Environment = "LOCAL" | "TEST" | "STAGE" | "PROD";
export enum ENVIRONMENT {
  LOCAL = "LOCAL",
  TEST = "TEST",
  STAGE = "STAGE",
  PROD = "PROD",
}

/**
 * Configuración de ambientes
 */
const COMPILATION_MODE: Compilation = COMPILATION_TYPE.MOBILE;
const DEFAULT_ENV_APP: Environment = ENVIRONMENT.PROD;
const CONFIG_ENVIRONMENTS: {
  LOCAL: IConfigEnv;
  STAGE: IConfigEnv;
  PROD: IConfigEnv;
} = {
  LOCAL: {
    httpUrl: `http://localhost:4000/graphql`,
    wssUrl: `ws://localhost:4000/graphql`,
    credentials: CREDENTIALS.TEST,
    clientSecret: app.clientSecret,
  },
  STAGE: {
    httpUrl: `https://apistage.upool.ar/graphql`,
    wssUrl: `wss://apistage.upool.ar/graphql`,
    credentials: CREDENTIALS.STAGE,
    clientSecret: app.clientSecret,
  },
  PROD: {
    httpUrl: `https://api1410.upool.ar/graphql`,
    wssUrl: `wss://api1410.upool.ar/subscriptions`,
    credentials: CREDENTIALS.PROD,
    clientSecret: app.clientSecret,
  },
};

export interface IConfigEnv {
  httpUrl: string;
  wssUrl: string;
  credentials: ICredentials;
  clientSecret: string | undefined;
}
export interface IConfigApp {
  appId: string;
  appName: string;
  versionCode: string;
  versionName: string;
  linkStore: {
    ios: string;
    android: string;
  };
  envName: Environment;
  env: IConfigEnv;
  compilationMode: Compilation;
}

/**
 * Configuración de la aplicación
 */
export const config: IConfigApp = {
  appId: "upool.app",
  appName: "uPool",
  versionName: app.versionName,
  versionCode: app.versionCode,
  linkStore: {
    ios: "https://apps.apple.com/ar/app/upool/id1620249997",
    android: "https://play.google.com/store/apps/details?id=upool.app",
  },
  envName: DEFAULT_ENV_APP,
  env: CONFIG_ENVIRONMENTS[DEFAULT_ENV_APP],
  compilationMode: COMPILATION_MODE,
};

export const IS_WEB = config.compilationMode === COMPILATION_TYPE.WEB;
