/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";

import "./DeltaUI/index.css";

/* Imports */
import React from "react";
import "./App.scss";
import { IonApp } from "@ionic/react";
import { BrowserRouter } from "react-router-dom";
import { UpoolContextProvider } from "./Providers/UpoolContext";
import InitApp from "./InitApp";
import {
  AdapterDateFns,
  LocalizationProviderMUI,
  esDateFNS,
} from "./DeltaUI/Components";
import { FirebaseProvider } from "./DeltaUI/Utils";
import { config, IS_WEB } from "./config";
import { AndroidProvider } from "./Providers/AndroidProvider";
import { NotificationsProvider } from "./Providers/NotificationsProvider";
import { ThemeAppProvider } from "./Providers/ThemeAppProvider";
import { OneSignalProvider } from "./Providers/OneSignalProvider";
import { EventAppProvider } from "./Providers/EventAppProvider";
import { DialogCustomProvider } from "./Components/DialogCustom";
import { AppContextProvider } from "./Providers/AppContext";
import { LoadApiGoogleMapsProvider } from "./DeltaUI/Components/GoogleMapsApiReact";
import { isIos } from "./DeltaUI/Utils/Constants";
import { ProgressiveWebAppProvider } from "./Providers/ProgressiveWebApp";
import { CustomApolloLibraryProvider } from "./Providers/CustomApolloLibrary";
import { ExceptionManagerLibrary } from "./Providers/ExceptionManagerLibrary";
import { CustomLoadingLibraryProvider } from "./Providers/CustomLoadingLibrary";

export const goStore = () => {
  if (isIos) {
    window.open(config.linkStore.ios, "_blank");
  } else {
    window.open(config.linkStore.android, "_blank");
  }
};

const UpoolApp: React.FC = () => {
  return (
    <CustomLoadingLibraryProvider>
      <IonApp>
        <BrowserRouter>
          <EventAppProvider>
            <UpoolContextProvider>
              <CustomApolloLibraryProvider>
                <ExceptionManagerLibrary>
                  <AndroidProvider>
                    <LocalizationProviderMUI
                      dateAdapter={AdapterDateFns}
                      locale={esDateFNS}
                    >
                      <OneSignalProvider>
                        <NotificationsProvider>
                          <ThemeAppProvider>
                            <DialogCustomProvider>
                              <InitApp />
                            </DialogCustomProvider>
                          </ThemeAppProvider>
                        </NotificationsProvider>
                      </OneSignalProvider>
                    </LocalizationProviderMUI>
                  </AndroidProvider>
                </ExceptionManagerLibrary>
              </CustomApolloLibraryProvider>
            </UpoolContextProvider>
          </EventAppProvider>
        </BrowserRouter>
      </IonApp>
    </CustomLoadingLibraryProvider>
  );
};

const App: React.FC = () => {
  if (config.env.credentials.firebaseConfig && !IS_WEB) {
    return (
      <FirebaseProvider firebaseConfig={config.env.credentials.firebaseConfig}>
        <LoadApiGoogleMapsProvider
          googleMapsApiKey={config.env.credentials.apiKeyGoogleMaps}
        >
          <AppContextProvider>
            <UpoolApp />
          </AppContextProvider>
        </LoadApiGoogleMapsProvider>
      </FirebaseProvider>
    );
  }
  return (
    <AppContextProvider>
      <ProgressiveWebAppProvider register={IS_WEB}>
        <UpoolApp />
      </ProgressiveWebAppProvider>
    </AppContextProvider>
  );
};

export default App;
