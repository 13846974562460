import React from "react";
import { Separator, TypographyMUI, BoxMUI } from "../../DeltaUI/Components";
import {
  RoomIcon,
  OriginIcon,
  MoreIcon,
} from "../../DeltaUI/Components/CustomIcon";
import { UpoolContext } from "../../Providers/UpoolContext";
import { IPlace } from "../../Services/PlaceService";
import { MomentJS } from "../../Utils";
import {
  COLOR_SECONDARY,
  COLOR_TERTIARY,
  COLOR_QUINARY,
} from "../../Utils/Constants";
import { MyTripsContext } from "../MyTrips/MyTripsContext";
import TagRemis from "../TagRemis";
import ListItemTextDescriptionPlace from "../Trip/PlaceDescription";
import { CardTripDescriptionStatusTrip } from "./CardTripDescriptionStatusTrip";

const distanceMtsDescription = (distanceMts: number) => {
  if (distanceMts < 1000) {
    return `${distanceMts} mts`;
  } else if (distanceMts < 10000) {
    return `${Number.parseFloat(`${distanceMts / 1000}`).toFixed(1)} km`;
  } else {
    return `${Number.parseFloat(`${distanceMts / 1000}`).toFixed(0)} km`;
  }
};
export interface CardDetailTripDataProps {
  placeFrom?: IPlace | google.maps.places.PlaceResult;
  placeTo?: IPlace | google.maps.places.PlaceResult;
  distanceMtsFrom?: number;
  distanceMtsTo?: number;
  dateFrom?: Date;
  totalPlaces?: number | string;
  amount?: number;
  typeId?: number;
  onClick?: () => void;
  disabled?: boolean;
  share?: boolean;
  search?: boolean;
}

const CardDetailTripData: React.FC<CardDetailTripDataProps> = (props) => {
  const {
    placeFrom,
    placeTo,
    distanceMtsFrom,
    distanceMtsTo,
    dateFrom,
    totalPlaces,
    amount,
    typeId,
    onClick,
    disabled,
    share,
    search,
  } = props;
  const { getDescriptionDate2, getTime } = MomentJS();
  const { detailTripMode, selectTripStretch } = React.useContext(
    MyTripsContext
  );
  const { selectPathTab, pathname } = React.useContext(UpoolContext);

  const isDetailTripPage = React.useMemo(() => {
    return pathname === `${selectPathTab}/detail-trip`;
  }, [pathname, selectPathTab]);

  const distanceMtsFromDescription = React.useMemo(() => {
    if (typeof distanceMtsFrom === "number") {
      return distanceMtsFrom
        ? ` - a ${distanceMtsDescription(distanceMtsFrom)} de tu origen`
        : " - en tu mismo punto de partida";
    }
    return "";
  }, [distanceMtsFrom]);

  const distanceMtsToDescription = React.useMemo(() => {
    if (typeof distanceMtsTo === "number") {
      return distanceMtsTo
        ? ` - a ${distanceMtsDescription(distanceMtsTo)} de tu destino`
        : " - en tu mismo punto de llegada";
    }
    return "";
  }, [distanceMtsTo]);

  return (
    <BoxMUI onClick={!(disabled || share) ? onClick : undefined}>
      <TagRemis typeId={typeId} />
      <Separator px={8} />
      <BoxMUI
        style={{
          position: "relative",
          width: "calc(100vw - 48px)",
          height: "112px",
          display: "flex",
          flexDirection: "column",
          justifyContent: !search ? "end" : "start",
        }}
      >
        <BoxMUI
          style={{
            display: "flex",
            padding: "0 24px",
          }}
        >
          <BoxMUI
            style={{
              width: "24px",
              height: "48px",
              marginLeft: "-4px",
            }}
          >
            <OriginIcon style={{ color: COLOR_TERTIARY }} />
          </BoxMUI>
          <Separator px={8} orientation="vertical" />
          <BoxMUI style={{ display: "flex", flexDirection: "column" }}>
            <ListItemTextDescriptionPlace
              place={placeFrom}
              distanceMts={distanceMtsFromDescription}
            />
          </BoxMUI>
        </BoxMUI>
        <BoxMUI
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            marginTop: "-24px",
            padding: "4px 20px",
          }}
        >
          <MoreIcon style={{ color: COLOR_QUINARY }} />
        </BoxMUI>
        <BoxMUI
          style={{
            display: "flex",
            padding: "0 24px",
          }}
        >
          <BoxMUI
            style={{
              width: "24px",
              height: "48px",
              marginLeft: "-4px",
            }}
          >
            <RoomIcon style={{ color: COLOR_SECONDARY }} />
          </BoxMUI>
          <Separator px={8} orientation="vertical" />
          <BoxMUI style={{ display: "flex", flexDirection: "column" }}>
            <ListItemTextDescriptionPlace
              place={placeTo}
              distanceMts={distanceMtsToDescription}
            />
          </BoxMUI>
        </BoxMUI>
      </BoxMUI>
      <Separator px={4} divider />
      <BoxMUI
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "8px 24px",
          height: "60px",
        }}
      >
        {dateFrom ? (
          <>
            <BoxMUI>
              <BoxMUI>
                <TypographyMUI
                  color={COLOR_QUINARY}
                  className="TypographyRegular10pt"
                >
                  Fecha
                </TypographyMUI>
              </BoxMUI>
              <BoxMUI>
                <TypographyMUI className="TypographyBold11pt">
                  {getDescriptionDate2(dateFrom)}
                </TypographyMUI>
              </BoxMUI>
            </BoxMUI>
            <Separator px={8} orientation="vertical" />
          </>
        ) : null}
        {dateFrom ? (
          <>
            <BoxMUI>
              <BoxMUI>
                <TypographyMUI
                  color={COLOR_QUINARY}
                  className="TypographyRegular10pt"
                >
                  Hora
                </TypographyMUI>
              </BoxMUI>
              <BoxMUI>
                <TypographyMUI className="TypographyBold11pt">
                  {getTime(dateFrom)}
                </TypographyMUI>
              </BoxMUI>
            </BoxMUI>
            <Separator px={8} orientation="vertical" />
          </>
        ) : null}
        {totalPlaces ? (
          <>
            <BoxMUI>
              <BoxMUI>
                <TypographyMUI
                  color={COLOR_QUINARY}
                  className="TypographyRegular10pt"
                >
                  Lugares
                </TypographyMUI>
              </BoxMUI>
              <BoxMUI>
                <TypographyMUI className="TypographyBold11pt">
                  {totalPlaces}
                </TypographyMUI>
              </BoxMUI>
            </BoxMUI>
            <Separator px={8} orientation="vertical" />
          </>
        ) : null}

        {amount ? (
          <BoxMUI>
            <BoxMUI>
              <TypographyMUI
                color={COLOR_QUINARY}
                className="TypographyRegular10pt"
              >
                Precio
              </TypographyMUI>
            </BoxMUI>
            <BoxMUI>
              <TypographyMUI className="TypographyBold11pt">
                ${amount}
              </TypographyMUI>
            </BoxMUI>
          </BoxMUI>
        ) : null}
      </BoxMUI>
      {detailTripMode && selectTripStretch && isDetailTripPage && !share ? (
        <>
          <Separator px={4} divider />
          <CardTripDescriptionStatusTrip
            tripStretch={selectTripStretch}
            isCard={false}
          />
        </>
      ) : null}
    </BoxMUI>
  );
};

export default CardDetailTripData;
