import React from "react";
import {
  ToolbarMUI,
  MenuButton,
  BodyPage,
  Separator,
  TypographyMUI,
} from "../DeltaUI/Components";
import { UpoolContext } from "../Providers/UpoolContext";
import { TitlePage } from "../Components/TitlePage";
import { Virtuoso } from "react-virtuoso";
import LoadData, { LoadDataSpin } from "../Components/LoadData";
import { CardNotification } from "../Components/Card/CardNotification";
import {
  INotification,
  NotificationService,
} from "../Services/NotificationService";
import { MomentJS, useComponentMount, useCustomLog } from "../Utils";
import { CardNotificationNews } from "../Components/Card/CardNotificationNews";
import {
  COLOR_PRIMARY,
  COLOR_TERTIARY,
  COLOR_QUINARY,
  AVAILABLE_SCREEN_SPACE_CSS,
} from "../Utils/Constants";
import { CustomPage, DialogCustomHook } from "../Components";
import { MyTripsContext } from "../Components/MyTrips/MyTripsContext";

const NotificationsPageContent: React.FC = () => {
  const isMountedComponent = useComponentMount("NotificationsPageContent");
  const {
    getNotifications,
    markAsReadedManyNotifications,
  } = NotificationService();
  const {
    notificationGeneral,
    notificationsIsNotReadedIds,
    setNotificationGeneral,
    isActiveApp,
  } = React.useContext(UpoolContext);
  const { handleOnNotification } = React.useContext(MyTripsContext);
  const { messageError } = DialogCustomHook();
  const Log = useCustomLog();
  const { createMoment, getDate, getDescriptionDate3, addToDate } = MomentJS();
  const [
    loadingNotifications,
    setLoadingNotifications,
  ] = React.useState<boolean>(false);
  const [data, setData] = React.useState<INotification[]>([]);
  const [initialCount, setInitialCount] = React.useState<number>(
    notificationGeneral.length
  );

  const getData = (paginationId?: number) => {
    markAsReadedManyNotifications(notificationsIsNotReadedIds)
      .then(() => {
        setNotificationGeneral((l) => {
          const nl = l.map((n) => {
            if (notificationsIsNotReadedIds.find((id) => id === n.id)) {
              n.readedAt = new Date();
              return n;
            }
            return n;
          });
          return nl;
        });
      })
      .catch(({ message }) => {
        Log.error({
          context: "NotificationsPageContent.getData.1",
          message,
        });
      });
    setLoadingNotifications(() => true);
    getNotifications(paginationId)
      .then((res) => {
        if (isMountedComponent.current && res && res.length) {
          setData((d) => [...d, ...res]);
        }
      })
      .catch(({ message }) => {
        messageError({
          context: "NotificationsPageContent.getData.2",
          message: message,
        });
      })
      .finally(() => setLoadingNotifications(() => false));
  };

  const today = React.useRef<Date>(
    createMoment({
      date: getDate(new Date()),
      originFormat: "DD/MM/YYYY",
    }).toDate()
  );

  const yesterday = React.useRef<Date>(
    createMoment({
      date: getDate(addToDate(-1, "d", new Date()).toDate()),
      originFormat: "DD/MM/YYYY",
    }).toDate()
  );

  const getDividerDate = (index: number) => {
    const prevAt =
      index > 0
        ? createMoment({
            date: getDate(data[index - 1].sendedAt),
            originFormat: "DD/MM/YYYY",
          }).toDate()
        : today.current;
    const nextAt = createMoment({
      date: getDate(data[index].sendedAt),
      originFormat: "DD/MM/YYYY",
    }).toDate();

    return (
      <>
        {nextAt < prevAt ? (
          <>
            {nextAt < yesterday.current ? (
              <>
                <Separator px={32} />
                <div style={{ width: "100%", textAlign: "center" }}>
                  <TypographyMUI
                    className="TypographyRegular10pt"
                    style={{ marginBottom: "4px", marginLeft: "4px" }}
                    color={COLOR_QUINARY}
                  >
                    {getDescriptionDate3(nextAt)}
                  </TypographyMUI>
                </div>
                <Separator px={0} divider />
              </>
            ) : (
              <>
                <Separator px={32} />
                <div style={{ width: "100%", textAlign: "center" }}>
                  <TypographyMUI
                    className="TypographyRegular10pt"
                    style={{ marginBottom: "4px", marginLeft: "4px" }}
                    color={COLOR_QUINARY}
                  >
                    Ayer
                  </TypographyMUI>
                </div>
                <Separator px={0} divider />
              </>
            )}
          </>
        ) : (
          <>
            {index === 0 ? (
              <>
                <Separator px={32} />
                <div style={{ width: "100%", textAlign: "center" }}>
                  <TypographyMUI
                    className="TypographyRegular10pt"
                    style={{ marginBottom: "4px", marginLeft: "4px" }}
                    color={COLOR_QUINARY}
                  >
                    Hoy
                  </TypographyMUI>
                </div>
                <Separator px={0} divider />
              </>
            ) : null}
          </>
        )}
      </>
    );
  };

  React.useEffect(() => {
    if (isMountedComponent.current) {
      setInitialCount(() => notificationGeneral.length);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  React.useEffect(() => {
    if (isActiveApp) {
      setData(() => []);
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActiveApp]);

  React.useEffect(() => {
    isMountedComponent.current = true;
    return () => {
      isMountedComponent.current = false;
    };
  }, [isMountedComponent]);

  return (
    <CustomPage
      headerPage={
        <ToolbarMUI>
          <MenuButton style={{ color: COLOR_PRIMARY }} />
          <TitlePage text="Notificaciones" />
        </ToolbarMUI>
      }
    >
      <BodyPage>
        <Virtuoso
          style={{
            height: `calc(${AVAILABLE_SCREEN_SPACE_CSS} - 56px - 48px)`,
          }}
          data={data}
          endReached={() =>
            getData(data.length ? data[data.length - 1].id : undefined)
          }
          overscan={200}
          itemContent={(index, n: INotification) => {
            return (
              <>
                {getDividerDate(index)}
                <CardNotification
                  notification={n}
                  onClick={() => handleOnNotification(n)}
                />
              </>
            );
          }}
          components={{
            Header: () => (
              <CardNotificationNews
                initialCount={initialCount}
                backgroundColor={`${COLOR_TERTIARY}40`}
                onClick={() => {
                  setData(() => []);
                  getData();
                }}
              />
            ),
            Footer: () => {
              return (
                <>
                  {data.length && loadingNotifications ? (
                    <LoadDataSpin />
                  ) : (
                    <LoadData
                      description="No hay notificaciones"
                      empty={!data.length}
                      loading={loadingNotifications}
                      height="45vh"
                    >
                      <Separator px={16} />
                    </LoadData>
                  )}
                </>
              );
            },
          }}
        />
      </BodyPage>
    </CustomPage>
  );
};

const NotificationsPage: React.FC<{}> = () => {
  return <NotificationsPageContent />;
};

export default NotificationsPage;
