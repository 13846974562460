import React from "react";
import { Imgs } from "../../Assets";
import { CardTrip } from "../../Components";
import { LoadDataSpin } from "../../Components/LoadData";
import { MyTripsProvider } from "../../Components/MyTrips/MyTripsContext";
import {
  BoxMUI,
  ButtonMUI,
  Separator,
  TypographyMUI,
} from "../../DeltaUI/Components";
import { UpoolContext } from "../../Providers/UpoolContext";
import { useComponentMount, useCustomLog } from "../../Utils";
import { DETAIL_TRIP_MODE, URL } from "../../Utils/Constants";
import { FooterShare } from "../../Components/Share/FooterShare";
import { PublicContext } from "../PublicContext";
import { HeaderShare } from "../../Components/Share/HeaderShare";
import { Share } from "@capacitor/share";
import { Clipboard } from "@capacitor/clipboard";
import { ShareSearch } from "../../Components/Share/ShareSearch";
import {
  SearchTripContext,
  SearchTripProvider,
} from "../../Components/SearchTrip/SearchTripContext";

const TAKE = 3;

interface SearchPublicPageProps {}

const SearchPublicComponent: React.FC<SearchPublicPageProps> = () => {
  const isMountedComponent = useComponentMount("SearchPublicComponent");

  const Log = useCustomLog();
  const { setLoading } = React.useContext(UpoolContext);
  const { params } = React.useContext(PublicContext);
  const {
    data,
    loadingSearchTripResults,
    setLoadingSearchTripResults,
    originPlace,
    destinationPlace,
  } = React.useContext(SearchTripContext);
  const [length, setLenght] = React.useState<number>();

  const share = async () => {
    if (!data.length) {
      try {
        setLoading(() => true);
        await Clipboard.write({
          string: URL.UPOOL,
        });
        await Share.share({
          text: "Mi viaje",
          url: URL.UPOOL,
          dialogTitle: "Compartir",
        }).catch((err) => {
          Log.error({
            context: "SearchPublicComponent.share.1",
            message: err.message,
          });
        });
      } catch (err: any) {
        Log.error({
          context: "SearchPublicComponent.share.2",
          message: err.message,
        });
      } finally {
        setLoading(() => false);
      }
    } else {
      setLoading(() => true);
      setLenght(() => data.length);
    }
  };

  const go = () => {
    window.open(`upool.app://go${params}`, "_system");
  };

  React.useEffect(() => {
    isMountedComponent.current = true;
    if (!data.length) {
      setLoadingSearchTripResults(() => true);
    }
    return () => {
      isMountedComponent.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMountedComponent]);

  return (
    <BoxMUI
      style={{
        width: "100vw",
        height: "100vh",
        overflowY: "scroll",
        backgroundImage: `url(${Imgs.upoolSplashScreen})`,
      }}
      className="BackgroundImage"
    >
      <HeaderShare height={580} />
      {loadingSearchTripResults ? (
        <BoxMUI
          sx={{
            "& .MuiCircularProgress-root": {
              color: "white",
            },
          }}
        >
          <LoadDataSpin />
          <Separator px={32} />
        </BoxMUI>
      ) : null}
      {!loadingSearchTripResults && !data.length ? (
        <BoxMUI
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <BoxMUI
            style={{
              width: "100%",
              maxWidth: "350px",
              padding: "0 24px",
              textAlign: "center",
            }}
          >
            <BoxMUI
              className="FlexRow FlexCenter"
              style={{
                padding: "24px",
                border: "2px dashed #bdbdbd",
                borderRadius: "10px",
              }}
            >
              <TypographyMUI
                className="TypographyBold16pt"
                color="white"
                style={{
                  textAlign: "center",
                }}
              >
                Esta busqueda no tiene viajes disponibles
              </TypographyMUI>
            </BoxMUI>
            <Separator px={32} />
          </BoxMUI>
        </BoxMUI>
      ) : null}
      {!loadingSearchTripResults && data.length ? (
        <BoxMUI
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {data.length && data.length > 1 ? (
            <>
              {data.length > TAKE ? (
                <TypographyMUI
                  className="TypographyBold16pt"
                  color="white"
                  style={{
                    textAlign: "center",
                  }}
                >
                  + {TAKE} viajes disponibles
                </TypographyMUI>
              ) : (
                <TypographyMUI
                  className="TypographyBold16pt"
                  color="white"
                  style={{
                    textAlign: "center",
                  }}
                >
                  {data.length} viajes disponibles
                </TypographyMUI>
              )}
            </>
          ) : (
            <TypographyMUI
              className="TypographyBold16pt"
              color="white"
              style={{
                textAlign: "center",
              }}
            >
              Viaje disponible
            </TypographyMUI>
          )}
          <Separator px={16} />
          <div className="ContentListPublicWeb">
            {data.length
              ? data
                  .filter((_, index) => index < TAKE)
                  .map((item) => (
                    <div
                      key={item.tripStretch.id}
                      className="CardListPublicWeb"
                    >
                      <CardTrip
                        style={{
                          position: "relative",
                          maxWidth: "350px",
                        }}
                        tripStretchInitial={item.tripStretch}
                        disabled={true}
                      />
                      <BoxMUI className="CardListSeparatorPublicWeb" />
                    </div>
                  ))
              : null}
          </div>
          <Separator px={24} />
        </BoxMUI>
      ) : null}

      <BoxMUI
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          background: "#ffffff",
        }}
      >
        <BoxMUI
          style={{
            width: "100%",
            maxWidth: "350px",
            padding: "0 24px",
            textAlign: "center",
          }}
        >
          <Separator px={32} />
          <TypographyMUI className="TypographyRegular11pt">
            Si ya tenés la app, abrí esta búsqueda
          </TypographyMUI>
          <ButtonMUI
            style={{
              width: "200px",
              marginTop: "16px",
            }}
            variant="contained"
            color="primary"
            onClick={go}
          >
            Abrir uPool
          </ButtonMUI>
          <Separator px={8} />
        </BoxMUI>
      </BoxMUI>
      <FooterShare onShare={share} />
      {length ? (
        <ShareSearch
          length={length}
          originPlace={originPlace}
          destinationPlace={destinationPlace}
          params={params}
          onFinish={() => setLenght(() => undefined)}
        />
      ) : null}
    </BoxMUI>
  );
};

const SearchPublicPage: React.FC = () => {
  return (
    <MyTripsProvider detailTripMode={DETAIL_TRIP_MODE.WEB}>
      <SearchTripProvider>
        <SearchPublicComponent />
      </SearchTripProvider>
    </MyTripsProvider>
  );
};

export default SearchPublicPage;
