import { useCustomLog } from "../Utils";
import { useCustomApolloAsync } from "./GraphQL/ApolloService";
import { Query } from "./GraphQL/Query";

export const SettingService = () => {
  const { queryAsync } = useCustomApolloAsync();
  const Log = useCustomLog();

  const getAppVersion = () => {
    return queryAsync({
      query: Query.settings,
      variables: {
        where: { configKey: "APP_VERSION" },
      },
    })
      .then((res: ISetting[]) => {
        return res.find((s) => s.configKey === "APP_VERSION");
      })
      .catch(({ message }) => {
        Log.error({
          context: "SettingService.getAppVersion.1",
          message,
        });
        return undefined;
      });
  };

  return {
    getAppVersion,
  };
};

export interface ISetting {
  id: number;
  name: string;
  description?: string;
  configCategory: string;
  configKey: string;
  configValue: string;
}
