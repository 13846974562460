import React from "react";
import { UpoolContext } from "../../Providers/UpoolContext";
import {
  ButtonMUI,
  BoxMUI,
  Separator,
  TypographyMUI,
} from "../../DeltaUI/Components";
import { UserService } from "../../Services/UserService";
import { DialogCustomHook } from "..";
import { useComponentMount } from "../../Utils";

interface EndProps {}

export const End: React.FC<EndProps> = () => {
  const isMountedComponent = useComponentMount("End");
  const { deleteOneUser } = UserService();
  const { messageError, message } = DialogCustomHook();
  const { setLoading } = React.useContext(UpoolContext);

  const handleDeleteAccount = () => {
    setLoading(() => true);
    deleteOneUser()
      .then(() => {
        message({
          context: "End.handleDeleteAccount.1",
          title: "¡Listo!",
          message: "Tu cuenta se eliminó correctamente.",
        });
      })
      .catch(({ message }) => {
        messageError({
          context: "End.handleDeleteAccount.2",
          message:
            "¡Ocurrió un problema al intentar eliminar la cuenta! Envianos un mensaje ingresando en Menú - Tu opinión, para que podamos ayudarte.",
          messageError: message,
        });
      })
      .finally(() => setLoading(() => false));
  };

  React.useEffect(() => {
    isMountedComponent.current = true;
    return () => {
      isMountedComponent.current = false;
    };
  }, [isMountedComponent]);

  return (
    <>
      <BoxMUI
        style={{
          width: "100%",
        }}
      >
        <Separator px={16} />
        <TypographyMUI className="TypographyRegular11pt">
          ¡Lamentamos que te vayas! Recuerda que podrás volver con nosotros en
          cualquier momento, registrando una nueva cuenta.
        </TypographyMUI>
      </BoxMUI>
      <BoxMUI
        style={{
          width: "100%",
        }}
      >
        <ButtonMUI
          style={{
            width: "100%",
            marginTop: "16px",
          }}
          variant="contained"
          color="error"
          onClick={handleDeleteAccount}
          disabled={false}
        >
          Eliminar
        </ButtonMUI>
        <Separator px={32} />
      </BoxMUI>
    </>
  );
};
