import React from "react";
import { BoxMUI, PaperMUI, Separator } from "../../DeltaUI/Components";
import {
  CloseIcon,
  OriginIcon,
  RoomIcon,
} from "../../DeltaUI/Components/CustomIcon";
import {
  GoogleMaps,
  InputAutocomplete,
} from "../../DeltaUI/Components/GoogleMapsApiReact";
import { CreateTripContext } from "./CreateTripContext";
import { CreateTripNextButton } from "./CreateTripNextButton";
import {
  COLOR_QUATERNARY,
  COLOR_PRIMARY,
  COLOR_SECONDARY,
  COLOR_TERTIARY,
  COLOR_QUINARY,
  AVAILABLE_SCREEN_SPACE_CSS,
} from "../../Utils/Constants";
import { useCustomLog } from "../../Utils";
import { MapRoute } from "../Trip/MapRoute";
import { AppContext } from "../../Providers/AppContext";

export interface CreateTripMapProps {
  onNext: () => void;
}

const CreateTripMapContent: React.FC<CreateTripMapProps> = (props) => {
  const { onNext } = props;
  const Log = useCustomLog();
  const { currentPosition } = React.useContext(AppContext);
  const {
    originPlace,
    setOriginPlace,
    destinationPlace,
    setDestinationPlace,
    placeFrom,
    placeTo,
    editing,
  } = React.useContext(CreateTripContext);

  return (
    <>
      <MapRoute
        currentPosition={currentPosition}
        originPlace={originPlace}
        destinationPlace={destinationPlace}
      />
      <BoxMUI
        style={{
          position: "absolute",
          top: 0,
          width: "100%",
          padding: "24px",
          zIndex: 1,
          background: "transparent",
        }}
      >
        <PaperMUI
          className="ContentInputCustom ShadowCard12"
          style={{
            height: "42px",
            border: "none",
            background: "white",
          }}
          elevation={7}
          sx={{
            "& .MuiInputBase-input, .MuiInput-input": {
              fontFamily: "'Open Sans Bold' !important",
            },
          }}
        >
          <InputAutocomplete
            id="CreateTripMapInputAutocompleteOrigen"
            iconLeft={<OriginIcon style={{ color: COLOR_TERTIARY }} />}
            textIconLeft={
              !originPlace ? (
                <span
                  style={{ margin: "0 12px", color: COLOR_QUATERNARY }}
                  className="TypographyBold11pt"
                >
                  Desde
                </span>
              ) : (
                <span style={{ margin: "0 12px", color: COLOR_QUINARY }}>
                  Desde
                </span>
              )
            }
            onChange={(ev) => {
              setOriginPlace(() => ev);
            }}
            initialValue={originPlace || placeFrom}
            iconClean={
              <CloseIcon
                style={{
                  fontSize: "20px",
                  color: COLOR_PRIMARY,
                }}
              />
            }
            onError={({ message }) =>
              Log.error({
                context: "CreateTripMapContent.InputAutocomplete.1",
                message,
              })
            }
          />
        </PaperMUI>
        <Separator px={8} />
        <PaperMUI
          className="ContentInputCustom ShadowCard12"
          style={{
            height: "42px",
            border: "none",
            background: "white",
          }}
          elevation={7}
          sx={{
            "& .MuiInputBase-input, .MuiInput-input": {
              fontFamily: "'Open Sans Bold' !important",
            },
          }}
        >
          <InputAutocomplete
            id="CreateTripMapInputAutocompleteDestination"
            iconLeft={<RoomIcon style={{ color: COLOR_SECONDARY }} />}
            textIconLeft={
              !destinationPlace ? (
                <span
                  style={{ margin: "0 12px", color: COLOR_QUATERNARY }}
                  className="TypographyBold11pt"
                >
                  Hasta
                </span>
              ) : (
                <span style={{ margin: "0 12px", color: COLOR_QUINARY }}>
                  Hasta
                </span>
              )
            }
            onChange={(ev) => {
              setDestinationPlace(() => ev);
            }}
            initialValue={destinationPlace || placeTo}
            iconClean={
              <CloseIcon
                style={{
                  fontSize: "20px",
                  color: COLOR_PRIMARY,
                }}
              />
            }
            onError={({ message }) =>
              Log.error({
                context: "CreateTripMapContent.InputAutocomplete.2",
                message,
              })
            }
          />
        </PaperMUI>
      </BoxMUI>
      {editing ? (
        <CreateTripNextButton
          className="Transparent"
          disabled={false}
          onClick={onNext}
          notViewCancel={true}
        />
      ) : (
        <CreateTripNextButton
          className="Transparent"
          disabled={!originPlace || !destinationPlace}
          onClick={onNext}
          notViewCancel={true}
        />
      )}
    </>
  );
};

export const CreateTripMap: React.FC<CreateTripMapProps> = (props) => {
  const Log = useCustomLog();
  return (
    <GoogleMaps
      onError={({ message }) => {
        Log.error({
          context: "CreateTripMap.GoogleMaps.1",
          message,
        });
      }}
      style={{
        width: "100vw",
        height: `calc(${AVAILABLE_SCREEN_SPACE_CSS} - 112px - 48px)`,
        position: "absolute",
      }}
    >
      <CreateTripMapContent {...props} />
    </GoogleMaps>
  );
};

export default CreateTripMap;
