import React from "react";
import { HeaderPage } from "../DeltaUI/Components";

export interface HeaderPageProps {
  style?: React.CSSProperties;
  className?: string;
  m?: number;
  color?: "inherit" | "primary" | "secondary" | "default" | "transparent";
  elevation?: number;
}

const HeaderPageCustom: React.FC<HeaderPageProps> = (props) => {
  return <HeaderPage {...props} />;
};

export default HeaderPageCustom;
