import React from "react";
import { TypographyMUI, Separator } from "../../DeltaUI/Components";
import {
  CardDetailTripData,
  CardDetailTripPreferences,
} from "../../Components";
import LoadData from "../../Components/LoadData";
import { ITripStretch } from "../../Services/TripService";
import DetailTripResume from "./DetailTripResume";
import { PREFERENCES } from "../../Utils/Constants";

interface ResumeTabProps {
  loading: boolean;
  tripStretch?: ITripStretch;
}

const ResumeTab: React.FC<ResumeTabProps> = (props) => {
  const { loading, tripStretch } = props;

  const places = React.useMemo(() => {
    if (tripStretch) {
      return tripStretch.isDriver
        ? `${tripStretch.usedPlaces}/${tripStretch.totalPlaces}`
        : `${tripStretch.availablePlaces}`;
    }
    return "";
  }, [tripStretch]);

  const preferences = React.useMemo(() => {
    return tripStretch
      ? tripStretch.trip.preferences.filter(
          (pUser) => pUser.preferenceId !== PREFERENCES.FREE_SEAT_MIDDLE
        )
      : [];
  }, [tripStretch]);

  return (
    <DetailTripResume
      dataTrip={
        <LoadData
          description="No hay datos"
          empty={!tripStretch}
          loading={loading}
          style={{ margin: "10vh auto", padding: "8px 0" }}
        >
          {tripStretch ? (
            <CardDetailTripData
              placeFrom={tripStretch.placeFrom}
              placeTo={tripStretch.placeTo}
              dateFrom={tripStretch.dateFrom}
              totalPlaces={places}
              amount={tripStretch.amount}
              typeId={tripStretch.trip.typeId}
            />
          ) : null}
        </LoadData>
      }
      vehicle={tripStretch?.trip?.vehicle}
      freeSeatMiddle={
        !!tripStretch?.trip.preferences.find(
          (pUser) => pUser.preferenceId === PREFERENCES.FREE_SEAT_MIDDLE
        )
      }
      preferences={
        <>
          {preferences.length ? (
            <>
              <TypographyMUI className="TypographyExtrabold11pt">
                Se permite
              </TypographyMUI>
              <Separator px={8} />
              <CardDetailTripPreferences
                className="ShadowCard8"
                preferencesList={preferences}
              />
            </>
          ) : null}
        </>
      }
      aditional={tripStretch?.trip.description}
    />
  );
};

export default ResumeTab;
