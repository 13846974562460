import CryptoJS from "crypto-js";
import { APP_AUTH } from "./Constants";

export const Crypto = () => {
  const encrypt = (text: string) => {
    return CryptoJS.enc.Base64.stringify(
      CryptoJS.enc.Utf8.parse(
        CryptoJS.Rabbit.encrypt(text, APP_AUTH.APP_KEY).toString()
      )
    );
  };

  const decrypt = (text: string) => {
    return CryptoJS.Rabbit.decrypt(
      CryptoJS.enc.Base64.parse(text).toString(CryptoJS.enc.Utf8),
      APP_AUTH.APP_KEY
    ).toString(CryptoJS.enc.Utf8);
  };

  return {
    encrypt,
    decrypt,
  };
};
