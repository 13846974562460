import React from "react";
import { Calendar as CalendarRDR, CalendarProps } from "react-date-range";
import { es } from "date-fns/locale";
import { format } from "date-fns";
import { BoxMUI, ListItemMUI, ListMUI, TypographyMUI } from "..";
import { MomentJS } from "../../Utils";

interface ICalendarProps extends CalendarProps {
  /** default: enUs from locale. Complete list here https://github.com/Adphorus/react-date-range/blob/next/src/locale/index.js */
  locale?: Locale;
  /** default: true */
  showMonthAndYearPickers?: boolean;
  color?: string;
}

const Calendar: React.FC<ICalendarProps> = (p) => {
  const props: any = p;
  return <CalendarRDR {...props} />;
};

export interface CalendarPickerProps {
  startDate: Date;
  minDate?: Date;
  maxDate?: Date;
  onChange?: (ev: Date) => void;
  color?: string;
  textColor?: string;
  label?: string;
  header?: boolean;
  showMonthAndYearPickers?: boolean;
}

const CalendarPicker: React.FC<CalendarPickerProps> = (props) => {
  const {
    startDate,
    minDate,
    maxDate,
    onChange,
    color,
    label,
    header,
    showMonthAndYearPickers,
  } = props;
  const { getDate, getTime, createMoment } = MomentJS();
  const [date, setDate] = React.useState(startDate || new Date());

  const COLOR = React.useMemo(() => color || "#3F5AA6", [color]);
  const styles: any = {
    root: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      "& .date": {
        marginTop: "4px",
        marginBottom: "4px",
        color: "#ffffff",
      },
      "& .label": {
        color: "#ffffff8a",
      },
    },
    header: {
      width: "100%",
      background: COLOR,
    },
    item: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "start",
      alignItems: "start",
      marginTop: "8px",
      marginBottom: "8px",
    },
    calendar: {
      "& .rdrDefinedRangesWrapper, .rdrDateDisplayWrapper": {
        display: "none",
      },
      "& .rdrDateDisplayWrapper ": {
        background: "white",
      },
      "& .rdrDayToday .rdrDayNumber span:after": {
        background: COLOR,
      },
      "& .rdrDayPassive": {
        "& .rdrDayNumber": {
          "& span": {
            color: "transparent",
          },
        },
      },
      "& .rdrMonthAndYearPickers, .rdrDayNumber, .rdrWeekDay": {
        fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
        fontWeight: 400,
        lineHeight: 1.5,
        textTransform: "none",
      },
      "& .rdrMonthAndYearPickers": {
        fontSize: "1rem",
      },
      "& .rdrDayNumber, .rdrWeekDay": {
        color: "black",
        fontSize: "0.875rem",
      },
      "& .rdrWeekDay": {
        color: "#00000061",
      },
      "& .rdrMonth": {
        width: "25em",
      },
    },
  };

  return (
    <BoxMUI sx={styles.root}>
      {!(header === false) ? (
        <div style={styles.header}>
          <ListMUI>
            <ListItemMUI style={styles.item}>
              <div>
                <TypographyMUI component="h5" className="label">
                  {label || "Fecha seleccionada"}
                </TypographyMUI>
              </div>
              <div>
                <TypographyMUI variant="h5" className="date">
                  {format(date, "d 'de' MMMM, yyyy", {
                    locale: es,
                  })}
                </TypographyMUI>
              </div>
            </ListItemMUI>
          </ListMUI>
        </div>
      ) : null}
      <BoxMUI sx={styles.calendar}>
        <Calendar
          locale={es}
          showMonthAndYearPickers={showMonthAndYearPickers || false}
          date={date}
          minDate={minDate}
          maxDate={maxDate}
          onChange={(ev: any) => {
            const d = createMoment({
              date: `${getDate(ev)} ${getTime(new Date())}`,
              originFormat: "DD/MM/YYYY HH:mm",
            }).toDate();
            setDate(d);
            if (onChange) {
              onChange(d);
            }
          }}
          color={COLOR}
        />
      </BoxMUI>
    </BoxMUI>
  );
};

export default CalendarPicker;
