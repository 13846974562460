import React from "react";
import {
  BoxMUI,
  CardMUI,
  Separator,
  TypographyMUI,
} from "../../DeltaUI/Components";
import { IUser } from "../../Services/UserService";

export interface CardUserStatisticProps {
  user: IUser;
  isDrive: boolean;
}

const CardUserStatistic: React.FC<CardUserStatisticProps> = (props) => {
  const { user, isDrive } = props;
  return (
    <CardMUI
      elevation={7}
      style={{
        padding: "16px",
      }}
      className="ShadowCard8"
    >
      {isDrive ? (
        <>
          <BoxMUI className="FlexRow JustifyBetween">
            <TypographyMUI style={{ marginTop: "4px" }}>
              Viajes realizados
            </TypographyMUI>
            <div style={{ width: "20%" }}>
              <TypographyMUI className="TypographyBold13pt">
                {user.tripsFinishedAsDriver}
              </TypographyMUI>
            </div>
          </BoxMUI>
          <Separator px={8} divider />
          <BoxMUI className="FlexRow JustifyBetween">
            <TypographyMUI style={{ marginTop: "4px" }}>
              Reservas finalizadas
            </TypographyMUI>
            <div style={{ width: "20%" }}>
              <TypographyMUI className="TypographyBold13pt">
                {user.reservationsFinishedAsDriver}
              </TypographyMUI>
            </div>
          </BoxMUI>
          <Separator px={8} divider />
          <BoxMUI className="FlexRow JustifyBetween">
            <TypographyMUI style={{ marginTop: "4px" }}>
              Reservas canceladas
            </TypographyMUI>
            <div style={{ width: "20%" }}>
              <TypographyMUI className="TypographyBold13pt">
                {user.reservationsCanceledAsDriver}
              </TypographyMUI>
            </div>
          </BoxMUI>
        </>
      ) : (
        <>
          <BoxMUI className="FlexRow JustifyBetween">
            <TypographyMUI style={{ marginTop: "4px" }}>
              Viajes realizados
            </TypographyMUI>
            <div style={{ width: "20%" }}>
              <TypographyMUI className="TypographyBold13pt">
                {user.reservationsFinishedAsPassenger}
              </TypographyMUI>
            </div>
          </BoxMUI>
          <Separator px={8} divider />
          <BoxMUI className="FlexRow JustifyBetween">
            <TypographyMUI style={{ marginTop: "4px" }}>
              Viajes cancelados
            </TypographyMUI>
            <div style={{ width: "20%" }}>
              <TypographyMUI className="TypographyBold13pt">
                {user.reservationsCanceledAsPassenger}
              </TypographyMUI>
            </div>
          </BoxMUI>
        </>
      )}
    </CardMUI>
  );
};

export default CardUserStatistic;
