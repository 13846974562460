import React from "react";
import { BoxMUI } from "..";

export interface BodyPageProps {
  style?: React.CSSProperties;
  className?: string;
  m?: number;
}

const BodyPage: React.FC<BodyPageProps> = (props) => {
  const { children, m, style, className } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
      }}
    >
      <BoxMUI m={m}>{children}</BoxMUI>
    </div>
  );
};

export default BodyPage;
