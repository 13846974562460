import React from "react";
import { Onboarding1 } from "./Onboarding1";
import { Onboarding2 } from "./Onboarding2";
import { Onboarding3 } from "./Onboarding3";
import { ActionSheet } from "../../DeltaUI/Components";
import { OnboardingButtonSkip } from "./OnboardingButtonSkip";
import { COLOR_PRIMARY } from "../../Utils/Constants";
import { Imgs } from "../../Assets";

export enum ONBOARDING_STEPS {
  ONBOARDING_1,
  ONBOARDING_2,
  ONBOARDING_3,
}

interface OnboardingProps {
  style?: React.CSSProperties;
  className?: string;
  open: boolean;
  onFinish?: () => void;
}

export const Onboarding: React.FC<OnboardingProps> = (props) => {
  const { style, className, open, onFinish } = props;
  const [step, setStep] = React.useState<number>(ONBOARDING_STEPS.ONBOARDING_1);

  React.useEffect(() => {
    if (open) {
      setStep(() => ONBOARDING_STEPS.ONBOARDING_1);
    }
  }, [open]);

  return (
    <ActionSheet
      open={open}
      style={{
        height: "100vh",
        ...style,
      }}
      className={className}
    >
      <OnboardingButtonSkip
        onClick={onFinish}
        style={{
          color:
            step === ONBOARDING_STEPS.ONBOARDING_2 ? "#fff" : COLOR_PRIMARY,
        }}
      />
      {step === ONBOARDING_STEPS.ONBOARDING_1 ? (
        <Onboarding1
          image={Imgs.onboarding1}
          onClick={() => setStep(() => ONBOARDING_STEPS.ONBOARDING_2)}
        />
      ) : null}
      {step === ONBOARDING_STEPS.ONBOARDING_2 ? (
        <Onboarding2
          image={Imgs.onboarding2}
          onClick={() => setStep(() => ONBOARDING_STEPS.ONBOARDING_3)}
        />
      ) : null}
      {step === ONBOARDING_STEPS.ONBOARDING_3 ? (
        <Onboarding3 image={Imgs.onboarding3} onClick={onFinish} />
      ) : null}
    </ActionSheet>
  );
};
