import React from "react";
import { AccessTimeIcon, CheckIcon } from "../../DeltaUI/Components/CustomIcon";

interface StatusMessageProps {
  style?: React.CSSProperties;
  className?: string;
  sending?: boolean;
  sent?: boolean;
  received?: boolean;
  seen?: boolean;
  color?: "primary" | "secondary";
  checkColor?: "primary" | "secondary";
}

export const StatusMessage: React.FC<StatusMessageProps> = (props) => {
  const { style, className, sending, sent, received, seen, color, checkColor } =
    props;

  return (
    <div
      style={{ width: "20px", position: "relative", ...style }}
      className={className}
    >
      {sending && !sent ? (
        <div style={{ position: "absolute", zIndex: 1 }}>
          <AccessTimeIcon
            sx={{
              fontSize: "12pt",
              color: (theme) =>
                theme.palette[checkColor || "primary"].contrastText,
            }}
          />
        </div>
      ) : null}
      {sent ? (
        <div style={{ position: "absolute", zIndex: 1 }}>
          <CheckIcon
            sx={{
              fontSize: "12pt",
              color: (theme) =>
                !seen
                  ? theme.palette[checkColor || "primary"].contrastText
                  : theme.palette[color || "primary"].main,
            }}
          />
        </div>
      ) : null}
      {received ? (
        <div style={{ position: "absolute", zIndex: 0, left: "5px" }}>
          <CheckIcon
            sx={{
              fontSize: "12pt",
              color: (theme) =>
                !seen
                  ? theme.palette[checkColor || "primary"].contrastText
                  : theme.palette[color || "primary"].main,
            }}
          />
        </div>
      ) : null}
    </div>
  );
};
