import React from "react";
import { Imgs } from "../Assets";
import {
  ActionSheet,
  BoxMUI,
  Separator,
  TypographyMUI,
} from "../DeltaUI/Components";
import { Loader3 } from "../DeltaUI/Components/Loader";
import { CustomPage } from "./CustomPage";

interface SplashScreenProps {
  style?: React.CSSProperties;
  className?: string;
  open: boolean;
  background?: string;
  textStatus?: string;
}

export const SplashScreen: React.FC<SplashScreenProps> = (props) => {
  const { style, className, open, background, textStatus } = props;

  return (
    <>
      <ActionSheet
        className="SplashScreen"
        open={open}
        style={{
          height: "100vh",
        }}
      >
        <CustomPage
          style={{
            background,
            ...style,
          }}
          className={`BackgroundImage ${className}`}
        >
          <BoxMUI
            style={{
              width: "100%",
              height: "100vh",
              position: "absolute",
              bottom: 0,
              left: 0,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-evenly",
            }}
          >
            <BoxMUI
              style={{
                width: "320px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                color: "white",
                textAlign: "center",
              }}
            >
              <BoxMUI
                style={{
                  width: "60vw",
                  maxWidth: "400px",
                }}
              >
                <img src={Imgs.logoWhite} alt="logo" width="100%" />
              </BoxMUI>
            </BoxMUI>
            <BoxMUI
              style={{
                textAlign: "center",
                color: "white",
                width: "320px",
              }}
            >
              <Loader3 />
              <Separator px={16} />
              <TypographyMUI
                className="TypographyRegular11pt"
                color="white"
                style={{
                  height: "20px",
                }}
              >
                {textStatus}
              </TypographyMUI>
            </BoxMUI>
          </BoxMUI>
        </CustomPage>
      </ActionSheet>
    </>
  );
};
