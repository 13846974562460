import React from "react";
import {
  ListItemMUI,
  ListItemIconMUI,
  ListItemTextMUI,
  TypographyMUI,
  CalendarPicker,
} from "../../DeltaUI/Components";
import { EventIcon, CloseIcon } from "../../DeltaUI/Components/CustomIcon";
import { SearchTripContext } from "./SearchTripContext";
import { MomentJS, useComponentMount } from "../../Utils";
import {
  COLOR_TERTIARY,
  COLOR_SECONDARY,
  COLOR_PRIMARY,
} from "../../Utils/Constants";
import { DialogCustom, DialogCustomFooterActions } from "../DialogCustom";

export interface SearchTripHomeCalendarProps {}

const SearchTripHomeCalendar: React.FC<SearchTripHomeCalendarProps> = (
  props
) => {
  const isMountedComponent = useComponentMount("SearchTripHomeCalendar");
  const { initialDate, setInitialDate, minDate, maxDate } = React.useContext(
    SearchTripContext
  );
  const { getDescriptionDate2 } = MomentJS();
  const [viewCalendar, setViewCalendar] = React.useState<boolean>(false);
  const [date, setDate] = React.useState<Date>(new Date());

  React.useEffect(() => {
    if (!initialDate) {
      setDate(() => new Date());
    }
  }, [initialDate]);

  React.useEffect(() => {
    isMountedComponent.current = true;
    return () => {
      isMountedComponent.current = false;
    };
  }, [isMountedComponent]);

  return (
    <>
      <ListItemMUI
        className="ContentInputCustom"
        style={{
          width: "48%",
          height: "42px",
          padding: "8px",
          paddingRight: "0",
        }}
      >
        <ListItemIconMUI
          style={{
            minWidth: "24px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "0 4px",
          }}
        >
          <EventIcon style={{ color: COLOR_TERTIARY }} />
        </ListItemIconMUI>
        <ListItemTextMUI
          style={{
            margin: "0",
          }}
          primary={
            <TypographyMUI
              className="TypographyBold11pt"
              style={{ paddingLeft: "8px" }}
            >
              {initialDate
                ? getDescriptionDate2(initialDate, true)
                : "Sin fecha"}
            </TypographyMUI>
          }
          onClick={() => setViewCalendar(() => true)}
        />
        {initialDate ? (
          <ListItemIconMUI
            style={{
              minWidth: "auto",
              padding: "3px 0",
              paddingRight: "4px",
            }}
            onClick={() => setInitialDate(() => undefined)}
          >
            <CloseIcon
              style={{
                fontSize: "20px",
                color: COLOR_PRIMARY,
              }}
            />
          </ListItemIconMUI>
        ) : null}
      </ListItemMUI>
      <DialogCustom
        context="SearchTripHomeCalendar.1"
        open={viewCalendar}
        onClose={() => setViewCalendar(() => false)}
        body={
          <CalendarPicker
            startDate={date}
            minDate={minDate}
            maxDate={maxDate}
            onChange={(ev) => setDate(() => ev)}
            header={false}
            color={COLOR_SECONDARY}
          />
        }
        footer={
          <DialogCustomFooterActions
            onOk={() => {
              setInitialDate(() => date);
              setViewCalendar(() => false);
            }}
            onCancel={() => {
              setViewCalendar(() => false);
            }}
          />
        }
      />
    </>
  );
};

export default SearchTripHomeCalendar;
