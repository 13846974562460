import React from "react";
import { UpoolContext } from "./UpoolContext";
import {
  INotification,
  NotificationService,
  prepareNotification,
} from "../Services/NotificationService";
import { NOTIFICATION_TYPE } from "../Utils/Constants";
import { IS_WEB } from "../config";
import { useComponentMount, useCustomLog } from "../Utils";
import { Subscription } from "../Services/GraphQL/Subscription";
import { SubscriptionComponent } from "../DeltaUI/Library/apollo/hooks/useCustomApolloSubscription";

export interface NotificationsProviderProps {}

export const NotificationsProviderMobile: React.FC<NotificationsProviderProps> = ({
  children,
}) => {
  const isMountedComponent = useComponentMount("NotificationsProviderMobile");
  const Log = useCustomLog();
  const { getNotificationsNotAttended } = NotificationService();
  const { user, setNotificationGeneral, isActiveApp } = React.useContext(
    UpoolContext
  );

  const handleNotificationNew = (res: INotification) => {
    const notification = prepareNotification(res);

    /**
     * Si la notificación es de tipo mensaje debemos reemplazar la notificación vieja
     */
    if (
      [NOTIFICATION_TYPE.MESSENGER].some((type) => notification.type === type)
    ) {
      setNotificationGeneral((list) => {
        const search = list.find((n) => n.id === notification.id);
        return search
          ? [...list.filter((n) => n.id !== notification.id), notification]
          : [...list, notification];
      });
    } else {
      setNotificationGeneral((b) => [...b, notification]);
    }
  };

  React.useEffect(() => {
    if (user && isActiveApp) {
      getNotificationsNotAttended()
        .then((list) => setNotificationGeneral(() => list))
        .catch(({ message }) => {
          Log.error({
            context: "NotificationsProviderMobile.useEffect.1",
            message,
          });
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, isActiveApp]);

  React.useEffect(() => {
    isMountedComponent.current = true;
    return () => {
      isMountedComponent.current = false;
    };
  }, [isMountedComponent]);

  return (
    <>
      {user && isActiveApp ? (
        <SubscriptionComponent
          config={Subscription.notificationCreated}
          onNext={handleNotificationNew}
          onError={({ message }) => {
            Log.error({
              context: "NotificationsProviderMobile.SubscriptionComponent.1",
              message,
            });
          }}
        />
      ) : null}
      {children}
    </>
  );
};

export const NotificationsProvider: React.FC<NotificationsProviderProps> = (
  props
) => {
  if (IS_WEB) {
    return <>{props.children}</>;
  }
  return (
    <NotificationsProviderMobile {...props}>
      {props.children}
    </NotificationsProviderMobile>
  );
};
