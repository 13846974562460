import React from "react";
import { BoxMUI, ButtonMUI, Separator, TypographyMUI } from "../Components";

interface ExceptionManagerState {
  hasError: any;
}

export interface ExceptionManagerError {
  name: string;
  message: string;
  stack: any;
  context: string;
}

export interface ExceptionManagerProps {
  context: string;
  messageModal?: string;
  component?: JSX.Element;
  message?: string;
  backgroundImage?: string;
  logo?: JSX.Element;
  eslogan?: JSX.Element;
  onClick?: () => void;
  onError?: (err: ExceptionManagerError) => void;
}

class ExceptionManagerContent extends React.Component<ExceptionManagerProps> {
  state: ExceptionManagerState;

  constructor(props: any) {
    super(props);
    this.state = {
      hasError: null,
    };
  }

  static getDerivedStateFromError(error: any) {
    // Actualiza el estado para que el siguiente renderizado muestre la interfaz de repuesto
    return { hasError: error };
  }

  componentDidCatch(error: any, errorInfo: any) {
    try {
      if (this.props.messageModal) {
        alert(this.props.messageModal);
      }

      // También puedes registrar el error en un servicio de reporte de errores
      if (this.props.onError)
        this.props.onError({
          name: error.name,
          message: error.message,
          stack: errorInfo.componentStack,
          context: this.props.context,
        });
    } catch {
      // @TODO: No hago nada si falla
    }
  }

  retry() {
    this.setState({
      hasError: null,
    });
    if (this.props.onClick) {
      this.props.onClick();
    } else {
      window.location.replace("/");
    }
  }

  render() {
    const message = this.props.component ? (
      this.props.component
    ) : (
      <BoxMUI
        style={{
          width: "100vw",
          height: "100vh",
        }}
      >
        <BoxMUI
          className="BackgroundImage"
          style={{
            width: "100%",
            height: "100%",
            backgroundImage: `url(${this.props.backgroundImage})`,
          }}
        >
          <BoxMUI
            style={{
              width: "100%",
              height: "100vh",
              position: "absolute",
              bottom: 0,
              left: 0,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-evenly",
            }}
          >
            <BoxMUI
              style={{
                width: "320px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                color: "white",
                textAlign: "center",
              }}
            >
              <BoxMUI
                style={{
                  width: "40vw",
                  maxWidth: "150px",
                }}
              >
                {this.props.logo ? this.props.logo : null}
              </BoxMUI>
              <Separator px={8} />
              {this.props.eslogan || null}
            </BoxMUI>
            <BoxMUI
              style={{
                textAlign: "center",
                color: "white",
                width: "230px",
              }}
            >
              <TypographyMUI className="TypographyRegular11pt">
                {this.props.message ||
                  "Ocurrió un problema! Ya se informó al administrador."}
              </TypographyMUI>
            </BoxMUI>
            <ButtonMUI
              style={{ width: "50%", background: "#f5835eff" }}
              variant="contained"
              onClick={() => this.retry()}
            >
              Reintentar
            </ButtonMUI>
          </BoxMUI>
        </BoxMUI>
      </BoxMUI>
    );
    if (this.props.messageModal) {
      return <>{this.state.hasError ? null : this.props.children}</>;
    }
    return <>{this.state.hasError ? message : this.props.children}</>;
  }
}

export const ExceptionManager: React.FC<
  React.PropsWithChildren<ExceptionManagerProps>
> = (props) => {
  return (
    <ExceptionManagerContent {...props}>
      {props.children}
    </ExceptionManagerContent>
  );
};
