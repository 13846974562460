import React from "react";
import { ButtonMUI, FabMUI } from "..";
import { FacebookIcon } from "../CustomIcon";
export interface FacebookLoginButtonProps {
  onClick: () => void;
  style?: React.CSSProperties;
  text?: string;
  fab?: boolean;
}

const FacebookLoginButton: React.FC<FacebookLoginButtonProps> = (props) => {
  const { onClick, style, text, fab } = props;
  if (fab) {
    return (
      <FabMUI
        style={{ background: "white", color: "#3a589e", ...style }}
        onClick={onClick}
      >
        <FacebookIcon />
      </FabMUI>
    );
  }
  return (
    <ButtonMUI
      variant="contained"
      color="primary"
      size="small"
      style={style}
      sx={{
        width: "100%",
        margin: "8px 0",
        backgroundColor: "#3b5998",
        textTransform: "uppercase",
      }}
      startIcon={<FacebookIcon />}
      onClick={onClick}
    >
      {text || "Facebook"}
    </ButtonMUI>
  );
};

export default FacebookLoginButton;
