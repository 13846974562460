import React from "react";
import {
  ButtonMUI,
  BoxMUI,
  Separator,
  TypographyMUI,
} from "../../DeltaUI/Components";
import { UpoolContext } from "../../Providers/UpoolContext";
import { DialogCustomHook } from "../DialogCustom";
import {
  PasswordSettingContext,
  PASSWORD_SETTING_STEPS,
} from "./PasswordSettingContext";

interface InitProps {}

export const Init: React.FC<InitProps> = () => {
  const { setStep } = React.useContext(PasswordSettingContext);
  const { user } = React.useContext(UpoolContext);
  const { messageWarn } = DialogCustomHook();

  const next = () => {
    if (user?.email || user?.phone) {
      setStep(PASSWORD_SETTING_STEPS.RECOVERY_OPTIONS);
    } else {
      messageWarn({
        context: "Init.next.1",
        message:
          "Para actualizar tu contraseña debes tener un email o teléfono registrado.",
      });
    }
  };

  return (
    <>
      <BoxMUI
        style={{
          width: "100%",
        }}
      >
        <TypographyMUI
          className="TypographyExtrabold26pt"
          sx={{ lineHeight: "48px" }}
        >
          Actualizar contraseña
        </TypographyMUI>
        <Separator px={24} />
        <TypographyMUI className="TypographyRegular11pt">
          Por seguridad deberás validar tu identidad.
        </TypographyMUI>
      </BoxMUI>
      <BoxMUI
        style={{
          width: "100%",
        }}
      >
        <Separator px={16} />
        <ButtonMUI
          style={{
            width: "100%",
            marginTop: "16px",
          }}
          variant="contained"
          color="primary"
          onClick={next}
        >
          Continuar
        </ButtonMUI>
        <Separator px={32} />
      </BoxMUI>
    </>
  );
};
