import React from "react";
import { UpoolContext } from "../../Providers/UpoolContext";
import { SetterState } from "../../Services/DefaultTypes";

export enum DELETE_ACCOUNT_STEPS {
  INIT,
  RECOVERY_OPTIONS,
  ENTRE_CODE,
  END,
}

interface IDeleteAccount {
  step: number;
  setStep: SetterState<number>;
  valueType: "email" | "phone" | undefined;
  setValueType: SetterState<"email" | "phone" | undefined>;
  verificationToken: string | undefined;
  setVerificationToken: SetterState<string | undefined>;
  requiresVerification: boolean;
}

const context: IDeleteAccount = {
  step: DELETE_ACCOUNT_STEPS.INIT,
  setStep: () => {},
  valueType: undefined,
  setValueType: () => {},
  verificationToken: "",
  setVerificationToken: () => {},
  requiresVerification: false,
};

export const DeleteAccountContext = React.createContext(context);

export interface DeleteAccountProviderProps {}

export const DeleteAccountProvider: React.FC<DeleteAccountProviderProps> = (
  props
) => {
  const { user } = React.useContext(UpoolContext);
  const [step, setStep] = React.useState<number>(DELETE_ACCOUNT_STEPS.INIT);
  const [valueType, setValueType] = React.useState<
    "email" | "phone" | undefined
  >();
  const [verificationToken, setVerificationToken] = React.useState<
    string | undefined
  >();

  const requiresVerification = React.useMemo(() => {
    return !!user?.email || !!user?.phone;
  }, [user]);

  React.useEffect(() => {
    if (user && !valueType) {
      if (user?.email) {
        setValueType(() => "email");
      } else if (user?.phone) {
        setValueType(() => "phone");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DeleteAccountContext.Provider
      value={{
        step,
        setStep,
        valueType,
        setValueType,
        verificationToken,
        setVerificationToken,
        requiresVerification,
      }}
    >
      {props.children}
    </DeleteAccountContext.Provider>
  );
};
