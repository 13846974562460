import { IS_WEB } from "../config";

import personIcon from "./Icons/person_icon.svg";
import tripDestinationIcon from "./Icons/trip_destination_icon.svg";
import tripOriginIcon from "./Icons/trip_origin_icon.svg";

import homeCreateTrip from "./Imgs/home_create_trip.svg";
import homeSearchTrip from "./Imgs/home_search_trip.svg";
import logo from "./Imgs/logo.svg";
import logoPNG from "./Imgs/logo.png";
import logoWhite from "./Imgs/logo_white.svg";
import onboarding1 from "./Imgs/onboarding_1.svg";
import onboarding2 from "./Imgs/onboarding_2.svg";
import onboarding3 from "./Imgs/onboarding_3.svg";
import upoolSplashScreen from "./Imgs/upool_splash_screen.svg";
import tripCreated from "./Imgs/trip_created.svg";
import requestSent from "./Imgs/request_sent.svg";
import welcomeToMyTrips from "./Imgs/welcome_to_my_trips.svg";
import btnAppleStore from "./Imgs/btn_apple_store.png";
import btnGoogleStore from "./Imgs/btn_google_store.png";

export const Icons = {
  personIcon,
  tripDestinationIcon,
  tripOriginIcon,
};

const ImgsWeb = IS_WEB
  ? {
      btnAppleStore,
      btnGoogleStore,
    }
  : {
      btnAppleStore: "",
      btnGoogleStore: "",
    };

export const Imgs = {
  homeCreateTrip,
  homeSearchTrip,
  logo,
  logoPNG,
  logoWhite,
  onboarding1,
  onboarding2,
  onboarding3,
  upoolSplashScreen,
  tripCreated,
  requestSent,
  welcomeToMyTrips,
  ...ImgsWeb,
};
