import { TextFieldMUI } from "../../DeltaUI/Components";

export interface TextAreaProps {
  style?: React.CSSProperties;
  className?: string;
  placeholder?: string;
  value: string;
  onChange?: (value: string) => void;
  rows?: number;
  disabled?: boolean;
}

const TextArea: React.FC<TextAreaProps> = (props) => {
  const { style, className, value, onChange, rows, disabled, placeholder } =
    props;
  return (
    <TextFieldMUI
      className={className}
      style={style}
      sx={{
        width: "100%",
        margin: "8px 0",
        "& .MuiInputBase-multiline": {
          padding: "12px",
        },
      }}
      variant="standard"
      multiline
      placeholder={placeholder}
      onChange={(ev) => {
        if (onChange) {
          onChange(ev.target.value);
        }
      }}
      value={value}
      rows={rows || 5}
      disabled={disabled}
    />
  );
};

export default TextArea;
