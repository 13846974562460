import { gql } from "@apollo/client";

export const CREATE_ONE_SHARE = gql`
  mutation createOneShare($data: ShareCreateInput!) {
    createOneShare(data: $data) {
      id
      description
    }
  }
`;
