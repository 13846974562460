import React from "react";
import { TypographyMUI, SegmentIcon, BoxMUI } from "../../DeltaUI/Components";
import { UpoolContext } from "../../Providers/UpoolContext";
import { UserService } from "../../Services/UserService";
import {
  CarSeatIcon,
  SteeringWheelIcon,
} from "../../DeltaUI/Components/CustomIcon";
import { DialogCustomHook } from "../DialogCustom";
import { COLOR_QUINARY } from "../../Utils/Constants";

interface SegmentRoleTypeProps {
  onChange?: () => void;
  size?: "small" | "large" | "medium";
}

const SegmentRoleType: React.FC<SegmentRoleTypeProps> = (props) => {
  const { onChange, size } = props;
  const { changeRoleType } = UserService();
  const { messageError } = DialogCustomHook();
  const { user, setLoading, roleTypeDriver } = React.useContext(UpoolContext);

  return (
    <BoxMUI
      className="FlexRow AlignCenter JustifyEnd"
      style={{
        width: "130px",
      }}
    >
      <BoxMUI
        style={{
          marginRight: "4px",
          textAlign: "right",
        }}
      >
        <TypographyMUI className="TypographyRegular10pt" color={COLOR_QUINARY}>
          {roleTypeDriver ? "Conductor" : "Pasajero"}
        </TypographyMUI>
      </BoxMUI>
      <SegmentIcon
        size={size}
        defaultValue={roleTypeDriver ? 2 : 1}
        value={roleTypeDriver ? 2 : 1}
        onChange={(key) => {
          const role = roleTypeDriver ? 2 : 1;
          if (user && role !== key) {
            setLoading(() => true);
            changeRoleType()
              .catch(({ message }) => {
                messageError({
                  context: "SegmentRoleType.changeRoleType.1",
                  message: message,
                });
              })
              .finally(() => {
                setLoading(() => false);
                if (onChange) {
                  onChange();
                }
              });
          }
        }}
        list={[
          {
            key: 1,
            element: <CarSeatIcon />,
          },
          {
            key: 2,
            element: <SteeringWheelIcon />,
          },
        ]}
      />
    </BoxMUI>
  );
};

export default SegmentRoleType;
