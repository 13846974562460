import React from "react";
import { ToolbarMUI, BodyPage, BoxMUI } from "../../../DeltaUI/Components";
import { TitlePage } from "../../../Components/TitlePage";
import { CustomPage } from "../../../Components";
import { GoBack } from "../../../Components/GoBack";
import { AVAILABLE_SCREEN_SPACE_CSS } from "../../../Utils/Constants";
import {
  DeleteAccountContext,
  DeleteAccountProvider,
  DELETE_ACCOUNT_STEPS,
} from "../../../Components/DeleteAccount/DeleteAccountContext";
import { VerificationOptions } from "../../../Components/DeleteAccount/VerificationOptions";
import { EnterCode } from "../../../Components/DeleteAccount/EnterCode";
import { End } from "../../../Components/DeleteAccount/End";
import { Init } from "../../../Components/DeleteAccount/Init";

const DeleteAccountPageContext: React.FC = () => {
  const { step } = React.useContext(DeleteAccountContext);
  return (
    <CustomPage
      headerPageProps={{
        className: step === DELETE_ACCOUNT_STEPS.INIT ? "Transparent" : "",
      }}
      headerPage={
        <>
          {step === DELETE_ACCOUNT_STEPS.INIT ? (
            <ToolbarMUI>
              <GoBack />
              <TitlePage text="" />
            </ToolbarMUI>
          ) : (
            <ToolbarMUI>
              <GoBack />
              <TitlePage text="Eliminar cuenta" />
            </ToolbarMUI>
          )}
        </>
      }
    >
      <BodyPage m={3}>
        <BoxMUI
          style={{
            height: `calc(${AVAILABLE_SCREEN_SPACE_CSS} - 56px - 48px)`,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <BoxMUI
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {step === DELETE_ACCOUNT_STEPS.INIT ? <Init /> : null}
            {step === DELETE_ACCOUNT_STEPS.RECOVERY_OPTIONS ? (
              <VerificationOptions />
            ) : null}
            {step === DELETE_ACCOUNT_STEPS.ENTRE_CODE ? <EnterCode /> : null}
            {step === DELETE_ACCOUNT_STEPS.END ? <End /> : null}
          </BoxMUI>
        </BoxMUI>
      </BodyPage>
    </CustomPage>
  );
};

const DeleteAccountPage: React.FC<{}> = () => {
  return (
    <DeleteAccountProvider>
      <DeleteAccountPageContext />
    </DeleteAccountProvider>
  );
};

export default DeleteAccountPage;
