import React from "react";
import { Marker as MarkerRGM } from "@react-google-maps/api";

export interface MarkerProps {
  iconUrl: string;
  position:
    | google.maps.LatLng
    | {
        lat: number;
        lng: number;
      };
}

export const Marker: React.FC<MarkerProps> = (props) => {
  const isMountedComponent = React.useRef<boolean>(false);
  const { position, iconUrl } = props;
  const [key, setkey] = React.useState<string>();
  const [marker, setMarker] = React.useState<google.maps.Marker>();

  const lat: number | undefined = React.useMemo(() => {
    if (typeof position?.lat === "number") {
      return position?.lat;
    }
    return position?.lat();
  }, [position]);

  const lng: number | undefined = React.useMemo(() => {
    if (typeof position?.lat === "number") {
      return position?.lat;
    }
    return position?.lat();
  }, [position]);

  React.useEffect(() => {
    setkey(() => `${new Date().getTime()}${lat}${lng}`);
  }, [lat, lng]);

  React.useEffect(() => {
    isMountedComponent.current = true;
    return () => {
      isMountedComponent.current = false;
      if (marker) {
        marker.setMap(null);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {key ? (
        <MarkerRGM
          key={key}
          onLoad={(m) => {
            if (isMountedComponent.current) {
              setMarker(m);
            }
          }}
          position={position}
          options={{
            icon: {
              url: iconUrl,
              // Ancho y alto
              size: new window.google.maps.Size(40, 40),
              // El origen de esta imagen es
              origin: new window.google.maps.Point(0, 0),
              // El ancla de esta imagen es la base del asta de la bandera
              anchor: new window.google.maps.Point(20, 20),
            },
            animation: window.google.maps.Animation.DROP,
          }}
        />
      ) : null}
    </>
  );
};
