import React from "react";
import { GoBackButton } from "../DeltaUI/Components";
import { EventAppContext } from "../Providers/EventAppProvider";
import { UpoolContext } from "../Providers/UpoolContext";

interface GoBackProps {
  style?: React.CSSProperties;
  className?: string;
  onClick?: () => void;
  fab?: boolean;
}

export const GoBack: React.FC<GoBackProps> = (props) => {
  const { style, className, onClick, fab } = props;
  const { handleEventAndroidGoBackButton } = React.useContext(EventAppContext);
  const { setGoBackFunctionList, historyGoBack } = React.useContext(
    UpoolContext
  );

  const handleGoBack = () => {
    if (onClick) {
      onClick();
    } else {
      historyGoBack();
    }
  };

  React.useEffect(() => {
    setGoBackFunctionList((l) => [...l, handleGoBack]);
    return () => {
      setGoBackFunctionList((l) => {
        l.pop();
        return l;
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <GoBackButton
      style={style}
      className={className}
      onClick={() => handleEventAndroidGoBackButton()}
      fab={fab}
    />
  );
};
