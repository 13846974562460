import React from "react";
import { DialogCustomHook } from "..";
import {
  ButtonMUI,
  BoxMUI,
  Separator,
  TypographyMUI,
  CodeVerifyInput,
} from "../../DeltaUI/Components";
import { UpoolContext } from "../../Providers/UpoolContext";
import { UserService } from "../../Services/UserService";
import { useComponentMount } from "../../Utils";
import {
  PhoneSettingContext,
  PHONE_SETTING_STEPS,
} from "./PhoneSettingContext";

interface EnterCodeProps {}

export const EnterCode: React.FC<EnterCodeProps> = () => {
  const isMountedComponent = useComponentMount("EnterCode");

  const {
    sendPhoneVerificationCode,
    verifyPhoneCode,
    updateOneUser,
  } = UserService();
  const { messageError, messageWarn, message } = DialogCustomHook();
  const { setLoading, user } = React.useContext(UpoolContext);
  const { setStep, phone } = React.useContext(PhoneSettingContext);

  const [code, setCode] = React.useState<string>("");

  const handleConfirm = () => {
    if (code && phone) {
      setLoading(() => true);
      verifyPhoneCode(phone, code)
        .then((verificationToken) => {
          if (isMountedComponent.current) {
            if (!verificationToken) {
              setCode(() => "");
              messageWarn({
                context: "PhoneSetting.handleConfirm.1",
                message: "El código no es correcto.",
              });
            } else {
              updateOneUser({
                id: user?.id,
                phone,
                verificationToken,
              })
                .then(() => {
                  if (isMountedComponent.current) {
                    setStep(() => PHONE_SETTING_STEPS.INIT_PAGE);
                    message({
                      context: "PhoneSetting.handleConfirm.2",
                      title: "¡Listo!",
                      message: "Registraste correctamente tu nuevo teléfono.",
                    });
                  }
                })
                .catch(({ message }) => {
                  messageError({
                    context: "PhoneSetting.handleConfirm.3",
                    message: message,
                  });
                })
                .finally(() => setLoading(() => false));
            }
          }
        })
        .catch(({ message }) => {
          setCode(() => "");
          messageError({
            context: "PhoneSetting.handleConfirm.4",
            message,
          });
          setLoading(() => false);
        });
    } else {
      messageError({
        context: "PhoneSetting.handleConfirm.5",
        message: "No se encontró el teléfono del usuario.",
      });
    }
  };

  const handleInitVerify = () => {
    if (phone) {
      setLoading(() => true);
      sendPhoneVerificationCode(phone)
        .then((res) => {
          if (!res) {
            messageError({
              context: "PhoneSetting.handleInitVerify.1",
              message: "No se puede enviar el código.",
            });
          }
        })
        .catch(({ message }) => {
          messageError({
            context: "PhoneSetting.handleInitVerify.2",
            message,
          });
        })
        .finally(() => setLoading(() => false));
    } else {
      messageError({
        context: "PhoneSetting.handleInitVerify.3",
        message: "No se encontró el teléfono del usuario.",
      });
    }
  };

  const handleReVerify = () => {
    setCode(() => "");
    handleInitVerify();
  };

  React.useEffect(() => {
    handleInitVerify();
    isMountedComponent.current = true;
    return () => {
      isMountedComponent.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMountedComponent]);

  return (
    <>
      <BoxMUI
        style={{
          width: "100%",
        }}
      >
        <Separator px={8} />
        <TypographyMUI className="TypographyBold11pt">
          Revisá tu teléfono
        </TypographyMUI>
        <Separator px={16} />
        <TypographyMUI className="TypographyRegular11pt">
          Ingresa a continuación el código que te enviamos por WhatsApp para
          validar tu número de teléfono.
        </TypographyMUI>
        <Separator px={24} />
        <TypographyMUI variant="overline">Introducí tu código</TypographyMUI>
        <Separator px={8} />
        <CodeVerifyInput
          value={code}
          color="secondary"
          onChange={(v) => setCode(() => v)}
        />
      </BoxMUI>
      <BoxMUI
        style={{
          width: "100%",
        }}
      >
        <ButtonMUI
          style={{
            width: "100%",
            marginTop: "16px",
          }}
          variant="outlined"
          color="primary"
          onClick={handleReVerify}
        >
          Reenviar código
        </ButtonMUI>
        <Separator px={16} />
        <ButtonMUI
          style={{
            width: "100%",
            marginTop: "16px",
          }}
          variant="contained"
          color="primary"
          onClick={handleConfirm}
          disabled={code.length < 6}
        >
          Continuar
        </ButtonMUI>
        <Separator px={32} />
      </BoxMUI>
    </>
  );
};
