import React from "react";
import { UpoolContext } from "../../Providers/UpoolContext";
import { SetterState } from "../../Services/DefaultTypes";

export enum PASSWORD_SETTING_STEPS {
  INIT,
  RECOVERY_OPTIONS,
  ENTRE_CODE,
  NEW_PASSWORD,
}

interface IPasswordSetting {
  step: number;
  setStep: SetterState<number>;
  valueType: "email" | "phone" | undefined;
  setValueType: SetterState<"email" | "phone" | undefined>;
  verificationToken: string | undefined;
  setVerificationToken: SetterState<string | undefined>;
}

const context: IPasswordSetting = {
  step: PASSWORD_SETTING_STEPS.INIT,
  setStep: () => {},
  valueType: undefined,
  setValueType: () => {},
  verificationToken: "",
  setVerificationToken: () => {},
};

export const PasswordSettingContext = React.createContext(context);

export interface PasswordSettingProviderProps {}

export const PasswordSettingProvider: React.FC<PasswordSettingProviderProps> = (
  props
) => {
  const { user } = React.useContext(UpoolContext);
  const [step, setStep] = React.useState<number>(PASSWORD_SETTING_STEPS.INIT);
  const [valueType, setValueType] = React.useState<
    "email" | "phone" | undefined
  >();
  const [verificationToken, setVerificationToken] = React.useState<
    string | undefined
  >();

  React.useEffect(() => {
    if (user && !valueType) {
      if (user?.email) {
        setValueType(() => "email");
      } else if (user?.phone) {
        setValueType(() => "phone");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PasswordSettingContext.Provider
      value={{
        step,
        setStep,
        valueType,
        setValueType,
        verificationToken,
        setVerificationToken,
      }}
    >
      {props.children}
    </PasswordSettingContext.Provider>
  );
};
