import React from "react";
import { ToolbarMUI, BodyPage, RenderListAsync } from "../DeltaUI/Components";
import { UpoolContext } from "../Providers/UpoolContext";
import { TitlePage } from "../Components/TitlePage";
import CardReservationChat from "../Components/Card/CardReservationChat";
import { MyTripsContext } from "../Components/MyTrips/MyTripsContext";
import LoadData from "../Components/LoadData";
import { GoBack } from "../Components/GoBack";
import { CustomPage } from "../Components";

const ChatListPage: React.FC = () => {
  const { historyGoBack } = React.useContext(UpoolContext);
  const { selectTripStretchReservations } = React.useContext(MyTripsContext);

  const goBack = () => {
    historyGoBack();
  };

  return (
    <CustomPage
      headerPage={
        <ToolbarMUI>
          <GoBack onClick={goBack} />
          <TitlePage text="Chats" />
        </ToolbarMUI>
      }
    >
      <BodyPage m={3}>
        <LoadData
          description="Ningún chat aún"
          empty={!selectTripStretchReservations.length}
          loading={false}
          height="45vh"
        >
          <RenderListAsync
            list={selectTripStretchReservations.map((r) => (
              <CardReservationChat key={r.id} reservation={r} />
            ))}
          />
        </LoadData>
      </BodyPage>
    </CustomPage>
  );
};

export default ChatListPage;
