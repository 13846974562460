import React from "react";
import { BoxMUI, PaperMUI } from "..";

export interface FooterPageProps {
  style?: React.CSSProperties;
  className?: string;
  m?: number;
  elevation?: number;
}

const FooterPage: React.FC<FooterPageProps> = (props) => {
  const { children, m, style, className, elevation } = props;
  return (
    <PaperMUI
      elevation={typeof elevation === "number" ? elevation : 4}
      className={className}
      style={{
        borderRadius: 0,
        ...style,
      }}
    >
      <BoxMUI m={m}>{children}</BoxMUI>
    </PaperMUI>
  );
};

export default FooterPage;
