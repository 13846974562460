import React from "react";
import { PaperMUI, ButtonMUI } from "..";

interface ISegmentIconItem {
  key: number;
  element: JSX.Element;
  onClick?: (key: number) => void;
}
export interface SegmentIconProps {
  elevation?: number;
  size?: "small" | "medium" | "large";
  list: ISegmentIconItem[];
  onChange?: (key: number) => void;
  value?: number;
  defaultValue: number;
  width?: "auto";
  style?: React.CSSProperties;
  className?: string;
  color?:
    | "inherit"
    | "primary"
    | "secondary"
    | "success"
    | "error"
    | "info"
    | "warning";
  fixedMode?: boolean;
}

/**
 * Medidas antes y despues
 * 42 38 22 18 = 42 38 26 22
 * 36 32 20 16 = 36 32 24 20
 * 30 26 18 14 = 30 26 22 18
 * @param props
 * @returns
 */
const SegmentIcon: React.FC<SegmentIconProps> = (props) => {
  const {
    elevation,
    list,
    size,
    onChange,
    value,
    defaultValue,
    width,
    style,
    className,
    color,
    fixedMode,
  } = props;

  const widthIcon = React.useMemo(() => {
    switch (size) {
      case "large":
        return "26px";
      case "medium":
        return "24px";
      default:
        return "22px";
    }
  }, [size]);

  const padding = React.useMemo(() => {
    switch (size) {
      case "large":
        return "6px";
      case "medium":
        return "4px";
      default:
        return "2px";
    }
  }, [size]);

  const styles: any = {
    root: {
      width: "min-content",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "20px",
      padding: "2px",
      background: "#EBEEF7",
    },
    button: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "20px",
      padding: `${padding} 0`,
      "& span > span": {
        marginRight: "0",
      },
      "& svg": {
        width: widthIcon,
        height: widthIcon,
      },
    },
  };

  const [select, setSelect] = React.useState<number>(value || defaultValue);

  const minWidth = React.useMemo(() => {
    if (width !== "auto") {
      switch (size) {
        case "large":
          return "62px";
        case "medium":
          return "48px";
        default:
          return "36px";
      }
    }
    return width;
  }, [size, width]);

  const height = React.useMemo(() => {
    switch (size) {
      case "large":
        return "32px";
      case "medium":
        return "28px";
      default:
        return "24px";
    }
  }, [size]);

  const handleOnClick = (item: ISegmentIconItem) => {
    if (!fixedMode) {
      setSelect(() => item.key);
    }
  };

  React.useEffect(() => {
    if (value && select !== value) {
      setSelect(() => value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  React.useEffect(() => {
    if (select && list.length) {
      const btn = list.find((item) => item.key === select);
      if (btn && btn.onClick) {
        btn.onClick(select);
      }
      if (onChange) {
        onChange(select);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [select]);

  return (
    <PaperMUI
      elevation={elevation || 0}
      className={`${className}`}
      style={{ ...styles.root, ...style }}
    >
      {list.length
        ? list.map((item) => {
            if (select === item.key) {
              return (
                <ButtonMUI
                  style={{
                    minWidth,
                    height,
                    textTransform: "none",
                  }}
                  sx={{
                    ...styles.button,
                  }}
                  variant="contained"
                  color={color}
                  size={size ? size : "small"}
                  key={item.key}
                  onClick={() => handleOnClick(item)}
                >
                  {item.element}
                </ButtonMUI>
              );
            }
            return (
              <ButtonMUI
                style={{
                  color: "#828899",
                  minWidth,
                  height,
                  textTransform: "none",
                }}
                sx={{
                  ...styles.button,
                }}
                size={size ? size : "small"}
                key={item.key}
                onClick={() => handleOnClick(item)}
              >
                {item.element}
              </ButtonMUI>
            );
          })
        : null}
    </PaperMUI>
  );
};

export default SegmentIcon;
