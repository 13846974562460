import { GooglePlus } from "@ionic-native/google-plus";
import { Facebook, FacebookLoginResponse } from "@ionic-native/facebook";

export interface IAuthGoogle {
  accessToken: string;
  displayName: string;
  email: string;
  expires: number;
  expires_in: number;
  familyName: string;
  givenName: string;
  idToken: string;
  imageUrl: string;
  userId: string;
}

export const Authentication = () => {
  const authGoogle = async (webClientId: string): Promise<IAuthGoogle> => {
    return GooglePlus.login({
      webClientId,
    });
  };

  const authFacebook = async (): Promise<FacebookLoginResponse> => {
    return Facebook.login(["public_profile", "email"]);
  };

  const logoutGoogle = async () => {
    return GooglePlus.logout().catch(() => {
      // TODO: log
    });
  };

  const logoutFacebook = async () => {
    return Facebook.logout().catch(() => {
      // TODO: log
    });
  };

  const logout = async () => {
    logoutGoogle();
    logoutFacebook();
  };

  return {
    authGoogle,
    authFacebook,
    logoutGoogle,
    logoutFacebook,
    logout,
  };
};
