const useNavigator = navigator as any;

export const ShareWeb = () => {
  const share = async (config: IShareConfig) => {
    try {
      const { files } = config;

      if (files && files.length > 0) {
        if (!useNavigator.canShare) {
          throw new Error(
            "canShare is not supported. File sharing may not be supported at all."
          );
        } else if (!checkBasicFileShare()) {
          throw new Error("File sharing is not supported in this browser.");
        } else if (!useNavigator.canShare({ files })) {
          throw new Error("share() does not support the given files");
        }
      }

      await useNavigator.share(config);
    } catch (error: any) {
      throw new Error("ShareWeb error: " + error.message);
    }
  };

  return {
    share,
  };
};

interface IShareConfig {
  files?: File[];
  title?: string;
  text?: string;
  url?: string;
}

function checkBasicFileShare() {
  // XXX: There is no straightforward API to do this.
  // For now, assume that text/plain is supported everywhere.
  const txt = new Blob(["Hello, world!"], { type: "text/plain" });
  // XXX: Blob support? https://github.com/w3c/web-share/issues/181
  const file = new File([txt], "test.txt");
  return useNavigator.canShare({ files: [file] });
}
