import React from "react";
import {
  CalendarPicker,
  SegmentIcon,
  BoxMUI,
  PaperMUI,
  TypographyMUI,
  Separator,
  TimePicker,
} from "../../DeltaUI/Components";
import { MomentJS, useComponentMount } from "../../Utils";
import { COLOR_SECONDARY } from "../../Utils/Constants";
import { CreateTripContext } from "./CreateTripContext";
import { CreateTripNextButton } from "./CreateTripNextButton";

export interface CreateTripDateProps {
  onNext: () => void;
}

const CreateTripDate: React.FC<CreateTripDateProps> = (props) => {
  const isMountedComponent = useComponentMount("CreateTripDate");
  const { onNext } = props;
  const {
    addToDate,
    getDate,
    getTime,
    createMoment,
    getDescriptionDate,
  } = MomentJS();
  const { dateFrom, setDateFrom } = React.useContext(CreateTripContext);

  const [valueSegment, setValueSegment] = React.useState<number>(1);

  const [date, setDate] = React.useState<Date>(dateFrom || new Date());
  const [time, setTime] = React.useState<Date>(dateFrom || new Date());

  React.useEffect(() => {
    setDateFrom(
      createMoment({
        date: `${getDate(date)} ${getTime(time)}`,
        originFormat: "DD/MM/YYYY HH:mm",
      }).toDate()
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, time]);

  React.useEffect(() => {
    isMountedComponent.current = true;
    return () => {
      isMountedComponent.current = false;
    };
  }, [isMountedComponent]);

  return (
    <div>
      <Separator px={32} />
      <BoxMUI
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <SegmentIcon
          style={{
            zIndex: 1,
          }}
          size="large"
          color="secondary"
          width="auto"
          defaultValue={1}
          value={valueSegment}
          onChange={(ev) => setValueSegment(() => ev)}
          list={[
            {
              key: 1,
              element: (
                <BoxMUI
                  style={{
                    width: "112px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  Fecha
                </BoxMUI>
              ),
            },
            {
              key: 2,
              element: (
                <BoxMUI
                  style={{
                    width: "112px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  Hora
                </BoxMUI>
              ),
            },
          ]}
        />
      </BoxMUI>
      <Separator px={16} />
      <BoxMUI
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <TypographyMUI className="TypographyExtrabold11pt">
          {getDescriptionDate(date)}
        </TypographyMUI>
      </BoxMUI>
      {valueSegment === 2 ? (
        <BoxMUI
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <TypographyMUI className="TypographyExtrabold11pt">
            {getTime(time)} hs
          </TypographyMUI>
        </BoxMUI>
      ) : null}
      <Separator px={16} />
      <PaperMUI
        elevation={7}
        style={{
          width: "auto",
          margin: "24px",
          maxWidth: "100%",
        }}
        className="ShadowCard12"
      >
        {valueSegment === 1 ? (
          <CalendarPicker
            startDate={date}
            minDate={new Date()}
            maxDate={addToDate(30, "d", new Date()).toDate()}
            onChange={(ev) => {
              setDate(() => ev);
            }}
            color={COLOR_SECONDARY}
            header={false}
          />
        ) : null}
        {valueSegment === 2 ? (
          <TimePicker
            color={COLOR_SECONDARY}
            value={time}
            onChange={(ev) => {
              if (typeof ev !== "string") {
                setTime(() => ev);
              }
            }}
          />
        ) : null}
      </PaperMUI>
      <CreateTripNextButton
        className="Transparent"
        disabled={valueSegment === 2 && !dateFrom}
        onClick={() => {
          if (valueSegment === 1) {
            setValueSegment(() => 2);
          } else {
            onNext();
          }
        }}
      />
    </div>
  );
};

export default CreateTripDate;
