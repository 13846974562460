import { gql } from "@apollo/client";
import { FragmentService } from "../FragmentService";

export const PREFERENCES = gql`
  query preferences(
    $where: PreferenceWhereInput
    $orderBy: [PreferenceOrderByInput!]
    $first: Int
    $last: Int
    $before: PreferenceWhereUniqueInput
    $after: PreferenceWhereUniqueInput
  ) {
    preferences(
      where: $where
      orderBy: $orderBy
      first: $first
      last: $last
      before: $before
      after: $after
    ) {
      ...FPreference
    }
  }
  ${FragmentService.Preference}
`;
