import React from "react";
import { AppContext } from "../../Providers/AppContext";
import { UpoolContext } from "../../Providers/UpoolContext";
import { useComponentMount } from "../../Utils";
import { Crypto } from "../../Utils/Crypto";
import { DialogCustomHook } from "../DialogCustom";
import { MyTripsContext } from "../MyTrips/MyTripsContext";
import { RecreateSearchAdvanced } from "./RecreateSearchAdvanced";
import { RecreateSearchOfTrip } from "./RecreateSearchOfTrip";
import { SearchTripContext } from "./SearchTripContext";

interface IRecreateSearch {}

const context: IRecreateSearch = {};

export const RecreateSearchContext = React.createContext(context);

export interface RecreateSearchProviderProps {}

export const RecreateSearchProvider: React.FC<RecreateSearchProviderProps> = (
  props
) => {
  const isMountedComponent = useComponentMount("RecreateSearchProvider");
  const { decrypt } = Crypto();
  const { initUrlSearchParams } = React.useContext(AppContext);
  const { setLoading } = React.useContext(UpoolContext);
  const { setLoadingMyTrips } = React.useContext(MyTripsContext);
  const { setLoadingSearchTripResults } = React.useContext(SearchTripContext);
  const { messageError } = DialogCustomHook();
  const [isError, setIsError] = React.useState<boolean>(false);

  const handleDecrypt = (p?: string) => {
    try {
      if (p) {
        const v = decrypt(p);
        if (v) {
          return v;
        } else {
          setIsError(() => true);
        }
      }
    } catch (err: any) {
      setIsError(() => true);
    }
    return undefined;
  };

  const paramS = React.useMemo(() => {
    const p = initUrlSearchParams?.get("s");
    if (p) {
      return handleDecrypt(p);
    }
    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initUrlSearchParams]);

  const paramT = React.useMemo(() => {
    const p = initUrlSearchParams?.get("t");
    if (p) {
      return handleDecrypt(p);
    }
    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initUrlSearchParams]);

  React.useEffect(() => {
    if (isError) {
      setLoading(() => false);
      setLoadingMyTrips(() => false);
      setLoadingSearchTripResults(() => false);
      messageError({
        context: "RecreateSearchProvider.handleError.1",
        message: "El enlace es incorrecto.",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  React.useEffect(() => {
    isMountedComponent.current = true;
    return () => {
      isMountedComponent.current = false;
    };
  }, [isMountedComponent]);

  return (
    <RecreateSearchContext.Provider value={{}}>
      {paramT ? <RecreateSearchOfTrip t={paramT} /> : null}
      {paramS && !paramT ? <RecreateSearchAdvanced s={paramS} /> : null}
      {props.children}
    </RecreateSearchContext.Provider>
  );
};
