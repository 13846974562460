import React from "react";
import {
  BoxMUI,
  ButtonMUI,
  CardMUI,
  RatingMUI,
  Separator,
  TypographyMUI,
} from "../../DeltaUI/Components";
import { DialogCustom } from "../DialogCustom";
import { MyTripsContext } from "../MyTrips/MyTripsContext";
import { ItemUser } from "../User/ItemUser";

export interface DialogRatingViewProps {}

const DialogRatingView: React.FC<DialogRatingViewProps> = (props) => {
  const { setRatingView, ratingView } = React.useContext(MyTripsContext);

  const onClose = () => {
    setRatingView(() => undefined);
  };

  const rate = React.useMemo(() => {
    return ratingView?.rate;
  }, [ratingView]);

  const userFromIsDriver = React.useMemo(() => {
    return ratingView?.userFromIsDriver || false;
  }, [ratingView]);

  return (
    <DialogCustom
      context="DialogRatingView.1"
      title={
        rate?.userFrom ? (
          <>
            <Separator px={8} />
            <ItemUser
              user={rate.userFrom}
              isDrive={userFromIsDriver}
              sizeAvatar={5}
              disabled={true}
            />
            <Separator px={16} />
            <Separator px={0} divider />
          </>
        ) : undefined
      }
      open={true}
      body={
        <>
          <TypographyMUI className="TypographyExtrabold11pt">
            Te calificó
          </TypographyMUI>
          <Separator px={8} />
          <CardMUI
            className="FlexRow"
            elevation={0}
            style={{ padding: "8px 16px" }}
          >
            <RatingMUI
              style={{ marginTop: "8px" }}
              readOnly
              value={Number(rate?.score) || 0}
              precision={0.1}
              sx={{
                "& .MuiRating-icon": {
                  fontSize: "16pt !important",
                },
              }}
            />
            <TypographyMUI
              style={{ marginLeft: "8px", alignSelf: "self-end" }}
              className="TypographyBold11pt"
            >
              {Number.parseFloat(`${rate?.score}`).toFixed(1)}
            </TypographyMUI>
          </CardMUI>
          <Separator px={16} />
          <TypographyMUI className="TypographyExtrabold11pt">
            Comentario
          </TypographyMUI>
          <Separator px={8} />
          <CardMUI
            className="FlexRow"
            elevation={0}
            style={{ padding: "8px 16px" }}
          >
            <TypographyMUI>{rate?.description}</TypographyMUI>
          </CardMUI>
        </>
      }
      footer={
        <BoxMUI className="FlexRow" style={{ padding: "16px 24px" }}>
          <ButtonMUI
            style={{ width: "100%" }}
            variant="contained"
            color="primary"
            onClick={onClose}
          >
            Aceptar
          </ButtonMUI>
        </BoxMUI>
      }
    />
  );
};

export default DialogRatingView;
