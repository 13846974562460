import React from "react";
import { PaperMUI, ChipMUI, BoxMUI } from "../../DeltaUI/Components";
import { IPreferenceTrip } from "../../Services/PreferenceService";
import { COLOR_QUATERNARY } from "../../Utils/Constants";
import { SvgIcon } from "../SvgIcon";

export interface CardDetailTripPreferencesProps {
  style?: React.CSSProperties;
  className?: string;
  preferencesList: IPreferenceTrip[];
  elevation?: number;
}

const CardDetailTripPreferences: React.FC<CardDetailTripPreferencesProps> = (
  props
) => {
  const { style, className, preferencesList, elevation } = props;

  return (
    <PaperMUI
      elevation={elevation || 7}
      className={className}
      style={{
        padding: "16px 24px",
        ...style,
      }}
      sx={{
        "& .MuiChip-root": {
          border: "none",
          background: "none",
          color: COLOR_QUATERNARY,
        },
      }}
    >
      <BoxMUI
        style={{
          marginRight: "-12px",
          marginLeft: "-8px",
        }}
      >
        {preferencesList.map((p) => {
          const pref = p.preference;
          return (
            <ChipMUI
              key={pref.id}
              size="medium"
              variant="outlined"
              icon={<SvgIcon src={pref.icon} />}
              label={pref.name}
              style={{
                height: "40px",
                marginRight: "12px",
              }}
            />
          );
        })}
      </BoxMUI>
    </PaperMUI>
  );
};

export default CardDetailTripPreferences;
