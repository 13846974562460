import React from "react";
import { BoxMUI, Separator, TypographyMUI } from "../../DeltaUI/Components";

interface TermsAndConditionsProps {
  style?: React.CSSProperties;
  className?: string;
}

export const TermsAndConditions: React.FC<TermsAndConditionsProps> = (
  props
) => {
  const { style, className } = props;
  const mark = <span style={{ fontWeight: "bold" }}>uPool</span>;

  return (
    <BoxMUI
      style={{ padding: "16px", ...style }}
      className={className}
      sx={{
        "& .MuiTypography-subtitle1, .MuiTypography-subtitle": {
          fontWeight: "bold",
        },
        "& .MuiTypography-subtitle1": {
          marginTop: "48px",
          marginBottom: "24px",
        },
        "& .MuiTypography-subtitle2": {
          marginTop: "24px",
          marginBottom: "16px",
        },
        "& .MuiTypography-body2": {
          marginBottom: "16px",
          textAlign: "justify",
        },
      }}
    >
      <Separator px={16} />
      <TypographyMUI
        className="TypographyBold11pt"
        style={{ textAlign: "center" }}
      >
        Términos y condiciones{" "}
      </TypographyMUI>
      <Separator px={32} />
      <TypographyMUI variant="body2">
        {mark} es dueña de y explota una plataforma online de trayectos
        compartidos, mediante una aplicación web interactiva que permite
        contactar conductores que se dirigen a un determinado destino, con otras
        personas que requieren acceder al mismo lugar, permitiendo de esta forma
        el traslado conjunto de éstos, como así también la asunción conjunta de
        los costos que dichos traslados impliquen.{" "}
      </TypographyMUI>
      <TypographyMUI variant="body2">
        Los presentes términos y condiciones tienen por objeto regular el acceso
        y los términos de uso de la Plataforma.{" "}
      </TypographyMUI>
      <TypographyMUI variant="body2">
        Cualquier persona que desee utilizar el servicio prestado por {mark} por
        medio de dicha aplicación web, sólo podrá hacerlo con la condición
        excluyente de prestar conformidad con los Términos y Condiciones
        respectivos, junto con todas las demás políticas y principios
        establecidos en el presente.
      </TypographyMUI>
      <TypographyMUI variant="body2">
        Los usuarios que presten conformidad con los presentes Términos y
        Condiciones reconocen expresamente haber leído los mismos y prestan
        expresa conformidad con éstos.
      </TypographyMUI>
      <TypographyMUI variant="body2">
        El usuario que no acepte estos términos y condiciones generales, los
        cuales tienen un carácter obligatorio y vinculante, no podrá utilizar{" "}
        {mark}.{" "}
      </TypographyMUI>
      <TypographyMUI variant="body2">
        Al utilizar la plataforma, el Usuario se compromete a cumplir con todas
        las leyes federales, estatales y municipales, así como con las normas
        del presente documento, cuales quieran que sean los derechos que estén
        envueltos.
      </TypographyMUI>
      <TypographyMUI variant="body2">
        Que, al suscribir el presente, usted entiende y reconoce que {mark} no
        es parte de ningún contrato, convenio, acuerdo o relación contractual de
        tipo alguna celebrado entre los Usuarios de la plataforma.{" "}
      </TypographyMUI>

      <TypographyMUI variant="subtitle1">
        1. Registro de Usuarios y Creación de Cuenta
      </TypographyMUI>
      <TypographyMUI variant="subtitle2">
        1.1 Edad Mínima Requerida
      </TypographyMUI>
      <TypographyMUI variant="body2">
        A los fines de poder acceder a los servicios brindados por {mark} por
        medio de su plataforma, las personas deben ser mayores de 17 años de
        edad.{" "}
      </TypographyMUI>
      <TypographyMUI variant="body2">
        Toda persona que acceda a la plataforma a los fines de registrarse en la
        misma como Usuario; garantiza superar la edad mínima requerida en el
        presente.{" "}
      </TypographyMUI>
      <TypographyMUI variant="subtitle2">
        1.2 Creación del Usuario y Registro de la Cuenta
      </TypographyMUI>
      <TypographyMUI variant="body2">
        Los servicios prestados por {mark} serán accesibles para toda persona
        física que cumpliera con el requisito de la edad mínima.{" "}
      </TypographyMUI>
      <TypographyMUI variant="body2">
        Las personas físicas y las personas jurídicas, que tengan plena
        capacidad legal, están autorizadas a acceder a las prestaciones del
        servicio ofrecido por {mark}.{" "}
      </TypographyMUI>
      <TypographyMUI variant="body2">
        Que la persona física o jurídica que se registrara en la plataforma solo
        podrá tener asociada una única Cuenta de Usuario, quedando estrictamente
        prohibida la creación de más de un Usuario por persona.
      </TypographyMUI>
      <TypographyMUI variant="body2">
        Para el caso de personas jurídicas, éstas sólo podrán registrarse en la
        plataforma por medio de un apoderado, o representante legal, debiendo
        acreditar dicha personería mediante el instrumento correspondiente.{" "}
      </TypographyMUI>
      <TypographyMUI variant="body2">
        A los fines de poder utilizar los servicios ofrecidos por {mark}, la
        persona deberá registrarse en la plataforma creando un “Usuario” y
        debiendo mantener activa una cuenta personal asociada al mismo
        (“Cuenta”).{" "}
      </TypographyMUI>
      <TypographyMUI variant="body2">
        Que en ningún caso se podrá realizar un anuncio y/o reservar una plaza
        para un trayecto compartido, sin que antes se hubiera cumplimentado con
        la debida creación del Usuario y el correspondiente registro de la
        Cuenta.{" "}
      </TypographyMUI>
      <TypographyMUI variant="body2">
        A los fines proceder a la creación del Usuario y al debido registro de
        una Cuenta, la persona puede optar hacerlo por medio de la base de datos
        de la aplicación web (app) o bien accediendo al sitio web
        (www.upool.com.ar).{" "}
      </TypographyMUI>
      <TypographyMUI variant="body2">
        Que, al momento de registrarse en la base de datos, los Usuarios deberán
        proporcionar toda la información requerida ingresar datos verídicos, y
        una dirección de correo electrónico activa.
      </TypographyMUI>
      <TypographyMUI variant="body2">
        Al momento del registro de la Cuenta, el Usuario deberá escoger un
        método de pago válido.{" "}
      </TypographyMUI>
      <TypographyMUI variant="body2">
        El proceso de registración del Usuario se considerará válido al
        completar online el formulario de registro por parte del Usuario, y la
        aceptación electrónicamente del presente documento de Términos y
        Condiciones de Uso.
      </TypographyMUI>
      <TypographyMUI variant="body2">
        Para ello, el Usuario deberá dar click al recuadro que dice “Acepto los
        Términos y Condiciones de Uso”, por medio del cual el Usuario declara
        automáticamente, de forma seria e irrevocable que está de acuerdo con
        este documento y todas las demás políticas y reglas disponibilidades en
        el sistema.
      </TypographyMUI>
      <TypographyMUI variant="body2">
        El Usuario se compromete a mantener toda la información requerida y
        vinculada a su Cuenta de forma exacta, completa y actualizada.{" "}
      </TypographyMUI>
      <TypographyMUI variant="body2">
        Para el caso en que no se mantuviera la información de Cuenta de forma
        exacta, completa y actualizada; o si el método de pago ingresado fuera
        inválido o no permitiera realizar la operación de cobro, dichas
        circunstancias podrán importar la suspensión de la Cuenta asociada al
        Usuario, significándole a éste la imposibilidad para poder acceder y
        utilizar los Servicios brindados por {mark}.{" "}
      </TypographyMUI>
      <TypographyMUI variant="body2">
        El Usuario es el único responsable de toda la actividad que ocurre en su
        Cuenta, ya sea por parte suya y/o de terceros; resultando su plena
        responsabilidad el resguardo en forma segura y secreta del nombre de
        usuario y la correspondiente contraseña de su Cuenta.{" "}
      </TypographyMUI>
      <TypographyMUI variant="body2">
        El Usuario será el único responsable del uso de su Cuenta, ya sea por
        parte suya y/o de terceros, salvo que hubiera comunicado de forma
        expresa, escrita y fehaciente a {mark} la pérdida, el uso fraudulento
        por parte de un tercero, o la revelación de su contraseña a un tercero.
      </TypographyMUI>
      <TypographyMUI variant="body2">
        El Usuario acepta que la responsabilidad de cualquier contenido
        visualizado, como textos, productos, informaciones en general, son de
        responsabilidad de las personas que crearon esos contenidos en el
        presente Sitio Web/Aplicación web.
      </TypographyMUI>
      <TypographyMUI variant="body2">
        El Usuario acepta que, al utilizar los servicios de {mark}, puede ser
        expuesto a contenidos que puedan ser considerados ofensivos, falsos, o
        que puedan no respetar este documento de Términos de Uso, y que aún así
        utiliza los servicios por cuenta propia y aceptando ese riesgo;
      </TypographyMUI>
      <TypographyMUI variant="body2">
        {mark} tiene el derecho de eliminar, filtrar y revisar cualquier
        contenido publicado que crea conveniente.
      </TypographyMUI>
      <TypographyMUI variant="body2">
        {mark} permite a todos los Usuarios la utilización de este Sitio Web y
        aplicación (app) para compartir vehículos en forma privada y sin fines
        de lucro. No obstante, existe la modalidad de uso profesional y/o
        comercial para el caso de Usuarios registrados como tales. La
        información publicada en la plataforma para compartir auto sólo puede
        usarse para fines privados. Los viajes comerciales se caracterizan
        especialmente porque el conductor los ofrece con intención de obtener
        beneficios.{" "}
      </TypographyMUI>

      <TypographyMUI variant="subtitle2">1.3 Verificación</TypographyMUI>
      <TypographyMUI variant="body2">
        El Usuario faculta a {mark}, para que, a su exclusivo criterio y
        discreción, establezca y lleve a cabo un procedimiento de verificación
        de la información suministrada en el perfil del Usuario.
      </TypographyMUI>
      <TypographyMUI variant="body2">
        El Usuario reconoce y acepta que cualquier referencia en la Plataforma o
        en los Servicios a la información "certificada" "verificada" o
        "validada", o a cualquier otro término análogo o similar, significa
        solamente que un Usuario ha superado con éxito el procedimiento de
        verificación existente en la Plataforma o en los Servicios, cualquiera
        sea este proceso e independientemente de su eficacia para verificar la
        real identidad de un Usuario.
      </TypographyMUI>

      <TypographyMUI variant="subtitle1">
        2. Utilización de los Servicios
      </TypographyMUI>
      <TypographyMUI variant="body2">
        {mark} proporciona a todos los Usuarios, una plataforma independiente
        para conectar a conductores que se dirigen a un determinado lugar, con
        personas que requieren viajar al mismo destino, permitiendo de esta
        forma compartir dicho trayecto, y la asunción conjunta de los gastos que
        ello implica.{" "}
      </TypographyMUI>
      <TypographyMUI variant="body2">
        Para ello, los conductores deberán publicar “Anuncios”; y quienes deseen
        compartir dicho trayecto como pasajeros deberán proceder a la debida
        “Reserva de una Plaza”, todo ello de acuerdo dando cumplimiento a todos
        los requisitos y deberes especificados en los presentes términos y
        condiciones.
      </TypographyMUI>
      <TypographyMUI variant="body2">
        La utilización de los servicios brindados por {mark} por parte de los
        Usuarios, ya sea como conductor, o como pasajero estará siempre asociado
        a la identidad de los mismos, que se deberá corresponder con aquella
        registrada en la base de datos de {mark}. Los Usuarios se comprometen y
        garantizan que solamente accederán a los servicios brindados por {mark}{" "}
        en dicha calidad.{" "}
      </TypographyMUI>

      <TypographyMUI variant="subtitle2">
        2.1 Publicación de Anuncios
      </TypographyMUI>
      <TypographyMUI variant="body2">
        Aquellas personas que se desempeñen como conductores y que se dirijan a
        un determinado destino, siempre que cumplan obligatoria y
        excluyentemente con todos y cada uno de los requisitos que continuación
        se especifican, podrán publicar “Anuncios” en la plataforma de {mark} a
        los fines de ofrecer plazas en sus vehículos.
      </TypographyMUI>
      <TypographyMUI variant="body2">
        El Usuario solo podrá publicar Anuncios relacionados a su persona;
        quedando terminantemente prohibida la publicación de anuncios
        relacionados a otros conductores.{" "}
      </TypographyMUI>
      <TypographyMUI variant="body2">
        Al momento de publicar un “Anuncio”, el Usuario deberá proporcionar
        información básica en relación al trayecto a ser realizado (fecha del
        viaje, horario de salida, lugar de salida, lugar de destino, número de
        plazas disponibles, costo del trayecto).
      </TypographyMUI>
      <TypographyMUI variant="body2">
        El Usuario es el único responsable por el contenido del Anuncio
        publicado en la plataforma de {mark}; y garantiza la veracidad de la
        información del mismo, comprometiéndose expresamente a respetar todas
        las condiciones ofrecidas en el mismo.{" "}
      </TypographyMUI>
      <TypographyMUI variant="body2">
        El Usuario podrá publicar Anuncios en la plataforma de {mark} siempre
        que cuente con:
      </TypographyMUI>
      <TypographyMUI variant="body2">
        <b>(a)</b> Edad mayor a 17 años.
      </TypographyMUI>
      <TypographyMUI variant="body2">
        <b>(b)</b> Licencia de Conducir vigente
      </TypographyMUI>
      <TypographyMUI variant="body2">
        El Usuario que se anuncie como conductor para llevar a cabo un viaje de
        trayecto compartido, deberá poseer licencia de conducir vigente al
        momento de realizar el viaje y mientras dure el mismo.
      </TypographyMUI>
      <TypographyMUI variant="body2">
        Al momento de publicar un Anuncio, el Usuario declara asimismo que no
        posee ninguna contraindicación ni incapacidad médica para conducir.
      </TypographyMUI>
      <TypographyMUI variant="body2">
        Que, para el caso en que se ofrezcan trayectos compartidos con fines de
        lucro, los conductores deberán presentar licencia de conducir
        profesional.
      </TypographyMUI>
      <TypographyMUI variant="body2">
        <b>(c)</b> Vehículo de su Propiedad o Autorizado
      </TypographyMUI>
      <TypographyMUI variant="body2">
        El Usuario sólo podrá realizar Anuncios para trayectos a ser recorridos
        con un vehículo de su propiedad, o bien mediante la debida acreditación
        del uso autorizado de un vehículo de propiedad de un tercero (“cédula
        azul”).
      </TypographyMUI>
      <TypographyMUI variant="body2">
        El Usuario que realiza Anuncios en la plataforma de {mark} se compromete
        a ser el conductor durante todo el trayecto anunciado.
      </TypographyMUI>
      <TypographyMUI variant="body2">
        <b>(d)</b> Póliza de Seguro Vigente
      </TypographyMUI>
      <TypographyMUI variant="body2">
        Que, el Usuario que anuncie un viaje como conductor, deberá acreditar la
        vigencia del seguro automotor del vehículo a ser utilizado para el
        mismo, el que deberá tener vigencia durante toda la duración del
        trayecto anunciado.{" "}
      </TypographyMUI>
      <TypographyMUI variant="body2">
        {mark} no se responsabiliza por las consecuencias, tanto para el
        conductor como para los pasajeros o cualquier tercero, que tenga un
        viaje no asegurado por el conductor; como así tampoco se responsabiliza
        de forma parcial o total por cualquier daño y/o perjuicio físico,
        psicológico, material, o de cualquier índole a las personas o cosas que
        deriven de esas consecuencias, ni se constituye en garante, fiador o
        responsable solidario de los responsables principales que por su
        impericia, negligencia, falta de observancia a sus deberes, culpa o dolo
        hayan generado dichos daños y/o perjuicios. Los pasajeros tendrán la
        responsabilidad absoluta de controlar el estado del automóvil, del
        conductor y del automóvil en el que viajarán, sin posibilidad de reclamo
        alguna a {mark} o a sus miembros.{" "}
      </TypographyMUI>
      <TypographyMUI variant="body2">
        <b>(e)</b> Condiciones Generales del Vehículo a ser utilizado:
      </TypographyMUI>
      <TypographyMUI variant="body2">
        El Usuario garantiza que el vehículo que utilizará para el trayecto
        ofrecido en el anuncio, se encuentra en buen estado en general; y que
        posee la Verificación Técnica Vehicular, o normativa similar vigente al
        momento en que se lleva a cabo el viaje compartido.{" "}
      </TypographyMUI>
      <TypographyMUI variant="body2">
        Asimismo, el Usuario se compromete a ofertar solamente las plazas
        disponibles en el vehículo y no más.{" "}
      </TypographyMUI>
      <TypographyMUI variant="body2">
        Que, a los fines de determinar las plazas que podrá ofertar, éstas
        deberán contar con cinturón de seguridad y apoyacabezas, sin excepción.{" "}
      </TypographyMUI>
      <TypographyMUI variant="body2">
        Que, asimismo, los usuarios que se ofrezcan como conductores deberán
        garantizar el cumplimiento de las normas de seguridad vigentes,
        responsabilizándose éstos en forma exclusiva y absoluta por cualquier
        incumplimiento o falta de los elementos de primeros auxilios y/o
        seguridad y/o estado general del vehículo utilizado que pudiera
        ocasionar algún perjuicio a otros usuarios y a sí mismo.{" "}
      </TypographyMUI>
      <TypographyMUI variant="body2">
        {mark} se reserva el derecho de modificar o eliminar cualquier Anuncio
        que no se ajustara a los presentes Términos y Condiciones, pudiendo
        ejercer dicho control sobre los Anuncios publicados en su plataforma, a
        su exclusivo criterio y sin necesidad de previo aviso.{" "}
      </TypographyMUI>

      <TypographyMUI variant="subtitle2">2.2 Reserva de Plazas</TypographyMUI>
      <TypographyMUI variant="body2">
        {mark} brinda la posibilidad de que los Usuarios interesados en los
        Anuncios publicados en su plataforma, pueden solicitar la guarda de una
        plaza en el vehículo del conductor.
      </TypographyMUI>
      <TypographyMUI variant="body2">
        Luego de que el Usuario realiza dicha reserva, se liberará la
        información privada de ambas partes para que puedan contactarse y pactar
        las condiciones y los detalles de los trayectos a ser compartidos.
      </TypographyMUI>
      <TypographyMUI variant="body2">
        El Usuario asume la total responsabilidad por las condiciones pactadas,
        y asume todas las consecuencias que pudiere trae aparejada cualquier
        cancelación, modificación o incumplimiento de las condiciones pactadas,
        así como también resulta plenamente responsable por cualquier
        incumplimiento en cuanto al pago de los costos compartidos.{" "}
      </TypographyMUI>
      <TypographyMUI variant="body2">
        Respecto a Usuarios que se transporten con menores de edad, deberán
        cumplir con las disposiciones de la SGT N°43/2016, de la CNRT
        N°1025/2016 y concordantes. El Usuario será el encargado exclusivo de
        hacer cumplir estas disposiciones y el responsable absoluto por su
        incumplimiento, sin perjuicio de las medidas que pueda tomar {mark}{" "}
        respecto a su suspensión temporal o permanente. El Usuario será
        responsable absoluto de todas las consecuencias derivadas del
        incumplimiento contractual de estos Términos y Condiciones, sobre las
        cosas a su cargo y/o las personas a su cuidado y por cualquier daño y/o
        perjuicio, sea mediante culpa o dolo.
      </TypographyMUI>
      <TypographyMUI variant="subtitle1">
        3. Comportamiento de los Usuarios
      </TypographyMUI>
      <TypographyMUI variant="body2">
        Todos los Usuarios que acceden a la plataforma de {mark} garantizan y
        reconocen su plena y absoluta responsabilidad en relación al respeto y
        cumplimiento de toda la normativa aplicable al uso de la misma.{" "}
      </TypographyMUI>
      <TypographyMUI variant="body2">
        En caso de que un Usuario, ya sea en calidad de conductor o de pasajero
        no cumpliera con cualquiera de estas obligaciones, {mark} se reserva el
        derecho de publicar o divulgar esta información en el perfil online del
        Usuario o hasta retirar a dicho Usuario del Sitio Web.{" "}
      </TypographyMUI>

      <TypographyMUI variant="subtitle2">
        3.1 Deberes de los Conductores.
      </TypographyMUI>
      <TypographyMUI variant="body2">
        Que, los Usuarios que se ofrezcan como conductores, se obligan a:
      </TypographyMUI>
      <TypographyMUI variant="body2">
        <b>a)</b> Reunir todos los requisitos exigidos por {mark} para poder
        realizar Anuncios en su plataforma, de acuerdo a las exigencias del
        Punto 2.1 del presente.
      </TypographyMUI>
      <TypographyMUI variant="body2">
        <b>b)</b> Ajustar su desempeño como conductor responsable respetando
        todas las normas de tránsito y la legislación vial vigente y aplicable.
      </TypographyMUI>
      <TypographyMUI variant="body2">
        <b>c)</b> Dirigirse en forma respetuosa, y cordial para con sus
        pasajeros.{" "}
      </TypographyMUI>
      <TypographyMUI variant="body2">
        <b>d)</b> Publicar anuncios que se correspondan con trayectos reales a
        ser recorridos.
      </TypographyMUI>
      <TypographyMUI variant="body2">
        <b>e)</b> Brindar toda la información requerida en la plataforma a los
        fines de poder anunciar.
      </TypographyMUI>
      <TypographyMUI variant="body2">
        <b>f)</b> Asegurarte de que los Pasajeros pueden contactarte a través
        del servicio de mensajería de la Plataforma.
      </TypographyMUI>
      <TypographyMUI variant="body2">
        <b>g)</b> Respetar las condiciones del trayecto tal como fueran
        anunciadas en la plataforma (fecha y hora del viaje, lugar de salida y
        lugar de destino, plazas disponibles).
      </TypographyMUI>
      <TypographyMUI variant="body2">
        En caso de requerir la modificación de alguna de las condiciones
        ofertadas en el anuncio, el Usuario deberá ponerse en contacto
        inmediatamente con sus pasajeros.{" "}
      </TypographyMUI>
      <TypographyMUI variant="body2">
        <b>h)</b> Utilizar un vehículo que se ajuste a los requisitos exigidos
        en el presente, y que han sido referidos ut-supra.
      </TypographyMUI>
      <TypographyMUI variant="body2">
        <b>i)</b> No ofrecer más plazas que aquellas disponibles en el vehículo
        utilizado.
      </TypographyMUI>
      <TypographyMUI variant="body2">
        <b>j)</b> Portar las constancias relacionadas a la Licencia de Conducir,
        Póliza de Seguro y Verificación Técnica Vehicular vigentes.{" "}
      </TypographyMUI>
      <TypographyMUI variant="body2">
        <b>k)</b> Exhibir la documentación habilitante para el caso en que algún
        pasajero así lo requiriera.{" "}
      </TypographyMUI>
      <TypographyMUI variant="body2">
        <b>l)</b> Deberá esperar a los pasajeros que hubieren reservado sus
        plazas, con una tolerancia de 15 minutos desde la hora de salida
        pactada.{" "}
      </TypographyMUI>
      <TypographyMUI variant="body2">
        <b>m)</b> Con el objetivo de resguardar la integridad física de los
        conductores y los usuarios de los vehículos utilizados para trayectos
        compartidos, y a los fines de reducir los incidentes viales, así como
        concientizar sobre las consecuencias negativas del uso de sustancias
        psicoactivas y/o alcohol en la conducción, se prohíbe terminantemente el
        uso de éstas.
      </TypographyMUI>
      <TypographyMUI variant="body2">
        <b>n)</b> Asumir total y absoluta responsabilidad ante las autoridades
        nacionales, provinciales y municipales por cualquier infracción en la
        que pudiera incurrir al desempeñarse como conductos, o bien de aquellas
        de las que pudiera ser pasible el vehículo utilizado.
      </TypographyMUI>
      <TypographyMUI variant="body2">
        <b>o)</b> Asumir total y absoluta responsabilidad frente a terceros por
        la modificación y/o cancelación de un trayecto compartido acordado,
        aceptando que {mark} no posee ningún tipo de responsabilidad sobre
        dichas circunstancias sin importar los motivos que motivaren dicha
        modificación y/o cancelación.{" "}
      </TypographyMUI>
      <TypographyMUI variant="body2">
        Ante dichas circunstancias, si un pasajero se negara a aceptar dichas
        modificaciones, éstos nada tendrán que reclamar a {mark} en concepto de
        daños y perjuicios ni por ningún otro concepto. En caso de que así lo
        hicieran, el conductor autoriza expresamente a {mark} a
        responsabilizarlo subsidiariamente.
      </TypographyMUI>
      <TypographyMUI variant="body2"></TypographyMUI>
      <TypographyMUI variant="subtitle2">
        3.2 Deberes de los Pasajeros
      </TypographyMUI>
      <TypographyMUI variant="body2">
        Que, los Usuarios que reserven plazas disponibles para realizar
        trayectos compartidos en carácter de pasajeros, se obligan a:
      </TypographyMUI>
      <TypographyMUI variant="body2">
        <b>a)</b> Comportarse adecuadamente durante todo el trayecto, evitando
        cualquier tipo de conducta que pudiere perjudicar la labor del
        conductor.
      </TypographyMUI>
      <TypographyMUI variant="body2">
        Asimismo, el pasajero deberá resguardar la integridad y limpieza del
        vehículo del conductor.{" "}
      </TypographyMUI>
      <TypographyMUI variant="body2">
        <b>b)</b> Dirigirse y comportarse respetuosamente con los demás
        pasajeros y con el conductor del vehículo.
      </TypographyMUI>
      <TypographyMUI variant="body2">
        <b>c)</b> Asegurarse de tener habilitado el servicio de mensajería de
        plataforma o del WhatsApp asociado a su Cuenta, a los fines de poder ser
        contactado el conductor.
      </TypographyMUI>
      <TypographyMUI variant="body2">
        <b>d)</b> Portar y exhibir en caso de ser solicitada, la documentación
        que acreditara su identidad.{" "}
      </TypographyMUI>
      <TypographyMUI variant="body2">
        <b>e)</b> Esperar al conductor en el punto de salida acordado, con una
        tolerancia de 15 minutos desde el horario acordado.{" "}
      </TypographyMUI>
      <TypographyMUI variant="body2">
        <b>f)</b> Para el caso en que un usuario desee cancelar el viaje
        preacordado, deberá informar dicha circunstancia al conductor con una
        antelación que jamás podrá ser inferior a 48 (cuarenta y ocho) horas.
        Caso contrario, el usuario que solicitara dicha cancelación deberá
        afrontar el costo proporcional del monto que le hubiera correspondido
        por la realización de dicho viaje.
      </TypographyMUI>
      <TypographyMUI variant="body2">
        <b>g)</b> Abonar los costos compartidos acordados con el conductor, en
        la proporción que le corresponda.{" "}
      </TypographyMUI>
      <TypographyMUI variant="body2">
        <b>h)</b> No portar ningún objeto y/o sustancia ilegal, asumiendo total
        y absoluta responsabilidad para el caso en que así lo hiciera.{" "}
      </TypographyMUI>
      <TypographyMUI variant="body2"></TypographyMUI>
      <TypographyMUI variant="subtitle1">4. Garantías</TypographyMUI>
      <TypographyMUI variant="body2">
        <b>a)</b> El Usuario acepta que {mark} brinda un servicio exclusivamente
        como intermediario entre Conductores y Pasajeros para realizar viajes
        compartidos a un mismo destino; no teniendo responsabilidad alguna sobre
        cualquier tipo de contrato, acuerdo, convención o pacto de transporte
        y/o de cualquier otro tipo que entre Conductores y Pasajeros pudieran
        celebrar.{" "}
      </TypographyMUI>
      <TypographyMUI variant="body2">
        El usuario reconocer que {mark} no es parte de ningún contrato,
        convenio, acuerdo o relación contractual de ninguna clase.
      </TypographyMUI>
      <TypographyMUI variant="body2">
        <b>b)</b> {mark} NO garantiza:
      </TypographyMUI>
      <TypographyMUI variant="body2">
        <b>I)</b> El funcionamiento correcto en todo momento de la plataforma
        para compartir auto ni su posibilidad de uso y acceso de manera
        ininterrumpida, pues no se pueden excluir fallas técnicas del servidor u
        oscilaciones en la calidad del acceso a la plataforma.
      </TypographyMUI>
      <TypographyMUI variant="body2">
        <b>II)</b> Que los servicios atenderán las necesidades del Usuario;
      </TypographyMUI>
      <TypographyMUI variant="body2">
        <b>III)</b> Que el cumplimiento de los requisitos por parte de los
        conductores y demás usuarios, garantizarán que el servicio sea brindado
        en forma completamente segura y respetando la legislación vigente;
      </TypographyMUI>
      <TypographyMUI variant="body2">
        <b>IV)</b> La calidad, veracidad, exactitud, licitud, actualidad o
        vigencia de los contenidos que integran el Sitio Web {mark} excluye, con
        toda la extensión permitida por la Ley, cualquier responsabilidad de
        cualquier clase por la calidad, veracidad, exactitud, licitud,
        actualidad o vigencia alguna que el Usuario pudiera haber atribuido a
        los contenidos que integran el Sitio Web
      </TypographyMUI>
      <TypographyMUI variant="body2"></TypographyMUI>
      <TypographyMUI variant="subtitle1">
        5. Derechos sobre los contenidos
      </TypographyMUI>
      <TypographyMUI variant="body2">
        5.1 Cada usuario debe asegurarse que los contenidos y materiales que
        utiliza están libres de derechos de terceros y de que no infringen,
        afectan o vulneran derechos de terceros. El usuario asume la absoluta
        responsabilidad por ello, eximiendo expresamente a {mark} de cualquier
        tipo de reclamación de terceros que puedan efectuarse por causa de los
        contenidos y materiales creados por él o por su comportamiento frente a{" "}
        {mark}, incluyendo los costes de defensa legal ocasionados por ello.
      </TypographyMUI>
      <TypographyMUI variant="subtitle1">
        6. Disposiciones finales
      </TypographyMUI>
      <TypographyMUI variant="body2">
        <b>a)</b> Estos Términos y Condiciones de Uso no generan ningún contrato
        de sociedad, franquicia o relación de trabajo entre {mark} y el Usuario;
      </TypographyMUI>
      <TypographyMUI variant="body2">
        <b>b)</b> Estos Términos y Condiciones de Uso pueden ser modificados por{" "}
        {mark} en cualquier momento.
      </TypographyMUI>
      <TypographyMUI variant="body2">
        Las modificaciones entrarán en vigor automáticamente en la fecha de la
        publicación de la nueva versión de la página web;
      </TypographyMUI>
      <TypographyMUI variant="body2">
        <b>c)</b> Al registrarse como Usuario en el sistema y aceptar
        electrónicamente el presente documento de Términos y Condiciones de Uso,
        a través del click que dice “Acepto los Términos y Condiciones de Uso”,
        el Usuario declara automáticamente, de forma seria e irrevocable que
        está de acuerdo con este documento y todas las demás políticas y reglas
        disponibilidades en el sistema, aún no habiendo creado una cuenta de
        Usuario.
      </TypographyMUI>
      <TypographyMUI variant="subtitle1">
        7. Política de Privacidad
      </TypographyMUI>
      <TypographyMUI variant="body2">
        <b>a)</b> {mark} colectará, almacenará y podrá transmitir a los Usuarios
        los datos e informaciones suministradas por otros Usuarios incluyendo,
        sin limitaciones, el nombre completo, edad, foto, su perfil, email y
        número de teléfono. {mark} se responsabiliza de las informaciones
        facilitadas por los Usuarios y se compromete a almacenarlas y no
        divulgarlas si no es para el servicio que la compañía brinda;
      </TypographyMUI>
      <TypographyMUI variant="body2">
        <b>b)</b> {mark} no se responsabiliza por cualquier pérdida de datos del
        Usuario, incluido casos fortuitos o fuerza mayor, ocurridas en virtud de
        invasiones a la página web o rotura de la seguridad por parte de
        terceros no autorizados.
      </TypographyMUI>
      <TypographyMUI variant="body2"></TypographyMUI>
      <TypographyMUI variant="subtitle1">8. Función de {mark}</TypographyMUI>
      <TypographyMUI variant="body2">
        La Plataforma constituye una plataforma de conexión de red en línea en
        la cual los Usuarios pueden crear y publicar Anuncios de Trayectos con
        el objetivo de compartir los mismos y los costes asociados al Trayecto.
        Estos Anuncios serán visualizados por otros Usuarios, los cuales
        observarán las condiciones del Trayecto, y en caso de serles
        conveniente, podrán reservar directamente una Plaza en el vehículo en
        cuestión con el Usuario que ha publicado el Anuncio en la Plataforma.
      </TypographyMUI>
      <TypographyMUI variant="body2">
        Al utilizar la Plataforma y aceptar las presentes Términos y Condiciones
        de Uso, reconoces que {mark} no es parte de ningún acuerdo formalizado
        entre tú y los demás Usuarios con el objetivo de compartir los costes
        relacionados con un Trayecto.
      </TypographyMUI>
      <TypographyMUI variant="body2">
        {mark} no tiene ningún tipo de control sobre el comportamiento de sus
        Usuarios y los usuarios de la Plataforma. Asimismo, no posee, no
        explota, no suministra ni administra los vehículos incluidos en los
        Anuncios, y no ofrece Trayectos en la Plataforma.
      </TypographyMUI>
      <TypographyMUI variant="body2">
        Reconoces y aceptas que {mark} no controla la validez, veracidad o
        legalidad de la información provista por los Usuarios, los Anuncios,
        Plazas y Trayectos ofrecidos. {mark} no ofrece un servicio público o
        privado de transporte ni actúa en calidad de transportista ni ninguna
        otra análoga. La función de {mark} se limita únicamente a facilitar el
        acceso a la Plataforma.
      </TypographyMUI>
      <TypographyMUI variant="body2">
        Los Usuarios (Conductores o Pasajeros) actúan bajo su única y total
        responsabilidad, por lo cual {mark} no será considerado responsable de
        ningún incidente que tenga lugar durante un Trayecto, en especial en lo
        relacionado con:
      </TypographyMUI>
      <TypographyMUI variant="body2">
        <b>(i)</b> información errónea comunicada por el Conductor en su
        Anuncio, o por cualquier otro medio, con respecto al Trayecto y sus
        condiciones;
      </TypographyMUI>
      <TypographyMUI variant="body2">
        <b>(ii)</b> la cancelación o modificación de un Trayecto por parte de un
        Usuario;
      </TypographyMUI>
      <TypographyMUI variant="body2">
        <b>(iii)</b> el no pago de los Costes compartidos por parte de un
        Pasajero en el caso de un Trayecto sin reserva;
      </TypographyMUI>
      <TypographyMUI variant="body2">
        <b>(iv)</b> el comportamiento de sus Usuarios durante, antes o después
        del Trayecto.
      </TypographyMUI>
      <TypographyMUI variant="body2"></TypographyMUI>
      <TypographyMUI variant="subtitle1">
        9. Funcionamiento, disponibilidad y funcionalidades de la Plataforma
      </TypographyMUI>
      <TypographyMUI variant="body2">
        {mark} podrá suspender de forma temporal el acceso a la Plataforma, sin
        previo aviso, por motivos técnicos, de mantenimiento, migración u
        operaciones de actualización, o debido a cortes de suministro o
        cualesquier otros que, a exclusivo criterio de {mark}, hagan necesario o
        conveniente la suspensión del Servicio y la Plataforma. {mark} se
        reserva el derecho a modificar o suspender en su totalidad o en parte el
        acceso a la Plataforma o sus funcionalidades, a su discreción, pudiendo
        ser de forma temporal o permanente.
      </TypographyMUI>
      <TypographyMUI variant="body2"></TypographyMUI>
      <TypographyMUI variant="subtitle1">
        10. Modificación de los Términos y Condiciones de Uso
      </TypographyMUI>
      <TypographyMUI variant="body2">
        Estos Términos y Condiciones de Uso y los documentos integrados por
        referencia forman el acuerdo completo entre tú y {mark} con relación a
        la utilización de los Servicios. Cualquier otro medio de información /
        difusión (páginas web, publicidades, posteos en redes sociales), tiene
        solamente fines publicitarios y/o indicativos y no resultan vinculantes
        para {mark}.
      </TypographyMUI>
      <TypographyMUI variant="body2">
        {mark} podrá modificar, total o parcialmente las presentes Términos y
        Condiciones de Uso o modificar la Plataforma y/o los Servicios según su
        exclusivo criterio. {mark} publicará los Términos y Condiciones de Uso
        modificados o unos nuevos términos y condiciones en la página web
        www.upool.com.ar y notificará dicho cambio a los Usuarios a través de la
        Plataforma. El hecho de que continúes usando los Servicios y la
        Plataforma luego de la modificación de los Términos y Condiciones de Uso
        o la publicación de nuevas condiciones de uso hará presumir sin admitir
        prueba en contrario que has aceptado las nuevas condiciones de uso.
      </TypographyMUI>
      <TypographyMUI variant="body2"></TypographyMUI>
      <TypographyMUI variant="subtitle1">
        11. Ley aplicable - Litigios
      </TypographyMUI>
      <TypographyMUI variant="body2">
        Estos Términos y Condiciones de Uso se han redactado en el idioma
        español, y deben interpretarse y aplicarse de conformidad con la
        legislación vigente en la República Argentina.
      </TypographyMUI>
      <Separator px={32} />
    </BoxMUI>
  );
};
