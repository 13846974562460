import { IonRange } from "@ionic/react";
import {
  ListItemMUI,
  Separator,
  TypographyMUI,
} from "../../DeltaUI/Components";

export interface SelectRadiusProps {
  value: number;
  onChange: (ev: number) => void;
  min?: number;
  max?: number;
}

const SelectRadius: React.FC<SelectRadiusProps> = (props) => {
  const { value, onChange, min, max } = props;

  return (
    <ListItemMUI>
      <Separator px={16} orientation="vertical" />
      <IonRange
        style={{ padding: 0 }}
        pin={true}
        value={value}
        min={min}
        max={max}
        onIonChange={(ev: any) => {
          onChange(Number(ev.detail.value));
        }}
        debounce={200}
        mode="ios"
      />
      <Separator px={32} orientation="vertical" />

      <div
        className="ContentInputCustom"
        style={{
          width: "80px",
          height: "42px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <TypographyMUI className="TypographySemibold11pt">
          {value} km
        </TypographyMUI>
      </div>
    </ListItemMUI>
  );
};

export default SelectRadius;
