import { gql } from "@apollo/client";
import { FragmentService } from "../FragmentService";

export const RESERVATION_CHAT_CREATED = gql`
  subscription($where: ReservationFKWhereUniqueInput!) {
    reservationChatCreated(where: $where) {
      ...FReservationChat
    }
  }
  ${FragmentService.ReservationChat}
`;
