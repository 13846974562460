import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { APPLICATION_PATHS } from "../Utils/Constants";
import CreateTripPage from "./CreateTripPage";

const defaultPath = `${APPLICATION_PATHS.CREATE_TRIP}/default`;

const CreateTripNavTreeSwitch: React.FC = () => {
  return (
    <Switch>
      <Route exact path={APPLICATION_PATHS.CREATE_TRIP}>
        <Redirect to={defaultPath} />
      </Route>
      <Route exact path={defaultPath} component={CreateTripPage} />
    </Switch>
  );
};

const CreateTripNavTree: React.FC = () => {
  return <CreateTripNavTreeSwitch />;
};

export default CreateTripNavTree;
