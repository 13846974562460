import React from "react";
import { ScreenOrientation } from "@ionic-native/screen-orientation";

import { EventAppContext } from "./EventAppProvider";
import { App, AppState, BackButtonListenerEvent } from "@capacitor/app";
import { StatusBar } from "@capacitor/status-bar";
import { useCustomLog } from "../Utils";
import { isDesktop } from "../DeltaUI/Utils/Constants";

export interface AndroidProviderProps {}

const AndroidContextProvider: React.FC<AndroidProviderProps> = ({
  children,
}) => {
  const {
    handleEventAndroidGoBackButton,
    handleEventAndroidGoAppStateChange,
  } = React.useContext(EventAppContext);
  const Log = useCustomLog();

  const handleAppStateChange = (state: AppState) => {
    handleEventAndroidGoAppStateChange(state);
  };
  const handleBackButton = (event: BackButtonListenerEvent) => {
    handleEventAndroidGoBackButton(event);
  };

  React.useEffect(() => {
    StatusBar.setOverlaysWebView({ overlay: true }).catch(({ message }) =>
      Log.error({ context: "AndroidContextProvider.StatusBar.1", message })
    );

    App.removeAllListeners().then(() => {
      App.addListener("appStateChange", handleAppStateChange);

      // Config action back button android
      App.addListener("backButton", handleBackButton);
    });

    // set to landscape
    ScreenOrientation.lock(ScreenOrientation.ORIENTATIONS.PORTRAIT);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <>{children}</>;
};

const DesktopAndroidContextProvider: React.FC<any> = ({ children }) => {
  const {
    handleEventAndroidGoBackButton,
    handleEventAndroidGoAppStateChange,
  } = React.useContext(EventAppContext);

  const handleAppStateChange = (ev: any) => {
    handleEventAndroidGoAppStateChange(ev.detail);
  };
  const handleBackButton = (ev: any) => {
    handleEventAndroidGoBackButton(ev.detail);
  };

  React.useEffect(() => {
    // document.dispatchEvent(new CustomEvent("appStateChange", { detail: { isActive: true }}))
    document.addEventListener("appStateChange", handleAppStateChange);
    // document.dispatchEvent(new CustomEvent("backButton", { detail: { canGoBack: true }}))
    document.addEventListener("backButton", handleBackButton);

    return () => {
      document.removeEventListener("appStateChange", handleAppStateChange);
      document.removeEventListener("backButton", handleBackButton);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <>{children}</>;
};

export const AndroidProvider: React.FC<AndroidProviderProps> = (props) => {
  if (!isDesktop) {
    return (
      <AndroidContextProvider {...props}>
        {props.children}
      </AndroidContextProvider>
    );
  } else {
    return (
      <DesktopAndroidContextProvider>
        {props.children}
      </DesktopAndroidContextProvider>
    );
  }
};
