import React from "react";
import { UpoolContext } from "../../Providers/UpoolContext";
import { CardMUI, ChipMUI } from "../../DeltaUI/Components";
import {
  IPreferenceUser,
  PreferenceService,
} from "../../Services/PreferenceService";
import { DialogCustomHook } from "../DialogCustom";
import { SvgIcon } from "../SvgIcon";
import { PreferenceUserContext } from "../../Providers/PreferenceUserContextProvider";
import { COLOR_TERTIARY } from "../../Utils/Constants";
import { useComponentMount } from "../../Utils";

export interface PreferencesListProps {
  style?: React.CSSProperties;
  disabled?: boolean;
  max?: number;
  color?: "primary" | "secondary";
}

const PreferencesList: React.FC<PreferencesListProps> = (props) => {
  const isMountedComponent = useComponentMount("PreferencesList");
  const { disabled, max, color, style } = props;
  const { user, setUser } = React.useContext(UpoolContext);
  const { preferencesUser } = React.useContext(PreferenceUserContext);
  const { getPreferences } = PreferenceService();
  const { messageError } = DialogCustomHook();
  const [preferences, setPreferences] = React.useState<IPreferenceUser[]>(
    preferencesUser
  );

  const handleClick = (preferenceUser: IPreferenceUser) => {
    setPreferences((list: IPreferenceUser[]) => {
      list.forEach((element) => {
        if (element.preferenceId === preferenceUser.preferenceId) {
          element.enabled = !element.enabled;
        }
      });
      return [...list];
    });
  };

  const getData = () => {
    if (user && !disabled) {
      getPreferences(true)
        .then((res) => {
          if (isMountedComponent.current) {
            const newsPreference: IPreferenceUser[] = [];

            res.forEach((element) => {
              const p = preferencesUser.find(
                (p) => p.preferenceId === element.id
              );
              if (!p) {
                newsPreference.push({
                  id: undefined,
                  preferenceId: element.id,
                  userId: user.id,
                  enabled: false,
                  preference: element,
                });
              }
            });

            if (newsPreference.length) {
              setPreferences(() => [...preferencesUser, ...newsPreference]);
            }
          }
        })
        .catch(({ message }) => {
          messageError({
            context: "PreferencesList.getData.1",
            message: message,
          });
        });
    }
  };

  React.useEffect(() => {
    if (isMountedComponent.current && preferences.length) {
      setUser((cUser) =>
        cUser
          ? {
              ...cUser,
              preferences: preferences,
            }
          : undefined
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preferences]);

  React.useEffect(() => {
    isMountedComponent.current = true;
    getData();
    return () => {
      isMountedComponent.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMountedComponent]);

  const renderChips = () => {
    const list = !disabled ? preferences : preferences.filter((i) => i.enabled);
    return (
      <>
        {list.slice(0, max).map((pUser) => (
          <ChipMUI
            key={pUser.preferenceId}
            sx={{
              height: "40px",
              margin: "4px 0",
              marginRight: "8px",
              paddingLeft: "4px",
              borderRadius: "50px",
              bgcolor:
                pUser.enabled && color
                  ? (theme) => `${theme.palette[color].main}17`
                  : undefined,
            }}
            size="medium"
            variant="outlined"
            icon={
              pUser.preference.icon ? (
                <SvgIcon
                  sx={{
                    "& svg": {
                      fill: (theme) =>
                        pUser.enabled && color
                          ? `${theme.palette[color].main}`
                          : `${COLOR_TERTIARY} !important`,
                    },
                    "& svg g path": {
                      fill: (theme) =>
                        pUser.enabled && color
                          ? `${theme.palette[color].main} !important`
                          : `${COLOR_TERTIARY} !important`,
                    },
                  }}
                  svg={pUser.preference.icon}
                />
              ) : undefined
            }
            label={pUser.preference.name}
            color={pUser.enabled ? color : undefined}
            onClick={!disabled ? () => handleClick(pUser) : undefined}
          />
        ))}
      </>
    );
  };

  return (
    <CardMUI
      style={style}
      sx={
        color
          ? {
              "& .MuiChip-outlinedPrimary:hover, .MuiChip-outlinedSecondary:hover": {
                bgcolor: (theme) => `${theme.palette[color].main}17`,
              },
              "& .MuiChip-outlinedDefault:hover": {
                bgcolor: (theme) => `${theme.palette[color].contrastText}`,
              },
            }
          : undefined
      }
      elevation={0}
    >
      {renderChips()}
    </CardMUI>
  );
};

export default PreferencesList;
