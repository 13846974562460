import React from "react";
import {
  EditOutlinedIcon,
  ThumbDownIcon,
  StarIcon,
  BlockIcon,
} from "../../DeltaUI/Components/CustomIcon";
import {
  ITripMode,
  ITripStretch,
  TripService,
} from "../../Services/TripService";
import { Safely } from "../../Utils";
import { UpoolContext } from "../../Providers/UpoolContext";
import {
  COLOR_PRIMARY,
  DETAIL_TRIP_MODE,
  RESERVATION_STATUS,
  TRIP_STATUS,
} from "../../Utils/Constants";
import {
  IReservation,
  ReservationService,
} from "../../Services/ReservationService";
import { MyTripsContext } from "../MyTrips/MyTripsContext";
import { EventAppContext } from "../../Providers/EventAppProvider";
import { DialogCustomHook } from "../DialogCustom";

export interface MyTripsUtilsProps {
  tripStretch?: ITripStretch;
  onChange?: () => void;
}

export const MyTripsUtils = (props: MyTripsUtilsProps) => {
  const { tripStretch, onChange } = props;
  const { message, messageError, messageWarn } = DialogCustomHook();
  const { eventSafely } = Safely();
  const { deleteOneTrip } = TripService();
  const {
    createOneReservation,
    createOneReservationForChat,
    deleteOneReservationToChat,
  } = ReservationService();
  const { user, setLoading, historyPush, selectPathTab } = React.useContext(
    UpoolContext
  );
  const {
    detailTripMode,
    setReservationForChat,
    setReservationForRate,
    setSelectTripStretchForEdit,
    isDriverOfSelectedTripStretch,
    selectTripStretchReservations,
    setCancelReservation,
  } = React.useContext(MyTripsContext);
  const { handleEventAndroidGoBackButton } = React.useContext(EventAppContext);

  const [open, setOpen] = React.useState<boolean>(false);
  const [quantity, setQuantity] = React.useState<number>(1);

  const tripMode: ITripMode = React.useMemo(() => {
    return {
      drive: !!(tripStretch && user && tripStretch.trip.ownerId === user.id),
      passenger: !!(
        tripStretch &&
        user &&
        tripStretch.trip.ownerId !== user.id
      ),
    };
  }, [tripStretch, user]);

  const myReservation = React.useMemo(() => {
    return tripStretch?.myReservation;
  }, [tripStretch]);

  const createNewReservation = React.useMemo(() => {
    return (
      detailTripMode === DETAIL_TRIP_MODE.SEARCH_TRIP &&
      (!myReservation ||
        (myReservation &&
          [
            RESERVATION_STATUS.DELETED_BY_PASSENGER,
            RESERVATION_STATUS.REJECTED_BY_DRIVER,
            RESERVATION_STATUS.REJECTED_BY_SYSTEM,
            RESERVATION_STATUS.CANCELED_BY_PASSENGER,
            RESERVATION_STATUS.CANCELED_BY_DRIVER,
          ].some((statusId) => myReservation.statusId === statusId)))
    );
  }, [myReservation, detailTripMode]);

  /**
   * Eliminar un viaje sin reservas
   */
  const handleDeleteOneTripStretch = () => {
    if (
      selectTripStretchReservations.some(
        (r) => r.statusId === RESERVATION_STATUS.IN_PROGRESS
      )
    ) {
      messageWarn({
        context: "MyTripsUtils.handleDeleteOneTripStretch.1",
        message: "Primero debe dar de baja a todos los integrantes.",
      });
    } else if (tripStretch) {
      message({
        context: "MyTripsUtils.handleDeleteOneTripStretch.2",
        title: "¿Querés cancelar tu viaje?",
        message: "Tu viaje ya no estará disponible.",
        onOk: async () => {
          setLoading(() => true);
          deleteOneTrip(tripStretch.trip.id)
            .then(() => {
              if (onChange) {
                onChange();
              }
            })
            .catch(({ message }) => {
              messageError({
                context: "MyTripsUtils.handleDeleteOneTripStretch.3",
                message: message,
              });
            })
            .finally(() => setLoading(() => false));
        },
        textOnOk: "Sí",
        textOnCancel: "No",
      });
    }
  };

  /**
   * Cancelar una reservación
   */
  const handleDeleteOneReservation = () => {
    if (myReservation) {
      setCancelReservation(() => myReservation);
    }
  };

  /**
   * Eliminar una reservación en estado de chat o revisión
   */
  const handleDeleteOneReservationToChat = () => {
    if (myReservation) {
      setLoading(() => true);
      deleteOneReservationToChat(myReservation.id)
        .then(handleEventAndroidGoBackButton)
        .catch(({ message }) => {
          messageError({
            context: "MyTripsUtils.handleDeleteOneReservationToChat.1",
            message: message,
          });
        })
        .finally(() => setLoading(() => false));
    }
  };

  const goChat = (reservation?: IReservation) => {
    if (reservation) {
      setReservationForChat(() => reservation);
    }
  };

  const confirmInitChat = async (onSuccess: Function) => {
    message({
      context: "MyTripsUtils.confirmInitChat.1",
      title: "Abrir chat",
      message: "¿Querés iniciar una conversación con el conductor?",
      onOk: async () => {
        if (tripStretch) {
          setLoading(() => true);
          createOneReservationForChat(tripStretch.id)
            .then((reservation) => {
              if (onChange) {
                onChange();
              }
              if (onSuccess) {
                onSuccess(reservation);
              }
            })
            .catch(({ message }) => {
              messageError({
                context: "MyTripsUtils.confirmInitChat.2",
                message: message,
              });
            })
            .finally(() => setLoading(() => false));
        }
      },
      textOnOk: "Sí",
      textOnCancel: "No",
    });
  };

  const handleCreateOneReservation = (quantity: number) => {
    setOpen(() => false);
    if (quantity && tripStretch) {
      setLoading(() => true);
      createOneReservation({
        data: {
          tripStretchId: tripStretch.id,
          quantity,
        },
      })
        .then(() => {
          if (onChange) {
            onChange();
          }
        })
        .catch(({ message }) => {
          messageError({
            context: "MyTripsUtils.handleCreateOneReservation.1",
            message: message,
          });
        })
        .finally(() => setLoading(() => false));
    }
  };

  const option2 = React.useMemo(() => {
    if (isDriverOfSelectedTripStretch) {
      if (tripStretch && tripStretch.trip.statusId === TRIP_STATUS.AVAILABLE) {
        return {
          key: 2,
          label: "Editar viaje",
          icon: <EditOutlinedIcon style={{ color: COLOR_PRIMARY }} />,
          onClick: eventSafely(() => {
            setSelectTripStretchForEdit(() => tripStretch);
            historyPush(`${selectPathTab}/edit-trip`);
          }),
          disabled: false,
        };
      }
      return null;
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDriverOfSelectedTripStretch, tripStretch, selectPathTab]);

  const rateTheDriver = React.useMemo(() => {
    if (!isDriverOfSelectedTripStretch) {
      if (
        (tripStretch?.trip.statusId === TRIP_STATUS.DONE ||
          tripStretch?.trip.statusId === TRIP_STATUS.CANCELED) &&
        myReservation &&
        ![
          RESERVATION_STATUS.CREATED,
          RESERVATION_STATUS.IN_REVIEW,
          RESERVATION_STATUS.IN_PROGRESS,
        ].some((statusId) => myReservation.statusId === statusId)
      ) {
        return {
          key: 3,
          label: "Calificar",
          icon: <StarIcon style={{ color: COLOR_PRIMARY }} />,
          onClick: eventSafely(() => {
            setReservationForRate(() => myReservation);
            historyPush(`${selectPathTab}/rate`);
          }),
          disabled:
            ![
              RESERVATION_STATUS.CANCELED_BY_DRIVER,
              RESERVATION_STATUS.CANCELED_BY_PASSENGER,
              RESERVATION_STATUS.FINISHED,
            ].some((statusId) => myReservation.statusId === statusId) ||
            myReservation.rates.some((r) => r.userFromId === user?.id),
        };
      }
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    myReservation,
    selectPathTab,
    user,
    isDriverOfSelectedTripStretch,
    tripStretch,
  ]);

  const option3 = React.useMemo(() => {
    if (isDriverOfSelectedTripStretch) {
      if (
        tripStretch &&
        (tripStretch.trip.statusId === TRIP_STATUS.AVAILABLE ||
          tripStretch.trip.statusId === TRIP_STATUS.IN_PROGRESS)
      ) {
        return {
          key: 3,
          label: "Cancelar viaje",
          icon: <BlockIcon style={{ color: COLOR_PRIMARY }} />,
          onClick: eventSafely(handleDeleteOneTripStretch),
          disabled: false,
        };
      }
      return null;
    }

    if (!isDriverOfSelectedTripStretch) {
      if (
        myReservation &&
        myReservation.statusId === RESERVATION_STATUS.CREATED
      ) {
        return {
          key: 3,
          label: "Eliminar chat",
          icon: <BlockIcon style={{ color: COLOR_PRIMARY }} />,
          onClick: eventSafely(handleDeleteOneReservationToChat),
          disabled: false,
        };
      }
      if (
        myReservation &&
        myReservation.statusId === RESERVATION_STATUS.IN_REVIEW
      ) {
        return {
          key: 3,
          label: "Cancelar solicitud",
          icon: <BlockIcon style={{ color: COLOR_PRIMARY }} />,
          onClick: eventSafely(() =>
            message({
              context: "MyTripsUtils.option3.1",
              title: "¿Querés cancelar esta solicitud de reserva?",
              message: "Ya no estarás postulado para este viaje.",
              onOk: eventSafely(handleDeleteOneReservationToChat),
              textOnOk: "Sí",
              textOnCancel: "No",
            })
          ),
          disabled: false,
        };
      }
      if (
        myReservation &&
        myReservation.statusId === RESERVATION_STATUS.IN_PROGRESS
      ) {
        return {
          key: 3,
          label: "Darme de baja",
          icon: <ThumbDownIcon style={{ color: COLOR_PRIMARY }} />,
          onClick: eventSafely(handleDeleteOneReservation),
          disabled: false,
        };
      }
      if (rateTheDriver) {
        return rateTheDriver;
      }
      return null;
    }
    return null;
    // TODO: chequear estas dependencias
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myReservation, tripStretch, isDriverOfSelectedTripStretch]);

  return {
    option2,
    option3,
    handleCreateOneReservation,
    confirmInitChat,
    open,
    setOpen,
    quantity,
    setQuantity,
    goChat,
    tripMode,
    rateTheDriver,
    createNewReservation,
  };
};
