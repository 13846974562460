import React from "react";
import {
  CardMUI,
  TypographyMUI,
  ListItemMUI,
  ButtonMUI,
  Separator,
} from "../../DeltaUI/Components";
import { Safely, Tools } from "../../Utils";
import { UserContext } from "../../Components/User/UserContext";
import { UpoolContext } from "../../Providers/UpoolContext";
import {
  DETAIL_TRIP_MODE,
  NOTIFICATION_TYPE,
  RESERVATION_STATUS,
} from "../../Utils/Constants";
import { MyTripsContext } from "../../Components/MyTrips/MyTripsContext";
import { ReservationService } from "../../Services/ReservationService";
import { EventAppContext } from "../../Providers/EventAppProvider";
import { DialogCustomHook } from "../DialogCustom";

const CardConfirmReservation: React.FC = () => {
  const { viewUser } = React.useContext(UserContext);
  const { setLoading, notificationGeneral } = React.useContext(UpoolContext);
  const { handleEventUpdateTrip, handleEventNotificationMarkAsAttended } =
    React.useContext(EventAppContext);
  const { selectTripStretchReservations, detailTripMode } =
    React.useContext(MyTripsContext);
  const { getPerson } = Tools();
  const { eventSafely } = Safely();
  const { message, messageError } = DialogCustomHook();
  const { approveOneReservation } = ReservationService();

  const reservation = React.useMemo(
    () =>
      selectTripStretchReservations.find(
        (r) =>
          viewUser &&
          r.userId === viewUser.id &&
          r.statusId === RESERVATION_STATUS.IN_REVIEW
      ),
    // TODO: chequear estas dependencias
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectTripStretchReservations]
  );

  const handleApproveOneReservation = (approve: boolean) => {
    if (reservation) {
      setLoading(() => true);
      approveOneReservation(reservation.id, approve)
        .then(() => {
          handleEventNotificationMarkAsAttended(
            notificationGeneral.filter(
              (n) =>
                n.type === NOTIFICATION_TYPE.RESERVATION_REQUEST &&
                n.payload.reservationId === reservation.id
            )
          );
          handleEventUpdateTrip();
        })
        .catch(({ message }) => {
          messageError({
            context: "CardConfirmReservation.handleApproveOneReservation.1",
            message: message,
          });
        })
        .finally(() => setLoading(() => false));
    }
  };

  const person = React.useMemo(
    () => getPerson(viewUser, 0),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [viewUser]
  );

  const name = React.useMemo(
    () => (person ? `${person.firstName} ${person.lastName}` : ""),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [person]
  );

  return (
    <>
      {reservation && detailTripMode === DETAIL_TRIP_MODE.MY_TRIPS ? (
        <>
          <Separator px={32} />
          <TypographyMUI className="TypographyExtrabold11pt">
            Solicitud de reserva
          </TypographyMUI>
          <Separator px={16} />
          <CardMUI
            style={{
              width: "100%",
              padding: "8px",
            }}
            elevation={7}
            className="ShadowCard8"
          >
            <ListItemMUI
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <ButtonMUI
                style={{
                  width: "100%",
                }}
                variant="outlined"
                color="primary"
                onClick={eventSafely(() =>
                  message({
                    context: "CardConfirmReservation.eventSafely.1",
                    title: "Rechazar",
                    message: `¿Querés rechazar a ${name}?`,
                    onOk: () => handleApproveOneReservation(false),
                    textOnOk: "Sí",
                    textOnCancel: "No",
                  })
                )}
              >
                Rechazar
              </ButtonMUI>
              <Separator px={16} orientation="vertical" />
              <ButtonMUI
                style={{
                  width: "100%",
                }}
                variant="contained"
                color="primary"
                onClick={eventSafely(() => handleApproveOneReservation(true))}
              >
                Confirmar
              </ButtonMUI>
            </ListItemMUI>
          </CardMUI>
        </>
      ) : null}
    </>
  );
};

export default CardConfirmReservation;
