import React from "react";
import { Imgs } from "../../Assets";
import { config } from "../../config";
import {
  BoxMUI,
  ButtonMUI,
  Separator,
  TypographyMUI,
} from "../../DeltaUI/Components";
import { COLOR_SECONDARY, URL } from "../../Utils/Constants";

interface FooterPublicProps {
  onShare?: () => void;
}

export const FooterShare: React.FC<FooterPublicProps> = (props) => {
  const { onShare } = props;
  return (
    <>
      <BoxMUI
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          background: "#ffffff",
        }}
      >
        <BoxMUI
          style={{
            width: "100%",
            maxWidth: "350px",
            padding: "0 24px",
            textAlign: "center",
          }}
        >
          <Separator px={32} />
          <TypographyMUI className="TypographyRegular11pt">
            Ayudá a más personas compartiendo
          </TypographyMUI>
          <ButtonMUI
            style={{
              width: "200px",
              marginTop: "16px",
              background: COLOR_SECONDARY,
            }}
            variant="contained"
            color="primary"
            onClick={onShare}
          >
            Compartir
          </ButtonMUI>
          <Separator px={8} />
        </BoxMUI>
      </BoxMUI>
      <BoxMUI
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          background: "#ffffff",
        }}
      >
        <BoxMUI
          style={{
            width: "100%",
            maxWidth: "350px",
            padding: "0 24px",
            textAlign: "center",
          }}
        >
          <Separator px={32} />
          <TypographyMUI className="TypographyBold20pt">
            ¡Descargá la app!
          </TypographyMUI>
          <TypographyMUI className="TypographyRegular11pt">
            Disponible en tiendas oficiales
          </TypographyMUI>
          <Separator px={16} />
          <BoxMUI
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <img
              style={{
                width: "45%",
                maxWidth: "150px",
              }}
              src={Imgs.btnGoogleStore}
              alt="Play Store"
              onClick={() => window.open(config.linkStore.android, "_blank")}
            />
            <Separator px={16} orientation="vertical" />
            <img
              style={{
                width: "45%",
                maxWidth: "150px",
              }}
              src={Imgs.btnAppleStore}
              alt="App Store"
              onClick={() => window.open(config.linkStore.ios, "_blank")}
            />
          </BoxMUI>
          <Separator px={48} />
        </BoxMUI>
      </BoxMUI>
      <BoxMUI
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          background: "#ffffff",
        }}
      >
        <BoxMUI
          style={{
            width: "100%",
            maxWidth: "350px",
            padding: "0 24px",
            paddingBottom: "120px",
            textAlign: "center",
          }}
        >
          <TypographyMUI className="TypographyRegular11pt">
            <a style={{ color: "inherit" }} href={URL.UPOOL}>
              uPool © {new Date().getFullYear()}
            </a>
            <span> | </span>
            <a style={{ color: "inherit" }} href="https://deltait.com.ar">
              Delta IT
            </a>
          </TypographyMUI>
          <Separator px={8} />
          <TypographyMUI className="TypographyRegular11pt">
            <a
              style={{ color: "inherit" }}
              href={`${URL.UPOOL}/terminos-condiciones.html`}
            >
              Términos y condiciones
            </a>
          </TypographyMUI>
        </BoxMUI>
      </BoxMUI>
    </>
  );
};
