import React from "react";
import {
  CardMUI,
  CardContentMUI,
  TypographyMUI,
  Separator,
  AvatarMUI,
  BoxMUI,
  FormControlLabelMUI,
  CheckboxMUI,
} from "../../DeltaUI/Components";
import { DriveEtaIcon } from "../../DeltaUI/Components/CustomIcon";
import { IVehicle } from "../../Services/VehicleService";
import AvailablePlaces from "../AvailablePlaces";

export interface CardVehiclePlacesProps {
  vehicle: IVehicle;
  places: number;
  onPlaces: (places: number) => void;
  min?: number;
  freeSeatMiddle: boolean;
  onFreeSeatMiddle: (places: boolean) => void;
  edit?: boolean;
}

const CardVehiclePlaces: React.FC<CardVehiclePlacesProps> = (props) => {
  const {
    vehicle,
    places,
    onPlaces,
    min,
    freeSeatMiddle,
    onFreeSeatMiddle,
    edit,
  } = props;

  return (
    <CardMUI
      sx={{
        margin: "24px",
      }}
      key={vehicle.id}
      title={vehicle.licensePlate}
      elevation={6}
    >
      <CardContentMUI
        sx={{
          padding: "24px",
        }}
        className="FlexColumn FlexCenter"
      >
        <BoxMUI
          style={{
            display: "flex",
            width: "100%",
          }}
        >
          <AvatarMUI
            sx={{
              width: "50px",
              height: "50px",
              marginRight: "16px",
              background: (theme) => theme.palette.primary.main,
            }}
          >
            <DriveEtaIcon />
          </AvatarMUI>
          <BoxMUI className="FlexColumn">
            <TypographyMUI className="TypographyBold11pt">
              {vehicle.model.brand.name} {vehicle.model.name}
            </TypographyMUI>
            <Separator px={4} />
            <BoxMUI className="FlexRow" style={{ alignItems: "baseline" }}>
              <TypographyMUI className="TypographyBold11pt">
                Patente:
              </TypographyMUI>
              <Separator px={4} orientation="vertical" />
              <TypographyMUI>{vehicle.licensePlate}</TypographyMUI>
            </BoxMUI>
          </BoxMUI>
        </BoxMUI>
        <Separator px={16} divider />
        <TypographyMUI
          className="TypographyBold11pt"
          style={{ textAlign: "center" }}
        >
          Asientos disponibles
        </TypographyMUI>
        <Separator px={16} />
        <AvailablePlaces value={places} min={min} onChange={onPlaces} />
        {!edit ? (
          <BoxMUI
            sx={{
              "& .MuiFormControlLabel-root": {
                marginRight: 0,
              },
            }}
          >
            <Separator px={24} />
            <FormControlLabelMUI
              control={
                <CheckboxMUI
                  checked={freeSeatMiddle}
                  onChange={(ev) => onFreeSeatMiddle(ev.target.checked)}
                />
              }
              sx={{
                "& .MuiTypography-root": {
                  fontSize: "10pt !important",
                },
              }}
              label="Dejo asiento libre en el medio para mayor comodidad de mis pasajeros"
            />
          </BoxMUI>
        ) : null}
      </CardContentMUI>
    </CardMUI>
  );
};

export default CardVehiclePlaces;
