import { ApolloError } from "@apollo/client";
import { DocumentNode, GraphQLErrorExtensions } from "graphql";

export interface IRequestError {
  status: boolean;
  error_code: string;
  message: string;
}

export interface IRequest {
  name: string;
  gql: DocumentNode;
}

export type ApolloServiceError = ApolloError & GraphQLErrorExtensions & Error;

export const parseError = (err: ApolloServiceError | ApolloError) => {
  const extensions = err.graphQLErrors.length
    ? err.graphQLErrors[0]?.extensions
    : undefined;
  return { ...err, ...extensions };
};

export const resultQuery = async (
  data: any,
  unions?: boolean
): Promise<any | any[]> => {
  return new Promise((resolve, reject) => {
    const result = async (
      responseQuery: any,
      success: Function,
      error: Function
    ) => {
      try {
        const response: any | any[] = responseQuery;

        if (Array.isArray(response) && !response.length) {
          success(response);
        } else if (!response) {
          success(response);
        } else {
          if (unions) {
            const typename: any =
              Array.isArray(response) && response.length
                ? response[0]?.__typename
                : response?.__typename;
            if (!typename) {
              error({
                status: false,
                error_code: null,
                message: 'Error: data incorrecta pasado a "resultQuery".',
              });
            } else if (typename && typename === "ResultError") {
              if (Array.isArray(response) && response.length) {
                error({
                  status: response[0].status,
                  error_code: response[0].error_code,
                  message: response[0].message,
                });
              } else {
                error({
                  status: response.status,
                  error_code: response.error_code,
                  message: response.message,
                });
              }
            } else {
              success(response);
            }
          } else {
            success(response);
          }
        }
      } catch (err: any) {
        error({
          status: false,
          error_code: null,
          message: err.message,
        });
      }
    };
    result(
      data,
      (success: any) => {
        return resolve(success);
      },
      (err: IRequestError) => {
        return reject(err);
      }
    );
  });
};
