import React from "react";
import {
  CardMUI,
  BoxMUI,
  TypographyMUI,
  CardActionAreaMUI,
} from "../../DeltaUI/Components";

export interface CardActionProps {
  style?: React.CSSProperties;
  className?: string;
  icon: JSX.Element;
  label: string;
  onClick?: () => void;
  elevation?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7;
}

const CardAction: React.FC<CardActionProps> = (props) => {
  const { style, className, icon, label, elevation, onClick } = props;

  return (
    <CardMUI
      className={`${className} ShadowCard12`}
      style={{
        minWidth: "148px",
        maxWidth: "148px",
        padding: "16px 8px",
        borderRadius: "10px",
        ...style,
      }}
      sx={{
        "& .MuiTypography-root": {
          lineHeight: "24px",
        },
      }}
      elevation={elevation || 10}
    >
      <CardActionAreaMUI
        style={{
          display: "flex",
          flexDirection: "column",
        }}
        onClick={onClick}
      >
        <BoxMUI
          sx={{
            width: "100%",
            height: "80px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {icon}
        </BoxMUI>
        <BoxMUI
          style={{
            width: "100px",
            display: "flex",
            justifyContent: "center",
            marginTop: "16px",
            textAlign: "center",
          }}
        >
          <TypographyMUI
            className="TypographyExtrabold13pt"
            sx={{ color: (theme) => theme.palette.primary.main }}
          >
            {label}
          </TypographyMUI>
        </BoxMUI>
      </CardActionAreaMUI>
    </CardMUI>
  );
};

export default CardAction;
