import React from "react";
import { SetterState } from "../../Services/DefaultTypes";
import { IUserExistsOutput } from "../../Services/UserService";
import { useComponentMount } from "../../Utils";

export enum RECOVER_PASSWORD_STEPS {
  SEARCH_ACCOUNT,
  RECOVERY_OPTIONS,
  ENTRE_CODE,
  NEW_PASSWORD,
}

interface IRecoverPassword {
  step: number;
  setStep: SetterState<number>;
  userToRecover: IUserExistsOutput | undefined;
  setUserToRecover: SetterState<IUserExistsOutput | undefined>;
  valueType: "email" | "phone" | undefined;
  setValueType: SetterState<"email" | "phone" | undefined>;
  verificationToken: string | undefined;
  setVerificationToken: SetterState<string | undefined>;
}

const context: IRecoverPassword = {
  step: RECOVER_PASSWORD_STEPS.SEARCH_ACCOUNT,
  setStep: () => {},
  userToRecover: undefined,
  setUserToRecover: () => {},
  valueType: undefined,
  setValueType: () => {},
  verificationToken: "",
  setVerificationToken: () => {},
};

export const RecoverPasswordContext = React.createContext(context);

export interface RecoverPasswordProviderProps {}

export const RecoverPasswordProvider: React.FC<RecoverPasswordProviderProps> = (
  props
) => {
  const isMountedComponent = useComponentMount("RecoverPasswordProvider");
  const [step, setStep] = React.useState<number>(
    RECOVER_PASSWORD_STEPS.SEARCH_ACCOUNT
  );
  const [userToRecover, setUserToRecover] = React.useState<
    IUserExistsOutput | undefined
  >();
  const [valueType, setValueType] = React.useState<
    "email" | "phone" | undefined
  >();
  const [verificationToken, setVerificationToken] = React.useState<
    string | undefined
  >();

  React.useEffect(() => {
    if (userToRecover) {
      setStep(() => RECOVER_PASSWORD_STEPS.RECOVERY_OPTIONS);
      if (!valueType) {
        setValueType(() => (userToRecover?.email ? "email" : "phone"));
      }
    }
    // TODO: chequear estas dependencias
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userToRecover]);

  React.useEffect(() => {
    isMountedComponent.current = true;
    return () => {
      isMountedComponent.current = false;
    };
  }, [isMountedComponent]);

  return (
    <RecoverPasswordContext.Provider
      value={{
        step,
        setStep,
        userToRecover,
        setUserToRecover,
        valueType,
        setValueType,
        verificationToken,
        setVerificationToken,
      }}
    >
      {props.children}
    </RecoverPasswordContext.Provider>
  );
};
