import React from "react";
import {
  ListItemMUI,
  ListItemIconMUI,
  ListItemTextMUI,
  TypographyMUI,
  CalendarPicker,
} from "../../../DeltaUI/Components";
import { EventIcon, CloseIcon } from "../../../DeltaUI/Components/CustomIcon";
import { MomentJS } from "../../../Utils";
import {
  COLOR_TERTIARY,
  COLOR_SECONDARY,
  COLOR_PRIMARY,
} from "../../../Utils/Constants";
import { DialogCustom, DialogCustomFooterActions } from "../../DialogCustom";

export interface AdvancedFiltersInputCalendarProps {
  date?: Date;
  onChange?: (ev?: Date) => void;
  minDate?: Date;
  maxDate?: Date;
}

const AdvancedFiltersInputCalendar: React.FC<AdvancedFiltersInputCalendarProps> = (
  props
) => {
  const { date, onChange, minDate, maxDate } = props;
  const { getDescriptionDate2 } = MomentJS();
  const [viewCalendar, setViewCalendar] = React.useState<boolean>(false);

  const handleOnChange = (ev?: Date) => {
    if (onChange) {
      onChange(ev);
    }
  };

  return (
    <>
      <ListItemMUI
        className="ContentInputCustom"
        style={{
          width: "50%",
          height: "42px",
          padding: "8px",
        }}
      >
        <ListItemIconMUI
          style={{
            minWidth: "24px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "0 4px",
          }}
        >
          <EventIcon style={{ color: COLOR_TERTIARY }} />
        </ListItemIconMUI>
        <ListItemTextMUI
          style={{
            margin: "0",
          }}
          primary={
            <TypographyMUI
              className="TypographySemibold11pt"
              style={{ padding: "8px 0" }}
            >
              {date ? getDescriptionDate2(date, true) : "Sin fecha"}
            </TypographyMUI>
          }
          onClick={() => setViewCalendar(() => true)}
        />
        {date ? (
          <ListItemIconMUI
            style={{
              minWidth: "auto",
              padding: "3px 0",
              paddingRight: "4px",
            }}
            onClick={() => handleOnChange(undefined)}
          >
            <CloseIcon
              style={{
                fontSize: "20px",
                color: COLOR_PRIMARY,
              }}
            />
          </ListItemIconMUI>
        ) : null}
      </ListItemMUI>
      <DialogCustom
        context="AdvancedFiltersInputCalendar.1"
        open={viewCalendar}
        onClose={() => setViewCalendar(() => false)}
        body={
          <CalendarPicker
            startDate={date || new Date()}
            minDate={minDate}
            maxDate={maxDate}
            onChange={handleOnChange}
            header={false}
            color={COLOR_SECONDARY}
          />
        }
        footer={
          <DialogCustomFooterActions
            onOk={() => setViewCalendar(() => false)}
            onCancel={() => setViewCalendar(() => false)}
          />
        }
      />
    </>
  );
};

export default AdvancedFiltersInputCalendar;
