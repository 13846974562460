import React from "react";
import { ToolbarMUI, BodyPage, BoxMUI } from "../DeltaUI/Components";
import {
  RegisterContext,
  RegisterProvider,
  REGISTER_STEPS,
} from "../Components/Register/RegisterContext";
import { TitlePage } from "../Components/TitlePage";
import { SupplementaryData } from "../Components/Register/SupplementaryData";
import { EnterCode } from "../Components/Register/EnterCode";
import { RegisterPassword } from "../Components/Register/RegisterPassword";
import { RegisterForm } from "../Components/Register/RegisterForm";
import { GoBack } from "../Components/GoBack";
import { CustomPage } from "../Components";
import { AVAILABLE_SCREEN_SPACE_CSS } from "../Utils/Constants";

const RegisterPageContext: React.FC = () => {
  const { step, goBackRegister } = React.useContext(RegisterContext);
  return (
    <CustomPage
      headerPage={
        <ToolbarMUI>
          <GoBack onClick={goBackRegister} />
          {step !== REGISTER_STEPS.FORM ? (
            <TitlePage text="Crea tu cuenta" />
          ) : null}
        </ToolbarMUI>
      }
      headerPageProps={{
        className: step === REGISTER_STEPS.FORM ? "Transparent" : undefined,
      }}
    >
      <BodyPage m={3}>
        <BoxMUI
          style={{
            height: `calc(${AVAILABLE_SCREEN_SPACE_CSS} - 56px - 48px)`,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <BoxMUI
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {step === REGISTER_STEPS.FORM ? <RegisterForm /> : null}
            {step === REGISTER_STEPS.ENTRE_CODE ? <EnterCode /> : null}
            {step === REGISTER_STEPS.PASSWORD ? <RegisterPassword /> : null}
            {step === REGISTER_STEPS.SUPLEMENTARY_DATA ? (
              <SupplementaryData />
            ) : null}
          </BoxMUI>
        </BoxMUI>
      </BodyPage>
    </CustomPage>
  );
};

const RegisterPage: React.FC<{}> = () => {
  return (
    <RegisterProvider>
      <RegisterPageContext />
    </RegisterProvider>
  );
};

export default RegisterPage;
