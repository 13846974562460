import React from "react";
import {
  ButtonMUI,
  BoxMUI,
  Separator,
  TypographyMUI,
  FormInputPassword,
} from "../../DeltaUI/Components";
import { DialogCustomHook } from "..";
import { RegisterContext, REGISTER_STEPS } from "./RegisterContext";
import { useComponentMount } from "../../Utils";

export const RegisterPassword: React.FC = () => {
  const isMountedComponent = useComponentMount("RegisterPassword");
  const { messageWarn, messageError } = DialogCustomHook();
  const { userToRegister, setUserToRegister, setStep } = React.useContext(
    RegisterContext
  );
  const [password, setPassword] = React.useState<string>("");
  const [errorPassword, setErrorPassword] = React.useState<boolean>(false);
  const [repassword, setRepassword] = React.useState<string>("");
  const [errorRepassword, setErrorRepassword] = React.useState<boolean>(false);

  const handleChangePassword = () => {
    if (!userToRegister) {
      return messageError({
        context: "Register.RegisterPassword.1",
        message: "Faltan los datos de registro.",
      });
    }
    if (password !== repassword) {
      return messageWarn({
        context: "Register.RegisterPassword.2",
        message: "La contraseña no se repite bien.",
      });
    }
    setUserToRegister({ password });
    setStep(() => REGISTER_STEPS.SUPLEMENTARY_DATA);
  };

  React.useEffect(() => {
    isMountedComponent.current = true;
    return () => {
      isMountedComponent.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMountedComponent]);

  return (
    <>
      <BoxMUI
        style={{
          width: "100%",
        }}
      >
        <Separator px={8} />
        <TypographyMUI className="TypographyExtrabold11pt">
          Necesitarás una contraseña
        </TypographyMUI>
        <Separator px={16} />
        <TypographyMUI className="TypographyRegular11pt">
          Las contraseñas fuertes incluyen números, letras y caracteres
          especiales.
        </TypographyMUI>
        <Separator px={16} />
        <TypographyMUI className="TypographyRegular11pt">
          Asegurate de que tenga 8 caracteres o más.
        </TypographyMUI>
        <Separator px={16} />
        <FormInputPassword
          value={password}
          label="Contraseña"
          onChange={(text) => setPassword(() => text)}
          onError={(v) => setErrorPassword(() => v)}
        />
        <Separator px={8} />
        <FormInputPassword
          value={repassword}
          label="Repetir contraseña"
          onChange={(text) => setRepassword(() => text)}
          onError={(v) => setErrorRepassword(() => v)}
        />
      </BoxMUI>
      <BoxMUI
        style={{
          width: "100%",
        }}
      >
        <ButtonMUI
          style={{
            width: "100%",
            marginTop: "16px",
          }}
          variant="contained"
          color="primary"
          onClick={handleChangePassword}
          disabled={
            !password || !repassword || errorPassword || errorRepassword
          }
        >
          Continuar
        </ButtonMUI>
        <Separator px={32} />
      </BoxMUI>
    </>
  );
};
