import React from "react";
import { UpoolContext } from "../../Providers/UpoolContext";
import html2canvas from "html2canvas";
import { Clipboard } from "@capacitor/clipboard";
import { Tools, useComponentMount, useCustomLog } from "../../Utils";
import { Share } from "@capacitor/share";
import { Directory, Filesystem } from "@capacitor/filesystem";
import { ShareWeb } from "../../Utils/ShareWeb";
import { isDesktop } from "../../DeltaUI/Utils/Constants";
import {
  BoxMUI,
  ButtonMUI,
  Separator,
  TypographyMUI,
} from "../../DeltaUI/Components";
import {
  ContentCopyIcon,
  InsertPhotoOutlinedIcon,
  LinkOutlinedIcon,
} from "../../DeltaUI/Components/CustomIcon";
import { COLOR_TERTIARY } from "../../Utils/Constants";
import { DialogCustom } from "../DialogCustom";
import { useIonToast } from "@ionic/react";
import { AppContext } from "../../Providers/AppContext";
import { OnboardingShare } from "./OnboardingShare";

interface ShareComponentProps {
  titleModal?: string;
  onFinish: () => void;
  elementId: string;
  config: {
    url?: string;
    title?: string;
  };
}

export const ShareComponent: React.FC<ShareComponentProps> = (props) => {
  const isMountedComponent = useComponentMount("ShareComponent");
  const { titleModal, onFinish, elementId, config } = props;
  const { url, title } = config;
  const { setLoading } = React.useContext(UpoolContext);
  const { blobToFile } = Tools();
  const Log = useCustomLog();
  const [present] = useIonToast();
  const { share } = ShareWeb();
  const [file, setFile] = React.useState<File>();
  const [fileUri, setFileUri] = React.useState<string>();
  const [open, setOpen] = React.useState<boolean>(false);
  const { isTipToShare } = React.useContext(AppContext);
  const [viewTipToShare, setViewTipToShare] = React.useState<boolean>(false);
  const base64 = React.useRef<string>();
  const blob = React.useRef<Blob | null>(null);

  const captureScreen = async () => {
    if (isMountedComponent.current) {
      try {
        const type = "image/png";
        const quality = 1;
        const element = document.getElementById(elementId);

        setLoading(() => true);
        setViewTipToShare(() => false);

        if (!element) {
          throw new Error("No se encuentra el elemento " + elementId);
        }
        const canvas = await html2canvas(element);
        try {
          await Clipboard.write({
            string: url,
          });
        } catch (err: any) {
          Log.info({
            context: "ShareComponent.captureScreen.1",
            message: err.message,
          });
        }
        if (isDesktop) {
          const getBlob = (): Promise<Blob | null> =>
            new Promise((resolve) => {
              canvas.toBlob((b) => resolve(b), type, quality);
            });
          blob.current = await getBlob();
          if (blob.current) {
            const f = blobToFile(blob.current, "share.png");
            setFile(() => f);
          } else {
            throw new Error("Blob is null");
          }
        } else {
          base64.current = canvas.toDataURL(type, quality);
          const result = await Filesystem.writeFile({
            path: "screenshots/share.png",
            data: base64.current,
            directory: Directory.Cache,
            recursive: true,
          }).catch((err) => {
            throw new Error(err);
          });
          setFileUri(() => result.uri);
        }
      } catch (err: any) {
        Log.error({
          context: "ShareComponent.captureScreen.2",
          message: err.message,
        });
        setLoading(() => false);
        setOpen(() => true);
      }
    }
  };

  const handleCopyLink = async () => {
    try {
      setOpen(() => false);
      await Clipboard.write({
        string: url,
      });
      present({
        message: "Enlace copiado en el portapapeles",
        position: "top",
        color: "light",
        duration: 2000,
      });
    } catch (err: any) {
      Log.error({
        context: "ShareComponent.handleCopyLink.1",
        message: err.message,
      });
    } finally {
      isMountedComponent.current = false;
      onFinish();
    }
  };

  const handleShareLink = async () => {
    try {
      setOpen(() => false);
      setLoading(() => true);
      await Share.share({
        url: url,
        dialogTitle: "Compartir",
      }).catch((err) => {
        Log.info({
          context: "ShareComponent.handleShareLink.1",
          message: err.message,
        });
      });
    } catch (err: any) {
      Log.error({
        context: "ShareComponent.handleShareLink.2",
        message: err.message,
      });
    } finally {
      isMountedComponent.current = false;
      setLoading(() => false);
      onFinish();
    }
  };

  const handleShareImage = async () => {
    try {
      setOpen(() => false);
      setLoading(() => true);
      if (isMountedComponent.current && file) {
        await share({
          title,
          files: [file],
        });
      } else if (isMountedComponent.current && fileUri) {
        await Share.share({
          title,
          url: fileUri,
          dialogTitle: "Compartir",
        }).catch((err) => {
          throw new Error(err);
        });
      }
    } catch (err: any) {
      Log.error({
        context: "ShareComponent.handleShareImage.1",
        message: err.message,
      });
    } finally {
      isMountedComponent.current = false;
      setLoading(() => false);
      onFinish();
    }
  };

  React.useEffect(() => {
    if (isMountedComponent.current && file) {
      setLoading(() => false);
      setOpen(() => true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  React.useEffect(() => {
    if (isMountedComponent.current && fileUri) {
      setLoading(() => false);
      setOpen(() => true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileUri]);

  React.useEffect(() => {
    isMountedComponent.current = true;
    if (isTipToShare && !isDesktop) {
      setLoading(() => false);
      setViewTipToShare(() => true);
    } else {
      captureScreen();
    }
    return () => {
      isMountedComponent.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMountedComponent]);

  return (
    <>
      <DialogCustom
        context="DialogCancelMessageView.1"
        title={
          <>
            <TypographyMUI
              className="TypographyBold14pt"
              style={{ textAlign: "center" }}
            >
              {titleModal || "Opciones"}
            </TypographyMUI>
            <Separator px={8} />
          </>
        }
        open={open}
        body={
          <BoxMUI
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {file || fileUri ? (
              <>
                <ButtonShare
                  onClick={handleShareImage}
                  text="Compartir imagen + enlace"
                  icon={
                    <InsertPhotoOutlinedIcon
                      style={{ color: COLOR_TERTIARY }}
                    />
                  }
                />
                <Separator px={16} />
              </>
            ) : null}
            <ButtonShare
              onClick={handleShareLink}
              text="Compartir enlace"
              icon={<LinkOutlinedIcon style={{ color: COLOR_TERTIARY }} />}
            />
            <Separator px={16} />
            <ButtonShare
              onClick={handleCopyLink}
              text="Copiar enlace"
              icon={<ContentCopyIcon style={{ color: COLOR_TERTIARY }} />}
            />
          </BoxMUI>
        }
        footer={
          <BoxMUI style={{ padding: "16px 24px" }}>
            <ButtonMUI
              style={{ width: "100%" }}
              variant="outlined"
              color="primary"
              onClick={onFinish}
            >
              Cancelar
            </ButtonMUI>
          </BoxMUI>
        }
      />
      <OnboardingShare open={viewTipToShare} onNext={captureScreen} />
    </>
  );
};

interface ButtonShareProps {
  icon: JSX.Element;
  text: string;
  onClick?: () => void;
  disabled?: boolean;
}

export const ButtonShare: React.FC<ButtonShareProps> = (props) => {
  const { icon, text, onClick, disabled } = props;

  return (
    <>
      <ButtonMUI
        sx={{
          width: "100%",
          justifyContent: "start",
          "& .MuiButton-startIcon": {
            marginLeft: "8px",
          },
        }}
        variant="text"
        color="primary"
        startIcon={icon}
        onClick={onClick}
        disabled={disabled}
      >
        {text}
      </ButtonMUI>
    </>
  );
};
