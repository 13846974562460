import { useCustomApolloAsync } from "./GraphQL/ApolloService";
import { Mutation } from "./GraphQL/Mutation";
import { IReservationChat } from "./ReservationChatService";
import { IRate, ITripStretchResume } from "./TripService";
import { IUser } from "./UserService";

export const ReservationService = () => {
  const { mutationAsync } = useCustomApolloAsync();

  const createOneReservation = (variables: {
    data: { tripStretchId: number; quantity: number };
  }): Promise<{ id: number }> => {
    return mutationAsync({
      mutation: Mutation.createOneReservation,
      variables,
    });
  };

  const acceptOneReservation = (
    reservationId: number
  ): Promise<{ id: number }> => {
    return mutationAsync({
      mutation: Mutation.reviewOneReservation,
      variables: {
        data: { approve: true },
        where: { id: reservationId },
      },
    });
  };

  const cancelOneReservation = (
    reservationId: number,
    message: string
  ): Promise<{ id: number }> => {
    return mutationAsync({
      mutation: Mutation.cancelOneReservation,
      variables: {
        where: { id: reservationId },
        data: { message },
      },
    });
  };

  const deleteOneReservationToChat = (
    reservationId: number
  ): Promise<{ id: number }> => {
    return mutationAsync({
      mutation: Mutation.cancelOneReservation,
      variables: {
        where: { id: reservationId },
      },
    });
  };

  const rejectOneReservation = (
    reservationId: number
  ): Promise<{ id: number }> => {
    return mutationAsync({
      mutation: Mutation.reviewOneReservation,
      variables: {
        data: { approve: false },
        where: { id: reservationId },
      },
    });
  };

  const approveOneReservation = (
    reservationId: number,
    approve: boolean
  ): Promise<{ id: number }> => {
    if (approve) {
      return acceptOneReservation(reservationId);
    }
    return rejectOneReservation(reservationId);
  };

  const createOneReservationForChat = (
    tripStretchId: number
  ): Promise<{ id: number }> => {
    return mutationAsync({
      mutation: Mutation.createOneReservation,
      variables: {
        data: { tripStretchId },
      },
    });
  };

  return {
    createOneReservation,
    approveOneReservation,
    createOneReservationForChat,
    cancelOneReservation,
    deleteOneReservationToChat,
  };
};

export interface IReservation {
  id: number;
  userId: number;
  tripStretchId: number;
  placeIdFrom: number;
  placeIdTo: number;
  statusId: number;
  amount: number;
  quantity: number;
  confirmedByPassenger: boolean;
  confirmedByDriver: boolean;
  tripStretch: ITripStretchResume;
  user: IUser;
  chats: IReservationChat[];
  rates: IRate[];
  totalChats: number;
  unreadChats: number;
  deletedAt: Date;
  canceledMessage: string;
}
