import React from "react";
import {
  ToolbarMUI,
  MenuButton,
  BodyPage,
  BoxMUI,
  Tabs,
  ButtonGroup,
} from "../DeltaUI/Components";
import { UpoolContext } from "../Providers/UpoolContext";
import { TitlePage } from "../Components/TitlePage";
import {
  MyTripsContext,
  MY_TRIPS_TAB,
} from "../Components/MyTrips/MyTripsContext";
import SegmentRoleType from "../Components/User/SegmentRoleType";
import MyTripsStretches from "../Components/MyTrips/MyTripsStreches";
import { WelcomeToMyTrips } from "../Components/MyTrips/WelcomeToMyTrips";
import { COLOR_PRIMARY, COLOR_QUATERNARY } from "../Utils/Constants";
import { CustomPage } from "../Components";

const MyTripsPage: React.FC = () => {
  const { roleTypeDriver } = React.useContext(UpoolContext);
  const { myTripsTab, setMyTripsTab } = React.useContext(MyTripsContext);

  return (
    <CustomPage
      headerPage={
        <>
          <ToolbarMUI>
            <MenuButton style={{ color: COLOR_PRIMARY }} />
            <TitlePage text="Mis viajes" />
            <ButtonGroup slot="end">
              <SegmentRoleType />
            </ButtonGroup>
          </ToolbarMUI>
          <Tabs
            value={myTripsTab}
            indicatorColor="secondary"
            invertedColor={true}
            textColor={COLOR_QUATERNARY}
            defaultValue={MY_TRIPS_TAB.CURRENT}
            onChange={(ev) => setMyTripsTab(() => ev)}
            list={[
              {
                key: MY_TRIPS_TAB.CURRENT,
                label: "Próximos",
                onClick: () => {},
              },
              {
                key: MY_TRIPS_TAB.END,
                label: "Finalizados",
                onClick: () => {},
              },
            ]}
          />
        </>
      }
    >
      <WelcomeToMyTrips />
      <BodyPage>
        {!roleTypeDriver ? (
          <BoxMUI>
            {myTripsTab === MY_TRIPS_TAB.CURRENT ? <MyTripsStretches /> : null}
            {myTripsTab === MY_TRIPS_TAB.END ? (
              <MyTripsStretches finished={true} />
            ) : null}
          </BoxMUI>
        ) : null}
        {roleTypeDriver ? (
          <BoxMUI>
            {myTripsTab === MY_TRIPS_TAB.CURRENT ? <MyTripsStretches /> : null}
            {myTripsTab === MY_TRIPS_TAB.END ? (
              <MyTripsStretches finished={true} />
            ) : null}
          </BoxMUI>
        ) : null}
      </BodyPage>
    </CustomPage>
  );
};

export default MyTripsPage;
