import React from "react";
export interface ButtonGroupProps {
  slot: "start" | "end";
}

const ButtonGroup: React.FC<ButtonGroupProps> = (props) => {
  const { slot } = props;
  if (slot === "end") {
    return (
      <>
        <div
          style={{
            flexGrow: 1,
          }}
        />
        {props.children}
      </>
    );
  }
  return <>{props.children}</>;
};

export default ButtonGroup;
