import { IRequest } from "../utils";
import { useCustomApolloAsync } from "./useCustomApolloAsync";

type Level = "error" | "warn" | "info" | "http" | "verbose" | "debug" | "silly";

/**
 * Niveles de log de Winston
 */
enum LogLevel {
  ERROR = "error",
  WARN = "warn",
  INFO = "info",
  HTTP = "http",
  VERBOSE = "verbose",
  DEBUG = "debug",
  SILLY = "silly",
}

export const useCustomApolloLog = (options: {
  mutation: IRequest;
  logLevel: Level;
}) => {
  const { callMutation } = useCustomApolloAsync();
  const { mutation, logLevel } = options;

  const sendLog = (log: IRegisterLogInput) => {
    return callMutation({
      mutation,
      variables: {
        data: {
          ...log,
        },
      },
    }).catch((error: any) => {
      // eslint-disable-next-line no-console
      console.warn(error);
    });
  };

  const registerLog = async (log: IRegisterLog, level: Level) => {
    const { context, message = "", userId, deviceInfo } = log;
    const send = () => {
      try {
        delete log.stack;
        delete log.name;
        return sendLog({
          context,
          message,
          userId,
          deviceInfo,
          level,
        });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.warn(error);
        return new Promise((resolve) => {
          resolve(null);
        });
      }
    };

    if (logLevel) {
      // INFO
      if (
        logLevel === LogLevel.INFO &&
        (level === LogLevel.ERROR ||
          level === LogLevel.WARN ||
          level === LogLevel.INFO)
      ) {
        return send();
      }

      // WARN
      if (
        logLevel === LogLevel.WARN &&
        (level === LogLevel.ERROR || level === LogLevel.WARN)
      ) {
        return send();
      }

      // ERROR
      if (logLevel === LogLevel.ERROR && level === LogLevel.ERROR) {
        return send();
      }
    }
    return new Promise((resolve) => {
      resolve(null);
    });
  };

  const Logger = {
    error: (log: ILog) => {
      return registerLog(log, LogLevel.ERROR);
    },
    warn: (log: ILog) => {
      return registerLog(log, LogLevel.WARN);
    },
    info: (log: ILog) => {
      return registerLog(log, LogLevel.INFO);
    },
  };

  return {
    Logger,
  };
};

export interface ILog {
  context: string;
  message: string;
  userId?: string;
  deviceInfo?: string;
}

interface IRegisterLog {
  context: string;
  message: string;
  userId?: string;
  deviceInfo?: string;
  stack?: string;
  name?: string;
}

interface IRegisterLogInput {
  context: string;
  message: string;
  level: string;
  userId?: string;
  deviceInfo?: string;
}
