import React from "react";
import { SearchTripContext } from "./SearchTripContext";
import { BoxMUI, Separator, TypographyMUI } from "../../DeltaUI/Components";
import { useComponentMount } from "../../Utils";

export interface SearchTripTitleProps {}

const SearchTripTitle: React.FC<SearchTripTitleProps> = (props) => {
  const isMountedComponent = useComponentMount("SearchTripTitle");
  const { originPlace, destinationPlace } = React.useContext(SearchTripContext);
  const [isFirstSearch, setFirstSearch] = React.useState<boolean>(true);

  React.useEffect(() => {
    if (originPlace || destinationPlace) {
      setFirstSearch(false);
    }
  }, [originPlace, destinationPlace]);

  React.useEffect(() => {
    isMountedComponent.current = true;
    return () => {
      isMountedComponent.current = false;
    };
  }, [isMountedComponent]);

  return (
    <>
      {isFirstSearch === true ? (
        <BoxMUI
          style={{
            width: "100vw",
            position: "absolute",
            top: 72,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <BoxMUI
            style={{
              width: "100vw",
              padding: "0 24px",
            }}
          >
            <Separator px={32} />
            <TypographyMUI
              className="TypographyExtrabold26pt"
              sx={{ lineHeight: "24px" }}
            >
              ¿A dónde
            </TypographyMUI>
            <TypographyMUI className="TypographyExtrabold26pt">
              querés viajar?
            </TypographyMUI>
          </BoxMUI>
        </BoxMUI>
      ) : null}
    </>
  );
};

export default SearchTripTitle;
