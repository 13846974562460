import React from "react";
import { Imgs } from "../Assets";
import { BoxMUI } from "../DeltaUI/Components";
import { ExceptionManager } from "../DeltaUI/Utils/ExceptionManager";
import { UpoolContext } from "./UpoolContext";

export const ExceptionManagerLibrary: React.FC = (props) => {
  const { setUser, setViewSplashScreen } = React.useContext(UpoolContext);

  const retry = () => {
    setUser(() => undefined);
    setViewSplashScreen(() => true);
  };

  return (
    <ExceptionManager
      context="ExceptionManagerLibrary.1"
      backgroundImage={Imgs.upoolSplashScreen}
      onClick={retry}
      logo={
        <BoxMUI
          style={{
            width: "60vw",
            maxWidth: "400px",
          }}
        >
          <img src={Imgs.logoWhite} alt="logo" width="100%" />
        </BoxMUI>
      }
    >
      {props.children}
    </ExceptionManager>
  );
};
