import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { MyTripsProvider } from "../Components/MyTrips/MyTripsContext";
import { SearchTripProvider } from "../Components/SearchTrip/SearchTripContext";
import SearchTripResults from "../Components/SearchTrip/SearchTripResults";
import { UpoolContext } from "../Providers/UpoolContext";
import { DETAIL_TRIP_MODE } from "../Utils/Constants";
import ChatPage from "./ChatPage";
import DetailTripPage from "./DetailTripPage";
import SearchTripPage from "./SearchTripPage";
import ViewUserNavTree from "./User/ViewUserNavTree";

const SearchTripNavTreeSwitch: React.FC = () => {
  const { selectPathTab } = React.useContext(UpoolContext);
  const defaultPath = `${selectPathTab}/default`;
  const searchTripResultsPath = `${selectPathTab}/result`;
  const searchTripDetailPath = `${selectPathTab}/detail-trip`;
  const searchTripChatPath = `${selectPathTab}/chat`;
  const searchTripUserPath = `${selectPathTab}/user`;

  return (
    <Switch>
      <Route exact path={selectPathTab}>
        <Redirect to={defaultPath} />
      </Route>
      <Route exact path={defaultPath} component={SearchTripPage} />
      <Route exact path={searchTripResultsPath} component={SearchTripResults} />
      <Route exact path={searchTripDetailPath} component={DetailTripPage} />
      <Route exact path={searchTripChatPath} component={ChatPage} />
      <Route path={searchTripUserPath} component={ViewUserNavTree} />
    </Switch>
  );
};

const SearchTripNavTree: React.FC<{}> = () => {
  return (
    <MyTripsProvider detailTripMode={DETAIL_TRIP_MODE.SEARCH_TRIP}>
      <SearchTripProvider>
        <SearchTripNavTreeSwitch />
      </SearchTripProvider>
    </MyTripsProvider>
  );
};

export default SearchTripNavTree;
