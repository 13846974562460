import React from "react";
import {
  ButtonMUI,
  BoxMUI,
  Separator,
  TypographyMUI,
} from "../../DeltaUI/Components";
import { DialogCustomHook } from "..";
import { RegisterContext } from "./RegisterContext";
import { UpoolContext } from "../../Providers/UpoolContext";
import { UserService } from "../../Services/UserService";
import { SelectBirthdate } from "../User/SelectBirthdate";
import { SelectPersonGender } from "../User/SelectPersonGender";
import UserUploadAvatar from "../User/UserUploadAvatar";
import { useComponentMount } from "../../Utils";

export const SupplementaryData: React.FC = () => {
  const isMountedComponent = useComponentMount("SupplementaryData");
  const {
    setLoading,
    setViewOnboarding,
    setUploadProfilePictureFile,
  } = React.useContext(UpoolContext);
  const { signup } = UserService();
  const { messageError } = DialogCustomHook();
  const { userToRegister, setUserToRegister } = React.useContext(
    RegisterContext
  );

  const handleSignup = async () => {
    const error = () => {
      messageError({
        context: "Register.SupplementaryData.1",
        message: "Faltan datos importantes para poder finalizar registro.",
      });
    };
    if (!userToRegister) {
      return error();
    }
    const {
      firstName,
      lastName,
      password,
      birthdate,
      genderId,
      emailOrPhone,
      valueType,
      facebookId,
      appleId,
      googleId,
      pictureFile,
      verified,
      verificationToken,
    } = userToRegister;
    const optional: any = {};

    if (googleId) {
      optional.googleId = googleId;
    }
    if (facebookId) {
      optional.facebookId = facebookId;
    }
    if (appleId) {
      optional.appleId = appleId;
    }
    if (password) {
      optional.password = password;
    }
    if (valueType === "email") {
      optional.email = emailOrPhone;
    }
    if (valueType === "phone") {
      optional.phone = emailOrPhone;
    }
    if (verified && verificationToken) {
      optional.verificationToken = verificationToken;
    }

    const manual =
      password &&
      emailOrPhone &&
      valueType &&
      !facebookId &&
      !googleId &&
      !appleId;
    if (
      firstName &&
      lastName &&
      birthdate &&
      genderId &&
      (manual || facebookId || googleId || appleId)
    ) {
      setLoading(() => true);
      signup({
        firstName,
        lastName,
        birthdate,
        genderId,
        ...optional,
      })
        .then(() => {
          setUploadProfilePictureFile(() => pictureFile);
          setViewOnboarding(() => true);
        })
        .catch(({ message }) => {
          messageError({
            context: "Register.SupplementaryData.2",
            message,
          });
          setLoading(() => false);
        });
    } else {
      return error();
    }
  };

  React.useEffect(() => {
    isMountedComponent.current = true;
    return () => {
      isMountedComponent.current = false;
    };
  }, [isMountedComponent]);

  return (
    <>
      <BoxMUI
        style={{
          width: "100%",
        }}
      >
        <Separator px={32} />
        <BoxMUI
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <UserUploadAvatar
            src={userToRegister?.pictureUrl}
            onChange={(pictureUrl) =>
              setUserToRegister({ pictureUrl, pictureFile: undefined })
            }
            size={13}
          />
        </BoxMUI>
        <Separator px={48} />
        <TypographyMUI className="TypographyExtrabold11pt">
          Datos complementarios
        </TypographyMUI>
        <Separator px={8} />
        <form noValidate autoComplete="off">
          <SelectBirthdate
            value={userToRegister?.birthdate}
            onChange={(birthdate) => setUserToRegister({ birthdate })}
            style={{
              width: "100%",
              margin: "8px 0",
            }}
          />
          <SelectPersonGender
            value={userToRegister?.genderId}
            onChange={(genderId) => setUserToRegister({ genderId })}
            onLoading={(v) => setLoading(() => v)}
            onError={messageError}
            style={{
              width: "100%",
              margin: "8px 0",
            }}
          />
        </form>
      </BoxMUI>
      <BoxMUI
        style={{
          width: "100%",
        }}
      >
        <ButtonMUI
          style={{
            width: "100%",
            marginTop: "16px",
          }}
          variant="contained"
          color="primary"
          onClick={handleSignup}
          disabled={!userToRegister?.birthdate || !userToRegister?.genderId}
        >
          Finalizar
        </ButtonMUI>
        <Separator px={32} />
      </BoxMUI>
    </>
  );
};
