import React from "react";
import { BoxMUI } from "..";

export interface SeparatorProps {
  px: 0 | 4 | 8 | 12 | 16 | 24 | 32 | 48;
  divider?: boolean;
  orientation?: "vertical" | "horizontal";
  style?: React.CSSProperties;
  color?: "primary" | "secondary";
}

const Separator: React.FC<SeparatorProps> = (props) => {
  const { divider, orientation, style, color } = props;
  if (orientation === "vertical") {
    if (divider) {
      return (
        <BoxMUI style={{ height: "100%", display: "flex" }}>
          <BoxMUI
            style={{ minWidth: props.px || `${props.px}px`, height: "100%" }}
          />
          <BoxMUI
            style={{ height: "100%", width: "1px", ...style }}
            sx={{
              background: (theme) =>
                color ? theme.palette[color].main : "#e0e0e0",
            }}
          />
          <BoxMUI
            style={{ minWidth: props.px || `${props.px}px`, height: "100%" }}
          />
        </BoxMUI>
      );
    }
    return (
      <BoxMUI
        style={{ minWidth: props.px || `${props.px}px`, height: "100%" }}
      />
    );
  }
  if (divider) {
    return (
      <BoxMUI
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <BoxMUI
          style={{ width: "100%", minHeight: props.px || `${props.px}px` }}
        />
        <BoxMUI
          style={{ width: "100%", height: "1px", ...style }}
          sx={{
            background: (theme) =>
              color ? theme.palette[color].main : "#e0e0e0",
          }}
        />
        <BoxMUI
          style={{ width: "100%", minHeight: props.px || `${props.px}px` }}
        />
      </BoxMUI>
    );
  }
  return (
    <BoxMUI style={{ width: "100%", minHeight: props.px || `${props.px}px` }} />
  );
};

export default Separator;
