import { useCustomApolloAsync } from "./GraphQL/ApolloService";
import { Mutation } from "./GraphQL/Mutation";
import { Query } from "./GraphQL/Query";

export const VehicleService = () => {
  const { queryAsync, mutationAsync } = useCustomApolloAsync();

  const vehicleBrands = (): Promise<IVehicleBrand[]> => {
    return queryAsync({
      query: Query.vehicleBrands,
      variables: {},
    });
  };

  const vehicleModels = (brandId: number): Promise<IVehicleModel[]> => {
    return queryAsync({
      query: Query.vehicleModels,
      variables: {
        where: {
          brand: {
            id: {
              equals: brandId,
            },
          },
        },
      },
    });
  };

  const colours = (): Promise<IColour[]> => {
    return queryAsync({
      query: Query.colours,
    });
  };

  const createOneVehicle = (data: any) => {
    return mutationAsync({
      mutation: Mutation.createOneVehicle,
      variables: {
        data,
      },
    });
  };

  const deleteOneVehicle = (id: number) => {
    return mutationAsync({
      mutation: Mutation.deleteOneVehicle,
      variables: {
        data: {
          id,
        },
      },
    });
  };

  return {
    vehicleBrands,
    vehicleModels,
    createOneVehicle,
    colours,
    deleteOneVehicle,
  };
};

export interface IVehicle {
  id: number;
  userId: number;
  licensePlate: string;
  description: string;
  colour: IColour;
  modelId: number;
  deletedAt?: Date;
  model: IVehicleModel;
}

export interface IVehicleFuelType {
  id: number;
  name: string;
  description: string;
  referenceCost: number;
}

export interface IVehicleModel {
  id: number;
  name: string;
  brandId: number;
  brand: IVehicleBrand;
}

export interface IVehicleBrand {
  id: number;
  name: string;
}

export interface IColour {
  id: number;
  name: string;
  code: string;
}
