import React from "react";
import { BoxMUI, TypographyMUI } from "../../DeltaUI/Components";

interface SquareButtonProps {
  style?: React.CSSProperties;
  className?: string;
  label?: string;
  icon?: JSX.Element;
  active?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  color?: "primary" | "secondary";
}

export const SquareButton: React.FC<SquareButtonProps> = (props) => {
  const { style, className, label, icon, active, disabled, onClick, color } =
    props;

  const isActive = React.useMemo(() => {
    return active === undefined ? true : active;
  }, [active]);

  return (
    <BoxMUI
      style={style}
      className={className}
      sx={{
        width: "100px",
        height: "100px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: "8px",
        border: (theme) =>
          isActive
            ? `1px solid ${theme.palette[color || "primary"].main}`
            : `1px solid #555`,
        borderRadius: "10px",
        bgcolor: (theme) =>
          isActive ? `${theme.palette[color || "primary"].main}17` : "inherit",
        opacity: disabled || !isActive ? 0.5 : 1,
      }}
      onClick={disabled ? () => {} : onClick}
    >
      <BoxMUI
        sx={{
          "& .MuiSvgIcon-root": {
            color: (theme) =>
              isActive ? theme.palette[color || "primary"].main : "inherit",
            fontSize: "40px",
          },
        }}
      >
        {icon}
      </BoxMUI>
      <BoxMUI>
        <TypographyMUI
          sx={{
            color: (theme) =>
              isActive ? theme.palette[color || "primary"].main : "inherit",
          }}
          variant="body2"
        >
          {label}
        </TypographyMUI>
      </BoxMUI>
    </BoxMUI>
  );
};
