import React from "react";
import { RenderListAsync } from "../../DeltaUI/Components";
import { IVehicle } from "../../Services/VehicleService";
import { UpoolContext } from "../../Providers/UpoolContext";
import CardVehicle from "./CardVehicle";
import EmptyData from "../EmptyData";
export interface MyVehiclesProps {
  onChange?: (vehicle?: IVehicle) => void;
}

const MyVehicles: React.FC<MyVehiclesProps> = (props) => {
  const { onChange } = props;
  const { user, setUser } = React.useContext(UpoolContext);

  const handleOnChange = (v?: IVehicle) => {
    if (onChange) {
      onChange(v);
    }
  };

  const handleOnDelete = (id: number) => {
    if (user) {
      setUser((cUser) => {
        if (cUser) {
          return {
            ...cUser,
            vehicles: cUser.vehicles.filter((v) => v.id !== id),
          };
        }
        return cUser;
      });
    }
  };

  return (
    <div>
      {user && user.vehicles.length ? (
        <RenderListAsync
          timeout={0}
          list={user.vehicles.map((v) => (
            <CardVehicle
              key={v.id}
              vehicle={v}
              onClick={() => handleOnChange(v)}
              isOptions={true}
              onDelete={handleOnDelete}
            />
          ))}
        />
      ) : (
        <EmptyData height="45vh" description="No tenes vehículos" />
      )}
    </div>
  );
};

export default MyVehicles;
