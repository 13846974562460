import React from "react";
import { useComponentMount } from "../Utils";

interface IEvent {
  count: number;
  data?: any;
}

interface IEventArray {
  count: number;
  data: any[];
}

interface IEventAppContext {
  eventLogin: IEvent;
  handleEventLogin: (data?: any) => void;
  eventUpdateTrip: IEvent;
  handleEventUpdateTrip: (data?: any) => void;
  eventNotificationMarkAsAttended: IEventArray;
  handleEventNotificationMarkAsAttended: (data: any[]) => void;
  eventNotificationTrip: IEvent;
  handleEventNotificationTrip: (data?: any) => void;
  eventAndroidGoBackButton: IEvent;
  handleEventAndroidGoBackButton: (data?: any) => void;
  eventAndroidGoAppStateChange: IEvent;
  handleEventAndroidGoAppStateChange: (data?: any) => void;
  eventSaveListOfMessagesToSend: IEvent;
  handleEventSaveListOfMessagesToSend: (data?: any) => void;
  eventWithoutConnection: IEvent;
  handleEventWithoutConnection: (data?: any) => void;
  eventUnauthenticatedUser: IEvent;
  handleEventUnauthenticatedUser: (data?: any) => void;
  eventInfiniteLoop: IEvent;
  handleEventInfiniteLoop: (data?: any) => void;
}

const context: IEventAppContext = {
  eventLogin: null as any,
  handleEventLogin: () => {},
  eventUpdateTrip: null as any,
  handleEventUpdateTrip: () => {},
  eventNotificationMarkAsAttended: null as any,
  handleEventNotificationMarkAsAttended: () => {},
  eventNotificationTrip: null as any,
  handleEventNotificationTrip: () => {},
  eventAndroidGoBackButton: null as any,
  handleEventAndroidGoBackButton: () => {},
  eventAndroidGoAppStateChange: null as any,
  handleEventAndroidGoAppStateChange: () => {},
  eventSaveListOfMessagesToSend: null as any,
  handleEventSaveListOfMessagesToSend: () => {},
  eventWithoutConnection: null as any,
  handleEventWithoutConnection: () => {},
  eventUnauthenticatedUser: null as any,
  handleEventUnauthenticatedUser: () => {},
  eventInfiniteLoop: null as any,
  handleEventInfiniteLoop: () => {},
};

export const EventAppContext = React.createContext(context);

export interface EventAppProviderProps {}

export const EventAppProvider: React.FC<EventAppProviderProps> = (props) => {
  const isMountedComponent = useComponentMount("EventAppProvider");

  const [eventLogin, setEventLogin] = React.useState<IEvent>({
    count: 0,
    data: null,
  });
  const handleEventLogin = (data?: any) => {
    if (isMountedComponent.current) {
      setEventLogin((e) => ({ data, count: e.count + 1 }));
    }
  };

  const [eventUpdateTrip, setEventUpdateTrip] = React.useState<IEvent>({
    count: 0,
    data: null,
  });
  const handleEventUpdateTrip = (data?: any) => {
    if (isMountedComponent.current) {
      setEventUpdateTrip((e) => ({ data, count: e.count + 1 }));
    }
  };

  const [
    eventNotificationMarkAsAttended,
    setEventNotificationMarkAsAttended,
  ] = React.useState<IEventArray>({ count: 0, data: [] });
  const handleEventNotificationMarkAsAttended = (data: any[]) => {
    if (isMountedComponent.current) {
      setEventNotificationMarkAsAttended((e) => ({ data, count: e.count + 1 }));
    }
  };

  const [
    eventNotificationTrip,
    setEventNotificationTrip,
  ] = React.useState<IEvent>({ count: 0, data: null });
  const handleEventNotificationTrip = (data?: any) => {
    if (isMountedComponent.current) {
      setEventNotificationTrip((e) => ({ data, count: e.count + 1 }));
    }
  };

  const [
    eventAndroidGoBackButton,
    setEventAndroidGoBackButton,
  ] = React.useState<IEvent>({ count: 0, data: null });
  const handleEventAndroidGoBackButton = (data?: any) => {
    if (isMountedComponent.current) {
      setEventAndroidGoBackButton((e) => ({ data, count: e.count + 1 }));
    }
  };

  const [
    eventAndroidGoAppStateChange,
    setEventAndroidGoAppStateChange,
  ] = React.useState<IEvent>({ count: 0, data: null });
  const handleEventAndroidGoAppStateChange = (data?: any) => {
    if (isMountedComponent.current) {
      setEventAndroidGoAppStateChange((e) => ({ data, count: e.count + 1 }));
    }
  };

  const [
    eventSaveListOfMessagesToSend,
    setSaveListOfMessagesToSend,
  ] = React.useState<IEvent>({
    count: 0,
    data: null,
  });
  const handleEventSaveListOfMessagesToSend = (data?: any) => {
    if (isMountedComponent.current) {
      setSaveListOfMessagesToSend((e) => ({ data, count: e.count + 1 }));
    }
  };

  const [eventWithoutConnection, setWithoutConnection] = React.useState<IEvent>(
    {
      count: 0,
      data: null,
    }
  );
  const handleEventWithoutConnection = (data?: any) => {
    if (isMountedComponent.current) {
      setWithoutConnection((e) => ({ data, count: e.count + 1 }));
    }
  };

  const [
    eventUnauthenticatedUser,
    setEventUnauthenticatedUser,
  ] = React.useState<IEvent>({
    count: 0,
    data: null,
  });
  const handleEventUnauthenticatedUser = (data?: any) => {
    if (isMountedComponent.current) {
      setEventUnauthenticatedUser((e) => ({ data, count: e.count + 1 }));
    }
  };

  const [eventInfiniteLoop, setEventInfiniteLoop] = React.useState<IEvent>({
    count: 0,
    data: null,
  });
  const handleEventInfiniteLoop = (data?: any) => {
    if (isMountedComponent.current) {
      setEventInfiniteLoop((e) => ({ data, count: e.count + 1 }));
    }
  };

  React.useEffect(() => {
    isMountedComponent.current = true;
    return () => {
      isMountedComponent.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <EventAppContext.Provider
      value={{
        eventLogin,
        handleEventLogin,
        eventUpdateTrip,
        handleEventUpdateTrip,
        eventNotificationMarkAsAttended,
        handleEventNotificationMarkAsAttended,
        eventNotificationTrip,
        handleEventNotificationTrip,
        eventAndroidGoBackButton,
        handleEventAndroidGoBackButton,
        eventAndroidGoAppStateChange,
        handleEventAndroidGoAppStateChange,
        eventSaveListOfMessagesToSend,
        handleEventSaveListOfMessagesToSend,
        eventWithoutConnection,
        handleEventWithoutConnection,
        eventUnauthenticatedUser,
        handleEventUnauthenticatedUser,
        eventInfiniteLoop,
        handleEventInfiniteLoop,
      }}
    >
      {props.children}
    </EventAppContext.Provider>
  );
};
