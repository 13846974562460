import React from "react";
import {
  ListItemMUI,
  ListItemTextMUI,
  TypographyMUI,
} from "../../../DeltaUI/Components";
import { SearchTripContext } from "../SearchTripContext";
import PreferencesListFilter from "../PreferencesListFilter";
import { DialogCustom, DialogCustomFooterActions } from "../../DialogCustom";
import { IPreference } from "../../../Services/PreferenceService";
import { VIEW_MAX_PREFERENCES } from "../../../Utils/Constants";
import { useComponentMount } from "../../../Utils";

export interface AdvancedFiltersPreferencesProps {}

const AdvancedFiltersPreferences: React.FC<AdvancedFiltersPreferencesProps> = (
  props
) => {
  const isMountedComponent = useComponentMount("AdvancedFiltersPreferences");
  const { preferencesIncluded, setPreferencesIncluded } = React.useContext(
    SearchTripContext
  );

  // viewAll
  const [
    viewAllPreferencesIncluded,
    setViewAllPreferencesIncluded,
  ] = React.useState<boolean>(false);

  const [
    preferencesIncludedLocal,
    setPreferencesIncludedLocal,
  ] = React.useState<IPreference[]>([]);

  const handleOnCancelIncluded = () => {
    setViewAllPreferencesIncluded(() => false);
  };

  const handleOnOkIncluded = () => {
    setPreferencesIncluded(() => preferencesIncludedLocal);
    handleOnCancelIncluded();
  };

  React.useEffect(() => {
    if (viewAllPreferencesIncluded) {
      setPreferencesIncludedLocal(() =>
        JSON.parse(JSON.stringify(preferencesIncluded))
      );
    }
    // TODO: chequear estas dependencias
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewAllPreferencesIncluded]);

  React.useEffect(() => {
    isMountedComponent.current = true;
    return () => {
      isMountedComponent.current = false;
    };
  }, [isMountedComponent]);

  return (
    <>
      <ListItemMUI button style={{ paddingBottom: 0 }}>
        <ListItemTextMUI
          primary={
            <TypographyMUI className="TypographyExtrabold11pt">
              Se permite
            </TypographyMUI>
          }
        />
      </ListItemMUI>
      <ListItemMUI>
        <PreferencesListFilter
          preferencesList={preferencesIncluded}
          setPreferencesList={setPreferencesIncluded}
          max={VIEW_MAX_PREFERENCES}
        />
      </ListItemMUI>
      <DialogCustom
        context="AdvancedFiltersPreferences.1"
        open={viewAllPreferencesIncluded}
        onClose={() => setViewAllPreferencesIncluded(() => false)}
        body={
          <>
            <PreferencesListFilter
              preferencesList={preferencesIncludedLocal}
              setPreferencesList={setPreferencesIncludedLocal}
            />
          </>
        }
        footer={
          <DialogCustomFooterActions
            onOk={handleOnOkIncluded}
            onCancel={handleOnCancelIncluded}
          />
        }
      />
    </>
  );
};

export default AdvancedFiltersPreferences;
