import React from "react";
import { BoxMUI, IconMUI, IconPropsMUI } from "../DeltaUI/Components";
import { useComponentMount } from "../Utils";

interface SvgIconProps extends IconPropsMUI {
  style?: React.CSSProperties;
  className?: string;
  svg?: string;
  src?: string;
}

export const SvgIcon: React.FC<SvgIconProps> = (props) => {
  const isMountedComponent = useComponentMount("SvgIcon");
  const { svg, src } = props;
  const span = React.useRef<HTMLSpanElement | undefined>();

  const [outerHTML, setOuterHTML] = React.useState<string>();
  const [loadRef, setLoadRef] = React.useState<boolean>(false);

  const getSvg = async () => {
    if (src) {
      const r = await fetch(src);
      const text = await r.text();
      if (isMountedComponent.current) {
        setOuterHTML(() => text);
      }
    }
  };

  React.useEffect(() => {
    if (span.current && outerHTML && loadRef) {
      span.current.outerHTML = outerHTML;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [outerHTML, loadRef]);

  React.useEffect(() => {
    isMountedComponent.current = true;
    if (svg) {
      setOuterHTML(() => svg);
    } else if (src) {
      getSvg();
    }
    return () => {
      isMountedComponent.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <IconMUI
      {...props}
      sx={{
        "& svg": {
          fill: "#9DAEFC !important",
        },
        "& svg g path": {
          fill: "#9DAEFC !important",
        },
        ...props.sx,
      }}
    >
      <BoxMUI
        component="span"
        ref={(r) => {
          if (r) {
            setLoadRef(() => true);
            span.current = r as HTMLSpanElement;
          }
        }}
      />
    </IconMUI>
  );
};
