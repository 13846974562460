import { gql } from "@apollo/client";
import { FragmentService } from "../FragmentService";

export const RESERVATION_CHATS = gql`
  query reservationChats(
    $where: ReservationChatWhereInput
    $orderBy: [ReservationChatOrderByInput!]
    $first: Int
    $last: Int
    $before: ReservationChatWhereUniqueInput
    $after: ReservationChatWhereUniqueInput
  ) {
    reservationChats(
      where: $where
      orderBy: $orderBy
      first: $first
      last: $last
      before: $before
      after: $after
    ) {
      ...FReservationChat
    }
  }
  ${FragmentService.ReservationChat}
`;
