import React from "react";
import { DrawerMUI } from "..";

export interface ActionSheetProps {
  style?: React.CSSProperties;
  className?: string;
  open: boolean;
  onClose?: () => void;
  borderRadius?: string;
}

const ActionSheet: React.FC<ActionSheetProps> = (props) => {
  const { children, open, onClose, style, className, borderRadius } = props;

  return (
    <DrawerMUI
      className={className}
      style={style}
      sx={{
        "& .MuiDrawer-paperAnchorBottom": {
          height: "inherit",
          borderRadius,
        },
      }}
      anchor="bottom"
      open={open}
      onClose={onClose}
    >
      {children}
    </DrawerMUI>
  );
};

export default ActionSheet;
