import React from "react";
import { UpoolContext } from "../../Providers/UpoolContext";
import {
  BoxMUI,
  PaperMUI,
  Separator,
  TypographyMUI,
} from "../../DeltaUI/Components";

interface CardNotificationNewsProps {
  style?: React.CSSProperties;
  className?: string;
  onClick?: () => void;
  text?: string;
  color?: "secondary" | "primary";
  backgroundColor?: string;
  initialCount: number;
}

export const CardNotificationNews: React.FC<CardNotificationNewsProps> = (
  props
) => {
  const {
    style,
    className,
    onClick,
    text,
    color,
    backgroundColor,
    initialCount,
  } = props;

  const { notificationGeneral } = React.useContext(UpoolContext);

  const isUpdate = React.useMemo(() => {
    return notificationGeneral.length !== initialCount;
  }, [notificationGeneral, initialCount]);

  return (
    <>
      {isUpdate ? (
        <BoxMUI
          sx={{
            background: (theme) =>
              backgroundColor || theme.palette[color || "secondary"].main,
          }}
        >
          <BoxMUI
            style={{
              width: "100%",
              height: "56px",
              ...style,
            }}
            className={`FlexRow FlexCenter ${className}`}
          >
            <PaperMUI
              elevation={7}
              style={{
                padding: "4px 16px",
              }}
            >
              <BoxMUI onClick={onClick}>
                <TypographyMUI variant="subtitle2">
                  {text || "Hay notificaciones nuevas"}
                </TypographyMUI>
              </BoxMUI>
            </PaperMUI>
          </BoxMUI>
          <div
            style={{
              padding: "0 16px",
            }}
          >
            <Separator px={0} divider />
          </div>
        </BoxMUI>
      ) : null}
    </>
  );
};
