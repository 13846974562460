import React from "react";
import { SetterState } from "../../Services/DefaultTypes";

export enum PHONE_SETTING_STEPS {
  INIT_PAGE,
  SEARCH_ACCOUNT,
  ENTRE_CODE,
}

interface IPhoneSetting {
  step: number;
  setStep: SetterState<number>;
  phone: string;
  setPhone: SetterState<string>;
}

const context: IPhoneSetting = {
  step: PHONE_SETTING_STEPS.SEARCH_ACCOUNT,
  setStep: () => {},
  phone: "",
  setPhone: () => {},
};

export const PhoneSettingContext = React.createContext(context);

export interface PhoneSettingProviderProps {}

export const PhoneSettingProvider: React.FC<PhoneSettingProviderProps> = (
  props
) => {
  const [step, setStep] = React.useState<number>(PHONE_SETTING_STEPS.INIT_PAGE);
  const [phone, setPhone] = React.useState<string>("");

  const reset = () => {
    setPhone(() => "");
  };

  React.useEffect(() => {
    if (step === PHONE_SETTING_STEPS.INIT_PAGE) {
      reset();
    }
  }, [step]);

  return (
    <PhoneSettingContext.Provider
      value={{
        step,
        setStep,
        phone,
        setPhone,
      }}
    >
      {props.children}
    </PhoneSettingContext.Provider>
  );
};
