import React from "react";
import { UpoolContext } from "../../Providers/UpoolContext";
import {
  ActionSheet,
  BoxMUI,
  ButtonMUI,
  Separator,
  TypographyMUI,
} from "../../DeltaUI/Components";
import { APPLICATION_PATHS, COLOR_PRIMARY } from "../../Utils/Constants";
import { CustomPage } from "../CustomPage";

interface RequestMessageSentProps {
  style?: React.CSSProperties;
  className?: string;
  open: boolean;
  onOk?: () => void;
  image?: string;
}

export const RequestMessageSent: React.FC<RequestMessageSentProps> = (
  props
) => {
  const { style, className, open, onOk, image } = props;
  const { selectPathTab } = React.useContext(UpoolContext);

  return (
    <ActionSheet
      open={open}
      style={{
        height: "100vh",
      }}
    >
      <CustomPage
        style={{
          ...style,
        }}
        className={className}
      >
        <BoxMUI
          className="BackgroundImage"
          style={{
            position: "absolute",
            top: 0,
            width: "100%",
            height: "100%",
            backgroundImage: `url(${image})`,
          }}
        >
          <BoxMUI
            style={{
              width: "100%",
              height: "51vh",
              position: "absolute",
              bottom: 0,
              left: 0,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-evenly",
            }}
          >
            <BoxMUI
              style={{
                textAlign: "center",
              }}
            >
              <TypographyMUI className="TypographyBold20pt">
                Solicitud enviada
              </TypographyMUI>
              <Separator px={8} />
              <TypographyMUI
                color={COLOR_PRIMARY}
                className="TypographyRegular11pt"
              >
                Enviaste la solicitud para unirte al viaje.
              </TypographyMUI>
              <TypographyMUI
                color={COLOR_PRIMARY}
                className="TypographyRegular11pt"
              >
                Te notificaremos cuando el conductor
              </TypographyMUI>
              <TypographyMUI
                color={COLOR_PRIMARY}
                className="TypographyRegular11pt"
              >
                haya dado su respuesta.
              </TypographyMUI>
            </BoxMUI>
            <ButtonMUI
              style={{
                width: "50%",
              }}
              variant="contained"
              color="primary"
              onClick={onOk}
            >
              {selectPathTab === APPLICATION_PATHS.SEARCH_TRIP
                ? "Ver mis viajes"
                : "Aceptar"}
            </ButtonMUI>
          </BoxMUI>
        </BoxMUI>
      </CustomPage>
    </ActionSheet>
  );
};
