import React from "react";
import {
  CardMUI,
  BoxMUI,
  TypographyMUI,
  Separator,
  BadgeMUI,
} from "../../DeltaUI/Components";
import { IReservation } from "../../Services/ReservationService";
import { MomentJS, Safely, Tools } from "../../Utils";
import { UpoolContext } from "../../Providers/UpoolContext";
import UserAvatar from "../User/UserAvatar";
import { MyTripsContext } from "../MyTrips/MyTripsContext";
import { COLOR_QUINARY } from "../../Utils/Constants";

export interface CardReservationChatProps {
  reservation: IReservation;
  onChange?: (reservation: IReservation) => void;
}

const CardReservationChat: React.FC<CardReservationChatProps> = (props) => {
  const { reservation } = props;
  const { getPerson } = Tools();
  const { getTime } = MomentJS();
  const { eventSafely } = Safely();
  const { historyPush, selectPathTab, setViewOtherUserId } = React.useContext(
    UpoolContext
  );
  const { setReservationForChat } = React.useContext(MyTripsContext);

  const reservationUser = React.useMemo(
    () => reservation.user,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [reservation]
  );

  const chat = React.useMemo(
    () =>
      reservation.chats && reservation.chats.length
        ? reservation.chats[reservation.chats.length - 1]
        : undefined,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [reservation]
  );

  const person = React.useMemo(
    () => getPerson(reservationUser, 0),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [reservationUser]
  );

  const name = React.useMemo(
    () => (person ? `${person.firstName} ${person.lastName}` : ""),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [person]
  );

  const viewUser = () => {
    setViewOtherUserId(() => reservationUser.id);
    historyPush(`${selectPathTab}/user`);
  };

  const avatar = React.useMemo(
    () => <UserAvatar size={4} user={reservationUser} />,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [reservationUser]
  );

  return (
    <CardMUI elevation={0}>
      <BoxMUI className="FlexRow">
        <BoxMUI
          onClick={viewUser}
          style={{
            paddingTop: "4px",
            marginRight: "16px",
          }}
        >
          {avatar}
        </BoxMUI>
        <BoxMUI
          className="FlexColumn FullWidth"
          onClick={eventSafely(() => {
            setReservationForChat(() => reservation);
          })}
        >
          <BoxMUI>
            <TypographyMUI className="TypographyBold11pt">{name}</TypographyMUI>
          </BoxMUI>
          <BoxMUI
            style={{
              width: "65vw",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            <TypographyMUI variant="caption" className="TypographyRegular10pt">
              {chat ? chat.description : ""}
            </TypographyMUI>
          </BoxMUI>
        </BoxMUI>
        <BoxMUI
          className="FlexColumn"
          style={{ marginLeft: "-24px", marginTop: "-2px" }}
        >
          <BoxMUI>
            <TypographyMUI
              variant="caption"
              className="TypographyRegular8pt"
              color={COLOR_QUINARY}
            >
              {chat ? getTime(chat.createdAt) : ""}
            </TypographyMUI>
          </BoxMUI>
          <BoxMUI className="FlexRow FlexCenter" style={{ height: "24px" }}>
            <BadgeMUI
              badgeContent={reservation.unreadChats}
              color="secondary"
            />
          </BoxMUI>
        </BoxMUI>
      </BoxMUI>
      <Separator px={8} divider />
    </CardMUI>
  );
};

export default CardReservationChat;
