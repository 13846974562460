import React from "react";
import { useLocation } from "react-router-dom";
import {
  useCustomApolloQuery as useCustomApolloQueryApollo,
  useCustomApolloMutation as useCustomApolloMutationApollo,
  useCustomApolloAsync as useCustomApolloAsync2,
} from "../../DeltaUI/Library/apollo/hooks";
import {
  CustomMutationOptionsAsync,
  CustomQueryOptionsAsync,
} from "../../DeltaUI/Library/apollo/hooks/useCustomApolloAsync";
import { CustomMutationOutput } from "../../DeltaUI/Library/apollo/hooks/useCustomApolloMutation";
import { CustomQueryOutput } from "../../DeltaUI/Library/apollo/hooks/useCustomApolloQuery";
import {
  ApolloServiceError,
  IRequest,
} from "../../DeltaUI/Library/apollo/utils";
import { EventAppContext } from "../../Providers/EventAppProvider";
import { ERROR_CODE } from "../../Utils/Constants";

const apolloServiceTimeout = {
  onChange: setTimeout(() => {}, 0),
};

export const useApolloError = () => {
  const {
    handleEventWithoutConnection,
    handleEventUnauthenticatedUser,
  } = React.useContext(EventAppContext);
  const { pathname } = useLocation();

  const resolveError = React.useMemo(() => {
    return (err: ApolloServiceError) => {
      switch (err.code || err.message) {
        case ERROR_CODE.FAILED_TO_FETCH:
          clearTimeout(apolloServiceTimeout.onChange);
          apolloServiceTimeout.onChange = setTimeout(() => {
            handleEventWithoutConnection(err);
          }, 3000);
          return false;
        case ERROR_CODE.UNAUTHENTICATED:
          if (pathname !== "/login" && pathname !== "/") {
            handleEventUnauthenticatedUser(err);
            return true;
          } else {
            return false;
          }

        default:
          return false;
      }
    };
  }, [handleEventUnauthenticatedUser, handleEventWithoutConnection, pathname]);

  return {
    resolveError,
  };
};

export const useCustomApolloQuery = (
  config: IRequest
): CustomQueryOutput<any> => {
  const [call, request] = useCustomApolloQueryApollo(config);
  const { resolveError } = useApolloError();

  React.useEffect(() => {
    if (request.error) {
      resolveError(request.error);
    }
  }, [request.error, resolveError]);

  return [call, request];
};

export const useCustomApolloMutation = (
  config: IRequest
): CustomMutationOutput<any> => {
  const [call, request] = useCustomApolloMutationApollo(config);
  const { resolveError } = useApolloError();

  React.useEffect(() => {
    if (request.error) {
      resolveError(request.error);
    }
  }, [request.error, resolveError]);

  return [call, request];
};

export const useCustomApolloAsync = () => {
  const { callQuery, callMutation } = useCustomApolloAsync2();
  const { resolveError } = useApolloError();

  const queryAsync = React.useMemo(() => {
    return (options: CustomQueryOptionsAsync): Promise<any> =>
      new Promise(
        (
          resolve: (data: any) => void,
          reject: (err: ApolloServiceError) => void
        ) => {
          callQuery(options)
            .then(resolve)
            .catch((err) => {
              if (!resolveError(err)) {
                return reject(err);
              }
              return resolve(null);
            });
        }
      );
  }, [callQuery, resolveError]);

  const mutationAsync = React.useMemo(() => {
    return (options: CustomMutationOptionsAsync): Promise<any> =>
      new Promise(
        (
          resolve: (data: any) => void,
          reject: (err: ApolloServiceError) => void
        ) => {
          callMutation(options)
            .then(resolve)
            .catch((err) => {
              if (!resolveError(err)) {
                return reject(err);
              }
              return resolve(null);
            });
        }
      );
  }, [callMutation, resolveError]);

  return {
    queryAsync,
    mutationAsync,
  };
};
