import { gql } from "@apollo/client";
import { FragmentService } from "../FragmentService";

export const CURRENT_USER = gql`
  query currentUser {
    currentUser {
      ...FUserLogin
    }
  }
  ${FragmentService.UserLogin}
`;

export const USER = gql`
  query user($where: UserWhereUniqueInput!) {
    user(where: $where) {
      ...FUserLogin
    }
  }
  ${FragmentService.UserLogin}
`;

export const RATES = gql`
  query rates(
    $where: RateWhereInput
    $orderBy: [RateOrderByInput!]
    $first: Int
    $last: Int
    $before: RateWhereUniqueInput
    $after: RateWhereUniqueInput
  ) {
    rates(
      where: $where
      orderBy: $orderBy
      first: $first
      last: $last
      before: $before
      after: $after
    ) {
      ...FRate
      userFrom {
        ...FUser
      }
    }
  }
  ${FragmentService.Rate}
  ${FragmentService.User}
`;

export const PERSON_GENDERS = gql`
  query personGenders {
    personGenders {
      id
      description
    }
  }
`;

export const USER_EXIST = gql`
  query userExists($where: UserExistsInput!) {
    userExists(where: $where) {
      id
      email
      phone
    }
  }
`;
