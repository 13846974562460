import { gql } from "@apollo/client";
import { FragmentService } from "../FragmentService";

export const NOTIFICATIONS = gql`
  query notifications(
    $where: NotificationWhereInput
    $orderBy: [NotificationOrderByInput!]
    $first: Int
    $last: Int
    $before: NotificationWhereUniqueInput
    $after: NotificationWhereUniqueInput
  ) {
    notifications(
      where: $where
      orderBy: $orderBy
      first: $first
      last: $last
      before: $before
      after: $after
    ) {
      ...FNotification
    }
  }
  ${FragmentService.Notification}
`;
