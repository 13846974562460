import React from "react";
import { UpoolContext } from "../../Providers/UpoolContext";
import {
  ButtonMUI,
  BoxMUI,
  Separator,
  TypographyMUI,
  FormInputPassword,
} from "../../DeltaUI/Components";
import { UserService } from "../../Services/UserService";
import { DialogCustomHook } from "../../Components";
import { PasswordSettingContext } from "./PasswordSettingContext";
import { useComponentMount } from "../../Utils";

interface NewPasswordProps {}

export const NewPassword: React.FC<NewPasswordProps> = () => {
  const isMountedComponent = useComponentMount("NewPassword");
  const { resetPassword } = UserService();
  const { messageError, message, messageWarn } = DialogCustomHook();
  const { setLoading, historyGoBack, user } = React.useContext(UpoolContext);
  const { verificationToken } = React.useContext(PasswordSettingContext);
  const [newPassword, setNewPassword] = React.useState<string>("");
  const [repassword, setRepassword] = React.useState<string>("");

  const handleChangePassword = () => {
    if (!user) {
      return messageWarn({
        context: "PasswordSetting.NewPassword.handleChangePassword.1",
        message: "Usuario no disponible.",
      });
    } else if (newPassword !== repassword) {
      return messageWarn({
        context: "PasswordSetting.NewPassword.handleChangePassword.2",
        message: "La contraseña nueva no se repite bien.",
      });
    } else if (!verificationToken) {
      return messageWarn({
        context: "PasswordSetting.NewPassword.handleChangePassword.3",
        message: "Faltan datos para poder actualizar la contraseña.",
      });
    }

    setLoading(() => true);
    resetPassword({
      userId: user.id,
      verificationToken,
      newPassword,
    })
      .then((res) => {
        if (res) {
          if (isMountedComponent.current) {
            setNewPassword(() => "");
            setRepassword(() => "");
          }
          message({
            context: "PasswordSetting.NewPassword.handleChangePassword.4",
            title: "¡Listo!",
            message: "La contraseña se actualizó correctamente.",
          });
          historyGoBack();
        } else {
          messageWarn({
            context: "PasswordSetting.NewPassword.handleChangePassword.5",
            message: "No se puede actualizar la contraseña.",
          });
        }
      })
      .catch(({ message }) => {
        messageError({
          context: "PasswordSetting.NewPassword.handleChangePassword.6",
          message: message,
        });
      })
      .finally(() => setLoading(() => false));
  };

  React.useEffect(() => {
    isMountedComponent.current = true;
    return () => {
      isMountedComponent.current = false;
    };
  }, [isMountedComponent]);

  return (
    <>
      <BoxMUI
        style={{
          width: "100%",
        }}
      >
        <Separator px={8} />
        <TypographyMUI className="TypographyBold11pt">
          Nueva contraseña
        </TypographyMUI>
        <Separator px={24} />
        <TypographyMUI className="TypographyRegular11pt">
          Las contraseñas fuertes incluyen números, letras y caracteres
          especiales.
        </TypographyMUI>
        <Separator px={8} />
        <FormInputPassword
          value={newPassword}
          label="Nueva contraseña"
          onChange={(text) => setNewPassword(() => text)}
        />
        <Separator px={8} />
        <FormInputPassword
          value={repassword}
          label="Repetir contraseña"
          onChange={(text) => setRepassword(() => text)}
        />
      </BoxMUI>
      <BoxMUI
        style={{
          width: "100%",
        }}
      >
        <ButtonMUI
          style={{
            width: "100%",
            marginTop: "16px",
          }}
          variant="contained"
          color="primary"
          onClick={handleChangePassword}
          disabled={false}
        >
          Continuar
        </ButtonMUI>
        <Separator px={32} />
      </BoxMUI>
    </>
  );
};
