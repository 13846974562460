import React from "react";
import { FormControlMUI, InputLabelMUI, InputMUI, FormHelperText } from "..";
import { Tools } from "../../Utils";

const ElementID = "FormInputEmailOrPhoneId";
const DEFAULT_MESSAGE_ERROR_EMAIL = "El mail no está bien escrito";
const DEFAULT_MESSAGE_ERROR_PHONE =
  "El número de teléfono no está bien escrito";

export interface FormInputEmailOrPhoneProps {
  id?: string;
  inputId?: string;
  value: string;
  label?: string | boolean;
  onClick?: () => void;
  onChange?: (text: string, type: "email" | "phone") => void;
  messageErrorEmail?: string;
  messageErrorPhone?: string;
  type?: "email" | "phone";
  multipleType?: boolean;
  activateMask?: boolean;
  readOnly?: boolean;
  onRef?: (instance: HTMLInputElement) => void;
  onError?: (value: boolean) => void;
}

const FormInputEmailOrPhone: React.FC<FormInputEmailOrPhoneProps> = (props) => {
  const {
    id,
    inputId,
    value,
    label,
    onClick,
    onChange,
    messageErrorEmail,
    messageErrorPhone,
    type,
    multipleType,
    activateMask,
    readOnly,
    onRef,
    onError,
  } = props;
  const [isPhoneType, setIsPhoneType] = React.useState<boolean>(false);
  const { applyMaskPhone, validateEmail, removeMaskPhone, validatePhone } =
    Tools();

  const handleOnRef = () => {
    if (onRef) {
      onRef(document.getElementById(ElementID) as HTMLInputElement);
    }
  };

  const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(event.target.value, "email");
    }
  };

  const handleChangePhone = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(removeMaskPhone(event.target.value), "phone");
    }
  };

  const handleChangeEmailOrPhone = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (Number.isNaN(Number(removeMaskPhone(event.target.value)))) {
      setIsPhoneType(() => false);
      if (onChange) {
        onChange(event.target.value, "email");
      }
    } else {
      setIsPhoneType(() => true);
      if (onChange) {
        onChange(removeMaskPhone(event.target.value), "phone");
      }
    }
  };

  const emailError = React.useMemo(() => {
    return value && !isPhoneType ? !validateEmail(value) : false;
  }, [value, isPhoneType, validateEmail]);

  const phoneError = React.useMemo(() => {
    return value && isPhoneType ? !validatePhone(value) : false;
  }, [value, isPhoneType, validatePhone]);

  React.useEffect(() => {
    setIsPhoneType(() => type === "phone");
  }, [type]);

  React.useEffect(() => {
    if (onError) {
      onError(emailError || phoneError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailError, phoneError]);

  if (type === "phone" && !multipleType) {
    return (
      <FormControlMUI
        variant="standard"
        sx={{
          width: "100%",
          margin: "8px 0",
        }}
        error={phoneError}
        id={id}
      >
        {label === false ? null : (
          <InputLabelMUI htmlFor={inputId || ElementID}>
            {label || "Teléfono"}
          </InputLabelMUI>
        )}
        <InputMUI
          ref={handleOnRef}
          id={inputId || ElementID}
          type="text"
          value={applyMaskPhone(value, !activateMask)}
          onClick={onClick}
          onChange={handleChangePhone}
          readOnly={readOnly}
        />
        {phoneError ? (
          <FormHelperText
            active={true}
            message={messageErrorPhone || DEFAULT_MESSAGE_ERROR_PHONE}
          />
        ) : null}
      </FormControlMUI>
    );
  }

  if (type === "email" && !multipleType) {
    return (
      <FormControlMUI
        variant="standard"
        sx={{
          width: "100%",
          margin: "8px 0",
        }}
        error={emailError}
        id={id}
      >
        {label === false ? null : (
          <InputLabelMUI htmlFor={inputId || ElementID}>
            {label || "Email"}
          </InputLabelMUI>
        )}
        <InputMUI
          ref={handleOnRef}
          id={inputId || ElementID}
          type="text"
          value={value}
          onClick={onClick}
          onChange={handleChangeEmail}
          readOnly={readOnly}
        />
        {emailError ? (
          <FormHelperText
            active={true}
            message={messageErrorEmail || DEFAULT_MESSAGE_ERROR_EMAIL}
          />
        ) : null}
      </FormControlMUI>
    );
  }

  return (
    <FormControlMUI
      variant="standard"
      sx={{
        width: "100%",
        margin: "8px 0",
      }}
      error={emailError || phoneError}
      id={id}
    >
      {label === false ? null : (
        <InputLabelMUI
          htmlFor={inputId || ElementID}
          className="TypographySemibold11pt"
        >
          {label || "Email o teléfono"}
        </InputLabelMUI>
      )}
      <InputMUI
        ref={handleOnRef}
        id={inputId || ElementID}
        type="text"
        value={isPhoneType ? applyMaskPhone(value, !activateMask) : value}
        onClick={onClick}
        onChange={handleChangeEmailOrPhone}
        readOnly={readOnly}
      />
      {emailError ? (
        <FormHelperText
          active={true}
          message={messageErrorEmail || DEFAULT_MESSAGE_ERROR_EMAIL}
        />
      ) : null}
      {phoneError ? (
        <FormHelperText
          active={true}
          message={messageErrorPhone || DEFAULT_MESSAGE_ERROR_PHONE}
        />
      ) : null}
    </FormControlMUI>
  );
};

export default FormInputEmailOrPhone;
