import React from "react";
import {
  BoxMUI,
  CheckboxMUI,
  FormControlLabelMUI,
  ListItemMUI,
  ListItemTextMUI,
  Separator,
  TypographyMUI,
} from "../../../DeltaUI/Components";
import {
  DriveEtaIcon,
  TaxiAlertOutlinedIcon,
} from "../../../DeltaUI/Components/CustomIcon";
import { TRIP_TYPE } from "../../../Utils/Constants";
import { SquareButton } from "../../Button/SquareButton";
import { SearchTripContext } from "../SearchTripContext";

export interface AdvancedFiltersTripTypeProps {}

const AdvancedFiltersTripType: React.FC<AdvancedFiltersTripTypeProps> = (
  props
) => {
  const { typeId, setTypeId, freeSeatMiddle, setFreeSeatMiddle } =
    React.useContext(SearchTripContext);

  return (
    <>
      <ListItemMUI style={{ paddingBottom: 0 }}>
        <ListItemTextMUI
          primary={
            <TypographyMUI className="TypographyExtrabold11pt">
              Tipo de viaje
            </TypographyMUI>
          }
        />
      </ListItemMUI>
      <Separator px={8} />
      <ListItemMUI>
        <BoxMUI className="FlexRow">
          <SquareButton
            active={typeId === TRIP_TYPE.PARTICULAR}
            label="Particular"
            icon={<DriveEtaIcon />}
            onClick={() => {
              if (typeId === TRIP_TYPE.PARTICULAR) {
                setTypeId(() => undefined);
              } else {
                setTypeId(() => TRIP_TYPE.PARTICULAR);
              }
            }}
          />
          <Separator px={16} orientation="vertical" />
          <SquareButton
            active={typeId === TRIP_TYPE.REMIS}
            label="Remís"
            icon={<TaxiAlertOutlinedIcon />}
            onClick={() => {
              if (typeId === TRIP_TYPE.REMIS) {
                setTypeId(() => undefined);
              } else {
                setTypeId(() => TRIP_TYPE.REMIS);
              }
            }}
          />
        </BoxMUI>
      </ListItemMUI>
      <ListItemMUI style={{ padding: "0 16px 0" }}>
        <FormControlLabelMUI
          control={
            <CheckboxMUI
              checked={freeSeatMiddle}
              onChange={(ev) => setFreeSeatMiddle(() => ev.target.checked)}
            />
          }
          label="Asiento libre en el medio"
        />
      </ListItemMUI>
    </>
  );
};

export default AdvancedFiltersTripType;
