import React from "react";
import { DialogCustomHook } from "../DialogCustom";
import { UpoolContext } from "../../Providers/UpoolContext";
import {
  ButtonMUI,
  BoxMUI,
  Separator,
  FormInputEmailOrPhone,
  TypographyMUI,
  FormControlMUI,
  InputLabelMUI,
  InputMUI,
  ActionSheet,
  BodyPage,
  FooterPage,
} from "../../DeltaUI/Components";
import { UserService } from "../../Services/UserService";
import { RegisterContext, REGISTER_STEPS } from "./RegisterContext";
import { TermsAndConditions } from "../User/TermsAndConditions";
import { COLOR_PRIMARY } from "../../Utils/Constants";
import { CustomPage } from "../CustomPage";

interface RegisterFormProps {}

export const RegisterForm: React.FC<RegisterFormProps> = (props) => {
  const { setUserToRegister, userToRegister, setStep } = React.useContext(
    RegisterContext
  );
  const { setLoading, historyGoBack } = React.useContext(UpoolContext);
  const { userExists } = UserService();
  const { messageError, messageWarn } = DialogCustomHook();
  const [errorEmailOrPhone, setErrorEmailOrPhone] = React.useState<boolean>(
    false
  );
  const [
    viewTermsAndConditions,
    setViewTermsAndConditions,
  ] = React.useState<boolean>(false);

  const handleViewTermsAndConditions = () => {
    setViewTermsAndConditions((s) => !s);
  };

  const disabledNext = React.useMemo(() => {
    const registerOptions =
      (!userToRegister?.emailOrPhone || !userToRegister?.valueType) &&
      !userToRegister?.facebookId &&
      !userToRegister?.googleId &&
      !userToRegister?.appleId;
    return (
      !userToRegister?.firstName ||
      !userToRegister?.lastName ||
      registerOptions ||
      errorEmailOrPhone
    );
  }, [userToRegister, errorEmailOrPhone]);

  const handleNext = () => {
    const error = () => {
      messageError({
        context: "RegisterForm.handleNext.1",
        message: "Faltan los datos de registro.",
      });
    };
    if (!userToRegister) {
      return error();
    }

    const {
      valueType,
      emailOrPhone,
      facebookId,
      googleId,
      appleId,
    } = userToRegister;

    if (googleId || facebookId || appleId) {
      const where: any = {};
      if (googleId) {
        where.googleId = googleId;
      } else if (appleId) {
        where.appleId = appleId;
      } else {
        where.facebookId = facebookId;
      }

      setLoading(() => true);
      userExists(where)
        .then((u) => {
          if (u) {
            if (googleId) {
              messageWarn({
                context: "RegisterForm.handleNext.2",
                message:
                  "Ya existe un usuario asociado a esta cuenta de Google, intente utilizar otra.",
              });
              historyGoBack();
            } else if (appleId) {
              messageWarn({
                context: "RegisterForm.handleNext.3",
                message:
                  "Ya existe un usuario asociado a esta cuenta de Apple, intente utilizar otra.",
              });
              historyGoBack();
            } else {
              messageWarn({
                context: "RegisterForm.handleNext.4",
                message:
                  "Ya existe un usuario asociado a esta cuenta de Facebook, intente utilizar otra.",
              });
              historyGoBack();
            }
          } else {
            setStep(() => REGISTER_STEPS.SUPLEMENTARY_DATA);
          }
        })
        .catch(({ message }) => {
          messageError({
            context: "RegisterForm.handleNext.5",
            message: message,
          });
        })
        .finally(() => setLoading(() => false));
    } else {
      const where: any = {};
      if (valueType === "phone") {
        where.phone = emailOrPhone;
      } else {
        where.email = emailOrPhone;
      }

      setLoading(() => true);
      userExists(where)
        .then((u) => {
          if (u) {
            if (valueType === "email") {
              messageWarn({
                context: "RegisterForm.handleNext.6",
                message:
                  "Ya existe una cuenta asociada a este email, intente utilizar otro.",
              });
            } else {
              messageWarn({
                context: "RegisterForm.handleNext.7",
                message:
                  "Ya existe una cuenta asociada a este número de teléfono, intente utilizar otro.",
              });
            }
          } else {
            setStep(() => REGISTER_STEPS.ENTRE_CODE);
          }
        })
        .catch(({ message }) => {
          messageError({
            context: "RegisterForm.handleNext.8",
            message: message,
          });
        })
        .finally(() => setLoading(() => false));
    }
  };

  return (
    <>
      <BoxMUI
        style={{
          width: "100%",
        }}
      >
        <TypographyMUI
          className="TypographyExtrabold26pt"
          sx={{ lineHeight: "24px" }}
        >
          Crea tu
        </TypographyMUI>
        <TypographyMUI className="TypographyExtrabold26pt">
          cuenta
        </TypographyMUI>
        {userToRegister?.facebookId ? (
          <TypographyMUI
            className="TypographyExtrabold26pt"
            sx={{ lineHeight: "24px" }}
          >
            con Facebook
          </TypographyMUI>
        ) : null}
        {userToRegister?.googleId ? (
          <TypographyMUI
            className="TypographyExtrabold26pt"
            sx={{ lineHeight: "24px" }}
          >
            con Google
          </TypographyMUI>
        ) : null}
        {userToRegister?.appleId ? (
          <TypographyMUI
            className="TypographyExtrabold26pt"
            sx={{ lineHeight: "24px" }}
          >
            con Apple
          </TypographyMUI>
        ) : null}
        <Separator px={32} />
        <form noValidate autoComplete="off">
          <FormControlMUI
            id="firstName"
            variant="standard"
            style={{
              width: "100%",
              margin: "8px 0",
            }}
          >
            <InputLabelMUI
              htmlFor="standar-adornment-firstName"
              className="TypographySemibold11pt"
            >
              Nombre
            </InputLabelMUI>
            <InputMUI
              id="standar-adornment-firstName"
              type="text"
              value={userToRegister?.firstName || ""}
              onChange={(ev) =>
                setUserToRegister({ firstName: ev.target.value })
              }
            />
          </FormControlMUI>
          <FormControlMUI
            id="lastName"
            variant="standard"
            style={{
              width: "100%",
              margin: "8px 0",
            }}
          >
            <InputLabelMUI
              htmlFor="standar-adornment-lastName"
              className="TypographySemibold11pt"
            >
              Apellido
            </InputLabelMUI>
            <InputMUI
              id="standar-adornment-lastName"
              type="text"
              value={userToRegister?.lastName || ""}
              onChange={(ev) =>
                setUserToRegister({ lastName: ev.target.value })
              }
            />
          </FormControlMUI>
          {!userToRegister?.facebookId &&
          !userToRegister?.googleId &&
          !userToRegister?.appleId ? (
            <FormInputEmailOrPhone
              id="emailOrPhone"
              type={userToRegister?.valueType}
              value={userToRegister?.emailOrPhone || ""}
              onChange={(emailOrPhone, valueType) =>
                setUserToRegister({ emailOrPhone, valueType })
              }
              onError={(v) => setErrorEmailOrPhone(() => v)}
            />
          ) : null}
        </form>
      </BoxMUI>
      <BoxMUI
        style={{
          width: "100%",
        }}
      >
        <Separator px={16} />
        <TypographyMUI className="TypographyRegular10pt">
          Si te registras, significa que aceptas los{" "}
          <span
            style={{ color: COLOR_PRIMARY, cursor: "pointer" }}
            onClick={handleViewTermsAndConditions}
            className="TypographyRegular10pt"
          >
            Términos del servicio
          </span>{" "}
          y la{" "}
          <span
            style={{ color: COLOR_PRIMARY, cursor: "pointer" }}
            onClick={handleViewTermsAndConditions}
            className="TypographyRegular10pt"
          >
            Política de privacidad
          </span>
          , incluido el{" "}
          <span
            style={{ color: COLOR_PRIMARY, cursor: "pointer" }}
            onClick={handleViewTermsAndConditions}
            className="TypographyRegular10pt"
          >
            Uso de Cookies · Opciones de privacidad
          </span>
          .
        </TypographyMUI>
        <Separator px={16} />
        <ButtonMUI
          style={{
            width: "100%",
            marginTop: "16px",
          }}
          variant="contained"
          color="primary"
          onClick={handleNext}
          disabled={disabledNext}
        >
          Continuar
        </ButtonMUI>
        <Separator px={32} />
      </BoxMUI>
      <ActionSheet
        open={viewTermsAndConditions}
        style={{
          height: "100vh",
        }}
      >
        <CustomPage
          footerPage={
            <FooterPage>
              <BoxMUI style={{ padding: "16px 24px" }}>
                <ButtonMUI
                  className="FullWidth"
                  variant="contained"
                  color="primary"
                  onClick={handleViewTermsAndConditions}
                >
                  Aceptar
                </ButtonMUI>
              </BoxMUI>
            </FooterPage>
          }
        >
          <BodyPage m={3}>
            <TermsAndConditions />
          </BodyPage>
        </CustomPage>
      </ActionSheet>
    </>
  );
};
