import React from "react";
import { UpoolContext } from "../../Providers/UpoolContext";
import {
  BoxMUI,
  PaperMUI,
  Separator,
  TypographyMUI,
} from "../../DeltaUI/Components";
import { TripService } from "../../Services/TripService";
import { MomentJS, useComponentMount, useCustomLog } from "../../Utils";
import { CardDetailTripData, CardDetailTripPreferences } from "../Card";
import PreferencesList from "../User/PreferencesList";
import { CreateTripContext } from "./CreateTripContext";
import { MapRoute } from "../Trip/MapRoute";
import { CreateTripNextButton } from "./CreateTripNextButton";
import DetailTripResume from "../DetailTrip/DetailTripResume";
import { EventAppContext } from "../../Providers/EventAppProvider";
import { DialogCustomHook } from "../DialogCustom";
import {
  AVAILABLE_SCREEN_SPACE_CSS,
  COLOR_QUATERNARY,
  PREFERENCES,
} from "../../Utils/Constants";
import { PreferenceUserContext } from "../../Providers/PreferenceUserContextProvider";
import { GoogleMaps } from "../../DeltaUI/Components/GoogleMapsApiReact";

export interface CreateTripResumeProps {
  onNext: () => void;
}

const CreateTripResume: React.FC<CreateTripResumeProps> = (props) => {
  const isMountedComponent = useComponentMount("CreateTripResume");
  const { onNext } = props;
  const { messageError, message, messageWarn } = DialogCustomHook();
  const Log = useCustomLog();
  const { createOneTrip, updateOneTrip } = TripService();
  const { setLoading, user, historyGoBack } = React.useContext(UpoolContext);
  const { preferencesUser } = React.useContext(PreferenceUserContext);
  const {
    description,
    clean,
    amount,
    originPlace,
    destinationPlace,
    tabResume,
    vehicle,
    totalPlaces,
    dateFrom,
    placeFrom,
    placeTo,
    editTrip,
    tripTypeId,
    setCreateTripEndMessage,
    freeSeatMiddle,
    resultCreateOneTrip,
  } = React.useContext(CreateTripContext);
  const { handleEventUpdateTrip } = React.useContext(EventAppContext);
  const { createMoment } = MomentJS();

  const handleCreateConfirm = () => {
    // validacion del importe
    if (!amount || isNaN(amount)) {
      return messageWarn({
        context: "CreateTripResume.handleCreateConfirm.1",
        message: "Debe indicar un monto válido.",
      });
    }
    if (!originPlace) {
      return messageWarn({
        context: "CreateTripResume.handleCreateConfirm.2",
        message: "Debe indicar un origen.",
      });
    }
    if (!dateFrom) {
      return messageWarn({
        context: "CreateTripResume.handleCreateConfirm.3",
        message: "Debe indicar una fecha y hora de salida.",
      });
    }
    if (!destinationPlace) {
      return messageWarn({
        context: "CreateTripResume.handleCreateConfirm.4",
        message: "Debe indicar un destino.",
      });
    }
    if (!vehicle) {
      return messageWarn({
        context: "CreateTripResume.handleCreateConfirm.5",
        message: "Debe seleccionar un vehiculo.",
      });
    }
    if (!user) {
      return messageWarn({
        context: "CreateTripResume.handleCreateConfirm.6",
        message: "El usuario no está disponible.",
      });
    }

    const preferenceIds = preferencesUser
      .filter((pUser) => pUser.enabled)
      .map((pUser) => pUser.preferenceId);

    if (freeSeatMiddle) {
      preferenceIds.push(PREFERENCES.FREE_SEAT_MIDDLE);
    }

    const data: any = {
      dateFrom,
      totalPlaces,
      placeIds: [originPlace.place_id, destinationPlace.place_id],
      vehicleId: vehicle.id,
      preferenceIds,
      amount,
      typeId: tripTypeId,
    };
    if (data) {
      data.description = description;
    }

    // confirmacion del viaje
    setLoading(() => true);
    createOneTrip({
      data,
    })
      .then((r) => {
        resultCreateOneTrip.current = r;
        if (isMountedComponent.current) {
          setCreateTripEndMessage(() => true);
        }
      })
      .catch(({ message }) => {
        messageError({
          context: "CreateTripResume.handleCreateConfirm.7",
          message: message,
        });
      })
      .finally(() => setLoading(() => false));
  };

  const handleUpdateConfirm = () => {
    // validacion del importe
    if (!amount || isNaN(amount)) {
      return messageWarn({
        context: "CreateTripResume.handleUpdateConfirm.1",
        message: "Debe indicar un monto válido.",
      });
    }
    if (!dateFrom) {
      return messageWarn({
        context: "CreateTripResume.handleUpdateConfirm.2",
        message: "Debe indicar una fecha y hora de salida.",
      });
    }
    if (!editTrip) {
      return null;
    }

    const data: any = {};

    const editTripDateFrom = createMoment({
      date: editTrip.dateFrom,
    }).toDate();

    if (dateFrom < editTripDateFrom || dateFrom > editTripDateFrom) {
      data.dateFrom = dateFrom;
    }

    if (totalPlaces !== editTrip.totalPlaces) {
      data.totalPlaces = totalPlaces;
    }

    message({
      context: "CreateTripResume.handleUpdateConfirm.4",
      title: "¿Querés actualizar este viaje?",
      message: "Se aplicarán modificiones a tu viaje.",
      onOk: () => {
        setLoading(() => true);
        updateOneTrip(editTrip.id, data)
          .then(() => {
            if (isMountedComponent.current) {
              message({
                context: "CreateTripResume.handleUpdateConfirm.5",
                title: "¡Viaje modificado!",
                message:
                  "Hiciste modificaciones en tu viaje original. Notificaremos a los pasajeros sobre los cambios realizados.",
              });
              clean();
              handleEventUpdateTrip();
              historyGoBack();
            }
          })
          .catch(({ message }) => {
            messageError({
              context: "CreateTripResume.handleUpdateConfirm.6",
              message: message,
            });
          })
          .finally(() => setLoading(() => false));
      },
      onCancel: () => setLoading(() => false),
    });
  };

  const selectPreferences = React.useMemo(
    () => preferencesUser.filter((pUser) => pUser.enabled).length,
    [preferencesUser]
  );

  const places = React.useMemo(() => {
    return editTrip
      ? `${editTrip.usedPlaces}/${totalPlaces}`
      : `0/${totalPlaces}`;
  }, [editTrip, totalPlaces]);

  const calculeFreeSeatMiddle = React.useMemo(() => {
    return editTrip
      ? !!editTrip?.trip.preferences.find(
          (pUser) => pUser.preferenceId === PREFERENCES.FREE_SEAT_MIDDLE
        )
      : freeSeatMiddle;
  }, [editTrip, freeSeatMiddle]);

  const preferences = React.useMemo(() => {
    return editTrip
      ? editTrip.trip.preferences.filter(
          (pUser) => pUser.preferenceId !== PREFERENCES.FREE_SEAT_MIDDLE
        )
      : [];
  }, [editTrip]);

  React.useEffect(() => {
    if (isMountedComponent.current && !originPlace) {
      onNext();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [originPlace]);

  React.useEffect(() => {
    isMountedComponent.current = true;
    return () => {
      isMountedComponent.current = false;
    };
  }, [isMountedComponent]);

  return (
    <>
      {tabResume === 1 ? (
        <BoxMUI
          sx={{
            padding: "24px",
          }}
        >
          <DetailTripResume
            dataTrip={
              <CardDetailTripData
                placeFrom={originPlace || placeFrom}
                placeTo={destinationPlace || placeTo}
                dateFrom={dateFrom}
                totalPlaces={places}
                amount={amount}
                typeId={tripTypeId}
              />
            }
            vehicle={vehicle}
            freeSeatMiddle={calculeFreeSeatMiddle}
            preferences={
              <>
                {!editTrip ? (
                  <>
                    {selectPreferences ? (
                      <>
                        <TypographyMUI className="TypographyExtrabold11pt">
                          Se permite
                        </TypographyMUI>
                        <Separator px={8} />
                        <PaperMUI elevation={7} className="ShadowCard8">
                          <BoxMUI
                            sx={{
                              padding: "12px",
                              "& .MuiChip-root": {
                                border: "none",
                                background: "none",
                                color: COLOR_QUATERNARY,
                              },
                            }}
                          >
                            <PreferencesList disabled={true} />
                          </BoxMUI>
                        </PaperMUI>
                      </>
                    ) : null}
                  </>
                ) : (
                  <>
                    {preferences.length ? (
                      <>
                        <TypographyMUI className="TypographyExtrabold11pt">
                          Se permite
                        </TypographyMUI>
                        <Separator px={8} />
                        <CardDetailTripPreferences
                          preferencesList={preferences}
                        />
                      </>
                    ) : null}
                  </>
                )}
              </>
            }
            aditional={description}
          />
        </BoxMUI>
      ) : null}
      {tabResume === 2 ? (
        <GoogleMaps
          onError={({ message }) => {
            Log.error({
              context: "CreateTripResume.GoogleMaps.1",
              message,
            });
          }}
          style={{
            width: "100vw",
            height: `calc(${AVAILABLE_SCREEN_SPACE_CSS} - 160px`,
            position: "fixed",
            bottom: "var(--ios-action-footer-height)",
          }}
        >
          <MapRoute
            originPlace={originPlace || placeFrom}
            destinationPlace={destinationPlace || placeTo}
          />
        </GoogleMaps>
      ) : null}
      {!editTrip ? (
        <CreateTripNextButton
          className={tabResume === 2 ? "Transparent" : undefined}
          text="Crear"
          disabled={false}
          onClick={handleCreateConfirm}
        />
      ) : (
        <CreateTripNextButton
          className={tabResume === 2 ? "Transparent" : undefined}
          text="Actualizar"
          disabled={false}
          onClick={handleUpdateConfirm}
        />
      )}
    </>
  );
};

export default CreateTripResume;
