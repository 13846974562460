import React from "react";
import { goStore } from "../App";
import { Imgs } from "../Assets";
import { config } from "../config";
import {
  ActionSheet,
  BoxMUI,
  ButtonMUI,
  TypographyMUI,
} from "../DeltaUI/Components";
import { UpoolContext } from "../Providers/UpoolContext";
import { SettingService } from "../Services/SettingService";
import { useComponentMount } from "../Utils";
import { CustomPage } from "./CustomPage";

interface CheckVersionAppProps {
  style?: React.CSSProperties;
  className?: string;
}

export const CheckVersionApp: React.FC<CheckVersionAppProps> = (props) => {
  const isMountedComponent = useComponentMount("CheckVersionApp");
  const { style, className } = props;
  const { getAppVersion } = SettingService();
  const { isActiveApp } = React.useContext(UpoolContext);
  const [open, setOpen] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (isActiveApp) {
      getAppVersion().then((s) => {
        if (s && Number(config.versionCode) < Number(s.configValue)) {
          setOpen(() => true);
        } else {
          setOpen(() => false);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActiveApp]);

  React.useEffect(() => {
    isMountedComponent.current = true;
    return () => {
      isMountedComponent.current = false;
    };
  }, [isMountedComponent]);

  return (
    <ActionSheet
      className="SplashScreen"
      open={open}
      style={{
        height: "100vh",
      }}
    >
      <CustomPage
        style={{
          background: "#6699ff",
          ...style,
        }}
        className={`BackgroundImage ${className}`}
      >
        <BoxMUI
          style={{
            width: "100%",
            height: "100vh",
            position: "absolute",
            bottom: 0,
            left: 0,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-evenly",
          }}
        >
          <BoxMUI
            style={{
              width: "320px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              color: "white",
              textAlign: "center",
            }}
          >
            <BoxMUI
              style={{
                width: "60vw",
                maxWidth: "400px",
              }}
            >
              <img src={Imgs.logoWhite} alt="logo" width="100%" />
            </BoxMUI>
          </BoxMUI>
          <BoxMUI
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              margin: "0 32px",
              paddingTop: "24px",
            }}
          >
            <BoxMUI
              style={{
                textAlign: "center",
                width: "260px",
              }}
            >
              <TypographyMUI
                style={{ color: "white" }}
                className="TypographyRegular11pt"
              >
                Hemos realizado cambios importantes en la aplicación, por lo
                cual es necesario actualizar a la última versión.
              </TypographyMUI>
            </BoxMUI>
          </BoxMUI>
          <ButtonMUI
            style={{ width: "50%" }}
            variant="contained"
            color="secondary"
            onClick={goStore}
          >
            Actualizar
          </ButtonMUI>
        </BoxMUI>
      </CustomPage>
    </ActionSheet>
  );
};
