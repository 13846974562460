import React from "react";
import { ToolbarMUI, BodyPage, BoxMUI } from "../DeltaUI/Components";
import { SearchAccount } from "../Components/RecoverPassword/SearchAccount";
import {
  RecoverPasswordContext,
  RecoverPasswordProvider,
  RECOVER_PASSWORD_STEPS,
} from "../Components/RecoverPassword/RecoverPasswordContext";
import { TitlePage } from "../Components/TitlePage";
import { RecoveryOptions } from "../Components/RecoverPassword/RecoveryOptions";
import { EnterCode } from "../Components/RecoverPassword/EnterCode";
import { NewPassword } from "../Components/RecoverPassword/NewPassword";
import { UpoolContext } from "../Providers/UpoolContext";
import { CustomPage, DialogCustomHook } from "../Components";
import { GoBack } from "../Components/GoBack";
import { AVAILABLE_SCREEN_SPACE_CSS } from "../Utils/Constants";

const RecoverPasswordPageContext: React.FC = () => {
  const { step } = React.useContext(RecoverPasswordContext);
  const { historyGoBack } = React.useContext(UpoolContext);
  const { message } = DialogCustomHook();
  return (
    <CustomPage
      headerPage={
        <ToolbarMUI>
          <GoBack
            onClick={() => {
              message({
                context: "RecoverPasswordPageContext.GoBack.1",
                title: "¿Querés cancelar la recuperación de contraseña?",
                message: "Perderás todos los datos ingresados hasta acá.",
                onOk: historyGoBack,
                textOnOk: "Sí",
                textOnCancel: "No",
              });
            }}
          />
          {step !== RECOVER_PASSWORD_STEPS.SEARCH_ACCOUNT ? (
            <TitlePage text="Recuperar contraseña" />
          ) : null}
        </ToolbarMUI>
      }
      headerPageProps={{
        className:
          step === RECOVER_PASSWORD_STEPS.SEARCH_ACCOUNT
            ? "Transparent"
            : undefined,
      }}
    >
      <BodyPage m={3}>
        <BoxMUI
          style={{
            height: `calc(${AVAILABLE_SCREEN_SPACE_CSS} - 56px - 48px)`,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <BoxMUI
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {step === RECOVER_PASSWORD_STEPS.SEARCH_ACCOUNT ? (
              <SearchAccount />
            ) : null}
            {step === RECOVER_PASSWORD_STEPS.RECOVERY_OPTIONS ? (
              <RecoveryOptions />
            ) : null}
            {step === RECOVER_PASSWORD_STEPS.ENTRE_CODE ? <EnterCode /> : null}
            {step === RECOVER_PASSWORD_STEPS.NEW_PASSWORD ? (
              <NewPassword />
            ) : null}
          </BoxMUI>
        </BoxMUI>
      </BodyPage>
    </CustomPage>
  );
};

const RecoverPasswordPage: React.FC<{}> = () => {
  return (
    <RecoverPasswordProvider>
      <RecoverPasswordPageContext />
    </RecoverPasswordProvider>
  );
};

export default RecoverPasswordPage;
