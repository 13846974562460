import React from "react";
import {
  ActionSheet,
  BoxMUI,
  ButtonMUI,
  Separator,
  TypographyMUI,
} from "../../DeltaUI/Components";
import { UpoolContext } from "../../Providers/UpoolContext";
import { useComponentMount } from "../../Utils";
import { COLOR_SECONDARY } from "../../Utils/Constants";
import { CustomPage } from "../CustomPage";
import { CreateTripContext } from "./CreateTripContext";

interface CreateTripEndProps {
  style?: React.CSSProperties;
  className?: string;
  open: boolean;
  onOk?: () => void;
  image?: string;
}

export const CreateTripEnd: React.FC<CreateTripEndProps> = (props) => {
  const isMountedComponent = useComponentMount("CreateTripEnd");
  const { style, className, open, onOk, image } = props;
  const { resultCreateOneTrip } = React.useContext(CreateTripContext);
  const { shareTripStretch, setShareTripStretch } = React.useContext(
    UpoolContext
  );

  React.useEffect(() => {
    if (isMountedComponent.current && open) {
      if (shareTripStretch) {
        resultCreateOneTrip.current = undefined;
      } else if (onOk && !resultCreateOneTrip.current) {
        onOk();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shareTripStretch]);

  React.useEffect(() => {
    isMountedComponent.current = true;
    return () => {
      isMountedComponent.current = false;
    };
  }, [isMountedComponent]);

  return (
    <ActionSheet
      open={open}
      style={{
        height: "100vh",
      }}
    >
      <CustomPage
        style={{
          background: COLOR_SECONDARY,
          ...style,
        }}
        className={className}
        sx={{
          "ion-content": {
            "--background": COLOR_SECONDARY,
          },
        }}
      >
        <BoxMUI
          className="BackgroundImage"
          style={{
            position: "absolute",
            top: 0,
            width: "100%",
            height: "100%",
            backgroundImage: `url(${image})`,
          }}
        >
          <BoxMUI
            style={{
              width: "100%",
              height: "50vh",
              position: "absolute",
              bottom: 0,
              left: 0,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "start",
            }}
          >
            <Separator px={24} />
            <BoxMUI
              style={{
                width: "320px",
                textAlign: "center",
                color: "white",
              }}
            >
              <TypographyMUI className="TypographyBold20pt" color="white">
                ¡Viaje creado!
              </TypographyMUI>
              <Separator px={8} />
              <TypographyMUI color="white" className="TypographyRegular13pt">
                Ya creaste tu próximo viaje.
              </TypographyMUI>
              <TypographyMUI color="white" className="TypographyRegular13pt">
                ¡Te notificaremos cuando haya interesados en viajar con vos!
              </TypographyMUI>
            </BoxMUI>
            <Separator px={16} />
            <ButtonMUI
              style={{
                width: "200px",
              }}
              variant="contained"
              color="primary"
              onClick={onOk}
            >
              Aceptar
            </ButtonMUI>
            <Separator px={32} />
            <BoxMUI
              style={{
                width: "320px",
                textAlign: "center",
                color: "white",
              }}
            >
              <TypographyMUI color="white" className="TypographyRegular11pt">
                Llegá a más personas compartiendo
              </TypographyMUI>
            </BoxMUI>
            <Separator px={16} />
            <ButtonMUI
              style={{
                width: "200px",
                color: "white",
                background: "transparent",
                border: "1px solid white",
              }}
              variant="text"
              onClick={() =>
                setShareTripStretch(
                  () => resultCreateOneTrip.current?.tripStretch
                )
              }
            >
              Compartir
            </ButtonMUI>
            <Separator px={24} />
          </BoxMUI>
        </BoxMUI>
      </CustomPage>
    </ActionSheet>
  );
};
