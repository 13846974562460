import React from "react";
import { UpoolContext } from "../../Providers/UpoolContext";
import { SetterState } from "../../Services/DefaultTypes";
import { IUser } from "../../Services/UserService";
import { useComponentMount } from "../../Utils";

interface IUserContext {
  viewUser: IUser;
  setViewUser: SetterState<IUser>;
  myUser: boolean;
  scoresUserPageMode: string;
  setScoresUserPageMode: SetterState<string>;
}

const context: IUserContext = {
  viewUser: null as any,
  setViewUser: () => {},
  myUser: false,
  scoresUserPageMode: "",
  setScoresUserPageMode: () => {},
};

export const UserContext = React.createContext(context);

export interface UserProviderProps {
  initUser: IUser;
  myUser: boolean;
}

export const UserProvider: React.FC<UserProviderProps> = (props) => {
  const isMountedComponent = useComponentMount("UserProvider");
  const { initUser, myUser } = props;
  const { user } = React.useContext(UpoolContext);
  const [viewUser, setViewUser] = React.useState<IUser>(initUser);
  const [scoresUserPageMode, setScoresUserPageMode] = React.useState<string>(
    ""
  );

  React.useEffect(() => {
    if (user && viewUser.id === user.id) {
      setViewUser(() => user);
    }
    // TODO: chequear estas dependencias
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  React.useEffect(() => {
    isMountedComponent.current = true;
    return () => {
      isMountedComponent.current = false;
    };
  }, [isMountedComponent]);

  return (
    <UserContext.Provider
      value={{
        viewUser,
        setViewUser,
        myUser,
        scoresUserPageMode,
        setScoresUserPageMode,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};
