import { NOTIFICATION_CREATED } from "./NotificationSubscription";
import { RESERVATION_CHAT_CREATED } from "./ReservationChatSubscription";

export const Subscription = {
  reservationChatCreated: {
    name: "reservationChatCreated",
    gql: RESERVATION_CHAT_CREATED,
  },
  notificationCreated: {
    name: "notificationCreated",
    gql: NOTIFICATION_CREATED,
  },
};
