import React from "react";
import { Imgs } from "../../Assets";
import {
  ActionSheet,
  BoxMUI,
  ButtonMUI,
  SegmentIcon,
  Separator,
  TypographyMUI,
} from "../../DeltaUI/Components";
import {
  CarSeatIcon,
  SteeringWheelIcon,
} from "../../DeltaUI/Components/CustomIcon";
import { AppContext } from "../../Providers/AppContext";
import { COLOR_SECONDARY } from "../../Utils/Constants";
import { CustomPage } from "../CustomPage";

interface WelcomeToMyTripsProps {
  style?: React.CSSProperties;
  className?: string;
}

export const WelcomeToMyTrips: React.FC<WelcomeToMyTripsProps> = (props) => {
  const { style, className } = props;
  const { isWelcomeToMyTrips, setWelcomeToMyTrips } = React.useContext(
    AppContext
  );

  return (
    <ActionSheet
      open={isWelcomeToMyTrips}
      style={{
        height: "100vh",
        zIndex: 6000,
      }}
    >
      <CustomPage
        style={{
          backgroundImage: `url(${Imgs.welcomeToMyTrips})`,
          ...style,
        }}
        className={`BackgroundImage ${className}`}
      >
        <BoxMUI
          style={{
            width: "100%",
            height: "100vh",
            position: "absolute",
            bottom: 0,
            left: 0,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-evenly",
          }}
        >
          <BoxMUI
            style={{
              textAlign: "center",
              color: "white",
              margin: "0 32px",
            }}
          >
            <TypographyMUI
              className="TypographyBold20pt"
              color={COLOR_SECONDARY}
            >
              ¡Bienvenido a
            </TypographyMUI>
            <TypographyMUI
              className="TypographyBold20pt"
              color={COLOR_SECONDARY}
            >
              Mis viajes!
            </TypographyMUI>
          </BoxMUI>
          <BoxMUI
            style={{
              textAlign: "center",
              width: "230px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <SegmentIcon
              fixedMode={true}
              size="large"
              defaultValue={1}
              value={1}
              list={[
                {
                  key: 1,
                  element: <CarSeatIcon />,
                },
                {
                  key: 2,
                  element: <SteeringWheelIcon />,
                },
              ]}
            />
            <Separator px={16} />
            <TypographyMUI className="TypographyRegular11pt">
              Como pasajero podrás ver los viajes a los que te uniste
            </TypographyMUI>
          </BoxMUI>
          <BoxMUI
            style={{
              textAlign: "center",
              width: "230px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <SegmentIcon
              fixedMode={true}
              size="large"
              defaultValue={2}
              value={2}
              list={[
                {
                  key: 1,
                  element: <CarSeatIcon />,
                },
                {
                  key: 2,
                  element: <SteeringWheelIcon />,
                },
              ]}
            />
            <Separator px={16} />
            <TypographyMUI className="TypographyRegular11pt">
              Como conductor podrás ver los viajes que creaste
            </TypographyMUI>
          </BoxMUI>
          <ButtonMUI
            style={{ width: "50%" }}
            variant="contained"
            color="primary"
            onClick={() => setWelcomeToMyTrips(false)}
          >
            Entendido
          </ButtonMUI>
        </BoxMUI>
      </CustomPage>
    </ActionSheet>
  );
};
