import React from "react";
import {
  BoxMUI,
  CardCarousel,
  RatingMUI,
  Separator,
  TypographyMUI,
} from "../../DeltaUI/Components";
import { IRate } from "../../Services/TripService";
import { ItemUser } from "../User/ItemUser";

export interface CardScoreUserProps {
  rate: IRate;
  isDrive: boolean;
}

const CardScoreUser: React.FC<CardScoreUserProps> = (props) => {
  const { rate, isDrive } = props;
  return (
    <CardCarousel separation={16}>
      <BoxMUI p={2}>
        <ItemUser
          sizeAvatar={5}
          user={rate.userFrom}
          isDrive={!isDrive}
          disabled={true}
        />
        <Separator px={16} />
        <RatingMUI
          style={{ fontSize: "1.3rem" }}
          readOnly
          value={Number(rate.score)}
          precision={0.1}
        />
        <TypographyMUI>
          {rate.description || "No comentó nada"}
        </TypographyMUI>
      </BoxMUI>
    </CardCarousel>
  );
};

export default CardScoreUser;
