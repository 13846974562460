import React from "react";
import {
  CardMUI,
  BadgeMUI,
  BoxMUI,
  IconButtonMUI,
  SwipeableActionSheet,
  Separator,
  TypographyMUI,
  SwipeableActionSheetItem,
} from "../../DeltaUI/Components";
import {
  ThumbDownIcon,
  ForumOutlinedIcon,
  MoreIcon,
  VisibilityIcon,
} from "../../DeltaUI/Components/CustomIcon";
import {
  IReservation,
  ReservationService,
} from "../../Services/ReservationService";
import { Safely, Tools } from "../../Utils";
import {
  COLOR_PRIMARY,
  DETAIL_TRIP_MODE,
  NOTIFICATION_TYPE,
  RESERVATION_STATUS,
  TRIP_STATUS,
} from "../../Utils/Constants";
import { UpoolContext } from "../../Providers/UpoolContext";
import { MyTripsContext } from "../MyTrips/MyTripsContext";
import { ItemUser } from "../User/ItemUser";
import { DialogCustomFooterActions, DialogCustomHook } from "../DialogCustom";
import LoadData from "../LoadData";
import { EventAppContext } from "../../Providers/EventAppProvider";

export interface CardReservationProps {
  reservation: IReservation;
  onChange?: () => void;
}

const CardReservation: React.FC<CardReservationProps> = (props) => {
  const { reservation, onChange } = props;
  const { handleEventNotificationMarkAsAttended } =
    React.useContext(EventAppContext);
  const { getPerson } = Tools();
  const { eventSafely } = Safely();
  const { message, messageError } = DialogCustomHook();
  const { approveOneReservation } = ReservationService();
  const { setLoading, historyPush, selectPathTab, user, notificationGeneral } =
    React.useContext(UpoolContext);
  const {
    setReservationForChat,
    setReservationForRate,
    detailTripMode,
    isDriverOfSelectedTripStretch,
    setCancelReservation,
    setRatingView,
    setCanceledMessageView,
  } = React.useContext(MyTripsContext);
  const [viewFilter, setViewFilter] = React.useState<boolean>(false);

  const handleApproveOneReservation = (approve: boolean) => {
    setLoading(() => true);
    approveOneReservation(reservation.id, approve)
      .then(() => {
        handleEventNotificationMarkAsAttended(
          notificationGeneral.filter(
            (n) =>
              n.type === NOTIFICATION_TYPE.RESERVATION_REQUEST &&
              n.payload.reservationId === reservation.id
          )
        );
        if (onChange) {
          onChange();
        }
      })
      .catch(({ message }) => {
        messageError({
          context: "CardReservation.handleApproveOneReservation.1",
          message: message,
        });
      })
      .finally(() => setLoading(() => false));
  };

  const reservationUser = React.useMemo(
    () => reservation.user,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [reservation]
  );

  const person = React.useMemo(
    () => getPerson(reservationUser, 0),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [reservationUser]
  );

  const name = React.useMemo(
    () => (person ? `${person.firstName} ${person.lastName}` : ""),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [person]
  );

  const handleClickOption = (callback?: Function) => {
    setViewFilter(() => false);
    if (callback && typeof callback === "function") {
      callback();
    }
  };

  const ratingForCurrentUser = React.useMemo(() => {
    return reservation.rates.find((re) => re.userToId === user?.id);
  }, [reservation, user]);

  const canceledMessageForCurrentUser = React.useMemo(() => {
    return !!reservation.canceledMessage;
  }, [reservation]);

  return (
    <>
      <CardMUI
        elevation={7}
        style={{
          marginBottom: "16px",
        }}
        className="ShadowCard8"
      >
        <Separator px={16} />
        <BoxMUI
          style={{
            paddingLeft: "24px",
            paddingRight: "8px",
          }}
          className="FlexRow"
        >
          <ItemUser user={reservationUser} isDrive={false} sizeAvatar={5} />
          {isDriverOfSelectedTripStretch &&
          detailTripMode === DETAIL_TRIP_MODE.MY_TRIPS ? (
            <BoxMUI
              style={{
                display: "flex",
              }}
            >
              <IconButtonMUI
                onClick={eventSafely(() => {
                  setReservationForChat(() => reservation);
                })}
              >
                <BadgeMUI
                  badgeContent={reservation.unreadChats}
                  color="secondary"
                >
                  <ForumOutlinedIcon style={{ color: COLOR_PRIMARY }} />
                </BadgeMUI>
              </IconButtonMUI>
              <IconButtonMUI onClick={() => setViewFilter(() => true)}>
                <MoreIcon style={{ color: COLOR_PRIMARY }} />
              </IconButtonMUI>
            </BoxMUI>
          ) : null}
        </BoxMUI>
        <Separator px={8} />
        {[
          RESERVATION_STATUS.IN_REVIEW,
          RESERVATION_STATUS.IN_PROGRESS,
          RESERVATION_STATUS.FINISHED,
        ].some((statusId) => reservation.statusId === statusId) &&
        reservation.quantity > 1 ? (
          <>
            <Separator px={4} divider />
            <BoxMUI
              style={{
                padding: "4px 16px",
              }}
              className="FlexRow"
            >
              {reservation.statusId === RESERVATION_STATUS.IN_REVIEW ? (
                <TypographyMUI color="secondary" className="TypographyBold10pt">
                  {`${reservation.quantity} lugares a reservar`}
                </TypographyMUI>
              ) : (
                <TypographyMUI color="secondary" className="TypographyBold10pt">
                  {`${reservation.quantity} lugares reservados`}
                </TypographyMUI>
              )}
            </BoxMUI>
            <Separator px={4} />
          </>
        ) : (
          <Separator px={8} />
        )}
        {isDriverOfSelectedTripStretch &&
        reservation.statusId === RESERVATION_STATUS.IN_REVIEW &&
        detailTripMode === DETAIL_TRIP_MODE.MY_TRIPS ? (
          <>
            <Separator px={0} divider />
            <DialogCustomFooterActions
              onOk={eventSafely(() => handleApproveOneReservation(true))}
              onCancel={eventSafely(() =>
                message({
                  context: "CardReservation.eventSafely.1",
                  title: "Rechazar",
                  message: `¿Querés rechazar a ${name}?`,
                  onOk: () => handleApproveOneReservation(false),
                  textOnOk: "Sí",
                  textOnCancel: "No",
                })
              )}
              textOnCancel="Rechazar"
            />
          </>
        ) : null}
        {isDriverOfSelectedTripStretch &&
        (reservation.tripStretch.trip.statusId === TRIP_STATUS.DONE ||
          reservation.tripStretch.trip.statusId === TRIP_STATUS.CANCELED) &&
        (reservation.statusId === RESERVATION_STATUS.FINISHED ||
          reservation.statusId === RESERVATION_STATUS.CANCELED_BY_PASSENGER ||
          reservation.statusId === RESERVATION_STATUS.CANCELED_BY_DRIVER) &&
        !reservation.rates.some((re) => re.userFromId === user?.id) &&
        detailTripMode === DETAIL_TRIP_MODE.MY_TRIPS ? (
          <>
            <Separator px={0} divider />
            <DialogCustomFooterActions
              onOk={eventSafely(() => {
                setReservationForRate(() => reservation);
                historyPush(`${selectPathTab}/rate`);
              })}
              textOnOk="Calificar"
            />
          </>
        ) : null}
      </CardMUI>
      <SwipeableActionSheet
        open={viewFilter}
        onClose={() => handleClickOption()}
        type="options"
      >
        {reservation.statusId === RESERVATION_STATUS.IN_PROGRESS ? (
          <SwipeableActionSheetItem
            onClick={eventSafely(() =>
              handleClickOption(() => setCancelReservation(() => reservation))
            )}
            text="Dar de baja"
            icon={<ThumbDownIcon style={{ color: COLOR_PRIMARY }} />}
          />
        ) : null}

        {ratingForCurrentUser ? (
          <SwipeableActionSheetItem
            onClick={eventSafely(() =>
              handleClickOption(() =>
                setRatingView(() => ({
                  rate: ratingForCurrentUser,
                  userFromIsDriver: false,
                }))
              )
            )}
            text="Ver calificación"
            icon={<VisibilityIcon style={{ color: COLOR_PRIMARY }} />}
          />
        ) : null}

        {canceledMessageForCurrentUser ? (
          <SwipeableActionSheetItem
            onClick={eventSafely(() =>
              handleClickOption(() => setCanceledMessageView(() => reservation))
            )}
            text="Ver motivo de baja"
            icon={<VisibilityIcon style={{ color: COLOR_PRIMARY }} />}
          />
        ) : null}

        {reservation.statusId !== RESERVATION_STATUS.IN_PROGRESS &&
        !ratingForCurrentUser &&
        !canceledMessageForCurrentUser ? (
          <LoadData description="Sin opciones" empty={true} loading={false} />
        ) : null}
      </SwipeableActionSheet>
    </>
  );
};

export default CardReservation;
