import React from "react";
import { BoxMUI, PaperMUI, TypographyMUI } from "../../DeltaUI/Components";
import { IReservationChat } from "../../Services/ReservationChatService";
import { MomentJS } from "../../Utils";
import { COLOR_QUATERNARY } from "../../Utils/Constants";
import { StatusMessage } from "./StatusMessage";

export interface ItemChatProps {
  myMessage?: boolean;
  message: IReservationChat;
  sending?: boolean;
  sent?: boolean;
  received?: boolean;
  seen?: boolean;
}

const ItemChat: React.FC<ItemChatProps> = (props) => {
  const { message, myMessage, sending, sent, received, seen } = props;

  const inOtherLine = React.useMemo(() => message.description.length > 15, [
    message,
  ]);

  const { getTime } = MomentJS();

  return (
    <div
      style={{
        display: "flex",
        justifyContent: myMessage ? "flex-end" : "flex-start",
      }}
    >
      <PaperMUI
        style={{
          width: "auto !important",
          maxWidth: "80%",
          margin: "1px 24px",
          borderRadius: "8px",
        }}
        sx={{
          color: () => (myMessage ? "white" : COLOR_QUATERNARY),
          background: (theme) =>
            myMessage
              ? theme.palette.primary.main
              : `${theme.palette.secondary.main}15`,
        }}
      >
        <BoxMUI
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <BoxMUI
            style={{
              display: "flex",
              flexDirection: inOtherLine ? "column" : "row",
              justifyContent: "space-between",
              padding: "8px",
            }}
          >
            <TypographyMUI
              className="TypographyRegular11pt"
              sx={{
                color: () => (myMessage ? "white" : COLOR_QUATERNARY),
              }}
            >
              {message.description}
            </TypographyMUI>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                marginLeft: "8px",
                marginRight: "4px",
                paddingTop: inOtherLine ? 0 : "12px",
                float: "right",
                fontSize: "10pt",
              }}
            >
              <div
                style={{
                  display: "flex",
                }}
              >
                {myMessage ? (
                  <TypographyMUI className="TypographyRegular8pt" color="white">
                    {getTime(message.createdAt)}
                  </TypographyMUI>
                ) : (
                  <TypographyMUI
                    className="TypographyRegular8pt"
                    color={COLOR_QUATERNARY}
                  >
                    {getTime(message.createdAt)}
                  </TypographyMUI>
                )}

                {myMessage ? (
                  <StatusMessage
                    sending={sending}
                    sent={sent}
                    received={received}
                    seen={seen}
                    style={{ marginLeft: "4px" }}
                    color="secondary"
                    checkColor="secondary"
                  />
                ) : null}
              </div>
            </div>
          </BoxMUI>
        </BoxMUI>
      </PaperMUI>
    </div>
  );
};

export default ItemChat;
