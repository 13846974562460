import React from "react";
import { UpoolContext } from "./Providers/UpoolContext";
import {
  CheckVersionApp,
  DialogCustomHook,
  SecurityLayout,
} from "./Components";
import LoginLayout from "./Components/Layout/LoginLayout";
import { BoxMUI, TimeoutPromise } from "./DeltaUI/Components";
import { IAuthPayload, UserService } from "./Services/UserService";
import { SplashScreen } from "./Components/SplashScreen";
import { SplashScreen as SplashScreenCAP } from "@capacitor/splash-screen";
import { Onboarding } from "./Components/Onboarding/Onboarding";
import { FullScreenImage } from "./Components/FullScreenImage";
import { useCustomLog } from "./Utils";
import { STORAGE_KEY } from "./Utils/Constants";
import { Preferences } from "@capacitor/preferences";
import { PreferenceUserContextProvider } from "./Providers/PreferenceUserContextProvider";
import { AppContext } from "./Providers/AppContext";
import { FileUploadService } from "./Services/FileUploadService";
import { EventAppContext } from "./Providers/EventAppProvider";
import PublicLayout from "./Public/PublicLayout";
import { COMPILATION_TYPE, config } from "./config";
import { CustomApolloLibraryContext } from "./Providers/CustomApolloLibrary";

export interface InitAppProps {}

const InitAppMobile: React.FC<InitAppProps> = () => {
  const isMountedComponent = React.useRef<boolean>(false);
  const {
    currentUser,
    setLastConnection,
    refreshToken,
    upsertOneUserDevice,
    logout,
  } = UserService();
  const { setToken, token } = React.useContext(CustomApolloLibraryContext);
  const { uploadPictureUrl } = FileUploadService();
  const { messageWarn, messageError } = DialogCustomHook();
  const Log = useCustomLog();
  const { updatePosition } = React.useContext(AppContext);
  const {
    eventLogin,
    eventUnauthenticatedUser,
    eventInfiniteLoop,
  } = React.useContext(EventAppContext);
  const {
    user,
    setUser,
    viewSplashScreen,
    setViewSplashScreen,
    viewOnboarding,
    setViewOnboarding,
    viewFullScreenImage,
    setViewFullScreenImage,
    authPayload,
    setLoading,
    uploadProfilePictureFile,
    setUploadProfilePictureFile,
  } = React.useContext(UpoolContext);

  const handleCurrentUser = () => {
    currentUser()
      .catch(({ message }) => {
        setLoading(() => false);
        Log.error({
          context: "InitApp.handleCurrentUser.1",
          message,
        });
      })
      .finally(() => {
        setViewSplashScreen(() => false);
      });
  };

  const handleSetLastConnection = () => {
    updatePosition();
    return setLastConnection()
      .then(() => {
        handleCurrentUser();
      })
      .catch(({ message }) => {
        Log.error({
          context: "InitApp.handleSetLastConnection.1",
          message,
        });
      });
  };

  const handleRefreshToken = (data: IAuthPayload) => {
    refreshToken(data).catch(({ message }) => {
      setViewSplashScreen(() => false);
      Log.error({
        context: "InitApp.handleRefreshToken.1",
        message,
      });
    });
  };

  const init = async () => {
    SplashScreenCAP.hide();
    const value = await Preferences.get({
      key: STORAGE_KEY.AUTH_PAYLOAD,
    }).then((s) => (s.value ? JSON.parse(s.value) : undefined));
    if (value) {
      handleRefreshToken(value);
    } else {
      setViewSplashScreen(() => false);
    }
  };

  React.useEffect(() => {
    if (uploadProfilePictureFile && viewOnboarding && user) {
      uploadPictureUrl(uploadProfilePictureFile)
        .then((pictureUrl) => {
          setUser((u) => {
            if (u) {
              return {
                ...u,
                person: u.person.map((p) => ({ ...p, pictureUrl })),
              };
            }
            return u;
          });
        })
        .catch(({ message }) => {
          messageWarn({
            context: "InitApp.uploadPictureUrl.1",
            message:
              "La imagen no ha subido correctamente, puede que sea demasiado grande. Vuelve a intentar luego desde la configuración de tu perfil.",
            messageError: message,
          });
        })
        .finally(() => setUploadProfilePictureFile(() => undefined));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadProfilePictureFile, viewOnboarding, user]);

  React.useEffect(() => {
    if (isMountedComponent.current && eventUnauthenticatedUser) {
      const err = eventUnauthenticatedUser.data || {};
      messageError({
        context: "InitApp.eventUnauthenticatedUser.1",
        message: err.message,
      });
      logout();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventUnauthenticatedUser.count]);

  React.useEffect(() => {
    if (!user && authPayload) {
      setViewSplashScreen(() => true);
      handleRefreshToken(authPayload);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  React.useEffect(() => {
    if (viewOnboarding) {
      setLoading(() => false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewOnboarding]);

  React.useEffect(() => {
    if (token) {
      handleCurrentUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  React.useEffect(() => {
    if (authPayload) {
      setToken(() => authPayload.accessToken);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authPayload]);

  React.useEffect(() => {
    if (user) {
      upsertOneUserDevice();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventLogin]);

  React.useEffect(() => {
    if (isMountedComponent.current && eventInfiniteLoop.data) {
      Log.error({
        context: "InitApp.eventInfiniteLoop.1",
        message: `Bucle infinito en ${eventInfiniteLoop.data}`,
      }).catch(() => {
        // si no loguea no podemos hacer nada
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventInfiniteLoop.count]);

  React.useEffect(() => {
    isMountedComponent.current = true;
    // tiempo para que se muestre el splash
    let t = setTimeout(() => {
      init();
    }, 500);
    return () => {
      isMountedComponent.current = false;
      clearTimeout(t);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BoxMUI className="App">
      {user ? (
        <TimeoutPromise time={120000} promise={handleSetLastConnection} />
      ) : null}
      <SplashScreen open={viewSplashScreen} background="#6699ff" />
      <CheckVersionApp />
      <Onboarding
        open={viewOnboarding}
        onFinish={() => setViewOnboarding(() => false)}
      />
      {viewFullScreenImage ? (
        <FullScreenImage
          image={viewFullScreenImage}
          onClose={() => setViewFullScreenImage(() => undefined)}
        />
      ) : null}
      {!user ? <LoginLayout /> : null}
      <PreferenceUserContextProvider>
        {user ? <SecurityLayout /> : null}
      </PreferenceUserContextProvider>
    </BoxMUI>
  );
};

const InitApp: React.FC<InitAppProps> = (props) => {
  return (
    <>
      {config.compilationMode === COMPILATION_TYPE.MOBILE ? (
        <InitAppMobile {...props} />
      ) : (
        <BoxMUI className="App">
          <PublicLayout />
        </BoxMUI>
      )}
    </>
  );
};

export default InitApp;
