import React from "react";
import {
  BadgeMUI,
  BoxMUI,
  IconButtonMUI,
  TypographyMUI,
} from "../../DeltaUI/Components";
import { NotificationsOutlinedIcon } from "../../DeltaUI/Components/CustomIcon";
import { ITripStretch } from "../../Services/TripService";
import { Safely, useComponentMount } from "../../Utils";
import {
  COLOR_QUINARY,
  COLOR_SECONDARY,
  DETAIL_TRIP_TAB,
  RESERVATION_STATUS,
  TRIP_STATUS,
} from "../../Utils/Constants";
import { MyTripsContext } from "../MyTrips/MyTripsContext";

interface CardTripDescriptionStatusTripProps {
  style?: React.CSSProperties;
  className?: string;
  tripStretch: ITripStretch;
  isCard: boolean;
}

export const CardTripDescriptionStatusTrip: React.FC<CardTripDescriptionStatusTripProps> = (
  props
) => {
  const isMountedComponent = useComponentMount("CardTripDescriptionStatusTrip");
  const { style, className, tripStretch, isCard } = props;
  const { eventSafely } = Safely();
  const {
    setSelectTripStretch,
    setDetailTripTab,
    isRateCalculate,
  } = React.useContext(MyTripsContext);

  const isDriver = React.useMemo(() => {
    return tripStretch.isDriver;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tripStretch]);

  const myReservation = React.useMemo(() => {
    return tripStretch?.myReservation;
  }, [tripStretch]);

  const isRate = React.useMemo(() => {
    return !!isRateCalculate(tripStretch);
  }, [tripStretch, isRateCalculate]);

  /**
   * Calcula todas las notificaciones no atendidas de este viaje
   */
  const badgeContent = React.useMemo(() => {
    let count = 0;
    if (myReservation && myReservation?.unreadChats) {
      count = count + 1;
    } else if (!myReservation) {
      count =
        count +
        tripStretch.reservations.filter(
          (r) => r.statusId === RESERVATION_STATUS.IN_REVIEW
        ).length;
      count =
        count +
        tripStretch.reservations.reduce(
          (count, r) => (r.unreadChats ? count + r.unreadChats : count),
          0
        );
    }
    count = count + isRateCalculate(tripStretch);
    return count;
  }, [isRateCalculate, myReservation, tripStretch]);

  const status: string = React.useMemo(() => {
    if (isDriver) {
      if (tripStretch.trip.statusId === TRIP_STATUS.AVAILABLE) {
        return "Viaje pendiente";
      } else if (tripStretch.trip.statusId === TRIP_STATUS.IN_PROGRESS) {
        return "Viaje en curso";
      } else if (tripStretch.trip.statusId === TRIP_STATUS.DONE) {
        return isRate ? "Viaje realizado - Falta calificar" : "Viaje realizado";
      } else if (tripStretch.trip.statusId === TRIP_STATUS.CANCELED) {
        return isRate ? "Viaje cancelado - Falta calificar" : "Viaje cancelado";
      }
    } else if (myReservation) {
      if (myReservation.statusId === RESERVATION_STATUS.CREATED) {
        return "Preguntaste";
      } else if (
        myReservation.statusId === RESERVATION_STATUS.DELETED_BY_PASSENGER
      ) {
        return "Eliminado";
      } else if (myReservation.statusId === RESERVATION_STATUS.IN_REVIEW) {
        return "Esperando aprobación";
      } else if (
        myReservation.statusId === RESERVATION_STATUS.REJECTED_BY_DRIVER
      ) {
        return "Solicitud rechazada";
      } else if (
        myReservation.statusId === RESERVATION_STATUS.REJECTED_BY_SYSTEM
      ) {
        return "Solicitud expirada";
      } else if (
        myReservation.statusId === RESERVATION_STATUS.IN_PROGRESS &&
        !(tripStretch.trip.statusId === TRIP_STATUS.IN_PROGRESS)
      ) {
        return "Viaje pendiente";
      } else if (
        myReservation.statusId === RESERVATION_STATUS.IN_PROGRESS &&
        tripStretch.trip.statusId === TRIP_STATUS.IN_PROGRESS
      ) {
        return "Viaje en curso";
      } else if (
        myReservation.statusId === RESERVATION_STATUS.CANCELED_BY_PASSENGER
      ) {
        if (tripStretch.trip.statusId === TRIP_STATUS.CANCELED) {
          return isRate
            ? "Te diste de baja - Sin calificar"
            : "Te diste de baja";
        }
        if (tripStretch.trip.statusId === TRIP_STATUS.DONE) {
          return isRate
            ? "Te diste de baja - Sin calificar"
            : "Te diste de baja";
        }
        return "Te diste de baja";
      } else if (
        myReservation.statusId === RESERVATION_STATUS.CANCELED_BY_DRIVER
      ) {
        if (tripStretch.trip.statusId === TRIP_STATUS.CANCELED) {
          return isRate ? "Viaje cancelado - Sin calificar" : "Viaje cancelado";
        }
        if (tripStretch.trip.statusId === TRIP_STATUS.DONE) {
          return isRate
            ? "Te dieron de baja - Sin calificar"
            : "Te dieron de baja";
        }
        return "Te dieron de baja";
      } else if (myReservation.statusId === RESERVATION_STATUS.FINISHED) {
        return isRate ? "Viaje realizado - Sin calificar" : "Viaje realizado";
      }
    }
    return "";
  }, [isDriver, isRate, myReservation, tripStretch]);

  const isView = React.useMemo(() => {
    return isDriver || (!isDriver && myReservation);
  }, [isDriver, myReservation]);

  React.useEffect(() => {
    isMountedComponent.current = true;
    return () => {
      isMountedComponent.current = false;
    };
  }, [isMountedComponent]);

  return (
    <BoxMUI
      style={{
        padding: "8px 24px",
        ...style,
      }}
      className={`${className} FlexRow JustifyBetween`}
    >
      <BoxMUI className="FlexColumn">
        {isView ? (
          <>
            <BoxMUI>
              <TypographyMUI
                color={COLOR_QUINARY}
                className="TypographyRegular10pt"
              >
                Estado del viaje
              </TypographyMUI>
            </BoxMUI>
            <BoxMUI>
              <TypographyMUI
                className="TypographyBold11pt"
                color={COLOR_SECONDARY}
              >
                {status}
              </TypographyMUI>
            </BoxMUI>
          </>
        ) : null}
      </BoxMUI>
      {isCard ? (
        <BoxMUI>
          {myReservation ? (
            <>
              <IconButtonMUI
                onClick={eventSafely(() => {
                  setSelectTripStretch(() => tripStretch);
                })}
              >
                <BadgeMUI badgeContent={badgeContent} color="secondary">
                  <NotificationsOutlinedIcon />
                </BadgeMUI>
              </IconButtonMUI>
            </>
          ) : (
            <IconButtonMUI
              onClick={eventSafely(() => {
                setSelectTripStretch(() => tripStretch);
                setDetailTripTab(() => DETAIL_TRIP_TAB.RESERVATIONS);
              })}
            >
              <BadgeMUI badgeContent={badgeContent} color="secondary">
                <NotificationsOutlinedIcon />
              </BadgeMUI>
            </IconButtonMUI>
          )}
        </BoxMUI>
      ) : null}
    </BoxMUI>
  );
};
