import React from "react";
import { UpoolContext } from "../../Providers/UpoolContext";
import {
  ButtonMUI,
  FormControlMUI,
  InputLabelMUI,
  MenuItemMUI,
  SelectMUI,
  SwipeableActionSheet,
  ToolbarMUI,
  TypographyMUI,
  BodyPage,
  Separator,
  BoxMUI,
  InputMUI,
  FormHelperText,
} from "../../DeltaUI/Components";
import {
  IColour,
  IVehicleBrand,
  IVehicleModel,
  VehicleService,
} from "../../Services/VehicleService";
import {
  DialogCustom,
  DialogCustomFooterActions,
  DialogCustomHook,
} from "../DialogCustom";
import { TitlePage } from "../TitlePage";
import { GoBack } from "../GoBack";
import { useComponentMount, useCustomLog } from "../../Utils";
import { CustomPage } from "../CustomPage";

export interface CreateOneVehicleProps {
  open: boolean;
  onClose: () => void;
}

const CreateOneVehicle: React.FC<CreateOneVehicleProps> = (props) => {
  const isMountedComponent = useComponentMount("CreateOneVehicle");
  const { open, onClose } = props;
  const Log = useCustomLog();
  const { setUser, setLoading, loading } = React.useContext(UpoolContext);
  const { messageError } = DialogCustomHook();
  const {
    createOneVehicle,
    vehicleModels,
    vehicleBrands,
    colours,
  } = VehicleService();
  const [vehicleBrandList, setVehicleBrandList] = React.useState<
    IVehicleBrand[]
  >([]);
  const [vehicleModelList, setVehicleModelList] = React.useState<
    IVehicleModel[]
  >([]);
  const [coloursList, setColoursList] = React.useState<IColour[]>([]);

  const [licensePlate, setLicensePlate] = React.useState<string>("");
  const [description, setDescription] = React.useState<string>("");
  const [colourId, setColourId] = React.useState<number | undefined>();
  const [brandId, setBrandId] = React.useState<number | undefined>();
  const [modelId, setModelId] = React.useState<number | undefined>();
  const [messageConfirm, setMessageConfirm] = React.useState<boolean>(false);

  // solo permita agregar una patente LLLDDDD o LLDDDLL siendo L una letra y D un dígito
  const validLicensePlate = React.useMemo(() => {
    return (c?: string) =>
      !!(
        c &&
        (/^[A-Z]{3}[0-9]{3}$/.test(c) ||
          /^[A-Z]{1}[0-9]{3}[A-Z]{3}$/.test(c) ||
          /^[A-Z]{2}[0-9]{3}[A-Z]{2}$/.test(c))
      );
  }, []);

  const clean = () => {
    setLicensePlate(() => "");
    setDescription(() => "");
    setColourId(() => undefined);
    setBrandId(() => undefined);
    setModelId(() => undefined);
    setMessageConfirm(() => false);
  };

  const handleOnClose = () => {
    clean();
    onClose();
  };

  const onFinish = async () => {
    setLoading(() => true);
    createOneVehicle({
      licensePlate,
      description,
      colourId,
      modelId,
    })
      .then((res) => {
        if (isMountedComponent.current) {
          setUser((cUser) =>
            cUser
              ? {
                  ...cUser,
                  vehicles: [...cUser.vehicles, res],
                }
              : undefined
          );
          setMessageConfirm(() => true);
        }
      })
      .catch(({ message }) => {
        messageError({
          context: "CreateOneVehicle.onFinish.1",
          message: message,
        });
      })
      .finally(() => setLoading(() => false));
  };

  const getVehicleModels = async () => {
    if (brandId) {
      setVehicleModelList(() => []);
      setModelId(() => undefined);
      setLoading(() => true);
      await vehicleModels(brandId)
        .then((res) => {
          if (isMountedComponent.current) {
            setVehicleModelList(() => res);
            if (res.length === 1) {
              setModelId(() => res[0].id);
            }
          }
        })
        .catch(({ message }) => {
          Log.error({
            context: "CreateOneVehicle.getVehicleModels.1",
            message,
          });
        });
      setLoading(() => false);
    }
  };

  const getData = async () => {
    setLoading(() => true);
    await vehicleBrands()
      .then((res) => {
        if (isMountedComponent.current) {
          setVehicleBrandList(() => res);
          if (res.length === 1) {
            setBrandId(() => res[0].id);
          }
        }
      })
      .catch(({ message }) => {
        Log.error({
          context: "CreateOneVehicle.getData.1",
          message,
        });
      });
    await colours()
      .then((res) => {
        if (isMountedComponent.current) {
          setColoursList(() => res);
          if (res.length === 1) {
            setColourId(() => res[0].id);
          }
        }
      })
      .catch(({ message }) => {
        Log.error({
          context: "CreateOneVehicle.getData.2",
          message,
        });
      });
    setLoading(() => false);
  };

  React.useEffect(() => {
    if (isMountedComponent.current) {
      getVehicleModels();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandId]);

  React.useEffect(() => {
    isMountedComponent.current = true;
    getData();
    return () => {
      isMountedComponent.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMountedComponent]);

  return (
    <SwipeableActionSheet
      style={{ height: "100vh" }}
      open={open}
      onClose={handleOnClose}
      type="page"
    >
      <CustomPage
        headerPage={
          <ToolbarMUI>
            <GoBack onClick={handleOnClose} />
            <TitlePage text="Agregar un vehículo" />
          </ToolbarMUI>
        }
      >
        <BodyPage m={3}>
          <div className="FlexColumn" style={{ paddingTop: "56px" }}>
            <FormControlMUI
              variant="standard"
              sx={{
                width: "100%",
                margin: "8px 0",
              }}
              error={!!licensePlate && !validLicensePlate(licensePlate)}
            >
              <InputLabelMUI className="TypographySemibold11pt">
                Patente
              </InputLabelMUI>
              <InputMUI
                type="string"
                value={licensePlate}
                onChange={(ev) => {
                  if (ev.target.value.length < 8) {
                    setLicensePlate(() => `${ev.target.value}`.toUpperCase());
                  }
                }}
              />

              <FormHelperText
                active={!!licensePlate && !validLicensePlate(licensePlate)}
                message="La patente no es válida."
              />
            </FormControlMUI>
            <Separator px={24} />
            <FormControlMUI variant="standard">
              <InputLabelMUI className="TypographySemibold11pt">
                Marca
              </InputLabelMUI>
              <SelectMUI
                variant="standard"
                value={brandId || ""}
                onChange={(ev) => setBrandId(() => Number(ev.target.value))}
              >
                {vehicleBrandList.map((m) => (
                  <MenuItemMUI key={m.id} value={m.id}>
                    {m.name}
                  </MenuItemMUI>
                ))}
              </SelectMUI>
            </FormControlMUI>
            <Separator px={24} />
            <FormControlMUI variant="standard">
              <InputLabelMUI className="TypographySemibold11pt">
                Modelo
              </InputLabelMUI>
              <SelectMUI
                variant="standard"
                value={modelId || ""}
                onChange={(ev) => setModelId(() => Number(ev.target.value))}
              >
                {vehicleModelList.map((m) => (
                  <MenuItemMUI key={m.id} value={m.id}>
                    {m.name}
                  </MenuItemMUI>
                ))}
              </SelectMUI>
            </FormControlMUI>
            <Separator px={24} />
            <FormControlMUI variant="standard">
              <InputLabelMUI className="TypographySemibold11pt">
                Color
              </InputLabelMUI>
              <SelectMUI
                variant="standard"
                value={colourId || ""}
                onChange={(ev) => setColourId(() => Number(ev.target.value))}
              >
                {coloursList.map((m) => (
                  <MenuItemMUI key={m.id} value={m.id}>
                    {m.name}
                  </MenuItemMUI>
                ))}
              </SelectMUI>
            </FormControlMUI>
          </div>
        </BodyPage>
        <BoxMUI
          p={3}
          style={{
            position: "absolute",
            bottom: 0,
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            background: "white",
          }}
        >
          <ButtonMUI
            className="FullWidth"
            variant="outlined"
            disabled={loading}
            onClick={handleOnClose}
          >
            Cancelar
          </ButtonMUI>
          <Separator px={16} orientation="vertical" />
          <ButtonMUI
            className="FullWidth"
            color="primary"
            variant="contained"
            disabled={
              !licensePlate ||
              !colourId ||
              !brandId ||
              !modelId ||
              loading ||
              !validLicensePlate(licensePlate)
            }
            onClick={onFinish}
          >
            Agregar
          </ButtonMUI>
        </BoxMUI>
        <DialogCustom
          context="CreateOneVehicle.1"
          open={messageConfirm}
          onClose={handleOnClose}
          title={
            <TypographyMUI className="TypographyBold13pt">
              Tu vehículo se agregó correctamente
            </TypographyMUI>
          }
          body={
            <TypographyMUI className="TypographyRegular11pt">
              Ya tenés un nuevo vehículo para realizar tus viajes.
            </TypographyMUI>
          }
          footer={<DialogCustomFooterActions onOk={handleOnClose} />}
        />
      </CustomPage>
    </SwipeableActionSheet>
  );
};

export default CreateOneVehicle;
