import React from "react";
import {
  TypographyMUI,
  Separator,
  RenderListAsync,
} from "../../DeltaUI/Components";
import { CardReservation } from "../../Components";
import LoadData from "../../Components/LoadData";
import { RESERVATION_STATUS, TRIP_STATUS } from "../../Utils/Constants";
import CardReservationDriver from "../../Components/Card/CardReservationDriver";
import { MyTripsContext } from "../MyTrips/MyTripsContext";
import { UpoolContext } from "../../Providers/UpoolContext";

interface ReservationTabProps {
  loading: boolean;
  onChange: () => void;
}

const ReservationTab: React.FC<ReservationTabProps> = (props) => {
  const { loading, onChange } = props;
  const { user } = React.useContext(UpoolContext);
  const {
    selectTripStretch,
    isDriverOfSelectedTripStretch,
    selectTripStretchReservations,
  } = React.useContext(MyTripsContext);

  const passengersInReviewModeDriver = React.useMemo(() => {
    if (selectTripStretch?.trip.statusId === TRIP_STATUS.AVAILABLE) {
      return selectTripStretchReservations.filter(
        (r) => r.statusId === RESERVATION_STATUS.IN_REVIEW
      );
    }
    return [];
  }, [selectTripStretchReservations, selectTripStretch]);
  const passengersChatModeDriver = React.useMemo(
    () =>
      selectTripStretchReservations.filter(
        (r) => r.statusId === RESERVATION_STATUS.CREATED
      ),
    [selectTripStretchReservations]
  );
  const passengersInProgressModeDriver = React.useMemo(
    () =>
      selectTripStretchReservations.filter(
        (r) => r.statusId === RESERVATION_STATUS.IN_PROGRESS
      ),
    [selectTripStretchReservations]
  );

  const passengersCanceledModeDriver = React.useMemo(() => {
    return selectTripStretchReservations.filter(
      (r) =>
        r.statusId === RESERVATION_STATUS.REJECTED_BY_DRIVER ||
        r.statusId === RESERVATION_STATUS.REJECTED_BY_SYSTEM ||
        r.statusId === RESERVATION_STATUS.CANCELED_BY_PASSENGER ||
        r.statusId === RESERVATION_STATUS.CANCELED_BY_DRIVER
    );
  }, [selectTripStretchReservations]);

  const passengersPendingRatingModeDriver = React.useMemo(() => {
    if (
      selectTripStretch?.trip.statusId === TRIP_STATUS.DONE ||
      selectTripStretch?.trip.statusId === TRIP_STATUS.CANCELED
    ) {
      return selectTripStretchReservations.filter(
        (r) =>
          (r.statusId === RESERVATION_STATUS.FINISHED ||
            r.statusId === RESERVATION_STATUS.CANCELED_BY_PASSENGER ||
            r.statusId === RESERVATION_STATUS.CANCELED_BY_DRIVER) &&
          !r.rates.some((re) => re.userFromId === user?.id)
      );
    }
    return [];
  }, [selectTripStretchReservations, user, selectTripStretch]);

  const passengersQualifiedModeDriver = React.useMemo(() => {
    if (
      selectTripStretch?.trip.statusId === TRIP_STATUS.DONE ||
      selectTripStretch?.trip.statusId === TRIP_STATUS.CANCELED
    ) {
      return selectTripStretchReservations.filter(
        (r) =>
          (r.statusId === RESERVATION_STATUS.FINISHED ||
            r.statusId === RESERVATION_STATUS.CANCELED_BY_PASSENGER ||
            r.statusId === RESERVATION_STATUS.CANCELED_BY_DRIVER) &&
          r.rates.some((re) => re.userFromId === user?.id)
      );
    }
    return [];
  }, [selectTripStretchReservations, user, selectTripStretch]);

  const passengersModePassengers = React.useMemo(
    () =>
      selectTripStretchReservations.filter(
        (r) =>
          r.statusId === RESERVATION_STATUS.IN_PROGRESS ||
          r.statusId === RESERVATION_STATUS.FINISHED
      ),
    [selectTripStretchReservations]
  );

  return (
    <>
      {!selectTripStretch ? (
        <LoadData
          description="El viaje no se cargó correctamente"
          empty={true}
          loading={loading}
          height="45vh"
        />
      ) : null}
      {selectTripStretch ? (
        <>
          <Separator px={8} />
          <TypographyMUI className="TypographyExtrabold11pt">
            Conductor
          </TypographyMUI>
          <Separator px={16} />
          <CardReservationDriver tripStretch={selectTripStretch} />
        </>
      ) : null}
      {selectTripStretch && isDriverOfSelectedTripStretch ? (
        <>
          {!passengersCanceledModeDriver.length &&
          !passengersChatModeDriver.length &&
          !passengersInProgressModeDriver.length &&
          !passengersQualifiedModeDriver.length &&
          !passengersPendingRatingModeDriver.length &&
          !passengersInReviewModeDriver.length ? (
            <LoadData
              description="Ningún pasajero aún"
              empty={true}
              loading={loading}
              height="40vh"
            />
          ) : null}
          {passengersInProgressModeDriver.length ? (
            <>
              <Separator px={16} />
              <TypographyMUI className="TypographyExtrabold11pt">
                Pasajeros confirmados
              </TypographyMUI>
              <Separator px={16} />
              <LoadData
                description="Ningún pasajero aún"
                empty={!passengersInProgressModeDriver.length}
                loading={loading}
              >
                <RenderListAsync
                  list={passengersInProgressModeDriver.map((r) => (
                    <CardReservation
                      key={r.id}
                      reservation={r}
                      onChange={onChange}
                    />
                  ))}
                />
              </LoadData>
            </>
          ) : null}
          {passengersQualifiedModeDriver.length ? (
            <>
              <Separator px={16} />
              <TypographyMUI className="TypographyExtrabold11pt">
                Pasajeros calificados
              </TypographyMUI>
              <Separator px={16} />
              <LoadData
                description="Ningún pasajero aún"
                empty={!passengersQualifiedModeDriver.length}
                loading={loading}
              >
                <RenderListAsync
                  list={passengersQualifiedModeDriver.map((r) => (
                    <CardReservation
                      key={r.id}
                      reservation={r}
                      onChange={onChange}
                    />
                  ))}
                />
              </LoadData>
            </>
          ) : null}
          {passengersPendingRatingModeDriver.length ? (
            <>
              <Separator px={16} />
              <TypographyMUI className="TypographyExtrabold11pt">
                Pasajeros sin calificar
              </TypographyMUI>
              <Separator px={16} />
              <LoadData
                description="Ningún pasajero aún"
                empty={!passengersPendingRatingModeDriver.length}
                loading={loading}
              >
                <RenderListAsync
                  list={passengersPendingRatingModeDriver.map((r) => (
                    <CardReservation
                      key={r.id}
                      reservation={r}
                      onChange={onChange}
                    />
                  ))}
                />
              </LoadData>
            </>
          ) : null}

          {passengersInReviewModeDriver.length ? (
            <>
              <Separator px={16} />
              <TypographyMUI className="TypographyExtrabold11pt">
                Pasajeros en espera
              </TypographyMUI>
              <Separator px={16} />
              <LoadData
                description="Ningún pasajero aún"
                empty={!passengersInReviewModeDriver.length}
                loading={loading}
              >
                <RenderListAsync
                  list={passengersInReviewModeDriver.map((r) => (
                    <CardReservation
                      key={r.id}
                      reservation={r}
                      onChange={onChange}
                    />
                  ))}
                />
              </LoadData>
            </>
          ) : null}

          {passengersChatModeDriver.length ? (
            <>
              <Separator px={16} />
              <TypographyMUI className="TypographyExtrabold11pt">
                Pasajeros preguntando
              </TypographyMUI>
              <Separator px={16} />

              <LoadData
                description="Ningún pasajero aún"
                empty={!passengersChatModeDriver.length}
                loading={loading}
              >
                <RenderListAsync
                  list={passengersChatModeDriver.map((r) => (
                    <CardReservation
                      key={r.id}
                      reservation={r}
                      onChange={onChange}
                    />
                  ))}
                />
              </LoadData>
            </>
          ) : null}
          {passengersCanceledModeDriver.length ? (
            <>
              <Separator px={16} />
              <TypographyMUI className="TypographyExtrabold11pt">
                Pasajeros cancelados
              </TypographyMUI>
              <Separator px={16} />
              <LoadData
                description="Ningún pasajero aún"
                empty={!passengersCanceledModeDriver.length}
                loading={loading}
              >
                <RenderListAsync
                  list={passengersCanceledModeDriver.map((r) => (
                    <CardReservation
                      key={r.id}
                      reservation={r}
                      onChange={onChange}
                    />
                  ))}
                />
              </LoadData>
            </>
          ) : null}
        </>
      ) : null}
      {selectTripStretch && !isDriverOfSelectedTripStretch ? (
        <>
          <Separator px={16} />
          <TypographyMUI className="TypographyExtrabold11pt">
            Pasajeros
          </TypographyMUI>
          <Separator px={16} />
          <LoadData
            description="Ningún pasajero aún"
            empty={!passengersModePassengers.length}
            loading={loading}
            height="30vh"
          >
            <RenderListAsync
              list={passengersModePassengers.map((r) => (
                <CardReservation
                  key={r.id}
                  reservation={r}
                  onChange={onChange}
                />
              ))}
            />
          </LoadData>
        </>
      ) : null}
    </>
  );
};

export default ReservationTab;
