import React from "react";
import {
  ToolbarMUI,
  BodyPage,
  ButtonGroup,
  MenuOptionsButton,
  Tabs,
  BoxMUI,
  FooterPage,
  SwipeableActionSheet,
  IconButtonMUI,
  BadgeMUI,
  SwipeableActionSheetItem,
} from "../DeltaUI/Components";
import {
  ForumOutlinedIcon,
  ShareIcon,
  VisibilityIcon,
} from "../DeltaUI/Components/CustomIcon";
import { UpoolContext } from "../Providers/UpoolContext";
import { DetailTripReservationButton } from "../Components/DetailTrip/DetailTripReservationButton";
import {
  DETAIL_TRIP_MODE,
  DETAIL_TRIP_TAB,
  APPLICATION_PATHS,
  COLOR_PRIMARY,
  COLOR_QUATERNARY,
  RESERVATION_STATUS,
  TRIP_STATUS,
} from "../Utils/Constants";
import { MyTripsContext } from "../Components/MyTrips/MyTripsContext";
import ReservationTab from "../Components/DetailTrip/ReservationTab";
import ResumeTab from "../Components/DetailTrip/ResumeTab";
import MapTab from "../Components/DetailTrip/MapTab";
import { MyTripsUtils } from "../Components/MyTrips/MyTripsUtils";
import LoadData from "../Components/LoadData";
import { IReservation } from "../Services/ReservationService";
import { TitlePage } from "../Components/TitlePage";
import { EventAppContext } from "../Providers/EventAppProvider";
import { Imgs } from "../Assets";
import { RequestMessageSent } from "../Components/MyTrips/RequestMessageSent";
import { DetailTripRateButton } from "../Components/DetailTrip/DetailTripRateButton";
import { GoBack } from "../Components/GoBack";
import { CustomPage } from "../Components";
import { Safely, useComponentMount } from "../Utils";

interface DetailTripPageProps {}

const DetailTripPage: React.FC<DetailTripPageProps> = () => {
  const isMountedComponent = useComponentMount("DetailTripPage");

  const { eventSafely } = Safely();
  const [viewFilter, setViewFilter] = React.useState<boolean>(false);
  const {
    historyGoBack,
    historyPush,
    selectPathTab,
    user,
    setShareTripStretch,
  } = React.useContext(UpoolContext);
  const {
    loadingMyTrips,
    detailTripMode,
    selectTripStretchChatsCount,
    detailTripTab,
    setDetailTripTab,
    selectTripStretch,
    setSelectTripStretch,
    setSearchTripEndMessage,
    searchTripEndMessage,
    setRatingView,
    isRateCalculate,
    setCanceledMessageView,
  } = React.useContext(MyTripsContext);
  const { handleEventUpdateTrip } = React.useContext(EventAppContext);

  const {
    option2,
    option3,
    confirmInitChat,
    goChat,
    tripMode,
    createNewReservation,
  } = MyTripsUtils({
    tripStretch: selectTripStretch,
    onChange: () => handleEventUpdateTrip(),
  });

  const myReservation = React.useMemo(() => {
    return selectTripStretch?.myReservation;
  }, [selectTripStretch]);

  const ratingForCurrentUser = React.useMemo(() => {
    return myReservation && user
      ? myReservation.rates.find((re) => re.userToId === user.id)
      : undefined;
  }, [myReservation, user]);

  const canceledMessageForCurrentUser = React.useMemo(() => {
    return myReservation && user && !!myReservation.canceledMessage;
  }, [myReservation, user]);

  const optionShare = React.useMemo(() => {
    return selectTripStretch?.trip.statusId === TRIP_STATUS.AVAILABLE;
  }, [selectTripStretch]);

  const handleChat = async () => {
    if (myReservation && !createNewReservation) {
      goChat(myReservation);
    } else {
      confirmInitChat((reservation: IReservation) => {
        if (isMountedComponent.current) {
          goChat(reservation);
        }
      });
    }
  };

  const handleClickOption = (callback?: Function) => {
    setViewFilter(() => false);
    if (callback && typeof callback === "function") {
      callback();
    }
  };

  const goBack = () => {
    setSelectTripStretch(() => undefined);
    historyGoBack();
  };

  /**
   * Calcula todas las notificaciones no atendidas de este viaje
   */
  const reservationsBadgeContent = React.useMemo(() => {
    let count = 0;
    if (!myReservation && selectTripStretch) {
      count =
        count +
        selectTripStretch.reservations.filter(
          (r) => r.statusId === RESERVATION_STATUS.IN_REVIEW
        ).length;
    }
    if (!myReservation) {
      count = count + isRateCalculate(selectTripStretch);
    }
    return count;
  }, [isRateCalculate, myReservation, selectTripStretch]);

  React.useEffect(() => {
    isMountedComponent.current = true;
    return () => {
      isMountedComponent.current = false;
    };
  }, [isMountedComponent]);

  return (
    <CustomPage
      headerPage={
        <>
          <ToolbarMUI>
            <GoBack onClick={goBack} />
            <TitlePage text="Detalle del viaje" />
            <ButtonGroup slot="end">
              {optionShare ? (
                <IconButtonMUI
                  color="inherit"
                  onClick={() => setShareTripStretch(() => selectTripStretch)}
                >
                  <ShareIcon style={{ color: COLOR_PRIMARY }} />
                </IconButtonMUI>
              ) : null}
              {tripMode.passenger ? (
                <IconButtonMUI onClick={handleChat}>
                  {detailTripMode === DETAIL_TRIP_MODE.MY_TRIPS ? (
                    <BadgeMUI
                      badgeContent={myReservation?.unreadChats}
                      color="secondary"
                    >
                      <ForumOutlinedIcon style={{ color: COLOR_PRIMARY }} />
                    </BadgeMUI>
                  ) : (
                    <ForumOutlinedIcon style={{ color: COLOR_PRIMARY }} />
                  )}
                </IconButtonMUI>
              ) : null}
              {tripMode.drive &&
              detailTripMode === DETAIL_TRIP_MODE.MY_TRIPS ? (
                <IconButtonMUI
                  onClick={() => {
                    historyPush(`${selectPathTab}/chat-list`);
                  }}
                >
                  <BadgeMUI
                    badgeContent={selectTripStretchChatsCount}
                    color="secondary"
                  >
                    <ForumOutlinedIcon style={{ color: COLOR_PRIMARY }} />
                  </BadgeMUI>
                </IconButtonMUI>
              ) : null}
              {detailTripMode === DETAIL_TRIP_MODE.MY_TRIPS ? (
                <MenuOptionsButton
                  menuId="DetailTripPageMenuOptionsID"
                  onClick={() => setViewFilter(() => true)}
                />
              ) : null}
            </ButtonGroup>
          </ToolbarMUI>
          <Tabs
            value={detailTripTab}
            invertedColor={true}
            color="secondary"
            textColor={COLOR_QUATERNARY}
            defaultValue={DETAIL_TRIP_TAB.RESUME}
            onChange={(ev) => setDetailTripTab(() => ev)}
            list={[
              {
                key: DETAIL_TRIP_TAB.RESUME,
                label: "Resumen",
              },
              {
                key: DETAIL_TRIP_TAB.RESERVATIONS,
                label: "Integrantes",
                badgeContent:
                  detailTripMode === DETAIL_TRIP_MODE.MY_TRIPS
                    ? reservationsBadgeContent
                    : undefined,
              },
              {
                key: DETAIL_TRIP_TAB.MAP,
                label: "Mapa",
              },
            ]}
          />
        </>
      }
      footerPage={
        <FooterPage>
          <SwipeableActionSheet
            open={viewFilter}
            onClose={() => handleClickOption()}
            type="options"
          >
            {optionShare ? (
              <SwipeableActionSheetItem
                onClick={() =>
                  handleClickOption(() =>
                    setShareTripStretch(() => selectTripStretch)
                  )
                }
                text="Compartir viaje"
                icon={<ShareIcon style={{ color: COLOR_PRIMARY }} />}
              />
            ) : null}
            {option2 ? (
              <SwipeableActionSheetItem
                onClick={() => handleClickOption(option2.onClick)}
                text={option2.label}
                icon={option2.icon}
                disabled={option2.disabled}
              />
            ) : null}
            {option3 ? (
              <SwipeableActionSheetItem
                onClick={() => handleClickOption(option3.onClick)}
                text={option3.label}
                icon={option3.icon}
                disabled={option3.disabled}
              />
            ) : null}

            {ratingForCurrentUser ? (
              <SwipeableActionSheetItem
                onClick={() =>
                  handleClickOption(() =>
                    setRatingView(() => ({
                      rate: ratingForCurrentUser,
                      userFromIsDriver: true,
                    }))
                  )
                }
                text="Ver calificación"
                icon={<VisibilityIcon style={{ color: COLOR_PRIMARY }} />}
              />
            ) : null}

            {canceledMessageForCurrentUser ? (
              <SwipeableActionSheetItem
                onClick={eventSafely(() =>
                  handleClickOption(() =>
                    setCanceledMessageView(() => myReservation)
                  )
                )}
                text="Ver motivo de baja"
                icon={<VisibilityIcon style={{ color: COLOR_PRIMARY }} />}
              />
            ) : null}

            {!optionShare &&
            !option2 &&
            !option3 &&
            !ratingForCurrentUser &&
            !canceledMessageForCurrentUser ? (
              <LoadData
                description="Sin opciones"
                empty={true}
                loading={loadingMyTrips}
              />
            ) : null}
          </SwipeableActionSheet>
        </FooterPage>
      }
    >
      <BodyPage>
        {detailTripTab === DETAIL_TRIP_TAB.RESUME ? (
          <BoxMUI p={3}>
            <ResumeTab
              loading={loadingMyTrips}
              tripStretch={selectTripStretch}
            />
          </BoxMUI>
        ) : null}
        {detailTripTab === DETAIL_TRIP_TAB.RESERVATIONS ? (
          <BoxMUI p={3}>
            <ReservationTab
              loading={loadingMyTrips}
              onChange={() => handleEventUpdateTrip()}
            />
          </BoxMUI>
        ) : null}
        {detailTripTab === DETAIL_TRIP_TAB.MAP ? (
          <BoxMUI>
            <MapTab loading={loadingMyTrips} />
          </BoxMUI>
        ) : null}
        {detailTripMode === DETAIL_TRIP_MODE.MY_TRIPS ? (
          <DetailTripRateButton
            tripStretch={selectTripStretch}
            onChange={() => handleEventUpdateTrip()}
          />
        ) : null}
        <DetailTripReservationButton
          tripStretch={selectTripStretch}
          onOk={() => setSearchTripEndMessage(true)}
        />
        <RequestMessageSent
          open={searchTripEndMessage}
          onOk={() => {
            if (selectPathTab === APPLICATION_PATHS.SEARCH_TRIP) {
              historyPush("/tabs/my-trips");
            } else {
              setSearchTripEndMessage(false);
              handleEventUpdateTrip();
            }
          }}
          image={Imgs.requestSent}
        />
      </BodyPage>
    </CustomPage>
  );
};

export default DetailTripPage;
