/**
 * Configuración de credenciales iguales para todos los ambientes
 */
const configForAllEnvironments: IConfigForAllEnvironments = {
  appIdFirebase: "284632302232",
  firebaseConfig: {
    apiKey: "AIzaSyCBQj6YkHCwGHe01cnzS36qJCuSHTvT014",
    authDomain: "upool-e3565.firebaseapp.com",
    projectId: "upool-e3565",
    storageBucket: "upool-e3565.appspot.com",
    messagingSenderId: "284632302232",
    appId: "1:284632302232:web:021faa86d68093ceb386ed",
    measurementId: "G-MQXF52KWMW",
  },
  apiKeyGoogleMaps: "AIzaSyDLRL3UHZhjEEgtpH3RGhCS4Z3vxqLlh5A",
  webClientIdGoogle:
    "284632302232-univcrefhi8vi5ulvl8rnms8pne5ppat.apps.googleusercontent.com",
};

/**
 * Configuración de credenciales
 */
export const CREDENTIALS: {
  TEST: ICredentials;
  STAGE: ICredentials;
  PROD: ICredentials;
} = {
  TEST: {
    ...configForAllEnvironments,
    appIdOneSignal: "1e6add38-65f3-44eb-850d-0332be5c8dc8",
  },
  STAGE: {
    ...configForAllEnvironments,
    appIdOneSignal: "842b9bab-2d17-4945-b9e6-b2bab931fa6c",
  },
  PROD: {
    ...configForAllEnvironments,
    appIdOneSignal: "11f1da6b-441e-4f2f-b4f2-eea773994745",
  },
};

export interface IConfigForAllEnvironments {
  apiKeyGoogleMaps: string | null;
  webClientIdGoogle: string | null;
  appIdFirebase: string | null;
  firebaseConfig: {
    apiKey: string;
    authDomain: string;
    projectId: string;
    storageBucket: string;
    messagingSenderId: string;
    appId: string;
    measurementId: string;
  };
}

export interface ICredentials extends IConfigForAllEnvironments {
  appIdOneSignal: string | null;
}
