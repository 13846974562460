import React from "react";
import { Imgs } from "../../Assets";
import { CardTrip, DialogCustomHook } from "../../Components";
import { BoxMUI, Separator, TypographyMUI } from "../../DeltaUI/Components";
import { UpoolContext } from "../../Providers/UpoolContext";
import { ITripStretch } from "../../Services/TripService";
import {
  COLOR_PRIMARY,
  COLOR_TERTIARY,
  URL_APP_FRONT,
} from "../../Utils/Constants";
import { Crypto } from "../../Utils/Crypto";
import { ShareComponent } from "./ShareComponent";

interface ShareTripStrechProps {
  tripStrech?: ITripStretch;
  onFinish: () => void;
}

export const ShareTripStrech: React.FC<ShareTripStrechProps> = (props) => {
  const { tripStrech, onFinish } = props;
  const { encrypt } = Crypto();
  const { messageError } = DialogCustomHook();
  const { setLoading } = React.useContext(UpoolContext);
  const [params, setParams] = React.useState<string>("");
  const [share, setShare] = React.useState<boolean>(false);

  const init = () => {
    if (tripStrech) {
      try {
        setLoading(() => true);
        setParams(() => `?t=${encrypt(`${tripStrech.id}`)}`);
      } catch (err: any) {
        setLoading(() => false);
        messageError({
          context: "ShareTripStrech.init.1",
          message:
            "Ocurrió un problema al intentar compartir! Por favor intente más tarde.",
          messageError: err.message,
        });
        onFinish();
      }
    }
  };

  React.useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BoxMUI
      style={{
        width: "100vw",
        height: "100vh",
        overflow: "hidden",
        background: COLOR_TERTIARY,
      }}
    >
      <BoxMUI
        style={{
          width: "calc(100vw + 48px)",
          marginTop: "-24px",
          marginLeft: "-24px",
          background: COLOR_TERTIARY,
        }}
      >
        <BoxMUI
          id="ShareTripStrech"
          style={{
            width: "102%",
            padding: "64px 32px 64px 32px",
            background: COLOR_TERTIARY,
          }}
        >
          <BoxMUI
            style={{
              width: "calc(100vw - 16px)",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              background: COLOR_PRIMARY,
              borderRadius: "20px",
            }}
          >
            <BoxMUI
              style={{
                width: "100%",
                display: "flex",
                alignItems: "start",
                justifyContent: "space-between",
                padding: "16px 24px",
              }}
            >
              <BoxMUI
                style={{
                  width: "100%",
                  height: "48px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "end",
                }}
              >
                <TypographyMUI className="TypographyBold16pt" color="white">
                  Viaje disponible
                </TypographyMUI>
              </BoxMUI>
              <img
                src={Imgs.logoPNG}
                alt=""
                style={{
                  height: "50px",
                }}
                onLoad={() => setShare(() => true)}
                onAbort={onFinish}
                onError={onFinish}
              />
            </BoxMUI>
            {tripStrech ? (
              <CardTrip
                style={{
                  position: "relative",
                  width: "calc(100vw - 48px)",
                }}
                tripStretchInitial={tripStrech}
                share={true}
              />
            ) : null}
            <Separator px={48} />
            <Separator px={24} />
          </BoxMUI>
        </BoxMUI>
      </BoxMUI>
      {share ? (
        <ShareComponent
          titleModal="Compartir viaje"
          onFinish={onFinish}
          elementId="ShareTripStrech"
          config={{
            url: `${URL_APP_FRONT}/public/trip${params}`,
            title: "Viaje disponible",
          }}
        />
      ) : null}
    </BoxMUI>
  );
};
