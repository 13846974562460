import React from "react";
import { PlaceResult, SetterState } from "../../Services/DefaultTypes";
import {
  IPreference,
  PreferenceService,
} from "../../Services/PreferenceService";
import {
  IMyAvailableTripStretchResult,
  TripService,
} from "../../Services/TripService";
import { MomentJS, Tools, useComponentMount } from "../../Utils";
import { PREFERENCES, RADIUS, TAKE } from "../../Utils/Constants";
import { DialogCustomHook } from "../DialogCustom";
import { ShareSearch } from "../Share/ShareSearch";
import { Crypto } from "../../Utils/Crypto";
import { RecreateSearchProvider } from "./RecreateSearchContext";
import { ShareService } from "../../Services/ShareService";
import { UpoolContext } from "../../Providers/UpoolContext";

export enum ORDER_BY {
  dateFromAsc,
  dateFromDesc,
  amountAsc,
  amountDesc,
}

interface ISearchTrip {
  cleanAdvancedFilters: () => void;
  data: IMyAvailableTripStretchResult[];
  setData: SetterState<IMyAvailableTripStretchResult[]>;
  loadingSearchTripResults: boolean;
  setLoadingSearchTripResults: SetterState<boolean>;
  search: (resetResults: boolean, advancedSearch: boolean) => void;
  loadMap: boolean;
  setLoadMap: SetterState<boolean>;
  originPlace: PlaceResult | undefined;
  setOriginPlace: SetterState<PlaceResult | undefined>;
  destinationPlace: PlaceResult | undefined;
  setDestinationPlace: SetterState<PlaceResult | undefined>;
  minDate: Date;
  maxDate: Date;
  initialDate: Date | undefined;
  setInitialDate: SetterState<Date | undefined>;
  dateFrom: Date | undefined;
  setDateFrom: SetterState<Date | undefined>;
  dateTo: Date | undefined;
  setDateTo: SetterState<Date | undefined>;
  availablePlaces: number;
  setAvailablePlaces: SetterState<number>;
  share: () => void;

  defaultRadiusFrom: number | undefined;
  setDefaultRadiusFrom: SetterState<number | undefined>;
  defaultRadiusTo: number | undefined;
  setDefaultRadiusTo: SetterState<number | undefined>;

  radiusFrom: number | undefined;
  setRadiusFrom: SetterState<number | undefined>;
  radiusTo: number | undefined;
  setRadiusTo: SetterState<number | undefined>;
  preferencesIncluded: IPreference[];
  setPreferencesIncluded: SetterState<IPreference[]>;
  orderByNumber: number | undefined;
  setOrderByNumber: SetterState<number | undefined>;
  typeId: number | undefined;
  setTypeId: SetterState<number | undefined>;
  freeSeatMiddle: boolean;
  setFreeSeatMiddle: SetterState<boolean>;
}

const context: ISearchTrip = {
  cleanAdvancedFilters: () => {},
  data: [],
  setData: () => {},
  loadingSearchTripResults: false,
  setLoadingSearchTripResults: () => {},
  search: () => {},
  loadMap: false,
  setLoadMap: () => {},
  originPlace: undefined,
  setOriginPlace: () => {},
  destinationPlace: undefined,
  setDestinationPlace: () => {},
  minDate: null as any,
  maxDate: null as any,
  dateFrom: null as any,
  setDateFrom: () => {},
  initialDate: null as any,
  setInitialDate: () => {},
  dateTo: null as any,
  setDateTo: () => {},
  availablePlaces: 1,
  setAvailablePlaces: () => {},
  share: () => {},

  defaultRadiusFrom: null as any,
  setDefaultRadiusFrom: () => {},
  defaultRadiusTo: null as any,
  setDefaultRadiusTo: () => {},

  radiusFrom: null as any,
  setRadiusFrom: () => {},
  radiusTo: null as any,
  setRadiusTo: () => {},
  preferencesIncluded: [],
  setPreferencesIncluded: () => {},
  orderByNumber: undefined,
  setOrderByNumber: () => {},
  typeId: undefined,
  setTypeId: () => {},
  freeSeatMiddle: false,
  setFreeSeatMiddle: () => {},
};

export const SearchTripContext = React.createContext(context);

export interface SearchTripProviderProps {}

export const SearchTripProvider: React.FC<SearchTripProviderProps> = (
  props
) => {
  const isMountedComponent = useComponentMount("SearchTripProvider");
  const { getPreferences } = PreferenceService();
  const { tripStretchesAvailables } = TripService();
  const { createOneShare } = ShareService();
  const { metersToKM } = Tools();
  const { encrypt } = Crypto();
  const { addToDate } = MomentJS();
  const { messageError, messageWarn } = DialogCustomHook();
  const { setLoading, user } = React.useContext(UpoolContext);

  const minDate: Date = React.useMemo(() => new Date(), []);
  const maxDate: Date = React.useMemo(
    () => addToDate(30, "d", minDate).toDate(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [minDate]
  );

  const [data, setData] = React.useState<IMyAvailableTripStretchResult[]>([]);
  const [
    loadingSearchTripResults,
    setLoadingSearchTripResults,
  ] = React.useState<boolean>(false);

  const [loadMap, setLoadMap] = React.useState<boolean>(false);
  const [originPlace, setOriginPlace] = React.useState<PlaceResult>();
  const [destinationPlace, setDestinationPlace] = React.useState<PlaceResult>();
  const [initialDate, setInitialDate] = React.useState<Date>();
  const [dateFrom, setDateFrom] = React.useState<Date>();
  const [dateTo, setDateTo] = React.useState<Date | undefined>(
    addToDate(1, "d", new Date()).toDate()
  );
  const [availablePlaces, setAvailablePlaces] = React.useState<number>(1);

  const [defaultRadiusFrom, setDefaultRadiusFrom] = React.useState<
    number | undefined
  >();
  const [defaultRadiusTo, setDefaultRadiusTo] = React.useState<
    number | undefined
  >();

  const [radiusFrom, setRadiusFrom] = React.useState<number | undefined>();
  const [radiusTo, setRadiusTo] = React.useState<number | undefined>();
  const [preferencesIncluded, setPreferencesIncluded] = React.useState<
    IPreference[]
  >([]);

  const [orderByNumber, setOrderByNumber] = React.useState<number | undefined>(
    undefined
  );
  const [typeId, setTypeId] = React.useState<number | undefined>(undefined);
  const [freeSeatMiddle, setFreeSeatMiddle] = React.useState<boolean>(false);
  const [shareLength, setShareLength] = React.useState<number>();
  const [params, setParams] = React.useState<string>();

  const handleSetDateTo = () => {
    setDateTo(() => dateFrom);
  };

  const cleanAdvancedFilters = () => {
    setData(() => []);
    setOrderByNumber(() => undefined);
    setTypeId(() => undefined);
    setDateFrom(() => initialDate);
    handleSetDateTo();
    setRadiusFrom(() => defaultRadiusFrom);
    setRadiusTo(() => defaultRadiusTo);
    setFreeSeatMiddle(() => false);
    setPreferencesIncluded((pList) => {
      pList.forEach((element) => {
        element.active = false;
      });
      return [...pList];
    });
  };

  const advancedFilters = React.useMemo(() => {
    const w: any = {};
    if (radiusFrom && radiusTo) {
      w.radiusFrom = Number(radiusFrom) * 1000;
      w.radiusTo = Number(radiusTo) * 1000;
    }
    const preferencesIncludedActive = preferencesIncluded
      .filter((p) => p.active)
      .map((p) => p.id);
    if (freeSeatMiddle) {
      preferencesIncludedActive.push(PREFERENCES.FREE_SEAT_MIDDLE);
    }
    if (preferencesIncludedActive.length) {
      w.preferencesIncluded = preferencesIncludedActive;
    }
    if (typeId) {
      w.typeId = typeId;
    }
    return w;
  }, [radiusFrom, radiusTo, preferencesIncluded, typeId, freeSeatMiddle]);

  const where = React.useMemo(() => {
    if (!originPlace || !destinationPlace) {
      return null;
    }
    const w: any = {
      coordsFrom: `${
        originPlace.geometry?.location
          ? originPlace.geometry?.location.lng()
          : ""
      };${
        originPlace.geometry?.location
          ? originPlace.geometry.location.lat()
          : ""
      }`,
      coordsTo: `${
        destinationPlace.geometry?.location
          ? destinationPlace.geometry?.location.lng()
          : ""
      };${
        destinationPlace.geometry?.location
          ? destinationPlace.geometry.location.lat()
          : ""
      }`,
      passengers: availablePlaces,
    };
    if (dateFrom) {
      w.dateFrom = dateFrom;
    }
    if (dateTo) {
      w.dateTo = dateTo;
    }
    return w;
  }, [originPlace, destinationPlace, availablePlaces, dateFrom, dateTo]);

  const orderBy = React.useMemo(() => {
    if (!originPlace || !destinationPlace) {
      return null;
    }
    const ob: any = {};
    switch (orderByNumber) {
      case ORDER_BY.dateFromDesc:
        ob.dateFrom = "desc";
        break;
      case ORDER_BY.dateFromAsc:
        ob.dateFrom = "asc";
        break;
      case ORDER_BY.amountAsc:
        ob.amount = "asc";
        break;
      case ORDER_BY.amountDesc:
        ob.amount = "desc";
        break;
      default:
        break;
    }

    return ob;
  }, [originPlace, destinationPlace, orderByNumber]);

  const search = (resetResults: boolean, advancedSearch: boolean) => {
    if (resetResults) {
      setData(() => []);
      setParams(() => undefined);
    }
    if (where) {
      setLoadingSearchTripResults(() => true);
      tripStretchesAvailables({
        where: advancedSearch
          ? {
              ...where,
              ...advancedFilters,
            }
          : where,
        orderBy,
        pagination: {
          skip: resetResults ? 0 : data.length,
          take: TAKE.OTHER,
        },
      })
        .then((r) => {
          if (isMountedComponent.current && r?.result && r.result.length) {
            setData((v) => [...v, ...r.result]);
          }
          if (isMountedComponent.current && r?.where && !advancedSearch) {
            setDefaultRadiusFrom(() => {
              const rf = metersToKM(r.where.radiusFrom);
              return rf && rf < RADIUS.MAX ? rf : RADIUS.MAX;
            });
            setDefaultRadiusTo(() => {
              const rt = metersToKM(r.where.radiusTo);
              return rt && rt < RADIUS.MAX ? rt : RADIUS.MAX;
            });
          }
        })
        .catch(({ message }) => {
          messageError({
            context: "SearchTripProvider.search.1",
            message: message,
          });
        })
        .finally(() => setLoadingSearchTripResults(() => false));
    }
  };

  const share = async () => {
    try {
      if (!data.length) {
        messageWarn({
          context: "SearchTripProvider.share.1",
          message: "Tu busqueda no tiene resultados para compartir.",
        });
        return;
      }

      if (!originPlace || !destinationPlace) {
        return;
      }

      if (!params) {
        setLoading(() => true);

        const f = JSON.stringify({
          originPlace: {
            formatted_address: originPlace.formatted_address,
            geometry: {
              location: {
                lat: originPlace.geometry?.location?.lat(),
                lng: originPlace.geometry?.location?.lng(),
              },
            },
            name: originPlace.name,
            place_id: originPlace.place_id,
          },
          destinationPlace: {
            formatted_address: destinationPlace.formatted_address,
            geometry: {
              location: {
                lat: destinationPlace.geometry?.location?.lat(),
                lng: destinationPlace.geometry?.location?.lng(),
              },
            },
            name: destinationPlace.name,
            place_id: destinationPlace.place_id,
          },
          orderByNumber,
          availablePlaces,
          dateFromNumber: dateFrom?.getTime(),
          dateToNumber: dateTo?.getTime(),
          radiusFrom,
          radiusTo,
          preferencesIncludedId: preferencesIncluded
            .filter((el) => el.active)
            .map((el) => el.id),
          typeId,
          freeSeatMiddle,
          initialDateNumber: initialDate?.getTime(),
        });

        const result = await createOneShare(f).catch((err) => {
          throw new Error(err.message);
        });

        setParams(() => `?s=${encrypt(`${result.id}`)}`);
      }

      setShareLength(() => data.length);
    } catch (err: any) {
      setLoading(() => false);
      messageError({
        context: "SearchTripProvider.share.2",
        message:
          "Ocurrió un problema al intentar compartir! Por favor intente más tarde.",
        messageError: err.message,
      });
    }
  };

  React.useEffect(() => {
    setRadiusFrom(() => defaultRadiusFrom);
    setRadiusTo(() => defaultRadiusTo);
  }, [defaultRadiusFrom, defaultRadiusTo]);

  React.useEffect(() => {
    handleSetDateTo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateFrom]);

  React.useEffect(() => {
    if (!dateTo) {
      setDateFrom(() => undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateTo]);

  React.useEffect(() => {
    if (user) {
      getPreferences()
        .then((res) => {
          if (isMountedComponent.current) {
            setPreferencesIncluded(() => JSON.parse(JSON.stringify(res)));
          }
        })
        .catch(({ message }) => {
          messageError({
            context: "SearchTripProvider.useEffect.1",
            message: message,
          });
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  React.useEffect(() => {
    isMountedComponent.current = true;
    return () => {
      isMountedComponent.current = false;
    };
  }, [isMountedComponent]);

  return (
    <SearchTripContext.Provider
      value={{
        cleanAdvancedFilters,
        data,
        setData,
        loadingSearchTripResults,
        setLoadingSearchTripResults,
        search,
        originPlace,
        setOriginPlace,
        destinationPlace,
        setDestinationPlace,
        minDate,
        maxDate,
        initialDate,
        setInitialDate,
        dateFrom,
        setDateFrom,
        dateTo,
        setDateTo,
        availablePlaces,
        setAvailablePlaces,
        share,
        defaultRadiusFrom,
        setDefaultRadiusFrom,
        defaultRadiusTo,
        setDefaultRadiusTo,
        radiusFrom,
        setRadiusFrom,
        radiusTo,
        setRadiusTo,
        preferencesIncluded,
        setPreferencesIncluded,
        orderByNumber,
        setOrderByNumber,
        typeId,
        setTypeId,
        freeSeatMiddle,
        setFreeSeatMiddle,
        loadMap,
        setLoadMap,
      }}
    >
      {shareLength && params ? (
        <ShareSearch
          length={shareLength}
          originPlace={originPlace}
          destinationPlace={destinationPlace}
          params={params}
          onFinish={() => setShareLength(() => undefined)}
        />
      ) : null}
      <RecreateSearchProvider>{props.children}</RecreateSearchProvider>
    </SearchTripContext.Provider>
  );
};
