import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { MyTripsProvider } from "../Components/MyTrips/MyTripsContext";
import { DETAIL_TRIP_MODE, APPLICATION_PATHS } from "../Utils/Constants";
import ChatListPage from "./ChatListPage";
import ChatPage from "./ChatPage";
import DetailTripPage from "./DetailTripPage";
import EditTripPage from "./EditTripPage";
import MyTripsPage from "./MyTripsPage";
import RatePage from "./RatePage";
import ViewUserNavTree from "./User/ViewUserNavTree";

const defaultPath = `${APPLICATION_PATHS.MY_TRIPS}/default`;
const myTripsDetailPath = `${APPLICATION_PATHS.MY_TRIPS}/detail-trip`;
const myTripsChatPath = `${APPLICATION_PATHS.MY_TRIPS}/chat`;
const myTripsRatePath = `${APPLICATION_PATHS.MY_TRIPS}/rate`;
const myTripsUserPath = `${APPLICATION_PATHS.MY_TRIPS}/user`;
const myTripsEditPath = `${APPLICATION_PATHS.MY_TRIPS}/edit-trip`;
const myTripsChatListPath = `${APPLICATION_PATHS.MY_TRIPS}/chat-list`;

const MyTripsNavTreeSwitch: React.FC = () => {
  return (
    <Switch>
      <Route exact path={APPLICATION_PATHS.MY_TRIPS}>
        <Redirect to={defaultPath} />
      </Route>
      <Route exact path={defaultPath} component={MyTripsPage} />
      <Route exact path={myTripsDetailPath} component={DetailTripPage} />
      <Route exact path={myTripsChatPath} component={ChatPage} />
      <Route path={myTripsUserPath} component={ViewUserNavTree} />
      <Route exact path={myTripsRatePath} component={RatePage} />
      <Route exact path={myTripsEditPath} component={EditTripPage} />
      <Route exact path={myTripsChatListPath} component={ChatListPage} />
    </Switch>
  );
};

const MyTripsNavTree: React.FC<{}> = () => {
  return (
    <MyTripsProvider detailTripMode={DETAIL_TRIP_MODE.MY_TRIPS}>
      <MyTripsNavTreeSwitch />
    </MyTripsProvider>
  );
};

export default MyTripsNavTree;
