import React from "react";

import { UpoolContext } from "../Providers/UpoolContext";
import {
  ButtonMUI,
  DividerAntd,
  TypographyMUI,
  LinkMUI,
  BoxMUI,
  FormInputEmailOrPhone,
  FormInputPassword,
  Separator,
} from "../DeltaUI/Components";
import { Authentication, Tools } from "../Utils";
import { UserService } from "../Services/UserService";
import { GoogleLogin, FacebookLogin, CustomPage } from "../Components";
import { DialogCustomHook } from "../Components";
import { ERROR_CODE } from "../Utils/Constants";
import { Imgs } from "../Assets";
import { AppContext } from "../Providers/AppContext";
import AppleLogin from "../Components/Button/AppleLogin";
import { isDesktop, isIos } from "../DeltaUI/Utils/Constants";

interface State {
  emailOrPhone: string;
  password: string;
  isNumberType: boolean;
}

const LoginPage: React.FC = () => {
  const { logout } = Authentication();
  const { messageError, messageWarn } = DialogCustomHook();
  const { login } = UserService();
  const { validateEmail, validatePhone } = Tools();
  const { setLoading, historyPush } = React.useContext(UpoolContext);
  const { showingKeyboard } = React.useContext(AppContext);
  const [errorEmailOrPhone, setErrorEmailOrPhone] = React.useState<boolean>(
    false
  );

  const [values, setValues] = React.useState<State>({
    emailOrPhone: "",
    password: "",
    isNumberType: false,
  });

  const handleLogin = () => {
    const optionalValues: any = {};
    if (values.isNumberType) {
      if (!validatePhone(values.emailOrPhone)) {
        return messageWarn({
          context: "LoginPage.1",
          message: "El teléfono no está bien escrito.",
        });
      }
      optionalValues.phone = values.emailOrPhone;
    } else {
      if (!validateEmail(values.emailOrPhone)) {
        return messageWarn({
          context: "LoginPage.2",
          message: "El mail no está bien escrito.",
        });
      }
      optionalValues.email = values.emailOrPhone;
    }
    setLoading(() => true);
    login({ password: values.password, ...optionalValues }).catch((err) => {
      setLoading(() => false);
      if (err.code === ERROR_CODE.USER_NOT_EXIST) {
        messageWarn({
          context: "LoginPage.3",
          message: err?.message,
        });
      } else {
        messageError({
          context: "LoginPage.4",
          message: err?.message,
        });
      }
    });
  };

  const disabledLogin = React.useMemo(
    () => !values.password || !values.emailOrPhone || errorEmailOrPhone,
    [values, errorEmailOrPhone]
  );

  React.useEffect(() => {
    if (!isDesktop) {
      logout();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CustomPage>
      {!showingKeyboard ? (
        <BoxMUI
          sx={{
            width: "150vw",
            height: "40vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "-12vh",
            marginLeft: "-25vw",
            padding: "0 25vw",
            paddingTop: "32px",
            borderRadius: "50%",
            background: (theme) =>
              `linear-gradient(to top, ${theme.palette.primary.main}, #93a4f2)`,
          }}
        >
          <BoxMUI
            style={{
              width: "320px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              color: "white",
              textAlign: "center",
            }}
          >
            <BoxMUI
              style={{
                width: "60vw",
                maxWidth: "400px",
              }}
            >
              <img src={Imgs.logoWhite} alt="logo" width="100%" />
            </BoxMUI>
          </BoxMUI>
        </BoxMUI>
      ) : null}
      <Separator px={24} />
      {/* body */}
      <BoxMUI
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <BoxMUI
          style={{
            width: "300px",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Separator px={8} />
          <form noValidate autoComplete="off" style={{ width: "100%" }}>
            <FormInputEmailOrPhone
              value={values.emailOrPhone}
              onChange={(text, type) =>
                setValues((v) => ({
                  ...v,
                  emailOrPhone: text,
                  isNumberType: type === "phone",
                }))
              }
              onError={(v) => setErrorEmailOrPhone(() => v)}
            />
            <Separator px={8} />
            <FormInputPassword
              value={values.password}
              onChange={(text) => setValues((v) => ({ ...v, password: text }))}
              disabledValidation={true}
            />
            <BoxMUI
              style={{
                padding: "8px 0",
              }}
              className="FlexRow JustifyEnd"
            >
              <LinkMUI
                onClick={() => historyPush("/recover-password")}
                className="TypographyBold11pt"
              >
                Olvidé mi contraseña
              </LinkMUI>
            </BoxMUI>
            <ButtonMUI
              style={{
                width: "100%",
                marginTop: "16px",
              }}
              variant="contained"
              color="primary"
              onClick={handleLogin}
              disabled={disabledLogin}
            >
              Iniciar sesión
            </ButtonMUI>
          </form>
          <BoxMUI
            style={{
              width: "100%",
              marginTop: "16px 0",
            }}
          >
            <DividerAntd plain>
              <TypographyMUI className="TypographyRegular10pt">
                O inicia sesión con
              </TypographyMUI>
            </DividerAntd>
          </BoxMUI>
          <BoxMUI className="FlexRow FlexCenter">
            <FacebookLogin login fab={true} />
            <Separator px={32} orientation="vertical" />
            <GoogleLogin login fab={true} />
            {isIos ? (
              <>
                <Separator px={32} orientation="vertical" />
                <AppleLogin login fab={true} />
              </>
            ) : null}
          </BoxMUI>
          <Separator px={32} />
          <BoxMUI>
            <TypographyMUI className="TypographyRegular11pt">
              ¿No tenés cuenta?&nbsp;&nbsp;
              <LinkMUI
                onClick={() => historyPush("/register")}
                className="TypographyBold11pt"
              >
                ¡Registrate ahora!
              </LinkMUI>
            </TypographyMUI>
          </BoxMUI>
        </BoxMUI>
      </BoxMUI>
    </CustomPage>
  );
};

export default LoginPage;
