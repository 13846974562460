import { gql } from "@apollo/client";
import { FragmentService } from "../FragmentService";

export const CREATE_RESERVATION = gql`
  # crear una reserva
  mutation createOneReservation($data: ReservationCreateInput!) {
    createOneReservation(data: $data) {
      ...FReservation
    }
  }
  ${FragmentService.Reservation}
`;

export const REVIEW_ONE_RESERVATION = gql`
  # confirmar una reserva
  mutation reviewOneReservation(
    $data: ReservationReviewInput!
    $where: IdInput!
  ) {
    reviewOneReservation(data: $data, where: $where) {
      id
    }
  }
`;

export const CANCEL_RESERVATION = gql`
  # cancelar una reserva
  mutation cancelOneReservation(
    $data: ReservationCancelInput
    $where: IdInput!
  ) {
    cancelOneReservation(where: $where, data: $data) {
      id
    }
  }
`;
