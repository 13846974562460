import React from "react";
import { IconButtonMUI } from "..";
import { MoreIcon } from "../CustomIcon";

export interface MenuOptionsButtonProps {
  menuId: string;
  onClick?: () => void;
}

const MenuOptionsButton: React.FC<MenuOptionsButtonProps> = (props) => {
  const { menuId, onClick } = props;
  return (
    <IconButtonMUI
      aria-label="show more"
      aria-controls={menuId}
      aria-haspopup="true"
      onClick={onClick}
      color="inherit"
      edge="end"
    >
      <MoreIcon style={{color: "#3F5AA6"}}/>
    </IconButtonMUI>
  );
};

export default MenuOptionsButton;
