import React from "react";
import { UpoolContext } from "../Providers/UpoolContext";
import { PREFERENCES } from "../Utils/Constants";
import { useCustomApolloAsync } from "./GraphQL/ApolloService";
import { Query } from "./GraphQL/Query";

export const PreferenceService = () => {
  const { queryAsync } = useCustomApolloAsync();
  const { userIsGenderF } = React.useContext(UpoolContext);

  const getPreferences = (
    filterOnlyWomen?: boolean
  ): Promise<IPreference[]> => {
    return queryAsync({
      query: Query.preferences,
    }).then((list: IPreference[]) => {
      if (filterOnlyWomen && !userIsGenderF) {
        return list.filter(
          (p) =>
            p.id !== PREFERENCES.ONLY_WOMEN &&
            p.id !== PREFERENCES.FREE_SEAT_MIDDLE
        );
      }
      return list.filter((p) => p.id !== PREFERENCES.FREE_SEAT_MIDDLE);
    });
  };

  return {
    getPreferences,
  };
};

export interface IPreference {
  id: number;
  name: string;
  icon: string;
  description: string;
  // front params
  active?: boolean;
}

export interface IPreferenceTrip {
  id: number;
  preferenceId: number;
  tripId: number;
  enabled: boolean;
  preference: IPreference;
}

export interface IPreferenceUser {
  id?: number;
  preferenceId: number;
  userId: number;
  enabled: boolean;
  preference: IPreference;
}
