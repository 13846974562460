import React from "react";
import { useCustomApolloSubscription } from "../../DeltaUI/Library/apollo";
import { ApolloServiceError } from "../../DeltaUI/Library/apollo/utils";
import { Subscription } from "../../Services/GraphQL/Subscription";

interface SubsciptionChatProps {
  style?: React.CSSProperties;
  reservationId: number;
  onNext?: (value: any) => void;
  onError?: (err: ApolloServiceError) => void;
}

export const SubsciptionChat: React.FC<SubsciptionChatProps> = (props) => {
  const { reservationId, onNext, onError } = props;
  const [callSubscription] = useCustomApolloSubscription(
    Subscription.reservationChatCreated
  );

  React.useEffect(() => {
    callSubscription({
      variables: {
        where: {
          reservationId,
        },
      },
      next: onNext,
      error: onError,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};
