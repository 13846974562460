import React from "react";
import { UpoolContext } from "../../Providers/UpoolContext";
import { TripService } from "../../Services/TripService";
import { useComponentMount, useCustomLog } from "../../Utils";
import { TRIP_STATUS } from "../../Utils/Constants";
import { MyTripsContext } from "../MyTrips/MyTripsContext";

interface RecreateSearchOfTripProps {
  t: string;
}

export const RecreateSearchOfTrip: React.FC<RecreateSearchOfTripProps> = (
  props
) => {
  const isMountedComponent = useComponentMount("RecreateSearchOfTrip");
  const { t } = props;

  const { tripStretch } = TripService();
  const Log = useCustomLog();
  const { setLoading } = React.useContext(UpoolContext);
  const { setSelectTripStretch, setLoadingMyTrips } = React.useContext(
    MyTripsContext
  );

  const tripStrechId = React.useMemo(() => {
    const v = Number(t);
    if (v) {
      return v;
    }
    return undefined;
  }, [t]);

  const updateSelectTripStretch = async () => {
    if (tripStrechId) {
      setSelectTripStretch(() => undefined);
      setLoading(() => true);
      tripStretch(tripStrechId)
        .then((ts) => {
          if (
            isMountedComponent.current &&
            ts &&
            ts.trip.statusId === TRIP_STATUS.AVAILABLE
          ) {
            setSelectTripStretch(() => ts);
          }
        })
        .catch(({ message }) => {
          Log.error({
            context: "RecreateSearchOfTrip.updateSelectTripStretch.1",
            message,
          });
        })
        .finally(() => {
          setLoadingMyTrips(() => false);
          setLoading(() => false);
        });
    }
  };

  React.useEffect(() => {
    isMountedComponent.current = true;
    updateSelectTripStretch();
    return () => {
      isMountedComponent.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMountedComponent]);

  return <></>;
};
