import React from "react";
import { UpoolContext } from "../Providers/UpoolContext";
import {
  ToolbarMUI,
  TypographyMUI,
  MenuButton,
  BodyPage,
  Separator,
  BoxMUI,
} from "../DeltaUI/Components";
import { CardAction, CardHomePhoto, CustomPage } from "../Components";
import { Imgs } from "../Assets";
import { APPLICATION_PATHS } from "../Utils/Constants";
import { CardHome } from "../Components/Card/CardHome";

const HomePage: React.FC = () => {
  const { historyPush, person } = React.useContext(UpoolContext);

  return (
    <CustomPage>
      <BodyPage>
        <BoxMUI
          sx={{
            position: "absolute",
            top: 0,
            height: "calc(56px + 56px + 56px + 20px)",
            width: "100%",
            zIndex: -1,
            background: (theme) =>
              `linear-gradient(to top, ${theme.palette.primary.main}, #93a4f2)`,
          }}
        />
        <BoxMUI>
          <ToolbarMUI>
            <MenuButton style={{ color: "white" }} />
          </ToolbarMUI>
          <ToolbarMUI>
            <BoxMUI className="FlexColumn">
              <TypographyMUI className="TypographyRegular13pt" color="white">
                Hola
              </TypographyMUI>
              <Separator px={8} />
              {person ? (
                <TypographyMUI
                  className="TypographyBold20pt"
                  color="white"
                  style={{ lineHeight: "24px" }}
                >
                  {person.firstName}
                </TypographyMUI>
              ) : null}
            </BoxMUI>
          </ToolbarMUI>
          <Separator px={24} />
        </BoxMUI>
        <BoxMUI
          sx={{
            background: "white",
            padding: "24px 24px",
            borderRadius: "15px 15px 0 0",
          }}
        >
          <TypographyMUI className="TypographyExtrabold13pt">
            ¿Qué querés hacer?
          </TypographyMUI>
          <Separator px={16} />
          <BoxMUI
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <CardAction
              icon={
                <img
                  src={Imgs.homeCreateTrip}
                  alt="homeCreateTrip"
                  width="100%"
                  style={{
                    marginTop: "10px",
                    marginBottom: "-10px",
                  }}
                />
              }
              label="Crear un viaje"
              onClick={() => historyPush(APPLICATION_PATHS.CREATE_TRIP)}
            />
            <Separator px={16} orientation="vertical" />
            <CardAction
              icon={
                <img
                  src={Imgs.homeSearchTrip}
                  alt="homeSearchTrip"
                  height="100%"
                />
              }
              label="Unirme a un viaje"
              onClick={() => historyPush(APPLICATION_PATHS.SEARCH_TRIP)}
            />
          </BoxMUI>
          <Separator px={32} />
          <CardHomePhoto />
          <Separator px={32} />
          <CardHome />
          <Separator px={48} />
        </BoxMUI>
      </BodyPage>
    </CustomPage>
  );
};

export default HomePage;
