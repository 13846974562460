import React from "react";
import {
  BoxMUI,
  IconButtonMUI,
  PaperMUI,
  TextFieldMUI,
} from "../../DeltaUI/Components";
import { CloseIcon, SendIcon } from "../../DeltaUI/Components/CustomIcon";
import { useComponentMount } from "../../Utils";

export interface WriteMessageProps {
  onChange?: (value: string) => void;
  onHeight?: (value: number) => void;
  showingKeyboard?: boolean;
}

const WriteMessage: React.FC<WriteMessageProps> = (props) => {
  const isMountedComponent = useComponentMount("WriteMessage");
  const { onChange, onHeight, showingKeyboard } = props;
  const [text, setText] = React.useState("");
  const [height, setHeight] = React.useState<number>(0);
  const textFieldMUIRef = React.useRef<HTMLDivElement | null | undefined>();
  const inputMultilineRef = React.useRef<HTMLInputElement | null | undefined>();

  React.useEffect(() => {
    // mejora la detección de cambio
    setTimeout(() => {
      if (textFieldMUIRef.current) {
        const h = textFieldMUIRef.current.clientHeight;
        const h2 = h + 22;
        if (h2 !== height) {
          if (h2 > 56) {
            setHeight(() => h2);
          } else {
            setHeight(() => 56);
          }
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [text]);

  React.useEffect(() => {
    if (onHeight) {
      onHeight(height);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [height]);

  React.useEffect(() => {
    isMountedComponent.current = true;
    return () => {
      isMountedComponent.current = false;
    };
  }, [isMountedComponent]);

  return (
    <PaperMUI
      elevation={4}
      style={{
        position: "absolute",
        bottom: 0,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        minHeight: "48px",
        background: "white",
        zIndex: 1,
      }}
    >
      <BoxMUI
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          minHeight: "48px",
          padding: "8px 0",
          paddingLeft: "24px",
        }}
      >
        <BoxMUI
          style={{
            display: "flex",
            alignItems: "flex-end",
            width: "100%",
            minHeight: "40px",
            padding: "3px 0",
            borderRadius: "20px",
            background: "white",
          }}
          sx={{
            border: "1px solid primary.main",
          }}
        >
          <TextFieldMUI
            variant="standard"
            ref={(r) => {
              textFieldMUIRef.current = r;
            }}
            inputRef={(r: HTMLInputElement | null) => {
              inputMultilineRef.current = r;
            }}
            style={{
              width: "100%",
            }}
            placeholder="Mensaje"
            multiline
            maxRows={4}
            value={text}
            onChange={(ev) => setText(() => ev.target.value)}
          />

          {text.length > 80 ? (
            <IconButtonMUI
              style={{
                position: "absolute",
                top: "12px",
                right: "14px",
              }}
              color="primary"
              size="small"
              onClick={() => setText(() => "")}
            >
              <CloseIcon />
            </IconButtonMUI>
          ) : null}

          <IconButtonMUI
            style={{
              paddingRight: "24px",
            }}
            color="primary"
            size="small"
            onClick={() => {
              if (showingKeyboard && inputMultilineRef.current) {
                inputMultilineRef.current.focus();
              }
              if (onChange) {
                onChange(text);
                setText(() => "");
              }
            }}
          >
            <SendIcon />
          </IconButtonMUI>
        </BoxMUI>
      </BoxMUI>
    </PaperMUI>
  );
};

export default WriteMessage;
