import { gql } from "@apollo/client";
import { FragmentService } from "../FragmentService";

export const RESERVATIONS = gql`
  query reservations(
    $where: ReservationWhereInput
    $orderBy: [ReservationOrderByInput!]
    $first: Int
    $last: Int
    $before: ReservationWhereUniqueInput
    $after: ReservationWhereUniqueInput
  ) {
    reservations(
      where: $where
      orderBy: $orderBy
      first: $first
      last: $last
      before: $before
      after: $after
    ) {
      ...FReservation
    }
  }
  ${FragmentService.Reservation}
`;

export const RESERVATION = gql`
  query reservation($where: ReservationWhereUniqueInput!) {
    reservation(where: $where) {
      ...FReservation
    }
  }
  ${FragmentService.Reservation}
`;
