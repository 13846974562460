import React from "react";
import { CustomApolloContext } from "../provider";
import { CustomMutationOptions } from "./useCustomApolloMutation";
import { CustomQueryOptions } from "./useCustomApolloQuery";
import {
  ApolloServiceError,
  IRequest,
  parseError,
  resultQuery,
} from "../utils";

export const useCustomApolloAsync = () => {
  const { client } = React.useContext(CustomApolloContext);

  const callQuery = React.useCallback(
    (options: CustomQueryOptionsAsync) => {
      return new Promise(
        (
          resolve: (data: any) => void,
          reject: (err: ApolloServiceError) => void
        ) => {
          const handleError = (err: ApolloServiceError) => {
            return reject(parseError(err));
          };

          client
            .query({
              ...{
                fetchPolicy: "no-cache",
                ...options,
              },
              query: options.query.gql,
            })
            .then((res: any) => {
              resultQuery(res.data[options.query.name])
                .then((d: any) => {
                  return resolve(d);
                })
                .catch(handleError);
            })
            .catch(handleError);
        }
      );
    },
    [client]
  );

  const callMutation = React.useCallback(
    (options: CustomMutationOptionsAsync) => {
      return new Promise(
        (
          resolve: (data: any) => void,
          reject: (err: ApolloServiceError) => void
        ) => {
          const handleError = (err: ApolloServiceError) => {
            return reject(parseError(err));
          };

          client
            .mutate({
              ...{
                fetchPolicy: "no-cache",
                ...options,
              },
              mutation: options.mutation.gql,
            })
            .then((res: any) => {
              resultQuery(res.data[options.mutation.name])
                .then((d: any) => {
                  return resolve(d);
                })
                .catch(handleError);
            })
            .catch(handleError);
        }
      );
    },
    [client]
  );

  return {
    callQuery,
    callMutation,
  };
};

export interface CustomQueryOptionsAsync extends CustomQueryOptions {
  query: IRequest;
}

export interface CustomMutationOptionsAsync extends CustomMutationOptions {
  mutation: IRequest;
}
