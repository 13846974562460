import React from "react";
import { TabsMUI, TabMUI, AppBarMUI, TypographyMUI, BadgeMUI } from "..";

export interface TabsProps {
  elevation?: number;
  list: {
    key: number;
    label: string | JSX.Element;
    onClick?: (key: number) => void;
    badgeContent?: number;
  }[];
  onChange?: (key: number) => void;
  value?: number;
  defaultValue: number;
  color?: "primary" | "secondary";
  textColor?: string;
  indicatorColor?: "primary" | "secondary";
  invertedColor?: boolean;
}

const Tabs: React.FC<TabsProps> = (props) => {
  const {
    elevation,
    list,
    onChange,
    value,
    defaultValue,
    color,
    invertedColor,
    textColor,
    indicatorColor,
  } = props;
  const [select, setSelect] = React.useState<number>(value || defaultValue);

  React.useEffect(() => {
    if (list.length) {
      const tab = list.find((item) => item.key === select);
      if (tab && tab.onClick) {
        tab.onClick(select);
      }
      if (onChange) {
        onChange(select);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [select]);

  React.useEffect(() => {
    if (value) {
      setSelect(() => value);
    }
  }, [value]);

  return (
    <AppBarMUI elevation={elevation || 0} position="static">
      <TabsMUI
        value={select}
        variant="fullWidth"
        centered
        sx={{
          maxHeight: "48px",
          bgcolor: (theme) =>
            invertedColor
              ? theme.palette[color || "primary"].contrastText
              : theme.palette[color || "primary"].main,
          "& .MuiTabs-indicator": {
            bgcolor: (theme) =>
              indicatorColor
                ? theme.palette[indicatorColor].main
                : invertedColor
                ? theme.palette[color || "primary"].main
                : theme.palette[color || "primary"].contrastText,
          },
        }}
      >
        {list.length
          ? list.map((tab) => (
              <TabMUI
                key={tab.key}
                label={
                  <>
                    <BadgeMUI badgeContent={tab.badgeContent} color="secondary">
                      <TypographyMUI
                        className="TypographyBold11pt"
                        sx={{
                          textTransform: "none",
                          color: (theme) =>
                            textColor
                              ? textColor
                              : invertedColor
                              ? theme.palette[color || "primary"].main
                              : theme.palette[color || "primary"].contrastText,
                        }}
                      >
                        {tab.label}
                      </TypographyMUI>
                    </BadgeMUI>
                  </>
                }
                onClick={() => setSelect(() => tab.key)}
                value={tab.key}
              />
            ))
          : null}
      </TabsMUI>
    </AppBarMUI>
  );
};

export default Tabs;
