import React from "react";
import {
  ListItemMUI,
  ListItemTextMUI,
  TypographyMUI,
} from "../../../DeltaUI/Components";
import { RADIUS } from "../../../Utils/Constants";
import { SearchTripContext } from "../SearchTripContext";
import SelectRadius from "../SelectRadius";

export interface AdvancedFiltersRadiusProps {}

const AdvancedFiltersRadius: React.FC<AdvancedFiltersRadiusProps> = (props) => {
  const { radiusFrom, radiusTo, setRadiusTo, setRadiusFrom } =
    React.useContext(SearchTripContext);
  return (
    <>
      <ListItemMUI>
        <ListItemTextMUI
          primary={
            <TypographyMUI className="TypographyExtrabold11pt">
              Cercanía al punto de salida
            </TypographyMUI>
          }
        />
      </ListItemMUI>
      <SelectRadius
        value={radiusFrom || RADIUS.MAX}
        onChange={(ev) => setRadiusFrom(ev)}
        min={RADIUS.MIN}
        max={RADIUS.MAX}
      />
      <ListItemMUI>
        <ListItemTextMUI
          primary={
            <TypographyMUI className="TypographyExtrabold11pt">
              Cercanía al punto de llegada
            </TypographyMUI>
          }
        />
      </ListItemMUI>
      <SelectRadius
        value={radiusTo || RADIUS.MAX}
        onChange={(ev) => setRadiusTo(ev)}
        min={RADIUS.MIN}
        max={RADIUS.MAX}
      />
    </>
  );
};

export default AdvancedFiltersRadius;
