import React from "react";
import { config } from "../config";
import { CustomApolloProvider } from "../DeltaUI/Library/apollo";
import { SetterState } from "../DeltaUI/types";

interface ICustomApolloLibrary {
  token: string | undefined;
  setToken: SetterState<string | undefined>;
}

const context: ICustomApolloLibrary = {
  token: undefined,
  setToken: () => {},
};

export const CustomApolloLibraryContext = React.createContext(context);

export interface CustomApolloLibraryProviderProps {}

export const CustomApolloLibraryProvider: React.FC<CustomApolloLibraryProviderProps> = (
  props
) => {
  const [token, setToken] = React.useState<string>();
  return (
    <CustomApolloLibraryContext.Provider value={{ token, setToken }}>
      <CustomApolloProvider
        uri={config.env.httpUrl}
        wssUri={config.env.wssUrl}
        token={token}
        clientSecret={config.env.clientSecret}
      >
        {props.children}
      </CustomApolloProvider>
    </CustomApolloLibraryContext.Provider>
  );
};
