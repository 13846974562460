import React from "react";
import { Imgs } from "../../Assets";
import { BoxMUI } from "../../DeltaUI/Components";
import { FooterShare } from "../../Components/Share/FooterShare";
import { useCustomLog } from "../../Utils";
import { Share } from "@capacitor/share";
import { UpoolContext } from "../../Providers/UpoolContext";
import { Clipboard } from "@capacitor/clipboard";
import { URL } from "../../Utils/Constants";
import { HeaderShare } from "../../Components/Share/HeaderShare";

interface HomePublicPageProps {}

const HomePublicPage: React.FC<HomePublicPageProps> = () => {
  const { setLoading } = React.useContext(UpoolContext);
  const Log = useCustomLog();

  const share = async () => {
    try {
      setLoading(() => true);
      await Clipboard.write({
        string: URL.UPOOL,
      });
      await Share.share({
        text: "Mi viaje",
        url: URL.UPOOL,
        dialogTitle: "Compartir",
      }).catch((err) => {
        Log.error({
          context: "HomePublicPage.share.1",
          message: err.message,
        });
      });
    } catch (err: any) {
      Log.error({
        context: "HomePublicPage.share.2",
        message: err.message,
      });
    } finally {
      setLoading(() => false);
    }
  };

  return (
    <BoxMUI
      style={{
        width: "100vw",
        height: "100vh",
      }}
    >
      <HeaderShare
        height={420}
        style={{ backgroundImage: `url(${Imgs.upoolSplashScreen})` }}
        className="BackgroundImage"
      />
      <FooterShare onShare={share} />
    </BoxMUI>
  );
};

export default HomePublicPage;
