import React from "react";
import { CardMUI } from "..";
export interface ItemCardProps {
  separation: 0 | 8 | 16 | 32 | 64 | 128;
  elevation?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7;
  alignItems?: "flex-start" | "flex-end";
}

const CardCarousel: React.FC<ItemCardProps> = (props) => {
  const { children, separation, elevation, alignItems } = props;
  return (
    <CardMUI
      elevation={elevation || 4}
      style={{
        display: "flex",
        position: "relative",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        minWidth: "80vw",
        height: "min-content",
        marginLeft: `${separation / 2}px`,
        marginRight: `${separation / 2}px`,
        alignItems: alignItems || "flex-end",
      }}
    >
      {children ? (
        <div
          style={{
            width: "100%",
          }}
        >
          {children}
        </div>
      ) : null}
    </CardMUI>
  );
};

export default CardCarousel;
