import React from "react";
import { CardMUI, ChipMUI } from "../../DeltaUI/Components";
import { SetterState } from "../../Services/DefaultTypes";
import { IPreference } from "../../Services/PreferenceService";
import { SvgIcon } from "../SvgIcon";

export interface PreferencesListFilterProps {
  disabled?: boolean;
  max?: number;
  preferencesList: IPreference[];
  setPreferencesList: SetterState<IPreference[]>;
  color?: "primary" | "secondary";
}

const PreferencesListFilter: React.FC<PreferencesListFilterProps> = (props) => {
  const { disabled, max, preferencesList, setPreferencesList, color } = props;

  const handleClick = (pref: IPreference) => {
    setPreferencesList((list: IPreference[]) => {
      list.forEach((element) => {
        if (element.id === pref.id) {
          element.active = element.active ? !element.active : true;
        }
      });
      return [...list];
    });
  };

  const renderChips = () => {
    return (
      <>
        {preferencesList.slice(0, max).map((pref) => (
          <ChipMUI
            key={pref.id}
            sx={{
              height: "40px",
              margin: "8px 0",
              marginRight: "8px",
              paddingLeft: "4px",
              borderRadius: "50px",
              bgcolor: pref.active
                ? (theme) => `${theme.palette.primary.main}17`
                : undefined,
            }}
            size="medium"
            variant="outlined"
            icon={<SvgIcon src={pref.icon} />}
            label={pref.name}
            color={pref.active ? color || "primary" : undefined}
            onClick={!disabled ? () => handleClick(pref) : undefined}
          />
        ))}
      </>
    );
  };

  return (
    <CardMUI
      style={{ marginRight: "-8px" }}
      sx={{
        "& .MuiChip-outlinedPrimary:hover": {
          bgcolor: (theme) => `${theme.palette.primary.main}17`,
        },
        "& .MuiChip-outlinedDefault:hover": {
          bgcolor: (theme) => `${theme.palette.primary.contrastText}`,
        },
      }}
      elevation={0}
    >
      {renderChips()}
    </CardMUI>
  );
};

export default PreferencesListFilter;
