import { LocationAccuracy } from "@ionic-native/location-accuracy";
import { Diagnostic } from "@ionic-native/diagnostic";

import { isAndroid, isDesktop } from "./Constants";

export type StatusGPS = "GRANTED" | "DENIED" | "DISABLED" | "ERROR";
export enum GPS_STATUS {
  GRANTED = "GRANTED",
  DENIED = "DENIED",
  DISABLED = "DISABLED",
  ERROR = "ERROR",
}

/**
 * Solicitar activación de GPS
 */
const requestGPSActivation = async (): Promise<StatusGPS> => {
  try {
    return LocationAccuracy.canRequest()
      .then(function (canRequest: any) {
        if (canRequest) {
          // the accuracy option will be ignored by iOS
          return LocationAccuracy.request(
            LocationAccuracy.REQUEST_PRIORITY_BALANCED_POWER_ACCURACY
          )
            .then(() => {
              // window.confirm("GPS activado");
              return Promise.resolve(GPS_STATUS.GRANTED);
            })
            .catch((error) => {
              console.error(
                "Error al solicitar la precisión de la ubicación:" +
                  JSON.stringify(error)
              );
              if (error) {
                // Android only
                console.error(
                  "error code=" +
                    error.code +
                    "; error message=" +
                    error.message
                );
                if (
                  isAndroid &&
                  error.code !== LocationAccuracy.ERROR_USER_DISAGREED
                ) {
                  const msg =
                    "¡No se pudo activar el GPS automáticamente! Deberá hacerlo manualmente desde la configuración de su dispositivo.";
                  console.error(msg);
                  if (window.confirm(msg)) {
                    Diagnostic.switchToLocationSettings();
                  }
                }
              }
              return Promise.resolve(GPS_STATUS.DISABLED);
            });
        } else {
          // On iOS, this will occur if Location Services is currently on OR a request is currently in progress.
          // On Android, this will occur if the app doesn't have authorization to use location.
          console.error("No se puede solicitar la precisión de la ubicación");
          return Promise.resolve(GPS_STATUS.ERROR);
        }
      })
      .catch((err) => {
        // On iOS, this will occur if Location Services is currently on OR a request is currently in progress.
        // On Android, this will occur if the app doesn't have authorization to use location.
        console.error("No se puede solicitar la precisión de la ubicación");
        return Promise.resolve(GPS_STATUS.ERROR);
      });
  } catch (err) {
    console.error(err);
    return Promise.resolve(GPS_STATUS.ERROR);
  }
};

/**
 * Verifica si el gps está encendido
 */
const isGPSEnabled = (): Promise<StatusGPS> => {
  return Diagnostic.isLocationEnabled()
    .then((r) => {
      if (!r) {
        return requestGPSActivation();
      }
      return Promise.resolve(GPS_STATUS.GRANTED);
    })
    .catch((e) => {
      console.error("isLocationEnabled error", e);
      return Promise.resolve(GPS_STATUS.ERROR);
    });
};

/**
 * Resuelve el código de permiso del usuario para la solicitud de ubicación actual
 * @param status
 */
const resolveAuthorizationStatus = (status: any): Promise<StatusGPS> => {
  // Usuario concedió el permiso para usar la ubicación
  if (status === Diagnostic.permissionStatus.GRANTED) {
    return isGPSEnabled();
  }

  // Requiere solicitar permiso
  if (status === Diagnostic.permissionStatus.NOT_REQUESTED) {
    return Diagnostic.requestLocationAuthorization()
      .then((s) => resolveAuthorizationStatus(s))
      .catch((err) => {
        console.error(err.message);
        return Promise.resolve(GPS_STATUS.ERROR);
      });
  }

  // Usuario denegado el permiso para usar la ubicación
  if (status === Diagnostic.permissionStatus.DENIED_ONCE) {
    console.error("Diagnostic.permissionStatus.DENIED_ONCE");
    return Promise.resolve(GPS_STATUS.DENIED);
  }

  // Android only
  // Usuario denegado el permiso para usar la ubicación
  if (status === Diagnostic.permissionStatus.DENIED_ALWAYS) {
    console.error("Diagnostic.permissionStatus.DENIED_ALWAYS");
    return Promise.resolve(GPS_STATUS.DENIED);
  }

  // iOS only
  // Usuario concedió el permiso para usar la ubicación
  if (status === Diagnostic.permissionStatus.GRANTED_WHEN_IN_USE) {
    return isGPSEnabled();
  }

  // ninguna de las anteriores
  return Promise.resolve(GPS_STATUS.ERROR);
};

/**
 * Chequea los permisos del usuario para la solicitud de ubicación actual y verifica si está activo el GPS
 */
const isAvailable = (): Promise<StatusGPS> => {
  if (!isDesktop) {
    return Diagnostic.getLocationAuthorizationStatus()
      .then((s) => resolveAuthorizationStatus(s))
      .catch((err) => {
        console.error(err.message);
        return Promise.resolve(GPS_STATUS.ERROR);
      });
  }
  return Promise.resolve(GPS_STATUS.GRANTED);
};

const request = (): Promise<StatusGPS> => {
  if (!isDesktop) {
    return Diagnostic.requestLocationAuthorization()
      .then((s) => resolveAuthorizationStatus(s))
      .catch((err) => {
        console.error(err.message);
        return Promise.resolve(GPS_STATUS.ERROR);
      });
  }
  return Promise.resolve(GPS_STATUS.GRANTED);
};

export const GPS = () => {
  return {
    request,
    isAvailable,
  };
};
