import { useCustomApolloAsync } from "./GraphQL/ApolloService";
import { Mutation } from "./GraphQL/Mutation";
import { Query } from "./GraphQL/Query";

export const ShareService = () => {
  const { queryAsync, mutationAsync } = useCustomApolloAsync();

  const share = (id: number): Promise<IShare> => {
    return queryAsync({
      query: Query.share,
      variables: {
        where: {
          id,
        },
      },
    });
  };

  const createOneShare = (description: string): Promise<IShare> => {
    return mutationAsync({
      mutation: Mutation.createOneShare,
      variables: {
        data: {
          description,
        },
      },
    });
  };

  return {
    share,
    createOneShare,
  };
};

export interface IShare {
  id: number;
  description: string;
}
