import React from "react";
import { Loading } from "../DeltaUI/Components";
import { AppContext } from "./AppContext";
import { ProgressiveWebAppContext } from "./ProgressiveWebApp";

export interface ICustomLoadingLibrary {
  onLoadingApp: () => void;
  offLoadingApp: () => void;
}

const context: ICustomLoadingLibrary = {
  onLoadingApp: () => {},
  offLoadingApp: () => {},
};

export const CustomLoadingLibraryContext = React.createContext(context);

export const CustomLoadingLibraryProvider: React.FC = (props) => {
  const { loadingProgressiveWebApp } = React.useContext(
    ProgressiveWebAppContext
  );
  const { loading, setLoading } = React.useContext(AppContext);
  const countLoading = React.useRef<number>(0);

  const onLoadingApp = React.useMemo(() => {
    return () => {
      countLoading.current = countLoading.current + 1;
      setLoading(() => !!countLoading.current);
    };
  }, [setLoading]);

  const offLoadingApp = React.useMemo(() => {
    return () => {
      if (countLoading.current) {
        countLoading.current = countLoading.current - 1;
      }
      setLoading(() => !!countLoading.current);
    };
  }, [setLoading]);

  return (
    <CustomLoadingLibraryContext.Provider
      value={{
        onLoadingApp,
        offLoadingApp,
      }}
    >
      <Loading open={loading || loadingProgressiveWebApp} />
      {props.children}
    </CustomLoadingLibraryContext.Provider>
  );
};
