import { gql } from "@apollo/client";
import { FragmentService } from "../FragmentService";

export const AVAILABLE_TRIP_STRETCHES = gql`
  query tripStretchesAvailables(
    $where: TripStretchesAvailablesWhereInput!
    $pagination: PaginationInput
    $orderBy: TripStretchesAvailablesOrderByInput
  ) {
    tripStretchesAvailables(
      where: $where
      pagination: $pagination
      orderBy: $orderBy
    ) {
      pagination {
        skip
        take
      }
      where {
        radiusFrom
        radiusTo
      }
      result {
        info {
          distanceMtsFrom
          distanceMtsTo
        }
        tripStretch {
          ...FTripStretch
        }
      }
    }
  }
  ${FragmentService.TripStretch}
`;

export const TRIP_STRETCH = gql`
  query tripStretch($where: TripStretchWhereUniqueInput!) {
    tripStretch(where: $where) {
      ...FTripStretch
    }
  }
  ${FragmentService.TripStretch}
`;

export const TRIPS_STRETCHES = gql`
  query tripStretches(
    $where: TripStretchWhereInput
    $orderBy: [TripStretchOrderByInput!]
    $first: Int
    $last: Int
    $before: TripStretchWhereUniqueInput
    $after: TripStretchWhereUniqueInput
  ) {
    tripStretches(
      where: $where
      orderBy: $orderBy
      first: $first
      last: $last
      before: $before
      after: $after
    ) {
      ...FTripStretch
    }
  }
  ${FragmentService.TripStretch}
`;

export const TRIP_SUGGESTED_AMOUNT = gql`
  query tripSuggestedAmount($where: TripSuggestedAmountInput!) {
    tripSuggestedAmount(where: $where) {
      suggested
      min
      max
    }
  }
`;

export const HOME_PAGE_TRIP_STRECH = gql`
  query tripStrechHomePage {
    tripStrechHomePage {
      ...FTripStretch
    }
  }
  ${FragmentService.TripStretch}
`;
