import React from "react";
import {
  ToolbarMUI,
  BodyPage,
  FooterPage,
  ButtonGroup,
  TypographyMUI,
  Separator,
  BoxMUI,
  IconButtonMUI,
} from "../../DeltaUI/Components";
import { IMyAvailableTripStretchResult } from "../../Services/TripService";
import { SearchTripContext } from "../../Components/SearchTrip/SearchTripContext";
import { CardTrip } from "../Card";
import AdvancedFilters from "./AdvancedFilters/AdvancedFilters";
import LoadData, { LoadDataSpin } from "../LoadData";
import { Virtuoso } from "react-virtuoso";
import { FilterListIcon, ShareIcon } from "../../DeltaUI/Components/CustomIcon";
import { TitlePage } from "../TitlePage";
import { GoBack } from "../GoBack";
import {
  AVAILABLE_SCREEN_SPACE_CSS,
  COLOR_PRIMARY,
} from "../../Utils/Constants";
import { CustomPage } from "../CustomPage";

const SearchTripResults: React.FC = () => {
  const { data, loadingSearchTripResults, search, share } = React.useContext(
    SearchTripContext
  );
  const [viewFilter, setViewFilter] = React.useState<boolean>(false);
  return (
    <CustomPage
      headerPage={
        <ToolbarMUI>
          <GoBack />
          <TitlePage text="Viajes disponibles" />
          <ButtonGroup slot="end">
            <IconButtonMUI color="inherit" onClick={share}>
              <ShareIcon style={{ color: COLOR_PRIMARY }} />
            </IconButtonMUI>
            <IconButtonMUI
              color="inherit"
              edge="end"
              onClick={() => setViewFilter(() => true)}
            >
              <FilterListIcon style={{ color: COLOR_PRIMARY }} />
            </IconButtonMUI>
          </ButtonGroup>
        </ToolbarMUI>
      }
      footerPage={
        <FooterPage>
          <AdvancedFilters
            open={viewFilter}
            onSearch={() => {
              setViewFilter(() => false);
              search(true, true);
            }}
            onBack={() => {
              setViewFilter(() => false);
            }}
          />
        </FooterPage>
      }
    >
      <BodyPage>
        <BoxMUI>
          <Virtuoso
            style={{
              height: `calc(${AVAILABLE_SCREEN_SPACE_CSS} - 56px)`,
            }}
            data={data}
            endReached={() => search(false, true)}
            overscan={200}
            itemContent={(_, t: IMyAvailableTripStretchResult) => {
              return (
                <>
                  <Separator px={16} />
                  <CardTrip
                    key={t.tripStretch.id}
                    tripStretchInitial={t.tripStretch}
                    search={true}
                    infoTripStretch={t.info}
                    style={{
                      position: "relative",
                      margin: "8px 24px",
                    }}
                  />
                </>
              );
            }}
            components={{
              Footer: () => {
                return (
                  <>
                    {data.length && loadingSearchTripResults ? (
                      <LoadDataSpin />
                    ) : (
                      <LoadData
                        description="No hay resultados"
                        empty={!data.length}
                        loading={loadingSearchTripResults}
                        height="45vh"
                      >
                        <BoxMUI>
                          <Separator px={8} />
                          <TypographyMUI
                            className="FlexRow FlexCenter"
                            style={{ color: "#00000040" }}
                          >
                            No hay más resultados
                          </TypographyMUI>
                          <Separator px={24} />
                        </BoxMUI>
                      </LoadData>
                    )}
                  </>
                );
              },
            }}
          />
        </BoxMUI>
      </BodyPage>
    </CustomPage>
  );
};

export default SearchTripResults;
