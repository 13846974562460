import React from "react";
import { AppBarMUI, BoxMUI } from "..";

export interface HeaderPageProps {
  style?: React.CSSProperties;
  className?: string;
  m?: number;
  color?: "inherit" | "primary" | "secondary" | "default" | "transparent";
  elevation?: number;
}

const HeaderPage: React.FC<HeaderPageProps> = (props) => {
  const { children, m, className, style, color, elevation } = props;
  return (
    <AppBarMUI
      color={color}
      position="static"
      className={className}
      style={{
        zIndex: 1,
        ...style,
      }}
      elevation={elevation || 4}
    >
      <BoxMUI m={m}>{children}</BoxMUI>
    </AppBarMUI>
  );
};

export default HeaderPage;
