import React from "react";
import Cropper from "react-easy-crop";
import { UpoolContext } from "../../Providers/UpoolContext";
import {
  BoxMUI,
  ButtonMUI,
  Separator,
  IconButtonMUI,
  PaperMUI,
} from "../../DeltaUI/Components";
import {
  RotateLeftIcon,
  RotateRightIcon,
} from "../../DeltaUI/Components/CustomIcon";
import { Tools, useCustomLog } from "../../Utils";
import { getCroppedImg } from "../../Utils/CanvasUtils";
import { AVAILABLE_SCREEN_SPACE_CSS } from "../../Utils/Constants";

interface EditPhotoWithCropperProps {
  imageUrl: string;
  onSave?: (src: string) => void;
  onCancel?: () => void;
}

export const EditPhotoWithCropper: React.FC<EditPhotoWithCropperProps> = (
  props
) => {
  const { imageUrl, onSave, onCancel } = props;
  const { setLoading } = React.useContext(UpoolContext);
  const { srcToHTMLImageElement } = Tools();
  const Log = useCustomLog();
  const [crop, setCrop] = React.useState({ x: 0, y: 0 });
  const [zoom, setZoom] = React.useState(1);
  const [rotation, setRotation] = React.useState(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = React.useState<any>({});
  const [imageSrc, setImageSrc] = React.useState<string>("");

  const onCropComplete = React.useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  /**
   * Comprime una imagen a la calidad y resolución especifica
   * @param src
   * @param initQ
   * @param px
   * @returns
   */
  const compressFile = async (
    src: string,
    initQ?: number,
    px?: number
  ): Promise<string> => {
    return new Promise((resolve) => {
      srcToHTMLImageElement(src)
        .then((img) => {
          try {
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");

            if (px) {
              if (img.height > img.width) {
                canvas.width = px < img.width ? px : img.width;
                canvas.height = (canvas.width * img.height) / img.width;
              } else {
                canvas.height = px < img.height ? px : img.height;
                canvas.width = (canvas.height * img.width) / img.height;
              }
            } else {
              canvas.height = img.height;
              canvas.width = img.width;
            }

            if (ctx) {
              ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
            }
            canvas.toBlob(
              (blob: Blob | null) => {
                if (blob) {
                  return resolve(URL.createObjectURL(blob));
                } else {
                  return resolve(img.src);
                }
              },
              "image/jpeg",
              initQ
            );
          } catch (err: any) {
            Log.error({
              context: "EditPhotoWithCropper.compressFile.1",
              message: err.message as string,
            });
            return resolve(img.src);
          }
        })
        .catch(({ message }) => {
          Log.error({
            context: "EditPhotoWithCropper.compressFile.2",
            message,
          });
          return resolve(src);
        });
    });
  };

  /**
   * Recorta la imagen
   */
  const showCroppedImage = React.useCallback(async () => {
    try {
      setLoading(() => true);
      const croppedImage = (await getCroppedImg(
        imageSrc,
        croppedAreaPixels,
        rotation
      )) as string;
      // vuelve a reducir la calidad de la imagen
      const finalSrc = await compressFile(croppedImage, 0.5);

      // efecto de carga
      setTimeout(() => {
        setLoading(() => false);
      }, 500);
      if (onSave) {
        onSave(finalSrc);
      }
    } catch (err: any) {
      Log.error({
        context: "EditPhotoWithCropper.showCroppedImage.1",
        message: err.message as string,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageSrc, croppedAreaPixels, rotation, onSave]);

  const init = async () => {
    setLoading(() => true);
    // cambia la resolusión de la imagen si es mas grande
    const compress = await compressFile(imageUrl, undefined, 1080);
    setImageSrc(() => compress);
    setLoading(() => false);
  };

  React.useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageUrl]);

  return (
    <BoxMUI
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        zIndex: 1,
        background: "#222222b3",
      }}
    >
      <BoxMUI
        style={{
          width: "100vw",
          height: `calc(${AVAILABLE_SCREEN_SPACE_CSS} - 56px - 125px)`,
        }}
      >
        {imageSrc ? (
          <Cropper
            image={imageSrc}
            crop={crop}
            zoom={zoom}
            aspect={1}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
            cropShape="round"
            rotation={rotation}
          />
        ) : null}
      </BoxMUI>

      <PaperMUI
        elevation={4}
        style={{
          position: "fixed",
          bottom: "var(--ios-action-footer-height)",
          width: "100vw",
          display: "flex",
          flexDirection: "column",
          background: "#fff",
        }}
      >
        <Separator px={8} />
        <BoxMUI className="FlexRow JustifyEvenly">
          <IconButtonMUI
            color="primary"
            onClick={() =>
              setRotation((r) => {
                if (r > 0) {
                  return r - 90;
                }
                return 270;
              })
            }
          >
            <RotateLeftIcon />
          </IconButtonMUI>
          <Separator px={16} orientation="vertical" />
          <IconButtonMUI
            color="primary"
            onClick={() =>
              setRotation((r) => {
                if (r < 360) {
                  return r + 90;
                }
                return 90;
              })
            }
          >
            <RotateRightIcon />
          </IconButtonMUI>
        </BoxMUI>
        <Separator px={8} />
        <Separator px={0} divider />
        <BoxMUI
          p={2}
          className="FlexRow JustifyCenter"
          style={{ height: "68px" }}
        >
          <ButtonMUI
            style={{ width: "50%" }}
            variant="outlined"
            onClick={onCancel}
          >
            Cancelar
          </ButtonMUI>
          <Separator px={16} orientation="vertical" />
          <ButtonMUI
            style={{ width: "50%" }}
            variant="contained"
            color="primary"
            onClick={showCroppedImage}
          >
            Aceptar
          </ButtonMUI>
        </BoxMUI>
      </PaperMUI>
    </BoxMUI>
  );
};
