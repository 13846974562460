import React from "react";
import { Imgs } from "../../Assets";
import {
  ActionSheet,
  BoxMUI,
  ButtonMUI,
  Separator,
  TypographyMUI,
} from "../../DeltaUI/Components";
import { isIos } from "../../DeltaUI/Utils/Constants";
import { AppContext } from "../../Providers/AppContext";
import { useComponentMount } from "../../Utils";
import { CustomPage } from "../CustomPage";

const historyGif = `https://minio.upool.ar/frontend-assets/imgs/history_of_instagram_${
  isIos ? "ios" : "android"
}.gif`;
const pasteLinkGif = `https://minio.upool.ar/frontend-assets/imgs/paste_link.gif`;

enum ONBOARDING_SHARE_STEPS {
  ONE,
  TWO,
}

interface OnboardingShareProps {
  onNext?: () => void;
  open?: boolean;
}

export const OnboardingShare: React.FC<OnboardingShareProps> = (props) => {
  const isMountedComponent = useComponentMount("OnboardingShare");
  const { onNext, open } = props;
  const { setTipToShare } = React.useContext(AppContext);
  const [step, setStep] = React.useState<number>(ONBOARDING_SHARE_STEPS.ONE);
  const [refText, setRefText] = React.useState<HTMLSpanElement | null>(null);
  const [isLoadImage1, setIsLoadImage1] = React.useState<boolean>(false);
  const [isLoadImage2, setIsLoadImage2] = React.useState<boolean>(false);

  const heightText = React.useMemo(() => {
    return refText && refText.clientHeight ? refText.clientHeight : 68;
  }, [refText]);

  React.useEffect(() => {
    if (isMountedComponent.current && open) {
      setTipToShare(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  React.useEffect(() => {
    isMountedComponent.current = true;
    return () => {
      isMountedComponent.current = false;
    };
  }, [isMountedComponent]);

  return (
    <>
      <ActionSheet
        open={!!open}
        style={{
          height: "100vh",
        }}
      >
        <CustomPage
          fullScreen={true}
          style={{
            backgroundImage: `url(${Imgs.welcomeToMyTrips})`,
          }}
          className={`BackgroundImage`}
        >
          <BoxMUI
            style={{
              position: "absolute",
              top: 0,
              width: "100%",
              height: "100vh",
              display: "flex",
              flexDirection: "column",
              overflow: "hidden",
              padding: "0 24px",
            }}
          >
            {step === ONBOARDING_SHARE_STEPS.ONE ? (
              <BoxMUI
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <BoxMUI
                  style={{
                    width: "100%",
                    padding: "0 24px",
                  }}
                >
                  <Separator px={32} />
                  <TypographyMUI
                    className="TypographyBold20pt"
                    color="secondary"
                    style={{ textAlign: "center" }}
                  >
                    Sugerencia
                  </TypographyMUI>
                  <Separator px={16} />
                </BoxMUI>
                <BoxMUI
                  style={{
                    width: "100%",
                    padding: "0 24px",
                    textAlign: "justify",
                  }}
                >
                  <TypographyMUI
                    className="TypographyRegular11pt"
                    ref={(el) => {
                      if (el && !refText) {
                        setRefText(() => el);
                      }
                    }}
                  >
                    Si vas a subir una historia, usá el enlace copiado para
                    agregarlo como link.
                  </TypographyMUI>
                </BoxMUI>
                <Separator px={8} />
                <BoxMUI
                  style={{
                    height: `calc(100vh - 208px - ${heightText}px)`,
                    padding: "0 24px",
                  }}
                >
                  <img
                    src={historyGif}
                    alt=""
                    style={{
                      height: "100%",
                    }}
                  />
                </BoxMUI>
                <BoxMUI
                  style={{
                    position: "absolute",
                    bottom: 0,
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Separator px={24} />
                  <ButtonMUI
                    style={{
                      width: "50%",
                    }}
                    variant="contained"
                    color="primary"
                    onClick={() => setStep(() => ONBOARDING_SHARE_STEPS.TWO)}
                    disabled={!isLoadImage1}
                  >
                    Continuar
                  </ButtonMUI>
                  <Separator px={48} />
                </BoxMUI>
              </BoxMUI>
            ) : null}
            {step === ONBOARDING_SHARE_STEPS.TWO ? (
              <BoxMUI
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <BoxMUI
                  style={{
                    width: "100%",
                    padding: "0 24px",
                  }}
                >
                  <Separator px={32} />
                  <TypographyMUI
                    className="TypographyBold20pt"
                    color="secondary"
                    style={{ textAlign: "center" }}
                  >
                    Última sugerencia
                  </TypographyMUI>
                  <Separator px={16} />
                </BoxMUI>
                <BoxMUI
                  style={{
                    width: "100%",
                    padding: "0 24px",
                    textAlign: "justify",
                  }}
                >
                  <TypographyMUI className="TypographyRegular11pt">
                    El enlace copiado se encuentra en el portapapeles. ¡Podés
                    pegarlo donde quieras!
                  </TypographyMUI>
                </BoxMUI>
                <Separator px={8} />
                <BoxMUI
                  style={{
                    height: `calc(100vh - 208px - ${heightText}px)`,
                    padding: "24px",
                  }}
                >
                  <img src={pasteLinkGif} alt="" />
                </BoxMUI>
                <BoxMUI
                  style={{
                    position: "absolute",
                    bottom: 0,
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Separator px={24} />
                  <ButtonMUI
                    style={{ width: "50%" }}
                    variant="contained"
                    color="primary"
                    onClick={onNext}
                    disabled={!isLoadImage2}
                  >
                    Entendido
                  </ButtonMUI>
                  <Separator px={48} />
                </BoxMUI>
              </BoxMUI>
            ) : null}
          </BoxMUI>
        </CustomPage>
      </ActionSheet>
      <img
        src={historyGif}
        alt=""
        style={{
          display: "none",
        }}
        onLoad={() => setIsLoadImage1(() => true)}
        onError={() => setStep(() => ONBOARDING_SHARE_STEPS.TWO)}
      />
      <img
        src={pasteLinkGif}
        alt=""
        style={{
          display: "none",
        }}
        onLoad={() => setIsLoadImage2(() => true)}
        onError={onNext}
      />
    </>
  );
};
