import React from "react";
import { SetterState } from "../../Services/DefaultTypes";

export enum EMAIL_SETTING_STEPS {
  INIT_PAGE,
  SEARCH_ACCOUNT,
  ENTRE_CODE,
}

interface IEmailSetting {
  step: number;
  setStep: SetterState<number>;
  email: string;
  setEmail: SetterState<string>;
}

const context: IEmailSetting = {
  step: EMAIL_SETTING_STEPS.SEARCH_ACCOUNT,
  setStep: () => {},
  email: "",
  setEmail: () => {},
};

export const EmailSettingContext = React.createContext(context);

export interface EmailSettingProviderProps {}

export const EmailSettingProvider: React.FC<EmailSettingProviderProps> = (
  props
) => {
  const [step, setStep] = React.useState<number>(EMAIL_SETTING_STEPS.INIT_PAGE);
  const [email, setEmail] = React.useState<string>("");

  const reset = () => {
    setEmail(() => "");
  };

  React.useEffect(() => {
    if (step === EMAIL_SETTING_STEPS.INIT_PAGE) {
      reset();
    }
  }, [step]);

  return (
    <EmailSettingContext.Provider
      value={{
        step,
        setStep,
        email,
        setEmail,
      }}
    >
      {props.children}
    </EmailSettingContext.Provider>
  );
};
