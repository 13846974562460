import React from "react";
import { DialogCustomHook } from "..";
import {
  ButtonMUI,
  BoxMUI,
  Separator,
  TypographyMUI,
  CodeVerifyInput,
} from "../../DeltaUI/Components";
import { UpoolContext } from "../../Providers/UpoolContext";
import { UserService } from "../../Services/UserService";
import { useComponentMount } from "../../Utils";
import {
  RecoverPasswordContext,
  RECOVER_PASSWORD_STEPS,
} from "./RecoverPasswordContext";

interface EnterCodeEmailProps {}

export const EnterCodeEmail: React.FC<EnterCodeEmailProps> = () => {
  const isMountedComponent = useComponentMount("EnterCodeEmail");

  const { sendEmailVerificationCode, verifyEmailCode } = UserService();
  const { messageError, messageWarn } = DialogCustomHook();
  const { setLoading } = React.useContext(UpoolContext);
  const { setStep, userToRecover, setVerificationToken } = React.useContext(
    RecoverPasswordContext
  );

  const [code, setCode] = React.useState<string>("");

  const email = React.useMemo(() => {
    return userToRecover?.email;
  }, [userToRecover]);

  const handleConfirm = () => {
    if (code && email) {
      setLoading(() => true);
      verifyEmailCode(email, code)
        .then((res) => {
          if (!res) {
            setCode(() => "");
            messageWarn({
              context: "RecoverPassword.handleConfirm.1",
              message: "El código no es correcto.",
            });
          } else {
            setVerificationToken(() => res);
            setStep(() => RECOVER_PASSWORD_STEPS.NEW_PASSWORD);
          }
        })
        .catch(({ message }) => {
          setCode(() => "");
          messageError({
            context: "RecoverPassword.handleConfirm.2",
            message,
          });
        })
        .finally(() => setLoading(() => false));
    } else {
      messageError({
        context: "RecoverPassword.handleConfirm.3",
        message: "No se encontró el email del usuario.",
      });
    }
  };

  const handleInitVerify = () => {
    if (email) {
      setLoading(() => true);
      sendEmailVerificationCode(email)
        .then((res) => {
          if (!res) {
            messageError({
              context: "RecoverPassword.handleInitVerify.1",
              message: "No se puede enviar el código.",
            });
          }
        })
        .catch(({ message }) => {
          messageError({
            context: "RecoverPassword.handleInitVerify.2",
            message,
          });
        })
        .finally(() => setLoading(() => false));
    } else {
      messageError({
        context: "RecoverPassword.handleInitVerify.3",
        message: "No se encontró el email del usuario.",
      });
    }
  };

  const handleReVerify = () => {
    setCode(() => "");
    handleInitVerify();
  };

  React.useEffect(() => {
    handleInitVerify();
    isMountedComponent.current = true;
    return () => {
      isMountedComponent.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMountedComponent]);

  return (
    <>
      <BoxMUI
        style={{
          width: "100%",
        }}
      >
        <Separator px={8} />
        <TypographyMUI className="TypographyBold11pt">
          Revisá tu email
        </TypographyMUI>
        <Separator px={16} />
        <TypographyMUI className="TypographyRegular11pt">
          Ingresa a continuación el código que te enviamos por correo
          electrónico para validar tu email.
        </TypographyMUI>
        <Separator px={24} />
        <TypographyMUI variant="overline">Introducí tu código</TypographyMUI>
        <Separator px={8} />
        <CodeVerifyInput
          value={code}
          color="secondary"
          onChange={(v) => setCode(() => v)}
        />
      </BoxMUI>
      <BoxMUI
        style={{
          width: "100%",
        }}
      >
        <ButtonMUI
          style={{
            width: "100%",
            marginTop: "16px",
          }}
          variant="outlined"
          color="primary"
          onClick={handleReVerify}
        >
          Reenviar código
        </ButtonMUI>
        <Separator px={16} />
        <ButtonMUI
          style={{
            width: "100%",
            marginTop: "16px",
          }}
          variant="contained"
          color="primary"
          onClick={handleConfirm}
          disabled={code.length < 6}
        >
          Continuar
        </ButtonMUI>
        <Separator px={32} />
      </BoxMUI>
    </>
  );
};
