import React from "react";
import { CircularProgressMUI, EmptyAntd } from "../DeltaUI/Components";
import { UpoolContext } from "../Providers/UpoolContext";

export interface LoadDataProps {
  description: string;
  empty: boolean;
  loading: boolean;
  style?: React.CSSProperties;
  height?: string;
}

const LoadData: React.FC<LoadDataProps> = (props) => {
  const { children, description, empty, height, style } = props;
  const { loading } = React.useContext(UpoolContext);

  const isLoading = React.useMemo(() => {
    return !loading ? props.loading : false;
  }, [loading, props]);

  return (
    <>
      {!isLoading && !empty ? children : null}
      {!isLoading && empty ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: " flex-end",
            height,
            ...style,
          }}
        >
          <EmptyAntd
            style={{
              margin: "16px 0",
            }}
            description={description}
            image={EmptyAntd.PRESENTED_IMAGE_SIMPLE}
          />
        </div>
      ) : null}
      {isLoading ? (
        <LoadDataSpin
          style={{
            height,
            ...style,
          }}
        />
      ) : null}
    </>
  );
};

export default LoadData;

interface LoadDataSpinProps {
  style?: React.CSSProperties;
}

export const LoadDataSpin: React.FC<LoadDataSpinProps> = (props) => {
  const { style } = props;
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: " flex-end",
        padding: "31px 0",
        ...style,
      }}
    >
      <CircularProgressMUI />
    </div>
  );
};
