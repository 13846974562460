import React from "react";
import { SetterState } from "../DeltaUI/types";
import { UpoolContext } from "../Providers/UpoolContext";
import { useComponentMount } from "../Utils";

interface IPublicContext {
  params: string;
  setParams: SetterState<string>;
}

const context: IPublicContext = {
  params: "",
  setParams: () => {},
};

export const PublicContext = React.createContext(context);

export interface PublicContextProviderProps {}

export const PublicContextProvider: React.FC<PublicContextProviderProps> = (
  props
) => {
  const isMountedComponent = useComponentMount("PublicContextProvider");
  const [params, setParams] = React.useState<string>("valor inicial");
  const { historyPush, setLoading } = React.useContext(UpoolContext);

  React.useEffect(() => {
    if (isMountedComponent.current) {
      setLoading(() => false);
      const urlSearchParams = new URLSearchParams(params);
      if (urlSearchParams.get("t")) {
        historyPush(`/public/trip`);
      } else if (urlSearchParams.get("s")) {
        historyPush(`/public/search`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  React.useEffect(() => {
    setLoading(() => true);
    setParams(() => window.location.search);
    isMountedComponent.current = true;
    return () => {
      isMountedComponent.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMountedComponent]);

  return (
    <PublicContext.Provider
      value={{
        params,
        setParams,
      }}
    >
      {props.children}
    </PublicContext.Provider>
  );
};
