import React from "react";
import {
  ToolbarMUI,
  BodyPage,
  ButtonMUI,
  Separator,
  BoxMUI,
  FooterPage,
  ActionSheetOptions,
  ButtonGroup,
  MenuOptionsButton,
} from "../../../DeltaUI/Components";
import { UpoolContext } from "../../../Providers/UpoolContext";
import { FileUploadService } from "../../../Services/FileUploadService";
import { TitlePage } from "../../../Components/TitlePage";
import { CustomPage, DialogCustomHook } from "../../../Components";
import UserUploadAvatar from "../../../Components/User/UserUploadAvatar";
import { Tools, useComponentMount } from "../../../Utils";
import { UserService } from "../../../Services/UserService";
import { DeleteIcon } from "../../../DeltaUI/Components/CustomIcon";
import { GoBack } from "../../../Components/GoBack";
import {
  AVAILABLE_SCREEN_SPACE_CSS,
  COLOR_PRIMARY,
} from "../../../Utils/Constants";

const ProfilePhotoSettingPage: React.FC = () => {
  const isMountedComponent = useComponentMount("ProfilePhotoSettingPage");
  const { uploadPictureUrl } = FileUploadService();
  const { deletePictureUrl } = UserService();
  const { getPerson, URLFileToFile } = Tools();
  const { setLoading, user, setUser } = React.useContext(UpoolContext);
  const { message, messageWarn, messageError } = DialogCustomHook();
  const [pictureSRC, setPictureSRC] = React.useState<string>("");
  const [viewOptions, setViewOptions] = React.useState<boolean>(false);

  const person = React.useMemo(() => {
    return getPerson(user, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const handleUpdate = () => {
    URLFileToFile(pictureSRC, `${person?.firstName} ${person?.lastName}`)
      .then((pictureFile) => {
        if (pictureFile && isMountedComponent.current) {
          setLoading(() => true);
          uploadPictureUrl(pictureFile)
            .then((pictureUrl) => {
              setUser((u) => {
                if (u) {
                  return {
                    ...u,
                    person: u.person.map((p) => ({ ...p, pictureUrl })),
                  };
                }
                return undefined;
              });
              message({
                context: "ProfilePhotoSettingPage.handleUpdate.1",
                title: "Operacón exitosa",
                message: "Tu foto de perfil se actualizó correctamente.",
              });
              setPictureSRC(() => "");
            })
            .catch(({ message }) => {
              messageWarn({
                context: "ProfilePhotoSettingPage.handleUpdate.2",
                message:
                  "La imagen no ha subido correctamente, puede que sea demasiado grande.",
                messageError: message,
              });
            })
            .finally(() => setLoading(() => false));
        } else {
          messageError({
            context: "ProfilePhotoSettingPage.handleUpdate.4",
            message: "Algo salió mal, intente de nuevo.",
            messageError:
              "URLFileToFile -> la url no devuelve un archivo valido.",
          });
        }
      })
      .catch(({ message }) => {
        messageError({
          context: "ProfilePhotoSettingPage.handleUpdate.6",
          message: "Algo salió mal, intente de nuevo.",
          messageError: message,
        });
      });
  };

  const handleDelete = () => {
    message({
      context: "ProfilePhotoSettingPage.handleDelete.1",
      title: "¿Querés eliminar tu foto de perfil?",
      message: "Sin foto de perfil no vas a generar confianza.",
      textOnOk: "Sí",
      textOnCancel: "No",
      onOk: () => {
        deletePictureUrl().catch(({ message }) => {
          messageError({
            context: "ProfilePhotoSettingPage.handleUpdate.6",
            message: "Algo salió mal, no pudimos eliminar la foto.",
            messageError: message,
          });
        });
      },
    });
  };

  React.useEffect(() => {
    isMountedComponent.current = true;
    return () => {
      isMountedComponent.current = false;
    };
  }, [isMountedComponent]);

  return (
    <CustomPage
      headerPage={
        <ToolbarMUI>
          <GoBack />
          <TitlePage text="Foto de perfil" />
          {person?.pictureUrl ? (
            <ButtonGroup slot="end">
              <MenuOptionsButton
                menuId="ProfilePhotoSettingPageMenuOptionsID"
                onClick={() => setViewOptions(() => true)}
              />
            </ButtonGroup>
          ) : null}
        </ToolbarMUI>
      }
    >
      <BodyPage m={3}>
        <BoxMUI
          style={{
            height: `calc(${AVAILABLE_SCREEN_SPACE_CSS} - 56px - 48px)`,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <BoxMUI
            style={{
              width: "100%",
            }}
          >
            <Separator px={32} />
            <BoxMUI
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <UserUploadAvatar
                src={pictureSRC || person?.pictureUrl}
                onChange={(src) => setPictureSRC(() => src)}
                size={15}
              />
            </BoxMUI>
          </BoxMUI>
          <BoxMUI
            style={{
              width: "100%",
            }}
          >
            <ButtonMUI
              style={{
                width: "100%",
                marginTop: "16px",
              }}
              variant="contained"
              color="primary"
              onClick={handleUpdate}
              disabled={!pictureSRC}
            >
              Guardar
            </ButtonMUI>
            <Separator px={32} />
          </BoxMUI>
        </BoxMUI>
      </BodyPage>
      <FooterPage>
        {person?.pictureUrl ? (
          <ActionSheetOptions
            open={viewOptions}
            onClose={() => setViewOptions(() => false)}
            listOptions={[
              {
                icon: <DeleteIcon style={{ color: COLOR_PRIMARY }} />,
                label: "Eliminar foto",
                onClick: handleDelete,
              },
            ]}
          />
        ) : null}
      </FooterPage>
    </CustomPage>
  );
};

export default ProfilePhotoSettingPage;
