import React from "react";
import { BoxMUI } from "..";

export interface CardCarouselProps {
  separation: 0 | 8 | 16 | 32 | 64 | 128;
}

const ItemCarousel: React.FC<CardCarouselProps> = (props) => {
  const { children, separation } = props;
  return (
    <BoxMUI
      style={{
        display: "flex",
        flexDirection: "row",
        overflow: "hidden",
        overflowX: "scroll",
        padding: "16px 0",
        marginTop: "-8px",
        marginBottom: "-8px",
      }}
    >
      <div style={{ marginRight: `${separation / 2}px` }} />
      {children}
      <div style={{ marginRight: `${separation / 2}px` }} />
    </BoxMUI>
  );
};

export default ItemCarousel;
