import React from "react";
import { useComponentMount, useCustomLog } from "../../Utils";
import { ERROR_CODE } from "../../Utils/Constants";
import { DialogCustom, DialogCustomFooterActions } from "./DialogCustom";

interface IDialogCustomContext {
  message: (config: IMessageDialogCustomProps) => void;
  messageWarn: (config: IMessageErrorDialogCustomProps) => void;
  messageError: (config: IMessageErrorDialogCustomProps) => void;
}

const context: IDialogCustomContext = {
  message: () => {},
  messageWarn: () => {},
  messageError: () => {},
};

const DialogCustomContext = React.createContext(context);

export interface DialogCustomProviderProps {}

export const DialogCustomProvider: React.FC<DialogCustomProviderProps> = (
  props
) => {
  const isMountedComponent = useComponentMount("DialogCustomProvider");
  const Log = useCustomLog();

  const [
    messageDialog,
    setMessageDialog,
  ] = React.useState<IMessageDialogCustomProps>();
  const [messageErrorDialog, setMessageErrorDialog] = React.useState<
    IMessageErrorDialogCustomProps & { type: TypeMessageError }
  >();

  const message = (config: IMessageDialogCustomProps) =>
    setMessageDialog(() => config);

  const messageWarn = (config: IMessageErrorDialogCustomProps) => {
    Log.warn({
      context: config.context,
      message: config.messageError || config.message || "",
    });
    if (config.message !== ERROR_CODE.FAILED_TO_FETCH) {
      setMessageErrorDialog(() => ({
        ...config,
        type: "warn",
      }));
    }
  };

  const messageError = (config: IMessageErrorDialogCustomProps) => {
    Log.error({
      context: config.context,
      message: config.messageError || config.message || "",
    });
    if (config.message !== ERROR_CODE.FAILED_TO_FETCH) {
      setMessageErrorDialog(() => ({
        ...config,
        type: "error",
      }));
    }
  };

  React.useEffect(() => {
    isMountedComponent.current = true;
    return () => {
      isMountedComponent.current = false;
    };
  }, [isMountedComponent]);

  return (
    <>
      <DialogCustomContext.Provider
        value={{
          message,
          messageWarn,
          messageError,
        }}
      >
        {messageDialog ? (
          <DialogCustom
            context={messageDialog.context}
            open={true}
            title={messageDialog.title || "Mensaje"}
            body={messageDialog.message}
            footer={
              <DialogCustomFooterActions
                textOnOk={messageDialog.textOnOk || "Aceptar"}
                textOnCancel={messageDialog.textOnCancel || "Cancelar"}
                onOk={() => {
                  setMessageDialog(() => undefined);
                  if (messageDialog.onOk) {
                    messageDialog.onOk();
                  }
                }}
                onCancel={
                  messageDialog.onCancel || messageDialog.textOnCancel
                    ? () => {
                        setMessageDialog(() => undefined);
                        if (messageDialog.onCancel) {
                          messageDialog.onCancel();
                        }
                      }
                    : undefined
                }
              />
            }
          />
        ) : null}

        {messageErrorDialog ? (
          <DialogCustom
            open={true}
            title="Error"
            body={messageErrorDialog.message}
            footer={
              <DialogCustomFooterActions
                textOnOk={messageErrorDialog.textOnOk || "Aceptar"}
                textOnCancel={messageErrorDialog.textOnCancel || "Cancelar"}
                onOk={() => {
                  setMessageErrorDialog(() => undefined);
                  if (messageErrorDialog.onOk) {
                    messageErrorDialog.onOk();
                  }
                }}
                onCancel={
                  messageErrorDialog.onCancel || messageErrorDialog.textOnCancel
                    ? () => {
                        setMessageErrorDialog(() => undefined);
                        if (messageErrorDialog.onCancel) {
                          messageErrorDialog.onCancel();
                        }
                      }
                    : undefined
                }
              />
            }
          />
        ) : null}
        {props.children}
      </DialogCustomContext.Provider>
    </>
  );
};

export const DialogCustomHook = () => {
  const { message, messageWarn, messageError } = React.useContext(
    DialogCustomContext
  );
  return {
    message,
    messageWarn,
    messageError,
  };
};

export interface IMessageDialogCustomProps {
  context: string;
  title?: JSX.Element | string;
  message?: JSX.Element | string;
  onOk?: () => void;
  onCancel?: () => void;
  textOnOk?: string;
  textOnCancel?: string;
}

export interface IMessageErrorDialogCustomProps {
  context: string;
  message?: string;
  messageError?: string;
  onOk?: () => void;
  onCancel?: () => void;
  textOnOk?: string;
  textOnCancel?: string;
}

export type TypeMessageError = "error" | "warn" | "info";
