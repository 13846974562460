import { gql } from "@apollo/client";

export const MARK_AS_ATTENDED_ALL_NOTIFICATIONS = gql`
  mutation markAsAttendedAllNotifications {
    markAsAttendedAllNotifications
  }
`;

export const MARK_AS_ATTENDED_MANY_NOTIFICATIONS = gql`
  mutation markAsAttendedManyNotifications($where: IdManyInput!) {
    markAsAttendedManyNotifications(where: $where)
  }
`;

export const MARK_AS_READED_ALL_NOTIFICATIONS = gql`
  mutation markAsReadedAllNotifications {
    markAsReadedAllNotifications
  }
`;

export const MARK_AS_READED_MANY_NOTIFICATIONS = gql`
  mutation markAsReadedManyNotifications($where: IdManyInput!) {
    markAsReadedManyNotifications(where: $where)
  }
`;
