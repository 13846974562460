import React from "react";
import { UpoolContext } from "../../Providers/UpoolContext";
import { ButtonMUI, PaperMUI } from "../../DeltaUI/Components";
import { ITripStretch } from "../../Services/TripService";
import { Safely } from "../../Utils";
import { RESERVATION_STATUS, TRIP_STATUS } from "../../Utils/Constants";
import { MyTripsContext } from "../MyTrips/MyTripsContext";
import { MyTripsUtils } from "../MyTrips/MyTripsUtils";

export interface DetailTripRateButtonProps {
  tripStretch?: ITripStretch;
  onChange?: () => void;
}

export const DetailTripRateButton: React.FC<DetailTripRateButtonProps> = (
  props
) => {
  const { tripStretch, onChange } = props;
  const { user, historyPush, selectPathTab } = React.useContext(UpoolContext);
  const { setReservationForRate } = React.useContext(MyTripsContext);
  const { eventSafely } = Safely();

  const myReservation = React.useMemo(() => {
    return tripStretch?.myReservation;
  }, [tripStretch]);

  const { tripMode } = MyTripsUtils({
    tripStretch,
    onChange,
  });

  return (
    <>
      {tripMode.passenger &&
      (tripStretch?.trip.statusId === TRIP_STATUS.DONE ||
        tripStretch?.trip.statusId === TRIP_STATUS.CANCELED) &&
      myReservation &&
      [
        RESERVATION_STATUS.CANCELED_BY_DRIVER,
        RESERVATION_STATUS.CANCELED_BY_PASSENGER,
        RESERVATION_STATUS.FINISHED,
      ].some((statusId) => myReservation.statusId === statusId) &&
      !myReservation.rates.some((r) => r.userFromId === user?.id) ? (
        <>
          <PaperMUI
            elevation={0}
            style={{
              position: "fixed",
              bottom: "var(--ios-action-footer-height)",
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              padding: "24px",
              borderRadius: 0,
              background: "transparent",
            }}
          >
            <ButtonMUI
              className="FullWidth"
              variant="contained"
              color="primary"
              onClick={eventSafely(() => {
                setReservationForRate(() => myReservation);
                historyPush(`${selectPathTab}/rate`);
              })}
            >
              Calificar
            </ButtonMUI>
          </PaperMUI>
          <div style={{ width: "100%", height: "100px" }} />
        </>
      ) : null}
    </>
  );
};
