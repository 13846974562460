import React from "react";
import { Imgs } from "../../Assets";
import { BoxMUI, Separator } from "../../DeltaUI/Components";

interface HeaderPublicProps {
  style?: React.CSSProperties;
  className?: string;
  height: number;
}

export const HeaderShare: React.FC<HeaderPublicProps> = (props) => {
  const { style, className, height } = props;
  return (
    <BoxMUI
      style={{
        display: "flex",
        flexDirection: "column",
        ...style,
      }}
      className={`${className}`}
    >
      <BoxMUI
        style={{
          width: "100%",
          height: `calc(100vh - ${height}px)`,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Separator px={8} />
        <BoxMUI
          style={{
            width: "60vw",
            maxWidth: "400px",
          }}
        >
          <img src={Imgs.logoWhite} alt="logo" height="100%" />
        </BoxMUI>
        <Separator px={16} />
      </BoxMUI>
    </BoxMUI>
  );
};
