import React from "react";
import {
  BoxMUI,
  ButtonMUI,
  CardMUI,
  Separator,
  TypographyMUI,
} from "../../DeltaUI/Components";
import { UpoolContext } from "../../Providers/UpoolContext";

interface CardHomePhotoProps {
  style?: React.CSSProperties;
  className?: string;
}

export const CardHomePhoto: React.FC<CardHomePhotoProps> = (props) => {
  const { style, className } = props;
  const { historyPush, person } = React.useContext(UpoolContext);

  if (person?.pictureUrl) {
    return null;
  }

  return (
    <div style={style} className={className}>
      <TypographyMUI className="TypographyExtrabold13pt">
        Sugerencia
      </TypographyMUI>
      <Separator px={16} />
      <CardMUI
        elevation={7}
        style={{
          padding: "16px 24px",
        }}
        className="ShadowCard8"
      >
        <BoxMUI style={{}}>
          <TypographyMUI className="TypographyRegular10pt">
            Agregá una foto de perfil para generar más confianza.
          </TypographyMUI>
        </BoxMUI>
        <Separator px={8} />
        <ButtonMUI
          style={{ width: "100%" }}
          variant="contained"
          color="primary"
          onClick={() => historyPush("/profile/user/setting/photo")}
        >
          Agregar
        </ButtonMUI>
      </CardMUI>
    </div>
  );
};
