import React from "react";
import { UpoolContext } from "../../Providers/UpoolContext";
import { GoogleLoginButton } from "../../DeltaUI/Components";
import { Authentication } from "../../Utils";
import { UserService } from "../../Services/UserService";
import { DialogCustomHook } from "../DialogCustom";

import { config } from "../../config";
import { ERROR_CODE } from "../../Utils/Constants";
import { isDesktop } from "../../DeltaUI/Utils/Constants";
export interface GoogleLoginProps {
  login: boolean;
  style?: React.CSSProperties;
  text?: string;
  fab?: boolean;
}

const GoogleLogin: React.FC<GoogleLoginProps> = (props) => {
  const { style, text, login, fab } = props;
  const { messageError, message } = DialogCustomHook();
  const { authGoogle, logout } = Authentication();
  const { loginGoogle, signupGoogle, associateGoogleAccount } = UserService();
  const { setLoading } = React.useContext(UpoolContext);

  const handleLoginGoogle = () => {
    if (!isDesktop) {
      setLoading(() => true);
      if (config.env.credentials.webClientIdGoogle) {
        authGoogle(config.env.credentials.webClientIdGoogle)
          .then((res) => {
            if (login) {
              loginGoogle(res.userId, res.idToken).catch((err1) => {
                setLoading(() => false);
                if (
                  err1.code === ERROR_CODE.USER_NOT_EXIST ||
                  err1.code === ERROR_CODE.USER_EXIST
                ) {
                  message({
                    title: "¡Registrarme!",
                    context: "GoogleLogin.handleLoginGoogle.1",
                    message: err1?.message,
                    textOnOk: "Continuar",
                    onOk: () => {
                      setLoading(() => true);
                      signupGoogle(res.userId, res.idToken)
                        .catch((err2) => {
                          logout();
                          messageError({
                            context: "GoogleLogin.handleLoginGoogle.2",
                            message: err2.message,
                          });
                        })
                        .finally(() => setLoading(() => false));
                    },
                    onCancel: logout,
                  });
                } else {
                  logout();
                  messageError({
                    context: "GoogleLogin.handleLoginGoogle.3",
                    message: err1.message,
                  });
                }
              });
            } else {
              associateGoogleAccount(res.userId, res.idToken)
                .then(() => {
                  message({
                    context: "GoogleLogin.handleLoginGoogle.4",
                    title: "¡Listo!",
                    message: "La cuenta de Google se asocio correctamente.",
                  });
                })
                .catch((err3) => {
                  messageError({
                    context: "GoogleLogin.handleLoginGoogle.5",
                    message: err3.message,
                  });
                })
                .finally(() => {
                  logout();
                  setLoading(() => false);
                });
            }
          })
          .catch((err4) => {
            setLoading(() => false);
            messageError({
              context: "GoogleLogin.handleLoginGoogle.6",
              message: "No se realizó el login con Google.",
              messageError: err4.message,
            });
          });
      } else {
        messageError({
          context: "GoogleLogin.handleLoginGoogle.7",
          message: "No se realizó el login con Google.",
          messageError: `webClientIdGoogle no se encuentra configurado para el ambiente ${config.envName}`,
        });
      }
    }
  };

  return (
    <GoogleLoginButton
      style={style}
      onClick={handleLoginGoogle}
      text={text}
      fab={fab}
    />
  );
};

export default GoogleLogin;
