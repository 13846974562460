import React from "react";
import { IonContent, IonPage } from "@ionic/react";
import { BoxMUI, HeaderPage } from "../DeltaUI/Components";
import { HeaderPageProps } from "./HeaderPage";
import { StatusBarPage } from "./StatusBarPage";

interface CustomPageProps {
  sx?: any;
  style?: React.CSSProperties;
  className?: string;
  statusBar?: boolean;
  fullScreen?: boolean;
  headerPage?: JSX.Element;
  headerPageProps?: HeaderPageProps;
  footerPage?: JSX.Element;
  iosActionFooterBackground?: string;
}

export const CustomPage: React.FC<CustomPageProps> = (props) => {
  const {
    sx,
    children,
    style,
    className,
    headerPage,
    headerPageProps,
    fullScreen,
    footerPage,
    iosActionFooterBackground,
  } = props;

  return (
    <BoxMUI
      className={`CustomPage ${className || ""}`}
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        paddingBottom: fullScreen ? 0 : "var(--ios-action-footer-height)",
        ...style,
      }}
      sx={sx}
    >
      <IonPage>
        {headerPage ? (
          <HeaderPage
            {...{
              color: "inherit",
              ...headerPageProps,
            }}
          >
            <StatusBarPage />
            {headerPage}
          </HeaderPage>
        ) : null}
        <IonContent>
          {!headerPage ? <StatusBarPage /> : null}
          {children}
        </IonContent>
        {footerPage}
        {!fullScreen ? (
          <BoxMUI
            style={{
              position: "relative",
              width: "100%",
              height:
                "calc(var(--ios-action-footer-height) + var(--custom-tabs-bar-height))",
              background: iosActionFooterBackground || "#00000000",
            }}
          />
        ) : null}
      </IonPage>
    </BoxMUI>
  );
};
