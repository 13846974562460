import React from "react";
import { Preferences } from "@capacitor/preferences";
import { UpoolContext } from "../Providers/UpoolContext";
import { ROLE_TYPE, STORAGE_KEY } from "../Utils/Constants";
import { IRate } from "./TripService";
import { IVehicle } from "./VehicleService";
import { AppleSignInResponse } from "@awesome-cordova-plugins/sign-in-with-apple";
import { Device } from "@capacitor/device";
import { AppContext } from "../Providers/AppContext";
import { useCustomApolloAsync } from "./GraphQL/ApolloService";
import { Mutation } from "./GraphQL/Mutation";
import { Query } from "./GraphQL/Query";
import { useCustomLog } from "../Utils";

export const UserService = () => {
  const { queryAsync, mutationAsync } = useCustomApolloAsync();
  const { getLogInfo } = React.useContext(AppContext);
  const Log = useCustomLog();
  const {
    setUser,
    roleTypeDriver,
    setCreateAccountWithUserSocialNetworks,
    resolveAuthPayload,
  } = React.useContext(UpoolContext);

  const currentUser = (): Promise<void> => {
    return queryAsync({
      query: Query.currentUser,
    }).then((user: IUser) => {
      setUser(() => {
        if (user) {
          return {
            ...user,
            lastConnection: new Date(),
            vehicles: user.vehicles.filter((v) => !v.deletedAt),
          };
        }
        return undefined;
      });
    });
  };

  const login = (data: {
    password: string;
    email?: string;
    phone?: string;
  }): Promise<void> => {
    return mutationAsync({
      mutation: Mutation.login,
      variables: {
        data,
      },
    }).then(resolveAuthPayload);
  };

  const loginGoogle = (userId: string, idToken: string): Promise<void> => {
    return mutationAsync({
      mutation: Mutation.loginGoogle,
      variables: {
        data: {
          userId,
          idToken,
        },
      },
    }).then(resolveAuthPayload);
  };

  const loginFacebook = (
    userId: string,
    accessToken: string
  ): Promise<void> => {
    return mutationAsync({
      mutation: Mutation.loginFacebook,
      variables: {
        data: {
          userId,
          accessToken,
        },
      },
    }).then(resolveAuthPayload);
  };

  const loginApple = (data: AppleSignInResponse): Promise<void> => {
    return mutationAsync({
      mutation: Mutation.loginApple,
      variables: {
        data: {
          userId: data.user,
          email: data.email,
        },
      },
    }).then(resolveAuthPayload);
  };

  const signup = (data: ISignupInput): Promise<void> => {
    return mutationAsync({
      mutation: Mutation.signup,
      variables: {
        data,
      },
    }).then(resolveAuthPayload);
  };

  const signupGoogle = (userId: string, idToken: string): Promise<void> => {
    return mutationAsync({
      mutation: Mutation.signupGoogle,
      variables: {
        data: {
          userId,
          idToken,
        },
      },
    }).then((res: IUserSocialNetworks) => {
      setCreateAccountWithUserSocialNetworks(() => res);
    });
  };

  const signupFacebook = (
    userId: string,
    accessToken: string
  ): Promise<void> => {
    return mutationAsync({
      mutation: Mutation.signupFacebook,
      variables: {
        data: {
          userId,
          accessToken,
        },
      },
    }).then((res: IUserSocialNetworks) => {
      setCreateAccountWithUserSocialNetworks(() => res);
    });
  };

  const signupApple = (data: AppleSignInResponse): Promise<void> => {
    return mutationAsync({
      mutation: Mutation.signupApple,
      variables: {
        data: {
          userId: data.user,
          email: data.email,
        },
      },
    }).then(() => {
      setCreateAccountWithUserSocialNetworks(() => ({
        email: data.email || "",
        firstName: data.fullName?.familyName || "",
        lastName: data.fullName?.givenName || "",
        googleId: undefined,
        facebookId: undefined,
        appleId: data.user,
        pictureUrl: undefined,
      }));
    });
  };

  const associateGoogleAccount = (
    userId: string,
    idToken: string
  ): Promise<void> => {
    return mutationAsync({
      mutation: Mutation.associateGoogleAccount,
      variables: {
        data: {
          userId,
          idToken,
        },
      },
    }).then(resolveAuthPayload);
  };

  const associateFacebookAccount = (
    userId: string,
    accessToken: string
  ): Promise<void> => {
    return mutationAsync({
      mutation: Mutation.associateFacebookAccount,
      variables: {
        data: {
          userId,
          accessToken,
        },
      },
    }).then(resolveAuthPayload);
  };

  const associateAppleAccount = (data: AppleSignInResponse): Promise<void> => {
    return mutationAsync({
      mutation: Mutation.associateAppleAccount,
      variables: {
        data: {
          userId: data.user,
          email: data.email,
        },
      },
    }).then(resolveAuthPayload);
  };

  const deleteGoogle = (): Promise<boolean> => {
    return mutationAsync({
      mutation: Mutation.deleteGoogle,
    }).then((res: boolean) => {
      if (res) {
        setUser((cUser) =>
          cUser ? { ...cUser, googleId: undefined } : undefined
        );
      }
      return res;
    });
  };

  const deleteFacebook = (): Promise<boolean> => {
    return mutationAsync({
      mutation: Mutation.deleteFacebook,
    }).then((res: boolean) => {
      if (res) {
        setUser((cUser) =>
          cUser ? { ...cUser, facebookId: undefined } : undefined
        );
      }
      return res;
    });
  };

  const deleteApple = (): Promise<boolean> => {
    return mutationAsync({
      mutation: Mutation.deleteApple,
    }).then((res: boolean) => {
      if (res) {
        setUser((cUser) =>
          cUser ? { ...cUser, appleId: undefined } : undefined
        );
      }
      return res;
    });
  };

  const updateOneUser = (data: IUserUpdateInput): Promise<void> => {
    return mutationAsync({
      mutation: Mutation.updateOneUser,
      variables: {
        data,
      },
    }).then((user) => {
      setUser(() => user);
    });
  };

  const changeRoleType = (): Promise<void> => {
    const roleType = roleTypeDriver ? ROLE_TYPE.PASSENGER : ROLE_TYPE.DRIVER;
    return updateOneUser({
      roleType,
    });
  };

  const getOneUser = (userId: number): Promise<IUser> => {
    return queryAsync({
      query: Query.user,
      variables: {
        where: {
          id: userId,
        },
      },
    });
  };

  const rates = (userId: number, mode: string): Promise<IRate[]> => {
    const reservation =
      mode === ROLE_TYPE.DRIVER
        ? {
            tripStretch: {
              trip: {
                ownerId: {
                  equals: userId,
                },
              },
            },
          }
        : {
            userId: {
              equals: userId,
            },
          };
    return queryAsync({
      query: Query.rates,
      variables: {
        where: {
          userToId: {
            equals: userId,
          },
          reservation,
          deletedAt: {
            equals: null,
          },
        },
        orderBy: [
          {
            createdAt: "desc",
          },
        ],
      },
    });
  };

  const setLastConnection = (): Promise<void> => {
    return mutationAsync({
      mutation: Mutation.setLastConnection,
    }).then(() => {
      setUser((u) => {
        if (u) {
          return {
            ...u,
            lastConnection: new Date(),
          };
        }
        return undefined;
      });
    });
  };

  const userExists = (where: {
    email?: string;
    phone?: string;
    googleId?: string;
    facebookId?: string;
    appleId?: string;
  }): Promise<IUserExistsOutput | null | undefined> => {
    return queryAsync({
      query: Query.userExists,
      variables: {
        where,
      },
    });
  };

  const updatePasswordUser = (data: {
    id: number;
    password: string;
  }): Promise<void> => {
    return mutationAsync({
      mutation: Mutation.updateOneUser,
      variables: {
        data,
      },
    });
  };

  const getPersonGenders = (): Promise<IPersonGenders[]> => {
    return queryAsync({
      query: Query.personGenders,
    });
  };

  const sendEmailVerificationCode = (email: string): Promise<boolean> => {
    return mutationAsync({
      mutation: Mutation.sendEmailVerificationCode,
      variables: {
        data: {
          email,
        },
      },
    });
  };

  const sendPhoneVerificationCode = (phone: string): Promise<boolean> => {
    return mutationAsync({
      mutation: Mutation.sendPhoneVerificationCode,
      variables: {
        data: {
          phone,
        },
      },
    });
  };

  const verifyEmailCode = (email: string, code: string): Promise<string> => {
    return mutationAsync({
      mutation: Mutation.verifyEmailCode,
      variables: {
        data: {
          email,
          code,
        },
      },
    });
  };

  const verifyPhoneCode = (phone: string, code: string): Promise<string> => {
    return mutationAsync({
      mutation: Mutation.verifyPhoneCode,
      variables: {
        data: {
          phone,
          code,
        },
      },
    });
  };

  const resetPassword = (data: {
    userId: number;
    verificationToken: string;
    newPassword: string;
  }): Promise<boolean> => {
    return mutationAsync({
      mutation: Mutation.resetPassword,
      variables: {
        data,
      },
    });
  };

  const logout = () => {
    Preferences.remove({ key: STORAGE_KEY.AUTH_PAYLOAD }).finally(() => {
      resolveAuthPayload();
      setUser(() => undefined);
      mutationAsync({
        mutation: Mutation.logout,
      }).catch(({ message }) => {
        Log.error({
          context: "UserService.logout.1",
          message,
        });
      });
    });
  };

  const deletePictureUrl = (): Promise<boolean> => {
    return mutationAsync({
      mutation: Mutation.deletePictureUrl,
    }).then((res: boolean) => {
      if (res) {
        setUser((u) => {
          if (u) {
            return {
              ...u,
              person: u.person.map((p) => ({ ...p, pictureUrl: undefined })),
            };
          }
          return undefined;
        });
      }
      return res;
    });
  };

  const deletePhone = (): Promise<boolean> => {
    return mutationAsync({
      mutation: Mutation.deletePhone,
    }).then((res: boolean) => {
      if (res) {
        setUser((cUser) =>
          cUser
            ? { ...cUser, phone: undefined, phoneVerified: false }
            : undefined
        );
      }
      return res;
    });
  };

  const deleteEmail = (): Promise<boolean> => {
    return mutationAsync({
      mutation: Mutation.deleteEmail,
    }).then((res: boolean) => {
      if (res) {
        setUser((cUser) =>
          cUser
            ? { ...cUser, email: undefined, emailVerified: false }
            : undefined
        );
      }
      return res;
    });
  };

  const refreshToken = (authPayload: IAuthPayload): Promise<void> => {
    return mutationAsync({
      mutation: Mutation.refreshToken,
      variables: {
        data: {
          accessToken: authPayload.accessToken,
          refreshToken: authPayload.refreshToken,
        },
      },
    }).then(resolveAuthPayload);
  };

  const createOneUserComment = (description: string): Promise<void> => {
    return mutationAsync({
      mutation: Mutation.createOneUserComment,
      variables: {
        data: {
          description,
        },
      },
    });
  };

  const upsertOneUserDevice = async () => {
    const info = await getLogInfo();
    Device.getId()
      .then((r) => {
        mutationAsync({
          mutation: Mutation.upsertOneUserDevice,
          variables: {
            data: {
              deviceId: r.uuid,
              info: JSON.stringify(info),
            },
          },
        }).catch(({ message }) => {
          Log.error({
            context: "UserService.upsertOneUserDevice.1",
            message,
          });
        });
      })
      .catch(({ message }) => {
        Log.error({
          context: "UserService.upsertOneUserDevice.2",
          message,
        });
      });
  };

  const deleteOneUser = async (): Promise<boolean> => {
    const res = await mutationAsync({
      mutation: Mutation.deleteOneUser,
    });

    if (res) {
      for (const key of Object.values(STORAGE_KEY)) {
        await Preferences.remove({ key });
      }
      resolveAuthPayload();
      setUser(() => undefined);
    }
    return res;
  };

  const verifyAccountDeletion = (): Promise<boolean> => {
    return mutationAsync({
      mutation: Mutation.verifyAccountDeletion,
    });
  };

  return {
    currentUser,
    login,
    loginGoogle,
    loginFacebook,
    signup,
    signupGoogle,
    signupFacebook,
    associateFacebookAccount,
    associateGoogleAccount,
    deleteGoogle,
    deleteFacebook,
    updateOneUser,
    changeRoleType,
    getOneUser,
    rates,
    setLastConnection,
    userExists,
    updatePasswordUser,
    getPersonGenders,
    sendEmailVerificationCode,
    sendPhoneVerificationCode,
    verifyEmailCode,
    verifyPhoneCode,
    resetPassword,
    logout,
    deletePictureUrl,
    deletePhone,
    deleteEmail,
    refreshToken,
    createOneUserComment,
    loginApple,
    signupApple,
    associateAppleAccount,
    deleteApple,
    upsertOneUserDevice,
    deleteOneUser,
    verifyAccountDeletion,
  };
};

export interface IUser {
  id: number;
  email?: string;
  emailVerified: boolean;
  phone?: string;
  phoneVerified: boolean;
  lastConnection: Date;
  googleId?: string;
  facebookId?: string;
  appleId?: string;
  person: IPerson[];
  vehicles: IVehicle[];
  createdAt: Date;
  rateAsDriver: number;
  rateAsPassenger: number;
  rateAverage: number;
  roleType: string;
  tripsFinishedAsDriver: number;
  reservationsFinishedAsDriver: number;
  reservationsCanceledAsDriver: number;
  reservationsFinishedAsPassenger: number;
  reservationsCanceledAsPassenger: number;
}

export interface IAuthPayload {
  userId: number;
  accessToken: string;
  refreshToken: string;
  expiration: Date;
}

export interface IPerson {
  documentNumber: string;
  documentType: IPersonDocumentType;
  firstName: string;
  id: number;
  lastName: string;
  personDocumentTypeId: number;
  userId: number;
  pictureUrl?: string;
  genderId: number;
  birthdate: Date;
}

export interface IPersonDocumentType {
  description: string;
  id: number;
  name: string;
}

export interface IPersonGenders {
  id: number;
  description: string;
}

export interface IUserSocialNetworks {
  email: string;
  firstName: string;
  lastName: string;
  googleId?: string;
  facebookId?: string;
  appleId?: string;
  pictureUrl?: string;
}

interface ISignupInput {
  firstName: string;
  lastName: string;
  birthdate: string;
  genderId: number;
  email?: string;
  phone?: string;
  verificationToken: number;
  password?: string;
  googleId?: string;
  facebookId?: string;
  appleId?: string;
}

interface IUserUpdateInput {
  id?: number;
  email?: string;
  phone?: string;
  verificationToken?: string;
  roleType?: string;
  firstName?: string;
  lastName?: string;
  birthdate?: Date;
  genderId?: number;
}

export interface IUserExistsOutput {
  id: number;
  email?: string;
  phone?: string;
}
