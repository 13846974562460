import { IonFooter } from "@ionic/react";
import React from "react";

interface CustomTabPageProps {
  routerElement: JSX.Element;
  tabBarElement: JSX.Element;
  hideTabs?: boolean;
}

export const CustomTabPage: React.FC<CustomTabPageProps> = (props) => {
  const { routerElement, tabBarElement, hideTabs } = props;

  return (
    <>
      {routerElement}
      <IonFooter>
        {!hideTabs ? (
          <div
            style={{
              position: "fixed",
              bottom: 0,
            }}
          >
            {tabBarElement}
          </div>
        ) : null}
      </IonFooter>
    </>
  );
};
