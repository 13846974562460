import React from "react";
import {
  FormControlMUI,
  InputLabelMUI,
  InputMUI,
  InputAdornmentMUI,
  IconButtonMUI,
  FormHelperText,
} from "..";
import { Tools } from "../../Utils";
import { VisibilityIcon, VisibilityOffIcon } from "../CustomIcon";

const DEFAULT_MIN = 8;
const DEFAULT_MAX = 16;

export interface FormInputPasswordProps {
  id?: string;
  value: string;
  label?: string | boolean;
  onClick?: () => void;
  onChange?: (text: string) => void;
  disabledValidation?: boolean;
  onError?: (value: boolean) => void;
}

const FormInputPassword: React.FC<FormInputPasswordProps> = (props) => {
  const { id, value, label, onClick, onChange, disabledValidation, onError } =
    props;
  const [showPassword, setShowPassword] = React.useState<boolean>(false);
  const { validatePassword } = Tools();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(event.target.value);
    }
  };

  const messageError = React.useMemo(() => {
    if (!value) {
      return "";
    }
    const validateLength = (mn: number, mx: number) => {
      return value.length < mn || value.length > mx;
    };
    const errorLength = "Debe tener entre 8 y 16 caracteres";
    const errorPass = "Debe incluir mayúsculas, minúsculas y números";

    // if (min && max && validateLength(min, max)) {
    //   return errorLength;
    // } else if (min && validateLength(min, DEFAULT_MAX)) {
    //   return errorLength;
    // } else if (max && validateLength(DEFAULT_MIN, max)) {
    //   return errorLength;
    // }
    if (validateLength(DEFAULT_MIN, DEFAULT_MAX)) {
      return errorLength;
    } else if (!validatePassword(value)) {
      return errorPass;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  React.useEffect(() => {
    if (onError) {
      onError(disabledValidation ? false : !!messageError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageError]);

  return (
    <FormControlMUI
      variant="standard"
      sx={{
        width: "100%",
        margin: "8px 0",
      }}
      id={id}
      error={disabledValidation ? false : !!messageError}
    >
      {label === false ? null : (
        <InputLabelMUI
          htmlFor="standar-adornment-password"
          className="TypographySemibold11pt"
        >
          {label || "Contraseña"}
        </InputLabelMUI>
      )}
      <InputMUI
        id="standar-adornment-password"
        type={showPassword ? "text" : "password"}
        value={value}
        onClick={onClick}
        onChange={handleChange}
        endAdornment={
          <InputAdornmentMUI position="end">
            <IconButtonMUI
              aria-label="toggle password visibility"
              onClick={() => setShowPassword((v) => !v)}
              onMouseDown={() => {}}
              edge="end"
            >
              {showPassword ? (
                <VisibilityIcon style={{ color: "#28264B" }} />
              ) : (
                <VisibilityOffIcon style={{ color: "#28264B" }} />
              )}
            </IconButtonMUI>
          </InputAdornmentMUI>
        }
      />
      {!disabledValidation ? (
        <FormHelperText active={!!messageError} message={messageError} />
      ) : null}
    </FormControlMUI>
  );
};

export default FormInputPassword;
