import React from "react";

export interface TimeoutPromiseProps {
  time: number;
  promise: () => Promise<unknown>;
}

const TimeoutPromise: React.FC<TimeoutPromiseProps> = (props) => {
  const [change, setChange] = React.useState<number>(0);
  const { time, promise } = props;

  React.useEffect(() => {
    let t = setTimeout(() => {
      promise().then((v) => {
        setChange(() => new Date().getTime());
      });
    }, time);
    return () => {
      clearTimeout(t);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [change]);

  return <></>;
};

export default TimeoutPromise;
