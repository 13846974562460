import React from "react";
import { ToolbarMUI, BodyPage, BoxMUI } from "../../../DeltaUI/Components";
import { TitlePage } from "../../../Components/TitlePage";
import { CustomPage } from "../../../Components";
import { GoBack } from "../../../Components/GoBack";
import { AVAILABLE_SCREEN_SPACE_CSS } from "../../../Utils/Constants";
import {
  PasswordSettingContext,
  PasswordSettingProvider,
  PASSWORD_SETTING_STEPS,
} from "../../../Components/PasswordSetting/PasswordSettingContext";
import { VerificationOptions } from "../../../Components/PasswordSetting/VerificationOptions";
import { EnterCode } from "../../../Components/PasswordSetting/EnterCode";
import { NewPassword } from "../../../Components/PasswordSetting/NewPassword";
import { Init } from "../../../Components/PasswordSetting/Init";

const PasswordSettingPageContext: React.FC = () => {
  const { step } = React.useContext(PasswordSettingContext);
  return (
    <CustomPage
      headerPage={
        <ToolbarMUI>
          <GoBack />
          <TitlePage text="Contraseña de tu cuenta" />
        </ToolbarMUI>
      }
    >
      <BodyPage m={3}>
        <BoxMUI
          style={{
            height: `calc(${AVAILABLE_SCREEN_SPACE_CSS} - 56px - 48px)`,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <BoxMUI
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {step === PASSWORD_SETTING_STEPS.INIT ? <Init /> : null}
            {step === PASSWORD_SETTING_STEPS.RECOVERY_OPTIONS ? (
              <VerificationOptions />
            ) : null}
            {step === PASSWORD_SETTING_STEPS.ENTRE_CODE ? <EnterCode /> : null}
            {step === PASSWORD_SETTING_STEPS.NEW_PASSWORD ? (
              <NewPassword />
            ) : null}
          </BoxMUI>
        </BoxMUI>
      </BodyPage>
    </CustomPage>
  );
};

const PasswordSettingPage: React.FC<{}> = () => {
  return (
    <PasswordSettingProvider>
      <PasswordSettingPageContext />
    </PasswordSettingProvider>
  );
};

export default PasswordSettingPage;
