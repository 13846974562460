import React from "react";
import { Imgs } from "../../Assets";
import { CardTrip } from "../../Components";
import { LoadDataSpin } from "../../Components/LoadData";
import {
  MyTripsContext,
  MyTripsProvider,
} from "../../Components/MyTrips/MyTripsContext";
import {
  BoxMUI,
  ButtonMUI,
  Separator,
  TypographyMUI,
} from "../../DeltaUI/Components";
import { ITripStretch } from "../../Services/TripService";
import { useComponentMount, useCustomLog } from "../../Utils";
import { DETAIL_TRIP_MODE, URL } from "../../Utils/Constants";
import { FooterShare } from "../../Components/Share/FooterShare";
import { PublicContext } from "../PublicContext";
import { Share } from "@capacitor/share";
import { Clipboard } from "@capacitor/clipboard";
import { ShareTripStrech } from "../../Components/Share/ShareTripStrech";
import { HeaderShare } from "../../Components/Share/HeaderShare";
import { SearchTripProvider } from "../../Components/SearchTrip/SearchTripContext";
import { UpoolContext } from "../../Providers/UpoolContext";

interface CardTripPublicPageProps {}

const CardTripPublicComponent: React.FC<CardTripPublicPageProps> = () => {
  const isMountedComponent = useComponentMount("CardTripPublicComponent");

  const Log = useCustomLog();
  const { params } = React.useContext(PublicContext);
  const { setLoading } = React.useContext(UpoolContext);
  const {
    selectTripStretch,
    loadingMyTrips,
    setLoadingMyTrips,
  } = React.useContext(MyTripsContext);
  const [shareTripStretch, setShareTripStretch] = React.useState<
    ITripStretch | undefined
  >();

  const share = async () => {
    if (!selectTripStretch) {
      try {
        setLoading(() => true);
        await Clipboard.write({
          string: URL.UPOOL,
        });
        await Share.share({
          text: "Mi viaje",
          url: URL.UPOOL,
          dialogTitle: "Compartir",
        }).catch((err) => {
          Log.error({
            context: "HomePublicPage.share.1",
            message: err.message,
          });
        });
      } catch (err: any) {
        Log.error({
          context: "HomePublicPage.share.2",
          message: err.message,
        });
      } finally {
        setLoading(() => false);
      }
    } else {
      setLoading(() => true);
      setShareTripStretch(() => selectTripStretch);
    }
  };

  const go = () => {
    window.open(`upool.app://go${params}`, "_system");
  };

  React.useEffect(() => {
    if (!selectTripStretch) {
      setLoadingMyTrips(() => true);
    } else {
      setLoadingMyTrips(() => false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectTripStretch]);

  React.useEffect(() => {
    isMountedComponent.current = true;
    return () => {
      isMountedComponent.current = false;
    };
  }, [isMountedComponent]);

  return (
    <BoxMUI
      id="share"
      style={{
        width: "100vw",
        height: "100vh",
        overflowY: "scroll",
        backgroundImage: `url(${Imgs.upoolSplashScreen})`,
      }}
      className="BackgroundImage"
    >
      <HeaderShare height={480} />
      {loadingMyTrips ? (
        <BoxMUI
          sx={{
            "& .MuiCircularProgress-root": {
              color: "white",
            },
          }}
        >
          <LoadDataSpin />
          <Separator px={16} />
        </BoxMUI>
      ) : null}
      {!loadingMyTrips && !selectTripStretch ? (
        <BoxMUI
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <BoxMUI
            style={{
              width: "100%",
              maxWidth: "350px",
              padding: "0 24px",
              textAlign: "center",
            }}
          >
            <Separator px={8} />
            <BoxMUI
              className="FlexRow FlexCenter"
              style={{
                padding: "24px",
                border: "2px dashed #bdbdbd",
                borderRadius: "10px",
              }}
            >
              <TypographyMUI
                className="TypographyBold16pt"
                color="white"
                style={{
                  textAlign: "center",
                }}
              >
                El viaje no está disponible
              </TypographyMUI>
            </BoxMUI>
            <Separator px={32} />
          </BoxMUI>
        </BoxMUI>
      ) : null}
      {!loadingMyTrips && selectTripStretch ? (
        <BoxMUI
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <TypographyMUI
            className="TypographyBold20pt"
            color="white"
            style={{
              textAlign: "center",
            }}
          >
            Viaje disponible
          </TypographyMUI>
          <Separator px={16} />
          <CardTrip
            style={{
              position: "relative",
              maxWidth: "350px",
            }}
            tripStretchInitial={selectTripStretch}
            disabled={true}
          />
          <Separator px={24} />
        </BoxMUI>
      ) : null}
      {selectTripStretch ? (
        <BoxMUI
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "#ffffff",
          }}
        >
          <BoxMUI
            style={{
              width: "100%",
              maxWidth: "350px",
              padding: "0 24px",
              textAlign: "center",
            }}
          >
            <Separator px={24} />
            <TypographyMUI className="TypographyRegular11pt">
              Si ya tenés la app, abrí este viaje
            </TypographyMUI>
            <ButtonMUI
              style={{
                width: "200px",
                marginTop: "16px",
              }}
              variant="contained"
              color="primary"
              onClick={go}
            >
              Abrir uPool
            </ButtonMUI>
            <Separator px={8} />
          </BoxMUI>
        </BoxMUI>
      ) : null}
      <FooterShare onShare={share} />
      {shareTripStretch ? (
        <ShareTripStrech
          tripStrech={shareTripStretch}
          onFinish={() => setShareTripStretch(() => undefined)}
        />
      ) : null}
    </BoxMUI>
  );
};

const CardTripPublicPage: React.FC = () => {
  return (
    <MyTripsProvider detailTripMode={DETAIL_TRIP_MODE.WEB}>
      <SearchTripProvider>
        <CardTripPublicComponent />
      </SearchTripProvider>
    </MyTripsProvider>
  );
};

export default CardTripPublicPage;
