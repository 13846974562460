import React from "react";
import * as serviceWorkerRegistration from "../serviceWorkerRegistration";
import { useComponentMount } from "../Utils";

interface IProgressiveWebAppContext {
  loadingProgressiveWebApp: boolean;
}

const context: IProgressiveWebAppContext = {
  loadingProgressiveWebApp: false,
};

export const ProgressiveWebAppContext = React.createContext(context);

export interface ProgressiveWebAppProviderProps {
  register: boolean;
}

export const ProgressiveWebAppProvider: React.FC<ProgressiveWebAppProviderProps> = (
  props
) => {
  const isMountedComponent = useComponentMount("ProgressiveWebAppProvider");
  const { register } = props;
  const [
    loadingProgressiveWebApp,
    setLoadingProgressiveWebApp,
  ] = React.useState<boolean>(register || false);

  const handleFinish = () => {
    if (isMountedComponent.current) {
      setLoadingProgressiveWebApp(() => false);
    }
  };

  React.useEffect(() => {
    if (register) {
      serviceWorkerRegistration.register({
        onLoad: (registration) => {
          if (registration) {
            registration.onupdatefound = () => {
              const installingWorker = registration.installing;
              if (installingWorker) {
                installingWorker.onstatechange = () => {
                  if (installingWorker.state === "installed") {
                    if (navigator.serviceWorker.controller) {
                      registration.unregister();
                      window.location.reload();
                    }
                  }
                };
              }
            };
          }
          setTimeout(handleFinish, 2000);
        },
        onError: handleFinish,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [register]);

  React.useEffect(() => {
    isMountedComponent.current = true;
    return () => {
      isMountedComponent.current = false;
    };
  }, [isMountedComponent]);

  return (
    <ProgressiveWebAppContext.Provider value={{ loadingProgressiveWebApp }}>
      {props.children}
    </ProgressiveWebAppContext.Provider>
  );
};
