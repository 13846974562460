import React from "react";
import {
  Marker,
  SetRoute,
  SetCenter,
} from "../../DeltaUI/Components/GoogleMapsApiReact";
import { PlaceResult } from "../../Services/DefaultTypes";
import { IPlace } from "../../Services/PlaceService";
import { Icons } from "../../Assets";
import { useCustomLog } from "../../Utils";
import { Position } from "@capacitor/geolocation";

interface MapRouteProps {
  originPlace?: IPlace | PlaceResult | undefined;
  destinationPlace?: IPlace | PlaceResult | undefined;
  currentPosition?: Position | undefined;
}

export const MapRoute: React.FC<MapRouteProps> = (props) => {
  const { originPlace, destinationPlace, currentPosition } = props;
  const Log = useCustomLog();

  const locationOrigin = React.useMemo(() => {
    if (originPlace) {
      const { geoLocCoords } = originPlace as IPlace;
      if (geoLocCoords) {
        const coords = geoLocCoords.split(";");
        if (coords.length === 2) {
          return {
            lat: Number(coords[1]),
            lng: Number(coords[0]),
          };
        }
      }
      const { geometry } = originPlace as PlaceResult;
      if (geometry && geometry?.location) {
        return {
          lat: geometry.location.lat() || 0,
          lng: geometry.location.lng() || 0,
        };
      }
    }
    return undefined;
  }, [originPlace]);

  const locationDestination = React.useMemo(() => {
    if (destinationPlace) {
      const { geoLocCoords } = destinationPlace as IPlace;
      if (geoLocCoords) {
        const coords = geoLocCoords.split(";");
        if (coords.length === 2) {
          return {
            lat: Number(coords[1]),
            lng: Number(coords[0]),
          };
        }
      }
      const { geometry } = destinationPlace as PlaceResult;
      if (geometry && geometry?.location) {
        return {
          lat: geometry.location.lat() || 0,
          lng: geometry.location.lng() || 0,
        };
      }
    }
    return undefined;
  }, [destinationPlace]);

  const current = React.useMemo(() => {
    return currentPosition
      ? {
          lat: currentPosition.coords.latitude,
          lng: currentPosition.coords.longitude,
        }
      : undefined;
  }, [currentPosition]);

  const firstLocation = React.useMemo(() => {
    return locationOrigin || locationDestination
      ? locationOrigin || locationDestination
      : current;
  }, [locationDestination, locationOrigin, current]);

  return (
    <>
      <SetCenter position={firstLocation} />
      {locationOrigin ? (
        <Marker iconUrl={Icons.tripOriginIcon} position={locationOrigin} />
      ) : null}
      {locationDestination ? (
        <Marker
          iconUrl={Icons.tripDestinationIcon}
          position={locationDestination}
        />
      ) : null}
      {locationOrigin && locationDestination ? (
        <SetRoute
          origin={locationOrigin}
          destination={locationDestination}
          onError={({ message }) =>
            Log.warn({
              context: "MapRoute.SetRoute.1",
              message,
            })
          }
        />
      ) : null}
    </>
  );
};
