import React from "react";
import { SetterState } from "../../DeltaUI/types";
import { EventAppContext } from "../../Providers/EventAppProvider";
import {
  IReservationChat,
  ReservationChatService,
} from "../../Services/ReservationChatService";
import { useComponentMount } from "../../Utils";

const context: IChat = {
  data: [],
  setData: () => {},
};

export const ChatContext = React.createContext(context);

export interface ChatProviderProps {}

export const ChatProvider: React.FC<ChatProviderProps> = (props) => {
  const isMountedComponent = useComponentMount("ChatProvider");
  const { saveChats, cleanChats } = ReservationChatService();
  const { eventSaveListOfMessagesToSend } = React.useContext(EventAppContext);
  const [data, setData] = React.useState<IReservationChat[]>([]);

  React.useEffect(() => {
    if (data.length && eventSaveListOfMessagesToSend.data) {
      const rId = eventSaveListOfMessagesToSend.data;
      const list = data.filter((m) => !m.sendedAt);
      if (list.length) {
        saveChats(rId, list);
      } else {
        cleanChats(rId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventSaveListOfMessagesToSend]);

  React.useEffect(() => {
    isMountedComponent.current = true;
    return () => {
      isMountedComponent.current = false;
    };
  }, [isMountedComponent]);

  return (
    <ChatContext.Provider
      value={{
        data,
        setData,
      }}
    >
      {props.children}
    </ChatContext.Provider>
  );
};

interface IChat {
  data: IReservationChat[];
  setData: SetterState<IReservationChat[]>;
}
