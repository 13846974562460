import React from "react";
import { CardMUI, Separator, BoxMUI } from "../../DeltaUI/Components";
import {
  IMyAvailableTripStretchResultInfo,
  ITripStretch,
  TripService,
} from "../../Services/TripService";
import { Safely, useComponentMount, useCustomLog } from "../../Utils";
import { MyTripsContext } from "../MyTrips/MyTripsContext";
import { CardDetailTripData } from ".";
import { ItemUser } from "../User/ItemUser";
import { CardTripDescriptionStatusTrip } from "./CardTripDescriptionStatusTrip";
import { UpoolContext } from "../../Providers/UpoolContext";
import { DETAIL_TRIP_MODE } from "../../Utils/Constants";

export interface CardTripProps {
  tripStretchInitial: ITripStretch;
  search?: boolean;
  infoTripStretch?: IMyAvailableTripStretchResultInfo;
  style?: React.CSSProperties;
  disabled?: boolean;
  share?: boolean;
}

const CardTrip: React.FC<CardTripProps> = (props) => {
  const isMountedComponent = useComponentMount("CardTrip");
  const {
    tripStretchInitial,
    infoTripStretch,
    style,
    disabled,
    share,
    search,
  } = props;
  const { eventSafely } = Safely();
  const { tripStretch } = TripService();
  const Log = useCustomLog();
  const { notificationGeneral } = React.useContext(UpoolContext);
  const { setSelectTripStretch, detailTripMode } = React.useContext(
    MyTripsContext
  );
  const [tripSt, setTripSt] = React.useState<ITripStretch>(tripStretchInitial);

  const places = React.useMemo(() => {
    return tripSt.isDriver
      ? `${tripSt.usedPlaces}/${tripSt.totalPlaces}`
      : `${tripSt.availablePlaces}`;
  }, [tripSt]);

  const userOwner = React.useMemo(
    () => tripSt.trip.userOwner,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tripSt]
  );

  const changeNotification = React.useMemo(() => {
    return notificationGeneral.filter(
      (n) => n.payload.tripStretchId === tripSt.id
    ).length;
  }, [tripSt, notificationGeneral]);

  const handleUpdateTripSt = () => {
    tripStretch(tripSt.id)
      .then((ts) => {
        if (isMountedComponent.current && ts) {
          setTripSt(() => ts);
        }
      })
      .catch(({ message }) => {
        Log.error({
          context: "CardTrip.tripStretch.1",
          message,
        });
      });
  };

  React.useEffect(() => {
    if (isMountedComponent.current) {
      handleUpdateTripSt();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeNotification]);

  React.useEffect(() => {
    isMountedComponent.current = true;
    return () => {
      isMountedComponent.current = false;
    };
  }, [isMountedComponent]);

  return (
    <CardMUI style={style} elevation={7} className="ShadowCard8">
      {detailTripMode === DETAIL_TRIP_MODE.SEARCH_TRIP && !share ? (
        <BoxMUI
          style={{
            padding: "16px",
            paddingBottom: 0,
          }}
        >
          <ItemUser
            user={userOwner}
            isDrive={true}
            disabled={disabled || share}
          />
        </BoxMUI>
      ) : null}
      {detailTripMode === DETAIL_TRIP_MODE.SEARCH_TRIP && !share ? (
        <Separator px={8} divider />
      ) : (
        <Separator px={8} />
      )}
      <CardDetailTripData
        placeFrom={tripSt.placeFrom}
        placeTo={tripSt.placeTo}
        dateFrom={tripSt.dateFrom}
        totalPlaces={places}
        amount={tripSt.amount}
        typeId={tripSt.trip.typeId}
        distanceMtsFrom={
          infoTripStretch ? infoTripStretch?.distanceMtsFrom : undefined
        }
        distanceMtsTo={
          infoTripStretch ? infoTripStretch?.distanceMtsTo : undefined
        }
        onClick={eventSafely(() => setSelectTripStretch(() => tripSt))}
        disabled={disabled}
        share={share}
        search={search}
      />
      {detailTripMode === DETAIL_TRIP_MODE.MY_TRIPS && !share ? (
        <>
          <Separator px={8} divider />
          <CardTripDescriptionStatusTrip tripStretch={tripSt} isCard={true} />
          <Separator px={8} />
        </>
      ) : (
        <Separator px={8} />
      )}
    </CardMUI>
  );
};

export default CardTrip;
