import { NOTIFICATIONS } from "./NotificationQuery";
import { PREFERENCES } from "./PreferenceQuery";
import { RESERVATION_CHATS } from "./ReservationChatQuery";
import { RESERVATION, RESERVATIONS } from "./ReservationsQuery";
import { SETTINGS } from "./SettingsQuery";
import { SHARE } from "./ShareQuery";
import {
  AVAILABLE_TRIP_STRETCHES,
  TRIP_STRETCH,
  TRIPS_STRETCHES,
  TRIP_SUGGESTED_AMOUNT,
  HOME_PAGE_TRIP_STRECH,
} from "./TripQuery";
import {
  CURRENT_USER,
  PERSON_GENDERS,
  RATES,
  USER,
  USER_EXIST,
} from "./UserQuery";
import {
  COLOURS,
  VEHICLE_BRANDS,
  VEHICLE_FUEL_TYPES,
  VEHICLE_MODELS,
} from "./VehicleQuery";

export const Query = {
  currentUser: { name: "currentUser", gql: CURRENT_USER },
  tripStretchesAvailables: {
    name: "tripStretchesAvailables",
    gql: AVAILABLE_TRIP_STRETCHES,
  },
  tripStretch: { name: "tripStretch", gql: TRIP_STRETCH },
  reservationChats: { name: "reservationChats", gql: RESERVATION_CHATS },
  reservations: { name: "reservations", gql: RESERVATIONS },
  preferences: { name: "preferences", gql: PREFERENCES },
  vehicleBrands: {
    name: "vehicleBrands",
    gql: VEHICLE_BRANDS,
  },
  vehicleModels: {
    name: "vehicleModels",
    gql: VEHICLE_MODELS,
  },
  vehicleFuelTypes: {
    name: "vehicleFuelTypes",
    gql: VEHICLE_FUEL_TYPES,
  },
  tripStretches: {
    name: "tripStretches",
    gql: TRIPS_STRETCHES,
  },
  tripSuggestedAmount: {
    name: "tripSuggestedAmount",
    gql: TRIP_SUGGESTED_AMOUNT,
  },
  user: {
    name: "user",
    gql: USER,
  },
  rates: {
    name: "rates",
    gql: RATES,
  },
  notifications: {
    name: "notifications",
    gql: NOTIFICATIONS,
  },
  reservation: { name: "reservation", gql: RESERVATION },
  personGenders: { name: "personGenders", gql: PERSON_GENDERS },
  colours: { name: "colours", gql: COLOURS },
  tripStrechHomePage: {
    name: "tripStrechHomePage",
    gql: HOME_PAGE_TRIP_STRECH,
  },
  userExists: {
    name: "userExists",
    gql: USER_EXIST,
  },
  settings: {
    name: "settings",
    gql: SETTINGS,
  },
  share: {
    name: "share",
    gql: SHARE,
  },
};
