import React from "react";
import { TypographyMUI } from "../DeltaUI/Components";

interface TitlePageProps {
  style?: React.CSSProperties;
  className?: string;
  text: string;
}

export const TitlePage: React.FC<TitlePageProps> = (props) => {
  const { style, text, className } = props;

  return (
    <TypographyMUI
      style={{ marginLeft: "4px", ...style }}
      className={`${className} TypographyExtrabold13pt`}
    >
      {text}
    </TypographyMUI>
  );
};
