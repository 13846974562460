import React from "react";

export interface RenderListAsyncProcessProps {
  list: JSX.Element[];
  timeout?: number;
  loading?: JSX.Element;
}

const RenderListAsyncProcess: React.FC<RenderListAsyncProcessProps> = (
  props
) => {
  const [mounted, setMounted] = React.useState<boolean>(false);
  const { list, timeout, loading } = props;
  const [renderList, setRenderList] = React.useState<JSX.Element[]>([]);

  const renderItem = async (item: JSX.Element) =>
    new Promise((resolve) => {
      if (mounted) {
        setRenderList((list) => [...list, item]);

        // tiempo de renderizado
        setTimeout(() => {
          resolve(null);
        }, timeout || 50);
      }
    });

  const render = async (list: JSX.Element[]) => {
    for (const el of list) {
      await renderItem(el);
    }
  };

  React.useEffect(() => {
    setMounted(() => true);
    return () => {
      setMounted(() => false);
    };
  }, []);

  React.useEffect(() => {
    if (mounted) {
      render(list);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mounted]);

  return (
    <>
      {renderList.length ? (
        renderList.map((component) => component)
      ) : (
        <>{loading || null}</>
      )}
    </>
  );
};

const RenderListAsync: React.FC<RenderListAsyncProcessProps> = (props) => {
  const { list } = props;
  const [updateList, setUpdateList] = React.useState<number>(0);

  React.useEffect(() => {
    setUpdateList(() => new Date().getTime());
  }, [list]);

  return <RenderListAsyncProcess key={updateList} {...props} />;
};

export default RenderListAsync;
