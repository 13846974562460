import { useCustomApolloAsync } from "./GraphQL/ApolloService";
import { Mutation } from "./GraphQL/Mutation";

export const FileUploadService = () => {
  const { mutationAsync } = useCustomApolloAsync();

  const uploadPictureUrl = (file: File): Promise<string> => {
    return mutationAsync({
      mutation: Mutation.uploadPictureUrl,
      variables: {
        data: {
          file,
        },
      },
    });
  };

  return {
    uploadPictureUrl,
  };
};
