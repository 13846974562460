import React from "react";
import { useCustomApolloLog } from "../DeltaUI/Library/apollo";
import { ILog } from "../DeltaUI/Library/apollo/hooks/useCustomApolloLog";
import { AppContext } from "../Providers/AppContext";
import { UpoolContext } from "../Providers/UpoolContext";
import { Mutation } from "../Services/GraphQL/Mutation";

export const useCustomLog = () => {
  const { getLogInfo } = React.useContext(AppContext);
  const { user } = React.useContext(UpoolContext);
  const { Logger } = useCustomApolloLog({
    logLevel: "warn",
    mutation: Mutation.registerLog,
  });

  const Log = React.useMemo(() => {
    return {
      error: async (log: ILog) => {
        console.error(log);
        const logInfo = await getLogInfo();
        return Logger.error({
          ...log,
          userId: `${user?.id}`,
          deviceInfo: JSON.stringify(logInfo),
        });
      },
      warn: async (log: ILog) => {
        const logInfo = await getLogInfo();
        return Logger.warn({
          ...log,
          userId: `${user?.id}`,
          deviceInfo: JSON.stringify(logInfo),
        });
      },
      info: async (log: ILog) => {
        const logInfo = await getLogInfo();
        return Logger.info({
          ...log,
          userId: `${user?.id}`,
          deviceInfo: JSON.stringify(logInfo),
        });
      },
    };
  }, [Logger, getLogInfo, user?.id]);

  return Log;
};
