import React from "react";
import { UpoolContext } from "../Providers/UpoolContext";
import { BadgeMUI, CustomTabPage } from "../DeltaUI/Components";
import {
  CardTravelIcon,
  HomeOutlinedIcon,
  SearchIcon,
  NotificationsOutlinedIcon,
  SteeringWheelIcon,
} from "../DeltaUI/Components/CustomIcon";
import { APPLICATION_PATHS } from "../Utils/Constants";
import { Redirect, Route, Switch } from "react-router-dom";
import HomeNavTree from "./HomeNavTree";
import CreateTripNavTree from "./CreateTripNavTree";
import SearchTripNavTree from "./SearchTripNavTree";
import MyTripsNavTree from "./MyTripsNavTree";
import NotificationsNavTree from "./NotificationsNavTree";
import {
  CustomTabsBar,
  CustomTabsBarItem,
} from "../DeltaUI/Components/Tabs/CustomTabsBar";

const TabsPage: React.FC = () => {
  const {
    notificationsIsNotReadedIds,
    isViewTabs,
    historyPush,
  } = React.useContext(UpoolContext);

  return (
    <CustomTabPage
      routerElement={
        <Switch>
          <Route
            exact
            path="/tabs"
            render={() => <Redirect to={APPLICATION_PATHS.HOME} />}
          />
          <Route path={APPLICATION_PATHS.HOME} component={HomeNavTree} />
          <Route
            path={APPLICATION_PATHS.CREATE_TRIP}
            component={CreateTripNavTree}
          />
          <Route
            path={APPLICATION_PATHS.SEARCH_TRIP}
            component={SearchTripNavTree}
          />
          <Route path={APPLICATION_PATHS.MY_TRIPS} component={MyTripsNavTree} />
          <Route
            path={APPLICATION_PATHS.NOTIFICATIONS}
            component={NotificationsNavTree}
          />
        </Switch>
      }
      hideTabs={!isViewTabs}
      tabBarElement={
        <CustomTabsBar invertedColor={true} elevation={4} color="secondary">
          <CustomTabsBarItem
            disabledTo={true}
            onClick={historyPush}
            to={APPLICATION_PATHS.HOME}
          >
            <HomeOutlinedIcon />
          </CustomTabsBarItem>
          <CustomTabsBarItem
            disabledTo={true}
            onClick={historyPush}
            to={APPLICATION_PATHS.CREATE_TRIP}
          >
            <SteeringWheelIcon />
          </CustomTabsBarItem>
          <CustomTabsBarItem
            disabledTo={true}
            onClick={historyPush}
            to={APPLICATION_PATHS.SEARCH_TRIP}
          >
            <SearchIcon />
          </CustomTabsBarItem>
          <CustomTabsBarItem
            disabledTo={true}
            onClick={historyPush}
            to={APPLICATION_PATHS.MY_TRIPS}
          >
            <CardTravelIcon />
          </CustomTabsBarItem>
          <CustomTabsBarItem
            disabledTo={true}
            onClick={historyPush}
            to={APPLICATION_PATHS.NOTIFICATIONS}
          >
            <BadgeMUI
              badgeContent={notificationsIsNotReadedIds.length || 0}
              color="secondary"
            >
              <NotificationsOutlinedIcon />
            </BadgeMUI>
          </CustomTabsBarItem>
        </CustomTabsBar>
      }
    />
  );
};

export default TabsPage;
