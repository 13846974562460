import React from "react";
import {
  AvatarMUI,
  BoxMUI,
  Separator,
  TypographyMUI,
} from "../../DeltaUI/Components";
import { INotification } from "../../Services/NotificationService";
import { MomentJS } from "../../Utils";
import {
  COLOR_PRIMARY,
  COLOR_SECONDARY,
  COLOR_TERTIARY,
  COLOR_QUINARY,
  NOTIFICATION_TYPE,
} from "../../Utils/Constants";

interface CardNotificationProps {
  style?: React.CSSProperties;
  className?: string;
  notification: INotification;
  onClick?: () => void;
}

export const CardNotification: React.FC<CardNotificationProps> = (props) => {
  const { style, className, notification, onClick } = props;
  const { getTime } = MomentJS();

  const background = React.useMemo(() => {
    if (notification.type === NOTIFICATION_TYPE.TRIP_DONE) {
      return `${COLOR_SECONDARY}30`;
    }
    return `${COLOR_TERTIARY}40`;
  }, [notification]);

  const colorTitle = React.useMemo(() => {
    if (notification.type === NOTIFICATION_TYPE.TRIP_IN_PROGRESS) {
      return COLOR_PRIMARY;
    }
    if (notification.type === NOTIFICATION_TYPE.TRIP_DONE) {
      return COLOR_SECONDARY;
    }
    return undefined;
  }, [notification]);

  return (
    <BoxMUI
      style={{
        background: !notification.attendedAt ? background : undefined,
      }}
    >
      <Separator px={8} />
      <BoxMUI
        style={{
          display: "flex",
          position: "relative",
          padding: "16px 24px",
          ...style,
        }}
        className={className}
        onClick={onClick}
      >
        <BoxMUI
          style={{
            padding: "5px 0",
          }}
        >
          {notification.type === NOTIFICATION_TYPE.MESSENGER ? (
            <AvatarMUI
              src={notification.payload.pictureUrl}
              sx={{
                width: "50px",
                height: "50px",
              }}
            />
          ) : (
            <AvatarMUI
              src={notification.payload.pictureUrl}
              sx={{
                width: "50px",
                height: "50px",
                borderRadius: 0,
              }}
            />
          )}
        </BoxMUI>
        <BoxMUI
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            marginLeft: "16px",
          }}
        >
          <BoxMUI>
            <TypographyMUI
              className="TypographyBold11pt"
              style={{ color: colorTitle }}
            >
              {notification.title}
            </TypographyMUI>
          </BoxMUI>
          <BoxMUI>
            <TypographyMUI className="TypographyRegular10pt">
              {notification.message}
            </TypographyMUI>
          </BoxMUI>

          {notification.sendedAt ? (
            <BoxMUI
              style={{
                marginBottom: "-14px",
                textAlign: "end",
              }}
            >
              <TypographyMUI
                className="TypographyRegular8pt"
                variant="caption"
                color={COLOR_QUINARY}
              >
                {getTime(notification.sendedAt)}
              </TypographyMUI>
            </BoxMUI>
          ) : null}
        </BoxMUI>
      </BoxMUI>
      <div
        style={{
          padding: "0 16px",
        }}
      >
        <Separator px={0} divider />
      </div>
    </BoxMUI>
  );
};
