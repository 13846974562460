import React from "react";
import { InputMUI, ListItemMUI, ListItemIconMUI } from "..";

const styles: any = {
  input: {
    width: "100%",
    marginRight: "8px",
  },
  item: {
    padding: "0",
  },
  avIcon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minWidth: "40px",
    paddingRight: "8px",
  },
};

export interface InputMoneyProps {
  value: string;
  onChange?: (ev: any) => void;
  iconLeft?: JSX.Element;
  iconRight?: JSX.Element;
  placeholder?: string;
  onClick?: () => void;
  disabled?: boolean;
  decimalSeparator?: "." | ",";
  max?: number;
  min?: number;
  onError?: (status: boolean) => void;
  decimalCount?: 0 | 1 | 2 | 3 | 4;
  className?: string;
}

const InputMoney: React.FC<InputMoneyProps> = (props) => {
  const {
    value,
    onChange,
    iconLeft,
    iconRight,
    placeholder,
    onClick,
    disabled,
    decimalSeparator,
    max,
    min,
    onError,
    decimalCount,
    className,
  } = props;

  const _decimalSeparator = React.useMemo(() => decimalSeparator || ",", [
    decimalSeparator,
  ]);
  const _decimalCount = React.useMemo(() => decimalCount || 0, [decimalCount]);

  const currentValue = React.useMemo(() => {
    // si no envia nada
    if (!value) {
      return value;
    }
    const regex = /(\W|[a-zA-Z])/g;
    const lastIndexOf = value.lastIndexOf(_decimalSeparator);
    let amountInit = `${Number(value.replace(regex, "")) * 1}`;

    if (lastIndexOf > 0) {
      const init = value.slice(0, lastIndexOf);
      const end =
        lastIndexOf < value.length
          ? value.slice(lastIndexOf + 1, value.length)
          : "";

      amountInit = `${Number(init.replace(regex, "")) * 1}${_decimalSeparator}${
        end ? end.replace(regex, "").slice(0, _decimalCount) : ""
      }`;
    }
    return amountInit;
  }, [_decimalSeparator, value, _decimalCount]);

  const currentError = React.useMemo((): boolean => {
    if (max && Number(currentValue) > max) {
      return true;
    }
    if (min && Number(currentValue) < min) {
      return true;
    }
    return false;
  }, [currentValue, max, min]);

  React.useEffect(() => {
    if (onError) {
      onError(currentError);
    }
  }, [currentError, onError]);

  return (
    <ListItemMUI style={styles.item} onClick={onClick}>
      {iconLeft ? (
        <ListItemIconMUI style={styles.avIcon}>{iconLeft}</ListItemIconMUI>
      ) : null}
      <InputMUI
        className={className}
        style={styles.input}
        placeholder={placeholder || "Ingresa un valor"}
        value={currentValue}
        onChange={(ev) => {
          if (onChange) {
            onChange(Number(ev.target.value));
          }
        }}
        disabled={disabled}
        error={currentError}
      />
      {iconRight ? (
        <ListItemIconMUI style={styles.avIcon}>{iconRight}</ListItemIconMUI>
      ) : null}
    </ListItemMUI>
  );
};

export default InputMoney;
