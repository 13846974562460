import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { DETAIL_TRIP_MODE, APPLICATION_PATHS } from "../Utils/Constants";
import NotificationsPage from "./NotificationsPage";
import ChatListPage from "./ChatListPage";
import ChatPage from "./ChatPage";
import DetailTripPage from "./DetailTripPage";
import EditTripPage from "./EditTripPage";
import RatePage from "./RatePage";
import ViewUserNavTree from "./User/ViewUserNavTree";
import { MyTripsProvider } from "../Components/MyTrips/MyTripsContext";

const defaultPath = `${APPLICATION_PATHS.NOTIFICATIONS}/default`;
const notificationsDetailPath = `${APPLICATION_PATHS.NOTIFICATIONS}/detail-trip`;
const notificationsChatPath = `${APPLICATION_PATHS.NOTIFICATIONS}/chat`;
const notificationsRatePath = `${APPLICATION_PATHS.NOTIFICATIONS}/rate`;
const notificationsUserPath = `${APPLICATION_PATHS.NOTIFICATIONS}/user`;
const notificationsEditPath = `${APPLICATION_PATHS.NOTIFICATIONS}/edit-trip`;
const notificationsChatListPath = `${APPLICATION_PATHS.NOTIFICATIONS}/chat-list`;

const NotificationsNavTreeSwitch: React.FC = () => {
  return (
    <Switch>
      <Route exact path={APPLICATION_PATHS.NOTIFICATIONS}>
        <Redirect to={defaultPath} />
      </Route>
      <Route exact path={defaultPath} component={NotificationsPage} />
      <Route exact path={notificationsDetailPath} component={DetailTripPage} />
      <Route exact path={notificationsChatPath} component={ChatPage} />
      <Route path={notificationsUserPath} component={ViewUserNavTree} />
      <Route exact path={notificationsRatePath} component={RatePage} />
      <Route exact path={notificationsEditPath} component={EditTripPage} />
      <Route exact path={notificationsChatListPath} component={ChatListPage} />
    </Switch>
  );
};

const NotificationsNavTree: React.FC = () => {
  return (
    <MyTripsProvider detailTripMode={DETAIL_TRIP_MODE.MY_TRIPS}>
      <NotificationsNavTreeSwitch />
    </MyTripsProvider>
  );
};

export default NotificationsNavTree;
