import React from "react";
import { UpoolContext } from "../../Providers/UpoolContext";
import { BoxMUI, Separator, TypographyMUI } from "../../DeltaUI/Components";
import {
  IReservationChat,
  ReservationChatService,
} from "../../Services/ReservationChatService";
import { MomentJS, useComponentMount } from "../../Utils";
import WriteMessage from "./WriteMessage";
import { debounce } from "lodash";
import { IReservation } from "../../Services/ReservationService";
import {
  TAKE,
  COLOR_QUINARY,
  AVAILABLE_SCREEN_SPACE_CSS,
} from "../../Utils/Constants";
import { Virtuoso } from "react-virtuoso";
import LoadData from "../LoadData";
import { DialogCustomHook } from "../DialogCustom";
import { AppContext } from "../../Providers/AppContext";
import { SubsciptionChat } from "./SubsciptionChat";
import { ChatContext } from "./ChatContext";
import { ChatSeparatorDate } from "./ChatSeparatorDate";
import ItemChat from "./ItemChat";
import { EventAppContext } from "../../Providers/EventAppProvider";
import { useCustomLog } from "../../Utils";
import { ApolloServiceError } from "../../DeltaUI/Library/apollo/utils";

const START_INDEX = 100000;

enum SENDING_MESSAGE_STATUS {
  INIT,
  SENDING,
  FINISH,
}

export interface ChatProps {
  reservation: IReservation;
}

export const Chat: React.FC<ChatProps> = (props) => {
  const isMountedComponent = useComponentMount("Chat");
  const { reservation } = props;
  const { handleEventSaveListOfMessagesToSend } = React.useContext(
    EventAppContext
  );
  const { showingKeyboard } = React.useContext(AppContext);
  const { user, isActiveApp } = React.useContext(UpoolContext);
  const { data, setData } = React.useContext(ChatContext);
  const { messageError } = DialogCustomHook();
  const {
    createOneReservationChat,
    reservationChats,
    markAsReadedManyReservationChats,
    getListOfMessagesToSend,
    cleanChats,
  } = ReservationChatService();
  const { createMoment, getDate } = MomentJS();
  const Log = useCustomLog();

  const [error, setError] = React.useState<ApolloServiceError>();
  const [loadingChats, setLoadingChats] = React.useState<boolean>(false);
  const [height, setHeight] = React.useState<number>(0);
  const virtuosoRef = React.useRef<any>(null);
  const [firstItemIndex, setFirstItemIndex] = React.useState<number>(
    START_INDEX
  );
  const [
    sendingMessageStatus,
    setSendingMessageStatus,
  ] = React.useState<number>(SENDING_MESSAGE_STATUS.FINISH);

  const updateAllMessage = debounce(() => {
    setData((b) =>
      b.map((m) => {
        return m.readedAt ? m : { ...m, readedAt: new Date() };
      })
    );
  }, 1000);

  const updateMessage = (chat: IReservationChat) => {
    if (isMountedComponent.current && user && chat) {
      if (user.id !== chat.createdById) {
        updateAllMessage();
      }
      setData((current) => {
        let exist = false;
        const l = current.map((item) => {
          if (item.externalId === chat.externalId) {
            exist = true;
            if (!item.readedAt && chat.readedAt) {
              return chat;
            } else if (!item.receivedAt && chat.receivedAt) {
              return chat;
            }
          }
          return item;
        });
        if (!exist) {
          l.push(chat);
        }

        const lInfo = addInfoMessage(l);
        if (!exist) {
          setFirstItemIndex((v) => v - 1);
        }
        return [...lInfo];
      });
      handleChangeScroll();
    }
  };

  const handleSend = (chat: IReservationChat) => {
    const { description, reservationId, externalId, createdAt } = chat;
    setSendingMessageStatus(() => SENDING_MESSAGE_STATUS.SENDING);
    setData((current) => {
      const l = current.map((item) => {
        if (item.externalId === chat.externalId) {
          return {
            ...chat,
            sendedAt: new Date(),
          };
        }
        return item;
      });
      return [...l];
    });
    return createOneReservationChat({
      description,
      reservationId,
      externalId,
      sendedAt: createdAt,
    })
      .then((c) => {
        updateMessage(c);
        setSendingMessageStatus(() => SENDING_MESSAGE_STATUS.INIT);
      })
      .catch(({ message }) => {
        setData((current) => {
          const l = current.map((item) => {
            if (item.externalId === chat.externalId) {
              return {
                ...chat,
                sendedAt: undefined,
              };
            }
            return item;
          });
          return [...l];
        });
        setSendingMessageStatus(() => SENDING_MESSAGE_STATUS.FINISH);
        messageError({
          context: "Chat.handleSend.1",
          message: message,
        });
      });
  };

  const sendMessage = (mensaje: string) => {
    if (!error) {
      const description = mensaje.trim();
      if (description && user) {
        const d = new Date();
        const chat: IReservationChat = {
          id: 0,
          reservationId: reservation.id,
          description,
          createdAt: d,
          createdById: user.id,
          createdByUser: user,
          sendedAt: undefined,
          readedAt: undefined,
          receivedAt: undefined,
          externalId: `${d.getTime()}`,
          myMessage: true,
          consecutive: false,
          separator: new Date(),
        };

        setFirstItemIndex((v) => v - 1);
        setData((l) => addInfoMessage([...l, chat]));
        handleChangeScroll();
        if (sendingMessageStatus !== SENDING_MESSAGE_STATUS.SENDING) {
          setSendingMessageStatus(() => SENDING_MESSAGE_STATUS.INIT);
        }
      }
    } else {
      messageError({
        context: "Chat.sendMessage.1",
        message: error.message,
      });
    }
  };

  const addInfoMessage = (list: IReservationChat[]) => {
    const l = list.map((chat, index, list) => {
      if (index > 0 && (!chat.consecutive || chat.separator)) {
        let prev = createMoment({
          date: getDate(list[index - 1].createdAt),
          originFormat: "DD/MM/YYYY",
        }).toDate();

        let next = createMoment({
          date: getDate(chat.createdAt),
          originFormat: "DD/MM/YYYY",
        }).toDate();

        chat.separator = prev < next ? list[index - 1].createdAt : undefined;
        chat.myMessage = chat.createdById === user?.id;
        chat.consecutive = list[index - 1].createdById === chat.createdById;
      } else if (index === 0) {
        chat.separator = chat.createdAt;
        chat.myMessage = chat.createdById === user?.id;
        chat.consecutive = false;
      }
      return chat;
    });
    return l;
  };

  /**
   * Marcar mensajes no leidos
   */
  const markAsReadedAll = () => {
    markAsReadedManyReservationChats(reservation.id).catch(({ message }) => {
      Log.error({
        context: "Chat.markAsReadedAll.1",
        message,
      });
    });
  };

  const initChats = async (chats: IReservationChat[]) => {
    setLoadingChats(() => true);
    const savedList = await getListOfMessagesToSend(reservation.id).catch(
      ({ message }) => {
        Log.error({
          context: "Chat.initChats.1",
          message,
        });
        return [];
      }
    );

    setLoadingChats(() => false);
    if (isMountedComponent.current) {
      if (chats.length) {
        markAsReadedAll();
      }
      if (savedList.length) {
        cleanChats(reservation.id);
      }
      const allChats = [...chats.reverse(), ...savedList];
      if (allChats.length) {
        setFirstItemIndex((v) => v - allChats.length);
        setData(() => addInfoMessage(allChats));
        setSendingMessageStatus(() => SENDING_MESSAGE_STATUS.INIT);
      }
    }
  };

  const getData = (paginationId?: number) => {
    setLoadingChats(() => true);
    reservationChats(reservation.id, paginationId)
      .then((chats) => {
        if (paginationId) {
          if (isMountedComponent.current && chats && chats.length) {
            setFirstItemIndex((v) => v - chats.length);
            setData((d) => addInfoMessage([...chats.reverse(), ...d]));
          }
        } else {
          initChats(chats);
        }
      })
      .catch(({ message }) => {
        messageError({
          context: "Chat.getData.1",
          message,
        });
        if (isMountedComponent.current && !paginationId) {
          initChats([]);
        }
      })
      .finally(() => setLoadingChats(() => false));
  };

  const handleChangeScroll = debounce(() => {
    if (virtuosoRef.current) {
      virtuosoRef.current.scrollIntoView();
    }
  }, 500);

  React.useEffect(() => {
    if (isMountedComponent.current && isActiveApp) {
      setSendingMessageStatus(() => SENDING_MESSAGE_STATUS.INIT);
    } else {
      handleEventSaveListOfMessagesToSend(reservation.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActiveApp]);

  React.useEffect(() => {
    if (
      isActiveApp &&
      isMountedComponent.current &&
      data.length &&
      sendingMessageStatus === SENDING_MESSAGE_STATUS.INIT
    ) {
      const list = data.filter((m) => !m.sendedAt);
      if (list.length) {
        handleSend(list[0]);
      } else {
        setSendingMessageStatus(() => SENDING_MESSAGE_STATUS.FINISH);
      }
    } else if (sendingMessageStatus === SENDING_MESSAGE_STATUS.FINISH) {
      handleEventSaveListOfMessagesToSend(reservation.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendingMessageStatus]);

  React.useEffect(() => {
    if (isMountedComponent.current) {
      handleChangeScroll();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showingKeyboard]);

  React.useEffect(() => {
    isMountedComponent.current = true;
    if (isMountedComponent.current) {
      setFirstItemIndex(() => START_INDEX);
      setData(() => []);
      getData();
    }
    return () => {
      isMountedComponent.current = false;
      handleEventSaveListOfMessagesToSend(reservation.id);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMountedComponent]);

  return (
    <>
      {isActiveApp ? (
        <SubsciptionChat
          reservationId={reservation.id}
          onNext={updateMessage}
          onError={(err) => {
            setError(() => err);
            Log.error({
              context: "Chat.SubsciptionChat.1",
              message: err.message,
            });
          }}
        />
      ) : null}
      <Virtuoso
        style={{
          position: "absolute",
          bottom: "56px",
          width: "100%",
          height: `calc(${AVAILABLE_SCREEN_SPACE_CSS} - 56px - ${height}px)`,
        }}
        firstItemIndex={firstItemIndex}
        data={data}
        initialTopMostItemIndex={TAKE.MESSAGES - 1}
        startReached={() => {
          if (data.length >= TAKE.MESSAGES) {
            getData(data.length ? data[0].id : undefined);
          }
          return false;
        }}
        itemContent={(_, chat) => (
          <>
            {chat.myMessage ? (
              <BoxMUI key={chat.id}>
                {!chat.consecutive && !chat.separator ? (
                  <Separator px={16} />
                ) : null}
                {chat.separator ? (
                  <ChatSeparatorDate date={chat.separator} />
                ) : null}
                <ItemChat
                  myMessage={chat.myMessage}
                  message={chat}
                  sending={!chat.sendedAt}
                  sent={!!chat.sendedAt}
                  received={!!chat.receivedAt}
                  seen={!!chat.readedAt}
                />
              </BoxMUI>
            ) : (
              <BoxMUI key={chat.id}>
                {!chat.consecutive && !chat.separator ? (
                  <Separator px={16} />
                ) : null}
                {chat.separator ? (
                  <ChatSeparatorDate date={chat.separator} />
                ) : null}
                <ItemChat key={chat.id} message={chat} />
              </BoxMUI>
            )}
          </>
        )}
        components={{
          Header: () => (
            <LoadData
              description="No hay mensajes"
              empty={!data.length}
              loading={loadingChats}
              height={data.length ? "102px" : "45vh"}
            >
              <BoxMUI
                style={{
                  width: "100%",
                  height: "102px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <BoxMUI style={{ width: "100%", textAlign: "center" }}>
                  <TypographyMUI
                    className="TypographyRegular10pt"
                    color={COLOR_QUINARY}
                  >
                    Inicio el chat
                  </TypographyMUI>
                </BoxMUI>
              </BoxMUI>
            </LoadData>
          ),
          Footer: () => (
            <>
              <Separator px={24} />
              <BoxMUI ref={virtuosoRef} />
            </>
          ),
        }}
      />
      <WriteMessage
        onHeight={(h) => {
          setHeight(() => h);
        }}
        onChange={sendMessage}
        showingKeyboard={showingKeyboard}
      />
    </>
  );
};
