import { gql } from "@apollo/client";

export const SHARE = gql`
  query share($where: ShareWhereUniqueInput!) {
    share(where: $where) {
      id
      description
    }
  }
`;
