import React from "react";
import { BackdropMUI, CircularProgressMUI } from "..";

export interface LoadingProps {
  open: boolean;
  color?: string;
}

const Loading: React.FC<LoadingProps> = (props) => {
  const { open, color } = props;
  return (
    <BackdropMUI
      style={{
        zIndex: 5000,
        color: color || "#fff",
      }}
      open={open}
    >
      <CircularProgressMUI color="inherit" />
    </BackdropMUI>
  );
};

export default Loading;
