import React from "react";
import {
  BoxMUI,
  IconButtonMUI,
  Separator,
  TypographyMUI,
} from "../../DeltaUI/Components";
import { ITripStretch, TripService } from "../../Services/TripService";
import { UpoolContext } from "../../Providers/UpoolContext";
import { LoadDataSpin } from "../LoadData";
import { COLOR_PRIMARY, TRIP_STATUS } from "../../Utils/Constants";
import CardTrip from "./CardTrip";
import { useComponentMount, useCustomLog } from "../../Utils";
import { ShareIcon } from "../../DeltaUI/Components/CustomIcon";

interface CardHomeProps {
  style?: React.CSSProperties;
  className?: string;
}

export const CardHome: React.FC<CardHomeProps> = (props) => {
  const isMountedComponent = useComponentMount("CardHome");
  const { style, className } = props;

  const {
    notificationGeneral,
    isActiveApp,
    setShareTripStretch,
  } = React.useContext(UpoolContext);

  const [loadingHome, setLoadingHome] = React.useState<boolean>(false);
  const [homeTripStretch, setHomeTripStretch] = React.useState<
    ITripStretch | undefined
  >();

  const Log = useCustomLog();
  const { tripStrechHomePage } = TripService();

  const getData = () => {
    if (!homeTripStretch) {
      setLoadingHome(() => true);
    }
    tripStrechHomePage()
      .then((ts) => {
        if (isMountedComponent.current && ts) {
          setHomeTripStretch(() => ts);
        } else {
          setLoadingHome(() => false);
        }
      })
      .catch(({ message }) => {
        setLoadingHome(() => false);
        Log.error({
          context: "CardHome.getData.1",
          message,
        });
      })
      .finally(() => setLoadingHome(() => false));
  };

  React.useEffect(() => {
    if (isActiveApp) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActiveApp, notificationGeneral]);

  React.useEffect(() => {
    isMountedComponent.current = true;
    return () => {
      isMountedComponent.current = false;
    };
  }, [isMountedComponent]);

  return (
    <BoxMUI style={style} className={className}>
      {loadingHome ? (
        <LoadDataSpin />
      ) : (
        <>
          <BoxMUI
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <BoxMUI
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              {homeTripStretch?.trip.statusId === TRIP_STATUS.IN_PROGRESS ? (
                <TypographyMUI className="TypographyExtrabold13pt">
                  Viaje en curso
                </TypographyMUI>
              ) : null}
              {homeTripStretch?.trip.statusId === TRIP_STATUS.AVAILABLE ? (
                <TypographyMUI className="TypographyExtrabold13pt">
                  Próximo viaje
                </TypographyMUI>
              ) : null}
              {homeTripStretch?.trip.statusId === TRIP_STATUS.DONE ? (
                <TypographyMUI className="TypographyExtrabold13pt">
                  Último viaje
                </TypographyMUI>
              ) : null}
            </BoxMUI>

            {homeTripStretch?.trip.statusId === TRIP_STATUS.AVAILABLE ? (
              <IconButtonMUI
                color="inherit"
                onClick={() => setShareTripStretch(() => homeTripStretch)}
              >
                <ShareIcon style={{ color: COLOR_PRIMARY }} />
              </IconButtonMUI>
            ) : null}
          </BoxMUI>
          <Separator px={8} />
          {homeTripStretch ? (
            <CardTrip
              style={{
                position: "relative",
              }}
              tripStretchInitial={homeTripStretch}
            />
          ) : null}
          {!homeTripStretch ? (
            <BoxMUI
              className="FlexRow FlexCenter"
              style={{
                padding: "24px",
                border: "2px dashed #bdbdbd",
                borderRadius: "10px",
              }}
            >
              <TypographyMUI className="TypographyRegular10pt">
                Aquí se cargarán los datos de tu próximo viaje o del último que
                hayas realizado.
              </TypographyMUI>
            </BoxMUI>
          ) : null}
        </>
      )}
    </BoxMUI>
  );
};
