import React from "react";
import { GoogleMap } from "@react-google-maps/api";
import { SetterState } from "../../types";
import { silverMode } from "./styles";
import { TypographyMUI } from "..";
import { Loader1 } from "../Loader";
import "./style.css";
import { LoadApiGoogleMapsContext } from "./LoadApiGoogleMaps";

const context: IGoogleMapsContext = {
  map: null as any,
  setMap: () => {},
};

export const GoogleMapsContext = React.createContext(context);

export interface GoogleMapsProviderProps {
  style?: React.CSSProperties;
  onLoad?: Function;
  onUnmount?: Function;
  loading?: JSX.Element;
  headerElement?: JSX.Element;
  footerElement?: JSX.Element;
}

export const GoogleMapsProvider: React.FC<GoogleMapsProviderProps> = React.memo(
  (props) => {
    const isMountedComponent = React.useRef<boolean>(false);
    const {
      children,
      style,
      loading,
      onLoad,
      onUnmount,
      headerElement,
      footerElement,
    } = props;
    const { isLoaded, loadError } = React.useContext(LoadApiGoogleMapsContext);
    const [map, setMap] = React.useState<google.maps.Map | undefined>();

    const handleLoad = React.useCallback(
      (m) => {
        if (isMountedComponent.current) {
          m.setOptions({
            styles: silverMode,
            disableDefaultUI: true,
          });
          setMap(m);
          if (onLoad) {
            onLoad();
          }
        }
      },
      [onLoad]
    );

    React.useEffect(() => {
      isMountedComponent.current = true;
      return () => {
        isMountedComponent.current = false;
        setMap(undefined);
        if (onUnmount) {
          onUnmount();
        }
      };
    }, [onUnmount]);

    return (
      <GoogleMapsContext.Provider
        value={{
          map,
          setMap,
        }}
      >
        {headerElement}
        <div style={style}>
          <div className="GoogleMapsApiReact">
            {!isLoaded && !loadError
              ? loading || (
                  <div className="Loading">
                    <Loader1 />
                    <TypographyMUI variant="body2">Cargando mapa</TypographyMUI>
                  </div>
                )
              : null}
            {loadError ? (
              <div className="Loading">
                <Loader1 />
                <TypographyMUI variant="body2">
                  El mapa no está disponible en este momento
                </TypographyMUI>
              </div>
            ) : null}
            {isLoaded ? (
              <GoogleMap
                mapContainerStyle={{
                  width: "100%",
                  height: "100%",
                }}
                zoom={10}
                onLoad={handleLoad}
              >
                {/* Child components, such as markers, info windows, etc. */}
                {children}
              </GoogleMap>
            ) : null}
          </div>
        </div>
        {footerElement}
      </GoogleMapsContext.Provider>
    );
  }
);

interface IGoogleMapsContext {
  map: google.maps.Map | undefined;
  setMap: SetterState<google.maps.Map | undefined>;
}

export interface IGoogleMapsPosition {
  lat: number;
  lng: number;
}
