import React from "react";
import { useJsApiLoader } from "@react-google-maps/api";

const ID = "google-map-script";
const LIBRARIES: (
  | "drawing"
  | "geometry"
  | "localContext"
  | "places"
  | "visualization"
)[] = ["places"];

interface ILoadApiGoogleMapsContext {
  isLoaded: boolean;
  loadError: Error | undefined;
}

const context: ILoadApiGoogleMapsContext = {
  isLoaded: false,
  loadError: undefined,
};

export const LoadApiGoogleMapsContext = React.createContext(context);

export interface LoadApiGoogleMapsProviderProps {
  googleMapsApiKey: string | null;
}

export const LoadApiGoogleMapsProvider: React.FC<
  LoadApiGoogleMapsProviderProps
> = (props) => {
  const { googleMapsApiKey, children } = props;
  const { isLoaded, loadError } = useJsApiLoader({
    id: ID,
    googleMapsApiKey: googleMapsApiKey || "",
    libraries: LIBRARIES,
  });

  if (!googleMapsApiKey) {
    alert("Falta configurar googleMapsApiKey");
    return <>{children}</>;
  }

  return (
    <LoadApiGoogleMapsContext.Provider
      value={{
        isLoaded,
        loadError,
      }}
    >
      {children}
    </LoadApiGoogleMapsContext.Provider>
  );
};
